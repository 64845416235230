import React from 'react';
import { Modal } from 'react-bootstrap';
import KpiScorecard2 from '../../../DashboardComponents/KpiComponents/Scorecard';

function KpiScorecard({ show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Scorecard KPI</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <nav className="container-fluid d-block">
                    <div className="row">
                        <div className="col-md-6 mt-2">
                            <div className="mb-3 form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="componentName"
                                    placeholder="Enter Component Name"
                                    required
                                />
                                <label htmlFor="componentName">Component Name</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="modules">
                                    <option value="">Select Module</option>
                                    <option value="module1">Module 1</option>
                                    <option value="module2">Module 2</option>
                                    <option value="module3">Module 3</option>
                                </select>
                                <label htmlFor="modules">Module(s)</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="measure">
                                    <option value="">Select Measure</option>
                                    <option value="measure1">Measure 1</option>
                                    <option value="measure2">Measure 2</option>
                                    <option value="measure3">Measure 3</option>
                                </select>
                                <label htmlFor="measure">Measure (y-axis)</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="grouping">
                                    <option value="">Select Grouping</option>
                                    <option value="group1">Group 1</option>
                                    <option value="group2">Group 2</option>
                                    <option value="group3">Group 3</option>
                                </select>
                                <label htmlFor="grouping">Grouping</label>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <div className='rounded p-3 border text-center' style={{ backgroundColor: '#f8f9fa', height: '100%', width: '100%' }}>
                                <div className="container-fluid">
                                    <div className="row g-4">
                                        <div className="col-md-12">
                                            <div className="card ">
                                                <div className="card-body text-center">
                                                    <KpiScorecard2 isStatic={true}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </Modal.Body>
        </Modal>
    );
}

export default KpiScorecard;
