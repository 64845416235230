import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaRegCalendarAlt, FaArrowLeft } from "react-icons/fa";
import userAvatar from '../../../Assets/images/icons/user-profile-icon.svg';
import { getLoginHistoryStart, getUserLoginHistoryStart } from '../../../Store/Slices/loginHistorySlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import { formatDateTime } from '../../../Components/DateFn';

const LoginHistory = () => {
    const dispatch = useDispatch();
    let defaultProfile = "https://campussafetyconference.com/wp-content/uploads/2020/08/iStock-476085198.jpg";
    const { history, userLoginHistory, isLoading } = useSelector((state) => state.loginhistory);
    const [userListView, setUserListView] = useState(true);
    const [loginHistoryView, setLoginHistoryView] = useState(false);
    const [dateInput, setDateInput] = useState("Last 7 Days");
    const [selectedEmail, setSelectedEmail] = useState('');

    useEffect(() => {
        if (selectedEmail) {
            let payload = {
                email: selectedEmail,
                input: dateInput
            }
            dispatch(getUserLoginHistoryStart(payload))
        } else {
            dispatch(getLoginHistoryStart(dateInput));
        }
    }, [dateInput]);

    useEffect(() => {
        if (userLoginHistory?.length) {
            setUserListView(false);
            setLoginHistoryView(true);
        }
    }, [userLoginHistory])

    const showUserListView = () => {
        dispatch(getLoginHistoryStart(dateInput));
        setSelectedEmail('');
        setUserListView(true);
        setLoginHistoryView(false);
    }

    const showLoginHistoryView = (email) => {
        setSelectedEmail(email);
        let payload = {
            email: email,
            input: dateInput
        }
        dispatch(getUserLoginHistoryStart(payload))
    }

    const handleDateChange = (date) => {
        setDateInput(date);
    };

    return (
        <>
            <Loader isVisible={isLoading} />
            <div className='log-history-page overflow-y-auto'>
                {/* Page Header */}
                <div className="d-flex flex-row mt-4">
                    <div className="left-con w-75">
                        <h5 className="fw-semibold">
                            <FaArrowLeft className={`h5 mb-0 me-2 cursor-pointer ${loginHistoryView ? 'd-inline-block' : 'd-none'}`} onClick={showUserListView} />
                            <span className='h5'>Login History</span>
                        </h5>
                    </div>
                    <div className="right-con d-flex flex-column align-items-end ms-auto">
                        <div className='btn-group'>
                            <Dropdown>
                                <Dropdown.Toggle variant='light' id="actions-dropdown" className='rounded-pill flat-btn with-arrow'>
                                    <FaRegCalendarAlt />
                                    <span className='ps-2 pe-3'>{dateInput}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='p-0 fs-14'>
                                    {["Today", "Yesterday", "Last 7 Days", "Last 15 Days", "Last 30 Days"].map((date) => (
                                        <Dropdown.Item key={date} onClick={() => handleDateChange(date)}>
                                            {date}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {/* // Page Header END // */}

                {/* User wise list Table */}
                <div className={`user-list-login ${userListView ? 'd-block' : 'd-none'}`}>
                    <div className="d-flex flex-row align-items-center mt-4 mb-3">
                        <div className="left-con">
                            <h6 className="fw-semibold mb-0">Activity by Users</h6>
                        </div>
                        <div className="right-con d-flex align-items-center ms-auto">
                            <span className='pe-2'>Total Count: </span>
                            <span className="badge rounded-pill text-bg-info me-2">{history?.length}</span>
                        </div>
                    </div>
                    <div className="tbl-container rounded-table">
                        <div className="table-responsive">
                            <table className="table table-hover hover-icon-table mb-0">
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Email Address</th>
                                        <th>No.of logins</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history?.length > 0 ? (
                                        history?.map((data) => (
                                            <tr className='cursor-pointer' onClick={() => showLoginHistoryView(data?.email)}>
                                                <td>
                                                    <div className="d-inline-flex align-items-center">
                                                        <img className={`${data?.profilePicture === defaultProfile && 'opacity-50'}`} src={data?.profilePicture} width="30" height="30" alt="profile-icon" />
                                                        <span className="fw-semibold ms-2">{data?.user_name}</span>
                                                    </div>
                                                </td>
                                                <td>{data?.email}</td>
                                                <td>{data?.count}</td>
                                            </tr>
                                        )))
                                        :
                                        <tr>
                                            <td colSpan="3">No data found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* // User wise list Table END // */}

                {/* Login History list Table */}
                <div className={`login-history ${loginHistoryView ? 'd-block' : 'd-none'}`}>
                    <div className="d-flex flex-row align-items-center mt-4 mb-3">
                        <div className="left-con">
                            <h6 className="fw-semibold mb-0">User Login History</h6>
                        </div>
                        <div className="right-con d-flex align-items-center ms-auto">
                            <span className='pe-2'>Total Count: </span>
                            <span className="badge rounded-pill text-bg-info me-2">{userLoginHistory?.length}</span>
                        </div>
                    </div>
                    <div className="tbl-container rounded-table">
                        <div className="table-responsive">
                            <table className="table table-hover hover-icon-table mb-0">
                                <thead>
                                    <tr>
                                        <th>User Name/Email Address</th>
                                        <th>Login Time</th>
                                        <th>Logout Time</th>
                                        <th>Location</th>
                                        <th>Device Info</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userLoginHistory?.length > 0 ? (
                                        userLoginHistory.map((data) => (
                                            <tr key={data?._id}>
                                                <td>
                                                    <span className='fw-semibold d-block'>{data?.user_name}</span>
                                                    <span>{data?.email}</span>
                                                </td>
                                                <td>{formatDateTime(data.login_time)}</td>
                                                <td>{data.logout_time ? formatDateTime(data.logout_time) : ''}</td>
                                                <td>{data.location}</td>
                                                <td>{data.device_info}</td>
                                            </tr>
                                        ))
                                    ) :
                                        <tr><td colSpan="4">No history available</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* // Login History list Table END // */}
            </div>
        </>
    )
}
export default LoginHistory;