import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Utils } from "../../../Utils";

const BusinessConnect = () => {
    let formList = useSelector((state) => state?.forms);
    let [moduleId, setModuleId] = useState("");
    let [method, setMethod] = useState("");
    let [errors, setErrors] = useState({});
    let [touched, setTouched] = useState({});
    let navigate = useNavigate();

    const validateField = (field, value) => {
        switch (field) {
            case "moduleId":
                if (!value) {
                    return "Module is required";
                }
                break;
            case "method":
                if (!value) {
                    return "Method is required";
                }
                break;
            default:
                break;
        }
        return "";
    };

    const validateForm = () => {
        let newErrors = {};
        newErrors.moduleId = validateField("moduleId", moduleId);
        newErrors.method = validateField("method", method);
        return newErrors;
    };

    useEffect(() => {
        if (touched.moduleId) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                moduleId: validateField("moduleId", moduleId),
            }));
        }
    }, [moduleId, touched.moduleId]);

    useEffect(() => {
        if (touched.method) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                method: validateField("method", method),
            }));
        }
    }, [method, touched.method]);

    const onSubmit = (e) => {
        e.preventDefault();
        setTouched({
            moduleId : true,
            method : true
        });
        let validationErrors = validateForm();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).every((key) => !validationErrors[key])) {
            navigate(Utils.getNavigationUrl(`integration-api/form`), { state: { id: moduleId, method } });
        }
    };

    const handleBlur = (field) => {
        setTouched({
            ...touched,
            [field]: true,
        });
    };

    return (
        // <div className="main-panel">
            <main className="py-md-4 content-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8">
                            <form onSubmit={onSubmit}>
                            <div className="form-floating mb-3">
                                    <select 
                                        className={`form-select w-50 ${touched.method && errors.method ? 'is-invalid' : ''}`}
                                        id="module"
                                        value={method}
                                        onChange={(e) => setMethod(e.target.value)}
                                        onBlur={() => handleBlur('method')}
                                    >
                                        <option value="">Select a Method</option>
                                        <option value="POST">POST</option>
                                        <option value="GET">GET</option>
                                    </select>
                                    <label htmlFor="module" className="form-label">Module</label>
                                    {touched.method && errors.method && (
                                        <div className="invalid-feedback">{errors.method}</div>
                                    )}
                                </div>
                                <div className="form-floating mb-3">
                                    <select 
                                        className={`form-select w-50 ${touched.moduleId && errors.moduleId ? 'is-invalid' : ''}`}
                                        id="module"
                                        value={moduleId}
                                        onChange={(e) => setModuleId(e.target.value)}
                                        onBlur={() => handleBlur('moduleId')}
                                    >
                                        <option value="">Select a Module</option>
                                        {formList?.navbarList?.length > 0 && formList.navbarList.map((x, index) => (
                                            <option key={index} value={x?._id}>{x?.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="module" className="form-label">Module</label>
                                    {touched.moduleId && errors.moduleId && (
                                        <div className="invalid-feedback">{errors.moduleId}</div>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-success">Connect</button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        // </div>
    );
};

export default BusinessConnect;
