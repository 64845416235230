import React, { useEffect, useState } from 'react';
import ReactEcharts from "echarts-for-react";
import { useSelector } from 'react-redux';
import { Helpers } from '../../../Helpers';
import Loader from "../../../Components/Loader";
import { Utils } from '../../../Utils';
import dayjs from 'dayjs';

const ColumnChart = ({ data = {}, isStatic = false }) => {
  let getOptions = (xAxisData, seriesData) => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        data: xAxisData || []
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: seriesData || [],
          type: 'bar'
        }
      ]
    }
  }
  let [columnChartOptions, setColumnChartOptions] = useState(getOptions([], []));
  let { subModuleData, isDashboardLoading } = useSelector((state) => state?.dashboardComponents);
  let [countData, setCountData] = useState([]);
  let [groupingData, setGroupingData] = useState([]);
  let [width, setWidth] = useState("0%");

  useEffect(() => {
    if (countData?.length > 0 && groupingData?.length > 0 && !isStatic) {
      let valueNumber = 0;
      let formatGroupingData = groupingData?.filter(x => x !== "None");
      let isNone = groupingData?.some(x => x === "None");
      let formatCountData = countData?.filter((_, index) => groupingData[index] !== "None");
      let noneCountData = countData?.filter((_, index) => groupingData[index] === "None");
      let sumCheck = (operation, values) => {
        switch (operation) {
          case 'sum':
            return values.length ? values.reduce((total, val) => total + val, 0) : 0;
          case 'min':
            return values.length ? Math.min(...values) : 0;
          case 'max':
            return values.length ? Math.max(...values) : 0;
          case 'average':
            return values.length ? values.reduce((total, val) => total + val, 0) / values.length : 0;
          default:
            return 0;
        }
      }
      let yAxisMeasure = data?.y_axis_measure;
      if (isNone) {
        if (yAxisMeasure?.startsWith("Count of - ")) {
          valueNumber = noneCountData?.reduce((total, num) => total + num, 0);
        } else if (yAxisMeasure?.startsWith("Sum of ")) {
          valueNumber = sumCheck('sum', noneCountData)
        } else if (yAxisMeasure?.startsWith("Minimum of ")) {
          valueNumber = sumCheck('min', noneCountData)
        } else if (yAxisMeasure?.startsWith("Maximum of ")) {
          valueNumber = sumCheck('max', noneCountData)
        } else if (yAxisMeasure?.startsWith("Average of ")) {
          valueNumber = sumCheck('average', noneCountData)
        }
      }
      let seriesData = [...formatCountData, ...(isNone ? [valueNumber] : [])];
      let xAxisData = [...formatGroupingData, ...(isNone ? ['None'] : [])];
      let { uniqueItems, aggregatedCounts } = Utils.removeDuplicatesAndTotalCount(xAxisData, seriesData);
      let { labels, values } = Utils.chartFormatDatas(data?.grouping, uniqueItems, aggregatedCounts, data?.grouping_type, "column");
      setColumnChartOptions(getOptions(labels, values));
      setTimeout(() => {
        setWidth("100%");
      }, 100);
    } else if (!isStatic) {
      setColumnChartOptions(getOptions([], []));
    }
  }, [countData, groupingData]);

  useEffect(() => {
    if (subModuleData?.[data?.moduleId]?.length > 0 && Object?.keys(data)?.length > 0 && !isStatic) {
      let datas = [...(subModuleData?.[data?.moduleId] || [])].reverse();
      let checkGrouping = ["day", "month", "year", "Created_At"];
      let newFilteredData = Utils.filteredDataGet(datas, data); 
      let yAxisMeasure = data?.y_axis_measure;
      let grouping = Helpers.replaceData(data?.grouping);
      let formattedDate = (date) => dayjs(date).format("YYYY-MM-DD");
      let calculate = (label, isGrouping = false) => {
        let values = newFilteredData?.map(record => {
          let value = isGrouping ? 'None' : 0;
          if (isGrouping && checkGrouping?.includes(label)) {
            value = formattedDate(record?.createdAt);
          } else {
            for (let section of record?.data || []) {
              if (section?.section_data?.[label] !== undefined) {
                if (isGrouping && data?.grouping_type === "user") {
                  value = section.section_data[label]?.name?.toString()?.trim() || 'None';
                } else if (isGrouping) {
                  value = section.section_data[label]?.toString()?.trim() || 'None';
                  value = (data?.grouping_type === "date" && value !== "None") ? formattedDate(value) : value;
                } else {
                  value = parseFloat(section.section_data[label]) || 0;
                }
                break;
              }
            }
          }
          return value;
        });
        return values || [];
      };

      let getLabel = (prefix) => Helpers.replaceData(yAxisMeasure?.replace(new RegExp(`^${prefix} of `), ''));

      if (yAxisMeasure?.startsWith("Count of - ")) {
        setCountData(Array(newFilteredData?.length || 0).fill(1));
      } else if (yAxisMeasure?.startsWith("Sum of ")) {
        let label = getLabel("Sum");
        setCountData(calculate(label));
      } else if (yAxisMeasure?.startsWith("Minimum of ")) {
        let label = getLabel("Minimum");
        setCountData(calculate(label));
      } else if (yAxisMeasure?.startsWith("Maximum of ")) {
        let label = getLabel("Maximum");
        setCountData(calculate(label));
      } else if (yAxisMeasure?.startsWith("Average of ")) {
        let label = getLabel("Average");
        setCountData(calculate(label));
      }
      setGroupingData(calculate(grouping, true));
    } else if (!isStatic) {
      setColumnChartOptions(getOptions([], []));
    }
  }, [subModuleData?.[data?.moduleId], data]);

  useEffect(() => {
    if (isStatic) {
      let xAxisData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      setColumnChartOptions(getOptions(xAxisData, [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]));
      setTimeout(() => {
        setWidth("100%");
      }, 100);
    }
  }, [isStatic]);

  return (
    <>
      {isDashboardLoading && !columnChartOptions?.series?.[0]?.data?.length ?
        <div className='chart-loader'><Loader type={'infinitySpin'} isVisible={true} /></div>
        :
        <>
          {columnChartOptions?.series?.[0]?.data?.length > 0 ? (
            <ReactEcharts option={columnChartOptions} style={{ height: "100%", width: width }} />
          ) : (
            <div className='h-100 fw-bold align-self-center align-center py-3'>No Data Found</div>
          )}
        </>
      }
    </>
  )
}

export default ColumnChart;