import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form, Row, Col } from "react-bootstrap";
import { getModuleByIdLookUpStart } from '../../../../Store/Slices/formSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Helpers } from "../../../../Helpers";
function SingleFilterLookupRecords({ show, handleClose, moduleId, filterLookupRecords, submitData }) {
    let dispatch = useDispatch();
    let getLookModuleData = useSelector((state) => state?.forms);
    let [fieldData, setFieldData] = useState([]);
    let [stringOptions] = useState([
        {
            "value": "",
            "label": "None"
        },
        {
            "value": "IS",
            "label": "is"
        },
        {
            "value": "ISN'T",
            "label": "isn't"
        },
        {
            "value": "CONTAINS",
            "label": "Contains"
        },
        {
            "value": "DOESN'T MATCH",
            "label": "doesn't match"
        },
        {
            "value": "STARTS WITH",
            "label": "starts with"
        },
        {
            "value": "ENDS WITH",
            "label": "ends with"
        },
        {
            "value": "IS EMPTY",
            "label": "is empty"
        },
        {
            "value": "IS NOT EMPTY",
            "label": "is not empty"
        }
    ]);
    let [numberOptions] = useState([
        {
            "value": "",
            "label": "None"
        },
        {
            "value": "=",
            "label": "="
        },
        {
            "value": "!=",
            "label": "!="
        },
        {
            "value": "<",
            "label": "<"
        },
        {
            "value": "<=",
            "label": "<="
        },
        {
            "value": ">",
            "label": ">"
        },
        {
            "value": ">=",
            "label": ">="
        },
        {
            "value": "BETWEEN",
            "label": "between"
        },
        {
            "value": "NOT BETWEEN",
            "label": "not between"
        },
        {
            "value": "IS EMPTY",
            "label": "is empty"
        },
        {
            "value": "IS NOT EMPTY",
            "label": "is not empty"
        }
    ]
    );

    let [dateOptions] = useState([
        {
            "value": "",
            "label": "None"
        },
        {
            "value": "IS",
            "label": "is"
        },
        {
            "value": "ISN'T",
            "label": "isn't"
        },
        {
            "value": "IS BEFORE",
            "label": "is before"
        },
        {
            "value": "LTE",
            "label": "is after"
        },
        {
            "value": "BETWEEN",
            "label": "between"
        },
        {
            "value": "NOT BETWEEN",
            "label": "not between"
        },
        {
            "value": "TODAY",
            "label": "Today"
        },
        {
            "value": "TOMORROW",
            "label": "Tomorrow"
        },
        {
            "value": "STARTING TOMORROW",
            "label": "Starting tomorrow"
        },
        {
            "value": "YESTERDAY",
            "label": "Yesterday"
        },
        {
            "value": "TILL YESTERDAY",
            "label": "Till Yesterday"
        },
        {
            "value": "LAST MONTH",
            "label": "Last Month"
        },
        {
            "value": "CURRENT MONTH",
            "label": "Current Month"
        },
        {
            "value": "NEXT MONTH",
            "label": "Next Month"
        },
        {
            "value": "LAST WEEK",
            "label": "Last Week"
        },
        {
            "value": "CURRENT WEEK",
            "label": "Current Week"
        },
        {
            "value": "TILL YEAR",
            "label": "Till Year"
        },
        {
            "value": "CURRENT FY",
            "label": "Current FY"
        },
        {
            "value": "CURRENT FQ",
            "label": "Current FQ"
        },
        {
            "value": "LAST YEAR",
            "label": "Last Year"
        },
        {
            "value": "PREVIOUS FY",
            "label": "Previous FY"
        },
        {
            "value": "PREVIOUS FQ",
            "label": "Previous FQ"
        },
        {
            "value": "NEXT YEAR",
            "label": "Next Year"
        },
        {
            "value": "NEXT FY",
            "label": "Next FY"
        },
        {
            "value": "NEXT FQ",
            "label": "Next FQ"
        },
        {
            "value": "AGE IN DAYS",
            "label": "Age in Days"
        },
        {
            "value": "DUE IN DAYS",
            "label": "Due in Days"
        },
        {
            "value": "IS EMPTY",
            "label": "is empty"
        },
        {
            "value": "IS NOT EMPTY",
            "label": "is not empty"
        }
    ]    
    );
    useEffect(() => {
        dispatch(getModuleByIdLookUpStart(moduleId));
    }, [moduleId]);

    useEffect(() => {
        if (getLookModuleData?.lookupModule?.length > 0) {
            let fields = getLookModuleData.lookupModule[0]?.fields;
            let data = [];
            fields?.sections.forEach((section) => {
                let droppedList = section?.dropped_list || {};
                try {
                    Object.values(droppedList).forEach((area) => {
                        area.forEach((item) => {
                            let checkArray = ["text", "email", "currency", "decimal", "number", "select", "date"];
                            if (checkArray.includes(item.input_type)) {
                                data.push(item);
                            }
                        });
                    });
                } catch (error) {
                    // console.log(error)
                }
            });
            setFieldData(data);
        }
    }, [getLookModuleData?.lookupModule]);

    let [lookupRecords, setLookupRecords] = useState(filterLookupRecords);

    let [isFormValid, setIsFormValid] = useState(false);

    let addLookupRecords = () => {
        setLookupRecords((items) => [
            ...items,
            { field_label: "", match: "", value: "" }
        ]);
    };

    let removeLookupRecords = (index) => {
        let data = lookupRecords.filter((_, i) => i !== index);
        setLookupRecords(data);
    };

    let handleInputChange = (index, field, value) => {
        let updatedRecords = lookupRecords.map((item, i) =>
            i === index ? { ...item, [field]: value, ...(field === "match" ? { value: "" } : {}) } : item
        );
        setLookupRecords(updatedRecords);
    };

    let isRowValid = (row) => {
        if (row.match === "IS EMPTY" || row.match === "IS NOT EMPTY") {
            return row.field_label !== "" && row.match !== "";
        }
        return row.field_label !== "" && row.match !== "" && row.value !== "";
    };
    

    let checkInputType = (label) => {
        if (label?.length > 0) {
            return fieldData?.find(x => Helpers.replaceData(x?.extra_fields?.field_label) === Helpers.replaceData(label))?.input_type;
        }
        return "";
    }

    useEffect(() => {
        let allRowsValid = lookupRecords?.every((row) => {
            if (row.match === "IS EMPTY" || row.match === "IS NOT EMPTY") {
                return true;
            }
            return isRowValid(row);
        });
        setIsFormValid(allRowsValid);
    }, [lookupRecords]);
    
    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Lookup Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {lookupRecords?.length > 0 && (
                        lookupRecords.map((record, index) => (
                            <Row key={index} className="mb-3 g-3 align-items-center">
                                <Form.Group as={Col} className="col-5 col-xl" controlId="fieldLabel">
                                    <Form.Select
                                        value={record.field_label}
                                        onChange={(e) => handleInputChange(index, 'field_label', e.target.value)}
                                    >
                                        <option value="">Choose Field</option>
                                        {fieldData?.length > 0 && (
                                            fieldData?.map((x, index2) => (
                                                <option key={index2} value={Helpers.replaceData(x?.extra_fields?.field_label)}>{x?.extra_fields?.field_label}</option>
                                            ))
                                        )}
                                    </Form.Select>
                                </Form.Group>

                                {
                                    (checkInputType(record?.field_label) === "text" || checkInputType(record?.field_label) === "email" || checkInputType(record?.field_label) === "select") ? (
                                        <>
                                            <Form.Group as={Col} className="col-5 col-xl" controlId="match">
                                                <Form.Select
                                                    value={record.match}
                                                    onChange={(e) => handleInputChange(index, 'match', e.target.value)}
                                                >
                                                    {stringOptions?.map((x, index2) => (
                                                        <option key={index2} value={x?.value}>{x?.label}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </>) :
                                        ((checkInputType(record?.field_label) === "currency" || checkInputType(record?.field_label) === "decimal" || checkInputType(record?.field_label) === "number") ?
                                            <>
                                                <Form.Group as={Col} className="col-5 col-xl" controlId="match">
                                                    <Form.Select
                                                        value={record.match}
                                                        onChange={(e) => handleInputChange(index, 'match', e.target.value)}
                                                    >
                                                        {numberOptions?.map((x, index2) => (
                                                            <option key={index2} value={x?.value}>{x?.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </> :
                                            (checkInputType(record?.field_label) === "date") ?
                                                <>
                                                    <Form.Group as={Col} className="col-5 col-xl" controlId="match">
                                                        <Form.Select
                                                            value={record.match}
                                                            onChange={(e) => handleInputChange(index, 'match', e.target.value)}
                                                        >
                                                            {dateOptions?.map((x, index2) => (
                                                                <option key={index2} value={x?.value}>{x?.label}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </> :
                                                <>
                                                    <Form.Group as={Col} className="col-5 col-xl" controlId="match">
                                                        <Form.Select
                                                            value={record.match}
                                                            onChange={(e) => handleInputChange(index, 'match', e.target.value)}
                                                        >
                                                            <option value="">Choose field</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </>
                                        )
                                }

                                {
                                    checkInputType(record?.field_label) === "date" ?
                                        <>
                                            <Form.Group as={Col} className="col-5 col-xl" controlId="valueInput">
                                                <Form.Control
                                                    type="date"
                                                    value={record.value}
                                                    onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                                                />
                                            </Form.Group>
                                        </>
                                        :
                                        (checkInputType(record?.field_label) === "number" ?
                                            <>
                                                <Form.Group as={Col} className="col-5 col-xl" controlId="valueInput">
                                                    <Form.Control
                                                        type="number"
                                                        value={record.value}
                                                        onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </> :
                                            <>
                                                <Form.Group as={Col} className="col-5 col-xl" controlId="valueInput">
                                                    <Form.Control
                                                        type="text"
                                                        value={record?.match === "IS EMPTY" ? '${EMPTY}' : (record?.match === "IS NOT EMPTY" ? '${NOTEMPTY}' : record.value)}
                                                        onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </>)
                                }

                                {lookupRecords.length > 1 && (
                                    <Form.Group as={Col} className="col col-md-1" controlId="removeRowBtn">
                                        <Button variant="primary" className="rounded-pill" type="button" onClick={() => removeLookupRecords(index)}>
                                            <i className="fa-solid fa-minus"></i>
                                        </Button>
                                    </Form.Group>
                                )}

                                {index === lookupRecords.length - 1 && (
                                    <Form.Group as={Col} className="col col-md-1" controlId="addRowBtn">
                                        <Button
                                            variant="primary"
                                            className="rounded-pill"
                                            type="button"
                                            onClick={addLookupRecords}
                                            disabled={!isRowValid(record)}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </Button>
                                    </Form.Group>
                                )}
                            </Row>
                        ))
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => submitData(lookupRecords)}
                    disabled={!isFormValid}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SingleFilterLookupRecords;
