import { call, put } from "redux-saga/effects";
import { createBusinessHoursFailure, createBusinessHoursSuccess, getBusinessHoursFailure, getBusinessHoursSuccess, updateBusinessHoursFailure, updateBusinessHoursSuccess } from "../../../Slices/businessHoursSlice";
import { createBusinessHours, getBusinessHours, updateBusinessHours } from "../../../../Services/apiService";

export function* getBusinessHoursHandler(actions) {
    try {
        const response = yield call(getBusinessHours, actions?.payload);
        yield put(getBusinessHoursSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getBusinessHoursFailure(errResponse))
    }
}

export function* createBusinessHoursHandler(actions) {
    try {
        const response = yield call(createBusinessHours, actions?.payload);
        yield put(createBusinessHoursSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createBusinessHoursFailure(errResponse))
    }
}

export function* updateBusinessHoursHandler(actions) {
    try {
        const response = yield call(updateBusinessHours, actions?.payload);
        yield put(updateBusinessHoursSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateBusinessHoursFailure(errResponse))
    }
}