import React from 'react';
import { Modal } from 'react-bootstrap';

function SendInvitationModal({ show, handleClose, submitData }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Send Invitations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Would you like to send invitations to guests?</span>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={() => submitData(true)}>
                    Send
                </button>
                <button className="btn btn-primary" onClick={() => submitData(false)}>
                    Do Not Send
                </button>
                <button className="btn btn-secondary" onClick={handleClose}>
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default SendInvitationModal;
