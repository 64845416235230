import { call, put } from "redux-saga/effects";
import { createCurrencyFailure, createCurrencySuccess, getCurrencyFailure, getCurrencySuccess, updateCurrencyFailure, updateCurrencySuccess } from "../../../Slices/currencySlice";
import { createCurrency, getCurrency, updateCurrency } from "../../../../Services/apiService";

export function* getCurrencyHandler(actions) {
    try {
        const response = yield call(getCurrency, actions?.payload);
        yield put(getCurrencySuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getCurrencyFailure(errResponse))
    }
}

export function* createCurrencyHandler(actions) {
    try {
        const response = yield call(createCurrency, actions?.payload);
        yield put(createCurrencySuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createCurrencyFailure(errResponse))
    }
}

export function* updateCurrencyHandler(actions) {
    try {
        const response = yield call(updateCurrency, actions?.payload);
        yield put(updateCurrencySuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateCurrencyFailure(errResponse))
    }
}