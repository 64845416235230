import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AllChartModel } from "../../../Components/ModalPopup/ChartComponents";

function ChooseChartModal({ show, handleClose, submitData, allList = [] }) {
    let [OpenChartModel, setOpenChartModel] = useState(null);
    let [showModal, setShowModal] = useState(false);

    let onClickOpenChartComponent = (key) => {
        let ChartComponent = AllChartModel[key];
        if (ChartComponent) {
            setOpenChartModel(() => ChartComponent);
            setShowModal(true);
        } else { }
    }

    return (
        <>
            <Modal show={show && !showModal} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Choose KPI Style</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mt-4">
                        <div className="row g-3">
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3" onClick={() => onClickOpenChartComponent("ChartModel")}>
                                    <i className="fas fa-chart-bar me-2"></i> Chart
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3" onClick={() => onClickOpenChartComponent("KpiModel")}>
                                    <i className="fas fa-chart-line me-2"></i> KPI
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3" onClick={() => onClickOpenChartComponent("Table")}>
                                    <i className="fas fa-table me-2"></i> Table
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3">
                                    <i className="fas fa-exchange-alt me-2"></i> Comparator
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3">
                                    <i className="fas fa-exclamation-triangle me-2"></i> Anomaly Detector
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3" onClick={() => onClickOpenChartComponent("TargetMeter")}>
                                    <i className="fas fa-bullseye me-2"></i> Target Meter
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3">
                                    <i className="fas fa-filter me-2"></i> Funnel
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3">
                                    <i className="fas fa-layer-group me-2"></i> Cohort
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3">
                                    <i className="fas fa-th-large me-2"></i> Quadrant
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3">
                                    <i className="fas fa-wifi me-2"></i> Zone
                                </button>
                            </div>
                            <div className="col-6 position-relative">
                                <button className="btn btn-light w-100 d-flex align-items-center justify-content-center p-3">
                                    <i className="fas fa-columns me-2"></i> Stage
                                </button>
                                <span className="badge bg-danger position-absolute top-0 end-0 translate-middle">NEW</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <button className="btn btn-outline-secondary d-flex align-items-center justify-content-center">
                                <i className="fas fa-folder-plus me-2"></i> Pick from Gallery
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {(showModal && OpenChartModel) && <OpenChartModel show={showModal} handleClose={() => {
                setShowModal(false);
                setOpenChartModel(null);
            }} submitData={(datas) => {
                submitData(datas);
                handleClose();
            }} allList={allList}/>}
        </>
    );
}

export default ChooseChartModal;
