import { Navigate, useLocation } from "react-router-dom";
import { getItem } from '../Services/localService';
import PermissionDeniedModal from "../Components/PermissionDeniedModal";
import { Utils } from "../Utils";
 
const ProtectedRoutes = ({ children }) => {
    let isToken = getItem('token');
    let location = useLocation();

    if (isToken && location.pathname.includes('/form-module/sub-form/')) {
        const basicPermissionData = getItem('modulePermission')?.basic;
        if (basicPermissionData) {
            let moduleId = location.pathname.split('/')[4];
            if (moduleId == 'create') {
                moduleId = location.pathname.split('/')[5];
                let response = basicPermissionData?.some(x => x?.id === moduleId && x.activities.create === true);
                if (!response) {
                    return <PermissionDeniedModal />
                }
            }
            if (moduleId == 'update') {
                moduleId = location.pathname.split('/')[5];
                let response = basicPermissionData?.filter((data) => data.id === moduleId && data.activities.edit === true);
                if (response.length === 0) {
                    return <PermissionDeniedModal />
                }
            }
            if (moduleId !== 'create' && moduleId !== 'update') {
                let response = basicPermissionData?.filter((data) => data.id === moduleId && data.activities.view === true);
                if (response.length === 0) {
                    return <PermissionDeniedModal />
                }
            }
        }
    }
 
    if (isToken && (location.pathname === '/' || location.pathname === '/auth/login' || location.pathname === '/auth/signup')) {
        return <Navigate to={Utils.getNavigationUrl(`dashboard`)}/>
    } else if (!isToken) {
        // return <Navigate to="/" />
        return <Navigate to="/auth/login" />
    }
 
    return children;
}
export default ProtectedRoutes