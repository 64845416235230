import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getItem } from "../../../Services/localService";
import ConfirmationModal from "../ConfirmationModal";

const MutiSelectDropDown = ({ handleInputChange, options, default_value, resetFlg = false, singleSelect = false, isSingleUser = false, isUser = false, needConfirmation = false }) => {
    let [selectedOptions, setSelectedOptions] = useState([]);
    let [optionsList, setOptionsList] = useState([]);
    let [openModal, setOpenModal] = useState([]);

    useEffect(() => {
        if (options?.length > 0) {
            setOptionsList(options?.map(x => ((x?._id?.length && isUser) > 0 ? {
                _id: x?._id,
                name: `${x?.firstName || ''} ${x?.lastName || ''}`,
                profilePicture: x?.profilePicture,
                email: x?.email
            } : x)));
        }
    }, [options]);
    
    useEffect(() => {
        if (default_value && (default_value.length > 0 || Object.keys(default_value)?.length > 0) && default_value !== "None" && optionsList?.length > 0) {
            setSelectedOptions(default_value);
        } else if(isSingleUser && !default_value){
            let userDatas = {_id : getItem('user')?._id, name : `${getItem('user')?.firstName || ''} ${getItem('user')?.lastName || ''}`};
            setSelectedOptions(userDatas);
            handleInputChange({
                target: { value: userDatas }
            });
        }
    }, [default_value, optionsList]);

    useEffect(() => {
        if (resetFlg) {
            setSelectedOptions([]);
        }
    }, [resetFlg]);

    let onChange = (selectedList) => {
        if (singleSelect) {
            setSelectedOptions({ _id: selectedList?._id, name : selectedList?.name });
            handleInputChange({
                target: { value: { _id: selectedList?._id, name : selectedList?.name } }
            });
        } else {
            let addedOption = selectedList?.find(
                (option) => !selectedOptions.includes(option)
            );
            if (needConfirmation && addedOption) {
                setOpenModal(selectedList);
            } else {
                setSelectedOptions(selectedList || []);
                handleInputChange({
                    target: { value: selectedList?.map(x => ({ ...(x?._id?.length > 0 ? { _id: x?._id } : {}), name: x?.name })) || [] }
                });
            }
        }
    };

    let CustomOption = ({ data, innerRef, innerProps }) => (
        data.email ?
            <>
                <div ref={innerRef} {...innerProps} className="d-flex align-items-center p-2">
                    <img
                        src={data.profilePicture}
                        alt={data.name}
                        className="rounded-circle me-2"
                        style={{ width: '30px', height: '30px' }}
                    />
                    <div>
                        <div>{data.name}</div>
                        <small className="text-muted">{data.email}</small>
                    </div>
                </div>
            </> :
            <>
                <div ref={innerRef} {...innerProps} className="d-flex align-items-center p-2">
                    <span>{data.name}</span>
                </div>
            </>
    );

    let customStyles = {
        multiValue: (provided) => ({
            ...provided,
            borderRadius: "5px",
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999
        })
    };

    let setValue = (confirm) => {
        let addedOption = openModal?.find(
            (option) => !selectedOptions.includes(option)
        );
        let slectedData = [...selectedOptions, { _id: addedOption?._id, name: addedOption?.name, need_confirmation: confirm }]
        setSelectedOptions(slectedData);
        handleInputChange({
            target: { value: slectedData?.map(x => ({ _id: x?._id, name: x?.name, need_confirmation: x?.need_confirmation || false })) }
        });
        setOpenModal([]);
    }

    let alertInfo = () => {
        let addedOption = openModal?.find(
            (option) => !selectedOptions.includes(option)
        );
        return {
            title: "Convert",
            content: `Do you want to need confirm for convert "${addedOption?.name}"?`,
            btnDeclineTitle: "No",
            buttonTitle: "Yes",
        };
    };
    return (
        <>
            <Select
                options={optionsList}
                isMulti={!singleSelect}
                value={selectedOptions}
                onChange={onChange}
                placeholder="Select Options"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => (option?._id?.length > 0 ? option?._id : option.name)}
                components={{ Option: CustomOption }}
                styles={customStyles}
                className="multiSelectContainer"
                classNamePrefix="select"
                closeMenuOnSelect={singleSelect}
            />
            { openModal?.length > 0 && <ConfirmationModal show={openModal?.length > 0} handleClose={() => {
                setValue(false);
            }} handleSuccess={() => {
                setValue(true);
            }} alertInfo={alertInfo()}/>}
        </>
    );
};

export default MutiSelectDropDown;
