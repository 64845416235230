import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    user: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    allCurrencies: null,
    createStatus: null,
    updateStatus: null,
    permissions: {
        isLoading: false,
        data: null,
        message: null,
        error: null,
        totalCount: null
    }
}

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        getCurrencyStart: (state) => {
            state.isLoading = true;
            state.reload = null;
        },
        getCurrencySuccess: (state, action) => {
            state.data = action?.payload?.Currencies;
            state.allCurrencies = action?.payload?.all_currencies_list;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        getCurrencyFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        createCurrencyStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
        },
        createCurrencySuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        createCurrencyFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        updateCurrencyStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.updateStatus = null;
        },
        updateCurrencySuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updateCurrencyFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        }
    }
});
export const {
    getCurrencyStart,
    getCurrencySuccess,
    getCurrencyFailure,
    createCurrencyStart,
    createCurrencySuccess,
    createCurrencyFailure,
    updateCurrencyStart,
    updateCurrencySuccess,
    updateCurrencyFailure
} = currencySlice?.actions;

export default currencySlice?.reducer;