import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
    name: 'form',
    initialState: {
        isLoading: false,
        forms: [],
        navbarList: [],
        lookupModule:[],
        allModules:[],
        tableData:[],
        rules:[],
        convert_modules:[],
        convertData: null,
        multiConvertData:null,
        isManageModuleApiError:false,
        isManageModuleApiCalled : false,
        totalCount: 0,
        exit: false,
        form: null,
        isFormEmpty: false,
        formFields: null,
        generatedLink: null,
        submissions: null,
        submission: null,
        error: false,
        message: null,
        successStatus: null,
        reload : false,
        messageConvert: null,
        errorConvert: null,
    },
    reducers: {
        getFormListStart: (state) => {
            state.isLoading = true;
        },
        getFormListSuccess: (state, action) => {
            state.forms = action?.payload?.data;
            state.isFormEmpty = !!action?.payload?.data?.length;
            state.isLoading = false;
            state.exit = false;
        },
        getFormListFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        createFormsListStart: (state) => {
            state.isLoading = true;
        },
        createFormsListSuccess: (state, action) => {
            state.forms = action?.payload?.data;
            state.isFormEmpty = !!action?.payload?.data?.length;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.exit = true;
            state.newCreated = true;
            state.isManageModuleApiCalled = false;
        },
        createFormsListFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getAllFormModuleListStart: (state) => {
            state.isLoading = true;
        },
        getAllFormModuleListSuccess: (state, action) => {
            state.allModules = action?.payload?.modules;
            state.isLoading = false;
            state.exit = false;
        },
        getAllFormModuleListFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getFormModuleListStart: (state) => {
            state.isLoading = true;
        },
        getFormModuleListSuccess: (state, action) => {
            state.modules = action?.payload?.modules;
            state.totalCount = action?.payload?.totalCount;
            state.isFormEmpty = !!action?.payload?.modules?.length;
            state.isLoading = false;
            state.exit = false;
        },
        getFormModuleListFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getModuleByIdStart: (state) => {
            state.isLoading = true;
        },
        getModuleByIdSuccess: (state, action) => {
            state.modules = action?.payload?.modules;
            state.isFormEmpty = !!action?.payload?.modules?.length;
            state.isLoading = false;
            state.exit = false;
        },
        getModuleByIdFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getModuleByIdLookUpStart: () => { },
        getModuleByIdLookUpSuccess: (state, action) => {
            state.lookupModule = action?.payload?.modules;
            state.exit = false;
        },
        getModuleByIdLookUpFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload;
        },
        updateModuleByIdStart: (state, action) => {
            state.isLoading = true;
        },
        updateModuleByIdSuccess: (state, action) => {
            let updateModules = action?.payload?.updateModules;
            state.tableData = [...(state?.tableData || [])]?.map((x) => x?._id === updateModules?._id ? {...x, is_default : updateModules?.is_default} : x);
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.exit = true;
            state.reload = true;
        },
        updateModuleByIdFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        removeModuleByIdStart: (state, action) => {
            state.isLoading = true;
        },
        removeModuleByIdSuccess: (state, action) => {
            state.reload = true;
            state.isLoading = false;
        },
        removeModuleByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        getAllFormModuleStart: (state) => {
            state.isLoading = true;
        },
        getAllFormModuleSuccess: (state, action) => {
            state.navbarList = action?.payload?.modules?.map(x => ({...x, show : true}));
            state.isLoading = false; 
            state.exit = false;
        },
        getAllFormModuleFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getAllFormModuleTableDataStart: (state) => {
            state.isLoading = true;
        },
        getAllFormModuleTableDataSuccess: (state, action) => {
            state.tableData = action?.payload?.modules;
            state.reload = false; 
            state.isLoading = false; 
            state.exit = false;
        },
        getAllFormModuleTableDataFailure: (state, action) => {
            state.error = true;
            state.message = action?.payload;
            state.isLoading = false;
        },
        getManageModulesStart: (state) => {
            state.isLoading = true;
        },
        getManageModulesSuccess: (state, action) => {
            state.isManageModuleApiError = false;
            state.isManageModuleApiCalled = true;
            state.navbarList = action.payload.manageModules?.[0]?.data;
            state.manageModuleId = action.payload.manageModules?.[0]?._id;
            state.message = action.payload.message; 
            state.isLoading = false;
        },
        getManageModulesFailure: (state, action) => {
            state.isManageModuleApiError = true;
            state.error = action?.payload?.message || "Error";
            state.isLoading = false;
        },
        createManageModuleStart: (state) => {
            state.isLoading = true;
        },
        createManageModuleSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        createManageModuleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        updateManageModuleStart: (state) => {
            state.isLoading = true;
        },
        updateManageModuleSuccess: (state, action) => {
            state.navbarList = action.payload.updateManageModules?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateManageModuleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        convertSelectedModuleListStart: (state) => {
            state.isLoading = true;
        },
        convertSelectedModuleListSuccess: (state, action) => {
            state.rules = action?.payload?.rules;
            state.message = action?.payload;
            state.isLoading = false;
        },
        convertSelectedModuleListFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        getConvertModuleListStart: (state) => {
            state.isLoading = true;
        },
        getConvertModuleListSuccess: (state, action) => {
            state.convert_modules = action?.payload?.convert_modules;
            state.message = action?.payload;
            state.isLoading = false;
        },
        getConvertModuleListFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        createConvertModuleStart: (state) => {
            state.isLoading = true;
        },
        createConvertModuleSuccess: (state, action) => {
            state.messageConvert = action?.payload?.message;
            state.isLoading = false;
        },
        createConvertModuleFailure: (state, action) => {
            state.errorConvert = action?.payload;
            state.isLoading = false;
        },
        getConvertModuleStart: (state) => {
            state.isLoading = true;
        },
        getConvertModuleSuccess: (state, action) => {
            let array = action?.payload?.convertModules || [];
            let ruleId = state.rules?.[0]?._id;
            if(array?.length > 0) {
                state.convertData = action?.payload?.convertModules?.find(x => x?.workflow_id === ruleId);
            }
            state.message = action?.payload;
            state.isLoading = false;
        },
        getConvertModuleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        getMultiConvertModuleStart: (state) => {
            state.isLoading = true;
        },
        getMultiConvertModuleSuccess: (state, action) => {
            let payload = action?.payload;
            if (payload?.convertModules?.length > 0) {
                state.multiConvertData = {
                    ...state.multiConvertData,
                    [payload?.moduleId]: payload?.convertModules[payload?.convertModules?.length - 1]
                };
            } else {
                state.multiConvertData = {
                    ...state.multiConvertData,
                    [payload?.moduleId]: null
                };
            }
            state.message = payload?.message;
            state.isLoading = false;
        },
        getMultiConvertModuleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        getByIDConvertModuleStart: (state) => {
            state.isLoading = true;
        },
        getByIDConvertModuleSuccess: (state, action) => {
            state.message = action?.payload;
            state.isLoading = false;
        },
        getByIDConvertModuleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        updateConvertModuleStart: (state) => {
            state.isLoading = true;
        },
        updateConvertModuleSuccess: (state, action) => {
            state.convertData = action?.payload?.updateConvertModules;
            state.messageConvert = action?.payload?.message;
            state.isLoading = false;
        },
        updateConvertModuleFailure: (state, action) => {
            state.errorConvert = action?.payload;
            state.isLoading = false;
        },
        resetState: (state) => {
            state.modules = [];
            state.reload = false;
            state.newCreated = false;
            state.isManageModuleApiError = false;
            state.convert_modules = [];
            state.rules = [];
            state.convertData = null;
        },
        destoryMsgAndError : (state) => {
            state.messageConvert = null;
            state.errorConvert = null;
        },
        resetReloadState: (state) => {
            state.reload = false;
        }
    }
});

export const {
    getAllFormModuleStart,
    getAllFormModuleSuccess,
    getAllFormModuleFailure,
    getAllFormModuleTableDataStart,
    getAllFormModuleTableDataSuccess,
    getAllFormModuleTableDataFailure,
    getFormListStart,
    getFormListSuccess,
    getFormListFailure,
    createFormsListStart,
    createFormsListSuccess,
    createFormsListFailure,
    getAllFormModuleListStart,
    getAllFormModuleListSuccess,
    getAllFormModuleListFailure,
    getFormModuleListStart,
    getFormModuleListSuccess,
    getFormModuleListFailure,
    getModuleByIdStart,
    getModuleByIdSuccess,
    getModuleByIdFailure,
    getModuleByIdLookUpStart,
    getModuleByIdLookUpSuccess,
    getModuleByIdLookUpFailure,
    updateModuleByIdStart,
    updateModuleByIdSuccess,
    updateModuleByIdFailure,
    removeModuleByIdStart,
    removeModuleByIdSuccess,
    removeModuleByIdFailure,
    getManageModulesStart,
    getManageModulesSuccess,
    getManageModulesFailure,
    createManageModuleStart,
    createManageModuleSuccess,
    createManageModuleFailure,
    updateManageModuleStart,
    updateManageModuleSuccess,
    updateManageModuleFailure,
    convertSelectedModuleListStart,
    convertSelectedModuleListSuccess,
    convertSelectedModuleListFailure,
    getConvertModuleListStart,
    getConvertModuleListSuccess,
    getConvertModuleListFailure,
    createConvertModuleStart,
    createConvertModuleSuccess,
    createConvertModuleFailure,
    getConvertModuleStart,
    getConvertModuleSuccess,
    getConvertModuleFailure,
    getMultiConvertModuleStart,
    getMultiConvertModuleSuccess,
    getMultiConvertModuleFailure,
    getByIDConvertModuleStart,
    getByIDConvertModuleSuccess,
    getByIDConvertModuleFailure,
    updateConvertModuleStart,
    updateConvertModuleSuccess,
    updateConvertModuleFailure,
    resetState,
    destoryMsgAndError,
    resetReloadState
} = formSlice?.actions;

export default formSlice?.reducer;