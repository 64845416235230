import React, { useEffect, useState } from "react";
import digyLogo from '../../../Assets/images/digylax-logo.png';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../../../Components/Toast";

const InvalidAccount = () => {
    const location = useLocation();
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const _error = queryParams.get('error');
        if (_error) {
            setErrorMsg(_error)
            toast.success(_error, {
                autoClose: 1000,
            })
        }
    }, []);

    return (
        <React.Fragment>
            {errorMsg != '' && <Toast />}
            <div className="d-flex flex-column flex-root h-100vh">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid auth-page auth-v1">
                    <div className="d-flex flex-column flex-lg-row-fluid order-2 order-lg-1 auth-l-box">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            <div className="w-lg-100">
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-logo" width="174" height="32" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Oops </h1>
                                    <p className="mt-4 mb-0 fw-semibold">{errorMsg}</p>
                                    <p className="text-gray-500 fw-semibold fs-6 d-block pt-3">
                                        Click here to<a onClick={() => navigate("/auth/signup")} className="ps-2 fw-semibold cursor-pointer">Sign up</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default InvalidAccount;