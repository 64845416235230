import React from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import frame from '../../Assets/img/frame.jpg';
import macbook from '../../Assets/img/macbook.jpg';
import '../../Assets/css/auth.css';

const AuthLayout = () => {
    return (
        <React.Fragment>
            <Outlet />
        </React.Fragment>
    )
}
export default AuthLayout;