import { takeEvery } from "redux-saga/effects";
import { forgotPasswordStart, loginStart, sendOtpStart, verifyEmailStart, verifyOtpStart } from "../Slices/authSlice";
import { checkExistUserHandler, forgotPasswordHandler, loginHandler, registerHandler, sendOtpHandler, userRegisterHandler, verifyEmailHandler, verifyOtpHandler } from "./handlers/authSaga";
import { registerStart, userExistStart, userRegisterStart } from "../Slices/registerSlice";
import { getDashboardGraphStart, getDashboardStart } from "../Slices/dashboardSlice";
import { getDashboardGraphHandler, getDashboardHandler } from "./handlers/dashboardSaga";
import { getManageModulesStart, createManageModuleStart, updateManageModuleStart, removeModuleByIdStart, getAllFormModuleStart, getFormListStart, createFormsListStart, getFormModuleListStart, getModuleByIdStart, getModuleByIdLookUpStart, updateModuleByIdStart, getAllFormModuleListStart, getAllFormModuleTableDataStart, convertSelectedModuleListStart, getConvertModuleListStart, createConvertModuleStart, getConvertModuleStart, getByIDConvertModuleStart, updateConvertModuleStart, getMultiConvertModuleStart } from "../Slices/formSlice";
import { getManageModulesHandler, createManageModuleHandler, updateManageModuleHandler, removeModuleModuleHandler, getAllFormModule, getFormsList, createFormsHandler, getModuleByIdHandler, getModuleByIdLookUpHandler, getFormModuleListHandler, updateModuleByIdHandler, getAllFormModuleListHandler, getAllFormModuleTableDataHandler, convertSelectedModuleListHandler, getConvertModuleListHandler, createConvertModuleHandler, getConvertModuleHandler, getByIDConvertModuleHandler, updateConvertModuleHandler, getMultiConvertModuleHandler } from "./handlers/formSaga";
import { getSubFormModuleCreatedRecordsStart, getSubFormModuleGraphicalStatusStart, createTableColumnSubFormModuleStart, updateTableColumnSubFormModuleStart, bulkUploadSubFormByIdStart, removeSubFormByIdStart, createSubFormStart, getSubFormListStart, getSubFormListByIdStart, updateSubFormByIdStart, convertSubformDataStart, convertedSubformDataStart, subFormModuleLogStart, createSubFormCustomViewStart, getAllSubFormCustomViewStart, getByIdSubFormCustomViewStart, updateSubFormCustomViewStart } from "../Slices/subFormSlice";
import { getSubFormModuleCreatedRecordsHandler, getSubFormModuleGraphicalStatusHandler, createTableColumnSubFormModuleHandler, updateTableColumnSubFormModuleHandler, bulkUploadSubFormModuleHandler, removeSubFormModuleHandler, getSubFormModuleListHandler, getSubFormModuleListByIdHandler, updateSubFormModuleByIdHandler, createSubFormModuleHandler, convertSubformDataHandler, convertedSubformDataHandler, subFormModuleLogHandler, createSubFormCustomViewHandler, getAllSubFormCustomViewHandler, getByIdSubFormCustomViewHandler, updateSubFormCustomViewHandler } from "./handlers/subFromSaga";
import { changePasswordStart, deleteUserStart, getAllUserDataStart, getAllUserListStart, getAllUserTypeListStart, getUserByIdStart, getUserListStart, updateUserStart, uploadUserProfileStart } from "../Slices/userSlice";
import { changePasswordHandler, deleteUserHandler, getAllUserDataHandler, getAllUserListHandler, getAllUserTypeListHandler, getUserByIdHandler, getUserListHandler, updateUserHandler, uploadUserProfileHandler } from "./handlers/userSaga";
import { createWorkflowStart, getApprovalByIdStart, getApprovalStart, getEmailTemplateStart, getWorkflowByIdStart, getWorkflowModuleStart, getWorkflowStart, removeWorkflowByIdStart, updateApprovalStatusStart, updateRejectStatusStart, updateWorkflowStart, uploadImageStart } from "../Slices/workflowSlice";
import { createWorkflowHandler, getApprovalByIdHandler, getApprovalHandler, getEmailTemplateHandler, getWorkflowByIdHandler, getWorkflowHandler, getWorkflowModuleHandler, removeWorkflowModuleHandler, updateApprovalStatusHandler, updateRejectStatusHandler, updateWorkflowHandler, uploadImageHandler } from "./handlers/workflowSaga";
import { existMaterialWebhookStart, listRefMaterialWebhookStart, createWebhookStart, getWebhookListByModuleIdStart, getWebhookListByIdStart, updateWebhookByIdStart, removeWebhookByIdStart } from "../Slices/webhookSlice";
import { existMaterialWebhookHandler, listRefMaterialWebhookHandler, createWebhookHandler, getWebhookListByModuleIdHandler, getWebhookListByIdHandler, updateWebhookByIdHandler, removeWebhookByIdHandler } from "./handlers/WebhooksSaga";
import { getVariantConfigHandler, createVariantConfigHandler, deleteVariantConfigByIdHandler, updateVariantConfigByIdHandler } from "../Saga/handlers/variantConfigSaga";
import { getVariantConfigStart, createVariantConfigStart, deleteVariantConfigByIdStart, updateVariantConfigByIdStart } from "../Slices/variantConfigSlice";
import { createCompanyStart, getCompanyListStart, updateCompanyStart, uploadCompanyProfileStart } from "../Slices/organizationSlice";
import { createComapanyHandler, getCompanyListHandler, updateCompanyHandler, uploadCompanyProfileHandler } from "./handlers/orgSaga";
import { createFiscalYearStart, getFiscalYearStart, updateFiscalYearStart } from "../Slices/fiscalYearSlice";
import { createFiscalYearHandler, getFiscalYearHandler, updateFiscalYearHandler } from "./handlers/fiscalYearSaga";
import { createBusinessHoursStart, getBusinessHoursStart, updateBusinessHoursStart } from "../Slices/businessHoursSlice";
import { createBusinessHoursHandler, getBusinessHoursHandler, updateBusinessHoursHandler } from "./handlers/businessHoursSaga";
import { createShiftHoursStart, deleteShiftHoursStart, getShiftHoursStart, updateShiftHoursStart } from "../Slices/shiftHoursSlice";
import { createShiftHoursHandler, deleteShiftHoursHandler, getShiftHoursHandler, updateShiftHoursHandler } from "./handlers/shiftHoursSaga";
import { createCurrencyStart, getCurrencyStart, updateCurrencyStart } from "../Slices/currencySlice";
import { createCurrencyHandler, getCurrencyHandler, updateCurrencyHandler } from "./handlers/currencySaga";
import { createSubCurrencyStart, updateSubCurrencyStart } from "../Slices/subCurrencySlice";
import { createSubCurrencyHandler, updateSubCurrencyHandler } from "./handlers/subCurrencySaga";
import { createDashboardComponentHandler, deleteDashboardComponentHandler, getAllDashboardComponentHandler, getAllSubFormModulesHandler, getByIdDashboardComponentHandler, getDashboardModuleByIdHandler, updateDashboardComponentHandler } from "./handlers/DashboardComponentsSaga";
import { createDashboardComponentLayoutHandler, deleteDashboardComponentLayoutHandler, getAllDashboardComponentLayoutHandler, getByIdDashboardComponentLayoutHandler, updateDashboardComponentLayoutHandler } from "./handlers/DashboardComponentsSaga";
import { createDashboardComponentStart, deleteDashboardComponentStart, getAllDashboardComponentStart, getAllSubFormModulesStart, getByIdDashboardComponentStart, getDashboardModuleByIdStart, updateDashboardComponentStart } from "../Slices/dashboardComponentsSlice";
import { createDashboardComponentLayoutStart, deleteDashboardComponentLayoutStart, getAllDashboardComponentLayoutStart, getByIdDashboardComponentLayoutStart, updateDashboardComponentLayoutStart } from "../Slices/dashboardComponentsSlice";
import { createProfileStart, deleteProfileStart, deleteToTransferProfileStart, getProfileByIdStart, getProfileStart, updatePermissionStart } from "../Slices/profileSlice";
import { createProfileHandler, deleteProfileHandler, deleteToTransferProfileHandler, getProfileByIdHandler, getProfilesHandler, updatePermissionHandler } from "./handlers/profileSaga";
import { createRoleStart, deleteRoleStart, deleteToTransferRoleStart, getRoleStart, updateRoleStart } from "../Slices/roleSlice";
import { createRoleHandler, deleteRoleHandler, deleteToTransferRoleHandler, getRolesHandler, updateRoleHandler } from "./handlers/roleSaga";
import { getLoginHistoryStart, getUserLoginHistoryStart } from "../Slices/loginHistorySlice";
import { getLoginHistoryHandler, getUserLoginHistoryHandler } from "./handlers/loginHistorySaga";
import { getAuditLogsStart } from "../Slices/auditLogSlice";
import { auditLogHandler } from "./handlers/auditLogSaga";

export function* watcherSaga() {
    // Auth
    yield takeEvery(loginStart, loginHandler);
    yield takeEvery(registerStart, registerHandler);
    yield takeEvery(userRegisterStart, userRegisterHandler);
    yield takeEvery(userExistStart, checkExistUserHandler);
    yield takeEvery(verifyEmailStart, verifyEmailHandler);
    yield takeEvery(sendOtpStart, sendOtpHandler);
    yield takeEvery(verifyOtpStart, verifyOtpHandler);
    yield takeEvery(forgotPasswordStart, forgotPasswordHandler)

    // Dashboard
    yield takeEvery(getDashboardStart, getDashboardHandler);
    // Dashboard Graphs
    yield takeEvery(getDashboardGraphStart, getDashboardGraphHandler);
    // Forms
    yield takeEvery(getAllFormModuleStart, getAllFormModule);
    yield takeEvery(getFormListStart, getFormsList);
    yield takeEvery(createFormsListStart, createFormsHandler);
    yield takeEvery(getFormModuleListStart, getFormModuleListHandler);
    yield takeEvery(getAllFormModuleListStart, getAllFormModuleListHandler);
    yield takeEvery(updateModuleByIdStart, updateModuleByIdHandler);
    yield takeEvery(getModuleByIdStart, getModuleByIdHandler);
    yield takeEvery(getModuleByIdLookUpStart, getModuleByIdLookUpHandler);
    yield takeEvery(removeModuleByIdStart, removeModuleModuleHandler);
    yield takeEvery(getManageModulesStart, getManageModulesHandler);
    yield takeEvery(createManageModuleStart, createManageModuleHandler);
    yield takeEvery(updateManageModuleStart, updateManageModuleHandler);
    yield takeEvery(getAllFormModuleTableDataStart, getAllFormModuleTableDataHandler);
    yield takeEvery(convertSelectedModuleListStart, convertSelectedModuleListHandler);
    yield takeEvery(getConvertModuleListStart, getConvertModuleListHandler);
    yield takeEvery(createConvertModuleStart, createConvertModuleHandler);
    yield takeEvery(getConvertModuleStart, getConvertModuleHandler);
    yield takeEvery(getMultiConvertModuleStart, getMultiConvertModuleHandler);
    yield takeEvery(getByIDConvertModuleStart, getByIDConvertModuleHandler);
    yield takeEvery(updateConvertModuleStart, updateConvertModuleHandler);
    // SubForms
    yield takeEvery(createSubFormStart, createSubFormModuleHandler);
    yield takeEvery(getSubFormListStart, getSubFormModuleListHandler);
    yield takeEvery(updateSubFormByIdStart, updateSubFormModuleByIdHandler);
    yield takeEvery(getSubFormListByIdStart, getSubFormModuleListByIdHandler);
    yield takeEvery(removeSubFormByIdStart, removeSubFormModuleHandler);
    yield takeEvery(bulkUploadSubFormByIdStart, bulkUploadSubFormModuleHandler);
    yield takeEvery(createTableColumnSubFormModuleStart, createTableColumnSubFormModuleHandler);
    yield takeEvery(updateTableColumnSubFormModuleStart, updateTableColumnSubFormModuleHandler);
    yield takeEvery(getSubFormModuleCreatedRecordsStart, getSubFormModuleCreatedRecordsHandler);
    yield takeEvery(getSubFormModuleGraphicalStatusStart, getSubFormModuleGraphicalStatusHandler);
    yield takeEvery(convertSubformDataStart, convertSubformDataHandler);
    yield takeEvery(convertedSubformDataStart, convertedSubformDataHandler);
    yield takeEvery(subFormModuleLogStart, subFormModuleLogHandler);
    yield takeEvery(createSubFormCustomViewStart, createSubFormCustomViewHandler);
    yield takeEvery(getAllSubFormCustomViewStart, getAllSubFormCustomViewHandler);
    yield takeEvery(getByIdSubFormCustomViewStart, getByIdSubFormCustomViewHandler);
    yield takeEvery(updateSubFormCustomViewStart, updateSubFormCustomViewHandler);
    // Users
    yield takeEvery(getUserListStart, getUserListHandler);
    yield takeEvery(getUserByIdStart, getUserByIdHandler);
    yield takeEvery(updateUserStart, updateUserHandler);
    yield takeEvery(deleteUserStart, deleteUserHandler);
    yield takeEvery(uploadUserProfileStart, uploadUserProfileHandler);
    yield takeEvery(getAllUserDataStart, getAllUserDataHandler);
    yield takeEvery(getAllUserTypeListStart, getAllUserTypeListHandler);
    // org
    yield takeEvery(uploadCompanyProfileStart, uploadCompanyProfileHandler);
    yield takeEvery(updateCompanyStart, updateCompanyHandler);
    yield takeEvery(getCompanyListStart, getCompanyListHandler);
    yield takeEvery(createCompanyStart, createComapanyHandler);
    // fiscal
    yield takeEvery(getFiscalYearStart, getFiscalYearHandler);
    yield takeEvery(createFiscalYearStart, createFiscalYearHandler);
    yield takeEvery(updateFiscalYearStart, updateFiscalYearHandler);
    // business hours
    yield takeEvery(getBusinessHoursStart, getBusinessHoursHandler);
    yield takeEvery(createBusinessHoursStart, createBusinessHoursHandler);
    yield takeEvery(updateBusinessHoursStart, updateBusinessHoursHandler);
    // shift hours
    yield takeEvery(getShiftHoursStart, getShiftHoursHandler);
    yield takeEvery(createShiftHoursStart, createShiftHoursHandler);
    yield takeEvery(updateShiftHoursStart, updateShiftHoursHandler);
    yield takeEvery(deleteShiftHoursStart, deleteShiftHoursHandler);
    // currency
    yield takeEvery(getCurrencyStart, getCurrencyHandler);
    yield takeEvery(createCurrencyStart, createCurrencyHandler);
    yield takeEvery(updateCurrencyStart, updateCurrencyHandler);
    // sub currency
    yield takeEvery(createSubCurrencyStart, createSubCurrencyHandler);
    yield takeEvery(updateSubCurrencyStart, updateSubCurrencyHandler);
    // profile
    yield takeEvery(getProfileStart, getProfilesHandler);
    yield takeEvery(getProfileByIdStart, getProfileByIdHandler);
    yield takeEvery(createProfileStart, createProfileHandler);
    yield takeEvery(updatePermissionStart, updatePermissionHandler);
    yield takeEvery(deleteProfileStart, deleteProfileHandler);
    yield takeEvery(deleteToTransferProfileStart, deleteToTransferProfileHandler);
    // roles and sharing
    yield takeEvery(getRoleStart, getRolesHandler);
    yield takeEvery(createRoleStart, createRoleHandler);
    yield takeEvery(updateRoleStart, updateRoleHandler);
    yield takeEvery(deleteRoleStart, deleteRoleHandler);
    yield takeEvery(deleteToTransferRoleStart, deleteToTransferRoleHandler);
    // All users
    yield takeEvery(getAllUserListStart, getAllUserListHandler);
    // Create workflow
    yield takeEvery(createWorkflowStart, createWorkflowHandler);
    // Get workflow
    yield takeEvery(getWorkflowStart, getWorkflowHandler);
    // Get workflow by id
    yield takeEvery(getWorkflowByIdStart, getWorkflowByIdHandler);
    // updateworkflow
    yield takeEvery(updateWorkflowStart, updateWorkflowHandler);
    yield takeEvery(getWorkflowModuleStart, getWorkflowModuleHandler);
    yield takeEvery(getEmailTemplateStart, getEmailTemplateHandler);
    yield takeEvery(uploadImageStart, uploadImageHandler);
    // Get approval list
    yield takeEvery(getApprovalStart, getApprovalHandler);
    yield takeEvery(getApprovalByIdStart, getApprovalByIdHandler);
    yield takeEvery(updateApprovalStatusStart, updateApprovalStatusHandler);
    yield takeEvery(updateRejectStatusStart, updateRejectStatusHandler);
    yield takeEvery(removeWorkflowByIdStart, removeWorkflowModuleHandler);
    // Webhooks
    yield takeEvery(createWebhookStart, createWebhookHandler);
    yield takeEvery(getWebhookListByModuleIdStart, getWebhookListByModuleIdHandler);
    yield takeEvery(getWebhookListByIdStart, getWebhookListByIdHandler);
    yield takeEvery(updateWebhookByIdStart, updateWebhookByIdHandler);
    yield takeEvery(removeWebhookByIdStart, removeWebhookByIdHandler);
    yield takeEvery(existMaterialWebhookStart, existMaterialWebhookHandler);
    yield takeEvery(listRefMaterialWebhookStart, listRefMaterialWebhookHandler);
    // Varient Config
    yield takeEvery(getVariantConfigStart, getVariantConfigHandler);
    yield takeEvery(createVariantConfigStart, createVariantConfigHandler);
    yield takeEvery(deleteVariantConfigByIdStart, deleteVariantConfigByIdHandler);
    yield takeEvery(updateVariantConfigByIdStart, updateVariantConfigByIdHandler);
    // Dashboard Components
    yield takeEvery(getAllSubFormModulesStart, getAllSubFormModulesHandler);
    yield takeEvery(createDashboardComponentStart, createDashboardComponentHandler);
    yield takeEvery(updateDashboardComponentStart, updateDashboardComponentHandler);
    yield takeEvery(getAllDashboardComponentStart, getAllDashboardComponentHandler);
    yield takeEvery(getByIdDashboardComponentStart, getByIdDashboardComponentHandler);
    yield takeEvery(deleteDashboardComponentStart, deleteDashboardComponentHandler);
    yield takeEvery(createDashboardComponentLayoutStart, createDashboardComponentLayoutHandler);
    yield takeEvery(updateDashboardComponentLayoutStart, updateDashboardComponentLayoutHandler);
    yield takeEvery(getAllDashboardComponentLayoutStart, getAllDashboardComponentLayoutHandler);
    yield takeEvery(getByIdDashboardComponentLayoutStart, getByIdDashboardComponentLayoutHandler);
    yield takeEvery(deleteDashboardComponentLayoutStart, deleteDashboardComponentLayoutHandler);
    yield takeEvery(getDashboardModuleByIdStart, getDashboardModuleByIdHandler);
    //
    yield takeEvery(getLoginHistoryStart, getLoginHistoryHandler);
    yield takeEvery(getUserLoginHistoryStart, getUserLoginHistoryHandler);
    // audit log
    yield takeEvery(getAuditLogsStart, auditLogHandler);
    // change password
    yield takeEvery(changePasswordStart, changePasswordHandler);
}