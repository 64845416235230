import { call, put } from "redux-saga/effects";
import { createCompany, createFiscalYear, getFiscalYear, getOrganization, updateCompanyById, updateFiscalYear, uploadCompanyPictureById } from "../../../../Services/apiService";
import { createCompanyFailure, createCompanySuccess, createFiscalYearFailure, createFiscalYearSuccess, getCompanyListFailure, getCompanyListSuccess, getFiscalYearFailure, getFiscalYearSuccess, updateCompanyFailure, updateCompanySuccess, updateFiscalYearFailure, updateFiscalYearSuccess, uploadCompanyProfileFailure, uploadCompanyProfileSuccess } from "../../../Slices/organizationSlice";

export function* getCompanyListHandler(actions) {
    try {
        const response = yield call(getOrganization, actions?.payload);
        yield put(getCompanyListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getCompanyListFailure(errResponse))
    }
}

export function* uploadCompanyProfileHandler(actions) {
    try {
        const response = yield call(uploadCompanyPictureById, actions?.payload);
        yield put(uploadCompanyProfileSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(uploadCompanyProfileFailure(errResponse))
    }
}

export function* updateCompanyHandler(actions) {
    try {
        const response = yield call(updateCompanyById, actions?.payload);
        yield put(updateCompanySuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateCompanyFailure(errResponse))
    }
}

export function* createComapanyHandler(actions) {
    try {
        const response = yield call(createCompany, actions?.payload);
        yield put(createCompanySuccess(response?.data));
    } catch (err) {
        // const errResponse = err?.response?.data?.message;
        const errResponse = err?.response?.data;
        yield put(createCompanyFailure(errResponse))
    }
}