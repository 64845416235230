import React, { useRef, useState, useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Helpers } from "../../Helpers";
import { FormModuleModel } from "../../Components/ModalPopup/FormModules/index";
import MenuDots from "../../Assets/images/menu-dots-icon.svg";
import { Utils } from '../../Utils';
import { CSS } from "@dnd-kit/utilities";
import { handleFieldLabelUpdate } from '../../Utils'; 

const SortableItem = ({ onRemove, item, dispatch, setShowPropertyModal, moduleStoreData, overIndex }) => {
  let [showPropertyModal, setShowPropertyModal2] = useState(false);
  let { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
    data: {
      field: { ...item, overIndex },
      fieldSortable: true
    },
    disabled: showPropertyModal
  });
  let style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  let dropdownRef = useRef(null);
  let [showModal, setShowModal] = useState(false);
  let [showDropDown, setShowDropDown] = useState(false);
  let [labelError, setLabelError] = useState(""); 
  let PropertyModal = FormModuleModel[Utils.propertyModal?.[item?.label?.replace(/\s/g, "")]];
  let dropDownList = Utils.dropDownList?.[item?.label?.replace(/\s/g, "")];
  let handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const editPropertySubmit = (newValue) => {
    dispatch({
      type: 'UPDATE_EDIT_PROPERTIES',
      payload: {
        sectionIndex: item.sectionIndex,
        area: item.area,
        itemId: item.id,
        newValue
      },
    });
  };

  const checkRequired = (key) => {
    return item?.extra_fields?.[key];
  };

  const handleAction = (action) => {
    switch (action) {
      case 'Mark as required':
        return () => editPropertySubmit({ ...item?.extra_fields, required: !item?.extra_fields?.required })
      case 'Do not allow duplicate values':
        return () => editPropertySubmit({ ...item?.extra_fields, do_not_allow_duplicate_values: !item?.extra_fields?.do_not_allow_duplicate_values })
      case "Remove Field":
        return () => onRemove(item);
      case "Set Permission":
        return () => setShowModal(true);
      case "Edit Properties":
        return () => {
          setShowPropertyModal(true);
          setShowPropertyModal2(true);
        }
      default:
        return () => { };
    }
  };

  let labelName = item?.extra_fields?.field_label;
  const handleFieldLabelChange = (event) => {
    const value = event.target.value;
    
    handleFieldLabelUpdate(value, (newLabel) => editPropertySubmit({ ...item?.extra_fields, field_label: newLabel }), setLabelError);
  };

  

  return (
    <>
      {item?.type === "field-spacer" ? 
      <>
      <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
        <div className="create-box-dragover" />
      </div>
      </>
      : 
      <>
      <div className={`field-container mt-2 mb-2 position-relative ${item?.extra_fields?.do_not_allow_duplicate_values || item?.extra_fields?.required ? 'unique' : ''}`} ref={setNodeRef} {...attributes} style={style}>
        <div className="w-50">
          <input type="text" className="section-name-input custom-input-border w-100 text-start" id="titleOwner" value={labelName}
          onChange={handleFieldLabelChange} 
          />
          {labelError && <div className="error-message text-danger">{labelError}</div>}
        </div>
        <div className="w-40 text-center cursor-grab" {...listeners}>
          <span className="text-label d-block text-truncate ps-2" title={item.label}>{item.label}</span>
        </div>
        <div className="w-10 text-end cursor-pointer" onClick={() => setShowDropDown((flg) => !flg)}>
          <img src={MenuDots} alt="dots-icon" width="20" height="20" />
        </div>

        <div ref={dropdownRef} className={`dropdown-menu ${showDropDown ? 'show' : ''}`}>
          <ul>
            {Helpers.hasLength(dropDownList) > 0 && (
              <>
              {dropDownList.map((action, index) => (
                <li key={index} onClick={handleAction(action)}>
                  {((index === 0 && checkRequired('required')) || (index === 1 && checkRequired('do_not_allow_duplicate_values'))) && <i className="fa fa-check" aria-hidden="true"></i>} {action}
                </li>
              ))}
              </>
            )}
          </ul>
        </div>

        {(showModal) && (
          <FormModuleModel.SetPermissionModal show={showModal} handleClose={handleCloseModal} />
        )}

        {(showPropertyModal && PropertyModal) && (
          <PropertyModal moduleStoreData={moduleStoreData} show={showPropertyModal} handleClose={() => {
              setShowPropertyModal(false);
              setShowPropertyModal2(false);
            }} item={item} editPropertySubmit={editPropertySubmit} />
        )}
      </div>
      </>}
    </>
  );
};

export default SortableItem;
