import React, { useEffect, useState } from "react";
// import digyLogo from '../../../Assets/images/digylax-logo.png';
import digyLogo from '../../../Assets/images/crm-logo-final.svg';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../../Services/localService";
import { toast } from "react-toastify";
import Toast from "../../../Components/Toast";
import { forgotPasswordStart, resetState, sendOtpStart, verifyOtpStart } from "../../../Store/Slices/authSlice";
import Loader from "../../../Components/Loader";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, message, otpSent, otpVerified, passwordUpdateSuccess } = useSelector((state) => state.auth);
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    // OTP Countdown
    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [verifyMode, setVerifyMode] = useState('')
    const verifiedUserData = getItem('verifiedUser');

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    const resendOTP = () => {
        setMinutes(0);
        setSeconds(10);
        dispatch(sendOtpStart({ type: 'email', email: verifiedUserData?.email }));
        showOtpBox();
    };
    // OTP Countdown END

    const verifyViaEmail = () => {
        if (otpVerified)
            createNewPasswordBoc();
        else
            resendOTP();
    }

    const [showPassField, setShowPassField] = useState(true);
    const [getOtpSection, setGetOtpSection] = useState(false);
    const [getMobNumSection, setGetMobNumSection] = useState(false);
    const [resetPasswordSection, setResetPasswordSection] = useState(false);

    const showAllOptionBox = () => {
        setShowPassField(true);
        setGetOtpSection(false);
        setGetMobNumSection(false);
        setResetPasswordSection(false);
    };

    const showOtpBox = () => {
        if (verifiedUserData?.verifyType == 'phone_no')
            dispatch(sendOtpStart({ type: 'phone', phone_no: parseInt(phoneNo) }));
        setGetOtpSection(true);
        setShowPassField(false);
        setGetMobNumSection(false);
        setResetPasswordSection(false);
    };

    const showMobNumBox = () => {
        setGetMobNumSection(true);
        setShowPassField(false);
        setGetOtpSection(false);
        setResetPasswordSection(false);
    };

    const createNewPasswordBoc = () => {
        setResetPasswordSection(true);
        setGetMobNumSection(false);
        setShowPassField(false);
        setGetOtpSection(false);
    }

    // changes

    useEffect(() => {
        const storedEmail = verifiedUserData?.verifyType == 'email' ? verifiedUserData?.email : verifiedUserData?.phone_no;
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);

    useEffect(() => {
        if (otpVerified) {
            // toast.success("OTP verified successfully!");
            toast.success(message, {
                autoClose: 1000,
            })
            createNewPasswordBoc();
        }
        if (otpVerified == false) {
            toast.success(message, {
                autoClose: 1000,
            })
        }
    }, [otpVerified
        // , otp
    ]);

    useEffect(() => {
        if (otpSent) {
            toast.success(message, {
                autoClose: 1000,
            })
        }
    }, [otpSent])

    useEffect(() => {
        if (passwordUpdateSuccess) {
            toast.success(message, {
                autoClose: 1000,
                onClose: () => {
                    dispatch(resetState());
                    navigate('/auth/login')
                }
            })
        }
    }, [passwordUpdateSuccess, navigate]);

    const handleVerifyOtp = () => {
        dispatch(verifyOtpStart({ otp, email: verifiedUserData?.email }));
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        // Prepare payload for password reset and dispatch action
        const payload = {
            password: newPassword,
            confirmPassword: confirmPassword,
            email: verifiedUserData?.email,
        };
        dispatch(forgotPasswordStart(payload));
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        if (e.target.name == 'newPassword') {
            setNewPassword(value)
            if (confirmPassword && value !== confirmPassword) {
                setPasswordMatchError("Passwords do not match");
            } else {
                setPasswordMatchError("");
            }
        }
        if (e.target.name == 'confirmPassword') {
            setConfirmPassword(value);
            // Validate if new password and confirm password match
            if (newPassword && value !== newPassword) {
                setPasswordMatchError("Passwords do not match");
            } else {
                setPasswordMatchError("");
            }
        }
    };

    const handlePhoneNoChange = (event) => {
        const value = verifiedUserData?.phone_no;
        const replaceValue = String(value).replace(/\D/g, '');
        let formattedNumber = replaceValue;
        if (formattedNumber.length > 2) {
            formattedNumber = formattedNumber.slice(0, 2) + '*******' + formattedNumber.slice(-2);
        }
        return formattedNumber
    };

    return (
        <React.Fragment>
            <Loader isVisible={isLoading} />
            {(message) && <Toast />}
            {/* <Loader isVisible={auth?.isLoading} /> */}
            <div className="auth-bg"></div>
            <div className="d-flex flex-column flex-root h-100vh">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid auth-page auth-v1 forgot-password">
                    <div className="d-flex flex-column flex-lg-row-fluid order-2 order-lg-1 auth-l-box">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            {/* Verify Your Identity - All options */}
                            <div className={`w-lg-100 ${showPassField ? 'd-block' : 'd-none'}`}>

                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block">
                                        <img src={digyLogo} alt="digylax-mdm-logo" width="146" height="56" />
                                    </a>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="d-inline-flex flex-nowrap align-items-center justify-content-between border rounded-2">
                                        <div className="py-2 px-3 fw-semibold text-truncate">{email}</div>
                                        <a onClick={() => navigate("/auth/login")} role="button" className="btn btn-link text-decoration-none">
                                            Change
                                        </a>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <h2 className="h4 text-dark fw-bold mb-2"> Verify Your identity </h2>
                                    <div className="text-body-tertiary fs-6"> Choose a mode to verify that this account belongs to you. </div>
                                    <p className="fw-semibold text-body-tertiary">{email}</p>
                                </div>

                                <div className="d-flex flex-column justify-content-center">
                                    <div role="button" className="d-flex flex-center text-wrap fgot-pass-opts"
                                        onClick={() => { verifyViaEmail(); setVerifyMode('email') }}>
                                        {/* // {resendOTP} */}
                                        <i className="fas fa-lock fa-2x" />
                                        <div className="ps-3">
                                            <p className="fw-semibold mb-1">Verify  via Email Address</p>
                                            <p className="mb-0">An OTP will be sent to your registered email address</p>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div role="button" className="d-flex flex-center text-wrap fgot-pass-opts"
                                        onClick={() => { showMobNumBox(); setVerifyMode('phone_no') }}>
                                        <i className="fas fa-lock fa-2x" />
                                        <div className="ps-3">
                                            <p className="fw-semibold mb-1">Verify  via Mobile Address</p>
                                            <p className="mb-0">An OTP will be sent to your registered mobile number</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Verify Your Identity - All options END */}

                            {/* Email / Mobile OTP Input */}
                            <div className={`w-lg-100 ${getOtpSection ? 'd-block' : 'd-none'}`}>
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block">
                                        <img src={digyLogo} alt="digylax-mdm-logo" width="146" height="56" />
                                    </a>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="d-flex flex-nowrap align-items-center justify-content-between border rounded-2">
                                        <div className="py-2 px-3 fw-semibold text-truncate">{verifyMode == 'email' ? verifiedUserData?.email : verifiedUserData?.phone_no}</div>
                                        <a onClick={() => navigate("/auth/login")} role="button" className="btn btn-link text-decoration-none">
                                            Change
                                        </a>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <h2 className="h4 text-dark fw-bold mb-2">
                                        <i className="fas fa-arrow-left fs-5 me-2 cursor-pointer"
                                            onClick={showAllOptionBox} />
                                        Forgot Password
                                    </h2>
                                    <div className="text-body-tertiary fs-6"> Enter the One time Password sent to </div>
                                    <p className="fw-semibold text-body-tertiary">{verifyMode == 'email' ? verifiedUserData?.email : verifiedUserData?.phone_no}</p>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="input-group email">
                                        <span className="input-group-text first-child bg-transparent border-end-0">
                                            <i className="fa-solid fa-key"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child" placeholder="Enter OTP"
                                            aria-describedby="otp" required value={otp}
                                            name={'otp'} onChange={({ target }) => { setOtp(target.value); }} />
                                    </div>
                                    <div className="mt-2 countdown-text">
                                        {seconds > 0 || minutes > 0 ? (
                                            <p className="mb-1">
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                            </p>
                                        ) : (
                                            <p className="mb-1">Didn't receive the code?</p>
                                        )}

                                        <p role="button" className={`d-inline ${seconds > 0 || minutes > 0 ? 'cursor-auto' : 'cursor-pointer'}`}
                                            style={{ color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630" }}
                                            onClick={resendOTP}>
                                            Resend OTP
                                        </p>
                                    </div>
                                </div>
                                <div className="d-grid">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            // createNewPasswordBoc();
                                            handleVerifyOtp();
                                        }}
                                        disabled={!otp}
                                    >
                                        <span className="indicator-label">Verify</span>
                                    </button>
                                </div>


                                <div className="text-gray-500 text-center mt-5">
                                    <span role="button" className="link-primary text-decoration-none" onClick={showAllOptionBox}> View All Options </span>
                                </div>

                            </div>
                            {/* // Email / Mobile OTP Input END // */}

                            {/* Get Mobile Number Box */}
                            <div className={`w-lg-100 ${getMobNumSection ? 'd-block' : 'd-none'}`}>

                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block">
                                        <img src={digyLogo} alt="digylax-mdm-logo" width="146" height="56" />
                                    </a>
                                </div>

                                <div className="mb-4">
                                    <h2 className="h4 text-dark fw-bold mb-2">
                                        <i className="fas fa-arrow-left fs-5 me-2 cursor-pointer"
                                            onClick={showAllOptionBox} />
                                        Verify Your Mobile Number
                                    </h2>
                                    <div className="text-body-tertiary fs-6"> You can verify yourself using your mobile number
                                        <span className="fw-semibold ps-2">{handlePhoneNoChange()}</span>
                                    </div>
                                    <p className="text-body-tertiary">Enter the full mobile to receive a one time password.</p>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="input-group email">
                                        <span className="input-group-text first-child bg-transparent border-end-0">
                                            <i className="fa-solid fa-mobile-screen-button"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child" placeholder="Enter Mobile Number"
                                            aria-describedby="mobNumber" required value={phoneNo}
                                            name="phoneNo" onChange={(e) => setPhoneNo(e?.target?.value)} />
                                    </div>
                                </div>

                                <div className="d-grid mb-5">
                                    <button type="submit" className="btn btn-primary" onClick={showOtpBox}>
                                        <span className="indicator-label">Send OTP</span>
                                    </button>
                                </div>

                                <div className="text-gray-500 text-center mt-5">
                                    <span role="button" className="link-primary text-decoration-none" onClick={showAllOptionBox}> View All Options </span>
                                </div>

                            </div>
                            {/* // Get Mobile Number Box END // */}

                            {/* Reset Password Box */}
                            <div className={`w-lg-100 ${resetPasswordSection ? 'd-block' : 'd-none'}`}>

                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block">
                                        <img src={digyLogo} alt="digylax-mdm-logo" width="146" height="56" />
                                    </a>
                                </div>

                                <div className="mb-4">
                                    <h2 className="h4 text-dark fw-bold mb-2">
                                        <i className="fas fa-arrow-left fs-5 me-2 cursor-pointer"
                                            onClick={showAllOptionBox} />
                                        Create New Password
                                    </h2>
                                    <div className="text-body-tertiary fs-6">
                                        <p>
                                            Enter a Unique and strong password that is easy to remember
                                            so that you won’t forget it the next time.
                                        </p>
                                    </div>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="input-group password">
                                        <span className="input-group-text bg-transparent border-end-0 first-child" id="newPassword">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="form-control border-start-0 border-end-0" placeholder="New Password"
                                            aria-describedby="newPassword" required
                                            name={'newPassword'}
                                            onChange={handlePasswordChange}
                                        // (e) => setNewPassword(e.target.value) // Update newPassword state

                                        />
                                        <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                            onClick={() => { newPassword && setShowPassword(!showPassword) }}>
                                            <i className={`fa-regular ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </span>
                                    </div>
                                </div>



                                <div className="mb-3 icon-input">
                                    <div className="input-group password">
                                        <span className="input-group-text bg-transparent border-end-0 first-child" id="confirmPassword">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
                                        <input
                                            type={showCPassword ? 'text' : 'password'}
                                            className="form-control border-start-0 border-end-0"
                                            placeholder="Confirm Password"
                                            aria-describedby="confirmPassword"
                                            required
                                            name={'confirmPassword'}
                                            onChange={handlePasswordChange}
                                        />
                                        <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                            onClick={() => { confirmPassword && setShowCPassword(!showCPassword) }}>
                                            <i className={`fa-regular ${showCPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </span>
                                    </div>
                                    {passwordMatchError && (
                                        <p className="text-danger mt-1">{passwordMatchError}</p>
                                    )}
                                </div>

                                <div className="d-grid">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handlePasswordReset}
                                        disabled={(newPassword !== confirmPassword) || newPassword == '' && confirmPassword == ''}  // Disable if either field is empty
                                    >
                                        <span className="indicator-label">Change Password</span>
                                    </button>
                                </div>

                                {/* <div className="text-gray-500 text-center mt-5">
                                    <span role="button" className="link-primary text-decoration-none"> Contact Support </span>
                                </div> */}

                            </div>
                            {/* // Reset Password Box END // */}

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;