import React, { useEffect, useRef, useState } from "react";
import FillterData from "../../../Components/FillterData";
import { useNavigate, useParams } from "react-router-dom";
import { getModuleByIdStart } from "../../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
import { Utils } from "../../../Utils";
import Loader from "../../../Components/Loader";
import { DndContext, useSensor, MouseSensor, TouchSensor, useSensors, DragOverlay } from "@dnd-kit/core";
import FieldsDraggable from "./FieldsDraggable";
import FieldsDroppableArea from "./FieldsDroppableArea";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import FieldsSortableArea from "./FieldsSortableArea";
import { Helpers } from "../../../Helpers";
import { createSubFormCustomViewStart, destroyCustomFormSubView } from "../../../Store/Slices/subFormSlice";
import ConfirmationModal from "../../../Components/ModalPopup/ConfirmationModal";

const NewCustomView = ({ isData }) => {
    let { id } = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let [customViewName, setCustomViewName] = useState(isData?.custom_view_data?.custom_view_name || "");
    let [criteriaFilter, setCriteriaFilter] = useState({
        criteria : isData?.custom_view_data?.criteria || [],
        relationships : isData?.custom_view_data?.relationships || [],
        relationship_pattern : isData?.custom_view_data?.relationship_pattern || "",
    });
    let [resetCriteria, setResetCriteria] = useState(false);
    let formData = useSelector((state) => state?.forms);
    let [moduleData, setModuleData] = useState(null);
    let [allDatas, setAllDatas] = useState([]);
    let [selectedFields, setSelectedFields] = useState(isData?.custom_view_data?.selected_fields || []);
    let formsubmodules = useSelector((state) => state?.subForm);
    let sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
        useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
    );
    let [currentDragStartData, setCurrentDragStartData] = useState(null);
    let fieldSpacerInsertedRef = useRef();
    let currentFieldDragRef = useRef();
    let [step, setStep] = useState(1);
    let [openModal, setOpenModal] = useState(null);

    useEffect(() => {
        if (id) {
            dispatch(getModuleByIdStart(id));
        }
    }, [id]);

    useEffect(() => {
        if (formData?.modules?.length > 0) {
            setModuleData(formData?.modules?.[0]);
        }
    }, [formData?.modules]);

    useEffect(() => {
        if(formsubmodules?.createdSubFormCustomView || formsubmodules?.updatedSubFormCustomView) {
            dispatch(destroyCustomFormSubView());
            navigate(Utils.getNavigationUrl(`form-module/sub-form/${id}`));
        }
    }, [formsubmodules?.createdSubFormCustomView, formsubmodules?.updatedSubFormCustomView])

    useEffect(() => {
        if (moduleData) {
            let allData2 = Utils.getModuleFields(moduleData?.fields?.sections, [...Utils.stringInputTypes, ...Utils.numberInputTypes, ...Utils.dateInputTypes]);
            if (!currentDragStartData && allData2?.moduleFields?.length > 0) {
                let updatedModuleFields = allData2?.moduleFields.filter(
                    field => !selectedFields.some(selected => selected?.id === field?.fields?.id)
                );
                setAllDatas(prevState => ({
                    ...prevState,
                    moduleFields: updatedModuleFields
                }));
            }
        }
    }, [selectedFields, moduleData]);

    let getData = (prop) => {
        return prop?.data?.current ?? {};
    }

    let dragStartEvent = (event) => {
        let { active } = event;
        let activeData = getData(active);
        if (activeData?.fieldSortArea) {
            let { field } = activeData;
            currentFieldDragRef.current = field;
            let areaItems = [...selectedFields];
            let newItem = {
                id: areaItems?.[field?.overIndex]?.id,
                type: `field-spacer`
            };
            areaItems.splice(field?.overIndex, 1, newItem);
            setSelectedFields(areaItems);
        }
        setCurrentDragStartData(activeData);
    }

    let handleDragEnd = (event) => {
        let { active, over } = event;
        let activeData = getData(active);
        let overData = getData(over);
        let filteredArray = selectedFields?.filter(item => item.type !== "field-spacer");
        if (activeData?.fieldDragArea) {
            let overIndex = overData?.field?.overIndex;
            let targetArray = Helpers.getItemsOrDefault(filteredArray);
            let updatedArray = overIndex >= 0
                ? [...targetArray.slice(0, overIndex), activeData?.field, ...targetArray.slice(overIndex)]
                : [...targetArray, activeData?.field];
            setSelectedFields(updatedArray);
        } else if (currentFieldDragRef?.current) {
            let overIndex = overData?.field?.overIndex;
            let targetArray = Helpers.getItemsOrDefault(filteredArray);
            let updatedArray = overIndex >= 0
                ? [...targetArray.slice(0, overIndex), currentFieldDragRef.current, ...targetArray.slice(overIndex)]
                : [...targetArray, currentFieldDragRef.current];
            setSelectedFields(updatedArray);
        }
        fieldSpacerInsertedRef.current = false;
        currentFieldDragRef.current = null;
        setCurrentDragStartData(null);
    }

    let fieldSpacerCreater = (overData) => {
        let overIndex = overData?.field?.overIndex;
        fieldSpacerInsertedRef.current = false;
        let filteredArray = selectedFields?.filter(item => item.type !== "field-spacer") || [];
        if (!fieldSpacerInsertedRef?.current) {
            let overIndexNumber = overIndex > -1 ? overIndex : selectedFields?.length - 1;
            let newItem = {
                id: `spacer`,
                type: `field-spacer`
            };
            filteredArray.splice(overIndexNumber, 0, newItem);
            setSelectedFields(filteredArray);
            fieldSpacerInsertedRef.current = true;
        } else {
            let spacerIndex = filteredArray.findIndex(item => item.type === "field-spacer");
            if (spacerIndex !== -1) {
                let [spacerItem] = filteredArray.splice(spacerIndex, 1);
                filteredArray.splice(overIndex, 0, spacerItem);
                setSelectedFields(filteredArray);
            }
        }
    }

    let handleDragOver = (event) => {
        let { over } = event;
        let overData = getData(over);
        fieldSpacerCreater(overData);
    }

    let submitData = () => {
        if (moduleData) {
            let allData2 = Utils.getModuleFields(moduleData?.fields?.sections, ["text", "email", "tel", "select", "date", "datetime-local", "number", "currency", "decimal", "percent", "longinteger", "url", "lookup", "user"]);
            let updatedModuleFields = allData2.moduleFields.map(field => {
                let selected = selectedFields.some(selected => selected?.id === field?.fields?.id);
                return {
                    label : field?.fields?.extra_fields?.field_label,
                    show : selected
                }
            });
            let obj = {
                moduleId : id,
                custom_view_data: {
                    custom_view_name: customViewName,
                    ...criteriaFilter,
                    selected_fields: selectedFields?.map(x => ({ id: x?.id, label: x?.extra_fields?.field_label })),
                    is_enable_criteria : true
                },
                manage_columns_data: updatedModuleFields
            }
            dispatch(createSubFormCustomViewStart(obj));
        }
    }

    let alertInfo = () => {
        return {
            title: "Custom View",
            content: `Please remove the field "${openModal}" from the criteria filter before proceeding.`,
            btnDeclineTitle: "No",
            buttonTitle: "Yes",
        };
    };
    return (
        <>
            <Loader isVisible={formData?.isLoading || formsubmodules?.isCustomViewLoading} />
            <div className="col-lg-6 col-12">
                <div className="card rounded-4 border-0 mt-3">
                    {step === 1 && (
                        <>
                            <div className="mb-3 form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="customViewName"
                                    placeholder="Enter Custom View Name"
                                    value={customViewName || ""}
                                    onChange={(e) => setCustomViewName(e?.target?.value)}
                                    required
                                />
                                <label htmlFor="customViewName">Custom View Name</label>
                            </div>
                            <div>
                                <h5 className="mb-3">Choose Columns - Tabular View</h5>
                                <div className="row">
                                    <DndContext sensors={sensors} onDragOver={handleDragOver} onDragStart={dragStartEvent} onDragEnd={handleDragEnd} autoScroll={{ layoutShiftCompensation: false, enable: false }}>
                                        <div className="col-md-6">
                                            <p className="ms-1 mb-1"><strong>Available</strong></p>
                                            <ul className="list-group overflow-auto border" style={{ height: "350px" }}>
                                                {allDatas?.moduleFields?.length > 0 && (
                                                    allDatas?.moduleFields.map((item, i) => (
                                                        <FieldsDraggable field={item.fields} key={i} addField={(item) => {
                                                            setSelectedFields([...selectedFields, item]);
                                                        }} />
                                                    ))
                                                )}
                                                {currentDragStartData && (
                                                    <DragOverlay>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center cursor-move">
                                                            {currentDragStartData?.field?.extra_fields?.field_label}
                                                            <span className="cursor-pointer">{currentDragStartData?.fieldSortArea ? '-' : '+'}</span>
                                                        </li>
                                                    </DragOverlay>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="ms-1 mb-1"><strong>Selected</strong></p>
                                            <FieldsDroppableArea>
                                                {selectedFields?.length > 0 && (
                                                    <SortableContext items={selectedFields?.map((section) => section?.fields?.id)} strategy={verticalListSortingStrategy}>
                                                        {selectedFields?.length > 0 && (
                                                            selectedFields.map((item, i) => (
                                                                <FieldsSortableArea overIndex={i} item={item} key={i} removeField={(removeId) => {
                                                                    let removedData = selectedFields?.filter(item => item?.id !== removeId);
                                                                    let removingLabel = selectedFields?.find(item => item?.id === removeId)?.extra_fields?.field_label;
                                                                    let checkFlg = !criteriaFilter?.criteria?.some(x => x?.field_label === removingLabel);
                                                                    if (checkFlg) {
                                                                        setSelectedFields(removedData);
                                                                    } else {
                                                                        setOpenModal(removingLabel);
                                                                    }
                                                                }} />
                                                            ))
                                                        )}
                                                    </SortableContext>
                                                )}
                                            </FieldsDroppableArea>
                                        </div>
                                    </DndContext>
                                </div>
                            </div>
                        </>
                    )}

                    {step === 2 && (
                        <FillterData onClickRemove={(flg) => {}} data={selectedFields?.map(x => ({fields : x}))} onChangeCriteriaFilter={(items) => setCriteriaFilter(items)} selectedDatas={criteriaFilter} resetCriteria={resetCriteria} setResetCriteria={setResetCriteria} />
                    )}

                    <div className="d-flex justify-content-end mt-3">
                        <button type="button" className="btn btn-outline-primary rounded-pill btn-blue me-1" onClick={() => navigate(Utils.getNavigationUrl(`form-module/sub-form/${id}`))}>
                            <span>Cancel</span>
                        </button>
                        {step === 1 && (
                            <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => setStep(2)} disabled={!customViewName && !selectedFields?.length}>
                                <span>Next</span>
                            </button>
                        )}
                        {step === 2 && (
                            <>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue me-1" onClick={() => setStep(1)} disabled={!customViewName && !selectedFields?.length}>
                                    <span>Back</span>
                                </button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={submitData} disabled={!customViewName && !selectedFields?.length}>
                                    <span>Save</span>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div >
            { openModal && <ConfirmationModal show={!!openModal} handleClose={() => {
                setOpenModal(null);
            }} handleSuccess={() => {
                setOpenModal(null);
            }} alertInfo={alertInfo()}/>}
        </>
    );
}

export default NewCustomView;