import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProfileStart, getProfileStart, updatePermissionStart } from "../../../Store/Slices/profileSlice";
import { getAllUserListStart } from "../../../Store/Slices/userSlice";
import { Utils } from "../../../Utils";

const ProfilePermissions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let currentProfiles = useSelector((state) => state?.profile);
    let allUsers = useSelector((state) => state?.user)?.data;
    // console.log('all user?', allUsers)
    let formList = useSelector((state) => state?.forms);
    // let currentProfiles = useSelector((state) => state?.profile);
    const stateData = location.state;
    // console.log('state data from parent?', stateData)

    // states
    const [permissionData, setPermissionData] = useState({
        profile_name: '',
        profile_desc: '',
        clone_profile: '',
        permission: {
            modulePermission: {
                basic: []
            }
        }
    });
    const [onChangeTrigger, setOnChangeTrigger] = useState(false);
    const [modalValue, setModalValue] = useState('');
    const [validForm, setValidForm] = useState(false);
    // functions

    // useeffect
    useEffect(() => {
        dispatch(getAllUserListStart({ page: 1, count: 100 }));
    }, [])

    useEffect(() => {
        // console.log('form list?', formList?.navbarList)
        if (formList?.navbarList?.length > 0) {
            // console.log('state data inside useeffect', stateData)
            if (stateData?.permission) {
                // console.log('*if');
                setPermissionData((prev) => ({
                    ...prev,
                    permission: {
                        ...prev.permission,
                        modulePermission: {
                            ...prev.permission.modulePermission,
                            basic: stateData?.permission?.modulePermission?.basic
                        }
                    }
                }))
            } else {
                // console.log('*else')
                let arr = [];
                let activities = {
                    view: true,
                    create: true,
                    edit: true,
                    delete: true
                }
                formList.navbarList.forEach(element => {
                    arr.push({ id: element._id, name: element.name, activities: activities })
                });
                if (arr.length)
                    setPermissionData((prev) => ({
                        ...prev,
                        permission: {
                            ...prev.permission,
                            modulePermission: {
                                ...prev.permission.modulePermission,
                                basic: arr
                            }
                        }
                    }))
            }
            setPermissionData((prev) => ({
                ...prev,
                clone_profile: stateData?.profile_name
            }));
        }

    }, [formList])

    useEffect(() => {
        setValidForm(permissionData?.profile_name !== '' ? true : false);
    }, [permissionData])

    useEffect(() => {
        if (onChangeTrigger) {
            // console.log('triggered')
            let payload = {
                profile_name: stateData?.profile_name,
                profile_desc: stateData?.profile_desc,
                clone_profile: stateData?.clone_profile,
                organization: stateData?.organization,
                permission: permissionData?.permission
            };
            // console.log('payload on trigger?', payload)
            dispatch(updatePermissionStart({ payload: payload, id: stateData?._id }));
        }
    }, [permissionData.permission])

    useEffect(() => {
        if (currentProfiles?.createStatus) {
            dispatch(getProfileStart());
            navigate(Utils.getNavigationUrl(`settings/profiles`))
        }
    }, [currentProfiles.createStatus]);

    // handlers
    const formHandler = (e) => {
        try {
            setPermissionData((prev) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value
            }));
        } catch (err) {
            throw err
        }
    }

    const handleClone = () => {
        // console.log('data?', permissionData)
    }

    const handleSwitch = (id, e) => {
        setOnChangeTrigger(true)
        setPermissionData((prev) => ({
            ...prev,
            permission: {
                ...prev.permission,
                modulePermission: {
                    ...prev.permission.modulePermission,
                    basic: prev.permission.modulePermission.basic.map(item => item.id == id ? {
                        ...item,
                        activities: {
                            view: e.target.checked ? true : false,
                            create: e.target.checked ? true : false,
                            edit: e.target.checked ? true : false,
                            delete: e.target.checked ? true : false,
                        }
                    } : item)
                }
            }
        }))
    }

    const handlePermissionChange = (id, name, e) => {
        setOnChangeTrigger(true)
        if (e.target.name == 'view' && e.target.checked == false) {
            setPermissionData((prev) => ({
                ...prev,
                permission: {
                    ...prev.permission,
                    modulePermission: {
                        ...prev.permission.modulePermission,
                        basic: prev.permission.modulePermission.basic.map(item => item.id == id && item.name == name ? {
                            ...item,
                            activities: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            }
                        } : item)
                    }
                }
            }))
        } else {
            setPermissionData((prev) => ({
                ...prev,
                permission: {
                    ...prev.permission,
                    modulePermission: {
                        ...prev.permission.modulePermission,
                        basic: prev.permission.modulePermission.basic.map(item => item.id == id ? {
                            ...item,
                            activities: {
                                ...item.activities,
                                [e.target.name]: e.target.checked
                            }
                        } : item)
                    }
                }
            }))
        }
    }

    const handleSubmit = () => {
        let payload = permissionData;
        payload['organization'] = stateData?.organization;
        dispatch(createProfileStart(payload));
    }

    return (
        <>
            <div className="pro-permission">
                <div className="navbar sticky-top bg-light-subtle bor   r-bottom border-light-subtle px-0 tb-header">
                    <div className="ps-3 me-auto">
                        <span className="text-secondary fs-4">
                            <i className="fa fa-arrow-left me-2 cursor-pointer"
                                onClick={() => navigate(Utils.getNavigationUrl(`settings/profiles`))}
                            // onClick={() => navigate(-1)}
                            >
                            </i> Profile:
                        </span>
                        <span className="ps-2 fw-semibold fs-4">{stateData?.profile_name}</span>
                    </div>
                    <div className="pe-3 ms-auto">
                        <div className="btn-group">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue"
                                // onClick={() => handleClone()}
                                data-bs-toggle="modal" data-bs-target="#addEditUserModal"
                            >
                                <span>Clone</span>
                            </button>
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue"
                                data-bs-toggle="modal" data-bs-target="#viewUsersModal">
                                <span>View Users</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="content-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-4 col-xxl-3 ps-0">
                                <div className="scrollspy-list border-end overflow-y-auto">
                                    {/* Scrollspy list section */}

                                    <div id="permissions-scrollspy"
                                        className="d-flex flex-column simple-list-scrollspy">

                                        <p className="sub-list-header pt-4 mb-1 fs-6 fw-semibold">Module Permissions</p>
                                        <a className="scrollspy-item active" href="#scrollspy-item-1">Basic</a>
                                    </div>
                                    {/* // Scrollspy list section // */}
                                </div>
                            </div>
                            <div className="col-12 col-md-8 col-xxl-9 pe-0">
                                <div className="scrollspy-content mt-1 ps-lg-3 ps-xl-5 overflow-y-auto">

                                    <div data-bs-spy="scroll" data-bs-target="#permissions-scrollspy"
                                        data-bs-offset="0" data-bs-smooth-scroll="true"
                                        className="scrollspy-scetion" tabIndex="0">
                                        {/* Scrollspy content section-1 */}
                                        <div id="scrollspy-item-1" className="scrollspy-section pt-4">
                                            <h4 className="fs-6 fw-semibold">Basic Permissions</h4>
                                            <div className="per-setup">
                                                {permissionData?.permission?.modulePermission?.basic?.length > 0 && permissionData?.permission?.modulePermission?.basic?.map((data, i) => (
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-12 col-lg-4">
                                                            <div className="fw-semibold">{data?.name}</div>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-1">
                                                            <div className="form-check form-switch form-check-success mb-0">
                                                                <input className="form-check-input cursor-pointer my-auto"
                                                                    type="checkbox" role="switch" id="module-1"
                                                                    // name="permissionSwitch"
                                                                    checked={data?.activities?.view}
                                                                    onChange={(e) => handleSwitch(data.id, e)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6 ps-3">
                                                            <div className="dropdown">
                                                                <button className={`btn btn-link ps-0 pe-4 dropdown-toggle ${data?.activities?.view && 'with-arrow'}`}
                                                                    type="button" data-bs-toggle="dropdown"
                                                                    data-bs-auto-close="outside" aria-expanded="false">
                                                                    {/* View, Create, Edit, Delete */}
                                                                    {
                                                                        Object.entries(data.activities)
                                                                            .filter(([key, value]) => value === true)
                                                                            .map(([key, value]) => key)
                                                                            .join(', ')
                                                                    }
                                                                </button>
                                                                <div className={`dropdown-menu px-0 py-1 rounded-0 ${!data?.activities?.view && 'd-none'}`}>
                                                                    <div className="dropdown-item">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input cursor-pointer" type="checkbox" id="m1ViewCheck"
                                                                                name="view" checked={data?.activities?.view}
                                                                                onChange={(e) => handlePermissionChange(data.id, data.name, e)} />
                                                                            <label className="form-check-label cursor-pointer" htmlFor="m1ViewCheck">
                                                                                View
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-item">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input cursor-pointer" type="checkbox" id="m1CreateCheck"
                                                                                name="create" checked={data?.activities?.create}
                                                                                onChange={(e) => handlePermissionChange(data.id, data.name, e)} />
                                                                            <label className="form-check-label cursor-pointer" htmlFor="m1CreateCheck">
                                                                                Create
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-item">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input cursor-pointer" type="checkbox" id="m1EditCheck"
                                                                                name="edit" checked={data?.activities?.edit}
                                                                                onChange={(e) => handlePermissionChange(data.id, data.name, e)} />
                                                                            <label className="form-check-label cursor-pointer" htmlFor="m1EditCheck">
                                                                                Edit
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-item">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input cursor-pointer" type="checkbox" id="m1DeleteCheck"
                                                                                name="delete" checked={data?.activities?.delete}
                                                                                onChange={(e) => handlePermissionChange(data.id, data.name, e)} />
                                                                            <label className="form-check-label cursor-pointer" htmlFor="m1DeleteCheck">
                                                                                Delete
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {/* // Scrollspy content section-1 // */}

                                        {/* Scrollspy content section-2 */}

                                        {/* // Scrollspy content section-2 // */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add & Edit User Modal */}
                <div className="modal fade" id="addEditUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addEditUserLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable add-user-form">
                        <div className="modal-content">
                            <div className="modal-body pb-0">
                                <h5 className="modal-title fs-5" id="addEditUserLabel">Create New Profile</h5>

                                <form className='pt-4'>
                                    <div className="row mb-3">
                                        <label htmlFor="profileName" className="col-auto col-md-3 text-md-end col-form-label">Profile Name</label>
                                        <div className="col-auto col-md-9 col-xl-7 input-control">
                                            <input type="text" className="form-control" id="profileName" required
                                                name='profile_name' value={permissionData.profile_name} onChange={formHandler} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="cloneProfile" className="col-auto col-md-3 text-md-end col-form-label">Clone Profile</label>
                                        <div className="col-auto col-md-9 col-xl-7 input-control">
                                            <input type="text" className="form-control" id="cloneProfile" required
                                                name='clone_profile' value={permissionData.clone_profile} disabled
                                                style={{ cursor: 'not-allowed' }} />
                                            {/* <select className="form-select" id="cloneProfile" required>
                                                <option value="1">Administrator</option>
                                                <option value="2">Standard</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="profileDesc" className="col-auto col-md-3 text-md-end col-form-label">Profile Description</label>
                                        <div className="col-auto col-md-9 col-xl-7 input-control">
                                            <textarea className="form-control" id="profileDesc" rows="3"
                                                name='profile_desc' value={permissionData.profile_desc} onChange={formHandler}></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={handleSubmit} disabled={!validForm} data-bs-dismiss="modal">Create</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Add & Edit User Modal // */}

                {/* View User Modal */}
                <div className="modal fade" id="viewUsersModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="viewUsersLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable add-user-modal">
                        <div className="modal-content">
                            <div className='modal-header border-bottom-0'>
                                <h5 className="modal-title fs-5" id="viewUsersLabel">Users in <span className='fs-5 fw-semibold'> {stateData?.profile_name} </span> profile</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <div className="d-flex col-4 pb-4">
                                    <div className="search-box border rounded-pill">
                                        <label htmlFor='usersSearch' className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></label>
                                        <input type="search" placeholder="Search" id='usersSearch' className="searchfield bg-white"
                                            value={modalValue} onChange={e => setModalValue(e.target.value)} />
                                    </div>
                                </div>

                                <div className="tbl-container rounded-table mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>User name</th>
                                                    <th>Role</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allUsers?.length > 0 && allUsers
                                                    .filter((user) => user?.Profile === stateData?.profile_name)
                                                    .filter(item => {
                                                        if (!modalValue) return true
                                                        if (item.firstName.includes(modalValue) || item.lastName.includes(modalValue) ||
                                                            item.role.role_name.includes(modalValue)) {
                                                            return true
                                                        }
                                                    })
                                                    .map((data) => (
                                                        < tr >
                                                            <td>
                                                                <div className='d-inline-flex align-items-center'>
                                                                    {/* <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" /> */}
                                                                    <span className='ms-2'>
                                                                        {data?.firstName + ' ' + data?.lastName}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td>{data?.role?.role_name}</td>
                                                            <td>{data?.isActive ? 'Active' : 'Inactive'}</td>
                                                        </tr>
                                                    ))}
                                                {/* <tr>
                                                    <td>
                                                        <div className='d-inline-flex align-items-center'>
                                                            <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" />
                                                            <span className='ms-2'>
                                                                info
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>CEO</td>
                                                    <td>Active</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='d-inline-flex align-items-center'>
                                                            <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" />
                                                            <span className='ms-2'>
                                                                johndoe
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>Manager</td>
                                                    <td>Yet to confirm.</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='d-inline-flex align-items-center'>
                                                            <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" />
                                                            <span className='ms-2'>
                                                                dexat50815
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>Manager</td>
                                                    <td>Yet to confirm.</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // View User Modal // */}
            </div>
        </>
    )
}
export default ProfilePermissions;