import React, { useState } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import AggregateProperties from './AggregateProperties';
import CRMFormulaForm from './CRMFormulaForm';
import { Utils } from '../../../Utils';

function Aggregate({ show, handleClose, section, aggregateSave, aggregate_list }) {
    const [subFormList] = useState(section?.selected_list?.filter(x => x?.input_type === "number" || x?.input_type === "formula"));
    const [saveData, setSaveData] = useState({
        aggregate: {},
        formula: {},
        number: {},
        currency: {},
        decimal: {}
    });
    const aggregateData = {
        id: Utils.idGenerator(),
        label: "Aggregate",
        input_type: "aggregate",
        extra_fields: {
            field_label: "Aggregate Field",
            aggregate_function: "SUM",
            subform_field: subFormList?.[0]?.extra_fields?.field_label,
            decimal_places: 0
        }
    }
    const formulaData = {
        id: Utils.idGenerator(),
        label: "Formula",
        input_type: "formula",
        extra_fields: {
            field_label: "Formula",
            return_type: "Decimal",
            decimal_place: 0,
            formula_expression: ""
        }
    }
    const numberData = {
        id: Utils.idGenerator(),
        label: "Number",
        input_type: "number",
        extra_fields: {
            field_label: "Number",
        }
    }
    const currencyData = {
        id: Utils.idGenerator(),
        label: "Currency",
        input_type: "currency",
        extra_fields: {
            field_label: "Currency",
        }
    }
    const decimalData = {
        id: Utils.idGenerator(),
        label: "Decimal",
        input_type: "decimal",
        extra_fields: {
            field_label: "Decimal",
        }
    }
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleTabSelect = (key) => {
        let tabIndex = { aggregate: 0, formula: 1, new_fields: 2 }[key];
        setSelectedIndex(tabIndex);
    }

    const editPropertySubmit = (newValue, label) => {
        setSaveData((item) => ({ ...item, [label]: newValue }));
    }

    const submitData = (data) => {
        if (selectedIndex === 0) {
            aggregateSave({
                ...aggregateData,
                extra_fields: saveData["aggregate"]
            })
        } else if (selectedIndex === 1) {
            aggregateSave({
                ...formulaData,
                extra_fields: saveData["formula"]
            })
        } else {
            aggregateSave(data)
        }
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} size={selectedIndex === 1 ? 'lg' : 'md'}>
            <Modal.Header closeButton>
                <Modal.Title>Aggregate Fields</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="aggregate" id="uncontrolled-tab-example" className="mb-3" onSelect={handleTabSelect}>
                    <Tab eventKey="aggregate" title="Aggregate">
                        <AggregateProperties item={aggregateData} section={section} editPropertySubmit={(data) => editPropertySubmit(data, "aggregate")} newAggregate={true} />
                    </Tab>
                    <Tab eventKey="formula" title="Formula">
                        <CRMFormulaForm item={formulaData} section={section} editPropertySubmit={(data) => editPropertySubmit(data, "formula")} newAggregate={true} subFormAggregate={true} aggregate_list={aggregate_list}/>
                    </Tab>
                    <Tab eventKey="new_fields" title="New Fields">
                        <div onClick={() => submitData(numberData)}>Number</div>
                        <div onClick={() => submitData(currencyData)}>Currency</div>
                        <div onClick={() => submitData(decimalData)}>Decimal</div>
                    </Tab>
                </Tabs>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => submitData("")}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Aggregate;
