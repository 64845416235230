import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { bulkUploadSubFormByIdStart } from "../../../Store/Slices/subFormSlice";

function ImportModal({ show, handleClose, moduleId }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();
    const formsubmodules = useSelector((state) => state?.subForm);

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleSubmit = () => {
        if (selectedFile) {
            let formData = new FormData();
            formData.append('file', selectedFile);
            dispatch(bulkUploadSubFormByIdStart({ moduleId, formData }));
        }
    };

    useEffect(() => {
        if (formsubmodules?.reload) {
            console.log(formsubmodules?.reload)
            handleClose();
        }
    }, [formsubmodules?.reload]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select XLSL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="file-upload-content">
                    <div className="mb-4">Upload your file here (only .xlsx).</div>
                    <span className="btn btn-primary btn-file">
                        Browse Local Files 
                        <input 
                            type="file" 
                            accept=".xlsx" 
                            onChange={handleFileChange}
                        />
                    </span>
                    {selectedFile && (
                        <div className="mt-3">
                            Selected File: {selectedFile.name}
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>
                    Close
                </button>
                <button 
                    className="btn btn-primary" 
                    onClick={handleSubmit} 
                    disabled={!selectedFile}
                >
                    Upload File
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ImportModal;
