import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AllChartModel } from "../../../Components/ModalPopup/ChartComponents";
import RGL, { WidthProvider } from "react-grid-layout";
import { DashboardComponents } from "../../../Components/DashboardComponents";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Loader from "../../../Components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubFormModulesStart, createDashboardComponentStart, reState } from '../../../Store/Slices/dashboardComponentsSlice';
import { Utils } from "../../../Utils";

const ReactGridLayout = WidthProvider(RGL);
const CreateDashboard = () => {
    let dispatch = useDispatch();
    let subModuleData = useSelector((state) => state?.dashboardComponents);
    let [dashboardName, setDashboardName] = useState('');
    let [description, setDescription] = useState('');
    let navigate = useNavigate();
    let [OpenChartModel, setOpenChartModel] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [componentDatainitialData, setComponentDatainitialData] = useState([]);
    let layout = componentDatainitialData?.length > 0 ? componentDatainitialData.map(item => ({
        ...item?.position
    })) : [];
    let [openDropdown, setOpenDropdown] = useState(null);
    let [isEditModalData, setIsEditModalData] = useState(null);
    let [dashboardNameDublicate, setDashboardNameDublicate] = useState(null);

    useEffect(() => {
        if (componentDatainitialData?.length > 0) {
            let uniqueModuleIds = [...new Set(componentDatainitialData.map(item => item.moduleId))];
            uniqueModuleIds?.forEach((moduleId) => {
                let hasModuleId = subModuleData?.subModuleData?.hasOwnProperty(moduleId);
                if (!hasModuleId) {
                    dispatch(getAllSubFormModulesStart(moduleId));
                }
            });
        }
    }, [componentDatainitialData]);

    let onClickOpenChartComponent = (key) => {
        let ChartComponent = AllChartModel[key];
        if (ChartComponent) {
            setOpenChartModel(() => ChartComponent);
            setShowModal(true);
        } else { }
    }

    let submitData = (data) => {
        if(isEditModalData) {
            let updatedData = componentDatainitialData.map(item => 
                item.position.i === data.position.i ? data : item
            );
            setComponentDatainitialData(updatedData);
        } else {
            setComponentDatainitialData([...componentDatainitialData, data]);
        }
        setIsEditModalData(null);
    }

    let onLayoutChange = (layout2) => {
        let data = [...componentDatainitialData];
        let updatedData = data.map(item => {
            let matchingLayout = layout2.find(layoutItem => layoutItem.i === item.position.i);
            if (matchingLayout) {
                item.position = { ...matchingLayout };
            }
            return item;
        });
        setComponentDatainitialData(updatedData);
    }

    let saveDashboard = () => {
        let obj = {
            dashboard_name: dashboardName,
            description,
            components: componentDatainitialData
        }
        dispatch(createDashboardComponentStart(obj));
    }

    useEffect(() => {
        if(subModuleData?.error?.length > 0 && !subModuleData?.status) {
            setDashboardNameDublicate(subModuleData?.error);
        }
    }, [subModuleData?.error]);

    useEffect(() => {
        if(subModuleData?.componentCreationSuccess) {
            navigate(Utils.getNavigationUrl(`analytics`));
            dispatch(reState());
        }
    }, [subModuleData?.componentCreationSuccess]);

    let handleToggle = (index) => {
        setOpenDropdown(prevIndex => (prevIndex === index ? null : index));
    };

    let openEditModal = (x) => {
        setIsEditModalData(x);
        onClickOpenChartComponent(x?.main_modal?.length > 0 ? x?.main_modal : x?.modal_name);
    }

    let removeData = (index) => {
        let data = [...componentDatainitialData];
        setComponentDatainitialData(data?.filter((_, i) => i !== index));
    }
    
    return (
        <>
            <Loader isVisible={subModuleData?.isLoading} />
            <div className="create-module">
                <div className="container-fluid">
                    <div className="row">
                        <div className="sidebar col-md-2 col-lg-2 p-0 bg-white h-100vh fixed-top">
                            <div className="offcanvas-md offcanvas-end h-100" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                                        aria-label="Close"></button>
                                </div>

                                <div className="offcanvas-body d-md-flex flex-column p-3 pt-lg-3 h-100 overflow-y-auto">
                                    <div className="row g-0 draggable-btns">
                                        <h5 className="mb-3">Components</h5>

                                        <div className="list-group">

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3" onClick={() => onClickOpenChartComponent("ChartModel")}>
                                                <i className="fas fa-chart-bar fs-4 me-3 text-secondary"></i>
                                                <span>Chart</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3" onClick={() => onClickOpenChartComponent("KpiModel")}>
                                                <i className="fas fa-signal fs-4 me-3 text-secondary"></i>
                                                <span>KPI</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3" onClick={() => onClickOpenChartComponent("Table")}>
                                                <i className="fas fa-table fs-4 me-3 text-secondary"></i>
                                                <span>Table</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3">
                                                <i className="fas fa-balance-scale fs-4 me-3 text-secondary"></i>
                                                <span>Comparator</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3">
                                                <i className="fas fa-chart-line fs-4 me-3 text-primary"></i>
                                                <span>Anomaly Detector</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3" onClick={() => onClickOpenChartComponent("TargetMeter")}>
                                                <i className="fas fa-bullseye fs-4 me-3 text-secondary"></i>
                                                <span>Target Meter</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3">
                                                <i className="fas fa-filter fs-4 me-3 text-secondary"></i>
                                                <span>Funnel</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3">
                                                <i className="fas fa-th-large fs-4 me-3 text-secondary"></i>
                                                <span>Cohort</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3">
                                                <i className="fas fa-th fs-4 me-3 text-secondary"></i>
                                                <span>Quadrant</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3">
                                                <i className="fas fa-th fs-4 me-3 text-secondary"></i>
                                                <span>Zone</span>
                                            </a>

                                            <a className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3">
                                                <i className="fas fa-th fs-4 me-3 text-secondary"></i>
                                                <span>Stage</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <main className="col-md-10 col-lg-10 ms-sm-auto px-md-4 pb-md-4 content-section">
                            <header className="navbar navbar-expand-lg sticky-top bg-white border-bottom border-light-subtle px-0 tb-header">
                                <nav className="container-fluid d-block">
                                    <div className="row">
                                        <div className="col-md-6 mt-2">
                                            <div className="mb-3 form-floating">
                                                <input
                                                    type="text"
                                                    className={`form-control ${dashboardNameDublicate?.length > 0 ? 'is-invalid' : ''}`}
                                                    id="dashboardName"
                                                    placeholder="Enter Dashboard Name"
                                                    value={dashboardName}
                                                    onChange={(e) => setDashboardName(e.target.value)}
                                                    required
                                                />
                                                {dashboardNameDublicate?.length > 0 && <span className="text-danger mt-1">{dashboardNameDublicate}</span>}
                                                <label htmlFor="dashboardName">Dashboard Name</label>
                                            </div>
                                            <div className="mb-3 form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter Description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />
                                                <label htmlFor="description">Description</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex justify-content-end">
                                                <ul className="navbar-nav flex-row btn-group">
                                                    <li className="nav-item text-nowrap">
                                                        <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => navigate(Utils.getNavigationUrl(`analytics`))}>
                                                            <span>Cancel</span>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item text-nowrap">
                                                        <button type="button" className="btn btn-primary rounded-pill btn-blue" disabled={!dashboardName.trim()} onClick={saveDashboard}>
                                                            <span>Save</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </header>
                            <div className="bg-image-box grid-main-container p-0">
                                <ReactGridLayout
                                    className="bg-image-box grid-container p-0"
                                    layout={layout}
                                    isResizable={true}
                                    isDraggable={true}
                                    rowHeight={30}
                                    preventCollision={false}
                                    compactType="vertical"
                                    onLayoutChange={onLayoutChange}
                                    draggableCancel='.top-right-menu, .dropdown-menu, .dropdown ul'
                                    cols={12}
                                >
                                    {componentDatainitialData?.length > 0 && (
                                        componentDatainitialData.map((x, index) => {
                                            let ComponentToRender = DashboardComponents[x?.modal_name];
                                            return ComponentToRender ? (
                                                <div key={x.position.i} className="grid-item">
                                                    <div className="top-right-menu dropdown" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={() => handleToggle(index)}>
                                                        <i className="fa fa-ellipsis-v cursor-pointer"></i>
                                                        {(openDropdown === index) && (
                                                            <div className="dropdown-menu d-block">
                                                                <ul>
                                                                    <li onClick={() => openEditModal(x)}>Edit</li>
                                                                    <li onClick={() => removeData(index)}>Delete</li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="content card">
                                                        <h5 className="card-title fs-16 ps-3 text-start fw-semibold">{x?.company_name}</h5>
                                                        <div className="card-body p-0 overflow-auto">
                                                            <ComponentToRender data={x} isStatic={false} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null;
                                        })
                                    )}
                                </ReactGridLayout>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            {(showModal && OpenChartModel) && <OpenChartModel show={showModal} handleClose={() => {
                setShowModal(false);
                setOpenChartModel(null);
                setIsEditModalData(null);
            }} submitData={(data) => submitData(data)} isData={isEditModalData} allList={componentDatainitialData}/>}
        </>
    )
}

export default CreateDashboard;