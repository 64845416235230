import { useDispatch, useSelector } from "react-redux";
import { getUserByIdStart, updateUserStart, uploadUserProfileStart } from "../../../Store/Slices/userSlice";
import { useEffect, useRef, useState } from "react";
import { getItem } from "../../../Services/localService";
import Loader from "../../../Components/Loader";
import { Helpers } from "../../../Helpers";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import setCanvasPreview from "../../../Components/canvasPreview";
import { Utils } from "../../../Utils";

const ASPECT_RATIO = '';
const MIN_DIMENSION = 150;

const PersonalSettings = () => {
    const dispatch = useDispatch();
    let id = getItem('user')?._id;
    const avatarUrl = useRef();
    let defaultProfile = "https://campussafetyconference.com/wp-content/uploads/2020/08/iStock-476085198.jpg";
    let currentUser = useSelector((state) => state?.user);
    let profilesList = useSelector((state) => state?.profile)?.profileData;
    let roleList = useSelector((state) => state?.role)?.roleData;
    let [oldUserData, setOldUserData] =  useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    let [storeData, setStoreData] = useState({
        moduleId: id,
        data: []
    });
    let [errors, setErrors] = useState({});
    let [displayNameProp, setDisplayNameProp] = useState({
        nameFormat: '',
        sortOrder: ''
    });

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState();
    const [error, setError] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);

    const [userData, setUserData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        Alias: '',
        Date_of_Birth: '',
        Fax: '',
        Mobile: '',
        phone_no: '',
        Profile: '',
        Website: '',
        City: '',
        Country: '',
        State: '',
        Street: '',
        Zip_Code: '',
        Country_Locale: '',
        Date_Format: '',
        Language: '',
        Preferred_Currency: '',
        Shift_Hour: '',
        Time_Format: '',
        Time_Zone: '',
        Decimal: '',
        Grouping: ''
    });

    useEffect(() => {
        setOldUserData(currentUser?.userData);
        
    }, [currentUser?.userData]);

    const formHandler = (e) => {
        try {
            setUserData((prev) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value
            }));
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        if (currentUser?.reload) {
            dispatch(getUserByIdStart(id));
            updateAvatar("");
            setCrop("");
            setImgSrc("");
        }
    }, [currentUser?.reload]);

    useEffect(() => {
        setUserData((prev) => ({
            ...prev,
            email: currentUser?.userData?.email || '',
            firstName: currentUser?.userData?.firstName || '',
            lastName: currentUser?.userData?.lastName || '',
            role: currentUser?.userData?.role?._id || '',
            Alias: currentUser?.userData?.Alias || '',
            Date_of_Birth: currentUser?.userData?.Date_of_Birth || '',
            Fax: currentUser?.userData?.Fax || '',
            Mobile: currentUser?.userData?.Mobile || '',
            phone_no: currentUser?.userData?.phone_no || '',
            Profile: currentUser?.userData?.Profile?._id || '',
            Website: currentUser?.userData?.Website || '',
            City: currentUser?.userData?.City || '',
            Country: currentUser?.userData?.Country || '',
            State: currentUser?.userData?.State || '',
            Street: currentUser?.userData?.Street || '',
            Zip_Code: currentUser?.userData?.Zip_Code || '',
            Country_Locale: currentUser?.userData?.Country_Locale || '',
            Date_Format: currentUser?.userData?.Date_Format || '',
            Language: currentUser?.userData?.Language || '',
            Preferred_Currency: currentUser?.userData?.Preferred_Currency || '',
            Shift_Hour: currentUser?.userData?.Shift_Hour || '',
            Time_Format: currentUser?.userData?.Time_Format || '',
            Time_Zone: currentUser?.userData?.Time_Zone || '',
            Decimal: currentUser?.userData?.Decimal || '',
            Grouping: currentUser?.userData?.Grouping || '',
        }));

        setDisplayNameProp({
            ['nameFormat']: currentUser?.userData?.nameFormat,
            ['sortOrder']: currentUser?.userData?.sortOrder
        });

        const img = new Image();
        img.src = currentUser?.userData?.profilePicture;
        img.onload = () => {
            setImageLoaded(true);
        };
    }, [currentUser])

    const updateAvatar = (imgSrc) => {
        avatarUrl.current = imgSrc;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = async () => {
        // event.preventDefault();
        if (!selectedFile) {
            alert("Please select a file first.");
            return;
        }
        const formData = new FormData();
        formData.append("profile", selectedFile);
        try {
            if (currentUser?.userData) {
                dispatch(uploadUserProfileStart({
                    id: currentUser?.userData?._id, payload: formData
                }))
            }
        } catch (error) {
            console.error("There was an error uploading the file:", error);
        }
    };

    const handleSubmit = () => {
        let payload = userData;
        setOldUserData(userData);
        const auditLog = Utils.logChanges(oldUserData, payload, profilesList, roleList);
        payload.audit_log = { msg: auditLog };
        dispatch(updateUserStart({
            id: currentUser?.userData?._id, payload: payload
        }))
    }

    const displayPropertyHandler = (e) => {
        try {
            setDisplayNameProp((prop) => ({
                ...prop,
                [e.target?.name]: e?.target?.value
            }))

            dispatch(updateUserStart({
                id: currentUser?.userData?._id, payload: { [e.target?.name]: e?.target?.value }
            }))
        } catch (err) {
            throw err;
        }
    }

    const formatDataStructures = (data, isValidate = false) => {
        let format = [];
        let newErrors = {};
        const assignDefaultValues = (item) => {
            let default_value = item?.extra_fields?.default_value;
            if (item?.input_type === 'multi-select' && default_value && default_value !== "None") {
                return [{ name: default_value }];
            } else {
                return default_value;
            }
        }
        if (data?.sections) {
            data?.sections?.forEach((section, sectionIndex) => {
                if (!isValidate) {
                    if (!format?.[sectionIndex]) {
                        // format[sectionIndex] = {
                        //     section_name: section?.section_name,
                        //     section_data: {}
                        // }
                        format[sectionIndex] = {
                            section_name: section?.section_name,
                            section_data: section?.section_data
                        }
                    }
                }
                if (section?.subform) {
                    if (section?.selected_list?.length > 0 && !isValidate) {
                        format[sectionIndex].section_data["table_data"] = [
                            Object.assign({}, ...section.selected_list.map(x => ({
                                [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                            })))
                        ];
                    }
                } else {
                    section?.dropped_list?.[`drop_${sectionIndex}_area_1`]?.forEach((item) => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        if (!isValidate) {
                            format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                        }
                        //  else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                        //     newErrors[field_label] = errors?.[field_label];
                        // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                        //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                        // }
                    });
                    if (section?.layouts === 2) {
                        section?.dropped_list?.[`drop_${sectionIndex}_area_2`]?.forEach((item) => {
                            let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                            if (!isValidate) {
                                format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                            }
                            // else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            //     newErrors[field_label] = errors?.[field_label];
                            // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                            // }
                        });
                    }
                }
            });
        }

        if (data?.data) {
            data?.data?.forEach((section, sectionIndex) => {
                if (!isValidate) {
                    if (!format?.[sectionIndex]) {
                        // format[sectionIndex] = {
                        //     section_name: section?.section_name,
                        //     section_data: {}
                        // }
                        format[sectionIndex] = {
                            section_name: section?.section_name,
                            section_data: section?.section_data
                        }
                    }
                }
                if (section?.subform) {
                    if (section?.selected_list?.length > 0 && !isValidate) {
                        format[sectionIndex].section_data["table_data"] = [
                            Object.assign({}, ...section.selected_list.map(x => ({
                                [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                            })))
                        ];
                    }
                } else {
                    section?.dropped_list?.[`drop_${sectionIndex}_area_1`]?.forEach((item) => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        if (!isValidate) {
                            format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                        }
                        //  else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                        //     newErrors[field_label] = errors?.[field_label];
                        // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                        //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                        // }
                    });
                    if (section?.layouts === 2) {
                        section?.dropped_list?.[`drop_${sectionIndex}_area_2`]?.forEach((item) => {
                            let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                            if (!isValidate) {
                                format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                            }
                            // else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            //     newErrors[field_label] = errors?.[field_label];
                            // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                            // }
                        });
                    }
                }
            });
        }

        if (!data?.sections && !data?.data) {
            let temp = [
                {
                    section_name: "User Information",
                    section_data: {
                        First_Name: currentUser?.userData?.firstName,
                        Last_Name: currentUser?.userData?.lastName,
                        Email: currentUser?.userData?.email,
                        // Role: currentUser?.userData?.role
                    }
                }
            ];

            temp?.forEach((section, sectionIndex) => {
                if (!isValidate) {
                    if (!format?.[sectionIndex]) {
                        format[sectionIndex] = {
                            section_name: section?.section_name,
                            section_data: section?.section_data
                        }
                    }
                }
                if (section?.subform) {
                    if (section?.selected_list?.length > 0 && !isValidate) {
                        format[sectionIndex].section_data["table_data"] = [
                            Object.assign({}, ...section.selected_list.map(x => ({
                                [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                            })))
                        ];
                    }
                } else {
                    section?.dropped_list?.[`drop_${sectionIndex}_area_1`]?.forEach((item) => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        if (!isValidate) {
                            format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                        }
                        //  else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                        //     newErrors[field_label] = errors?.[field_label];
                        // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                        //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                        // }
                    });
                    if (section?.layouts === 2) {
                        section?.dropped_list?.[`drop_${sectionIndex}_area_2`]?.forEach((item) => {
                            let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                            if (!isValidate) {
                                format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                            }
                            // else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            //     newErrors[field_label] = errors?.[field_label];
                            // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                            // }
                        });
                    }
                }
            });
        }
        if (!isValidate) {
            setStoreData({
                moduleId: id,
                data: format,
            });
        } else {
            setErrors(newErrors);
        }
        return Object.keys(newErrors).length === 0;
    }

    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;

            imageElement.addEventListener("load", (e) => {
                if (error) setError("");
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("Image must be at least 150 x 150 pixels.");
                    return setImgSrc("");
                }
            });
            setImgSrc(imageUrl);
        });
        reader.readAsDataURL(file);
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    const base64ToBlob = (base64Data, contentType) => {
        const byteCharacters = atob(base64Data.split(',')[1]); // Remove the base64 header
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    };

    const handleUpdateProfile = () => {
        if (avatarUrl?.current) {
            const contentType = 'image/jpeg';
            const blob = base64ToBlob(avatarUrl?.current, contentType);

            const formData = new FormData();
            formData.append('profile', blob, 'image.jpeg')

            try {
                dispatch(uploadUserProfileStart({
                    id: currentUser?.userData?._id, payload: formData
                }))
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }

    const dynamicProfileStyle = {
        opacity: currentUser?.userData?.profilePicture !== defaultProfile ? '1' : '.3'
    }

    let decimal = currentUser?.userData?.Decimal;
    let grouping = currentUser?.userData?.Grouping;

    return (
        <>
            <Loader isVisible={currentUser?.isLoading} />
            {/* Personal Settings Card */}
            <div className="g-settings">
                <div className="card rounded-4 border-0 p-0 personal-settings">
                    <div className="card-header py-0 fs-14 bg-white rounded-top-4">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item px-3" role="presentation">
                                <button className="nav-link text-primary position-relative px-0 active" id="pills-tab-1"
                                    data-bs-toggle="pill" data-bs-target="#pills-tab1" type="button" role="tab"
                                    aria-controls="pills-tab2" aria-selected="true">Personal Settings</button>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content px-3" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab-1">
                                <div className="row g-0">
                                    <div className="col-md-7 px-3">
                                        {/* User Details Top */}
                                        <div className="d-flex align-items-center py-2 mt-3 user-details">
                                            <div className="photo-upload position-relative d-inline-block cursor-pointer"
                                                data-bs-toggle="modal" data-bs-target="#imgUploadModal">
                                                <div className="hover-img">
                                                    <div className="photo-icon"></div>
                                                </div>
                                                <div className="profile-icon">
                                                    {imageLoaded &&
                                                        <img src={currentUser?.userData?.profilePicture} alt="profile-icon"
                                                            style={dynamicProfileStyle} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="px-3 r-cont">
                                                <div className="fw-semibold user-name pb-1">
                                                    {currentUser?.userData?.firstName + ' ' + currentUser?.userData?.lastName}
                                                    {/* <span className="badge rounded-pill text-bg-warning bg-opacity-25 border border-warning ms-2">Super Admin</span> */}
                                                    <i className="fa-solid fa-pencil ms-2 cursor-pointer"
                                                        data-bs-toggle="modal" data-bs-target="#userInfo"></i>
                                                </div>
                                                <div className="designation text-secondary pb-1">
                                                    {/* <span>{currentUser?.userData?.role}</span> */}
                                                    {/* <span>at DIGY</span> */}
                                                </div>
                                                <div className="user-email">
                                                    <i className="fa-regular fa-envelope me-2"></i>
                                                    {currentUser?.userData?.email}
                                                </div>
                                                <div className="user-ph">
                                                    <i className="fa-solid fa-phone-volume me-2" style={{ transform: "rotate(-30deg)" }}></i>
                                                    {currentUser?.userData?.Mobile}
                                                </div>
                                            </div>
                                        </div>
                                        {/* // User Details Top END // */}

                                        {/* Show / Hide User information */}
                                        <div className="user-details pt-3">
                                            <button type="button"
                                                className="btn btn-link text-decoration-none text-body p-0 text-start collapse-btn"
                                                data-bs-toggle="collapse" data-bs-target="#collapseUserDetail"
                                                aria-expanded="true" aria-controls="collapseUserDetail">
                                                Show Details
                                                <i className="fa-solid fa-chevron-down ms-3"></i>
                                            </button>
                                            <div className="collapse fade show" id="collapseUserDetail">
                                                <h6 className="sec-header fw-semibold pb-3 pt-2">User information</h6>
                                                <dl className="row justify-content-center list-detail">
                                                    <dd className="col-sm-9 col-lg-5">First Name</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.firstName}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Last Name</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.lastName}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Email</dd>
                                                    <dt className="col-sm-3 col-lg-5">
                                                        <a href="mailto:kadinrhuel@gmail.com">{currentUser?.userData?.email}</a>
                                                    </dt>

                                                    <dd className="col-sm-9 col-lg-5">Role</dd>
                                                    {/* <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.role}</dt> */}
                                                    <dt className="col-sm-3 col-lg-5">
                                                        {/* {roleList?.filter((item) => item._id === currentUser?.userData?.Profile)?.[0]?.profile_name} */}
                                                        {currentUser?.userData?.role?.role_name}
                                                    </dt>

                                                    <dd className="col-sm-9 col-lg-5">Profile</dd>
                                                    <dt className="col-sm-3 col-lg-5">
                                                        {/* {profilesList?.filter((item) => item._id === currentUser?.userData?.Profile)?.[0]?.profile_name} */}
                                                        {/* {currentUser?.userData?.Profile} */}
                                                        {currentUser?.userData?.Profile?.profile_name}
                                                    </dt>

                                                    {/* <dd className="col-sm-9 col-lg-5">Added By</dd>
                                                    <dt className="col-sm-3 col-lg-5">
                                                        Kadin Rhiel Madsen <br />
                                                        <span className="text-secondary fw-normal" role="datetime">Mon, 9 Sep 2024 12:28 PM</span>
                                                    </dt> */}

                                                    <dd className="col-sm-9 col-lg-5">Alias</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Alias}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Phone</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.phone_no}</dt>
                                                    {/* <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Phone}</dt> */}

                                                    <dd className="col-sm-9 col-lg-5">Mobile</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Mobile}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Website</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Website}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Fax</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Fax}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Date of Birth</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Date_of_Birth}</dt>
                                                </dl>

                                                <h6 className="sec-header fw-semibold pb-3 pt-2">Address information</h6>
                                                <dl className="row justify-content-center list-detail">
                                                    <dd className="col-sm-9 col-lg-5">Street</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Street}</dt>

                                                    <dd className="col-sm-9 col-lg-5">City</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.City}</dt>

                                                    <dd className="col-sm-9 col-lg-5">State</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.State}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Zip Code</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Zip_Code}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Country</dd>
                                                    <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Country}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                        {/* // Show / Hide User information END // */}

                                        <div className="social pt-3">
                                            <h6 className="sec-header fw-semibold pb-3 pt-2">Social Profiles</h6>
                                            <dl className="row justify-content-center list-detail">
                                                <dd className="col-sm-9 col-lg-5">Twitter</dd>
                                                <dt className="col-sm-3 col-lg-5">
                                                    <a >+ Add</a>
                                                </dt>
                                            </dl>
                                        </div>

                                        <div className="local-info pt-3">
                                            <h6 className="sec-header fw-semibold pb-3 pt-2">
                                                Locale information
                                                <i className="fa-solid fa-pencil ms-2 cursor-pointer"
                                                    data-bs-toggle="modal" data-bs-target="#localeInfo"></i>
                                            </h6>
                                            <dl className="row justify-content-center list-detail">
                                                <dd className="col-sm-9 col-lg-5">Language</dd>
                                                <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Language}</dt>

                                                <dd className="col-sm-9 col-lg-5">Country Locale</dd>
                                                <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Country_Locale}</dt>

                                                <dd className="col-sm-9 col-lg-5">Date Format</dd>
                                                <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Date_Format}</dt>

                                                <dd className="col-sm-9 col-lg-5">Time Format</dd>
                                                <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Time_Format}</dt>

                                                <dd className="col-sm-9 col-lg-5">Time Zone</dd>
                                                <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Time_Zone}</dt>

                                                <dd className="col-sm-9 col-lg-5">Shift Hour</dd>
                                                <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Shift_Hour}</dt>

                                                <dd className="col-sm-9 col-lg-5">Preferred Currency</dd>
                                                <dt className="col-sm-3 col-lg-5">{currentUser?.userData?.Preferred_Currency}</dt>

                                                <dd className="col-sm-9 col-lg-5">Number Format</dd>
                                                <dt className="col-sm-3 col-lg-5">{`123${decimal == 'Comma' ? ',' : '' || decimal == 'Period' ? '.' : ''}456${grouping == 'Comma' ? ',' : '' || grouping == 'Period' ? '.' : '' || grouping == 'Space' ? ' ' : ''}789`}</dt>
                                            </dl>
                                        </div>

                                        <div className="group-info pt-3">
                                            <h6 className="sec-header fw-semibold pb-3 pt-2">Groups</h6>
                                            <dl className="row justify-content-center list-detail">
                                                <dd className="col-sm-9 col-lg-5">Member in</dd>
                                                <dt className="col-sm-3 col-lg-5">-</dt>
                                            </dl>
                                        </div>

                                        <div className="prefer-info pt-3">
                                            <h6 className="sec-header fw-semibold pb-0 pt-2">Display Name Format & Preferences</h6>
                                            <p>It applies to full name in column of list view, lookup fields and user name</p>
                                            <div className="mb-3 row">
                                                <label htmlFor="nameFormat" className="col-sm-4 text-sm-end col-form-label">Name Format</label>
                                                <div className="col-sm-8">
                                                    <select className="form-select" id="nameFormat" aria-label="Name Format" name="nameFormat" value={displayNameProp?.nameFormat}
                                                        onChange={(e) => { displayPropertyHandler(e) }}>
                                                        {/* <option selected="" value="">Name Format</option> */}
                                                        <option value="Salutation,First Name,Last Name">Salutation, First Name, Last Name</option>
                                                        <option value="Salutation,Last Name,First Name">Salutation, Last Name, First Name</option>
                                                        <option value="First Name,Salutation,Last Name">First Name, Salutation, Last Name</option>
                                                        <option value="First Name,Last Name,Salutation">First Name, Last Name, Salutation</option>
                                                        <option value="Last Name,Salutation,First Name">Last Name, Salutation, First Name</option>
                                                        <option value="Last Name,First Name,Salutation">Last Name, First Name, Salutation</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="sortOrderPrefer" className="col-sm-4 text-sm-end col-form-label">Sort order preference</label>
                                                <div className="col-sm-8">
                                                    <select className="form-select" id="sortOrderPrefer" aria-label="Sort Order Prefer" name="sortOrder"
                                                        value={displayNameProp.sortOrder} onChange={(e) => { displayPropertyHandler(e) }}>
                                                        <option selected="" value="First Name,Last Name">First Name, Last Name</option>
                                                        <option value="Last Name,First Name">Last Name, First Name</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Photo Upload Modal */}
                <div className="modal fade" id="imgUploadModal" tabIndex="-1" aria-labelledby="imgUploadModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h4 className="modal-title fs-5 fw-semibold" id="imgUploadModalLabel">Select Image</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body pt-0">

                                {!imgSrc &&
                                    <div className="file-upload-content">
                                        <div className="mb-4">Upload your photo here.</div>
                                        <span className="btn btn-primary btn-file">
                                            Browse Local Files <input type="file" accept="image/*" onChange={onSelectFile} />
                                        </span>
                                    </div>
                                }

                                {imgSrc && (
                                    <div className="flex flex-col items-center">
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                                            aspect={ASPECT_RATIO}
                                            minWidth={MIN_DIMENSION}
                                        >
                                            <img
                                                ref={imgRef}
                                                src={imgSrc}
                                                alt="Upload"
                                                style={{ maxHeight: "70vh" }}
                                                onLoad={onImageLoad}
                                            />
                                        </ReactCrop>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline"
                                                onClick={() => {
                                                    setCanvasPreview(
                                                        imgRef.current, // HTMLImageElement
                                                        previewCanvasRef.current, // HTMLCanvasElement
                                                        convertToPixelCrop(
                                                            crop,
                                                            imgRef.current.width,
                                                            imgRef.current.height
                                                        )
                                                    );
                                                    const dataUrl = previewCanvasRef.current.toDataURL();
                                                    updateAvatar(dataUrl);
                                                    handleUpdateProfile()
                                                    // closeModal();
                                                }}
                                                data-bs-dismiss="modal"
                                            >
                                                Crop and Set
                                            </button>
                                            <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline ms-2" onClick={() => { setCrop(""); setImgSrc("") }} data-bs-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                )}

                                {crop && (
                                    <canvas
                                        ref={previewCanvasRef}
                                        className="mt-4"
                                        style={{
                                            display: "none",
                                            border: "1px solid black",
                                            objectFit: "contain",
                                            width: 150,
                                            height: 150,
                                        }}
                                    />
                                )}
                                {/* <form action="#" method="post"> */}
                                {/* {!previewImage ?
                                    <div className="file-upload-content">
                                        <div className="mb-4">Upload your photo here.</div>
                                        <span className="btn btn-primary btn-file">
                                            Browse Local Files <input type="file" accept="image/*" onChange={handleFileChange} />
                                        </span>
                                    </div>
                                    :
                                    <>
                                        <div>
                                            <h3>Image Preview:</h3>
                                            <img src={previewImage} alt="Profile Preview" style={{ width: "150px" }} />
                                        </div>
                                        <div className="float-end">
                                            <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline" onClick={() => { handleUpload(); setPreviewImage(null); setSelectedFile(null) }} data-bs-dismiss="modal">Upload</button>
                                            <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline ms-2" data-bs-dismiss="modal" onClick={() => { setPreviewImage(null); setSelectedFile(null) }}>Cancel</button>
                                        </div>
                                    </>
                                } */}
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Photo Upload Modal // */}
            </div>

            {/* user modal */}
            <div className="modal fade" id="userInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="comInfoLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable com-details-form">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fs-5" id="comInfoLabel">Account Information</h5>
                        </div>
                        <div className="modal-body pb-0">

                            <div className="row mb-40">
                                <h6 className="sec-header pb-3 pt-2 col-12">User Information</h6>
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <label htmlFor="firstName" className="col-auto col-form-label">First Name</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="firstName" required name="firstName"
                                                onChange={formHandler} value={userData?.firstName} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="lastName" className="col-auto col-form-label">Last Name</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="lastName" name="lastName"
                                                onChange={formHandler} value={userData?.lastName} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="emailBox" className="col-auto col-form-label">Email</label>
                                        <div className="col-auto input-control">
                                            <input type="email" className="form-control" id="emailBox" required name="email"
                                                onChange={formHandler} value={userData?.email} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="role" className="col-auto col-form-label">Role</label>
                                        <div className="col-auto input-control">
                                            {/* <input type="text" className="form-control" id="role" required name="role"
                                                onChange={formHandler} value={userData?.role} /> */}
                                            <select className="form-select" required
                                                name='role' value={userData?.role} onChange={formHandler}
                                            >
                                                <option value="">Select Role</option>
                                                {
                                                    roleList?.length > 0 && roleList?.map((data) => (
                                                        <option value={data?._id}>{data?.role_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="profile" className="col-auto col-form-label">Profile</label>
                                        <div className="col-auto input-control">
                                            <select className="form-select"
                                                name='Profile' value={userData?.Profile} onChange={formHandler}
                                            >
                                                <option value="">Select Profile</option>
                                                {
                                                    profilesList?.length > 0 && profilesList?.map((data) => (
                                                        <option value={data?._id}>{data?.profile_name}</option>
                                                    ))
                                                }
                                            </select>
                                            {/* <input type="text" className="form-control" id="profile" required name="Profile"
                                                onChange={formHandler} value={userData?.Profile} /> */}
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="alias" className="col-auto col-form-label">Alias</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="alias" name="Alias"
                                                onChange={formHandler} value={userData?.Alias} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="phone" className="col-auto col-form-label">Phone</label>
                                        <div className="col-auto input-control">
                                            <input type="number" className="form-control" id="phone" name="phone_no"
                                                onChange={formHandler} value={userData?.phone_no} />
                                            {/* <input type="number" className="form-control" id="phone" name="Phone"
                                                onChange={formHandler} value={userData?.Phone} /> */}
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="mobNum" className="col-auto col-form-label">Mobile</label>
                                        <div className="col-auto input-control">
                                            <input type="number" className="form-control" id="mobNum" name="Mobile"
                                                onChange={formHandler} value={userData?.Mobile} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="website" className="col-auto col-form-label">Website</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="website" name="Website"
                                                onChange={formHandler} value={userData?.Website} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="fax" className="col-auto col-form-label">Fax</label>
                                        <div className="col-auto input-control">
                                            <input type="number" className="form-control" id="fax" name="Fax"
                                                onChange={formHandler} value={userData?.Fax} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="dob" className="col-auto col-form-label">Date of Birth</label>
                                        <div className="col-auto input-control">
                                            <input type="date" className="form-control" id="dob" name="Date_of_Birth"
                                                onChange={formHandler} value={userData?.Date_of_Birth} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-40">
                                <h6 className="sec-header pb-3 pt-2 col-12">Address Information</h6>
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <label htmlFor="street" className="col-auto col-form-label">Street</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="street" name="Street"
                                                onChange={formHandler} value={userData?.Street} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="city" className="col-auto col-form-label">City</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="city" name="City"
                                                onChange={formHandler} value={userData?.City} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="state" className="col-auto col-form-label">State</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="state" name="State"
                                                onChange={formHandler} value={userData?.State} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="zipCode" className="col-auto col-form-label">Zip Code</label>
                                        <div className="col-auto input-control">
                                            <input type="number" maxLength={6} className="form-control" id="zipCode" name="Zip_Code"
                                                onChange={formHandler} value={userData?.Zip_Code} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="country" className="col-auto col-form-label">Country</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="country" name="Country"
                                                onChange={formHandler} value={userData?.Country} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* subform::start */}
                            {/* {Helpers.arrayLength(listItems?.sections) && (
                                listItems.sections.map((section, sectionIndex) =>
                                    (section?.section_name == 'User Information' || section?.section_name == 'Address Information') && (
                                        <div className="row mb-40" key={sectionIndex}>
                                            <h6 className="sec-header pb-3 pt-2 col-12">{section?.section_name}</h6>
                                            <>
                                                <div className={`col-md-${section?.layouts === 1 ? '12' : '6 left-side'}`}>
                                                    {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_1`]) && (
                                                        section.dropped_list[`drop_${sectionIndex}_area_1`].map(area1 => (
                                                            <ModalCustomInput
                                                                key={area1?.id}
                                                                items={area1}
                                                                sectionIndex={sectionIndex}
                                                                storeData={storeData}
                                                                setStoreData={setStoreData}
                                                                error={errors[Helpers.replaceData(area1.extra_fields.field_label)]}
                                                                listItems={listItems}
                                                                setErrors={setErrors}
                                                                preview={false}
                                                                subFormData={subFormData}
                                                            />))
                                                    )}
                                                </div>
                                                {section?.layouts === 2 && (
                                                    <div className="col-md-6 right-side">
                                                        {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_2`]) && (
                                                            section.dropped_list[`drop_${sectionIndex}_area_2`].map(area2 => (
                                                                <ModalCustomInput
                                                                    key={area2?.id}
                                                                    items={area2}
                                                                    sectionIndex={sectionIndex}
                                                                    storeData={storeData}
                                                                    setStoreData={setStoreData}
                                                                    error={errors[Helpers.replaceData(area2.extra_fields.field_label)]}
                                                                    listItems={listItems}
                                                                    setErrors={setErrors}
                                                                    preview={false}
                                                                    subFormData={subFormData}
                                                                />
                                                            ))
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    ))
                            )} */}
                            {/* subform::end */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSubmit()} data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* locale modal */}
            <div className="modal fade" id="localeInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="comInfoLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable com-details-form">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fs-5" id="comInfoLabel">Locale Information</h5>
                        </div>
                        <div className="modal-body pb-0">
                            <form>
                                <div className="row mb-40">
                                    <h6 className="sec-header pb-3 pt-2 col-12">Locale Information</h6>
                                    <div className="col-md-12">
                                        <div className="form-group mb-4">
                                            <label htmlFor="language" className="col-auto col-form-label">Language</label>
                                            <div className="col-auto input-control">
                                                <select className="form-select" id="language" name="Language"
                                                    onChange={formHandler} value={userData?.Language}>
                                                    <option value="">Select</option>
                                                    <option value="English (United Kingdom)">English (United Kingdom)</option>
                                                    <option value="English (United States)">English (United States)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="countryLocale" className="col-auto col-form-label">Country Locale</label>
                                            <div className="col-auto input-control">
                                                <select className="form-select" id="countryLocale" name="Country_Locale"
                                                    onChange={formHandler} value={userData?.Country_Locale}>
                                                    <option value="">Select</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="dateFormat" className="col-auto col-form-label">Date Format</label>
                                            <div className="col-auto input-control">
                                                <select className="form-select" id="dateFormat" name="Date_Format"
                                                    onChange={formHandler} value={userData?.Date_Format}>
                                                    <option value="">Select</option>
                                                    <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                                                    <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="timeFormat" className="col-auto col-form-label" >Time Format</label>
                                            <div className="col-auto input-control">
                                                <select className="form-select" id="timeFormat" name="Time_Format"
                                                    onChange={formHandler} value={userData?.Time_Format}>
                                                    <option value="">Select</option>
                                                    <option value="12 Hours">12 Hours</option>
                                                    <option value="24 Hours">24 Hours</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="timeZone" className="col-auto col-form-label">Time Zone</label>
                                            <div className="col-auto input-control">
                                                <select className="form-select" id="timeZone" name="Time_Zone"
                                                    onChange={formHandler} value={userData?.Time_Zone}>
                                                    <option value="">Select</option>
                                                    <option value="(GMT 5:30) India Standard Time (Asia/Kolkata)">(GMT 5:30) India Standard Time (Asia/Kolkata)</option>
                                                    <option value="(GMT 5:30) India Standard Time (IST)">(GMT 5:30) India Standard Time (IST)</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-40">
                                    <h6 className="sec-header pb-3 pt-2 col-12">Number Format</h6>
                                    <div className="col-md-12">
                                        <div className="form-group mb-4">
                                            <label htmlFor="grouping" className="col-auto col-form-label">Grouping</label>
                                            <div className="col-auto input-control">
                                                <select className="form-select" id="grouping" name="Grouping"
                                                    onChange={formHandler} value={userData?.Grouping}>
                                                    <option value="">Select</option>
                                                    <option value="Space">Space</option>
                                                    <option value="Comma">Comma</option>
                                                    <option value="Period">Period</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="decimal" className="col-auto col-form-label">Decimal</label>
                                            <div className="col-auto input-control">
                                                <select className="form-select" id="decimal" name="Decimal"
                                                    onChange={formHandler} value={userData?.Decimal}>
                                                    <option value="">Select</option>
                                                    <option value="Comma">Comma</option>
                                                    <option value="Period">Period</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSubmit()} data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* // Personal Settings Card END // */}
        </>
    )
}
export default PersonalSettings;