import { call, put } from "redux-saga/effects";
import { createDashboardComponentSuccess, createDashboardComponentFailure, updateDashboardComponentSuccess, updateDashboardComponentFailure, getAllDashboardComponentSuccess, getAllDashboardComponentFailure, getByIdDashboardComponentSuccess, getByIdDashboardComponentFailure, deleteDashboardComponentSuccess, deleteDashboardComponentFailure, getAllSubFormModulesSuccess, getAllSubFormModulesFailure, getDashboardModuleByIdSuccess, getDashboardModuleByIdFailure } from "../../../Slices/dashboardComponentsSlice";
import { createDashboardComponentLayoutSuccess, createDashboardComponentLayoutFailure, updateDashboardComponentLayoutSuccess, updateDashboardComponentLayoutFailure, getAllDashboardComponentLayoutSuccess, getAllDashboardComponentLayoutFailure, getByIdDashboardComponentLayoutSuccess, getByIdDashboardComponentLayoutFailure, deleteDashboardComponentLayoutSuccess, deleteDashboardComponentLayoutFailure } from "../../../Slices/dashboardComponentsSlice";
import { getAllSubFormModules, createDashboardComponent, updateDashboardComponent, getAllDashboardComponent, getByIdDashboardComponent, deleteDashboardComponent, getModuleById } from "../../../../Services/apiService";
import { createDashboardComponentLayout, updateDashboardComponentLayout, getAllDashboardComponentLayout, getByIdDashboardComponentLayout, deleteDashboardComponentLayout } from "../../../../Services/apiService";

export function* getDashboardModuleByIdHandler(actions) {
    try {
        let response = yield call(getModuleById, actions?.payload);
        yield put(getDashboardModuleByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getDashboardModuleByIdFailure(errResponse))
    }
}

export function* getAllSubFormModulesHandler(actions) {
    try {
        let response = yield call(getAllSubFormModules, actions?.payload);
        yield put(getAllSubFormModulesSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getAllSubFormModulesFailure(errResponse))
    }
}

export function* createDashboardComponentHandler(actions) {
    try {
        let response = yield call(createDashboardComponent, actions?.payload);
        yield put(createDashboardComponentSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createDashboardComponentFailure(errResponse))
    }
}

export function* updateDashboardComponentHandler(actions) {
    try {
        let response = yield call(updateDashboardComponent, actions?.payload);
        yield put(updateDashboardComponentSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateDashboardComponentFailure(errResponse))
    }
}

export function* getAllDashboardComponentHandler(actions) {
    try {
        let response = yield call(getAllDashboardComponent, actions?.payload);
        yield put(getAllDashboardComponentSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getAllDashboardComponentFailure(errResponse))
    }
}

export function* getByIdDashboardComponentHandler(actions) {
    try {
        let response = yield call(getByIdDashboardComponent, actions?.payload);
        yield put(getByIdDashboardComponentSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getByIdDashboardComponentFailure(errResponse))
    }
}

export function* deleteDashboardComponentHandler(actions) {
    try {
        let response = yield call(deleteDashboardComponent, actions?.payload);
        yield put(deleteDashboardComponentSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(deleteDashboardComponentFailure(errResponse))
    }
}

export function* createDashboardComponentLayoutHandler(actions) {
    try {
        let response = yield call(createDashboardComponentLayout, actions?.payload);
        yield put(createDashboardComponentLayoutSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createDashboardComponentLayoutFailure(errResponse))
    }
}

export function* updateDashboardComponentLayoutHandler(actions) {
    try {
        let response = yield call(updateDashboardComponentLayout, actions?.payload);
        yield put(updateDashboardComponentLayoutSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateDashboardComponentLayoutFailure(errResponse))
    }
}

export function* getAllDashboardComponentLayoutHandler(actions) {
    try {
        let response = yield call(getAllDashboardComponentLayout, actions?.payload);
        yield put(getAllDashboardComponentLayoutSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getAllDashboardComponentLayoutFailure(errResponse))
    }
}

export function* getByIdDashboardComponentLayoutHandler(actions) {
    try {
        let response = yield call(getByIdDashboardComponentLayout, actions?.payload);
        yield put(getByIdDashboardComponentLayoutSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getByIdDashboardComponentLayoutFailure(errResponse))
    }
}

export function* deleteDashboardComponentLayoutHandler(actions) {
    try {
        let response = yield call(deleteDashboardComponentLayout, actions?.payload);
        yield put(deleteDashboardComponentLayoutSuccess(response?.data))
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(deleteDashboardComponentLayoutFailure(errResponse))
    }
}