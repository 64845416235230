import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Utils } from "../../Utils";

const PermissionDeniedModal = () => {
    const navigate = useNavigate();
    return (
        <>
            <Modal
                show={true}
                cancel={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                top
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Permission Denied</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>You do not have sufficient permissions to perform this operation. Contact your administrator.</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary me-2px rounded-start btn-digy-dark" onClick={() => navigate(Utils.getNavigationUrl(`dashboard`))}>OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default PermissionDeniedModal;