import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFiscalYearStart, getFiscalYearStart, updateFiscalYearStart } from "../../../Store/Slices/fiscalYearSlice";
import Loader from "../../../Components/Loader";
import { getCurrentYearRange, getNextYearRange, getPreviousYearRange, formatDate, formatFilterDate, extractNumber } from "../../../Components/DateFn";
import { Utils } from "../../../Utils";
const FiscalYear = () => {
    const dispatch = useDispatch();
    let companyId = useSelector((state) => state?.org)?.companyData?.[0]?._id;
    let currentFiscal = useSelector((state) => state?.fiscalyear);
    let currentYear = new Date().getFullYear();
    let tempCalendarData = [];

    const [show, setShow] = useState(true);
    const { previousStartDate, previousEndDate } = getPreviousYearRange();
    const { currentStartDate, currentEndDate } = getCurrentYearRange();
    const { nextStartDate, nextEndDate } = getNextYearRange();

    let QuarterBasedFormat = [
        { value: '4-4-5', data: 'In each quarter, Period 1 has 4 weeks, Period 2 has 4 weeks, Period 3 has 5 weeks' },
        { value: '4-5-4', data: 'In each quarter, Period 1 has 4 weeks, Period 2 has 5 weeks, Period 3 has 4 weeks' },
        { value: '5-4-4', data: 'In each quarter, Period 1 has 5 weeks, Period 2 has 4 weeks, Period 3 has 4 weeks' },
    ]

    let YearBasedFormat = [
        { value: '3-3-3-4', data: 'Quarter 1 has 3 periods, Quarter 2 has 3 periods, Quarter 3 has 3 periods, Quarter 4 has 4 periods' },
        { value: '3-3-4-3', data: 'Quarter 1 has 3 periods, Quarter 2 has 3 periods, Quarter 3 has 4 periods, Quarter 4 has 3 periods' },
        { value: '3-4-3-3', data: 'Quarter 1 has 3 periods, Quarter 2 has 4 periods, Quarter 3 has 3 periods, Quarter 4 has 3 periods' },
        { value: '4-3-3-3', data: 'Quarter 1 has 4 periods, Quarter 2 has 3 periods, Quarter 3 has 3 periods, Quarter 4 has 3 periods' }
    ]

    const [fiscalYearData, setFiscalYearData] = useState({
        fiscalYearType: 'standard',
        standard: {
            fiscalYearBegins: 'january',
            fiscalYearBased: 'starting_year'
        },
        custom: {
            fiscalYearBegins: '',
            formatValue: QuarterBasedFormat?.[0]?.value,
            formatData: QuarterBasedFormat?.[0]?.data,
            startDate: '',
            startMonth: '',
            fiscalYearBased: 'starting_year',
            periodDisplayOption: 'number_by_year',
            periodValue: ''
        }
    });

    let tempArr = [];
    let tempArr1 = [];

    useEffect(() => {
        dispatch(getFiscalYearStart());
    }, []);

    useEffect(() => {
        if (fiscalYearData?.custom?.periodDisplayOption === 'number_by_year') {
            tempArr = [];
            tempArr1 = [];
            quarters.map((quarter, qIndex) => {
                tempArr = []
                quarter.map(({ year, month, weeks }, index) => {
                    if (!tempArr1.length) {
                        if (!tempArr.length) {
                            tempArr.push(`P${index + 1}`)
                        } else {
                            let recent = extractNumber(tempArr[tempArr.length - 1]);
                            tempArr.push(`P${recent + 1}`)
                        }
                    } else {
                        if (!tempArr.length) {
                            let recent = tempArr1[tempArr1.length - 1];
                            let recent1 = recent[recent.length - 1];
                            let recent2 = extractNumber(recent1)
                            tempArr.push(`P${recent2 + 1}`)
                        } else {
                            let recent = extractNumber(tempArr[tempArr.length - 1]);
                            tempArr.push(`P${recent + 1}`)
                        }
                    }
                })
                tempArr1.push(tempArr)
            })
        }
        if (fiscalYearData?.custom?.periodDisplayOption === 'number_by_quarter') {
            tempArr = [];
            tempArr1 = [];
            quarters.map((quarter, qIndex) => {
                tempArr = []
                quarter.map(({ year, month, weeks }, index) => {
                    tempArr.push(`Q${qIndex + 1}P${index + 1}`)
                })
                tempArr1.push(tempArr)
            })
        }
    }, [fiscalYearData?.custom?.periodDisplayOption])

    useEffect(() => {
        if (currentFiscal?.reload) {
            dispatch(getFiscalYearStart());
            setShow(true)
        }
    }, [currentFiscal?.reload]);

    useEffect(() => {
        setShow(true)
        let current_fiscal = currentFiscal?.data?.[0]
        setFiscalYearData((prev) => ({
            ...prev,
            fiscalYearType: current_fiscal?.fiscalYearType
        }));
        if (current_fiscal?.fiscalYearType == 'standard')
            setFiscalYearData((prev) => ({
                ...prev,
                standard: {
                    ...prev.standard,
                    fiscalYearBegins: current_fiscal?.standard?.fiscalYearBegins,
                    fiscalYearBased: current_fiscal?.standard?.fiscalYearBased
                }
            }));
        if (current_fiscal?.fiscalYearType == 'custom')
            setFiscalYearData((prev) => ({
                ...prev,
                custom: {
                    ...prev.custom,
                    fiscalYearBegins: current_fiscal?.custom?.fiscalYearBegins,
                    formatValue: current_fiscal?.custom?.formatValue,
                    formatData: current_fiscal?.custom?.formatData,
                    startDate: current_fiscal?.custom?.startDate,
                    startMonth: current_fiscal?.custom?.startMonth,
                    fiscalYearBased: current_fiscal?.custom?.fiscalYearBased,
                    periodDisplayOption: current_fiscal?.custom?.periodDisplayOption,
                    periodValue: current_fiscal?.custom?.periodValue
                }
            }));
    }, [currentFiscal?.data?.length])

    const formatHandler = (value, data) => {
        try {
            setFiscalYearData((prev) => ({
                ...prev,
                custom: {
                    ...prev.custom,
                    formatValue: value,
                    formatData: data,
                }
            }));
        } catch (err) {
            throw err;
        }
    }

    const formHandler = (e) => {
        try {
            if (e?.target?.name == 'fiscalYearType') {
                setFiscalYearData((prev) => ({
                    ...prev,
                    [e?.target?.name]: e?.target?.value
                }));

                setFiscalYearData((prev) => ({
                    ...prev,
                    standard: {
                        ...prev.standard,
                        fiscalYearBegins: 'january',
                        fiscalYearBased: 'starting_year'
                    }
                }));

                setFiscalYearData((prev) => ({
                    ...prev,
                    custom: {
                        ...prev.custom,
                        fiscalYearBegins: '',
                        // formatValue: QuarterBasedFormat?.[0]?.value,
                        // formatData: QuarterBasedFormat?.[0]?.data,
                        startDate: '',
                        startMonth: '',
                        fiscalYearBased: 'starting_year',
                        periodDisplayOption: 'number_by_year',
                        periodValue: ''
                    }
                }));
            }
            if (fiscalYearData?.fiscalYearType == 'standard' && e?.target?.name !== 'fiscalYearType') {
                setFiscalYearData((prev) => ({
                    ...prev,
                    standard: {
                        ...prev.standard,
                        [e?.target?.name]: e?.target?.value
                    }
                }));
            }
            if (fiscalYearData?.fiscalYearType == 'custom' && e?.target?.name !== 'fiscalYearType') {
                setFiscalYearData((prev) => ({
                    ...prev,
                    custom: {
                        ...prev.custom,
                        [e?.target?.name]: e?.target?.value
                    }
                }));
            }
            if (e?.target?.name == 'startDate') {
                if (fiscalYearData?.custom?.startMonth !== '') {
                    let year = new Date().getFullYear()
                    let date = `${year}-${fiscalYearData?.custom?.startMonth}-${e?.target?.value}`;
                    let sDate = new Date(date);
                    let eDate = new Date(sDate);
                    eDate.setFullYear(sDate.getFullYear() + 1);
                    let s_date = formatFilterDate(sDate);
                    let e_date = formatFilterDate(eDate)

                    setDateFilter((prev) => ({
                        ...prev,
                        startDate: s_date,
                        endDate: e_date
                    }));
                }
            }
            if (e?.target?.name == 'startMonth') {
                if (fiscalYearData?.custom?.startDate !== '') {
                    let year = new Date().getFullYear()
                    let date = `${year}-${e?.target?.value}-${fiscalYearData?.custom?.startDate}`;
                    let sDate = new Date(date);
                    let eDate = new Date(sDate);
                    eDate.setFullYear(sDate.getFullYear() + 1);
                    let s_date = formatFilterDate(sDate);
                    let e_date = formatFilterDate(eDate)

                    setDateFilter((prev) => ({
                        ...prev,
                        startDate: s_date,
                        endDate: e_date
                    }));
                }
            }
        } catch (err) {
            throw err;
        }
    };

    const handleSubmitFiscalYear = () => {
        if (currentFiscal?.data?.length) {
            if (fiscalYearData?.fiscalYearType == 'standard') {
                const { custom, ...rest } = fiscalYearData;

                let payload = rest
                payload['organization'] = companyId
                payload['custom'] = {}
                const oldData = currentFiscal.data[0];
                const newData = { ...oldData, ...payload };
                const auditLog = Utils.logUpdatedFields(oldData, newData, 'fiscalYear', 'updateStandard');
                payload.audit_log = auditLog;
                dispatch(updateFiscalYearStart({
                    id: currentFiscal?.data?.[0]?._id, payload: payload
                }))
            } else if (fiscalYearData?.fiscalYearType == 'custom') {
                const { standard, ...rest } = fiscalYearData;

                let payload = rest
                payload['organization'] = companyId
                payload['standard'] = {}
                const oldData = currentFiscal.data[0];
                const newData = { ...oldData, ...payload };
                const auditLog = Utils.logUpdatedFields(oldData, newData, 'fiscalYear', 'updateCustom');
                payload.audit_log = auditLog;
                dispatch(updateFiscalYearStart({
                    id: currentFiscal?.data?.[0]?._id, payload: payload
                }))
            }
        } else {
            if (fiscalYearData?.fiscalYearType == 'standard') {
                const { custom, ...rest } = fiscalYearData;
                let payload = rest
                payload['organization'] = companyId
                payload['custom'] = {}
                dispatch(createFiscalYearStart(payload))
            } else if (fiscalYearData?.fiscalYearType == 'custom') {
                const { standard, ...rest } = fiscalYearData;
                let payload = rest
                payload['organization'] = companyId
                payload['standard'] = {}
                dispatch(createFiscalYearStart(payload))
            }
        }
    }

    const [dateFilter, setDateFilter] = useState({
        filterType: 'current',
        startDate: currentStartDate,
        endDate: currentEndDate
    })

    // Helper function to generate weeks for a specific month with 4 or 5 weeks
    const generateWeeksForMonth = (year, month, weeksCount, startDate, endDate) => {
        let currentDay;
        const weeks = [];
        const firstDayOfMonth = new Date(year, month, 1);
        const firstValidDate = new Date(startDate);
        const lastValidDate = new Date(endDate);

        const firstWeekDay = firstDayOfMonth.getDay(); // Day of the week (0: Sunday, 1: Monday, ...)
        if (tempCalendarData.length == 0) {
            currentDay = new Date(startDate);
        } else if (tempCalendarData.length > 0) {
            let data1; let data2; let data3; let data4; let data5;
            data1 = tempCalendarData;
            data2 = data1[data1.length - 1].weeks;
            data3 = data2[data2.length - 1];
            data4 = data3[data3.length - 1];
            currentDay = new Date(data4);
            currentDay.setDate(currentDay.getDate() + 1)
        }

        // let currentDay = new Date(firstDayOfMonth);
        // currentDay.setDate(currentDay.getDate() - firstWeekDay);


        // currentDay.setDate(currentDay.getDate() - firstWeekDay); // Move back to the Sunday before the first day of the month

        // Generate calendar weeks
        while (weeks.length < weeksCount) {
            const week = [];

            for (let i = 0; i < 7; i++) {
                const day = new Date(currentDay);

                // Show the day only if it falls within the start and end range
                if (day >= firstValidDate && day <= lastValidDate) {
                    week.push(day);
                } else {
                    week.push(null); // Fill with null for dates outside the range
                }

                currentDay.setDate(currentDay.getDate() + 1);
            }

            weeks.push(week);
        }
        return weeks;
    };

    // Utility function to split months into quarters
    const splitIntoQuarters = (months) => {
        const quarters = [];
        // const temp = [3, 4, 3, 3]
        const temp = fiscalYearData.custom.formatValue.split('-').map(Number);
        let inx = 0
        for (let i = 0; i < months.length; i += 3) {
            if (temp.length == 4) {
                quarters.push(months.slice(i, i + temp[inx]));
                inx++;
            } else if (temp.length == 3) {
                quarters.push(months.slice(i, i + 3));
            }
            // quarters.push(months.slice(i, i + (i == 0 ? temp[i] : temp[i - 3])));
        }
        // for (let i = 0; i < months.length; i += 3) {
        //     quarters.push(months.slice(i, i + 3));
        // }
        return quarters;
    };

    const [calendarData, setCalendarData] = useState([]);

    const generateCalendarForRange = (start, end) => {
        const calendar = [];
        let currentDate = new Date(start); // Start from the first valid date
        let monthIndex = 0;
        while (currentDate <= end) {
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            let weeksCount;
            let formatValue = fiscalYearData?.custom?.formatValue;
            // First month of each quarter gets 4 weeks, others get 5 weeks
            // const weeksCount = (monthIndex % 3 === 0) ? 5 : 4;

            if (formatValue == '4-4-5') {
                if (monthIndex === 0 || monthIndex == 1 || monthIndex == 3 || monthIndex == 4 ||
                    monthIndex == 6 || monthIndex == 7 || monthIndex == 9 || monthIndex == 10 ||
                    monthIndex === 12 || monthIndex == 13 || monthIndex == 15 || monthIndex == 15
                ) {
                    weeksCount = 4
                } else {
                    weeksCount = 5
                }
            }
            if (formatValue == '4-5-4') {
                if (monthIndex === 0 || monthIndex == 2 || monthIndex == 3 || monthIndex == 5 ||
                    monthIndex == 6 || monthIndex == 8 || monthIndex == 9 || monthIndex == 11 ||
                    monthIndex === 12 || monthIndex == 14 || monthIndex == 15 || monthIndex == 17
                ) {
                    weeksCount = 4
                } else {
                    weeksCount = 5
                }
            }
            if (formatValue == '5-4-4') {
                if (monthIndex === 1 || monthIndex == 2 || monthIndex == 4 || monthIndex == 5 ||
                    monthIndex == 7 || monthIndex == 8 || monthIndex == 10 || monthIndex == 11 ||
                    monthIndex === 13 || monthIndex == 14 || monthIndex == 16 || monthIndex == 17
                ) {
                    weeksCount = 4
                } else {
                    weeksCount = 5
                }
            }
            if (formatValue !== '4-4-5' && formatValue !== '4-5-4' && formatValue !== '5-4-4') {
                weeksCount = 4
            }
            const weeks = generateWeeksForMonth(year, month, weeksCount, dateFilter?.startDate, dateFilter?.endDate);

            tempCalendarData.push({ year, month, weeks });
            calendar.push({ year, month, weeks });
            monthIndex++;

            // Move to the next month
            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(1); // Reset to the first day of the next month
        }
        setCalendarData(calendar);
    };

    useEffect(() => {
        generateCalendarForRange(new Date(dateFilter?.startDate), new Date(dateFilter?.endDate));
    }, [dateFilter?.startDate, dateFilter?.endDate, fiscalYearData?.custom?.formatValue]);

    // Split the calendar data into quarters
    const quarters = splitIntoQuarters(calendarData);

    const calendarHandler = (e) => {
        let value = e?.target?.value
        setDateFilter((prev) => ({
            ...prev,
            filterType: value,
            startDate: value == 'current' && currentStartDate || value == 'previous' && previousStartDate || value == 'next' && nextStartDate,
            endDate: value == 'current' && currentEndDate || value == 'previous' && previousEndDate || value == 'next' && nextEndDate
        }));
    }

    // calendar fb::end

    var arr = new Array(12).fill().map((d, i) => ++i)
    var arr1 = new Array(3).fill().map((d, i) => ++i)
    var arr2 = new Array(30).fill().map((d, i) => ++i)

    let days = [];

    // {
    // quarters.map((quarter, qIndex) => {
    //     if (qIndex == 0)
    //         quarter.map(({ year, month, weeks }, index) => {
    //             if (index == 0)
    //                 weeks.map((week, weekIndex) => {
    //                     if (weekIndex == 0) {
    //                         week.map((day, dayIndex) => {
    //                             days.push(new Date(day).toLocaleString('en-us', { weekday: 'short' }))
    //                         })
    //                     }
    //                 })
    //         })
    // })

    // new::start
    quarters.map((quarter, qIndex) => {
        if (qIndex == 0) {
            quarter.map(({ year, month, weeks }, index) => {
                if (index == 0) {
                    weeks.map((week, weekIndex) => {
                        if (weekIndex == 0) {
                            week.map((day, dayIndex) => {
                                days.push(new Date(day).toLocaleString('en-us', { weekday: 'short' }))
                            })
                        }
                    })
                }
            })
        }
    })

    // new::end

    // }
    return (
        <>
            <Loader isVisible={currentFiscal?.isLoading} />
            {show ?
                <div className="fiscal-main">
                    <div className="d-flex flex-row mt-4">
                        <div className="left-con">
                            <h5 className="fw-semibold">Fiscal Year</h5>
                            <p>
                                A defined accounting period can be set to measure financial performance and reporting.
                            </p>
                        </div>
                        <div className="right-con d-flex flex-column align-items-end ms-auto">
                            <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn ">
                                <i className="fa-regular fa-circle-question me-2"></i>
                                Help
                            </button>
                            <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3 mt-2"
                                onClick={() => setShow(!show)}>
                                Manage Fiscal Year
                            </button>
                        </div>
                    </div>

                    {currentFiscal?.data?.[0]?.fiscalYearType == 'standard' ?
                        <div className="row g-0">
                            <div className="col-md-7">
                                <div className="content bg-light-blue border border-light-blue rounded-3 p-3">
                                    <dl className="row justify-content-start list-detail">
                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Fiscal Year Type</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal text-capitalize">{currentFiscal?.data?.[0]?.fiscalYearType} Fiscal Year</dt>

                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Fiscal Year begins in</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal text-capitalize">{currentFiscal?.data?.[0]?.standard?.fiscalYearBegins}</dt>

                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Set by</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal">{currentFiscal?.data?.[0]?.createdBy?.firstName + ' ' + currentFiscal?.data?.[0]?.createdBy?.lastName} on {formatDate(currentFiscal?.data?.[0]?.updatedAt)}</dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row g-0">
                            <div className="col-md-7">
                                <div className="content bg-light-blue border border-light-blue rounded-3 p-3">
                                    <dl className="row justify-content-start list-detail">
                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Fiscal Year Type</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal text-capitalize">{currentFiscal?.data?.[0]?.fiscalYearType} Fiscal Year</dt>

                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Fiscal Year Format</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal text-capitalize">{currentFiscal?.data?.[0]?.custom?.formatValue}</dt>

                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Start Date</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal text-capitalize">{(currentFiscal?.data?.[0]?.custom?.startDate &&
                                            currentFiscal?.data?.[0]?.custom?.startMonth) &&
                                            currentFiscal?.data?.[0]?.custom?.startDate + '-' +
                                            currentFiscal?.data?.[0]?.custom?.startMonth + '-' +
                                            new Date().getFullYear()}
                                        </dt>

                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Display Fiscal Year Based on</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal text-capitalize">{currentFiscal?.data?.[0]?.custom?.fiscalYearBased == 'starting_year' && 'Starting Year' ||
                                            currentFiscal?.data?.[0]?.custom?.fiscalYearBased == 'ending_year' && 'Ending Year'}</dt>

                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Period display option</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal text-capitalize">{currentFiscal?.data?.[0]?.custom?.periodDisplayOption == 'number_by_year' && 'Number by Year' ||
                                            currentFiscal?.data?.[0]?.custom?.periodDisplayOption == 'number_by_quarter' && 'Number by Quarter'}</dt>

                                        <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Set by</dd>
                                        <dt className="col-sm-8 col-lg-9 fw-normal">{(currentFiscal?.data?.[0]?.createdBy?.firstName &&
                                            currentFiscal?.data?.[0]?.createdBy?.lastName &&
                                            currentFiscal?.data?.[0]?.updatedAt
                                        ) && (currentFiscal?.data?.[0]?.createdBy?.firstName + ' ' + currentFiscal?.data?.[0]?.createdBy?.lastName + ' on ' + formatDate(currentFiscal?.data?.[0]?.updatedAt))}</dt>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                :
                <>
                    <div className="fiscal-form mt-4">
                        <div className="d-flex">
                            <h5 className="fw-semibold">Manage Fiscal Year</h5>
                            {fiscalYearData?.fiscalYearType == 'custom' &&
                                <button type="button" className="btn btn-light flat-btn rounded-pill py-1 px-3 ms-auto"
                                    data-bs-toggle="modal" data-bs-target="#previewCalendarModal">
                                    Preview in Calendar
                                </button>
                            }
                        </div>

                        <fieldset className="row mt-4 mb-3">
                            <legend className="col-form-label col-sm-2 pt-0 text-end">Fiscal Year Type</legend>
                            <div className="col-sm-10">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" value="standard" name="fiscalYearType" id="stdFiscalYear" onChange={formHandler}
                                        checked={fiscalYearData?.fiscalYearType === "standard"} />
                                    <label className="form-check-label" htmlFor="stdFiscalYear">
                                        Standard Fiscal Year
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" value="custom" name="fiscalYearType" id="cusFiscalYear" onChange={formHandler}
                                        checked={fiscalYearData?.fiscalYearType === "custom"} />
                                    <label className="form-check-label" htmlFor="cusFiscalYear">
                                        Custom Fiscal Year
                                    </label>
                                </div>
                            </div>
                        </fieldset>

                        {fiscalYearData?.fiscalYearType == 'standard' ?
                            <>
                                <div className="row mb-3">
                                    <label htmlFor="beginIn" className="col-sm-2 col-form-label text-end">Fiscal Year begins in</label>
                                    <div className="col-sm-8 col-xl-4">
                                        <select className="form-select" id="beginIn" aria-label="Fiscal Begin In"
                                            onChange={formHandler} name="fiscalYearBegins" value={fiscalYearData?.standard?.fiscalYearBegins}>
                                            <option value="">Select</option>
                                            <option value="january">January</option>
                                            <option value="february">February</option>
                                            <option value="march">March</option>
                                            <option value="april">April</option>
                                            <option value="may">May</option>
                                            <option value="june">June</option>
                                            <option value="july">July</option>
                                            <option value="august">August</option>
                                            <option value="september">September</option>
                                            <option value="october">October</option>
                                            <option value="november">November</option>
                                            <option value="december">December</option>
                                        </select>
                                    </div>
                                </div>

                                <fieldset className="row mt-4 mb-3">
                                    <legend className="col-form-label col-sm-2 pt-0 text-end">Display Fiscal Year Based on Q-YYYY</legend>
                                    <div className="col-sm-10">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="fiscalYearBased" id="disOptStart" value="starting_year" onChange={formHandler}
                                                checked={fiscalYearData?.standard?.fiscalYearBased === "starting_year"} />
                                            <label className="form-check-label" htmlFor="disOptStart">
                                                Starting Year
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="fiscalYearBased" id="disOptEnd" value="ending_year" onChange={formHandler}
                                                checked={fiscalYearData?.standard?.fiscalYearBased === "ending_year"} />
                                            <label className="form-check-label" htmlFor="disOptEnd">
                                                Ending Year
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            </> :
                            <>
                                <div className="row mb-3 d-none">
                                    <label htmlFor="beginIn" className="col-sm-2 col-form-label text-end">Fiscal Year begins in</label>
                                    <div className="col-sm-8 col-xl-4">
                                        <select className="form-select" id="beginIn" aria-label="Fiscal Begin In"
                                            onChange={formHandler} name="fiscalYearBegins" value={fiscalYearData?.custom?.fiscalYearBegins}>
                                            <option value="">Select</option>
                                            <option value="january">January</option>
                                            <option value="february">February</option>
                                            <option value="march">March</option>
                                            <option value="april">April</option>
                                            <option value="may">May</option>
                                            <option value="june">June</option>
                                            <option value="july">July</option>
                                            <option value="august">August</option>
                                            <option value="september">September</option>
                                            <option value="october">October</option>
                                            <option value="november">November</option>
                                            <option value="december">December</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="yearFormat" className="col-sm-2 col-form-label text-end">Format</label>
                                    <div className="col-sm-8 col-xl-4">
                                        <div className="dropdown custom-dropdown w-100">
                                            <button type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                className="btn btn-link border dropdown-toggle with-arrow text-decoration-none text-dark w-100 text-start">
                                                {fiscalYearData?.custom?.formatValue}
                                            </button>
                                            <ul className="dropdown-menu w-100 max-h-200px">
                                                {/* onChange={formHandler} name="format"> */}
                                                <li><h6 className="dropdown-header text-dark">Quarter Based</h6></li>
                                                {QuarterBasedFormat.map((item) => (
                                                    <li onClick={() => formatHandler(item?.value, item?.data)} name="format">
                                                        <button className={`dropdown-item rounded-0 ps-3 mb-2 ${item?.value == fiscalYearData?.custom?.formatValue && 'active'}`} type="button">
                                                            <span className="small d-block ps-3">
                                                                {item?.value} <br />
                                                                {item?.data}
                                                            </span>
                                                        </button>
                                                    </li>
                                                ))
                                                }
                                                <li><h6 className="dropdown-header text-dark">Year Based</h6></li>
                                                {YearBasedFormat.map((item) => (
                                                    <li onClick={() => formatHandler(item?.value, item?.data)} name="format">
                                                        <button className={`dropdown-item rounded-0 ps-3 mb-2 ${item?.value == fiscalYearData?.custom?.formatValue && 'active'}`} type="button">
                                                            <span className="small d-block ps-3">
                                                                {item?.value} <br />
                                                                {item?.data}
                                                            </span>
                                                        </button>
                                                    </li>
                                                ))}
                                                {/* <li>
                                                    <button className="dropdown-item rounded-0 ps-3 mb-2" type="button">
                                                        <span className="small d-block ps-3">
                                                            3-3-4-3 <br />
                                                            Quarter 1 has 3 periods, Quarter 2 has 3 periods, Quarter 3 has 4 periods, Quarter 4 has 3 periods
                                                        </span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item rounded-0 ps-3 mb-2" type="button">
                                                        <span className="small d-block ps-3">
                                                            3-4-3-3 <br />
                                                            Quarter 1 has 3 periods, Quarter 2 has 4 periods, Quarter 3 has 3 periods, Quarter 4 has 3 periods
                                                        </span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item rounded-0 ps-3 mb-2" type="button">
                                                        <span className="small d-block ps-3">
                                                            4-3-3-3 <br />
                                                            Quarter 1 has 4 periods, Quarter 2 has 3 periods, Quarter 3 has 3 periods, Quarter 4 has 3 periods
                                                        </span>
                                                    </button>
                                                </li> */}
                                            </ul>
                                        </div>

                                        <div className="content bg-light-blue border border-light-blue rounded-3 p-3 mt-2">
                                            <p className="mb-0 fw-semibold">
                                                4 Quarters per year, 13 weeks per Quarter
                                            </p>
                                            <p className="mb-0">{fiscalYearData?.custom?.formatData}</p>
                                            {/* <p className="mb-0">In each quarter,</p>
                                            <p className="mb-0">Period 1 has 4 weeks</p>
                                            <p className="mb-0">Period 2 has 4 weeks</p>
                                            <p className="mb-0">Period 3 has 5 weeks</p> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label text-end">Start Date</label>
                                    <div className="col-sm-8 col-xl-4">
                                        <div className="row g-0">
                                            <div className="col">
                                                <select className="form-select rounded-end-0" aria-label="Start Date"
                                                    onChange={formHandler} name="startDate" value={fiscalYearData?.custom?.startDate}>
                                                    <option>Select</option>
                                                    {
                                                        arr2.map((dt) => (
                                                            <option value={dt}>{dt}</option>
                                                        ))
                                                    }
                                                    {/* <option value="1">1</option> */}
                                                    {/* <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option> */}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <select className="form-select col rounded-start-0 border-start-0" aria-label="Start Month"
                                                    onChange={formHandler} name="startMonth" value={fiscalYearData?.custom?.startMonth}>
                                                    <option value="">Select</option>
                                                    <option value="1">January</option>
                                                    <option value="2">February</option>
                                                    <option value="3">March</option>
                                                    <option value="4">April</option>
                                                    <option value="5">May</option>
                                                    <option value="6">June</option>
                                                    <option value="7">July</option>
                                                    <option value="8">August</option>
                                                    <option value="9">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h6 className="fw-semibold mt-4">Display Options</h6>
                                <fieldset className="row mt-4 mb-3">
                                    <legend className="col-form-label col-sm-2 pt-0 text-end">Display Fiscal Year Based on Q-YYYY</legend>
                                    <div className="col-sm-10">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="fiscalYearBased" id="disOptStart" value="starting_year" onChange={formHandler}
                                                checked={fiscalYearData?.custom?.fiscalYearBased === "starting_year"} />
                                            <label className="form-check-label" htmlFor="disOptStart">
                                                Starting Year
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="fiscalYearBased" id="disOptEnd" value="ending_year" onChange={formHandler}
                                                checked={fiscalYearData?.custom?.fiscalYearBased === "ending_year"} />
                                            <label className="form-check-label" htmlFor="disOptEnd">
                                                Ending Year
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className="row mb-3">
                                    <label htmlFor="periodDisOpt" className="col-sm-2 col-form-label text-end">Period display option</label>
                                    <div className="col-sm-8 col-xl-4">
                                        <select className="form-select" id="periodDisOpt" aria-label="Period display option"
                                            onChange={formHandler} name="periodDisplayOption" value={fiscalYearData?.custom?.periodDisplayOption}>
                                            <option value="number_by_year">Number by Year</option>
                                            <option value="number_by_quarter">Number by Quarter</option>
                                        </select>

                                        {fiscalYearData?.custom?.periodDisplayOption == 'number_by_year' &&
                                            <div className="content bg-light-blue border border-light-blue rounded-3 p-3 mt-2">
                                                <p className="mb-2 fw-semibold">Preview</p>
                                                {/* {
                                                    tempArr1?.length && tempArr1?.length?.map((arr) => {
                                                        <p className="mb-0">{arr.map(x => x).join(", ")}</p>
                                                    })
                                                } */}
                                                <p className="mb-0">P1 {currentYear}, P2 {currentYear}, P3 {currentYear}</p>
                                                <p className="mb-0">P4 {currentYear}, P5 {currentYear}, P6 {currentYear}</p>
                                                <p className="mb-0">P7 {currentYear}, P8 {currentYear}, P9 {currentYear}</p>
                                                <p className="mb-0">P10 {currentYear}, P11 {currentYear}, P12 {currentYear}</p>
                                            </div>
                                        }
                                        {fiscalYearData?.custom?.periodDisplayOption == 'number_by_quarter' &&
                                            <div className="content bg-light-blue border border-light-blue rounded-3 p-3 mt-2">
                                                <p className="mb-2 fw-semibold">Preview</p>
                                                <p className="mb-0">Q1 P1 {currentYear}, Q1 P2 {currentYear}, Q1 P3 {currentYear}</p>
                                                <p className="mb-0">Q2 P1 {currentYear}, Q2 P2 {currentYear}, Q2 P3 {currentYear}</p>
                                                <p className="mb-0">Q3 P1 {currentYear}, Q3 P2 {currentYear}, Q3 P3 {currentYear}</p>
                                                <p className="mb-0">Q4 P1 {currentYear}, Q4 P2 {currentYear}, Q4 P3 {currentYear}</p>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <h6 className="fw-semibold mt-4">Surplus week add-on option</h6>
                                <p>
                                    Since each fiscal year in 4-5-4 format will include only 364days,
                                    left out day in each year can be accumulated in 6th year and added as an
                                    extra week in any quarter or period.
                                </p>
                                <button type="button" className="btn btn-link text-decoration-none fw-semibold rounded-2 py-1 px-3"
                                    data-bs-toggle="modal" data-bs-target="#addSurplusWeekModal">
                                    Add Surplus Week
                                </button>
                            </>
                        }
                    </div>


                    {/* Button Section */}
                    <div
                        className="form-footer rounded-bottom-4 position-absolute start-0 bottom-0 w-100 bg-white border-top ps-4 py-3 d-flex">
                        <button type="button" className="btn btn-light flat-btn rounded-pill py-1 px-3"
                            onClick={() => setShow(!show)}>
                            Cancel
                        </button>
                        <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3 ms-3"
                            onClick={handleSubmitFiscalYear}>
                            Save
                        </button>
                    </div>
                    {/* // Button Section END // */}
                </>
            }
            {/* Add Surplus Week Modal */}
            <div className="modal fade" id="addSurplusWeekModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addSurplusWeekLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable surplus-week-modal">
                    <div className="modal-content">
                        <div className="modal-body pb-0">
                            <h5 className="modal-title fs-5 mb-4" id="addSurplusWeekLabel">Add Surplus Week</h5>

                            <form>
                                <div className="row mb-3">
                                    <label htmlFor="period" className="col-auto col-md-3 col-xl-2 col-form-label">Period</label>
                                    <div className="col-auto col-xl-8 input-control">

                                        {fiscalYearData?.custom?.periodDisplayOption == 'number_by_year' &&
                                            <select className="form-select" id="period"
                                                onChange={formHandler} name="periodValue" value={fiscalYearData?.custom?.periodValue}>
                                                <option disabled selected>Select Period</option>
                                                {arr.map((data, index) => (
                                                    <option value={`P${index + 1}${currentYear}`}>P{index + 1} {currentYear}</option>
                                                ))}
                                                {arr.map((data, index) => (
                                                    <option value={`P${index + 1}${currentYear + 1}`}>P{index + 1} {currentYear + 1}</option>
                                                ))}
                                            </select>
                                        }

                                        {fiscalYearData?.custom?.periodDisplayOption == 'number_by_quarter' &&
                                            <select className="form-select" id="period"
                                                onChange={formHandler} name="periodValue" value={fiscalYearData?.custom?.periodValue}>
                                                <option disabled selected>Select Period</option>
                                                {arr1.map((data, index) => (
                                                    <option value={`Q1P${index + 1}${currentYear}`}>Q1 P{index + 1} {currentYear}</option>
                                                ))}
                                                {arr1.map((data, index) => (
                                                    <option value={`Q2P${index + 1}${currentYear}`}>Q2 P{index + 1} {currentYear}</option>
                                                ))}
                                                {arr1.map((data, index) => (
                                                    <option value={`Q3P${index + 1}${currentYear}`}>Q3 P{index + 1} {currentYear}</option>
                                                ))}
                                                {arr1.map((data, index) => (
                                                    <option value={`Q4P${index + 1}${currentYear}`}>Q4 P{index + 1} {currentYear}</option>
                                                ))}
                                                {arr1.map((data, index) => (
                                                    <option value={`Q1P${index + 1}${currentYear + 1}`}>Q1 P{index + 1} {currentYear + 1}</option>
                                                ))}
                                                {arr1.map((data, index) => (
                                                    <option value={`Q2P${index + 1}${currentYear + 1}`}>Q2 P{index + 1} {currentYear + 1}</option>
                                                ))}
                                                {arr1.map((data, index) => (
                                                    <option value={`Q3P${index + 1}${currentYear + 1}`}>Q3 P{index + 1} {currentYear + 1}</option>
                                                ))}
                                                {arr1.map((data, index) => (
                                                    <option value={`Q4P${index + 1}${currentYear + 1}`}>Q4 P{index + 1} {currentYear + 1}</option>
                                                ))}

                                            </select>
                                        }
                                    </div>
                                </div>
                                <div className="alert alert-warning rounded-3 p-3 mt-4" role="alert">
                                    <p className='fw-semibold'>Note:</p>
                                    <ul className='ps-3 mb-0'>
                                        <li>Surplus week will be added to the selected period of the fiscal year.</li>
                                        <li>
                                            Once surplus week is added for the fiscal year, option to add another
                                            surplus week will be available after 6 fiscal years.
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className='me-auto'>
                                <button type="button" className="btn btn-outline-primary light-blue rounded-pill"
                                    data-bs-toggle="modal" data-bs-target="#previewCalendarModal">
                                    Preview in Calendar
                                </button>
                            </div>
                            <div className='ms-auto'>
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue me-2" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue me-0" onClick={handleSubmitFiscalYear} data-bs-dismiss="modal">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // Add Surplus Week Modal // */}

            {/* Preview Calendar Modal */}
            <div className="modal fade" id="previewCalendarModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="previewCalendarLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen mx-auto pre-cal-modal">
                    <div className="modal-content">
                        <div className='modal-header pb-0 border-0'>
                            <div className='d-inline-flex flex-wrap'>
                                <h5 className="modal-title fs-5" id="previewCalendarLabel">Preview in Calendar</h5>
                                <span className='bg-light-blue border border-light-blue rounded-3 px-3 py-2 mx-3'>
                                    {fiscalYearData?.custom?.formatValue} Format <i className='fa-solid fa-circle-info ms-2'></i>
                                </span>
                                <select className="form-select fw-normal w-auto" id="calendarPeriod"
                                    onChange={calendarHandler}>
                                    <option value="current">Current FY ({formatDate(currentStartDate)} - {formatDate(currentEndDate)})</option>
                                    <option value="previous">Previous FY ({formatDate(previousStartDate)} - {formatDate(previousEndDate)})</option>
                                    <option value="next">Next FY ({formatDate(nextStartDate)} - {formatDate(nextEndDate)})</option>
                                </select>
                                <p className='text-secondary w-100'>
                                    This is how you used for the fiscal year structure in your organisation.
                                </p>
                            </div>
                            <button type="button" className="btn-close align-self-start" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pre-calendar">

                            <div className='row'>
                                {quarters.map((quarter, qIndex) => (
                                    <>
                                        <p className='col-12 sec-header fw-semibold mb-0 pb-2'>Quarter {qIndex + 1}</p>
                                        {quarter.map(({ year, month, weeks }, index) => (
                                            <div className='col-md-6 col-xl-4 s-month'>
                                                <div className='tbl-container rounded-table'>
                                                    <div className='table-responsive'>
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Week</th>
                                                                    <th>Month</th>
                                                                    {days?.map((day, i) => (
                                                                        <th>{day}</th>
                                                                    ))
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {weeks.map((week, weekIndex) => (
                                                                    <>
                                                                        {/* {week.map((day, dayIndex) => ( */}
                                                                        <tr>
                                                                            <th>{weekIndex + 1}</th>
                                                                            {/* <th></th> */}
                                                                            {/* <th>{new Date(month).toLocaleString('default', { month: 'long' })}</th> */}
                                                                            {week.map((day, dayIndex) => (
                                                                                <>
                                                                                    {dayIndex == 0 &&
                                                                                        <th>{day ? new Date(day).toLocaleString('default', { month: 'short' }) : ''}</th>
                                                                                    }
                                                                                    <th>{day ? day.getDate() : ''}</th>
                                                                                </>
                                                                            ))}
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='text-center text-secondary pt-2'>Period {index + 1}</div>
                                            </div>
                                        ))}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // Preview Calendar Modal // */}
        </>
    )
}
export default FiscalYear;