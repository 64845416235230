import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import "./css/MultiPickList.css";

function MultiBulkOptions({ show, handleClose }) {
    let [values, setValues] = useState('');

    let handleValuesChange = (e) => {
        setValues(e.target.value);
    };

    let handleAddValues = () => {
        let valuesArray = values?.split('\n').map(value => value?.trim()).filter(value => value);
        let formattedValuesArray = valuesArray.reduce((accumulator, currentValue) => {
            if (!accumulator.some(item => item.name === currentValue)) {
                accumulator.push({ name: currentValue });
            }
            return accumulator;
        }, []);
        handleClose(formattedValuesArray);
    };
    

    let handleCloseModal = () => {
        setValues('');
        handleClose([]);
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title>Pick List Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            placeholder='Please enter your values sequentially'
                            id="valuesTextarea"
                            rows={15}
                            value={values}
                            onChange={handleValuesChange}
                        />
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleAddValues()}>
                    Add Values
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MultiBulkOptions;
