import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { getVariantConfigStart, createVariantConfigStart, resetReload, deleteVariantConfigByIdStart, updateVariantConfigByIdStart } from "../../../Store/Slices/variantConfigSlice";
import { getFormModuleListStart } from "../../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
import MutiSelectDropDown from '../../../Components/ModalPopup/FormModules/MutiSelectDropDown';
import { Helpers } from "../../../Helpers";
import ConfirmationModal from "../../../Components/ModalPopup/ConfirmationModal";
import Loader from "../../../Components/Loader";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const VariantConfiguration = () => {
    let dispatch = useDispatch();
    let variantConfig = useSelector((state) => state?.variantConfig);
    let formList = useSelector((state) => state?.forms);
    let gridRef = useRef();
    let columnDefs = useMemo(() => [
        { headerName: "Module", field: "module_name" },
        { headerName: "Parent Name", field: "parent_field" },
        {
            headerName: "Parent View",
            valueGetter: (params) => params.data[Helpers.replaceData(params?.data?.parent_field)]
        },
        { headerName: "Child Name", field: "child_field" },
        {
            headerName: "Child View",
            cellRenderer: (params) => {
                return (
                    <select className="form-select">
                        {params.data[Helpers.replaceData(params?.data?.child_field)]?.map((option, index) => (
                            <option key={index}>
                                {option}
                            </option>
                        ))}
                    </select>
                )
            },
            width: 350
        },
        {
            headerName: "Edit",
            filter: false,
            cellRenderer: (params) => (
                <span style={{ color: "#29409d" }} className="cursor-pointer" onClick={() => handleUpdate(params.data)}
                    data-bs-toggle="modal" data-bs-target="#createConfigFormModal2"><i className="fas fa-edit"></i></span>
            ),
        },
        {
            headerName: "Delete",
            filter: false,
            cellRenderer: (params) => {
                return (
                    <span style={{ color: "red" }} className="cursor-pointer" onClick={() => handleDelete(params.data)}><i className="fas fa-trash"></i></span>
                );
            },
        },
    ], []);

    let [createConfigData, setCreateConfigData] = useState({
        config_name: "",
        module_name: "",
        moduleId: "",
        parent_field: "",
        child_field: "",
        description: ""
    });
    let [createConfigError, setCreateConfigError] = useState({});
    let [createConfigTouched, setCreateConfigTouched] = useState({});
    let [dropDownPickList, setDropDownPickList] = useState([]);
    let [parentOption, setParentOption] = useState("");
    let [childOption, setChildOption] = useState([]);
    let [previewData, setPreviewData] = useState([]);
    let [resetMutiDropDown, setResetMutiDropDown] = useState(false);
    let [isEditIndex, setIsEditIndex] = useState(null);
    let [removeModalData, setRemoveModalData] = useState(null);
    let [updateData, setUpdateData] = useState(null);
    useEffect(() => {
        dispatch(getVariantConfigStart());
    }, []);

    useEffect(() => {
        if (variantConfig?.reload) {
            dispatch(getVariantConfigStart());
            dispatch(resetReload());
        }
    }, [variantConfig?.reload]);

    useEffect(() => {
        dispatch(getFormModuleListStart({ page: 1, count: 100 }));
    }, []);

    let onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            event.target.value,
        );
    }

    let handleUpdate = (data) => {
        setParentOption(data?.[Helpers.replaceData(data?.parent_field)]);
        setChildOption(data?.[Helpers.replaceData(data?.child_field)]?.map(x => ({ name: x })));
        setCreateConfigData({
            config_name: data?.config_name,
            module_name: data?.module_name,
            moduleId: data?.moduleId,
            parent_field: data?.parent_field,
            child_field: data?.child_field,
            description: data?.description
        });
        setUpdateData(data);
    }

    let handleDelete = (data) => {
        setRemoveModalData(data);
    }

    let createConfigHandleChange = (e) => {
        let { name, value } = e.target;
        if (name === "module_name") {
            let data = JSON.parse(value);
            setCreateConfigData(prevState => ({
                ...prevState,
                module_name: data?.name,
                moduleId: data?._id,
                parent_field: "",
                child_field: ""
            }));
        } else {
            setCreateConfigData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    let createConfigTouchedHandleBlur = (field) => {
        setCreateConfigTouched({
            ...createConfigTouched,
            [field]: true,
        });
    };

    let validateForm = () => {
        let newErrors = {};
        for (let field in createConfigData) {
            if (!createConfigData[field]) {
                newErrors[field] = `${field?.replace(/_/g, " ")} is required`;
            }
        }
        return newErrors;
    };

    useEffect(() => {
        let updatedErrors = { ...createConfigError };
        for (let field in createConfigData) {
            if (createConfigTouched[field]) {
                if (!createConfigData[field]) {
                    updatedErrors[field] = `${field?.replace(/_/g, " ")} is required`;
                } else {
                    updatedErrors[field] = "";
                }
            }
        }
        setCreateConfigError(updatedErrors);
    }, [createConfigData, createConfigTouched]);

    useEffect(() => {
        if (!formList?.modules?.length) return;
        let data = [];
        formList?.modules.forEach((module) => {
            if (createConfigData?.module_name === module?.name) {
                module?.fields?.sections.forEach((section) => {
                    let droppedList = section?.dropped_list || {};
                    Object.values(droppedList).forEach((area) => {
                        area.forEach((item) => {
                            if (item.input_type === 'select') {
                                data.push(item.extra_fields);
                            }
                        });
                    });
                });
            }
        });
        setDropDownPickList(data);
    }, [createConfigData?.module_name]);

    let getOptions = (label) => {
        let data = [...dropDownPickList.find((opt) => opt.field_label === createConfigData[label])?.options?.map(x => ({ name: x })) || []];
        if (label === "parent_field") {
            data = data?.filter(x => !previewData?.find(y => y?.[Helpers.replaceData(y?.parent_field)] === x?.name));
        }
        return data;
    };

    let resetCreateConfigData = () => {
        setCreateConfigData({
            config_name: "",
            module_name: "",
            moduleId: "",
            parent_field: "",
            child_field: "",
            description: ""
        });
        setCreateConfigError({});
        setCreateConfigTouched({});
    }

    let handleAddToPreview = () => {
        setPreviewData((prevData) => {
            let updatedData = [...prevData];
            updatedData[(isEditIndex !== null) ? isEditIndex : (updatedData.length || 0)] = {
                ...createConfigData,
                [Helpers.replaceData(createConfigData?.parent_field)]: parentOption,
                [Helpers.replaceData(createConfigData?.child_field)]: childOption
            };
            console.log(updatedData)
            return updatedData;
        });
        setParentOption("");
        setChildOption([]);
        setResetMutiDropDown(true);
        setIsEditIndex(null);
    };

    useEffect(() => {
        setResetMutiDropDown(false);
    }, [parentOption, childOption]);

    let submitCreateUpdateConfigForm = () => {
        if (updateData !== null) {
            let jsonData = {
                ...createConfigData,
                [Helpers.replaceData(createConfigData?.parent_field)]: parentOption,
                [Helpers.replaceData(createConfigData?.child_field)]: childOption?.map(x => x?.name),
            };
            dispatch(updateVariantConfigByIdStart({
                _id: updateData?._id,
                data: jsonData
            }));
        } else {
            let jsonData = {
                ...createConfigData,
                data: previewData.map(entry => ({
                    [Helpers.replaceData(createConfigData?.parent_field)]: entry?.[Helpers.replaceData(createConfigData?.parent_field)],
                    [Helpers.replaceData(createConfigData?.child_field)]: entry?.[Helpers.replaceData(createConfigData?.child_field)]?.map(x => x?.name),
                })),
            };
            dispatch(createVariantConfigStart(jsonData));
        }
        resetCreateConfigData();
        setUpdateData(null);
    }

    let previewHandleDelete = (index) => {
        setPreviewData(prev => prev.filter((_, i) => i !== index));
    };

    let previewHandleEdit = (index) => {
        let entryToEdit = previewData[index];
        setParentOption(entryToEdit?.[Helpers.replaceData(entryToEdit?.parent_field)]);
        setChildOption(entryToEdit?.[Helpers.replaceData(entryToEdit?.child_field)]);
        setIsEditIndex(index);
    };

    return (
        <>
            <Loader isVisible={formList?.isLoading || variantConfig?.isLoading} />
            <div className="col-md-12">
                <div className="card rounded-4 border-0 mt-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                                <button className="btn btn-primary btn-digy-dark rounded-pill me-3">
                                    <i className="fa fa-arrow-left me-2"></i> Back
                                </button>
                                <div className="left d-flex align-items-start">
                                    <div className="search-box border rounded-pill">
                                        <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                        <input type="search" placeholder="Search" className="searchfield bg-white" onInput={onFilterTextBoxChanged} />
                                    </div>
                                </div>
                                <div className="ms-auto">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-primary btn-digy-dark rounded-pill" onClick={() => {
                                            setUpdateData(null);
                                            setPreviewData([]);
                                        }}
                                            data-bs-toggle="modal" data-bs-target="#createConfigFormModal1">
                                            <i className="fa-solid fa-plus me-2"></i>
                                            <span>Create Configurator</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="ag-theme-quartz" style={{ height: "calc(100vh - 23vh)", display: "flex", flexDirection: "column", position: "relative" }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={variantConfig?.data}
                                        columnDefs={columnDefs}
                                        defaultColDef={{
                                            filter: "agTextColumnFilter",
                                            floatingFilter: true,
                                        }}
                                        rowSelection="single"
                                        pagination={true}
                                        rowHeight={40}
                                        floatingFiltersHeight={40}
                                        headerHeight={40}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Create Configurator Form - step 1 */}
                    <div className="modal fade" id="createConfigFormModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="createConfigFormLabel1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable creat-config-modal1">
                            <div className="modal-content">
                                <div className='modal-header border-bottom-0 pb-0'>
                                    <h5 className="modal-title fs-5" id="createConfigFormLabel1">
                                        Create Configurator
                                    </h5>
                                </div>
                                <div className="modal-body pb-0">
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="configSpecifyName">
                                            <Form.Label column md={4} className="text-md-end">Specify Name</Form.Label>
                                            <Col md={8}>
                                                <Form.Control
                                                    type='text' name='config_name'
                                                    placeholder="Enter Configuration Name"
                                                    required
                                                    onChange={createConfigHandleChange}
                                                    onBlur={() => createConfigTouchedHandleBlur('config_name')}
                                                    className={`${createConfigTouched.config_name && createConfigError.config_name ? 'is-invalid' : ''}`}
                                                    value={createConfigData.config_name}
                                                />
                                                {createConfigTouched.config_name && createConfigError.config_name && (
                                                    <div className="invalid-feedback">{createConfigError.config_name}</div>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="configChooseModule">
                                            <Form.Label column md={4} className="text-md-end">Choose Module</Form.Label>
                                            <Col md={8}>
                                                <Form.Select aria-label="Choose Module" name='module_name' className={`${createConfigTouched.module_name && createConfigError.module_name ? 'is-invalid' : ''}`} required value={JSON.stringify({name : createConfigData.module_name, _id : createConfigData.moduleId})} onChange={createConfigHandleChange} onBlur={() => createConfigTouchedHandleBlur('module_name')}>
                                                    <option value="" selected>Choose Module</option>
                                                    {formList?.modules?.map((x, index) => (
                                                        <option key={index} value={JSON.stringify({ name: x?.name, _id: x?._id })}>{x?.name}</option>
                                                    ))}
                                                </Form.Select>
                                                {createConfigTouched.module_name && createConfigError.module_name && (
                                                    <div className="invalid-feedback">{createConfigError.module_name}</div>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="configChooseParentField">
                                            <Form.Label column md={4} className="text-md-end">Choose Parent Field</Form.Label>
                                            <Col md={8}>
                                                <Form.Select aria-label="Choose Parent Field" name='parent_field' className={`${createConfigTouched.parent_field && createConfigError.parent_field ? 'is-invalid' : ''}`} required value={createConfigData.parent_field} onChange={createConfigHandleChange} onBlur={() => createConfigTouchedHandleBlur('parent_field')}>
                                                    <option value="" selected>Choose Parent Field</option>
                                                    {dropDownPickList?.map((x, index) => (
                                                        <>
                                                            {x?.field_label !== createConfigData?.child_field && (
                                                                <option value={x?.field_label} key={index}>{x?.field_label}</option>
                                                            )}
                                                        </>
                                                    ))}
                                                </Form.Select>
                                                {createConfigTouched.parent_field && createConfigError.parent_field && (
                                                    <div className="invalid-feedback">{createConfigError.parent_field}</div>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="configChooseChildField">
                                            <Form.Label column md={4} className="text-md-end">Choose Child Field</Form.Label>
                                            <Col md={8}>
                                                <Form.Select aria-label="Choose Child Field" name='child_field' className={`${createConfigTouched.child_field && createConfigError.child_field ? 'is-invalid' : ''}`} required value={createConfigData.child_field} onChange={createConfigHandleChange} onBlur={() => createConfigTouchedHandleBlur('child_field')}>
                                                    <option value="" selected>Choose Child Field</option>
                                                    {dropDownPickList?.map((x, index) => (
                                                        <>
                                                            {x?.field_label !== createConfigData?.parent_field && (
                                                                <option value={x?.field_label} key={index}>{x?.field_label}</option>
                                                            )}
                                                        </>
                                                    ))}
                                                </Form.Select>
                                                {createConfigTouched.child_field && createConfigError.child_field && (
                                                    <div className="invalid-feedback">{createConfigError.child_field}</div>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="configDescription">
                                            <Form.Label column md={4} className="text-md-end">Description</Form.Label>
                                            <Col md={8}>
                                                <Form.Control as="textarea" rows={1} className={`${createConfigTouched.description && createConfigError.description ? 'is-invalid' : ''}`} value={createConfigData.description}
                                                    type="text"
                                                    name="description"
                                                    onChange={createConfigHandleChange}
                                                    onBlur={() => createConfigTouchedHandleBlur('description')}
                                                />
                                                {createConfigTouched.description && createConfigError.description && (
                                                    <div className="invalid-feedback">{createConfigError.description}</div>
                                                )}
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="modal-footer">
                                    <Button variant="outline-secondary" className="rounded-pill" data-bs-dismiss="modal" onClick={resetCreateConfigData}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" className="rounded-pill"
                                        data-bs-toggle="modal" data-bs-target="#createConfigFormModal2" disabled={Object?.values(validateForm())?.length > 0}>Next</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // Create Configurator Form - step 1 // */}

                    {/* Create Configurator Form - step 2 */}
                    <div className="modal fade" id="createConfigFormModal2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="createConfigFormLabel2" aria-hidden="true">
                        <div className="modal-dialog modal-xl modal-dialog-scrollable creat-config-modal1">
                            <div className="modal-content">
                                <div className='modal-header border-bottom-0 pb-0'>
                                    <h5 className="modal-title fs-5" id="createConfigFormLabel2">
                                        {(isEditIndex !== null || updateData !== null) ? 'Update' : 'Create'} Configurator
                                    </h5>
                                </div>
                                <div className="modal-body pb-0">
                                    <Form>
                                        <Row className="mb-3 g-3">
                                            <Form.Group as={Col} className="col-5 col-xl" controlId="materialType">
                                                <Form.Control type="text" className="fw-bold" placeholder={createConfigData?.parent_field} readOnly />
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-5 col-xl" controlId="materialTypeChild">
                                                <Form.Select defaultValue="Choose" onChange={(e) => setParentOption(e.target.value)} value={parentOption} disabled={isEditIndex !== null || updateData != null}>
                                                    <option value="" selected>Choose Parent Field</option>
                                                    {getOptions("parent_field")?.map((x, index) => (
                                                        <>
                                                            <option value={x?.name} key={index}>{x?.name}</option>
                                                        </>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-5 col-xl" controlId="materialGroup">
                                                <Form.Control type="text" className="fw-bold" placeholder={createConfigData?.child_field} readOnly />
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-5 col-xl" controlId="materialGroupChild">
                                                <MutiSelectDropDown
                                                    options={getOptions("child_field")}
                                                    handleInputChange={(data) => setChildOption(data?.target.value)}
                                                    default_value={childOption}
                                                    resetFlg={resetMutiDropDown}
                                                />
                                            </Form.Group>
                                            {(updateData === null) && (
                                                <Form.Group as={Col} className="col col-md-1" controlId="addRowBtn">
                                                    <Button variant="primary" className="rounded-pill" type="button" onClick={handleAddToPreview}>
                                                        <i className="fa-solid fa-plus"></i>
                                                    </Button>
                                                </Form.Group>
                                            )}
                                        </Row>
                                    </Form>

                                    {updateData === null && (
                                        <>
                                            <div className="tbl-container rounded-table mt-5 mb-3">
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Module</th>
                                                                <th>Parent Field</th>
                                                                <th>Parent View</th>
                                                                <th>Child Field</th>
                                                                <th>Child View</th>
                                                                <th>Edit</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {previewData?.length > 0 && (
                                                                previewData?.map((x, index) => (
                                                                    <tr key={index}>
                                                                        <td>{x?.module_name}</td>
                                                                        <td>{x?.parent_field}</td>
                                                                        <td>{x?.[Helpers.replaceData(x?.parent_field)]}</td>
                                                                        <td>{x?.child_field}</td>
                                                                        <td>
                                                                            <select className="form-select">
                                                                                {x?.[Helpers.replaceData(x?.child_field)]?.map((option, index) => (
                                                                                    <option key={index}>
                                                                                        {option?.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <span style={{ color: "#29409d" }} className="cursor-pointer" onClick={() => previewHandleEdit(index)}><i className="fas fa-edit"></i></span>
                                                                        </td>
                                                                        <td>
                                                                            <span style={{ color: "red" }} className="cursor-pointer" onClick={() => previewHandleDelete(index)}><i className="fas fa-trash"></i></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <Button variant="outline-secondary" className="rounded-pill" data-bs-dismiss="modal" onClick={resetCreateConfigData}>
                                        Cancel
                                    </Button>
                                    {updateData === null ?
                                        <>
                                            <Button variant="primary" className="rounded-pill" data-bs-dismiss="modal" disabled={!previewData?.length} onClick={submitCreateUpdateConfigForm}>Create</Button>
                                        </> :
                                        <>
                                            <Button variant="primary" className="rounded-pill" data-bs-dismiss="modal" onClick={submitCreateUpdateConfigForm}>Update</Button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // Create Configurator Form step 2 // */}

                </div>
            </div>
            {removeModalData && <ConfirmationModal data={removeModalData} show={true} handleClose={() => setRemoveModalData(null)} handleSuccess={(subModuleId) => {
                dispatch(deleteVariantConfigByIdStart(subModuleId));
                setRemoveModalData(null);
            }} />}
        </>
    );
};

export default VariantConfiguration;