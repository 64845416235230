import React, { useState } from "react";

const ModuleName = ({moduleStoreData, dispatch, onCLickClose}) => {
  const [moduleName, setModuleName] = useState({
    plural_form: moduleStoreData?.plural_form,
    singular_form: moduleStoreData?.singular_form,
  });

  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setModuleName((prev) => ({
      ...prev,
      [id]: value,
    }));
    setError("");
  };

  const handleDone = () => {
    if (!moduleName.plural_form.trim() || !moduleName.singular_form.trim()) {
      setError("Module name cannot be empty");
      return;
    }
    dispatch({
      type: 'MODULE_NAME',
      payload: moduleName,
    });
    onCLickClose(true);
  };

  const handleSingularFocus = () => {
    if (!moduleName.singular_form.trim()) {
      setModuleName((prev) => ({
        ...prev,
        singular_form: prev.plural_form,
      }));
    }
  };

  return (
    <div 
    className="module-name-popup bg-light p-3 shadow">
      <div className="form-floating w-placeholder mb-3">
        <input
          id="plural_form"
          type="text"
          className="form-control mb-2"
          placeholder="i.e. Leads/Contacts"
          value={moduleName.plural_form}
          onChange={handleInputChange}
          maxLength={25}
        />
        <label htmlFor="plural_form">Plural form of module name:</label>
      </div>
      <div className="form-floating w-placeholder">
        <input
          id="singular_form"
          type="text"
          className="form-control"
          placeholder="i.e. Lead/Contact"
          value={moduleName.singular_form}
          onChange={handleInputChange}
          onFocus={handleSingularFocus}
        />
        <label htmlFor="singular_form">Singular form of module name:</label>
      </div>
      {error && <div className="text-danger mt-2 ">{error}</div>}
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary mt-2" onClick={handleDone}>
          Done
        </button>
      </div>
    </div>
  );
};

export default ModuleName;
