import React, { useEffect, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { getAllDashboardComponentStart, createDashboardComponentLayoutStart, updateDashboardComponentLayoutStart, reState, getByIdDashboardComponentLayoutStart, destorylayoutDataById } from "../../Store/Slices/dashboardComponentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllSubFormModulesStart } from "../../Store/Slices/dashboardComponentsSlice";
import { DashboardComponents } from "../../Components/DashboardComponents";
import HomePageCustomization from "../../Components/ModalPopup/ChartComponents/HomePageCustomization";
import Loader from "../../Components/Loader";
import { Utils } from "../../Utils";

const ReactGridLayout = WidthProvider(RGL);
const HomeCustomizationCreate = () => {
    let { id } = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let subModuleData = useSelector((state) => state?.dashboardComponents);
    let workflowList = useSelector((state) => state?.workflow);
    let [selectedComponentIndex, setSelectedComponentIndex] = useState(0);
    let [layoutData, setLayoutData] = useState(null);
    let [selectedComponentDatas, setSelectedComponentDatas] = useState([{
        "company_name": "Inbox",
        "default" : true,
        "position": {
            "w": 3,
            "h": 3,
            "x": 0,
            "y": 0,
            "i": "inbox",
            "minW": 3,
            "minH": 3
        }
    }]);
    let [layoutPostion, setLayoutPostion] = useState([]);
    let [openDropdown, setOpenDropdown] = useState(null);
    let [layoutName, setLayoutName] = useState("Untitled Homepage");
    let [showModal, setShowModal] = useState(false);
    let currentOrg = useSelector((state) => state?.org);
    
    useEffect(() => {
        if(id?.length > 0) {
            dispatch(getByIdDashboardComponentLayoutStart(id));
        }
        return () => {
            dispatch(destorylayoutDataById());
        }
    }, [id]);

    useEffect(() => {
        if(subModuleData?.layoutDataById) {
            setLayoutData(subModuleData?.layoutDataById);
            setLayoutName(subModuleData?.layoutDataById?.layout_name || "");
            setSelectedComponentDatas(subModuleData?.layoutDataById?.components || []);
        }
    }, [subModuleData?.layoutDataById]);

    useEffect(() => {
        if (selectedComponentDatas?.length > 0) {
            setLayoutPostion(selectedComponentDatas?.map(item => ({
                ...item?.position
            })) || []);
            if (selectedComponentDatas?.length > 0) {
                let uniqueModuleIds = [...new Set(selectedComponentDatas?.map(item => item.moduleId))];
                uniqueModuleIds?.forEach((moduleId) => {
                    let hasModuleId = subModuleData?.subModuleData?.hasOwnProperty(moduleId);
                    if (!hasModuleId && moduleId) {
                        dispatch(getAllSubFormModulesStart(moduleId));
                    }
                });
            }
        }
    }, [selectedComponentDatas]);

    useEffect(() => {
        if (!subModuleData?.components?.length) {
            dispatch(getAllDashboardComponentStart());
        }
    }, []);

    let checkedComponent = (event, compoenent) => {
        let isChecked = event?.target?.checked;
        if(isChecked) {
            console.log([...selectedComponentDatas, compoenent])
            setSelectedComponentDatas([...selectedComponentDatas, compoenent]);
        } else {
            let postionId = compoenent?.position?.i;
            let data = selectedComponentDatas?.filter(x => (x?.position?.i !== postionId));
            setSelectedComponentDatas(data);
        }
    }

    let checkTheCheckedComponent = (positionId) => {
        return selectedComponentDatas?.some(x => x?.position?.i === positionId) || false;
    }

    let handleToggle = (index) => {
        setOpenDropdown(prevIndex => (prevIndex === index ? null : index));
    };

    let removeData = (index) => {
        let data = [...selectedComponentDatas];
        setSelectedComponentDatas(data?.filter((_, i) => i !== index));
    }

    
    let submitHomePage = (data) => {
        let data2 = [...selectedComponentDatas];
        let updatedData = data2.map(item => {
            let matchingLayout = layoutPostion.find(layoutItem => layoutItem.i === item.position.i);
            if (matchingLayout) {
                return { 
                    ...item,
                    position: { ...matchingLayout }
                };
            }
            return item;
        });
        if (id?.length > 0) {
            let obj = {
                id: layoutData._id,
                payload: {
                    ...layoutData,
                    layout_name: data?.layoutName,
                    description: data?.description,
                    components: updatedData
                }
            }
            dispatch(updateDashboardComponentLayoutStart(obj));
        } else {
            setLayoutName(data?.layoutName);
            let obj = {
                layout_name: data?.layoutName,
                description: data?.description,
                components: updatedData,
                organization: currentOrg?.data?.[0]?._id
            }
            dispatch(createDashboardComponentLayoutStart(obj));
        }
    }

    useEffect(() => {
        if(subModuleData?.layoutCreationSuccess || subModuleData?.layoutUpdateSuccess) {
            setShowModal(false);
            navigate(Utils.getNavigationUrl(`home-customization`));
            dispatch((reState()));
        }
    }, [subModuleData?.layoutCreationSuccess, subModuleData?.layoutUpdateSuccess]);

    let onLayoutChange = (layout2) => {
        setLayoutPostion(layout2);
    }
    return (
        <>
            <Loader isVisible={subModuleData?.isLoading} />
            <div className="create-module">
                <div className="container-fluid">
                    <div className="row">
                        <div className="sidebar col-md-2 col-lg-2 p-0 bg-white h-100vh fixed-top">
                            <div className="offcanvas-md offcanvas-end h-100" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                                        aria-label="Close"></button>
                                </div>

                                <div className="offcanvas-body d-md-flex flex-column p-3 pt-lg-3 h-100 overflow-y-auto">
                                    <div className="row g-0 draggable-btns">
                                        <div className="list-group">
                                            <button className="list-group-item list-group-item-action d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#dashboardCollapse">
                                                Dashboard
                                                <span>&rsaquo;</span>
                                            </button>

                                            <div id="dashboardCollapse" className="collapse">
                                                <div className="card card-body">
                                                    <div className={`${subModuleData?.components?.[selectedComponentIndex]?.components?.length > 0 ? 'mb-3' : ''}`}>
                                                        <select id="dashboardSelect" className="form-select" value={selectedComponentIndex} onChange={(e) => setSelectedComponentIndex(e?.target?.value)}>
                                                            <option value="">-- Choose Dashboard --</option>
                                                            {subModuleData?.components?.length > 0 && (
                                                                subModuleData?.components?.map((x, index) => (
                                                                    <React.Fragment key={x?._id}>
                                                                        <option value={index}>{x?.dashboard_name}</option>
                                                                    </React.Fragment>
                                                                ))
                                                            )}
                                                        </select>
                                                    </div>
                                                    {subModuleData?.components?.[selectedComponentIndex]?.components?.length > 0 && (
                                                        <>
                                                            <p className="mb-1">Select Component</p>
                                                            {subModuleData?.components?.[selectedComponentIndex]?.components?.map?.((x, index) => (
                                                                <div className="form-check" key={index}>
                                                                    <input className="form-check-input" type="checkbox" value="" id={x?.position?.i} onChange={(e) => checkedComponent(e, x)} checked={checkTheCheckedComponent(x?.position?.i)}/>
                                                                    <label className="form-check-label" htmlFor={x?.position?.i}>{x?.company_name}</label>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <main className="col-md-10 col-lg-10 ms-sm-auto px-md-4 pb-md-4 content-section">
                            <header className="navbar navbar-expand-lg sticky-top bg-white border-bottom border-light-subtle px-0 tb-header">
                                <nav className="container-fluid d-block">
                                    <div className="row">
                                        <div className="col-md-4 mt-2">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="homepage"
                                                    value={layoutName}
                                                    onChange={(e) => setLayoutName(e?.target?.value)}
                                                />
                                                <label className={`d-inline-block`} htmlFor="homepage">
                                                    Enter the name
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-6">
                                            <div className="d-flex justify-content-end">
                                                <ul className="navbar-nav flex-row btn-group">
                                                    <li className="nav-item text-nowrap">
                                                        <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => navigate(Utils.getNavigationUrl(`home-customization`))}>
                                                            <span>Cancel</span>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item text-nowrap">
                                                        <button type="button" className="btn btn-primary rounded-pill btn-blue" disabled={!layoutName?.trim() || !selectedComponentDatas?.length} onClick={() => setShowModal(true)}>
                                                            <span>Save</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </header>
                            <div className="bg-image-box grid-main-container p-0">
                                <ReactGridLayout
                                    className="bg-image-box grid-container p-0"
                                    layout={layoutPostion || []}
                                    isResizable={true}
                                    isDraggable={true}
                                    rowHeight={30}
                                    preventCollision={false}
                                    compactType="vertical"
                                    draggableCancel='.top-right-menu, .dropdown-menu, .dropdown ul'
                                    cols={12}
                                    onLayoutChange={onLayoutChange}
                                >
                                    {selectedComponentDatas?.length > 0 && (
                                            selectedComponentDatas.map((x, index) => {
                                                if (x?.company_name === "Inbox" && x?.default) {
                                                    return (<div key={x.position.i} className="grid-item">
                                                        <div className="content card">
                                                            <h5 className="card-title fs-16 ps-3 text-start fw-semibold">Inbox</h5>
                                                            <div className="card-body p-0 overflow-auto">
                                                                <div className="count-num fw-bold">{workflowList?.data?.length || 0}</div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                } else {
                                                    let ComponentToRender = DashboardComponents[x?.modal_name];
                                                    return ComponentToRender ? (
                                                        <div key={x.position.i} className="grid-item">
                                                            <div className="top-right-menu dropdown" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={() => handleToggle(index)}>
                                                                <i className="fa fa-ellipsis-v cursor-pointer"></i>
                                                                {(openDropdown === index) && (
                                                                    <div className="dropdown-menu d-block">
                                                                        <ul>
                                                                            <li>Edit</li>
                                                                            <li onClick={() => removeData(index)}>Delete</li>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="content card">
                                                                <h5 className="card-title fs-16 ps-3 text-start fw-semibold">{x?.company_name}</h5>
                                                                <div className="card-body p-0 overflow-auto">
                                                                    <ComponentToRender data={x} isStatic={false} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null;
                                                }
                                            })
                                        )}
                                </ReactGridLayout>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            {
                showModal && <HomePageCustomization show={showModal} handleClose={() => setShowModal(false)} submitData={(data) => {
                    submitHomePage(data);
                }} name={layoutName} description={layoutData?.description}/>
            }
        </>
    )
}

export default HomeCustomizationCreate;