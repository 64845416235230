import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { handleFieldLabelUpdate } from '../../../Utils'; 

function UserFieldPropertiesModal({ show, handleClose, item, editPropertySubmit }) {
  let fields = item?.extra_fields;
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label);
  const [allowRecordAccessibility, setAllowRecordAccessibility] = useState(fields?.allow_record_accessibility);
  const [permission, setPermission] = useState(fields?.permission);
  const [type, setType] = useState(fields?.user_type);
  const [required, setRequired] = useState(fields?.required);
  const [showTooltip, setShowTooltip] = useState({ checked: fields?.show_tooltip?.checked, type: fields?.show_tooltip?.type, message: fields?.show_tooltip?.message });
  const [showPreview, setShowPreview] = useState(false);
  const [labelError, setLabelError] = useState(''); 

  const handleFieldLabelChange = (value) => {
    handleFieldLabelUpdate(value, (newLabel) => {
      setFieldLabel(newLabel);
    }, setLabelError); 
  };

  const handleCancel = () => {
    setFieldLabel('');
    setAllowRecordAccessibility(false);
    setPermission('');
    setType('single_user');
    setRequired(false);
    setShowTooltip({ checked: false, type: 'static', message: '' });
    handleClose();
  };

  const handleSubmit = () => {
    if (labelError) return; 

    editPropertySubmit({
      field_label: fieldLabel,
      user_type : type,
      allow_record_accessibility : allowRecordAccessibility,
      permission,
      required,
      show_tooltip: showTooltip,
    });
    handleClose();
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Field Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Field Label</Form.Label>
            <Form.Control type="text" value={fieldLabel} onChange={(e) => handleFieldLabelChange(e.target.value)} />
            {labelError && <div className="text-danger">{labelError}</div>} 
          </Form.Group>

          <Form.Group controlId="formUserType" className="mb-3">
            <Form.Label>Type</Form.Label>
            <div className="d-flex">
              <Form.Check
                inline
                label="Single User"
                type="radio"
                id="singleUserType"
                name="userType"
                checked={type === 'single_user'}
                onChange={() => {
                  setType('single_user');
                }}
                className="me-3"
              />
              <Form.Check
                inline
                label="Multiple Users"
                type="radio"
                id="multipleUserType"
                name="userType"
                checked={type === 'multiple_user'}
                onChange={() => {
                  setType('multiple_user');
                }}
              />
            </div>
          </Form.Group>

          <>
            <Form.Group className="mb-3">
              <Form.Check
                label="Allow Record Accessibility"
                checked={allowRecordAccessibility}
                onChange={(e) => setAllowRecordAccessibility(e.target.checked)}
              />
            </Form.Group>

            {allowRecordAccessibility && (
              <Form.Group className="mb-3">
                <Form.Label>Permission</Form.Label>
                <Form.Select value={permission} onChange={(e) => setPermission(e.target.value)}>
                  <option value="Full Access">Full Access</option>
                  <option value="Read & Write">Read & Write</option>
                  <option value="Read Only">Read Only</option>
                </Form.Select>
              </Form.Group>
            )}

            {type === "single_user" && (
              <Form.Group className="mb-3">
                <Form.Check label="Required" checked={required} onChange={(e) => setRequired(e.target.checked)} />
              </Form.Group>
            )}

<Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip.checked}
              onChange={() => setShowTooltip({ ...showTooltip, checked: !showTooltip.checked })}
            />
          </Form.Group>

          {showTooltip.checked && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Message (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={showTooltip.message}
                  onChange={(e) => setShowTooltip({ ...showTooltip, message: e.target.value })}
                  placeholder="Enter Tooltip message"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={showTooltip.type === 'info'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'info' })}
                />
                <Form.Check
                  type="radio"
                  label="Static Text"
                  name="tooltipOption"
                  checked={showTooltip.type === 'static'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'static' })}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showTooltip.type === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {showTooltip.message && <span className="ms-2">{showTooltip.message}</span>}
                    </div>
                  ) : (
                    <p>{showTooltip.message}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
          </>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={labelError}> 
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserFieldPropertiesModal;
