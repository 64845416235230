import React from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import '../../Assets/css/dashboard.css';
import Setup from "../../Components/Setup";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { Utils } from "../../Utils";

const DashboarLayout = () => {
    const location = useLocation();
    const hideSetup = ['/workflow-rules', '/form-module',
        '/settings/personal-settings', '/settings/users', '/settings/company-settings',
        '/settings/profiles', '/settings/roles-and-sharing', '/settings/login-history', '/settings/audit-log',
        '/settings/email', '/settings/business-messaging', '/settings/notification-sms'
    ];
    for (var i = 0; i < hideSetup.length; i++) {
        hideSetup[i] = Utils.getNavigationUrl(hideSetup[i]);
    }
    const isShowSetup = hideSetup.some(path =>
        matchPath({ path, exact: true }, location.pathname)
    ) || location.pathname?.includes("/form-module/layout/");

    // Main sidebar
    const hideSidebarPages = [Utils.getNavigationUrl("/form-module/create"), Utils.getNavigationUrl("/form-module/update/"), Utils.getNavigationUrl("/print-template"),];
    const isShowSidebar = hideSidebarPages.every(path => !location.pathname.includes(path));

    return (
        <React.Fragment>
            <Navbar />
            <div className={`main-panel ${isShowSidebar ? 'with-sidebar' : ''}`}>
                {isShowSidebar && <Sidebar />}
                <div className={`${isShowSidebar ? 'main-content' : ''}`}>
                    {isShowSetup ? <Setup /> : <Outlet />}
                </div>
            </div>
        </React.Fragment >
    );
}

export default DashboarLayout;
