import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Utils } from "../../../Utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getItem } from "../../../Services/localService";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import EmailTemplateEditor from "../EmailTemplate";

export const DeleteWorkflowModal = ({
    showAlertModal,
    selectedId,
    setShowAlertModal,
    setSelectedId,
    removeWorkflow
}) => {
    return (
        <Modal
            show={showAlertModal}
            onHide={() => setShowAlertModal(false)}
            backdrop="static"
            keyboard={false}
            className="modal-md"
        >
            <Modal.Body>
                <h4 className='pb-3'>Delete Workflow Rule</h4>
                <p className='fw-semibold'>
                    Are you sure you want to delete this workflow rule?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-outline-secondary rounded-pill flat-btn text-secondary"
                    onClick={() => { setSelectedId(''); setShowAlertModal(false) }}
                    data-bs-dismiss="modal">
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-danger rounded-pill btn-blue"
                    data-bs-dismiss="modal"
                    onClick={() => { removeWorkflow(selectedId); setShowAlertModal(false) }}>
                    Yes, Delete
                </button>
            </Modal.Footer>
        </Modal>
    )
};

export const CreateRuleModal = ({
    showCreateModal,
    setShowCreateModal,
    formData,
    handleInputChange,
    activeCount
}) => {
    let navigate = useNavigate();
    let formList = useSelector((state) => state?.forms);
    let basicPermissionData = getItem('modulePermission')?.basic;
    const [moduleList, setModuleList] = useState([]);
    const isFormComplete = formData.module && formData.ruleName;

    useEffect(() => {
        if (formList?.navbarList?.length > 0) {
            let list = formList?.navbarList?.filter(x => x.show);
            if (basicPermissionData) {
                list = list.filter(({ _id }) => basicPermissionData?.some(x => (x?.id == _id && x?.activities?.view == true)));
            }
            setModuleList(list);
        }
    }, [formList?.navbarList]);

    return (
        <Modal
            show={showCreateModal}
            onHide={() => setShowCreateModal(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create New Rule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Module</Form.Label>
                        <Form.Select name={'module'}
                            required
                            value={formData.module}
                            onChange={handleInputChange} >
                            <option value="" disabled>Select</option>
                            {moduleList && moduleList.length > 0 ? (
                                moduleList.map((module) => (
                                    <option key={module._id} value={module._id + '#' + module.name}>{module.name}</option>
                                ))
                            ) : null}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Rule Name</Form.Label>
                        <Form.Control
                            value={formData.ruleName}
                            onChange={handleInputChange}
                            type={'text'}
                            name={'ruleName'}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={1}
                            value={formData.ruleDescription}
                            onChange={handleInputChange}
                            type={'text'}
                            name={'ruleDescription'}
                        />
                    </Form.Group>
                </Form>
                <p className="fw-semibold">Active workflow count: {activeCount}</p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-outline-primary rounded-pill btn-blue"
                    onClick={() => setShowCreateModal(false)}>
                    Cancel
                </button>
                <button
                    className="btn btn-primary rounded-pill btn-blue"
                    disabled={!isFormComplete}
                    onClick={() => {
                        setShowCreateModal(false);
                        navigate(Utils.getNavigationUrl("workflow-rules/create"), {
                            state: {
                                moduleId: {
                                    _id: formData?.module?.split('#')[0],
                                    name: formData?.module?.split('#')[1]
                                },
                                ruleName: formData?.ruleName,
                                ruleDescription: formData?.ruleDescription
                            }
                        })
                    }}
                >Next</button>
            </Modal.Footer>
        </Modal>
    )
};

export const ApprovalUserModal = ({
    showApprovalUserModal,
    setShowApprovalUserModal,
    module_name,
    approvalUserDataBkp,
    getAllUsers,
    formApprovalUserHandler,
    formApprovalSectionHandler,
    formPrimaryFieldHandler,
    sectionsData,
    fieldList,
    handleAddUser,
    handleRemoveUser,
    handleSaveApprovalUser,
    validApprovalUser
}) => {
    return (
        <Modal
            show={showApprovalUserModal}
            onHide={() => setShowApprovalUserModal(false)}
            backdrop="static"
            keyboard={false}
            className="modal-lg"
        >
            <Modal.Header>
                <h5 className="modal-title fs-5" id="actionFormLabel">Approval Users - <span className="fw-semibold">{module_name}</span></h5>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '400px' }}>
                <div className="form-group card mb-2">
                    {approvalUserDataBkp?.approvalUserList?.length > 0 && approvalUserDataBkp?.approvalUserList?.map((dta, index) => (
                        <>
                            <div className="row ps-3">
                                <select
                                    className="form-select mb-3 me-2 col-4 w-25"
                                    id="sub-select"
                                    name="userId"
                                    value={dta.userId}
                                    onChange={(e) => formApprovalUserHandler(e, index)}
                                >
                                    <option value="" disabled selected>Approval User</option>
                                    {getAllUsers?.allUserData?.length > 0 && getAllUsers?.allUserData?.map((user) => (
                                        <option value={user._id}>
                                            {user.firstName + user.lastName}
                                        </option>
                                    ))}
                                </select>
                                <Select
                                    className="mb-3 me-2 col-4 w-25"
                                    placeholder="Approval Sections"
                                    closeMenuOnSelect={false}
                                    options={sectionsData}
                                    isMulti
                                    name="approvalSections"
                                    value={dta.approvalSections}
                                    onChange={(e) => formApprovalSectionHandler(e, index)}
                                />
                                <select
                                    className="form-select mb-3 me-3 col-3 w-22"
                                    id="sub-select"
                                    placeholder="test"
                                    name="primaryField"
                                    value={dta.primaryField}
                                    onChange={(e) => formPrimaryFieldHandler(e, index)}
                                >
                                    <option value="" disabled selected>Primary Field</option>
                                    {fieldList.map((data) => data?.extra_fields?.required == true ? (
                                        <option value={data?.extra_fields?.field_label}>
                                            {data?.extra_fields?.field_label}
                                        </option>
                                    ) :
                                        (
                                            <option value={data?.extra_fields?.field_label}>
                                                {data?.extra_fields?.field_label}
                                            </option>
                                        ))}
                                </select>
                                <button type="button" className="form-control btn btn-light col-2 w-10 mb-3 me-2 rounded-circle"
                                    onClick={() => handleAddUser(index)}>+</button>
                                <button type="button" className="form-control btn btn-light col-2 w-10 mb-3 rounded-circle"
                                    onClick={() => handleRemoveUser(index)}>-</button>
                            </div>
                        </>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => { setShowApprovalUserModal(false) }}>Cancel</button>
                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSaveApprovalUser()} disabled={!validApprovalUser}>Save</button>
            </Modal.Footer>
        </Modal>
    )
};

export const EmailNotificationModal = ({
    showEmailNotificationModal,
    setShowEmailNotificationModal,
    module_name,
    handleSaveEmailNotification,
    mailHandler,
    emailData,
    departmentOptions,
    HideGroupHeading,
    HideGroupMenuList,
    handleToUserChange,
    handleCcUserChange,
    handleBccUserChange,
    setReceipientSelctionType,
    setValue,
    showBcc,
    isShowBcc,
    setShowParticipantModal,
    setIsShowTemplateList,
    setShowTemplateModal,
    selectedTemplate,
    setShowPreviewModal
}) => {
    return (
        <Modal
            show={showEmailNotificationModal}
            onHide={() => setShowEmailNotificationModal(false)}
            backdrop="static"
            keyboard={false}
            className="modal-lg"
        >
            <Modal.Header>
                <h5 className="modal-title fs-5" id="actionFormLabel">Email Notification - <span className="fw-semibold">{module_name}</span></h5>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '400px' }}>
                <div className="form-group card mb-2">
                    <div className="row">
                        <label className="col-3">Name</label>
                        <div className="col-6">
                            <input type="text" className="form-control"
                                onChange={(e) => mailHandler(e)}
                                name="name"
                                value={emailData?.rules?.name} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-3">To</label>
                        <div className="col-6">
                            <div className="input-group">
                                <Select
                                    className="react-select multi-select react-select-container"
                                    options={departmentOptions}
                                    isMulti
                                    name="departmentOptions"
                                    classNamePrefix="select"
                                    blurInputOnSelect={false}
                                    closeMenuOnSelect={false}
                                    defaultMenuIsOpen={false}
                                    components={{
                                        GroupHeading: HideGroupHeading,
                                        MenuList: HideGroupMenuList,
                                    }}
                                    placeholder="Add Users"
                                    required
                                    value={emailData.rules.to}
                                    onChange={handleToUserChange}
                                />
                                <button className="btn btn-outline-secondary btn-blue" type="button"
                                    onClick={() => { setReceipientSelctionType('base'); setValue([]); setShowParticipantModal(true); }}>
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-3"></label>
                        <div className="col-9">
                            <div className="form-control d-flex p-0"
                                style={{ border: 'none' }}>
                                <input className="form-check-input" type="checkbox" id="agreeToc" required="" value=""
                                    onChange={(e) => mailHandler(e)}
                                    name="isSingleMail"
                                    checked={emailData?.rules?.isSingleMail} />
                                <label className="form-check-label ms-2">Send this notification as a Single Mass Email with all recipients displayed</label>
                            </div>
                        </div>
                    </div>
                    {emailData?.rules?.isSingleMail &&
                        <>
                            <div className="row mt-2">
                                <label className="col-3">CC</label>
                                <div className="col-6">
                                    <div className="input-group">
                                        <Select
                                            className="react-select multi-select react-select-container"
                                            options={departmentOptions}
                                            isMulti
                                            name="departmentOptions"
                                            classNamePrefix="select"
                                            blurInputOnSelect={false}
                                            closeMenuOnSelect={false}
                                            defaultMenuIsOpen={false}
                                            components={{
                                                GroupHeading: HideGroupHeading,
                                                MenuList: HideGroupMenuList,
                                            }}
                                            placeholder="Add Users"
                                            required
                                            value={emailData.rules.cc}
                                            onChange={handleCcUserChange}
                                        />
                                        <button className="btn btn-outline-secondary btn-blue" type="button"
                                            onClick={() => { setReceipientSelctionType('cc'); setValue([]); setShowParticipantModal(true); }}>
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {!showBcc &&
                                <div className="row mt-2">
                                    <label className="col-3"></label>
                                    <div className="col-6">
                                        <a style={{ color: 'rgba(13,110,253)', cursor: 'pointer' }} onClick={() => isShowBcc(true)}>BCC</a>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {emailData?.rules?.isSingleMail && showBcc &&
                        <div className="row mt-2">
                            <label className="col-3">BCC</label>
                            <div className="col-6">
                                <div className="input-group">
                                    <Select
                                        className="react-select multi-select react-select-container"
                                        options={departmentOptions}
                                        isMulti
                                        name="departmentOptions"
                                        classNamePrefix="select"
                                        blurInputOnSelect={false}
                                        closeMenuOnSelect={false}
                                        defaultMenuIsOpen={false}
                                        components={{
                                            GroupHeading: HideGroupHeading,
                                            MenuList: HideGroupMenuList,
                                        }}
                                        placeholder="Add Users"
                                        required
                                        value={emailData.rules.bcc}
                                        onChange={handleBccUserChange}
                                    />
                                    <button className="btn btn-outline-secondary btn-blue" type="button"
                                        onClick={() => { setReceipientSelctionType('bcc'); setValue([]); setShowParticipantModal(true); }}>
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row mt-2">
                        <label className="col-3">Email Template</label>
                        <div className="col-6">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue"
                                onClick={() => { setIsShowTemplateList(true); setShowTemplateModal(true) }}
                            >Select Template</button>
                            {selectedTemplate &&
                                <div className="selected-template">
                                    <span className="cursor-pointer" style={{ color: '#338cf0' }}
                                        onClick={() => { setShowPreviewModal(true); }}
                                    >{selectedTemplate?.templateName}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="form-control d-flex p-0"
                                style={{ border: 'none' }}>
                                {/* <div className="form-check"> */}
                                <input className="form-check-input" type="checkbox" id="agreeToc" required="" value=""
                                    onChange={(e) => mailHandler(e)}
                                    name="isSendAtBestTime"
                                    checked={emailData?.rules?.isSendAtBestTime} />
                                <label className="form-check-label ms-2"> Send this email notification at Best Time to Email.</label>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => { setShowEmailNotificationModal(false); setReceipientSelctionType('') }}>Cancel</button>
                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSaveEmailNotification()}
                // disabled={!validEmailNotification}
                >Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export const ShowParticipantModal = ({
    showParticipantModal,
    setShowParticipantModal,
    handleKeyDown,
    inputValue,
    value,
    setValue,
    setInputValue,
    setReceipientSelctionType,
    isValidRecipient,
    handleRecipients
}) => {
    return (
        <Modal
            show={showParticipantModal}
            onHide={() => setShowParticipantModal(false)}
            backdrop="static"
            keyboard={false}
            className="modal-lg"
        >
            <Modal.Header>
                <h5 className="modal-title fs-5">Additional Recipients</h5>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group card mb-2">
                    <div className="row ps-3">
                        <div className="col-12">
                            <CreatableSelect
                                inputValue={inputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={(newValue) => setValue(newValue)}
                                onInputChange={(newValue) => setInputValue(newValue)}
                                onKeyDown={handleKeyDown}
                                placeholder="Add Emails"
                                value={value}
                            />
                        </div>
                        <div className="mt-3">
                            Press enter to separate additional email addresses.
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => { setReceipientSelctionType(''); setShowParticipantModal(false); }}>Cancel</button>
                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { handleRecipients(); setShowParticipantModal(false); }}
                    disabled={!isValidRecipient}>Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export const TemplateModal = ({
    showTemplateModal,
    setShowTemplateModal,
    isShowTemplateList,
    setIsShowTemplateList,
    emailData,
    setEmailData,
    setSelectedTemplate,
    setShowPreviewModal,
    handleEmailData,
    validEmailTemplate,
    workflowList,
    setUpdatedContent,
    module_id,
    id,
    isEdit
}) => {
    return (
        <Modal
            show={showTemplateModal}
            onHide={() => { setShowTemplateModal(false) }}
            backdrop="static"
            keyboard={false}
            className="modal-xl"
        >
            <Modal.Header closeButton>
                <h5 className="modal-title fs-5" id="emailTemplateFormLabel">
                    {isShowTemplateList ? 'Select Template' : 'Email Template'}
                </h5>
            </Modal.Header>
            {isShowTemplateList ?
                <Modal.Body style={{ minHeight: '400px' }}>
                    <div className="row">
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="left d-flex align-items-start">
                                <div className="search-box border rounded-pill">
                                    <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    <input type="search" placeholder="Search" className="searchfield bg-white" />
                                </div>
                            </div>
                            <div className="ms-auto">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary rounded-pill btn-blue"
                                        onClick={() => setIsShowTemplateList(false)}>
                                        <i className="fa-solid fa-plus me-2"></i>
                                        <span>Create Template</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="col-md-12">
                                <div className="tbl-container">
                                    <div className="table-responsive">
                                        <table className="table table-hover hover-icon-table users-table mb-0">
                                            <tbody>
                                                {workflowList?.templateData?.length > 0 &&
                                                    <>
                                                        {workflowList?.templateData?.map((data) => (
                                                            <tr>
                                                                <td className="fw-semibold">
                                                                    <i className="fa-regular fa-star me-2 fs-10"></i>
                                                                    <span className="cursor-pointer"
                                                                        onClick={() => {
                                                                            setEmailData((form) => ({
                                                                                ...form,
                                                                                rules: {
                                                                                    ...form.rules,
                                                                                    templateData: {
                                                                                        ...form.rules.templateData,
                                                                                        templateName: data.rules.emailData.templateData.templateName,
                                                                                        subject: data.rules.emailData.templateData.subject,
                                                                                        content: data.rules.emailData.templateData.content,
                                                                                    }
                                                                                }
                                                                            }));
                                                                            setSelectedTemplate(data.rules.emailData.templateData);
                                                                            setShowTemplateModal(false);
                                                                        }}
                                                                    >{data?.rules?.emailData?.templateData?.templateName}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="hover-icon" style={{ color: '#338cf0' }}
                                                                        onClick={() => { setSelectedTemplate(data.rules.emailData.templateData); setShowPreviewModal(true); }}
                                                                    >Preview</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                :
                <>
                    <Modal.Body style={{ minHeight: '400px' }}>
                        <div className="row align-items-center mb-3">
                            <label htmlFor="templateName" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                Template Name
                            </label>
                            <div className="col-md-auto col-xl-3">
                                <input type="text" className="form-control" id="templateName" placeholder='Template name'
                                    name="templateName"
                                    value={emailData.rules.templateData.templateName}
                                    onChange={(e) => { handleEmailData(e) }}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <label htmlFor="subject" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                Subject
                            </label>
                            <div className="col-md-auto col-xl-6">
                                <input type="text" className="form-control" id="subject" placeholder='Email subject'
                                    name="subject"
                                    value={emailData.rules.templateData.subject}
                                    onChange={(e) => { handleEmailData(e) }}
                                />
                            </div>
                        </div>
                        <div className="row align-items-start mb-3">
                            <label htmlFor="templateContent" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                Content
                            </label>
                            <div className="col-md-auto col-xl-6">
                                <EmailTemplateEditor _content={setUpdatedContent} module_id={module_id} id={id} isEdit={isEdit} emailData={emailData} />
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button type="button" className={`btn btn-outline-primary rounded-pill btn-blue`}
                            onClick={() => { setSelectedTemplate(emailData); setShowPreviewModal(true) }}
                        >Preview</button>
                        <button type="button" className="btn btn-outline-primary rounded-pill btn-blue"
                            onClick={() => { setShowTemplateModal(false) }}
                        >Cancel</button>
                        <button type="button" className="btn btn-primary rounded-pill btn-blue"
                            onClick={() => { setSelectedTemplate(emailData.rules.templateData); setShowTemplateModal(false); }}
                            disabled={!validEmailTemplate}
                        >Save</button>
                    </Modal.Footer>
                </>
            }
        </Modal>
    )
}

export const PreviewModal = ({
    showPreviewModal,
    setShowPreviewModal,
    selectedTemplate
}) => {
    return (
        <Modal
            show={showPreviewModal}
            onHide={() => { setShowPreviewModal(false); }}
            backdrop="static"
            keyboard={false}
            className="modal-xl"
        >
            <Modal.Header closeButton>
                <div className="row">
                    <span className="col-12 fw-semibold">{selectedTemplate?.templateName}</span>
                    <span className="col-12">{selectedTemplate?.subject}</span>
                </div>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '500px', backgroundColor: 'rgb(240,240,240)' }}>
                <div className="row align-items-center mb-3">
                    <div dangerouslySetInnerHTML={{ __html: selectedTemplate?.content }}>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}