import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';

// import './styles.css';

const ImgEditor = ({ updateContent, moduleName, moduleFieldName }) => {
    const { quill, quillRef, Quill } = useQuill({
        modules: { blotFormatter: {} }
    });

    if (Quill && !quill) {
        // const BlotFormatter = require('quill-blot-formatter');
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                console.log('here')
                // console.log('get text', quill.getText());
                // console.log('get inner html:', quill.root.innerHTML)
                // console.log('old content:', oldContents)
                // console.log('Text change!');
                // console.log(delta);

                // let currrentContents = quill.getContents();
                // console.log('current content:', currrentContents)
                // console.log(currrentContents.diff(oldContents));
                updateContent(quill.root.innerHTML)
            });
        }
    }, [quill, Quill]);

    // console.log('quill:', quill)
    return (
        <div>
            <div ref={quillRef} />
        </div>
    );
};

export default ImgEditor;