import React from 'react';

function KpiRankings() {
    return (
        <>
            <h6 className="card-title">Top 5 Deals Owners</h6>
            <table className="table align-middle mb-0">
                <thead>
                    <tr>
                        <th>Deal Owner</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1. Mark</td>
                        <td>$1280</td>
                    </tr>
                    <tr>
                        <td>2. Jason</td>
                        <td>$1010</td>
                    </tr>
                    <tr>
                        <td>3. Michael</td>
                        <td>$777</td>
                    </tr>
                    <tr>
                        <td>4. Steve</td>
                        <td>$728</td>
                    </tr>
                    <tr>
                        <td>5. David</td>
                        <td>$696</td>
                    </tr>
                    <tr>
                        <td>6. John</td>
                        <td>$533</td>
                    </tr>
                </tbody>
            </table>
            <small className="text-muted">Rankings</small>
        </>
    );
}

export default KpiRankings;
