import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ toastMessage, type, navigation = null, toastInstance }) => {
    return (
        <React.Fragment>
            <ToastContainer />
        </React.Fragment>
    )
}

export default Toast