import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './css/PickList.css';

function PredefinedChoices({ show, handleClose }) {
    let predefinedChoicesList = {
        "week": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "month_year": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "time_zones": ["PST", "MST", "CST", "EST", "GMT", "CET", "IST", "JST", "AEST"],
        "us_state": ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"],
        "continents": ["Africa", "Antarctica", "Asia", "Europe", "North America", "Australia/Oceania", "South America"],
        "countries_regions": ["United States", "Canada", "United Kingdom", "Germany", "France", "Italy", "Spain", "China", "India", "Japan", "Brazil", "Australia", "South Africa"]
    };

    let [selectedKey, setSelectedKey] = useState("week");
    let [selectedItems, setSelectedItems] = useState([]);
    let [lastSelectedIndex, setLastSelectedIndex] = useState(null);

    const handleItemClick = (item, index, e) => {
        let newSelection = [...selectedItems];
        if (e.shiftKey && lastSelectedIndex !== null) {
            let start = Math.min(lastSelectedIndex, index);
            let end = Math.max(lastSelectedIndex, index);
            let isRangeSelected = predefinedChoicesList[selectedKey]
                .slice(start, end + 1)
                .every(selectedItem => newSelection.includes(selectedItem));
            if (isRangeSelected) {
                newSelection = newSelection.filter(
                    selected => !predefinedChoicesList[selectedKey].slice((start + 1), end + 1).includes(selected)
                );
            } else {
                for (let i = start; i <= end; i++) {
                    let currentItem = predefinedChoicesList[selectedKey][i];
                    if (!newSelection.includes(currentItem)) {
                        newSelection.push(currentItem);
                    }
                }
            }
        }
        else if (e.ctrlKey || e.metaKey) {
            if (newSelection.includes(item)) {
                newSelection = newSelection.filter(selected => selected !== item);
            } else {
                newSelection.push(item);
            }
        }
        else {
            newSelection = [item];
        }
        newSelection = predefinedChoicesList[selectedKey].filter(item => newSelection.includes(item));
        setSelectedItems(newSelection);
        setLastSelectedIndex(index);
    };
    

    const tabChange = (key) => {
        setSelectedKey(key);
        setSelectedItems([]);
        setLastSelectedIndex(null);
    }

    const handleCloseModal = () => {
        handleClose([]);
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Pick List Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="mb-3">
                        <span>Import Predefined Choices</span>
                        <div className="predefined-choices-list-box bg-light p-3">
                            <div className="row">
                                <div className="col-md-4 p-1">
                                    <div className="list-group">
                                        <a className={`list-group-item list-group-item-action ${selectedKey === 'week' ? 'active' : ''}`} onClick={() => tabChange("week")}>Days of the week</a>
                                        <a className={`list-group-item list-group-item-action ${selectedKey === 'month_year' ? 'active' : ''}`} onClick={() => tabChange("month_year")}>Month of the year</a>
                                        <a className={`list-group-item list-group-item-action ${selectedKey === 'time_zones' ? 'active' : ''}`} onClick={() => tabChange("time_zones")}>Time Zones</a>
                                        <a className={`list-group-item list-group-item-action ${selectedKey === 'us_state' ? 'active' : ''}`} onClick={() => tabChange("us_state")}>US State</a>
                                        <a className={`list-group-item list-group-item-action ${selectedKey === 'continents' ? 'active' : ''}`} onClick={() => tabChange("continents")}>Continents</a>
                                        <a className={`list-group-item list-group-item-action ${selectedKey === 'countries_regions' ? 'active' : ''}`} onClick={() => tabChange("countries_regions")}>Countries / Regions</a>
                                    </div>
                                </div>
                                <div className="col-md-8 p-1">
                                    <div className="list-group">
                                        {predefinedChoicesList?.[selectedKey]?.map((item, index) => (
                                            <div key={item}
                                                className={`prevent-select list-group-item ${selectedItems.includes(item) ? 'active' : ''}`}
                                                onClick={(e) => handleItemClick(item, index, e)}
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleClose(selectedItems?.length > 0 ? selectedItems : predefinedChoicesList[selectedKey])}>
                    Add Values
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PredefinedChoices;
