import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getItem } from "../Services/localService";
import Login from "../Pages/Auth/Login";

const RootRedirect = ({ children }) => {
    const location = useLocation();
    const token = getItem("token");
    const ACCESS_URL = getItem('user')?.organization?.access_url;
    if (token && ACCESS_URL) {
        return <Navigate to={`/${ACCESS_URL}/dashboard`} />
    } else if (!token) {
        if (location.pathname === '/auth/login')
            return <Login />
        else
            return <Outlet />
    }
    return children;
};

export default RootRedirect;