import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Collapse from 'react-bootstrap/Collapse';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaBullhorn } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import { CiFilter, CiSearch } from "react-icons/ci";
import userIcon from '../../../Assets/images/icons/user-profile-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import { getAuditLogsStart } from '../../../Store/Slices/auditLogSlice';
import { formatTime } from '../../../Components/DateFn';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a className='dropdown-item'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span className='ms-2'>&#128898;</span>
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const EntitiesMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <InputGroup className="mb-2">
                    <InputGroup.Text id="search-icon" className='rounded-0 bg-white px-2 border-end-0'><CiSearch /></InputGroup.Text>
                    <Form.Control
                        autoFocus
                        className="form-control rounded-0 border-start-0 ps-0"
                        placeholder="Search Entities"
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                </InputGroup>
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

const customPopover = (
    <Popover id="custom-popover" className='audit custom-popover'>
        <Popover.Header as="h3">BUSINESS HOURS</Popover.Header>
        <Popover.Body>
            <div className='hstack gap-3'>
                <div>
                    <div>Before Changes</div>
                    <div className='mt-3'>Business hours - <span className='fw-semibold'>24 * 5</span></div>
                </div>
                <div className="vr"></div>
                <div>
                    <div>After Changes</div>
                    <div className='mt-3'>Moday - Friday <span className='fw-semibold'>(09:30 AM - 05:30 PM)</span></div>
                </div>
            </div>
        </Popover.Body>
    </Popover>
);

const AuditLog = () => {
    const dispatch = useDispatch();
    const allUsers = useSelector((state) => state?.user)?.allUserData;
    const { logs, isLoading, message } = useSelector((state) => state?.auditlog);

    // Announcement Alert
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    // Filter Collapse
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserName, setSelectedUserName] = useState('All Users');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAction, setSelectedAction] = useState('All Actions');

    useEffect(() => {
        const params = {};

        // Handle Action Filter
        if (selectedAction !== 'All Actions') {
            params.actions = selectedAction;
        }

        // // Handle Date Filter
        if (selectedDate === 'Today') {
            params.filterType = 'today';
        } else if (selectedDate === 'Last 7 Days') {
            params.filterType = 'last7Days';
        } else if (selectedDate === 'Last 30 Days') {
            params.filterType = 'last30Days';
        } else if (selectedDate && !dateRange.startDate && !dateRange.endDate) {
            const formattedDate = new Date(selectedDate).toLocaleDateString('en-US');
            params.filterType = 'specificDate';
            params.specificDate = formattedDate;
        } else if (dateRange.startDate && dateRange.endDate) {
            params.filterType = 'dateRange';
            params.startDate = dateRange.startDate;
            params.endDate = dateRange.endDate;
        }

        if (selectedUser && selectedUserName !== 'All Users') {
            params.user = selectedUser;
        }
        dispatch(getAuditLogsStart(params));
    }, [selectedAction, selectedDate, dateRange, selectedUser, selectedUserName]);

    const handleSelectUser = (user) => {
        setSelectedUser(user._id);
        setSelectedUserName(user.firstName);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredUsers = Array.isArray(allUsers)
        ? allUsers.filter((user) =>
            user.firstName?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];

    const handleSpecificDateChange = (e) => {
        setSelectedDate(e.target.value);
        setDateRange({ startDate: '', endDate: '' });
    };

    const handleDateRangeChange = (field, value) => {
        setDateRange((prev) => ({ ...prev, [field]: value }));
        setSelectedDate('');
    };

    const displayDateRange = () => {
        const { startDate, endDate } = dateRange;
        return startDate && endDate ? `${startDate} - ${endDate}` : 'All Time';
    };

    return (
        <>
            <Loader isVisible={isLoading} />
            <div className='audit-log-page overflow-y-auto'>
                {/* Page Header */}
                <div className="d-flex flex-row mt-4">
                    <div className="left-con w-75">
                        <h5 className="fw-semibold">Audit Log</h5>
                        <p>
                            The audit log provides you with a chronological sequence of actions performed by the users in Didylax MDM.
                        </p>

                        <Alert variant="warning" show={showAnnouncement} className='alert-dismissible py-2 mb-0'>
                            <button type="button" className="btn-close" aria-label="Close alert"
                                onClick={() => setShowAnnouncement(false)}></button>
                            <Alert.Heading className='fs-16'>
                                <FaBullhorn className='me-2' />
                                Announcement
                            </Alert.Heading>
                            <p className='ps-4 mb-0 text-body'>
                                Audit logs have now been extended to display logs from the last three years.
                                Starting from early 2025, audit log storage will be incorporated into
                                the data storage calculation.
                            </p>
                        </Alert>
                    </div>
                    <div className="right-con d-flex flex-column align-items-end justify-content-around ms-auto">
                        <div className='btn-group'>

                            {!showAnnouncement &&
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip className='tooltip-bottom'>
                                            Announcement
                                        </Tooltip>
                                    }>
                                    <button type="button" className="btn btn-outline-warning rounded-pill py-1 px-2 flat-btn"
                                        onClick={() => setShowAnnouncement(true)}>
                                        <FaBullhorn />
                                    </button>
                                </OverlayTrigger>
                            }

                            <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn ">
                                <i className="fa-regular fa-circle-question me-2"></i>
                                Help
                            </button>
                        </div>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip className='tooltip-bottom info-tooltip'>
                                    You can export the last three years or upto 1 million (10 lakh) audit log entries.
                                    If filters are applied , only the filtered logs will be exported.
                                </Tooltip>
                            }>
                            {({ ref, ...triggerHandler }) => (

                                <button type="button" className="btn btn-link py-1 px-3 mt-2"
                                    {...triggerHandler}>
                                    <span className='me-2'>Export Audit Log</span>
                                    <span ref={ref}>
                                        <BsInfoCircle />
                                    </span>
                                </button>
                            )}
                        </OverlayTrigger>
                    </div>
                </div>
                {/* // Page Header END // */}

                {/* Filter Section */}
                <div className='filter bg-light-blue border border-light-blue rounded-3 p-3 mt-3'>
                    <p className="d-inline-flex align-items-center gap-1 mb-0">
                        <h5 className="mb-0 me-1 h6">RECENT ACTIVITY</h5>
                        <CiFilter className='fs-5 cursor-pointer' role='button'
                            onClick={() => setFilterOpen(!filterOpen)}
                            aria-controls="filter-collapse"
                            aria-expanded={filterOpen}
                        />
                    </p>
                    <Collapse in={filterOpen}>
                        <div id='filter-collapse' className='mt-3'>
                            <div className='d-flex gap-3'>
                                {/* <Dropdown className='me-3'>
                                    <Dropdown.Toggle variant='light' id="entities-dropdown" className='flat-btn bg-white with-arrow text-dark text-start'>
                                        <span className='pe-3'>All Entities</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='p-0 fs-14' as={EntitiesMenu}>
                                        <Dropdown.Item eventKey="1" active>All Entities</Dropdown.Item>
                                        <Dropdown.Item eventKey="2">Setup</Dropdown.Item>
                                        <Dropdown.Item eventKey="3">Export</Dropdown.Item>
                                        <Dropdown.Item eventKey="1">Reports</Dropdown.Item>
                                        <Dropdown.Item eventKey="4">Analytics</Dropdown.Item>
                                        <Dropdown.Item eventKey="5">Users</Dropdown.Item>
                                        <Dropdown.Item eventKey="6">Leads</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}

                                <Dropdown className='me-3'>
                                    <Dropdown.Toggle variant='light' id="users-dropdown" className='flat-btn bg-white with-arrow text-dark text-start'>
                                        <span className='pe-3'>{selectedUserName}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='p-0 fs-14'>
                                        <Dropdown.ItemText className='p-0'>
                                            <InputGroup className="mb-2">
                                                <InputGroup.Text className='rounded-0 bg-white px-2 border-end-0'><CiSearch /></InputGroup.Text>
                                                <Form.Control
                                                    autoFocus
                                                    className="form-control rounded-0 border-start-0 ps-0"
                                                    placeholder="Search Users"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </InputGroup>
                                        </Dropdown.ItemText>
                                        <Dropdown.Item onClick={() => { setSelectedUserName('All Users') }}>
                                            All Users
                                        </Dropdown.Item>
                                        {filteredUsers.length > 0 ? (
                                            filteredUsers.map((user, index) => (
                                                <Dropdown.Item key={index} onClick={() => {
                                                    setSelectedUser(user._id);
                                                    handleSelectUser(user);
                                                }}>
                                                    <span className='d-flex align-items-center'>
                                                        <img
                                                            src={user?.profilePicture || userIcon}
                                                            alt={user?.firstName || 'User'}
                                                            width={30}
                                                            height={30}
                                                        />
                                                        <span className='ps-2'>
                                                            <span className='d-block'>{user?.firstName}</span>
                                                            <span className='d-block text-secondary'>{user?.email}</span>
                                                        </span>
                                                    </span>
                                                </Dropdown.Item>

                                            ))
                                        ) : (
                                            <Dropdown.Item>No users available</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown className='me-3'>
                                    <Dropdown.Toggle variant='light' id="actions-dropdown" className='flat-btn bg-white with-arrow text-dark text-start'>
                                        <span className='pe-3'>
                                            {selectedAction.charAt(0).toUpperCase() + selectedAction.slice(1)}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='p-0 fs-14'>
                                        {['All Actions', 'added', 'updated', 'deleted'].map((action) => (
                                            <Dropdown.Item
                                                key={action}
                                                active={selectedAction === action}
                                                onClick={() => setSelectedAction(action)}
                                            >
                                                {action.charAt(0).toUpperCase() + action.slice(1)}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown className='me-3' autoClose={`outside`}>
                                    <Dropdown.Toggle variant='light' id="time-filter-dropdown" className='flat-btn bg-white with-arrow text-dark text-start'>
                                        <span className='pe-3'>
                                            {selectedDate || displayDateRange() || 'Any Time'}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='p-0 fs-14'>
                                        <Dropdown.Item onClick={() => setSelectedDate('')}>Any Time</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setSelectedDate('Today')}>Today</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setSelectedDate('Last 7 Days')}>Last 7 Days</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setSelectedDate('Last 30 Days')}>Last 30 Days</Dropdown.Item>
                                        <Dropdown.ItemText className='p-0'>
                                            <Dropdown drop="end">
                                                <Dropdown.Toggle as={CustomToggle} id="specific-date-dropdown">
                                                    On a Specific Date
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='p-0 border-0 fs-14'>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={selectedDate}
                                                        onChange={handleSpecificDateChange}
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Dropdown.ItemText>
                                        <Dropdown.ItemText className='p-0'>
                                            <Dropdown drop="end">
                                                <Dropdown.Toggle as={CustomToggle} id="date-range-dropdown">
                                                    Date Range
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='p-0 border-0 fs-14'>
                                                    <div className="px-3">
                                                        {/* Start Date Input */}
                                                        <label htmlFor="startDate" className="form-label">Start Date</label>
                                                        <input
                                                            type="date"
                                                            id="startDate"
                                                            className="form-control mb-2"
                                                            value={dateRange.startDate}
                                                            onChange={(e) => handleDateRangeChange('startDate', e.target.value)}
                                                            placeholder="Start Date"
                                                        />
                                                        {/* End Date Input */}
                                                        <label htmlFor="endDate" className="form-label">End Date</label>
                                                        <input
                                                            type="date"
                                                            id="endDate"
                                                            className="form-control mb-2"
                                                            value={dateRange.endDate}
                                                            onChange={(e) => handleDateRangeChange('endDate', e.target.value)}
                                                            placeholder="End Date"
                                                        />
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Dropdown.ItemText>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Collapse>
                </div>
                {/* // Filter Section END // */}

                {/* Log Contents */}
                <div className='log-content'>
                    <div className='s-date mt-4'>
                        <div className="audit-log">
                            {!isLoading ? (
                                logs.length > 0 ? (
                                    logs.map((log, index) => (
                                        <div key={index} className="log-content">
                                            <h5 className="fw-semibold fs-16 mt-4">{log.date}</h5>
                                            {log.activities.map((activity, idx) => (
                                                <div key={idx} className="s-time mt-3">
                                                    <span className="time pe-4 small text-muted">
                                                        {formatTime(activity?.time)}
                                                    </span>
                                                    <span className="con">
                                                        <span className="user-name pe-2">{activity.msg}</span>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-logs mt-4">
                                        <h5 className="text-muted">No Logs are Available</h5>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>
                </div>
                {/* <div className='log-content'>
                    <div className='s-date mt-4'>
                        <div className="audit-log">
                            {!isLoading ? (
                                logs.length > 0 ? (
                                    logs.map((log, index) => (
                                        <div key={index} className="log-content">
                                            <h5 className="fw-semibold fs-16 mt-4">{log.date}</h5>
                                            {log.activities.map((activity, idx) => (
                                                <div key={idx} className="s-time mt-3">
                                                    <span className="time pe-4 small text-muted">{activity.time}</span>
                                                    <span className="con">
                                                        <span className='user-name pe-2'> {activity.msg}</span>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-logs mt-4">
                                        <h5 className="text-muted">No Logs are Available</h5>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>
                </div> */}
                {/* // Log Contents END // */}
            </div>
        </>
    );
}
export default AuditLog;