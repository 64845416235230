import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getApprovalStart, updateApprovalStatusStart, updateRejectStatusStart } from "../../../Store/Slices/workflowSlice";
import { Utils } from "../../../Utils";

const DashboardDetails = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let workflowList = useSelector((state) => state?.workflow);
    const [tableIndex, setTableIndex] = useState(null);
    const [id, setId] = useState(null);
    const [moduleId, setModuleId] = useState(null);
    const [content, setContent] = useState(null);

    // console.log('WORK FLOW LIST?', workflowList)

    let filterdArr = [];
    if (workflowList?.data) {
        if (Array.isArray(workflowList?.data)) {
            let tempData = workflowList?.data?.filter((dt) => dt._id == id)
            if (tempData) {
                // console.log('tempdata---->', tempData)
                tempData.forEach(element => {
                    let sections = element.approvalUser[0].approvalSections.map(option => option.value);
                    element.data.forEach(obj => {
                        sections.forEach((sectionname) => {
                            if (obj?.section_name?.includes([sectionname])) {
                                filterdArr.push(obj.section_data)
                            }
                        })
                    });


                    // console.log('SECTION DATA FILTERED ARR?', filterdArr)

                    // console.log('element?', element)
                    // main::start
                    // if (element?.position == 1) {
                    //     element.data.forEach(obj => {
                    //         if (obj?.section_name?.includes('Initial Screen') ||
                    //             obj?.section_name?.includes('Product Hierarchy')) {
                    //             filterdArr.push(obj.section_data)
                    //         }
                    //     })
                    // }
                    // if (element?.position == 2) {
                    //     element.data.forEach(obj => {
                    //         if (obj.section_name.includes('Initial Screen') ||
                    //             obj.section_name.includes('Sales')) {
                    //             filterdArr.push(obj.section_data)
                    //         }
                    //     })
                    // }
                    // if (element?.position == 3) {
                    //     element.data.forEach(obj => {
                    //         if (obj.section_name.includes('Initial Screen') ||
                    //             obj.section_name.includes('BIS Standard')) {
                    //             filterdArr.push(obj.section_data)
                    //         }
                    //     })
                    // }
                    // if (element?.position == 4) {
                    //     element.data.forEach(obj => {
                    //         filterdArr.push(obj.section_data);
                    //     })
                    // }
                    // main::end
                });
                // if (tempData?.[0]?.position == 1) {
                // let filteredData = tempData?.[0]?.data?.filter((obj) => obj.section_name == 'Initial Screen' || obj.section_name == 'Product Hierarchy')
                // if (filteredData) {
                //     console.log('filter data---->', filteredData)
                // }
                // }
            }

            // console.log('PUSHED ARR____________', filterdArr)
        } else {
            // if (tempData) {
            // console.log('tempdata---->', tempData)
            // tempData.forEach(element => {
            // console.log('element?', element)
            let element = workflowList?.data
            if (element?.position == 1) {
                element.data.forEach(obj => {
                    if (obj.section_name.includes('Initial Screen') ||
                        obj.section_name.includes('Product Hierarchy')) {
                        filterdArr.push(obj.section_data)
                    }
                })
            }
            if (element?.position == 2) {
                element.data.forEach(obj => {
                    if (obj.section_name.includes('Initial Screen') ||
                        obj.section_name.includes('Sales')) {
                        filterdArr.push(obj.section_data)
                    }
                })
            }
            if (element?.position == 3) {
                element.data.forEach(obj => {
                    if (obj.section_name.includes('Initial Screen') ||
                        obj.section_name.includes('BIS Standard')) {
                        filterdArr.push(obj.section_data)
                    }
                })
            }

            if (element?.position == 4) {
                element.data.forEach(obj => {
                    filterdArr.push(obj.section_data);
                })
            }
            // });
            // if (tempData?.[0]?.position == 1) {
            // let filteredData = tempData?.[0]?.data?.filter((obj) => obj.section_name == 'Initial Screen' || obj.section_name == 'Product Hierarchy')
            // if (filteredData) {
            //     console.log('filter data---->', filteredData)
            // }
            // }
            // }
        }

        // console.log('filter arr?', filterdArr)
    }

    useEffect(() => {
        dispatch(getApprovalStart());
    }, []);

    const updateData = (id, moduleId, index) => {
        setTableIndex(index);
        setId(id);
        setModuleId(moduleId);
    }

    useEffect(() => {
        if (workflowList?.approvalStatus) {
            navigate(Utils.getNavigationUrl(`dashboard`))
        } else if (!workflowList?.approvalStatus) {
            console.log('error status')
        }
    }, [workflowList?.approvalStatus]);

    useEffect(() => {
        if (tableIndex != null) {
            // list?.approvalUser?.[0]?.primaryField
            let initialData = workflowList?.data?.[tableIndex]?.data[0];
            // debugger
            let primaryField = workflowList?.data?.[tableIndex]?.approvalUser?.[0]?.primaryField;
            primaryField = primaryField.replaceAll(' ', '_')
            // setContent(checkText(Object.keys(initialData)[0]) + ' ' + initialData[Object.keys(initialData)[0]])

            workflowList?.data?.[tableIndex]?.data.map(item => {
                //     if ([primaryField] in item.section_data) {

                // }
                // if ([primaryField] in item.section_data) {
                if (item?.section_data?.[primaryField]) {
                    setContent(primaryField + ' ' + item?.section_data?.[primaryField])
                }
            })
            // debugger

            // main
            // if (initialData?.section_data?.[primaryField])
            //     setContent(primaryField + ' ' + initialData?.section_data?.[primaryField])
            // else
            //     setContent(primaryField)
        }
    }, [tableIndex]);

    useEffect(() => {
        if (workflowList?.data?.length > 0) {

        }
    }, [workflowList])

    const displayListContent = (list) => {
        // debugger

        // { list?.approvalUser?.[0]?.primaryField }
        let primaryField = list?.approvalUser?.[0]?.primaryField;
        primaryField = primaryField.replaceAll(' ', '_')
        // setContent(checkText(Object.keys(initialData)[0]) + ' ' + initialData[Object.keys(initialData)[0]])

        let primaryValue;
        list?.data.map(item => {
            //     if ([primaryField] in item.section_data) {

            // }
            // if ([primaryField] in item.section_data) {
            if (item?.section_data?.[primaryField]) {
                // setContent(primaryField + ' ' + item?.section_data?.[primaryField])
                // return primaryField
                //  + ' ' + item?.section_data?.[primaryField]
                primaryValue = item?.section_data?.[primaryField];
            }
        })
        return primaryField + ': ' + primaryValue
    }

    const updateApprovalStatus = (status) => {
        let payload = {
            "moduleId": moduleId
        }
        if (id) {
            dispatch(updateApprovalStatusStart({
                id, payload
            }))
        }
    }

    const updateRejectStatus = () => {
        if (id) {
            let payload = {}
            dispatch(updateRejectStatusStart({
                id, payload
            }))
        }
    }

    const checkText = (text) => {
        // console.log('TEXT----->', text)
        if (text.includes('_')) {
            text = text.replaceAll('_', ' ')
        }
        // console.log('text---->', text)
        return text;
    }

    // console.log('data----->', workflowList?.data?.[0]?.data?.[0]?.section_data?.Material_Number)

    // workflowList?.data?.length && workflowList?.data?.map((list, index) => {
    //     list?.data?.map((data, i) => {
    //         console.log('dataaaaa (arra)----->', data?.section_data?.['Material_Number'])
    //     })
    // })

    // console.log('data with index------->', workflowList?.data?.[tableIndex])

    // console.log('data with index(data)------->', workflowList?.data?.[tableIndex]?.data)

    return (
        // <div className="main-panel">
        <div className="container-fluid">
            <div className="row">
                {/* <!-- Sidebar --> */}
                <div className="sidebar col-md-3 col-lg-3 p-0 bg-white h-100vh fixed-top">
                    <div className="offcanvas-md offcanvas-end" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                                aria-label="Close"></button>
                        </div>

                        <div className="offcanvas-body d-md-flex flex-column p-0 overflow-y-auto">
                            <div className="sticky-top">
                                <div className="sub-title text-center lh-lg">All Items <span>({workflowList?.data?.length})</span></div>
                                <div className="input-group input-group-sm mb-1">
                                    <input type="text" className="form-control rounded-0 border-end-0" placeholder="Search"
                                        aria-label="List Search" aria-describedby="listSearch" />
                                    <span className="input-group-text rounded-0 bg-transparent cursor-pointer" id="listSearch"><i
                                        className="fa-solid fa-magnifying-glass"></i></span>
                                </div>
                            </div>
                            <div className="row g-0 list-btns">
                                {workflowList?.data?.length && workflowList?.data?.map((list, index) => (
                                    <>
                                        {/* {list?.data?.map((data, i) =>
                                             (data?.section_data?.Material_Number && */}
                                        <div className="col-md-12">
                                            {/* className="border px-2 py-3 list-item" */}
                                            <div role="button" className={`border px-2 py-3 list-item ${tableIndex == index ? 'active' : ''}`} onClick={() => updateData(list._id, list.moduleId, index)}>
                                                <div className="px-1 text-muted">Reserved to You</div>
                                                {/* <div className="px-1 h5 my-2">Please release {checkText(Object.keys(data.section_data)[0])} {data[Object.keys(data)[0]]}</div> */}
                                                {/* <div className="px-1 h5 my-2">Please release {list?.approvalUser?.[0]?.primaryField} */}
                                                <div className="px-1 h6 my-2">Please release {displayListContent(list)}
                                                    {/* Material Number {data.section_data['Material_Number']} */}
                                                </div>
                                                {/* <div className="px-1 h5 my-2">Please release purchase order 4500009946</div> */}
                                                <div className="px-1 text-muted d-flex justify-content-between">
                                                    {/* <div>{list?.userId?.firstName + ' ' + list?.userId?.lastName}</div> */}
                                                    <div className="text-dark">{new Date(list?.updatedAt).toLocaleDateString()}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* )
                                        )} */}
                                    </>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- // Sidebar // --> */}

                {/* <!-- Main play ground --> */}
                <main className="col-md-9 col-lg-9 ms-sm-auto px-md-4 py-md-4 content-section">

                    {!id && <div className="text-center">Please select an item to Approve</div>}
                    {/* <!-- Card list box section --> */}
                    {id && <div className="card styled-card">
                        <h6 className="card-header py-3">Release of {content}</h6>
                        <div className="card-body">
                            {/* {workflowList?.data?.[tableIndex]?.data?.map((list) => ( */}
                            {filterdArr?.map((list) => (
                                <dl className="row justify-content-center list-detail">
                                    {/* {Object.keys(list.section_data)?.map((key) => ( */}
                                    {/* {filterdArr.map((key) => ( */}
                                    {list && Object.keys(list).map((key) => (
                                        <>
                                            <dd className="col-sm-9 col-lg-5">{checkText(key)}</dd>
                                            <dt className="col-sm-3 col-lg-5">{typeof list[key] === "string" ? list[key] : (Array.isArray(list[key]) ? list[key].map(x => x.name).join(", ") : '')}</dt>
                                        </>
                                    ))}
                                    {/* ))} */}
                                </dl>
                            ))}
                            {/* ))} */}
                        </div>
                        <div className="card-footer bg-transparent text-end">
                            <div className="btn-group">
                                <button type="button" className="btn btn-danger rounded-pill py-1 px-3 text-light"
                                    disabled={!id} onClick={() => updateRejectStatus()}>Reject</button>
                                <button type="button" className="btn btn-primary rounded-pill approval-btn"
                                    disabled={!id} onClick={() => updateApprovalStatus()}>Approve</button>
                            </div>
                        </div>
                    </div>
                    }
                    {/* <!-- // Card list box section // --> */}

                </main>
                {/* <!-- // Main play ground // --> */}

            </div>
        </div>

        // </div>
    )
}
export default DashboardDetails;