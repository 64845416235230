import { call, put } from "redux-saga/effects";
import { getDashboardFailure, getDashboardGraphFailed, getDashboardGraphSuccess, getDashboardSuccess } from "../../../Slices/dashboardSlice";
import { getAllGraphs, getDashboard } from "../../../../Services/apiService";

export function* getDashboardHandler(actions) {
    try {
        const response = yield call(getDashboard, actions?.payload);
        yield put(getDashboardSuccess(response?.data))
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getDashboardFailure(errResponse))
    }
}

export function* getDashboardGraphHandler(actions) {
    try {
        const response = yield call(getAllGraphs, actions?.payload);
        yield put(getDashboardGraphSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getDashboardGraphFailed(errResponse));
    }
} 