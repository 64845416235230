const isBoolean = (value) => typeof value === "boolean";

const isString = (value) => typeof value === "string";

const isNumber = (value) => typeof value === "number" && !isNaN(value);

const isArray = (value) => Array.isArray(value);

const isObject = (value) => value && value !== null && typeof value === "object" && !Array.isArray(value);

const isFunction = (value) => typeof value === "function";

const isUndefined = (value) => typeof value === "undefined";

const isNull = (value) => value === null;

const isDate = (value) => value instanceof Date && !isNaN(value);

const isPromise = (value) => 
  !!value && (typeof value === "object" || typeof value === "function") && typeof value.then === "function";

const isRegExp = (value) => value instanceof RegExp;

const isSymbol = (value) => typeof value === "symbol";

const hasLength = (value) => isArray(value) || (isString(value) && value.length !== undefined);

const getItemsOrDefault = (values) => {
    return isArray(values) ? values : [];
};

const arrayLength = (values) => {
  return getItemsOrDefault(values).length > 0;
}

const isNotEmptyObject = (obj) => isObject(obj) && Object.keys(obj).length > 0;

const getValidObjectOrEmpty = (obj) => {
  return isNotEmptyObject(obj) ? obj : {};
};

const replaceData = (str) => {
  if(str) {
    let str2 = str.replace(/[\s\(\)\[\]]/g, '_');
    return (str2.replace(/_+/g, "_"));
  }
  return "";
}

const getNumbersFromString = (str) => {
  if (str && typeof str === "string") {
    return str.match(/\d+/g)?.join('');
  }
  return "";
}

export const Helpers = {
  isBoolean,
  isString,
  isNumber,
  isArray,
  isObject,
  isFunction,
  isUndefined,
  isNull,
  isDate,
  isPromise,
  isRegExp,
  isSymbol,
  hasLength,
  getItemsOrDefault,
  arrayLength,
  isNotEmptyObject,
  getValidObjectOrEmpty,
  replaceData,
  getNumbersFromString
};
