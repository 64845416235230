import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBusinessHoursStart, getBusinessHoursStart, updateBusinessHoursStart } from "../../../Store/Slices/businessHoursSlice";
import Loader from "../../../Components/Loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EditShiftHours from "./editShiftHours";
import { getShiftHoursStart } from "../../../Store/Slices/shiftHoursSlice";
import { Utils } from "../../../Utils";

const BusinessHours = () => {
    const dispatch = useDispatch();
    let companyId = useSelector((state) => state?.org)?.companyData?.[0]?._id;
    let currentBusinessHour = useSelector((state) => state?.businesshours);
    let currentBHData = currentBusinessHour?.businessData?.[0];
    let shiftHoursData = useSelector((state) => state?.shifthours);
    const [showInitialUI, setShowInitialUI] = useState(true);
    const [showBusinessHours, setShowBusinessHours] = useState(true);
    const isEdit = currentBusinessHour?.businessData?.length ? true : false;
    const [validForm, setValidForm] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // pre-defined array's
    const orderedWeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const weekDays = orderedWeekDays;
    const weekDayTime = [
        { day: 'Monday', startTime: '', endTime: '' },
        { day: 'Tuesday', startTime: '', endTime: '' },
        { day: 'Wednesday', startTime: '', endTime: '' },
        { day: 'Thursday', startTime: '', endTime: '' },
        { day: 'Friday', startTime: '', endTime: '' },
        { day: 'Saturday', startTime: '', endTime: '' },
        { day: 'Sunday', startTime: '', endTime: '' }
    ];

    // states
    const [businessHoursData, setBusinessHoursData] = useState({
        businessHours: '24x7',
        weeksStartsOn: 'Monday',
        businessHoursManagementData: {
            businessHours5Days: {
                businessDays: []
            },
            businessHoursCustom: {
                businessTiming: 'diff',
                businessTimingSame: {
                    startTime: '9:00 AM',
                    endTime: '5:00 PM',
                    businessDays: []
                },
                businessTimingDifferent: {
                    businessDays: []
                }
            }
        },
    });

    // useeffects
    useEffect(() => {
        generateTimeOptions()
        dispatch(getBusinessHoursStart())
    }, []);

    useEffect(() => {
        if (currentBusinessHour?.reload) {
            dispatch(getBusinessHoursStart());
            setShowInitialUI(true)
        }
    }, [currentBusinessHour?.reload]);

    useEffect(() => {
        if (businessHoursData.businessHours == '24x5') {
            let arr = calculateNext5Days(businessHoursData.weeksStartsOn, 'default');
            setBusinessHoursData((prev) => ({
                ...prev,
                businessHoursManagementData: {
                    ...prev.businessHoursManagementData,
                    businessHours5Days: {
                        ...prev.businessHoursManagementData.businessHours5Days,
                        businessDays: arr
                    },
                }
            }));
        }
        if (businessHoursData.businessHours == 'custom') {
            if (businessHoursData.businessHoursManagementData?.businessHoursCustom?.businessTiming == 'same') {
                let arr = calculateNext5Days(businessHoursData.weeksStartsOn, 'same');
                setBusinessHoursData((prev) => ({
                    ...prev,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHoursCustom: {
                            ...prev.businessHoursManagementData.businessHoursCustom,
                            businessTiming: 'same',
                            businessTimingSame: {
                                ...prev.businessHoursManagementData.businessHoursCustom.businessTimingSame,
                                startTime: '9:00 AM',
                                endTime: '5:00 PM',
                                businessDays: arr
                            }
                        },
                    }
                }));
            }
            if (businessHoursData.businessHoursManagementData.businessHoursCustom?.businessTiming == 'diff') {
                let arr = calculateNext5Days(businessHoursData.weeksStartsOn, 'diff');
                setBusinessHoursData((prev) => ({
                    ...prev,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHoursCustom: {
                            ...prev.businessHoursManagementData.businessHoursCustom,
                            businessTiming: 'diff',
                            businessTimingDifferent: {
                                ...prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent,
                                businessDays: arr
                            }
                        },
                    }
                }));
            }
        }
    }, [
        businessHoursData.businessHours,
        businessHoursData.weeksStartsOn,
        businessHoursData.businessHoursManagementData?.businessHoursCustom?.businessTiming
    ]);

    useEffect(() => {
        let mgtData = businessHoursData.businessHoursManagementData;
        if (businessHoursData?.businessHours == '24x5')
            setValidForm(mgtData.businessHours5Days.businessDays.length == 5 &&
                mgtData.businessHours5Days?.businessDays?.includes(businessHoursData.weeksStartsOn) ? true : false);
        if (businessHoursData?.businessHours == 'custom') {
            if (mgtData.businessHoursCustom.businessTiming == 'same')
                setValidForm(mgtData.businessHoursCustom?.businessTimingSame?.businessDays?.length == 5 &&
                    mgtData.businessHoursCustom?.businessTimingSame?.businessDays?.includes(businessHoursData.weeksStartsOn) ? true : false);
            if (mgtData.businessHoursCustom.businessTiming == 'diff')
                setValidForm(mgtData.businessHoursCustom?.businessTimingDifferent?.businessDays?.length == 5 &&
                    mgtData.businessHoursCustom?.businessTimingDifferent?.businessDays?.some((selectedItem) => selectedItem.day.includes(businessHoursData.weeksStartsOn)) ? true : false);
        }
    },
        [businessHoursData.businessHoursManagementData.businessHours5Days?.businessDays,
        businessHoursData.businessHoursManagementData.businessHoursCustom?.businessTimingSame?.businessDays,
        businessHoursData.businessHoursManagementData.businessHoursCustom?.businessTimingDifferent?.businessDays
        ]
    );

    useEffect(() => {
        if (currentBusinessHour?.businessData?.length) {
            setShowInitialUI(true);
            setBusinessHoursData((prev) => ({
                ...prev,
                businessHours: currentBHData?.businessHours,
                weeksStartsOn: currentBHData?.weeksStartsOn
            }));
            if (currentBHData?.businessHours == '24x5') {
                setBusinessHoursData((prev) => ({
                    ...prev,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHours5Days: {
                            ...prev.businessHoursManagementData.businessHours5Days,
                            businessDays: currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays
                        }
                    }
                }));
            }
            if (currentBHData?.businessHours == 'custom') {
                setBusinessHoursData((prev) => ({
                    ...prev,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHoursCustom: {
                            ...prev.businessHoursManagementData.businessHoursCustom,
                            businessTiming: currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTiming,
                            businessTimingSame: {
                                ...prev.businessHoursManagementData.businessHoursCustom?.businessTimingSame,
                                startTime: currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.startTime,
                                endTime: currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.endTime,
                                businessDays: currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays
                            },
                            businessTimingDifferent: {
                                ...prev.businessHoursManagementData.businessHoursCustom?.businessTimingDifferent,
                                businessDays: currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays
                            }
                        }
                    }
                }));
            }
        }
    }, [currentBusinessHour])

    const hasRun = useRef(false);
    useEffect(() => {
        if (currentBusinessHour?.getLoading) {
            if (hasRun.current) return; // Skip if already executed
            hasRun.current = true;
            if (currentBusinessHour?.businessData?.length > 0) {
                dispatch(getShiftHoursStart(currentBusinessHour?.businessData?.[0]?._id));
            }
        }
    }, [currentBusinessHour.getLoading])

    // functions
    const handleShowModal = () => setShowConfirmModal(true);

    const handleCloseModal = () => setShowConfirmModal(false);

    const generateTimeOptions = () => {
        const times = [];
        const periods = ['AM', 'PM'];
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 30) {
                const hour = i === 0 || i === 12 ? 12 : i % 12;
                const minute = j === 0 ? '00' : '30';
                const period = i < 12 ? periods[0] : periods[1];
                const time = `${hour}:${minute} ${period}`;
                times.push(<option key={time} value={time}>{time}</option>);
            }
        }
        return times;
    };

    const isContinuousWeekdays = (days) => {
        const startIndex = orderedWeekDays.indexOf(days[0]);
        const endIndex = startIndex + days.length - 1;
        return orderedWeekDays.slice(startIndex, endIndex + 1).join(',') === days.join(',');
    };

    const calculateNext5Days = (day, type) => {
        const startIndex = orderedWeekDays.indexOf(day);
        const nextDaysArray = [];
        for (var i = 0; i <= 4; i++) {
            const nextDayIndex = (startIndex + i) % orderedWeekDays.length
            if (type == 'default' || type == 'same')
                nextDaysArray.push(orderedWeekDays[nextDayIndex]);
            if (type == 'diff')
                nextDaysArray.push({ day: orderedWeekDays[nextDayIndex], startTime: '9:00 AM', endTime: '5:00 PM' });
        }
        return nextDaysArray
    }

    const calculateNext7Days = (day) => {
        const startIndex = orderedWeekDays.indexOf(day);
        const nextDaysArray = [];
        for (var i = 0; i <= 6; i++) {
            const nextDayIndex = (startIndex + i) % orderedWeekDays.length
            nextDaysArray.push(orderedWeekDays[nextDayIndex]);
        }
        let res = `${nextDaysArray[0]} - ${nextDaysArray[6]}`
        return res
    }

    const showBHHandler = (value) => {
        debugger
        setShowBusinessHours(value);
    };

    // event handlers
    const formHandler = (e) => {
        try {
            if (e?.target?.name == 'businessHours' || e?.target?.name == 'weeksStartsOn') {
                setBusinessHoursData((prev) => ({
                    ...prev,
                    [e?.target?.name]: e?.target?.value
                }));
            }
            if (e?.target?.name == 'businessHours' && e?.target?.value == 'custom') {
                setBusinessHoursData((prev) => ({
                    ...prev,
                    [e?.target?.name]: e?.target?.value,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHoursCustom: {
                            ...prev.businessHoursManagementData.businessHoursCustom,
                            businessTiming: 'same',
                            businessTimingSame: {
                                // ...prev.businessHoursManagementData.businessHoursCustom.businessTimingSame,
                                startTime: '9:00 AM',
                                endTime: '5:00 PM',
                                businessDays: []
                            }
                        }
                    }
                }));
            }
            if (e?.target?.name == 'businessTiming') {
                setBusinessHoursData((prev) => ({
                    ...prev,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHoursCustom: {
                            ...prev.businessHoursManagementData.businessHoursCustom,
                            [e?.target?.name]: e?.target?.value
                        }
                    }
                }));
            }
            if (e?.target?.name == 'startTime' || e?.target?.name == 'endTime') {
                setBusinessHoursData((prev) => ({
                    ...prev,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHoursCustom: {
                            ...prev.businessHoursManagementData.businessHoursCustom,
                            businessTimingSame: {
                                ...prev.businessHoursManagementData.businessHoursCustom.businessTimingSame,
                                [e.target.name]: e.target.value
                            }
                        }
                    }
                }));
            }
            if (e?.target?.name == 'day' || e?.target?.name == 'fromTime' || e?.target?.name == 'toTime') {
                setBusinessHoursData((prev) => ({
                    ...prev,
                    businessHoursManagementData: {
                        ...prev.businessHoursManagementData,
                        businessHoursCustom: {
                            ...prev.businessHoursManagementData.businessHoursCustom,
                            businessTimingDifferent: {
                                ...prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent,
                                businessDays: {
                                    ...prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays,
                                    [e?.target?.name]: e?.target?.value
                                }
                            }
                        }
                    }
                }));
            }
        } catch (err) {
            throw err;
        }
    }

    const handleCheckboxChangeDefault = (value) => {
        setBusinessHoursData((prev) => ({
            ...prev,
            businessHoursManagementData: {
                ...prev.businessHoursManagementData,
                businessHours5Days: {
                    ...prev.businessHoursManagementData.businessHours5Days,
                    businessDays:
                        prev.businessHoursManagementData.businessHours5Days.businessDays.includes(value) ? prev.businessHoursManagementData.businessHours5Days.businessDays.filter((itemId) => itemId !== value)
                            : [...prev.businessHoursManagementData.businessHours5Days.businessDays, value]
                }
            }
        }))
    }

    const handleCheckboxChange = (value) => {
        setBusinessHoursData((prev) => ({
            ...prev,
            businessHoursManagementData: {
                ...prev.businessHoursManagementData,
                businessHoursCustom: {
                    ...prev.businessHoursManagementData.businessHoursCustom,
                    businessTimingSame: {
                        ...prev.businessHoursManagementData.businessHoursCustom.businessTimingSame,
                        businessDays:
                            prev.businessHoursManagementData.businessHoursCustom.businessTimingSame.businessDays.includes(value) ? prev.businessHoursManagementData.businessHoursCustom.businessTimingSame.businessDays.filter((itemId) => itemId !== value)
                                : [...prev.businessHoursManagementData.businessHoursCustom.businessTimingSame.businessDays, value]
                    }
                }
            }
        }))
    }

    const handleCheckboxChange1 = (item) => {
        setBusinessHoursData((prev) => ({
            ...prev,
            businessHoursManagementData: {
                ...prev.businessHoursManagementData,
                businessHoursCustom: {
                    ...prev.businessHoursManagementData.businessHoursCustom,
                    businessTimingDifferent: {
                        ...prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent,
                        businessDays:
                            prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays.some((selectedItem) => selectedItem.day.includes(item.day)) ? prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays.filter((itemId) => itemId.day !== item.day)
                                : [...prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays, { day: item.day, startTime: '9:00 AM', endTime: '5:00 PM' }]
                    }
                }
            }
        }))
    };

    const handleInputChange1 = (day, e) => {
        setBusinessHoursData((prev) => ({
            ...prev,
            businessHoursManagementData: {
                ...prev.businessHoursManagementData,
                businessHoursCustom: {
                    ...prev.businessHoursManagementData.businessHoursCustom,
                    businessTimingDifferent: {
                        ...prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent,
                        businessDays: prev.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays.map((dta, i) =>
                            dta.day == day ? {
                                ...dta,
                                [e.target.name]: e.target.value
                            } : dta
                        )
                    }
                }
            }
        }))
    };

    const handlesubmit = () => {
        let payload = {};
        if (businessHoursData.businessHours == '24x7') {
            const { businessHoursManagementData, ...rest } = businessHoursData;
            payload = rest;
        }
        if (businessHoursData.businessHours == '24x5') {
            const sortedWeekdays = [...businessHoursData.businessHoursManagementData.businessHours5Days.businessDays].sort(
                (a, b) => orderedWeekDays.indexOf(a) - orderedWeekDays.indexOf(b)
            );
            businessHoursData.businessHoursManagementData.businessHours5Days.businessDays = sortedWeekdays
            const updatedObj = businessHoursData.businessHoursManagementData = Object.fromEntries(
                Object.entries(businessHoursData.businessHoursManagementData).filter(([key]) => key !== 'businessHoursCustom')
            );
            payload = businessHoursData;
        }
        if (businessHoursData.businessHours == 'custom') {
            if (businessHoursData.businessHoursManagementData.businessHoursCustom.businessTiming == 'same') {
                const sortedWeekdays = [...businessHoursData.businessHoursManagementData.businessHoursCustom.businessTimingSame.businessDays].sort(
                    (a, b) => orderedWeekDays.indexOf(a) - orderedWeekDays.indexOf(b)
                );
                businessHoursData.businessHoursManagementData.businessHoursCustom.businessTimingSame.businessDays = sortedWeekdays
                const updatedObj = businessHoursData.businessHoursManagementData = Object.fromEntries(
                    Object.entries(businessHoursData.businessHoursManagementData).filter(([key]) => key !== 'businessHours5Days')
                );
                const updatedObj1 = businessHoursData.businessHoursManagementData.businessHoursCustom = Object.fromEntries(
                    Object.entries(businessHoursData.businessHoursManagementData.businessHoursCustom).filter(([key]) => key !== 'businessTimingDifferent')
                );
            }
            if (businessHoursData.businessHoursManagementData.businessHoursCustom.businessTiming == 'diff') {
                const sortedWeekdays = [...businessHoursData.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays].sort(
                    (a, b) => orderedWeekDays.indexOf(a.day) - orderedWeekDays.indexOf(b.day)
                );
                businessHoursData.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays = sortedWeekdays
                const updatedObj = businessHoursData.businessHoursManagementData = Object.fromEntries(
                    Object.entries(businessHoursData.businessHoursManagementData).filter(([key]) => key !== 'businessHours5Days')
                );
                const updatedObj1 = businessHoursData.businessHoursManagementData.businessHoursCustom = Object.fromEntries(
                    Object.entries(businessHoursData.businessHoursManagementData.businessHoursCustom).filter(([key]) => key !== 'businessTimingSame')
                );
            }
            payload = businessHoursData;
        }
        payload['organization'] = companyId
        let auditLog; 
        if (isEdit) {
            if (shiftHoursData?.shiftData?.length > 0 && currentBHData?.businessHours !== businessHoursData.businessHours) {
                handleShowModal();
            } else {
                const auditLog = Utils.logUpdatedFields(currentBHData, payload, 'businessHour');
                payload.audit_log = auditLog;
                dispatch(updateBusinessHoursStart({
                    id: currentBHData?._id, payload: payload
                }))
            }
        } else {
            payload.audit_log = auditLog;
            dispatch(createBusinessHoursStart(payload))
        }
    }

    // ui
    return (
        <React.Fragment>
            <Loader isVisible={currentBusinessHour?.isLoading} />
            {showInitialUI &&
                <div className='busi-hour mt-4'>
                    {showBusinessHours &&
                        <>
                            <h5 className="fw-semibold">
                                Business Hours
                                {isEdit ?
                                    <i className="fa-solid fa-pencil small ms-2 cursor-pointer"
                                        data-bs-toggle="modal" data-bs-target="#editBusiHoursForm"></i>
                                    :
                                    <button type="button" className="d-inline btn btn-primary btn-digy-dark rounded-pill py-1 px-3 ms-3"
                                        data-bs-toggle="modal" data-bs-target="#editBusiHoursForm">
                                        + New Business Hours
                                    </button>
                                }
                            </h5>
                            <p className='w-75'>
                                Business hours define the operational hours of your organization. Set business hours
                                to help your employees ensure that the activities are carried out at the operational
                                hours of your organization.
                            </p>
                        </>
                    }
                    {isEdit &&
                        <>
                            {showBusinessHours &&
                                <dl className="row justify-content-start list-detail">
                                    <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">
                                        Week starts on
                                        {['bottom'].map((placement) => (
                                            <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                    <Tooltip id={`weekStartTooltip`}>
                                                        The data for next week, current week and previous week in reports, smart filters and
                                                        critiria will be based on the day that you choose here.
                                                    </Tooltip>
                                                }
                                            >
                                                <i className='fa-solid fa-circle-info ms-2'></i>
                                            </OverlayTrigger>
                                        ))}
                                    </dd>
                                    <dt className="col-sm-8 col-lg-9 fw-normal">{currentBHData?.weeksStartsOn}</dt>

                                    {currentBHData?.businessHours == '24x7' &&
                                        <>
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Business days</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">{calculateNext7Days(currentBHData?.weeksStartsOn)}</dt>
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Business hours</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">24 Hours</dt>
                                        </>
                                    }
                                    {currentBHData?.businessHours == '24x5' &&
                                        <>
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Business days</dd>
                                            {
                                                currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays.length === 5 && isContinuousWeekdays(currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays) ?
                                                    <dt className="col-sm-8 col-lg-9 fw-normal">{`${currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays[0]} - ${currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays[currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays.length - 1]}`}</dt>
                                                    :
                                                    <dt className="col-sm-8 col-lg-9 fw-normal">{currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays.join(', ')}</dt>

                                            }
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Business hours</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">24 Hours</dt>
                                        </>
                                    }
                                    {(currentBHData?.businessHours == 'custom' && currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == 'same') &&
                                        <>
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Business days</dd>
                                            {
                                                currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays.length === 5 && isContinuousWeekdays(currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays) ?
                                                    <dt className="col-sm-8 col-lg-9 fw-normal">{`${currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays[0]} - ${currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays[currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays.length - 1]}`}</dt>
                                                    :
                                                    <dt className="col-sm-8 col-lg-9 fw-normal">{currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays.join(', ')}</dt>
                                            }
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Business hours</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">{currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.startTime}-{currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.endTime}</dt>
                                        </>
                                    }
                                    {currentBHData?.businessHours == '24x5' &&
                                        <>
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Closed</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">{orderedWeekDays.filter(value => !currentBHData?.businessHoursManagementData?.businessHours5Days?.businessDays?.includes(value)).join(', ')}</dt>
                                        </>
                                    }
                                    {(currentBHData?.businessHours == 'custom' && currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == 'same') &&
                                        <>
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Closed</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">{orderedWeekDays.filter(value => !currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays?.includes(value)).join(', ')}</dt>
                                        </>
                                    }
                                    {(currentBHData?.businessHours == 'custom' && currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == 'diff') &&
                                        <>
                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Time Zone</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">India Standard Time</dt>

                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Business days</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">
                                                <table>
                                                    {
                                                        currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays.length > 0 &&
                                                        currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays.map((data) =>
                                                            <tr>
                                                                <td>{data.day} </td>
                                                                <td><b> {data.startTime} </b></td>
                                                                <td><b> {data.endTime} </b></td>
                                                            </tr>
                                                        )}

                                                </table>
                                            </dt>

                                            <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Closed</dd>
                                            <dt className="col-sm-8 col-lg-9 fw-normal">{orderedWeekDays.filter(value => !currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays?.some((item) => item.day.includes(value))).join(', ')}</dt>

                                            {/* {
                                            currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays.length > 0 &&
                                            currentBHData?.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays.map((data) =>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">{data.day} - {data.startTime} - {data.endTime}</dt>
                                            )} */}
                                        </>
                                    }
                                </dl>
                            }

                            <EditShiftHours showBHFn={showBHHandler} />
                        </>
                    }
                </div>
                // :
                // <CreateShiftHours />
            }
            <div className="modal fade" id="editBusiHoursForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="editBusiHoursLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable edit-busi-hours-form">
                    <div className="modal-content">
                        <div className='modal-header'>
                            <h5 className="modal-title fs-5" id="editBusiHoursLabel">{isEdit ? 'Edit' : 'New'} Business hours</h5>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row mb-3">
                                <legend className="col-auto col-md-3 col-form-label pt-0">Business hours</legend>
                                <div className="col-auto">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="businessHours" id="busiHourRadio1" value="24x7" onChange={formHandler} checked={businessHoursData?.businessHours == "24x7"} />
                                        <label className="form-check-label cursor-pointer" htmlFor="busiHourRadio1">
                                            24 Hours X 7 days
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="businessHours" id="busiHourRadio2" value="24x5" onChange={formHandler} checked={businessHoursData?.businessHours == "24x5"} />
                                        <label className="form-check-label cursor-pointer" htmlFor="busiHourRadio2">
                                            24 Hours X 5 days
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="businessHours" id="busiHourRadio3" value="custom" onChange={formHandler} checked={businessHoursData?.businessHours == "custom"} />
                                        <label className="form-check-label cursor-pointer" htmlFor="busiHourRadio3">
                                            Custom hours
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="weekStarts" className="col-auto col-md-3 col-form-label pt-0">
                                    Week starts on
                                    {['bottom'].map((placement) => (
                                        <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Tooltip className='custom-tooltip-b' id={`customTooltip`}>
                                                    The data for next week, current week and previous week in reports, smart filters and
                                                    critiria will be based on the day that you choose here.
                                                </Tooltip>
                                            }
                                        >
                                            <i className='fa-solid fa-circle-info ms-2'></i>
                                        </OverlayTrigger>
                                    ))}
                                </label>
                                <div className="col-auto input-control">
                                    <select className="form-select" id="weekStarts" name="weeksStartsOn" onChange={formHandler}
                                        value={businessHoursData?.weeksStartsOn}>
                                        {
                                            weekDays.map((day) => (
                                                <option value={day}>{day}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {businessHoursData?.businessHours === "custom" &&
                                <div className="row mb-3">
                                    <legend className="col-auto col-md-3 col-form-label">Business timing</legend>
                                    <div className="col-12">
                                        <div className="form-check d-flex align-items-center">
                                            <input className="form-check-input mt-0" type="radio" name="businessTiming" id="busiTimingRadio1"
                                                value="same" onChange={formHandler} checked={businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == "same"} />
                                            <label className="form-check-label ms-2 me-3 cursor-pointer" htmlFor="busiTimingRadio1">
                                                Same hours every day
                                            </label>
                                            {businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == "same" &&
                                                <>
                                                    <select className="form-select form-select-sm w-auto"
                                                        onChange={formHandler}
                                                        name="startTime"
                                                        value={businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.startTime}
                                                    >
                                                        {generateTimeOptions()}
                                                    </select>
                                                    <span className='form-check-label mx-3'>to</span>
                                                    <select className="form-select form-select-sm w-auto" onChange={formHandler}
                                                        name="endTime"
                                                        value={businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.endTime}
                                                    >
                                                        {generateTimeOptions()}
                                                    </select>
                                                    <span className='ms-3'>India Standard Time</span>
                                                </>
                                            }
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="businessTiming" id="busiTimingRadio2"
                                                value="diff" onChange={formHandler} checked={businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == "diff"} />
                                            <label className="form-check-label cursor-pointer" htmlFor="busiTimingRadio2">
                                                Different hours every day
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {businessHoursData?.businessHours === "24x5" &&
                                <div className="row mb-3">
                                    <legend className="col-auto col-md-3 col-form-label pt-1">Business days</legend>
                                    <div className="col-auto">
                                        {weekDays?.map((day) => (
                                            <div className="form-check d-flex align-items-center mb-3" key={day}>
                                                <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${day}`} name="businessDays"
                                                    checked={businessHoursData?.businessHoursManagementData?.businessHours5Days?.businessDays.includes(day)} onChange={() => handleCheckboxChangeDefault(day)} />

                                                <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="busiDaysCheckbox1">
                                                    {day}
                                                </label>
                                            </div>
                                        ))}

                                        <div className='help-block small w-100 text-muted'>
                                            Note: All times are in India Standard Time
                                        </div>
                                    </div>
                                </div>
                            }
                            {businessHoursData?.businessHours === "custom" &&
                                <div className="row mb-3">
                                    <legend className="col-auto col-md-3 col-form-label pt-1">Business days</legend>
                                    <div className="col-auto">
                                        {businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == "same" &&
                                            <>
                                                {weekDays?.map((day) => (
                                                    <div className="form-check d-flex align-items-center mb-3" key={day}>
                                                        <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${day}`} name="businessDays"
                                                            checked={businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTimingSame?.businessDays?.includes(day)}
                                                            onChange={() => handleCheckboxChange(day)} />

                                                        <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="busiDaysCheckbox1">
                                                            {day}
                                                        </label>
                                                    </div>
                                                ))}
                                                {/* {weekDays?.map((day) => (
                                                        <div className="form-check d-flex align-items-center mb-3" key={day}>
                                                            <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${day}`} name="businessDays"
                                                                checked={businessHoursData?.businessHoursManagementData?.businessHours5Days?.businessDays.includes(day)} onChange={() => handleCheckboxChange(day)} />

                                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="busiDaysCheckbox1">
                                                                {day}
                                                            </label>
                                                        </div>
                                                    ))} */}
                                            </>
                                        }
                                        {businessHoursData?.businessHoursManagementData?.businessHoursCustom?.businessTiming == "diff" &&
                                            <>
                                                {weekDayTime?.map((value, index) => (
                                                    <>
                                                        <div className="form-check d-flex align-items-center mb-3" key={value.day}>
                                                            <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${value.day}`} name="day"
                                                                value={value?.day}
                                                                onChange={() => handleCheckboxChange1(value)}
                                                                checked={businessHoursData.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays?.some((selectedItem) => selectedItem.day === value.day)}
                                                            />
                                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="busiDaysCheckbox1">
                                                                {value.day}
                                                            </label>

                                                            <select className="form-select form-select-sm w-auto"
                                                                name="startTime"
                                                                defaultValue="9:00 AM"
                                                                value={businessHoursData.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.startTime}
                                                                onChange={(e) => handleInputChange1(value.day, e)}
                                                            >
                                                                {generateTimeOptions()}
                                                            </select>

                                                            <span className='form-check-label mx-3'>to</span>

                                                            <select className="form-select form-select-sm w-auto"
                                                                name="endTime"
                                                                defaultValue="5:00 PM"
                                                                value={businessHoursData.businessHoursManagementData?.businessHoursCustom?.businessTimingDifferent?.businessDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.endTime}
                                                                onChange={(e) => handleInputChange1(value.day, e)}
                                                            >
                                                                {generateTimeOptions()}
                                                            </select>
                                                        </div>
                                                    </>
                                                ))}
                                                <div className='help-block small w-100 text-muted'>
                                                    Note: All times are in India Standard Time
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer shadow-top">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                            {shiftHoursData?.shiftData?.length > 0 && currentBHData?.businessHours !== businessHoursData.businessHours ?
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={handlesubmit} disabled={!validForm}>Save</button>
                                :
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={handlesubmit} data-bs-dismiss="modal" disabled={!validForm}>Save</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation Alert Modal */}
            {showConfirmModal && (
                <div className="modal fade show d-block" id="warningModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="warningModalLabel" aria-hidden="true">
                    <div className="modal-dialog alert-modal">
                        <div className="modal-content">
                            <div className="modal-body">
                                <p className='fs-6'>
                                    The timing of the following shift hours fall outside business hours.
                                </p>
                                <p className='fs-6'>
                                    Change the shift timings accordingly before altering Business hours
                                </p>
                                <p className='fs-6'>
                                    <span className='fs-6 fw-semibold'>Node:</span> The changes in the Business hours will not be reflected for the records that have actions scheduled through workflow rules.
                                </p>
                            </div>
                            <div className="modal-footer border-top-0">
                                <button type="button" className="btn btn-danger btn-outline-danger rounded-pill text-light fs-6 fw-semibold" data-bs-dismiss="modal"
                                    onClick={handleCloseModal}>OK, I Understand</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* // Confirmation Alert Modal // */}
        </React.Fragment >
    )
}
export default BusinessHours;