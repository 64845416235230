import React, { useEffect, useState } from "react";
import digyLogo from '../../../Assets/images/digylax-logo.png';
import { getItem, setItem } from "../../../Services/localService";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import Toast from "../../../Components/Toast";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { forgotPasswordStart, resetState } from "../../../Store/Slices/authSlice";
import { Utils } from "../../../Utils";

const ConfirmAccount = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading, message, passwordUpdateSuccess } = useSelector((state) => state.auth);
    let token;

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [showCreatePassword, setShowCreatePasword] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const _token = queryParams.get('token');
        setItem('token', _token)
        // setItem('organization', "67627340b53f54575cf41de6");
        // setItem('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzMzMzhjZWUzMzRhMjZjNzljYWY4YWEiLCJmaXJzdE5hbWUiOiJLYWxlZXMiLCJsYXN0TmFtZSI6Ik0iLCJlbWFpbCI6ImtpbWFtaTMxMDhAZWRlY3R1cy5jb20iLCJwcm9maWxlUGljdHVyZSI6Imh0dHBzOi8vY2FtcHVzc2FmZXR5Y29uZmVyZW5jZS5jb20vd3AtY29udGVudC91cGxvYWRzLzIwMjAvMDgvaVN0b2NrLTQ3NjA4NTE5OC5qcGciLCJyb2xlIjoiVXNlciIsImlzR29vZ2xlVXNlciI6ZmFsc2UsImlzX2VtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc0NyZWF0ZWRVc2VyIjpmYWxzZSwiaXNWZXJpZmllZCI6ZmFsc2UsImlzQWN0aXZlIjp0cnVlLCJ2ZXJpZmljYXRpb25fY29kZSI6bnVsbCwiY3JlYXRlZEF0IjoiMjAyNC0xMS0xMlQxMToxNToyNi4yMDBaIiwidXBkYXRlZEF0IjoiMjAyNC0xMS0xMlQxMToxNTo1OC43NDZaIiwiX192IjowLCJpYXQiOjE3MzE0MTAxNTgsImV4cCI6MTczMTQ1MzM1OH0.3lJ5w2F6YR_h2N6AbQzkjutdJGGjc_k6B7fJhgJHXMU")
        if (_token) {
            token = getItem('token');
            let decode = jwtDecode(token);
            if (decode?.organization) {
                setItem('organization', decode?.organization?._id);
            }
            if (decode.is_password == true) {
                setItem('user', decode);
                setItem('modulePermission', decode?.Profile?.permission?.modulePermission);
                toast.success('Account Confirmed', {
                    autoClose: 1000,
                    onClose: () => {
                        navigate(`/${decode?.organization?.access_url}/dashboard`)
                        window.location.reload();
                    }
                })
            } else {
                setItem('user', decode);
                setShowCreatePasword(true)
            }
        }
    }, []);

    useEffect(() => {
        if (passwordUpdateSuccess) {
            toast.success(message, {
                autoClose: 2000,
                onClose: () => {
                    dispatch(resetState());
                    navigate(Utils.getNavigationUrl(`dashboard`))
                }
            })
        }
    }, [passwordUpdateSuccess])

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        if (e.target.name == 'newPassword') {
            setNewPassword(value)
            if (confirmPassword && value !== confirmPassword) {
                setPasswordMatchError("Passwords do not match");
            } else {
                setPasswordMatchError("");
            }
        }
        if (e.target.name == 'confirmPassword') {
            setConfirmPassword(value);
            // Validate if new password and confirm password match
            if (newPassword && value !== newPassword) {
                setPasswordMatchError("Passwords do not match");
            } else {
                setPasswordMatchError("");
            }
        }
    };

    const handlePasswordReset = () => {
        const queryParams = new URLSearchParams(location.search);
        const _token = queryParams.get('token');
        if (_token) {
            // Prepare payload for password reset and dispatch action
            const payload = {
                password: newPassword,
                confirmPassword: confirmPassword,
                email: jwtDecode(_token).email,
            };
            dispatch(forgotPasswordStart(payload));
        }
    };

    return (
        <React.Fragment>
            <Loader isVisible={isLoading} />
            {token != '' && <Toast />}
            <div className={`d-flex flex-column flex-root h-100vh
                ${showCreatePassword ? 'd-block' : 'd-none'}`}>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid auth-page auth-v1 forgot-password">
                    <div className="d-flex flex-column flex-lg-row-fluid order-2 order-lg-1 auth-l-box">
                        <div className="d-flex flex-column flex-lg-row-fluid"></div>
                        <div className="w-lg-100">
                            {/* ${showCreatePassword ? 'd-block' : 'd-none'} */}
                            {/* ${resetPasswordSection ? 'd-block' : 'd-none'} */}

                            <div className="mb-4">
                                <a className="brand-logo d-inline-block">
                                    <img src={digyLogo} alt="digylax-logo" width="174" height="32" />
                                </a>
                            </div>

                            <div className="mb-4">
                                <h2 className="h4 text-dark fw-bold mb-2">
                                    Create New Password
                                </h2>
                                <div className="text-body-tertiary fs-6">
                                    <p>
                                        Enter a Unique and strong password that is easy to remember
                                        so that you won’t forget it the next time.
                                    </p>
                                </div>
                            </div>

                            <div className="mb-3 icon-input">
                                <div className="input-group password">
                                    <span className="input-group-text bg-transparent border-end-0 first-child" id="newPassword">
                                        <i className="fa-solid fa-lock"></i>
                                    </span>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control border-start-0 border-end-0" placeholder="New Password"
                                        aria-describedby="newPassword" required
                                        name={'newPassword'}
                                        onChange={handlePasswordChange}
                                    // (e) => setNewPassword(e.target.value) // Update newPassword state

                                    />
                                    <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                        onClick={() => { newPassword && setShowPassword(!showPassword) }}>
                                        <i className={`fa-regular ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                    </span>
                                </div>
                            </div>



                            <div className="mb-3 icon-input">
                                <div className="input-group password">
                                    <span className="input-group-text bg-transparent border-end-0 first-child" id="confirmPassword">
                                        <i className="fa-solid fa-lock"></i>
                                    </span>
                                    <input
                                        type={showCPassword ? 'text' : 'password'}
                                        className="form-control border-start-0 border-end-0"
                                        placeholder="Confirm Password"
                                        aria-describedby="confirmPassword"
                                        required
                                        name={'confirmPassword'}
                                        onChange={handlePasswordChange}
                                    />
                                    <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                        onClick={() => { confirmPassword && setShowCPassword(!showCPassword) }}>
                                        <i className={`fa-regular ${showCPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                    </span>
                                </div>
                                {passwordMatchError && (
                                    <p className="text-danger mt-1">{passwordMatchError}</p>
                                )}
                            </div>

                            <div className="d-grid">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={() => handlePasswordReset()}
                                    disabled={(newPassword !== confirmPassword) || newPassword == '' && confirmPassword == ''}  // Disable if either field is empty
                                >
                                    <span className="indicator-label">Set Password</span>
                                </button>
                            </div>

                            {/* <div className="text-gray-500 text-center mt-5">
                                    <span role="button" className="link-primary text-decoration-none"> Contact Support </span>
                                </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ConfirmAccount;