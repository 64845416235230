import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    user: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    permissions: {
        isLoading: false,
        data: null,
        message: null,
        error: null,
        totalCount: null
    }
}

const fiscalYearSlice = createSlice({
    name: 'org',
    initialState,
    reducers: {
        // fiscal
        getFiscalYearStart: (state) => {
            state.isLoading = true;
        },
        getFiscalYearSuccess: (state, action) => {
            state.data = action?.payload?.fiscalYear;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getFiscalYearFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        createFiscalYearStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
        },
        createFiscalYearSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        createFiscalYearFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        updateFiscalYearStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
        },
        updateFiscalYearSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        updateFiscalYearFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        }
    }
});
export const {
    getFiscalYearStart,
    getFiscalYearSuccess,
    getFiscalYearFailure,
    createFiscalYearStart,
    createFiscalYearSuccess,
    createFiscalYearFailure,
    updateFiscalYearStart,
    updateFiscalYearSuccess,
    updateFiscalYearFailure
} = fiscalYearSlice?.actions;

export default fiscalYearSlice?.reducer;