import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { Utils } from "../../../Utils";
import { Helpers } from "../../../Helpers";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

let ReminderModal = ({ show, handleClose, isData = [] }) => {
    let navigate = useNavigate();
    useEffect(() => {
        if (show) {
            document.body.classList.add('reminder-modal-open');
        } else {
            document.body.classList.remove('reminder-modal-open');
        }
        return () => {
            document.body.classList.remove('reminder-modal-open');
        };
    }, [show]);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                centered={false}
                backdrop={false}
                className="reminder-modal"
                dialogClassName="reminder-container"
            >
                <Modal.Header closeButton className="reminder-header">
                    <Modal.Title>
                        Reminders <span class="badge bg-light text-danger rounded-circle">{isData?.length || 0}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="reminder-content">
                        {isData?.length > 0 ? (
                            isData?.map((x, i) => (
                                <div key={i}>
                                    <div className="reminder-item mb-2" onClick={() => {
                                        navigate(Utils.getNavigationUrl(`form-module/sub-form/update/${x?.moduleId}/${x?._id}`));
                                        handleClose();
                                    }}>
                                        <div className="reminder-title">{Utils.getSubFormFieldToData("Subject", x?.data) || ""}</div>
                                        <div className="reminder-date"> Due Date - {dayjs(Utils.getSubFormFieldToData("Due_Date", x?.data) || "").format("MM/DD/YYYY")}</div>
                                        <div className="related-lead">Related Lead - {Utils.getSubFormFieldToData("Lead_Name", x?.data) || ""}</div>
                                    </div>
                                    { isData?.length - 1 !== i && (<hr className="my-2 text-secondary" />)}
                                </div>
                            ))
                        ) : <div className='h-100 fw-bold align-self-center align-center py-3'>No Data Found</div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReminderModal;
