import React from "react";
import { useNavigate } from "react-router-dom";
import { Utils } from "../../Utils";

const Settings = () => {
    let navigate = useNavigate();
    return (
        <>
            {/* <div className="main-panel"> */}
            <main className="py-md-4 content-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 setup-actions d-flex">
                            <div className="left d-flex align-items-center">
                                <div className="sec-title h5 mb-0 pe-4">Setup</div>
                                <div className="search-box border rounded-pill">
                                    <label htmlFor="moduleNameSearch" className="searchbutton bg-white"><i className="fa-solid fa-magnifying-glass"></i></label>
                                    <input type="search" placeholder="Search" id="moduleNameSearch" className="searchfield bg-white" />
                                </div>
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn btn-light btn-sm flat-btn">Customize Setup</button>
                            </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="row setup-card d-flex flex-lg-row">
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">General</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/personal-settings`))}>Personal Settings</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/users`))}>Users</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/company-settings`))}>Company Settings</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">Security Control</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/profiles`))}>Profiles</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/roles-and-sharing`))}>Roles and Sharing</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/login-history`))}>Login History</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/audit-log`))}>Audit Log</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">Channels</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/email`))}>Email</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/business-messaging`))}>Business Messaging</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/notification-sms`))}>Notification SMS</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">Customization</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`form-module`))}>Modules and Fields</li>
                                            <li className="list-group-item cursor-pointer">Canvas ✨</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`home-customization`))}>Customize Home Page</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`analytics`))}>Analytics</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`print-template/quote`))}>Print Template</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">Automation</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`workflow-rules`))}>Workflow Rules</li>
                                            <li className="list-group-item cursor-pointer">Actions</li>
                                            <li className="list-group-item cursor-pointer">Schedules</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">Data Administration</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer">Import</li>
                                            <li className="list-group-item cursor-pointer">Export</li>
                                            <li className="list-group-item cursor-pointer">Data Backup</li>
                                            <li className="list-group-item cursor-pointer">Remove sample data</li>
                                            <li className="list-group-item cursor-pointer">Storage</li>
                                            <li className="list-group-item cursor-pointer">Recycle Bin</li>
                                            <li className="list-group-item cursor-pointer">Sandbox</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">Developer Hub</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer">APIs and SDKs</li>
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`integration-api`))}>Connections</li>
                                            <li className="list-group-item cursor-pointer">Variables</li>
                                            <li className="list-group-item cursor-pointer">Functions</li>
                                            <li className="list-group-item cursor-pointer">Widgets</li>
                                            <li className="list-group-item cursor-pointer">Notifications</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 custom-col-lg pt-4 d-flex flex-column px-1">
                                <div className="card flex-grow-1 rounded-3 border-light-subtle">
                                    <div className="card-header bg-transparent border-0 pb-2 fw-bold">CPQ ✨</div>
                                    <div className="card-body pt-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`variant-configuration`))}>Product configurator</li>
                                            <li className="list-group-item cursor-pointer">Price rules</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* </div> */}
        </>
    )
}

export default Settings;