import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    message: null,
    // error: null,
    successStatus: null,
    registerStatus: null,
    savedUser: null,
    emailError: null,
    phoneError: null,
    existError: null
}

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        registerStart: (state) => {
            debugger
            state.isLoading = true;
            state.registerStatus = null;
            state.savedUser = null;
            state.message = null;
            state.successStatus = null;
        },
        registerSuccess: (state, action) => {
            debugger
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.registerStatus = action?.payload?.success;
            state.savedUser = action?.payload?.savedUser;
        },
        registerFailure: (state, action) => {
            debugger
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.registerStatus = action?.payload?.success;
            state.savedUser = null;
        },
        resetState: (state) => {
            state.isLoading = false;
            state.data = null;
            state.message = null;
            // error: null,
            state.successStatus = null;
            state.registerStatus = null;
        },
        userExistStart: (state) => {
            state.emailError = null;
            state.phoneError = null;
            state.existError = null;
        },
        userExistSuccess: (state, action) => {
            state.emailError = action.payload.message;
            state.phoneError = action.payload.message;
            state.existError = action.payload.message || action.payload.data.message;
        },
        userExistFailure: (state, action) => {
            state.emailError = action.payload.message;
            state.phoneError = action.payload.message;
            state.existError = action.payload.message;
        },
        userRegisterStart: (state) => {
            debugger
            state.isLoading = true;
            state.registerStatus = null;
            state.savedUser = null;
            state.message = null;
            state.successStatus = null;
        },
        userRegisterSuccess: (state, action) => {
            debugger
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.registerStatus = action?.payload?.success;
            state.savedUser = action?.payload?.savedUser;
        },
        userRegisterFailure: (state, action) => {
            debugger
            // state.error = action?.payload;
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.registerStatus = action?.payload?.success;
            state.savedUser = null;
        },
    }
})


export const {
    registerStart,
    registerSuccess,
    registerFailure,
    resetState,
    userExistStart,
    userExistSuccess,
    userExistFailure,
    userRegisterStart,
    userRegisterSuccess,
    userRegisterFailure
} = registerSlice?.actions

export default registerSlice?.reducer;