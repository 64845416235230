import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
import Toast from "../../../Components/Toast";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerStart, userExistStart } from "../../../Store/Slices/registerSlice";
// import digyLogo from '../../../Assets/images/digylax-logo.png';
import digyLogo from '../../../Assets/images/crm-logo-final.svg';
import googleLogo from '../../../Assets/images/auth/google-icon-logo.svg';
import microsoftLogo from '../../../Assets/images/auth/microsoft_logo.svg';
import authImage from '../../../Assets/images/auth/auth-main.png';
import { createCompanyStart } from "../../../Store/Slices/organizationSlice";
import { setItem } from "../../../Services/localService";
import { Form, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { Utils } from "../../../../src/Utils";

const ACCESS_URL = process.env.REACT_APP_ACCESS_URL;

const Signup = () => {
    const dispatch = useDispatch();
    const register = useSelector((state) => state?.register);
    let currentOrg = useSelector((state) => state?.org);
    const navigate = useNavigate();

    const regex = /^[a-zA-Z0-9][a-zA-Z0-9_]{3,30}$/;
    const [registerForm, setRegisterForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone_no: parseInt(''),
        password: '',
        confirmPassword: '',
        organizationName: '',
        Phone: '',
        access_url: ''
    });
    const [invalidPassword, setInvalidPassword] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [firstNameTouched, setFirstNameTouched] = useState(false);
    const [lastNameTouched, setLastNameTouched] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [phoneTouched, setPhoneTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
    const [agreeTocTouched, setAgreeTocTouched] = useState(false);
    const [agreeToc, setAgreeToc] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [organizationNameTouched, setOrganizationNameTouched] = useState(false);
    const [organizationPhoneTouched, setOrganizationPhoneTouched] = useState(false);
    const [accessUrlTouched, setAccessUrlTouched] = useState(false);
    const [urlError, setUrlError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(true);
    let [existValidationType, setExistValidationType] = useState('');

    const registerFormHandler = (e) => {
        try {
            const { name, value } = e.target;

            // if (name === 'email') {
            // setEmailError('');
            // } else if (name === 'phone_no') {
            // setPhoneError('');
            // }

            if (name === 'email') {
                setEmailError('');
                if (value === '') {
                    setIsReadOnly(true)
                }
            }
            if (name === 'phone_no') {
                setPhoneError('')
            }

            // Handle validation for firstName and lastName
            if (name === 'firstName' || name === 'lastName' || name === 'organizationName') {
                const isAlphabetic = /^[A-Za-z\s]*$/.test(value);
                const maxLength = 50;
                if (!isAlphabetic || value.length > maxLength) {
                    return;
                }
            }
            // Password validation
            if (name === 'password') {
                // Check password strength and length
                // checkPasswordStrength(value);

                if (value.length < 8) {
                    setPasswordError('Password must be at least 8 characters');
                } else {
                    setPasswordError('');
                }

                if (value.length == 0) {
                    setPasswordError('');
                }

                // Check for mismatch if confirmPassword is already filled
                if (registerForm.confirmPassword && value !== registerForm.confirmPassword) {
                    setConfirmPasswordError("Password Doesn't Match");
                } else if (registerForm.confirmPassword) {
                    setConfirmPasswordError('');
                }
            }

            // Confirm Password validation
            if (name === 'confirmPassword') {
                if (value !== registerForm.password) {
                    setConfirmPasswordError("Password Doesn't Match");
                } else {
                    setConfirmPasswordError('');
                }
            }

            if (name === 'phone_no' || name === 'Phone') {
                // if (!value) {
                //     setPhoneError('Please enter your phone number.');
                // } else if (value.length !== 10) {
                //     setPhoneError('Phone number must be exactly 10 digits.');
                // }
                // if (value.length == 0 || value.length == 10) {
                //     setPhoneError('')
                // } else if (value.length > 0 && value.length !== 10) {
                //     setPhoneError('Phone number must be exactly 10 digits.');
                // }

                if (value.length > 0 && value.length !== 10) {
                    setPhoneError('Phone number must be exactly 10 digits.');
                } else {
                    setPhoneError('');
                }
            }
            setRegisterForm((registerForm) => ({
                ...registerForm,
                [name]: name === 'access_url' ? value.toLowerCase() : value
            }));

        } catch (err) {
            throw err;
        }
    };

    const handleUrlOnBlur = (e) => {
        setAccessUrlTouched(true)
        if (regex.test(registerForm.access_url)) {
            setUrlError('')
        } else {
            setUrlError('Please enter a valid access URL.')
        }
    }
    // old handler
    // const registerFormHandler = (e) => {
    //     try {
    //         setRegisterForm((registerForm) => ({
    //             ...registerForm,
    //             [e.target?.name]: (e?.target?.name == 'phone_no') ? parseInt(e?.target?.value) : e?.target?.value
    //         }))
    //     } catch (err) {
    //         throw err;
    //     }
    // }

    const handleCompanySubmit = () => {
        if (registerForm?.access_url && registerForm?.access_url !== '') {
            if (regex.test(registerForm.access_url)) {
                setUrlError('')
            } else {
                setUrlError('Please enter a valid access URL.')
                return
            }
        }
        const companyPayload = {
            organizationName: registerForm.organizationName,
            Phone: registerForm.Phone,
            access_url: registerForm.access_url
        };
        dispatch(createCompanyStart(companyPayload));
    };

    const [showVerifyEmailUI, setShowVerifyEmailUI] = useState(false);
    const [showAddCompanyUI, setShowAddCompanyUI] = useState(false);

    const registerHandler = () => {
        try {
            const { password, confirmPassword } = registerForm;
            if (password == confirmPassword) {
                const { organizationName, Phone, confirmPassword, ...rest } = registerForm
                dispatch(registerStart(rest))
            } else {
                setInvalidPassword(true)
            }
        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        if (invalidPassword == true)
            toast.error('Password and Confirm Password Mismatch', {
                autoClose: 2000
            })
    }, [invalidPassword])

    useEffect(() => {
        if (register?.successStatus) {
            localStorageHandler(register);
            // toast.success(register?.message, {
            //     autoClose: 3000,
            //     onClose: () => {
            // setShowVerifyEmailUI(true);
            setShowAddCompanyUI(true);
            //     }
            // })
        } else if (!register?.successStatus) {
            toast.error(register?.message, {
                autoClose: 2000
            })
        }
    }, [register?.successStatus]);

    useEffect(() => {
        if (currentOrg?.createStatus) {
            if (currentOrg?.orgData) {
                setItem('organization', currentOrg?.orgData);
            }
            toast.success(currentOrg?.message, {
                autoClose: 1000,
                onClose: () => {
                    setShowAddCompanyUI(false);
                    setShowVerifyEmailUI(true);
                    setUrlError('');
                    setAccessUrlTouched(false);
                }
            })
        } else if (!currentOrg?.createStatus) {
            setUrlError(currentOrg?.message)
            // toast.error(currentOrg?.message, {
            //     autoClose: 2000
            // })
        }
    }, [currentOrg?.createStatus]);

    // useEffect(() => {
    //     if (emailTouched && register.emailError) {
    //         setEmailError(register.emailError);
    //     } else if (!emailTouched) {
    //         setEmailError(null);
    //     }

    //     if (phoneTouched && register.phoneError) {
    //         setPhoneError(register.phoneError);
    //     } else if (!phoneTouched) {
    //         setPhoneError(null);
    //     }
    // }, [register.emailError, register.phoneError, emailTouched, phoneTouched]);

    useEffect(() => {
        if (register?.existError?.includes('Registered') || register?.existError?.includes('Invalid')) {
            if (existValidationType == 'email') {
                setEmailError(register.existError);
                setPhoneError('');
            }
            if (existValidationType == 'phone') {
                setPhoneError(register.existError);
                setEmailError('');
            }
        }

        if (register?.existError?.includes('Proceed')) {
            if (existValidationType == 'email') {
                setEmailError('');
                setPhoneError('');
                setIsReadOnly(false);
            }
        }
    }, [register])

    const handleEmailOnBlur = (e) => {
        setIsReadOnly(true)
        setExistValidationType('email');
        setEmailTouched(true);
        handleEmailBlur(e);
    };

    const handlePhoneNoBlur = (e) => {
        setExistValidationType('phone');
        setPhoneTouched(true);
        handlePhoneBlur();
    };

    const handleEmailBlur = async () => {
        if (registerForm.email) {
            dispatch(userExistStart({ email: registerForm.email }));
        }
    };

    const handlePhoneBlur = async () => {
        if (registerForm.phone_no) {
            dispatch(userExistStart({ phone_no: registerForm.phone_no }));
        }
    };

    const localStorageHandler = (auth) => {
        try {
            setItem("user", auth?.savedUser);
            setItem('token', auth?.savedUser?.accessToken)
        } catch (err) {
            throw err;
        }
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setAgreeToc(isChecked);
    };

    // const validatePhoneNumber = (phoneNumber) => {
    //     if (!phoneNumber) {
    //         setPhoneError('Please enter your phone number.');
    //     } else if (phoneNumber.length !== 10) {
    //         setPhoneError('Phone number must be exactly 10 digits.');
    //     }
    // };

    const isFormComplete = registerForm.firstName && registerForm.lastName &&
        registerForm.email && registerForm.password && registerForm.confirmPassword && agreeToc &&
        (registerForm.password === registerForm.confirmPassword) && !emailError && !phoneError;
    const isCompanyFormComplete = registerForm.organizationName && (registerForm.Phone && phoneError === '') && registerForm.access_url;

    const popover = (
        <Popover className="h-auto w-auto" style={{ maxWidth: "370px" }} id="accessUrlPopover">
            <Popover.Header className="text-primary bg-transparent border-0 px-0" as="h3">
                <i className="fa-solid fa-circle-info mx-3"></i>
                Instructions
            </Popover.Header>
            <Popover.Body className="p-0">
                <ol>
                    <li>Should have character length between 4 and 30.</li>
                    <li>Should contain alphabets.</li>
                    <li>Can contain numbers.</li>
                    <li>Can contain underscore (only allowed special character).</li>
                    <li>Should start either with an alphabet or a number.</li>
                </ol>
            </Popover.Body>
        </Popover>
    );

    // UI

    return (
        <React.Fragment>
            <Loader isVisible={register?.isLoading || currentOrg?.isLoading} />
            {(invalidPassword) && <Toast toastMessage='Password and Confirm Password Mismatch..' toastInstance={() => { }} />}
            {(register?.message || register?.error) && <Toast toastMessage={register?.message} toastInstance={() => { }} />}
            {/* <!-- Login Page --> */}
            <div className="auth-bg"></div>
            <div className="d-flex flex-column flex-root h-100vh">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid auth-page auth-v1">
                    <div className="d-flex flex-column flex-lg-row-fluid order-2 order-lg-1 auth-l-box">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            {/* // Sign Up Form Section END // */}
                            <div className={`w-lg-100 ${showVerifyEmailUI || showAddCompanyUI ? 'd-none' : 'd-block'}`}>
                                {/* <form className="form w-100"> */}
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-crm-logo" width="146" height="56" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Sign Up your account </h1>
                                    <div className="text-body-tertiary fw-semibold fs-6"> Enter your email and password to sign up </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6 icon-input">
                                        {/* <div className="input-group"> */}
                                        <div className={`input-group ${!registerForm?.firstName && firstNameTouched ? 'has-validation' : ''}`}>
                                            {/* <!-- Error Message showing add this class in Above DIV "has-validation" --> */}
                                            <span className="input-group-text first-child bg-transparent border-end-0" id="userName">
                                                <i className="fa-regular fa-user"></i>
                                            </span>
                                            <input type="text" className="form-control border-start-0 last-child" placeholder="First Name"
                                                aria-describedby="firstName" required name={'firstName'}
                                                onChange={(e) => { registerFormHandler(e) }}
                                                onBlur={() => setFirstNameTouched(true)}
                                                value={registerForm?.firstName} />
                                            {!registerForm?.firstName && firstNameTouched && (
                                                <div className="invalid-feedback">Please enter your first name.</div>
                                            )}
                                            {/* <div className="invalid-feedback">Error Message Box</div> */}
                                        </div>
                                    </div>

                                    <div className="col-md-6 icon-input">
                                        {/* <div className="input-group"> */}
                                        <div className={`input-group ${!registerForm?.lastName && lastNameTouched ? 'has-validation' : ''}`}>
                                            {/* <!-- Error Message showing add this class in Above DIV "has-validation" --> */}
                                            <span className="input-group-text first-child bg-transparent border-end-0" id="phoneNumber">
                                                <i className="fa-regular fa-user"></i>
                                            </span>
                                            <input type="text" className="form-control border-start-0 last-child" placeholder="Last Name"
                                                aria-describedby="lastName" required name={'lastName'}
                                                onChange={(e) => { registerFormHandler(e) }}
                                                onBlur={() => setLastNameTouched(true)}
                                                value={registerForm?.lastName} />
                                            {!registerForm?.lastName && lastNameTouched && (
                                                <div className="invalid-feedback">Please enter your last name.</div>
                                            )}
                                            {/* <div className="invalid-feedback">Error Message Box</div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 icon-input">
                                    {/* <div className="input-group"> */}
                                    <div className={`input-group ${!registerForm?.email && emailTouched ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="email">
                                            <i className="fa-regular fa-envelope"></i>
                                        </span>
                                        <input type="email" className="form-control border-start-0 last-child" placeholder="Email"
                                            aria-describedby="email" required name={'email'}
                                            onChange={(e) => { registerFormHandler(e) }}
                                            // onBlur={() => setEmailTouched(true)}
                                            onBlur={handleEmailOnBlur}
                                            value={registerForm?.email} />
                                        {(!registerForm?.email && emailTouched) && (
                                            <div className="invalid-feedback">Please enter your email.</div>
                                        )}
                                        {emailError && <div className="error-message text-danger">{emailError}</div>}
                                        {/* <div className="invalid-feedback">Error Message Box</div> */}
                                    </div>
                                </div>

                                <div className="mb-3 icon-input">
                                    {/* <div className="input-group"> */}
                                    <div className={`input-group ${!registerForm?.phone_no && phoneTouched ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="phone_no">
                                            <i className="fa-solid fa-phone-volume rotate-320"></i>
                                        </span>
                                        <input type="number" className="form-control border-start-0 last-child" placeholder="Phone Number"
                                            aria-describedby="phone_no" required name={'phone_no'}
                                            onChange={(e) => {
                                                if (/^\d*$/.test(e.target.value)) {
                                                    registerFormHandler(e);
                                                }
                                                // validatePhoneNumber(e.target.value);
                                            }}
                                            // onBlur={() => setPhoneTouched(true)}
                                            onBlur={handlePhoneNoBlur}
                                            value={registerForm?.phone_no}
                                            readOnly={isReadOnly}
                                            onKeyDown={(e) => {
                                                if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                    e.preventDefault();
                                                }
                                            }} />
                                        {/* {!registerForm?.phone_no && phoneTouched && phoneError && (
                                            <div className="invalid-feedback">{phoneError}</div>
                                        )} */}
                                        {(!registerForm?.phone_no && !registerForm.email && phoneTouched) && (
                                            <div className="invalid-feedback">Please enter the email first.</div>
                                        )}
                                        {(!registerForm?.phone_no && registerForm.email && phoneTouched) && (
                                            <div className="invalid-feedback">Please enter your phone number.</div>
                                        )}
                                        {phoneError && <div className="error-message">{phoneError}</div>}
                                        {/* {(!registerForm?.phone_no && phoneTouched) && (
                                            <div className="invalid-feedback">Please enter your phone number.</div>
                                        )} */}
                                        {/* <div className="invalid-feedback">Error Message Box</div> */}
                                    </div>
                                </div>

                                <div className="row g-2">
                                    <div className="col-md-6">
                                        <div className="mb-3 icon-input">
                                            {/* <div className="input-group"> */}
                                            <div className={`input-group ${!registerForm?.password && passwordTouched ? 'has-validation' : ''}`}>
                                                {/* <!-- Error Message showing add this class in Above DIV "has-validation" --> */}
                                                <span className="input-group-text bg-transparent border-end-0 first-child" id="password">
                                                    <i className="fa-solid fa-lock"></i>
                                                </span>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    className="form-control border-start-0 border-end-0" placeholder="Password"
                                                    aria-describedby="password" required name={'password'}
                                                    onChange={(e) => { registerFormHandler(e) }}
                                                    onBlur={() => setPasswordTouched(true)}
                                                    value={registerForm?.password}
                                                />
                                                <span className="input-group-text bg-transparent border-start-0 last-child"
                                                    onClick={() => { registerForm?.password && setShowPassword(!showPassword) }}>
                                                    <i className={`fa-regular ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                    {/* <!-- Below icon for OPEN EYE -->
                                                    <!-- <i className="fa-regular fa-eye"></i> --> */}
                                                </span>

                                                {(!registerForm?.password || passwordTouched) && (
                                                    <div className="invalid-feedback">Please enter password.</div>
                                                )}
                                                {passwordError && <div className="error-message">{passwordError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3 icon-input">
                                            {/* <div className="input-group"> */}
                                            <div className={`input-group ${!registerForm?.confirmPassword && confirmPasswordTouched ? 'has-validation' : ''}`}>
                                                {/* <!-- Error Message showing add this class in Above DIV "has-validation" --> */}
                                                <span className="input-group-text bg-transparent border-end-0 first-child" id="conformPassword">
                                                    <i className="fa-solid fa-lock"></i>
                                                </span>
                                                <input
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    className="form-control border-start-0 border-end-0" placeholder="Confirm password"
                                                    aria-describedby="conformPassword" required name={'confirmPassword'}
                                                    onChange={(e) => { registerFormHandler(e) }}
                                                    onBlur={() => setConfirmPasswordTouched(true)}
                                                    value={registerForm?.confirmPassword} />
                                                <span className="input-group-text bg-transparent border-start-0 last-child"
                                                    onClick={() => { registerForm?.confirmPassword && setShowConfirmPassword(!showConfirmPassword) }}>
                                                    <i className={`fa-regular ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                    {/* <!-- Below icon for OPEN EYE -->
                                                            <!-- <i className="fa-regular fa-eye"></i> --> */}
                                                </span>
                                                {(!registerForm?.confirmPassword && confirmPasswordTouched) && (
                                                    <div className="invalid-feedback">Please enter confirm password.</div>
                                                )}
                                                {confirmPasswordError && <div className="error-message">{confirmPasswordError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className={`form-check ${!agreeToc && agreeTocTouched ? 'has-validation' : ''}`}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="agreeToc"
                                            required
                                            checked={agreeToc}
                                            onChange={handleCheckboxChange}
                                            onBlur={() => setAgreeTocTouched(true)}
                                        />
                                        <label className="form-check-label" htmlFor="agreeToc">
                                            I agree to the
                                            <a onClick={() => navigate(`/${Utils}/terms-of-service`)} target="_blank" rel="noopener noreferrer" className="ms-1">Terms of Service </a>
                                            and
                                            <a onClick={() => navigate(`/${Utils}/privacy-policy`)} target="_blank" rel="noopener noreferrer" className="ms-1">Privacy Policy</a>.
                                        </label>
                                        {(!agreeToc && agreeTocTouched) && (
                                            <div className="invalid-feedback">You must agree to the terms and privacy policy.</div>
                                        )}
                                    </div>
                                </div>

                                <div className="d-grid mb-3">
                                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary"
                                        onClick={() => { registerHandler() }} disabled={!isFormComplete}>
                                        <span className="indicator-label">Sign Up</span>
                                    </button>
                                </div>

                                <div className="separator separator-content my-4">
                                    <span className="w-125px fw-semibold fs-7 text-gray-500">Or</span>
                                </div>

                                <div className="d-flex justify-content-center mb-3">
                                    <a className="btn btn-flex btn-outline flex-center text-nowrap border rounded-pill me-3 social-log-btn">
                                        <img alt="brand-logo" src={googleLogo} className="h-15px" />
                                    </a>
                                    <a className="btn btn-flex btn-outline flex-center text-nowrap border rounded-pill social-log-btn">
                                        <img alt="brand-logo" src={microsoftLogo} className="h-15px" />
                                    </a>
                                </div>

                                <div className="text-gray-500 text-center fw-semibold fs-6">
                                    Don’t you have an account?
                                    <a onClick={() => { localStorage.clear(); navigate("/auth/login") }} className="link-primary text-decoration-none"> Login </a>
                                </div>
                                {/* </form> */}
                            </div>
                            {/* // Sign Up Form Section END // */}

                            {/* Company Details Section */}
                            <div className={`w-lg-100 ${showAddCompanyUI ? 'd-block' : 'd-none'}`}>
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-crm-logo" width="146" height="56" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Welcome to Digylax </h1>
                                    <div className="text-body fw-semibold fs-6">
                                        You're one step away from getting started
                                    </div>
                                </div>
                                <div className="mb-3 icon-input">
                                    <div className={`input-group ${!registerForm?.organizationName && organizationNameTouched ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="companyName">
                                            <i className="fa-regular fa-building"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child"
                                            placeholder="Company Name"
                                            aria-describedby="companyName"
                                            required
                                            name={'organizationName'}
                                            value={registerForm?.organizationName}
                                            onChange={registerFormHandler}
                                            onBlur={() => setOrganizationNameTouched(true)} />
                                        {!registerForm?.organizationName && organizationNameTouched && (
                                            <div className="invalid-feedback">Company name required</div>
                                        )}
                                        {/* <div className="invalid-feedback">Company name required</div> */}
                                    </div>
                                </div>
                                <div className="mb-3 icon-input">
                                    <div className={`input-group ${!registerForm?.Phone && organizationPhoneTouched ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="companyPhNum">
                                            <i className="fa-solid fa-phone-volume rotate-320"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child" placeholder="Company Phone Number"
                                            aria-describedby="companyPhNum" required
                                            name={'Phone'}
                                            value={registerForm?.Phone}
                                            onChange={(e) => {
                                                if (/^\d*$/.test(e.target.value)) {
                                                    registerFormHandler(e);
                                                }
                                            }}
                                            onBlur={() => setOrganizationPhoneTouched(true)}
                                            onKeyDown={(e) => {
                                                if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {!registerForm?.Phone && organizationPhoneTouched && (
                                            <div className="invalid-feedback">Company phone number required</div>
                                        )}
                                        {phoneError && <div className="error-message">{phoneError}</div>}
                                        {/* {phoneError && <div className="invalid-feedback">{phoneError}</div>} */}
                                    </div>
                                </div>
                                <div className="mb-3 icon-input">
                                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                                        <InputGroup className={`input-group ${(!registerForm?.access_url && accessUrlTouched || urlError !== '') ? 'has-validation' : ''}`}>
                                            <InputGroup.Text id="accessUrlInput">
                                                {ACCESS_URL}
                                            </InputGroup.Text>
                                            <Form.Control className="rounded-start-0" id="accessUrl"
                                                placeholder="Access URL"
                                                aria-describedby="accessUrlInput"
                                                //  autoFocus
                                                name={'access_url'}
                                                value={registerForm?.access_url}
                                                onChange={registerFormHandler}
                                                onBlur={handleUrlOnBlur}
                                            />
                                            {(!registerForm?.access_url && accessUrlTouched || urlError !== '') && (
                                                <div className="invalid-feedback">{urlError}</div>
                                            )}
                                        </InputGroup>
                                    </OverlayTrigger>
                                </div>
                                {/* <div className="mb-3 icon-input">
                                    <div className={`input-group ${(!registerForm?.access_url && accessUrlTouched || urlError !== '') ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="accessUrl">
                                            <i className="fa fa-link"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child"
                                            placeholder="Access URL"
                                            aria-describedby="accessUrl"
                                            required
                                            name={'access_url'}
                                            value={registerForm?.access_url}
                                            onChange={registerFormHandler}
                                            onBlur={handleUrlOnBlur} />
                                        {(!registerForm?.access_url && accessUrlTouched || urlError !== '') && (
                                            <div className="invalid-feedback">{urlError}</div>
                                        )}
                                    </div>
                                </div> */}
                                <div className="d-grid pt-4 my-3">
                                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary"
                                        onClick={() => { handleCompanySubmit() }} disabled={!isCompanyFormComplete}>
                                        <span className="indicator-label">Get Started</span>
                                    </button>
                                </div>
                            </div>
                            {/* // Company Details Section END // */}

                            {/* Verify Email Section */}
                            <div className={`w-lg-100 ${showVerifyEmailUI ? 'd-block' : 'd-none'}`}>
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-crm-logo" width="146" height="56" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Please verify your email </h1>
                                    <p className="mt-4 mb-0">We will sent an email to <span className="fw-semibold">({registerForm?.email})</span>.</p>
                                    <p>Click the link in the email to verify your account.</p>
                                    {/* <div className="text-body-tertiary fw-semibold fs-6 my-3">
                                        Did’t receive an email?
                                        <a className="ps-2">Try Again</a>
                                    </div> */}
                                    <p className="pt-3">
                                        Already have an account?
                                        <a onClick={() => { localStorage.clear(); navigate("/auth/login") }} className="ps-2 fw-semibold cursor-pointer">Log in</a>.
                                    </p>
                                </div>

                            </div>
                            {/* // Verify Email Section END // */}
                        </div>
                    </div>
                    <div className="d-none d-lg-flex flex-lg-row-fluid order-1 order-lg-2 auth-r-box">
                        <div className="d-flex flex-column flex-center py-3 w-100">
                            <img className="main-img" src={authImage} alt="auth-bg" />
                            <h5 className="heading">
                                MFA for all accounts
                            </h5>
                            <p className="content">
                                Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.
                            </p>
                            {/* <a className="btn text-decoration-none">Learn more</a> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- // Login Page // --> */}
        </React.Fragment>
    )
}
export default Signup;