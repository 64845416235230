import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getItem } from '../../Services/localService';
import { getAllFormModuleStart, getManageModulesStart } from "../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
// import digyLogo from '../../Assets/images/digylax-logo.png';
import digyCrmLogo from '../../Assets/images/crm-logo-final.svg';
import whatsappIcon from '../../Assets/images/header-icons/whatsapp-icon.svg';
import settingIcon from '../../Assets/images/header-icons/setting-icon.svg';
import qmarkIcon from '../../Assets/images/header-icons/question-mark-icon.svg';
import bellIcon from '../../Assets/images/header-icons/bell-icon.svg';
import { getAllUserDataStart, getUserByIdStart } from "../../Store/Slices/userSlice";
import { getCompanyListStart } from "../../Store/Slices/organizationSlice";
import { FiLayers } from "react-icons/fi";
import { Tooltip } from "bootstrap";
import { getRoleStart } from "../../Store/Slices/roleSlice";
import { getProfileStart } from "../../Store/Slices/profileSlice";
import { logout } from "../../Services/apiService";
import { Utils } from "../../Utils";
import ReminderModal from "../ModalPopup/ReminderModal";
import { io } from 'socket.io-client';

const Header = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    let dispatch = useDispatch();
    let basicPermissionData = getItem('modulePermission')?.basic;
    let formList = useSelector((state) => state?.forms);
    let currentUser = useSelector((state) => state?.user);
    let currentOrg = useSelector((state) => state?.org);
    let profile_picture = "https://campussafetyconference.com/wp-content/uploads/2020/08/iStock-476085198.jpg";
    let [showNavbarList, setShowNavbarList] = useState([]);
    let currentId = location.pathname.split('/').pop();
    let id = getItem('user')?._id;
    let orgId = getItem('organization')
    let [show, setShow] = useState(0);
    let [showReminder, setShowReminder] = useState(false);
    let [reminders, setReminders] = useState([]);

    if (Array.isArray(currentUser?.data)) {
        profile_picture = currentUser?.data.filter((data) => data._id === id)?.[0]?.profilePicture;
    } else {
        profile_picture = currentUser?.data?.profilePicture
    }

    useEffect(() => {
        if (id) {
            // let SOCKET_SERVER_URL = process.env.SOCKET_SERVER_URL;
            let socket = io("https://crm-api.digylax.com/");
            socket.on('connect', () => {
                console.log('Connected to server:', socket.id);
            });
            socket.on('reminder', (data) => {
                if (data.user_id === id) {
                    let reminderDatas = data?.data;
                    if (reminderDatas?.reminder?.alert === "Pop-up" || reminderDatas?.reminder?.alert === "Both") {
                        setReminders((prev) => [reminderDatas, ...prev]);
                        setShowReminder(true);
                    }
                }
            });
            return () => {
                socket.disconnect();
                console.log('Disconnected from server');
            };
        }
    }, [id]);

    useEffect(() => {
        // This is important for React tooltip (Dont remove)
        // if you want tooltip or Popover please use react react-bootstrap
        let tooltipElement = document.querySelector('.tooltip-hover');
        if (tooltipElement) {
            new Tooltip(tooltipElement, {
                title: 'Tooltip',
                placement: 'bottom',
            });
        }
    }, []);

    useEffect(() => {
        dispatch(getUserByIdStart(id));
        dispatch(getCompanyListStart(orgId));
        dispatch(getManageModulesStart());
        dispatch(getProfileStart());
        dispatch(getRoleStart());
        dispatch(getAllUserDataStart());
    }, []);

    useEffect(() => {
        if (formList?.isManageModuleApiError || formList?.isManageModuleApiCalled && !formList?.navbarList?.length) {
            dispatch(getAllFormModuleStart());
        }
    }, [formList?.isManageModuleApiError, formList?.isManageModuleApiCalled]);

    useEffect(() => {
        if (formList?.newCreated) {
            dispatch(getManageModulesStart());
        }
    }, [formList?.newCreated]);

    useEffect(() => {
        let localUser = getItem('user');
        setUser(localUser);
    }, []);

    useEffect(() => {
        if (formList?.navbarList?.length > 0) {
            let list = formList?.navbarList?.filter(x => x.show);
            if (basicPermissionData) {
                list = list.filter(({ _id }) => basicPermissionData?.some(x => (x?.id == _id && x?.activities?.view == true)));
            }
            let homeMenuPush = [{ name: "Home" }, ...list];
            setShowNavbarList(homeMenuPush);
        }
    }, [formList?.navbarList]);

    const logoutHandler = async () => {
        try {
            const data = await logout();
            // localStorage.clear();
            // window.location.reload();
        } catch (err) {
            throw err;
        }
    }

    let autoResize = () => {
        if (window.innerWidth <= 991) { // up to 991 = 20 link
            setShow();
        } else if (window.innerWidth <= 1199) { // 992 to 1200 = 1 links
            setShow(3);
        } else if (window.innerWidth <= 1200) { // 1200 to 1200 = 2 links
            setShow(4);
        } else if (window.innerWidth <= 1300) { // 1201 to 1300 = 3 links
            setShow(5);
        } else if (window.innerWidth <= 1366) { // 1301 to 1366 = 5 links
            setShow(7);
        } else if (window.innerWidth <= 1400) { // 1367 to 1400 = 5 links
            setShow(7);
        } else if (window.innerWidth <= 1500) { // 1401 to 1500 = 6 links
            setShow(8);
        } else if (window.innerWidth <= 1600) { // 1501 to 1600 = 6 links
            setShow(8);
        } else if (window.innerWidth <= 1700) { // 1601 to 1700 = 7 links
            setShow(9);
        } else if (window.innerWidth <= 1800) { // 1701 to 1800 = 8 links
            setShow(10);
        } else if (window.innerWidth <= 1900) { // 1801 to 1900 = 9 links
            setShow(10);
        } else if (window.innerWidth <= 2500) { // 1901 to 2500 = 10 links
            setShow(11);
        } else {
            setShow(15) // above 2501
        }
    }

    useEffect(() => {
        window.addEventListener('resize', autoResize)
        autoResize();
    }, [])

    const changeNavbar = (x) => {
        navigate(Utils.getNavigationUrl(`form-module/sub-form/${x?._id}`));
    }

    return (
        <>
            <header
                className="navbar navbar-expand-lg sticky-top bg-white border-bottom border-light-subtle px-0 py-1 top-header">
                <nav className="container-fluid flex-wrap flex-lg-nowrap">
                    {/* Left side Mobile Hamburger icon */}
                    <button className="navbar-toggler border-0" type="button"
                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenuNavbar"
                        aria-controls="offcanvasMenuNavbar">
                        {/* <span className="navbar-toggler-icon"></span> */}
                        {/* <img src={FiLayers} alt="setting-icon" width="20" height="20"></img> */}
                        <FiLayers />
                    </button>
                    {/* Brand Logo */}
                    <a className="navbar-brand py-0" onClick={() => navigate(Utils.getNavigationUrl(`dashboard`))}>
                        <img src={digyCrmLogo} alt="digylax-crm-logo" width="111" height="36" />
                    </a>

                    {/* Left side menus Start */}
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasMenuNavbar" aria-labelledby="offcanvasMenuNavbarLabel">
                        <div className="offcanvas-header">
                            {/* <h5 className="offcanvas-title" id="offcanvasMenuNavbarLabel">Mobile Menu Title</h5> */}
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body me-lg-auto">
                            <ul className="navbar-nav flex-lg-row me-auto btn-group module-links-list">
                                {showNavbarList?.length > 0 && (
                                    <>
                                        {showNavbarList?.slice(0, show)?.map((x, index) => (
                                            (x?.name === "Home") ?
                                                <li key={index} className={`nav-item text-nowrap ${index > 2 && `h-menu-link h-menu-link${index}`}`}>
                                                    <button type="button" className={`btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue ${currentId === 'dashboard' ? 'btn-digy-dark-outline' : 'btn-plain-border'}`}
                                                        onClick={() => navigate(Utils.getNavigationUrl(`dashboard`))}>
                                                        <span>{x?.name}</span>
                                                    </button>
                                                </li>
                                                :
                                                x?.name === "Analytics" ?
                                                    <>
                                                        <li key={index} className={`nav-item text-nowrap ${index > 2 && `h-menu-link h-menu-link${index}`}`}>
                                                            <button type="button" className={`btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue ${currentId === 'analytics' ? 'btn-digy-dark-outline' : 'btn-plain-border'}`}
                                                                onClick={() => navigate(Utils.getNavigationUrl(`analytics`))}>
                                                                <span>{x?.name}</span>
                                                            </button>
                                                        </li>
                                                    </>
                                                    :
                                                    <li className={`nav-item text-nowrap ${index > 2 && `h-menu-link h-menu-link${index}`}`} key={index}>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary rounded-pill light-blue ${currentId === x?._id ? 'btn-digy-dark-outline' : 'btn-plain-border'}`}
                                                            onClick={() => changeNavbar(x)}
                                                        >
                                                            <span>{x?.name}</span>
                                                        </button>
                                                    </li>
                                        ))}
                                        <li className="nav-item d-none d-lg-inline-block">
                                            <div className="dropdown">
                                                <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="moduleDropdown">
                                                    <i className="fa-solid fa-ellipsis d-none d-lg-inline"></i>
                                                    <span className="d-inline d-lg-none">More Links</span>
                                                </button>
                                                <ul className="dropdown-menu p-0" aria-labelledby="moduleDropdown" style={{ overflow: "auto" }}>
                                                    <li>
                                                        <ul className="overflow-y-auto py-2">
                                                            {showNavbarList?.slice(show)?.length > 0 && (
                                                                <>
                                                                    {showNavbarList?.slice(show).map((x, index) => (
                                                                        x?.name === "Analytics" ?
                                                                            <>
                                                                                <li key={index} className={`p-0 ${index > 2 && `h-menu-link h-menu-link${index}`}`}>
                                                                                    <a className="dropdown-item cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`analytics`))}>
                                                                                        {x?.name}
                                                                                    </a>
                                                                                </li>
                                                                            </>
                                                                            :
                                                                            <li key={index} className={`p-0 ${index > 2 && `h-menu-link h-menu-link${index}`}`}>
                                                                                <a className="dropdown-item cursor-pointer" onClick={() => changeNavbar(x)}>
                                                                                    {x?.name}
                                                                                </a>
                                                                            </li>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </ul>
                                                    </li>
                                                    <li><hr className="dropdown-divider my-0" /></li>
                                                    <li>
                                                        <a className="dropdown-item bg-light link-primary cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`form-module/create`))}>
                                                            <i className="fa-solid fa-plus"></i> Add Module
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                        {/* Mobile & Tablet only - Add module link*/}
                        <div className="offcanvas-footer p-3 text-center shadow-top d-lg-none d-inline-block">
                            <a className="link-primary cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`form-module/create`))}>
                                <i className="fa-solid fa-plus"></i> Add Module
                            </a>
                        </div>
                        {/* // Mobile & Tablet only // */}
                    </div>
                    {/* // Left side menus END // */}

                    {/* Right side menus Start */}
                    {/* Right side Mobile Hamburger icon */}
                    <button className="navbar-toggler rounded-pill p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUserNavbar" aria-controls="offcanvasUserNavbar">
                        {/* <span className="navbar-toggler-icon"></span> */}
                        <img className="user-icon" src={profile_picture} alt="user-image" width="32" height="32" />
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasUserNavbar" aria-labelledby="offcanvasUserNavbarLabel">
                        <div className="offcanvas-header">
                            {/* <h5 className="offcanvas-title" id="offcanvasUserNavbarLabel">Mobile Menu Title</h5> */}
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body ms-lg-auto">
                            {/* Mobile & Tablet only */}
                            <div className="nav-item text-nowrap d-block d-lg-none text-center mb-3">
                                <button className="nav-link p-0 w-auto mx-auto border-0 bg-white com-logo" type="button">
                                    <img className="img-fluid w-50" src={currentOrg?.data?.[0]?.profilePicture} alt="Logo" width="90" height="36" />
                                </button>
                            </div>
                            {/* // Mobile & Tablet only // */}

                            <ul className="navbar-nav flex-row justify-content-center">
                                <li className="nav-item text-nowrap">
                                    <button className="nav-link p-0" type="button">
                                        <img src={whatsappIcon} alt="whatsapp-icon" width="20"
                                            height="20" />
                                    </button>
                                </li>
                                <li className="nav-item text-nowrap">
                                    <button className="nav-link p-0" type="button" onClick={() => navigate(Utils.getNavigationUrl(`settings`))}>
                                        <img src={settingIcon} alt="setting-icon" width="20"
                                            height="20" />
                                    </button>
                                </li>
                                <li className="nav-item text-nowrap">
                                    <button className="nav-link p-0" type="button">
                                        <img src={qmarkIcon} alt="question-mark-icon"
                                            width="20" height="20" />
                                    </button>
                                </li>
                                <li className="nav-item text-nowrap">
                                    <button className="nav-link p-0" type="button" onClick={() => setShowReminder(true)}>
                                        <img src={bellIcon} alt="bell-icon" width="20" height="20" />
                                    </button>
                                </li>
                                {currentOrg?.data?.[0]?.profilePicture &&
                                    <li className="nav-item text-nowrap d-none d-lg-inline-block">
                                        <button className="nav-link p-0 w-auto border-0 bg-white com-logo" type="button">
                                            {/* currentOrg?.data?.[0]?.profilePicture */}
                                            <img src={currentOrg?.data?.[0]?.profilePicture} alt="Logo" width="90" height="36" />
                                        </button>
                                    </li>
                                }
                                <li className="nav-item text-nowrap dropdown d-none d-lg-inline-block">
                                    <button className="nav-link p-0 dropdown-toggle" type="button"
                                        id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        {/* currentUser?.data?.profilePicture */}
                                        <img className="user-icon" src={profile_picture} alt="user-image" width="32" height="32" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                        <li>
                                            <a className="dropdown-item">
                                                Welcome, {user?.firstName || ''} {user?.lastName || ''}
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => navigate(Utils.getNavigationUrl(`form-module`))}>Module</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => navigate(Utils.getNavigationUrl(`settings/users`))}>User</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => navigate(Utils.getNavigationUrl(`settings/profiles`))}>Profile</a>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => navigate(Utils.getNavigationUrl(`change-password`))}>Change Password</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => { logoutHandler() }}>
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            {/* Mobile & Tablet only */}
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 mt-3 d-flex d-lg-none">
                                <li className="nav-item w-100 dropdown">
                                    <button className="nav-link p-0 w-100 mx-auto with-arrow dropdown-toggle" type="button"
                                        id="userDropdownMob" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>Hi, {user?.firstName || ''} {user?.lastName || ''}</span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdownMob">
                                        <li>
                                            <a className="dropdown-item">Module</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">User</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">Profile</a>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => { logoutHandler() }}>
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            {/* // Mobile & Tablet only // */}

                        </div>
                    </div>
                    {/* // Right side menus END // */}
                </nav>
            </header>
            {showReminder && <ReminderModal show={showReminder} handleClose={() => setShowReminder(false)} isData={reminders || []} />}
        </>
    )

}
export default Header;