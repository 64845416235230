import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    createStatus: null,
    updateStatus: null,
    deleteStatus: null,
    deleteTransferStatus: null,
    profileData: null
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        getProfileStart: (state) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
            state.profileData = null;
        },
        getProfileSuccess: (state, action) => {
            state.data = action?.payload?.profiles;
            state.profileData = action?.payload?.profiles;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        getProfileFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        getProfileByIdStart: (state) => {
            state.isLoading = true;
            state.reload = null;
        },
        getProfileByIdSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        getProfileByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        createProfileStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
        },
        createProfileSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.savedProfile;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        createProfileFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        updatePermissionStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
            state.updateStatus = null;
        },
        updatePermissionSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updatePermissionFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        deleteProfileStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.deleteStatus = null;
            state.deleteTransferStatus = null;
        },
        deleteProfileSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.deleteStatus = action?.payload?.success;
        },
        deleteProfileFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.deleteStatus = action?.payload?.success;
        },
        deleteToTransferProfileStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.deleteStatus = null;
        },
        deleteToTransferProfileSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.deleteTransferStatus = action?.payload?.success;
        },
        deleteToTransferProfileFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.deleteTransferStatus = action?.payload?.success;
        }
    }
});
export const {
    getProfileStart,
    getProfileSuccess,
    getProfileFailure,
    getProfileByIdStart,
    getProfileByIdSuccess,
    getProfileByIdFailure,
    createProfileStart,
    createProfileSuccess,
    createProfileFailure,
    updatePermissionStart,
    updatePermissionSuccess,
    updatePermissionFailure,
    deleteProfileStart,
    deleteProfileSuccess,
    deleteProfileFailure,
    deleteToTransferProfileStart,
    deleteToTransferProfileSuccess,
    deleteToTransferProfileFailure
} = profileSlice?.actions;

export default profileSlice?.reducer;