import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Modal } from 'react-bootstrap';
import { handleFieldLabelUpdate } from '../../../Utils';



const BodyContent = ({
  fieldLabel,
  setFieldLabel,
  returnType,
  setReturnType,
  showReturnType,
  setShowReturnType,
  decimalPlaces,
  setDecimalPlaces,
  showDecimalPlaces,
  setShowDecimalPlaces,
  functionCategory,
  showFunctionDropdown,
  setShowFunctionDropdown,
  filteredFunctions,
  selectedFunction,
  selectedField,
  selectedOperator,
  formulaExpression,
  setFormulaExpression,
  syntaxError,
  handleKeyDown,
  handleInsertFunction,
  handleInsertField,
  handleInsertOperator,
  checkSyntax,
  handleSelect,
  selectfields,
  operators,
  returnTypeRef,
  functionDropdownRef,
  decimalPlacesRef,
  handleCategorySelect,
  setHoveredFunction,
  hoveredFunction,
  handleConfirmInsertFunction,
  setHoveredField,
  hoveredField,
  handleConfirmInsertField,
  setHoveredOperator,
  hoveredOperator,
  handleConfirmInsertOperator,
  functionDetails,
  labelError,
  setLabelError,
  showTooltip,
  setShowTooltip,
  tooltipText,
  setTooltipText,
  radioValue,
  setRadioValue
}) => {

  let handleFieldLabelChange = (event) => {
    const value = event.target.value;
    handleFieldLabelUpdate(value, setFieldLabel, setLabelError);  
  };

  return (
    <Container fluid className="mt-1">
      <Form>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Field Label *</Form.Label>
              <Form.Control
                type="text"
                value={fieldLabel}
                onChange={handleFieldLabelChange}
              />
              {labelError && <div className="error-message">{labelError}</div>}
            </Form.Group>
          </Col>
          <Col md={4} ref={returnTypeRef}>
            <Form.Group className="mb-3">
              <Form.Label>Formula Return Type</Form.Label>
              <div className="custom-dropdown" onClick={() => setShowReturnType(!showReturnType)}>
                <Form.Control
                  type="text"
                  value={returnType}
                  readOnly
                  className="cursor-pointer"
                  onChange={(e) => handleKeyDown(e, 'returnType')}
                />
                {showReturnType && (
                  <div className="formula-dropdown-options return-type-dropdown" style={{ position: 'absolute', zIndex: 1000 }}>
                    {['Decimal', 'Currency', 'String', 'Date', 'DateTime', 'Boolean'].map((type) => (
                      <div key={type} onClick={() => { setReturnType(type); setShowReturnType(false); }}>
                        {type}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Form.Group>
          </Col>
          {['Decimal', 'Currency'].includes(returnType) && (
            <Col md={4} ref={decimalPlacesRef}>
              <Form.Group className="mb-3">
                <Form.Label>Number of Decimal Places</Form.Label>
                <div className="custom-dropdown" onClick={() => setShowDecimalPlaces(!showDecimalPlaces)}>
                  <Form.Control
                    type="text"
                    value={decimalPlaces}
                    readOnly
                    className="cursor-pointer"
                    onChange={(e) => handleKeyDown(e, 'decimal')}
                  />
                  {showDecimalPlaces && (
                    <div className="formula-dropdown-options" style={{ position: 'absolute', zIndex: 1000, maxHeight: '150px', overflowY: 'auto' }}>
                      {Array.from({ length: 10 }, (_, i) => (
                        <div key={i} onClick={() => { setDecimalPlaces(i); setShowDecimalPlaces(false); }}>
                          {i}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Form.Group>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={4} ref={functionDropdownRef}>
            <Form.Group className="mb-3">
              <Form.Label>Select Function</Form.Label>
              <Form.Control
                type="text"
                placeholder="Select Function Category"
                value={functionCategory}
                className="cursor-pointer"
                onClick={() => setShowFunctionDropdown(!showFunctionDropdown)}
                onChange={(e) => handleKeyDown(e, 'function')}
              />
              {showFunctionDropdown && (
                <div className="formula-dropdown-options" style={{ position: 'absolute', zIndex: 1000 }}>
                  {['All Functions', 'String', 'Numeric', 'DateTime', 'Boolean'].map(category => (
                    <div key={category} onClick={() => handleCategorySelect(category)}>
                      {category === 'All Functions' ? 'All Functions' : `${category} Functions`}
                    </div>
                  ))}
                </div>
              )}
              <div className="formula-dropdown-options function-dropdown">
                {filteredFunctions.map((func, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setHoveredFunction(func)}
                    onMouseLeave={() => setHoveredFunction(null)}
                    onClick={() => handleInsertFunction(func)}
                    style={{
                      cursor: 'pointer',
                      padding: '1px',
                      backgroundColor: selectedFunction === func ? '#c0c0c0' : hoveredFunction === func ? 'blue' : 'transparent',
                      transition: 'background-color 0.3s',
                      color: selectedFunction === func ? 'white' : hoveredFunction === func ? 'white' : 'black'

                    }}
                  >
                    {func}
                  </div>
                ))}
              </div>
            </Form.Group>
            <div className="text-center">
              <Button variant="secondary" className="insert-button" disabled onClick={handleConfirmInsertFunction}>Insert</Button>
            </div>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select Field</Form.Label>
              <div className="formula-dropdown-options field-dropdown">
                {selectfields?.length > 0 ? selectfields.map((field, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setHoveredField(field?.name)}
                    onMouseLeave={() => setHoveredField(null)}
                    onClick={() => handleInsertField(field?.name)}
                    style={{
                      cursor: 'pointer',
                      padding: '1px',
                      backgroundColor: selectedField === field?.name ? '#c0c0c0' : hoveredField === field?.name ? 'blue' : 'transparent',
                      transition: 'background-color 0.3s',
                      color: selectedField === field?.name ? 'white' : hoveredField === field?.name ? 'white' : 'black'
                    }}
                  >
                    {field?.name}
                  </div>
                )) :
                  <>
                    <div> There are no number fields available</div>
                  </>}
              </div>
            </Form.Group>
            <div className="text-center">
              <Button variant="secondary" className="insert-button" onClick={handleConfirmInsertField}>Insert</Button>
            </div>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select Operator</Form.Label>
              <div className="formula-dropdown-options operator-dropdown">
                {operators.map((op, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setHoveredOperator(op)}
                    onMouseLeave={() => setHoveredOperator(null)}
                    onClick={() => handleInsertOperator(op)}
                    style={{
                      cursor: 'pointer',
                      padding: '1px',
                      backgroundColor: selectedOperator === op ? '#c0c0c0' : hoveredOperator === op ? 'blue' : 'transparent',
                      transition: 'background-color 0.3s',
                      color: selectedOperator === op ? 'white' : hoveredOperator === op ? 'white' : 'black'
                    }}
                  >
                    {op}
                  </div>
                ))}
              </div>
            </Form.Group>
            <div className="text-center">
              <Button variant="secondary" className="insert-button" onClick={handleConfirmInsertOperator}>Insert</Button>
            </div>
          </Col>
        </Row>

        <Card className="my-3">
          <Card.Body>
            <Card.Title>Function Usage:</Card.Title>
            {selectedFunction ? (
              <Card.Text>
                <strong>Function:</strong> {selectedFunction}<br />
                <strong>Description:</strong> {functionDetails[selectedFunction]?.description || 'No description available'}<br />
                <strong>Syntax:</strong> {functionDetails[selectedFunction]?.syntax || 'No syntax available'}<br />
                <strong>Example:</strong> {functionDetails[selectedFunction]?.example || 'No example available'}
              </Card.Text>
            ) : (
              <Card.Text>Please select a function to see its usage.</Card.Text>
            )}
          </Card.Body>
        </Card>

        <Form.Group className="mb-3">
          <Form.Label>Formula Expression *</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formulaExpression}
            onSelect={handleSelect}
            onChange={(e) => setFormulaExpression(e.target.value)}
          />
        </Form.Group>

        {syntaxError && <div className={`text-${syntaxError === "No Errors" ? 'success' : 'danger'}`}>{syntaxError}</div>}
        <Button className="check-syntax-button" variant="secondary" onClick={checkSyntax}>Check Syntax</Button>

        <Form.Group className="mt-3">
          <Form.Check
            type="checkbox"
            label="Display with Number Separator"
          />
        </Form.Group>

        <Form.Group className="mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="Tooltip"
                onChange={(e) => setShowTooltip(e.target.checked)}
                checked={showTooltip}
              />
              <label className="form-check-label" htmlFor="Tooltip">
                Show Tooltip
              </label>
            </div>
      
            {showTooltip && (
              <div className="tooltip-container position-relative">
                <div className="float-right">Max of 255 characters</div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Type tooltip message"
                    value={tooltipText}
                    onChange={(e) => setTooltipText(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="infoIcon"
                    id="infoIcon"
                    checked={radioValue === 'info'}
                    onChange={(e) => setRadioValue('info')}
                  />
                  <label className="form-check-label info-icon" htmlFor="infoIcon">
                    Info Icon
                  </label>
                </div>
              </div>
            )}
          </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label><h6>Blank Value Preference</h6></Form.Label>
          <p>If the values in the participating fields are blank, calculate the formula's value according to the following preference:</p>
          <Form.Check
            type="radio"
            label="Consider blank values as Empty"
            name="blankValuePreference"
            defaultChecked
          />
          <Form.Check
            type="radio"
            label="Consider blank values as '0' for integers, decimals and '' for strings."
            name="blankValuePreference"
          />
        </Form.Group>
      </Form>
    </Container>
  )
}

const CRMFormulaForm = ({ show, handleClose, item, moduleStoreData, section, editPropertySubmit, newAggregate = false, subFormAggregate = false, aggregate_list }) => {
  let initialData = item?.extra_fields;
  let [fieldLabel, setFieldLabel] = useState(initialData?.field_label || '');
  let [returnType, setReturnType] = useState(initialData?.return_type || '');
  let [decimalPlaces, setDecimalPlaces] = useState(initialData?.decimal_place || 0);
  let [selectedFunction, setSelectedFunction] = useState('');
  let [selectedField, setSelectedField] = useState('');
  let [selectedOperator, setSelectedOperator] = useState('');
  let [formulaExpression, setFormulaExpression] = useState(initialData?.formula_expression || '');
  let [showDecimalPlaces, setShowDecimalPlaces] = useState(false);
  let [showReturnType, setShowReturnType] = useState(false);
  let [showFunctionDropdown, setShowFunctionDropdown] = useState(false);
  let [functionCategory, setFunctionCategory] = useState('All Functions');
  let [hoveredFunction, setHoveredFunction] = useState(null);
  let [hoveredField, setHoveredField] = useState(null);
  let [hoveredOperator, setHoveredOperator] = useState(null);
  let [syntaxError] = useState('');
  let returnTypeRef = useRef();
  let decimalPlacesRef = useRef();
  let functionDropdownRef = useRef();
  let [selectfields, setSelectFields] = useState([]);
  let [selectionRange, setSelectionRange] = useState({ start: 0, end: 0 });     // Existing
  let [labelError, setLabelError] = useState('');
  let [showTooltip, setShowTooltip] = useState(false);
  let [tooltipText, setTooltipText] = useState('');
  let [radioValue, setRadioValue] = useState('info');

  let handleSelect = (event) => {
    let { selectionStart, selectionEnd } = event.target;
    setSelectionRange({ start: selectionStart, end: selectionEnd });
  };

  useEffect(() => {
    setSelectionRange({ start: formulaExpression.length, end: formulaExpression.length });
  }, [formulaExpression]);

  useEffect(() => {
    let fieldLabels = [];
    const pushFields = (item) => {
      let checkArray = ["number", "aggregate", "decimal", "currency", "formula", "percent"];
      if (item?.extra_fields?.field_label && checkArray?.includes(item?.input_type)) {
        fieldLabels.push({ name: item?.extra_fields?.field_label, id: item?.id });
      }
    }
    if (subFormAggregate) {
      aggregate_list?.forEach(item => {
        pushFields(item);
      });
    } else if (section?.subform) {
      section?.selected_list?.forEach(item => {
        pushFields(item);
      });
    } else if (moduleStoreData) {
      moduleStoreData?.sections.forEach(section => {
        Object.entries(section?.dropped_list || {}).forEach(([key, value]) => {
          value?.forEach(item => {
            pushFields(item);
          });
        });
      });
    }
    setSelectFields(fieldLabels);
  }, []);

  let stringFunctions = [
    'Len', 'Find', 'Concat', 'CaseInsensitiveEquals', 'Contains',
    'Startswith', 'Endswith', 'Lower', 'Upper', 'Trim',
    'Substring', 'ToString', 'Replace', 'IsEmpty'
  ];

  let numericFunctions = [
    'Abs', 'Ceil', 'Floor', 'Round', 'Naturallog',
    'Base10log', 'Max', 'Min', 'Sqrt', 'Tonumber',
    'IsPositive', 'IsNegative'
  ];

  let datetimeFunctions = [
    'Now', 'NewDate', 'Datepart', 'Timepart', 'Adddate',
    'Subdate', 'Datecomp', 'DateBetween', 'FromTimestamp',
    'Timestamp', 'Dayofweek', 'Dayofmonth', 'Dayofyear',
    'Hour', 'Minute', 'Month', 'Year', 'Weekday'
  ];

  let booleanFunctions = ['And', 'Or', 'Not', 'If'];
  let allFunctions = [...numericFunctions, ...stringFunctions, ...datetimeFunctions, ...booleanFunctions];

  let operators = [
    '+ Add', '- Subtract', '* Multiply', '/ Divide',
    '% Remainder', '^ Exponentiation',
    '( Open parenthesis', ') Close parenthesis',
    '!= Not equal', '== Equals', '< Less than',
    '> Greater than', '<= Less than or equal',
    '>= Greater than or equal'
  ];

  let functionDetails = {
    Abs: {
      description: "Returns the absolute value of the number.",
      syntax: "Abs(number)",
      example: "Abs(-42) gives result as 42 ; Abs(+33) gives result as 33"
    },
    Ceil: {
      description: "Returns the smallest integer greater than or equal to the input number.",
      syntax: "Ceil(number); Ceil(number,number)",
      example: "Ceil(3.4) gives result as 4 ; Ceil(-3.4) gives result as -3 ; Ceil(3.417,2) gives result as 3.42"
    },
    Floor: {
      description: "Returns the largest integer less than or equal to the input number.",
      syntax: "Floor(number); Floor(number,number)",
      example: "Floor(3.8) gives result as 3 ; Floor(-3.8) gives result as -4 ; Floor(3.417,2) gives result as 3.41"
    },
    Round: {
      description: "Rounds the number to the closest number with desired precision.",
      syntax: "Round(number); Round(number,number)",
      example: "Round(3.8) gives result as 4 ; Round(-3.5) gives result as -3 ; Round(3.417,2) gives result as 3.42"
    },
    Naturallog: {
      description: "Returns the natural logarithm of the input number.",
      syntax: "Naturallog(number)",
      example: "Naturallog(2) gives result as 0.69"
    },
    Base10log: {
      description: "Returns the base 10 logarithm of the input number.",
      syntax: "Base10log(number)",
      example: "Base10log(10) gives result as 1.0"
    },
    Max: {
      description: "Returns the maximum value from the specified list of numbers.",
      syntax: "Max(number, number,....)",
      example: "Max(3,1,5,8) gives result as 8 ; Max(0,-4,-3,2) gives result as 2"
    },
    Min: {
      description: "Returns the minimum value from the specified list of numbers.",
      syntax: "Min(number)",
      example: "Min(3,1,5,8) gives result as 1 ; Min(0,-4,-3,2) gives result as -4"
    },
    Sqrt: {
      description: "Returns the square root of the number.",
      syntax: "Sqrt(number)",
      example: "Sqrt(4) gives result as 2 ; Sqrt(9) gives result as 3"
    },
    If: {
      description: "Returns one of two values, depending on a logical condition.",
      syntax: "If(Boolean, generic, generic)",
      example: "If(8>7, 1, 0) gives result as 1 ; If(8>7, 'True', 'False') gives result as 'True'"
    },
    Len: {
      description: "Returns the number of characters in a specified text string.",
      syntax: "Len(string)",
      example: "Len('abc') gives result as 3 ; Len('zoho') gives result as 4"
    },
    Find: {
      description: "Returns the position of specified character/string starting from the first character in the input string.",
      syntax: "Find(string, search string, search_start_position)",
      example: "Find('greenery','r',1) gives result as 2 & Find('greenery','r',3) gives result as 7"
    },
    Dayofweek: {
      description: "Returns the day of the week for the given date.",
      syntax: "Dayofweek(date-time)",
      example: "Dayofweek(Newdate(2007,12,21,06,30,'AM')) gives result as Friday"
    },
    Dayofmonth: {
      description: "Returns the number corresponding to day of the month for the given date.",
      syntax: "Dayofmonth(date-time)",
      example: "Dayofmonth(Newdate(2009,05,19,11,30,'AM')) gives result as 19.0"
    },
    Dayofyear: {
      description: "Returns the number corresponding to day of the year for the given date.",
      syntax: "Dayofyear(date-time)",
      example: "Dayofyear(Newdate(2009,05,19,11,30,'AM')) gives result as 139.0"
    },
    Hour: {
      description: "Returns the hour (24-hour clock format) corresponding to the given time.",
      syntax: "Hour(date-time)",
      example: "Hour(Newdate(2009,05,19,11,30,'AM')) gives result as 11.0"
    },
    Minute: {
      description: "Returns the minute corresponding to the given time.",
      syntax: "Minute(date-time)",
      example: "Minute(Newdate(2009,05,19,11,30,'AM')) gives result as 30.0"
    },
    Month: {
      description: "Returns the number that corresponds with the month in the given date.",
      syntax: "Month(date-time)",
      example: "Month(Newdate(2009,05,19,11,30,'AM')) gives result as 5.0"
    },
    Year: {
      description: "Returns the year for the given date.",
      syntax: "Year(date-time)",
      example: "Year(Newdate(2009,05,19,11,30,'AM')) gives result as 2009.0"
    },
    Weekday: {
      description: "Returns the day of the week (1-7) corresponding to the input date.",
      syntax: "Weekday(date-time)",
      example: "Weekday(Newdate(2009,05,19,11,30,'AM')) gives result as 3.0"
    },
    And: {
      description: "Returns 'true' if all expressions are true; returns 'false' if one or more expressions is false.",
      syntax: "And(Boolean, Boolean,...)",
      example: "And( 2>1 ,5>3 ,7<8 ) gives result as true ; And( 2>1 ,5>3 ,7>8 ) gives result as false"
    },
    Or: {
      description: "Returns 'true' if any one expression is true. Returns false if all expressions are false.",
      syntax: "Or(Boolean, Boolean,...)",
      example: "Or( 2>1 ,3>5 ,7>8 ) gives result as true ; Or( 1>2, 3>5 ,7>8 ) gives result as false"
    },
    Not: {
      description: "Returns the logical negation of the given expression.",
      syntax: "Not(Boolean)",
      example: "Not(true) gives result as false ; Not(false) gives result as true"
    },
    Concat: {
      description: "Returns the concatenation of all the strings.",
      syntax: "Concat(string, string,...)",
      example: "Concat( 'Zoho' , ' ' , 'CRM' ) gives result as Zoho CRM"
    },
    CaseInsensitiveEquals: {
      description: "Compares two strings in case insensitive manner.",
      syntax: "CaseInsensitiveEquals(string,string)",
      example: "CaseInsensitiveEquals('asdf','AsDf') gives result as true; CaseInsensitiveEquals('asdf','AsDg') gives result as false"
    },
    Contains: {
      description: "Returns true if string 1 is found in the string 2.",
      syntax: "Contains(string, search string)",
      example: "Contains( 'abcdef' , 'cd' ) gives result as true ; Contains( 'abcdef' , 'kl' ) gives result as false"
    },
    Startswith: {
      description: "Returns 'true' if the string begins with the search string.",
      syntax: "Startswith(string, search string)",
      example: "Startswith( 'abcdef' , 'cd' ) gives result as false ; Startswith( 'abcdef' , 'ab' ) gives result as true"
    },
    Endswith: {
      description: "Returns 'true' if the string ends with the search string.",
      syntax: "Endswith(string, search string)",
      example: "Endswith( 'abcdef' , 'cd' ) gives result as false ; Endswith( 'abcdef' , 'ef' ) gives result as true"
    },
    Lower: {
      description: "Converts all letters in the input string to lowercase.",
      syntax: "Lower(string)",
      example: "Lower('APPLES') gives result as 'apples' ; Lower('Apples') gives result as 'apples'"
    },
    Upper: {
      description: "Converts all letters in the input string to uppercase.",
      syntax: "Upper(string)",
      example: "Upper('apples') gives result as 'APPLES' ; Upper('AppLes') gives result as 'APPLES'"
    },
    Trim: {
      description: "Removes the spaces before and after the string.",
      syntax: "Trim(string)",
      example: "Trim( 'ZohoCRM' ) gives result as ZohoCRM"
    },
    Substring: {
      description: "Returns a portion of the input string.",
      syntax: "Substring(string, number, number)",
      example: "Substring( 'abcdefg' , 4 ,7 ) gives result as 'defg'"
    },
    Tostring: {
      description: "Converts any argument to the string data type.",
      syntax: "Tostring(generic)",
      example: "Tostring(3.8) gives result as '3.8'"
    },
    Replace: {
      description: "Replaces each occurrence of the search string in the input string with the replacement string.",
      syntax: "Replace(string, search string, replacement string)",
      example: "Replace( 'abcdefg' , 'abc' , 'xyz' ) gives result as 'xyzdefg'"
    },
    IsEmpty: {
      description: "Checks whether the value is empty or not.",
      syntax: "IsEmpty(generic)",
      example: "IsEmpty('') gives result as true; IsEmpty('asdf') gives result as false; IsEmpty(${Customer.Score}) gives result as true if Score is not entered."
    },
    Newdate: {
      description: "Creates a date from the year, month, day, and time.",
      syntax: "Newdate(year, month, day, hour, minute, 'AM/PM')",
      example: "Newdate( 2007 ,12 ,21 ,06 ,30 ,'AM' ) gives result as 21/12/2007 06:30 AM"
    },
    Datepart: {
      description: "Returns just the date for the DateTime expression.",
      syntax: "Datepart(date-time)",
      example: "Datepart( Newdate( 2007 ,12 ,21 ,06 ,30 ,'AM' ) ) gives result as 21/12/2007"
    },
    Timepart: {
      description: "Returns just the time for the DateTime expression.",
      syntax: "Timepart(date-time)",
      example: "Timepart( Newdate( 2007 ,12 ,21 ,06 ,30 ,'AM') ) gives result as 06.30 AM"
    },
    Adddate: {
      description: "Returns the date you get by adding n (year/day/month/hour/min) to the given date.",
      syntax: "Adddate(date-time, number, 'YEAR/DAY/MONTH/HOUR/MINUTE')",
      example: "Adddate( Newdate( 2007 ,12 ,21 ,06 ,30 ,'AM' ) ,2 ,'YEAR' ) gives result as 21/12/2009 06:30 AM"
    },
    Subdate: {
      description: "Returns the date you get by subtracting n (year/day/month/hour/min) from the given date.",
      syntax: "Subdate(date-time, number, 'YEAR/DAY/MONTH/HOUR/MINUTE')",
      example: "Subdate( Newdate( 2007 ,12 ,21 ,06 ,30 ,'AM' ) ,2 ,'YEAR' ) gives result as 21/12/2005 06:30 AM"
    },
    Now: {
      description: "Returns the current date and time.",
      syntax: "Now()",
      example: "Now() gives result as 01/10/2024 03:15 PM"
    },
    Datecomp: {
      description: "Returns the difference in time (in minutes) between two days.",
      syntax: "Datecomp(date-time, date-time)",
      example: "Datecomp(Newdate(2009 ,05 ,19 ,11 ,30 ,'AM') , Newdate(2009 ,05 ,19 ,11 ,00 ,'AM') ) gives result as 30"
    },
    DateBetween: {
      description: "Returns the time between two dates where unit can be: years, months, weeks, days, hours, minutes.",
      syntax: "DateBetween(date-time,date-time,string)",
      example: "DateBetween(Newdate(2022,02,10,11,30,'AM'), Newdate(2023,02,19,11,30,'AM'),'years') gives result as 1"
    },
    FromTimestamp: {
      description: "Creates date time from timestamp.",
      syntax: "FromTimestamp(number)",
      example: "FromTimestamp(1581066895) gives result as Feb 7, 2020 02:44 PM"
    },
    Timestamp: {
      description: "Returns timestamp of the value.",
      syntax: "Timestamp(date-time)",
      example: "Timestamp(Newdate(2022,02,10,11,30,'AM')) gives result as 1,171,107,000"
    },
    Tonumber: {
      description: "Converts any string that consists of only numbers into a numeric variable.",
      syntax: "Tonumber(generic)",
      example: "Tonumber( '1000' ) gives result as 1000"
    },
    IsPositive: {
      description: "Checks if the number is positive.",
      syntax: "IsPositive(number)",
      example: "IsPositive(-345) gives result as false"
    },
    IsNegative: {
      description: "Checks if the number is negative.",
      syntax: "IsNegative(number)",
      example: "IsNegative(-345) gives result as true"
    },
  };

  let handleCategorySelect = (category) => {
    setFunctionCategory(category);
    setShowFunctionDropdown(false);
  };

  let filteredFunctions = functionCategory === 'All Functions'
    ? allFunctions
    : functionCategory === 'String'
      ? stringFunctions
      : functionCategory === 'Numeric'
        ? numericFunctions
        : functionCategory === 'DateTime'
          ? datetimeFunctions
          : booleanFunctions;

  let handleInsertFunction = (func) => {
    setSelectedFunction(func);
    setHoveredFunction(null);
  };

  let handleInsertField = (field) => {
    setSelectedField(field);
    setHoveredField(null);
  };

  let handleInsertOperator = (op) => {
    setSelectedOperator(op);
    setHoveredOperator(null);
  };

  // let handleConfirmInsertFunction = () => {
  //   if (selectedFunction) {
  //     setFormulaExpression((prev) => `${prev} ${selectedFunction}()`.trim());
  //     setSelectedFunction('');
  //   }
  // };

  let handleConfirmInsertFunction = () => {
    if (selectedFunction) {
      let newFormula = (`${formulaExpression.substring(0, selectionRange.start)}${selectedFunction}()${formulaExpression.substring(selectionRange.end)}`).trim();
      setFormulaExpression(newFormula);
      setSelectedFunction('');
    }
  };


  let handleConfirmInsertField = () => {
    if (selectedField) {
      let newField = `\${${(section?.subform ? section?.section_name : moduleStoreData?.plural_form) || 'Untitled'}.${selectedField}}`;
      setFormulaExpression((prev) => {
        return (`${prev.substring(0, selectionRange.start)}${newField}${prev.substring(selectionRange.end)}`).trim();
      });
      setSelectedField('');
    }
  };

  let handleConfirmInsertOperator = () => {
    if (selectedOperator) {
      let operatorSymbol = selectedOperator.split(' ')[0];
      setFormulaExpression((prev) => {
        return (`${prev.substring(0, selectionRange.start)}${operatorSymbol}${prev.substring(selectionRange.end)}`).trim();
      });
      setSelectedOperator('');
    }
  };


  // let tokens = formulaExpression.trim().split(/\s+/).filter(token => token.length > 0);
  //   console.log(tokens)
  //   let fieldCount = 0;
  //   let operatorCount = 0;

  //   let validOperators = ['+', '-', '*', '/', '%', '^', '!=', '==', '<', '>', '<=', '>='];

  //   tokens.forEach(token => {
  //     if (token.startsWith('${' + `${(section?.subform ? section?.section_name : moduleStoreData?.plural_form) || 'Untitled'}.`)) {
  //       fieldCount++;
  //     } else if (validOperators.includes(token)) {
  //       operatorCount++;
  //     }
  //   });

  //   let expressionError = false;

  //   if (fieldCount < 2 || operatorCount < 1) {
  //     expressionError = true;
  //   } else if (operatorCount >= fieldCount) {
  //     expressionError = true;
  //   }


  let checkSyntax = () => {
    console.log(formulaExpression.trim());
    // setSyntaxError(true ? "Expression Error" : "No Errors");
  };


  let handleOutsideClick = (event) => {
    if (returnTypeRef.current && !returnTypeRef.current.contains(event.target)) {
      setShowReturnType(false);
    }
    if (decimalPlacesRef.current && !decimalPlacesRef.current.contains(event.target)) {
      setShowDecimalPlaces(false);
    }
    if (functionDropdownRef.current && !functionDropdownRef.current.contains(event.target)) {
      setShowFunctionDropdown(false);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  let handleKeyDown = (event, dropdownType) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      if (dropdownType === 'decimal') {
        setShowDecimalPlaces(true);
      } else if (dropdownType === 'function') {
        setShowFunctionDropdown(true);
      }
    }
  };

  const submit = () => {
    if (labelError) {
      return;
    }
    editPropertySubmit({
      field_label: fieldLabel,
      return_type: returnType,
      decimal_place: decimalPlaces,
      formula_expression: formulaExpression
    });
    handleClose();
  };

  useEffect(() => {
    if (newAggregate) {
      editPropertySubmit({
        field_label: fieldLabel,
        return_type: returnType,
        decimal_place: decimalPlaces,
        formula_expression: formulaExpression
      });
    }
  }, [newAggregate, fieldLabel, returnType, decimalPlaces, formulaExpression]);

  return (
    newAggregate ?
      <>
        <BodyContent
          fieldLabel={fieldLabel}
          setFieldLabel={setFieldLabel}
          returnType={returnType}
          setReturnType={setReturnType}
          showReturnType={showReturnType}
          setShowReturnType={setShowReturnType}
          decimalPlaces={decimalPlaces}
          setDecimalPlaces={setDecimalPlaces}
          showDecimalPlaces={showDecimalPlaces}
          setShowDecimalPlaces={setShowDecimalPlaces}
          functionCategory={functionCategory}
          showFunctionDropdown={showFunctionDropdown}
          setShowFunctionDropdown={setShowFunctionDropdown}
          filteredFunctions={filteredFunctions}
          selectedFunction={selectedFunction}
          selectedField={selectedField}
          selectedOperator={selectedOperator}
          formulaExpression={formulaExpression}
          setFormulaExpression={setFormulaExpression}
          syntaxError={syntaxError}
          handleKeyDown={handleKeyDown}
          handleInsertFunction={handleInsertFunction}
          handleInsertField={handleInsertField}
          handleInsertOperator={handleInsertOperator}
          checkSyntax={checkSyntax}
          handleSelect={handleSelect}
          selectfields={selectfields}
          operators={operators}
          returnTypeRef={returnTypeRef}
          functionDropdownRef={functionDropdownRef}
          decimalPlacesRef={decimalPlacesRef}
          handleCategorySelect={handleCategorySelect}
          setHoveredFunction={setHoveredFunction}
          hoveredFunction={hoveredFunction}
          handleConfirmInsertFunction={handleConfirmInsertFunction}
          setHoveredField={setHoveredField}
          hoveredField={hoveredField}
          handleConfirmInsertField={handleConfirmInsertField}
          setHoveredOperator={setHoveredOperator}
          hoveredOperator={hoveredOperator}
          handleConfirmInsertOperator={handleConfirmInsertOperator}
          functionDetails={functionDetails}
          labelError={labelError}
          setLabelError={setLabelError}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          tooltipText={tooltipText}
          setTooltipText={setTooltipText}
          radioValue={radioValue}
          setRadioValue={setRadioValue}

        />
      </>
      :
      <>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Formula Properties</Modal.Title>
          </Modal.Header>
          <Modal.Body className="scrollable-modal-body">
            <BodyContent
              fieldLabel={fieldLabel}
              setFieldLabel={setFieldLabel}
              returnType={returnType}
              setReturnType={setReturnType}
              showReturnType={showReturnType}
              setShowReturnType={setShowReturnType}
              decimalPlaces={decimalPlaces}
              setDecimalPlaces={setDecimalPlaces}
              showDecimalPlaces={showDecimalPlaces}
              setShowDecimalPlaces={setShowDecimalPlaces}
              functionCategory={functionCategory}
              showFunctionDropdown={showFunctionDropdown}
              setShowFunctionDropdown={setShowFunctionDropdown}
              filteredFunctions={filteredFunctions}
              selectedFunction={selectedFunction}
              selectedField={selectedField}
              selectedOperator={selectedOperator}
              formulaExpression={formulaExpression}
              setFormulaExpression={setFormulaExpression}
              syntaxError={syntaxError}
              handleKeyDown={handleKeyDown}
              handleInsertFunction={handleInsertFunction}
              handleInsertField={handleInsertField}
              handleInsertOperator={handleInsertOperator}
              checkSyntax={checkSyntax}
              handleSelect={handleSelect}
              selectfields={selectfields}
              operators={operators}
              returnTypeRef={returnTypeRef}
              functionDropdownRef={functionDropdownRef}
              decimalPlacesRef={decimalPlacesRef}
              handleCategorySelect={handleCategorySelect}
              setHoveredFunction={setHoveredFunction}
              hoveredFunction={hoveredFunction}
              handleConfirmInsertFunction={handleConfirmInsertFunction}
              setHoveredField={setHoveredField}
              hoveredField={hoveredField}
              handleConfirmInsertField={handleConfirmInsertField}
              setHoveredOperator={setHoveredOperator}
              hoveredOperator={hoveredOperator}
              handleConfirmInsertOperator={handleConfirmInsertOperator}
              functionDetails={functionDetails}
              labelError={labelError}
              setLabelError={setLabelError}
              showTooltip={showTooltip}
              setShowTooltip={setShowTooltip}
              tooltipText={tooltipText}
              setTooltipText={setTooltipText}
              radioValue={radioValue}
              setRadioValue={setRadioValue}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel-button" variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button className="done-button" variant="primary" onClick={submit}>Done</Button>
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default CRMFormulaForm;
