import React, { useState } from 'react';   
import Modal from 'react-bootstrap/Modal'; 
import Form from 'react-bootstrap/Form';  
import Button from 'react-bootstrap/Button';  
import InputGroup from 'react-bootstrap/InputGroup';
import {handleFieldLabelUpdate} from '../../../Utils';
function ImageUploadPropertiesModal({ show, handleClose,item,editPropertySubmit }) {
  let fields = item?.extra_fields;
  const [field_label, setFieldLabel] = useState(fields?.field_label);
  const [maximum_image_allowed, setMaximumImageAllowed] = useState(fields?.maximum_image_allowed);
  const [showTooltip, setShowTooltip] = useState({ checked: fields?.show_tooltip?.checked, type: fields?.show_tooltip?.type, message: fields?.show_tooltip?.message});
  const [showPreview, setShowPreview] = useState(false);
  const [labelError, setLabelError] = useState('');

  const handleSubmit = () => {
    const formData = {
      field_label:field_label,
      maximum_image_allowed,
      show_tooltip: showTooltip
    };

    editPropertySubmit(formData);

    handleClose();
  };

  const handleCancel = () => {
    setFieldLabel('Image Upload');
    setMaximumImageAllowed(10);
    setShowTooltip({ checked: false, type: 'info', message: '' });
    handleClose();
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleFieldLabelChange = (e) => {
    handleFieldLabelUpdate(e.target.value, setFieldLabel, setLabelError);
  }
  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
      <Modal.Header closeButton>
        <Modal.Title>Image Upload Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Field Label</Form.Label>
            <Form.Control 
              type="text" 
              value={field_label} 
              onChange={handleFieldLabelChange}
            />
            {labelError && <div className="text-danger">{labelError}</div>}
          </Form.Group>

          <Form.Group controlId="formMaximumImageAllowed" className="mt-3">
            <Form.Label>Maximum Images Allowed</Form.Label>
            <InputGroup>
              <Form.Select
                value={maximum_image_allowed}
                onChange={(e) => setMaximumImageAllowed(e.target.value)}
                required
              >
                {[...Array(10).keys()].map((i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </Form.Select>
              <InputGroup.Text>Images</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip.checked}
              onChange={() => setShowTooltip({ ...showTooltip, checked: !showTooltip.checked })}
            />
          </Form.Group>

          {showTooltip.checked && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Message (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={showTooltip.message}
                  onChange={(e) => setShowTooltip({ ...showTooltip, message: e.target.value })}
                  placeholder="Enter Tooltip message"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={showTooltip.type === 'info'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'info' })}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showTooltip.type === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {showTooltip.message && <span className="ms-2">{showTooltip.message}</span>}
                    </div>
                  ) : (
                    <p>{showTooltip.message}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageUploadPropertiesModal;
