import React, { useEffect, useState } from 'react';
import { Utils } from '../../Utils';

const FillterData = ({ resetCriteria = false, setResetCriteria, title = "", onClickRemove, data = [], selectedDatas = null, onChangeCriteriaFilter }) => {
    let [stringOptions] = useState([
        {
            "value": "",
            "label": "None"
        },
        {
            "value": "IS",
            "label": "is"
        },
        {
            "value": "ISN'T",
            "label": "isn't"
        },
        {
            "value": "CONTAINS",
            "label": "Contains"
        },
        {
            "value": "DOESN'T MATCH",
            "label": "doesn't match"
        },
        {
            "value": "STARTS WITH",
            "label": "starts with"
        },
        {
            "value": "ENDS WITH",
            "label": "ends with"
        },
        {
            "value": "IS EMPTY",
            "label": "is empty"
        },
        {
            "value": "IS NOT EMPTY",
            "label": "is not empty"
        }
    ]);
    let [numberOptions] = useState([
        {
            "value": "",
            "label": "None"
        },
        {
            "value": "=",
            "label": "="
        },
        {
            "value": "!=",
            "label": "!="
        },
        {
            "value": "<",
            "label": "<"
        },
        {
            "value": "<=",
            "label": "<="
        },
        {
            "value": ">",
            "label": ">"
        },
        {
            "value": ">=",
            "label": ">="
        },
        {
            "value": "BETWEEN",
            "label": "between"
        },
        {
            "value": "NOT BETWEEN",
            "label": "not between"
        },
        {
            "value": "IS EMPTY",
            "label": "is empty"
        },
        {
            "value": "IS NOT EMPTY",
            "label": "is not empty"
        }
    ]
    );

    let [dateOptions] = useState([
        {
            "value": "",
            "label": "None"
        },
        {
            "value": "IS",
            "label": "is"
        },
        {
            "value": "ISN'T",
            "label": "isn't"
        },
        {
            "value": "IS BEFORE",
            "label": "is before"
        },
        {
            "value": "LTE",
            "label": "is after"
        },
        {
            "value": "BETWEEN",
            "label": "between"
        },
        {
            "value": "NOT BETWEEN",
            "label": "not between"
        },
        {
            "value": "TODAY",
            "label": "Today"
        },
        {
            "value": "TOMORROW",
            "label": "Tomorrow"
        },
        {
            "value": "STARTING TOMORROW",
            "label": "Starting tomorrow"
        },
        {
            "value": "YESTERDAY",
            "label": "Yesterday"
        },
        {
            "value": "TILL YESTERDAY",
            "label": "Till Yesterday"
        },
        {
            "value": "LAST MONTH",
            "label": "Last Month"
        },
        {
            "value": "CURRENT MONTH",
            "label": "Current Month"
        },
        {
            "value": "NEXT MONTH",
            "label": "Next Month"
        },
        {
            "value": "LAST WEEK",
            "label": "Last Week"
        },
        {
            "value": "CURRENT WEEK",
            "label": "Current Week"
        },
        {
            "value": "TILL YEAR",
            "label": "Till Year"
        },
        {
            "value": "CURRENT FY",
            "label": "Current FY"
        },
        {
            "value": "CURRENT FQ",
            "label": "Current FQ"
        },
        {
            "value": "LAST YEAR",
            "label": "Last Year"
        },
        {
            "value": "PREVIOUS FY",
            "label": "Previous FY"
        },
        {
            "value": "PREVIOUS FQ",
            "label": "Previous FQ"
        },
        {
            "value": "NEXT YEAR",
            "label": "Next Year"
        },
        {
            "value": "NEXT FY",
            "label": "Next FY"
        },
        {
            "value": "NEXT FQ",
            "label": "Next FQ"
        },
        {
            "value": "AGE IN DAYS",
            "label": "Age in Days"
        },
        {
            "value": "DUE IN DAYS",
            "label": "Due in Days"
        },
        {
            "value": "IS EMPTY",
            "label": "is empty"
        },
        {
            "value": "IS NOT EMPTY",
            "label": "is not empty"
        }
    ]
    );
    let [selectedLabel, setSelectedLabel] = useState(null);
    let [criteria, setCriteria] = useState(selectedDatas?.criteria || []); 
    let [relationships, setRelationships] = useState(selectedDatas?.relationships || []);
    let [relationshipPattern, setRelationshipPattern] = useState(selectedDatas?.relationship_pattern || "");
    let [editMode, setEditMode] = useState(false);

    useEffect(() => {
        onChangeCriteriaFilter({
            criteria,
            relationships,
            relationship_pattern : relationshipPattern
        });
    }, [criteria, relationships]);

    useEffect(() => {
        if(!criteria?.length || criteria?.length === 1) {
            setRelationships([]);
        }
    }, [criteria]);

    let initialData = () => {
        let array = [
            {
                id : 1,
                input_type: data?.[0]?.fields?.input_type,
                field_label: data?.[0]?.fields?.extra_fields?.field_label,
                operator: "",
                value_dropdown: "",
                value: ""
            }
        ]
        setCriteria(array);
        setRelationshipPattern(Utils.buildNestedPattern(array, relationships));
    }

    useEffect(() => {
        if(!selectedDatas?.is_enable_criteria && !selectedDatas?.criteria?.length) {
            initialData();
        }
    }, [selectedDatas?.is_enable_criteria]);

    useEffect(() => {
        if(resetCriteria) {
            setCriteria([]);
            setRelationships([]);
            setRelationshipPattern("");
            initialData();
            setResetCriteria(false);
        }
    }, [resetCriteria]);

    useEffect(() => {
        if (data?.length > 0 && !selectedDatas) {
            initialData();
        }
    }, [data]);

    useEffect(() => {
        if (data?.length > 0) {
            let field = data?.[0]?.fields;
            setSelectedLabel(field);
        }
    }, [data]);

    let onClickPlus = () => {
        let newItem = {
            id : criteria?.length + 1,
            input_type: selectedLabel?.input_type,
            field_label: selectedLabel?.extra_fields?.field_label,
            operator: "",
            value_dropdown: "",
            value: ""
        }
        let array = [...criteria, newItem];
        setCriteria(array);
        let updatedRelationships = [...relationships, "and"];
        let newPattern = `( ${relationshipPattern} and ${newItem?.id} )`;
        setRelationships(updatedRelationships);
        setRelationshipPattern(newPattern);
    }

    let onClickMinus = (id) => {
        let index = criteria.findIndex((c) => c?.id === id);
        let updatedCriteria = criteria.filter((c) => c?.id !== id)?.map((x, index) => ({...x, id: index + 1}));
        let updatedRelationships = relationships.filter((_, i) => i !== index - 1);
        setCriteria(updatedCriteria);
        setRelationships(updatedRelationships);
        setRelationshipPattern(Utils.buildNestedPattern(updatedCriteria, updatedRelationships));
    }

    let handleRelationshipChange = (index) => {
        let updatedRelationships = relationships.map((rel, i) => (i === index ? rel === "or" ? "and" : "or" : rel));
        setRelationships(updatedRelationships);
        setRelationshipPattern(Utils.buildNestedPattern(criteria, updatedRelationships));
    };

    let extractRelationships = (pattern) => {
        let validOperators = ["and", "or"];
        let tokens = pattern
            .replace(/\(/g, " ( ")
            .replace(/\)/g, " ) ")
            .split(/\s+/)
            .filter((token) => token);
        return tokens.filter((token) => validOperators?.includes(token));
    };

    let handlePatternChange = (e) => {
        setRelationshipPattern(e.target.value);
    };

    let toggleEditMode = () => {
        if (editMode) {
            let { isValid: isValidPattern, position: invalidPosition } = Utils.validatePattern(relationshipPattern, criteria.length);
            let { isValid: isValidOrder, position: orderPosition } = Utils.validateOrder(criteria, relationshipPattern);
            if (isValidPattern && isValidOrder) {
                let updatedRelationships = extractRelationships(relationshipPattern);
                if (updatedRelationships.length === criteria.length - 1) {
                    setRelationships(updatedRelationships);
                    setEditMode(false);
                } else {
                    alert("Invalid pattern: Relationships do not match the expected length.");
                }
            } else {
                if (!isValidPattern) {
                    alert(`Invalid pattern. Please check the pattern at position ${invalidPosition}.`);
                } else {
                    alert("Pattern order has been changed. Please maintain the original order of criteria.");
                }
            }
        } else {
            setEditMode(true);
        }
    };
    return (
        <>
            <div className="container-fluid p-0">
                <div className="card p-3 overflow-auto">
                    {title && (
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">{title}</h5>
                            <a className="text-danger text-decoration-none" onClick={() => onClickRemove(false)}>Remove</a>
                        </div>
                    )}
                    {criteria?.length > 0 && (
                        criteria?.map((x, index) => (
                            <React.Fragment key={index}>
                                <div className="row mb-2 align-items-center">
                                    <div className="col-12 col-sm-1 criteria-number">{index + 1}</div>
                                    <div className="col-12 col-sm-3">
                                        <select className="form-select" value={x?.field_label || ""}
                                            onChange={(e) => {
                                                let updatedList = [...criteria];
                                                let value = e.target.value;
                                                let inputType = data?.find(item => item?.fields?.extra_fields?.field_label === value)?.fields?.input_type;
                                                updatedList[index] = { 
                                                    ...updatedList[index], 
                                                    field_label: value, 
                                                    input_type: inputType 
                                                };
                                                setCriteria(updatedList);
                                            }}>
                                            <option value="">Select a Field</option>
                                            {data?.length > 0 && (
                                                data.map((item, i) => (
                                                    <option key={'field' + i} value={item.fields.extra_fields.field_label || ""}>
                                                        {item.fields.extra_fields.field_label}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-3 mt-2 mt-sm-0">
                                        <select className="form-select" value={x?.operator || ""}
                                            onChange={(e) => {
                                                let updatedList = [...criteria];
                                                updatedList[index] = { ...updatedList[index], operator: e.target.value };
                                                setCriteria(updatedList);
                                            }}>
                                            {data?.length > 0 && (() => {
                                                let isDataField = data?.find((item) => item?.fields?.extra_fields?.field_label === x?.field_label)?.fields;
                                                if (isDataField?.input_type === "user" ? isDataField?.extra_fields?.user_type === "single_user" : Utils.stringInputTypes?.includes(isDataField?.input_type)) {
                                                    return (
                                                        stringOptions.map((option, i) => (
                                                            <option key={'value' + i} value={option?.value || ""}>
                                                                {option.label}
                                                            </option>
                                                        ))
                                                    )
                                                } else if (Utils.numberInputTypes?.includes(isDataField?.input_type)) {
                                                    return (
                                                        numberOptions.map((option, i) => (
                                                            <option key={'value' + i} value={option?.value || ""}>
                                                                {option.label}
                                                            </option>
                                                        ))
                                                    )
                                                } else if (Utils.dateInputTypes?.includes(isDataField?.input_type)) {
                                                    return (
                                                        dateOptions.map((option, i) => (
                                                            <option key={'value' + i} value={option?.value || ""}>
                                                                {option.label}
                                                            </option>
                                                        ))
                                                    )
                                                }
                                            })()}

                                        </select>
                                    </div>
                                    {/* {(() => {
                                        let input_type = data?.find((item) => item?.fields?.extra_fields?.field_label === x?.field_label)?.fields?.input_type;
                                        if (["number", "date"]?.includes(input_type)) {
                                            return (
                                                <div className="col-12 col-sm-3 mt-2 mt-sm-0">
                                                    <select className="form-select" value={x?.value_dropdown}
                                                        onChange={(e) => {
                                                            let updatedList = [...criteria];
                                                            updatedList[index].value_dropdown = e.target.value;
                                                            setCriteria(updatedList);
                                                        }}>
                                                        <option selected>Value</option>
                                                    </select>
                                                </div>
                                            )
                                        }
                                    })()} */}
                                    {(() => {
                                        let input_type = data?.find((item) => item?.fields?.extra_fields?.field_label === x?.field_label)?.fields?.input_type;
                                        let checkNumbers = ["number", "decimal", "currency"]?.includes(input_type);
                                        let checkDate = ["date"]?.includes(input_type);
                                        return (
                                            <div className="col-12 col-sm-3 mt-2 mt-sm-0">
                                                {(x?.operator === "BETWEEN" || x?.operator === "NOT BETWEEN") ?
                                                    <>
                                                        <div className='form-floating mb-3'>
                                                            <input id={'value1' + index} type={checkDate ? "datetime-local" : "number"} className="form-control" value={x?.value1 || ""}
                                                                onChange={(e) => {
                                                                    let updatedList = [...criteria];
                                                                    updatedList[index] = { ...updatedList[index], value1: e.target.value };
                                                                    setCriteria(updatedList);
                                                                }}/>
                                                            <label htmlFor={'value1' + index}>FROM:</label>
                                                        </div>
                                                        <div className='form-floating'>
                                                            <input id={'value2' + index} type={checkDate ? "datetime-local" : "number"} className="form-control" value={x?.value2 || ""}
                                                                onChange={(e) => {
                                                                    let updatedList = [...criteria];
                                                                    updatedList[index] = { ...updatedList[index], value2: e.target.value };
                                                                    setCriteria(updatedList);
                                                                }}/>
                                                            <label htmlFor={'value2' + index}>TO:</label>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <input type={checkDate ? "datetime-local" : (checkNumbers ? "number" : "text")} className="form-control" value={x?.value1 || ""}
                                                            onChange={(e) => {
                                                                let updatedList = [...criteria];
                                                                updatedList[index] = { ...updatedList[index], value1: e.target.value };
                                                                setCriteria(updatedList);
                                                            }}/>
                                                    </>
                                                }
                                            </div>
                                        )
                                    })()}
                                    <div className="col-12 col-sm-1 mt-2 mt-sm-0 d-flex justify-content-between">
                                        {criteria?.length === 1 || index === criteria?.length - 1 ? (
                                            <>
                                                {criteria?.length !== 1 && (
                                                    <button className="btn btn-outline-danger rounded-pill me-1" onClick={() => onClickMinus(x?.id)}>
                                                        <i className="fa-solid fa-minus"></i>
                                                    </button>
                                                )}
                                                <button className="btn btn-outline-primary rounded-pill" onClick={onClickPlus}>
                                                    <i className="fa-solid fa-plus"></i>
                                                </button>
                                            </>
                                        ) : (
                                            <button className="btn btn-outline-danger rounded-pill" onClick={() => onClickMinus(x?.id)}>
                                                <i className="fa-solid fa-minus"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                                {
                                    index !== relationships?.length && (
                                        <div className="row mb-2">
                                            <div className="col-12 logic-symbol" onClick={() => handleRelationshipChange(index)}>{relationships?.[index]}</div>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        ))
                    )}
                    {criteria?.length > 1 && (
                        <>
                            <div className="row mt-3">
                                <div className="col-md-12 criteria-pattern">
                                    Criteria Pattern: {editMode ? (
                                        <input
                                            type="text"
                                            value={relationshipPattern || ""}
                                            onChange={handlePatternChange}
                                        />
                                    ) : (
                                        <span>{relationshipPattern}</span>
                                    )}
                                    <a className="ms-2 text-primary text-decoration-none" onClick={toggleEditMode}>{editMode ? "Save Pattern" : "Edit Pattern"}</a>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default FillterData;
