import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRoleStart, deleteRoleStart, deleteToTransferRoleStart, getRoleStart, updateRoleStart } from "../../../Store/Slices/roleSlice";
import Loader from "../../../Components/Loader";
import { getItem } from '../../../Services/localService';
import { useNavigate } from "react-router-dom";
import { Utils } from "../../../Utils";

const RolesAndSharing = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let user = getItem('user');
    let allRoles = useSelector((state) => state?.role);
    let allUsers = useSelector((state) => state?.user);
    // states
    const [showTreeNode, setShowTreeNode] = useState(true);
    const [showRolesList, setShowRolesList] = useState(true);
    const [addEditRoleSection, setAddEditRoleSection] = useState(false);
    const [deleteRoleSection, setDeleteRoleSection] = useState(false);
    const [showDetailsView, setShowDetailsView] = useState(false);
    const [roleData, setRoleData] = useState({
        role_name: '',
        reporting_to: {
            name: '',
            id: ''
        },
        share_with_peers: false,
        description: ''
    })
    const [deleteRoleData, setDeleteRoleData] = useState({
        id: '',
        name: ''
    })
    const [transferRoleData, setTransferRoleData] = useState({
        id: '',
        name: ''
    })
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState({
        id: '',
        name: ''
    });
    let [formattedRole, setFormattedRole] = useState([]);
    let [formattedModalRole, setFormattedModalRole] = useState([]);

    // nested tree works::start
    function getNestedStructure(array) {
        const idMap = new Map();
        array.forEach(item => {
            idMap.set(item._id, { ...item, children: [] });
        });
        const result = [];
        array.forEach(item => {
            if (item.reporting_to !== null && idMap.has(item.reporting_to.id)) {
                const parent = idMap.get(item.reporting_to.id);
                parent.children.push(idMap.get(item._id));
            } else {
                if (item.reporting_to === null) {
                    idMap.get(item._id)['primary'] = true
                    result.push(idMap.get(item._id));
                }
            }
        });

        return result;
    }

    function getModalNestedStructure(array, roleId) {
        const idMap = new Map();

        array.forEach(item => {
            idMap.set(item._id, { ...item, children: [] });
        });
        const result = [];
        let ids = [roleId]
        console.log('array data?', array)
        array.forEach(item => {
            if (item.reporting_to !== null && idMap.has(item.reporting_to.id)) {

                if (item._id !== roleId && item.reporting_to.id !== roleId) {
                    const parent = idMap.get(item.reporting_to.id);
                    parent.children.push(idMap.get(item._id));
                }

                // new try::start
                // if (item.reporting_to.id === roleId) {
                //     if (!ids.includes(item._id)) {
                //         console.log('2->', ids)
                //         console.log('2:', item._id)
                //         ids.push(item._id)
                //     }
                // }
                // if (ids.includes(item._id)) {
                //     if (!ids.includes(item._id)) {
                //         console.log('3->', ids)
                //         console.log('3:', item._id)
                //         ids.push(item._id)
                //     }
                // }
                // if (ids.includes(item._id)) {
                //     if (!ids.includes(item._d)) {
                //         console.log('4->', ids)
                //         console.log('4:', item._id)
                //         ids.push(item._id)
                //     }
                // }

                // if (ids.includes(item._id)) {
                //     const parent = idMap.get(item.reporting_to.id);
                //     parent['disaled'] = true
                //     let obj = idMap.get(item._id)
                //     obj['disabled'] = true
                //     parent.children.push(obj);
                // }
                // else {
                //     const parent = idMap.get(item.reporting_to.id);
                //     let obj = idMap.get(item._id)
                //     parent.children.push(obj);
                // }
                // new try::end
            } else {
                if (item.reporting_to === null) {
                    idMap.get(item._id)['primary'] = true
                    result.push(idMap.get(item._id));
                }
            }
        });
        console.log('ids:', ids)

        return result;
    }

    useEffect(() => {
        if (allRoles?.roleData?.length) {
            let response = getNestedStructure(allRoles?.roleData);
            setFormattedRole(response);
            setFormattedModalRole(response);
        }
    }, [allRoles.roleData])
    // nested tree works::end

    useEffect(() => {
        if (allRoles?.createStatus) {
            dispatch(getRoleStart());
            setSelectedRoleId((prev) => ({
                ...prev,
                name: allRoles?.data?.role_name,
                id: allRoles?.data?._id
            }))
            setShowRolesList(false);
            setAddEditRoleSection(false);
            setDeleteRoleSection(false);
            setShowDetailsView(true);
        }
        if (allRoles?.updateStatus) {
            dispatch(getRoleStart());
            setIsEdit(true);
            setSelectedRoleId((prev) => ({
                ...prev,
                name: allRoles?.data?.role_name,
                id: allRoles?.data?._id
            }))
            setShowRolesList(false);
            setAddEditRoleSection(false);
            setDeleteRoleSection(false);
            setShowDetailsView(true);
        }
        debugger
        if (allRoles?.deleteTransferStatus) {
            showRoleList();
            // dispatch(deleteRoleStart(deleteRoleData.id));
        }
        // if (allRoles?.deleteStatus) {
        //     dispatch(getRoleStart());
        //     showRoleList();
        // }
        // , allRoles.deleteStatus
    }, [allRoles.createStatus, allRoles.updateStatus, allRoles.deleteTransferStatus])

    // functions
    const resetState = () => {
        setRoleData((prev) => ({
            ...prev,
            role_name: '',
            reporting_to: {
                name: '',
                id: ''
            },
            share_with_peers: false,
            description: ''
        }));
    }

    const expandseAll = () => {
        setShowTreeNode(true);
    }

    const collapseAll = () => {
        setShowTreeNode(false);
    }

    const showUpdatedRoleList = () => {
        dispatch(getRoleStart());
        resetState();
        setShowRolesList(true);
        setAddEditRoleSection(false);
        setDeleteRoleSection(false);
        setShowDetailsView(false);
    }

    const showRoleList = () => {
        dispatch(getRoleStart());
        setIsEdit(false);
        setSelectedRoleId((prev) => ({
            ...prev,
            name: '',
            id: ''
        }))
        setDeleteRoleData((prev) => ({
            ...prev,
            name: '',
            id: ''
        }))
        setTransferRoleData((prev) => ({
            ...prev,
            name: '',
            id: ''
        }))
        resetState();
        setShowRolesList(true);
        setAddEditRoleSection(false);
        setDeleteRoleSection(false);
        setShowDetailsView(false);
    }

    const newRole = () => {
        setShowRolesList(false);
        setAddEditRoleSection(true);
        setDeleteRoleSection(false);
        setShowDetailsView(false);
    }

    const editRole = (node) => {
        setIsEdit(true)
        // setSelectedRoleId(node.id)
        setSelectedRoleId((prev) => ({
            ...prev,
            name: node.role_name,
            id: node._id
        }))
        debugger
        let selectedData = allRoles?.roleData?.filter((item) => item._id === node._id)?.[0];
        console.log('selected data?', selectedData)
        setRoleData((prev) => ({
            ...prev,
            role_name: selectedData.role_name,
            reporting_to: {
                name: selectedData.reporting_to.name,
                id: selectedData.reporting_to.id
            },
            share_with_peers: selectedData.share_with_peers,
            description: selectedData.description
        }))

        setShowRolesList(false);
        setAddEditRoleSection(true);
        setDeleteRoleSection(false);
        setShowDetailsView(false);
    }

    const editRoleFromView = () => {
        setIsEdit(true)
        let selectedData = allRoles?.roleData?.filter((item) => item._id === selectedRoleId.id)?.[0];

        setRoleData((prev) => ({
            ...prev,
            role_name: selectedData.role_name,
            reporting_to: {
                name: selectedData.reporting_to.name,
                id: selectedData.reporting_to.id
            },
            share_with_peers: selectedData.share_with_peers,
            description: selectedData.description
        }))

        setShowRolesList(false);
        setAddEditRoleSection(true);
        setDeleteRoleSection(false);
        setShowDetailsView(false);
    }

    const deleteRole = () => {
        setShowRolesList(false);
        setAddEditRoleSection(false);
        setDeleteRoleSection(true);
        setShowDetailsView(false);
    }

    const deleteRoleFromView = () => {
        setDeleteRoleData((prev) => ({
            ...prev,
            name: selectedRoleId.name,
            id: selectedRoleId.id
        }))
        if (allRoles?.roleData?.length) {
            let response = getModalNestedStructure(allRoles?.roleData, selectedRoleId.id);
            setFormattedModalRole(response);
        }
        setShowRolesList(false);
        setAddEditRoleSection(false);
        setDeleteRoleSection(true);
        setShowDetailsView(false);
    }

    const transferAndDelete = () => {
        let payload = {
            oldRoleId: deleteRoleData.id,
            newRoleId: transferRoleData.id
        }
        dispatch(deleteToTransferRoleStart({ payload: payload }))
    }

    const showDetails = () => {
        let obj = roleData
        if (roleData.reporting_to.name === '' && roleData.reporting_to.id === '') {
            const { reporting_to, ...rest } = roleData
            obj = rest
        }
        if (isEdit) {
            dispatch(updateRoleStart({
                id: selectedRoleId.id, payload: obj
            }))
        } else {
            dispatch(createRoleStart(obj))
        }
    }

    const formHandler = (e) => {
        try {
            setRoleData((prev) => ({
                ...prev,
                [e?.target?.name]: e?.target?.name === 'share_with_peers' ? e?.target?.checked : e?.target?.value
            }))
        } catch (err) {
            throw err;
        }
    }

    // Data sharing tab 
    const [dataShareView, SetDataShareView] = useState(true);
    const [editSharePermission, SetEditSharePermission] = useState(false);

    const showDataShareView = () => {
        SetDataShareView(true);
        SetEditSharePermission(false);
    }

    const showEditPermission = () => {
        SetDataShareView(false);
        SetEditSharePermission(true);
    }

    const updateRoleData = (node) => {
        if (deleteRoleData?.id) {
            setTransferRoleData((prev) => ({
                ...prev,
                name: node.role_name,
                id: node._id
            }))
        } else {
            setRoleData((prev) => ({
                ...prev,
                reporting_to: {
                    name: node.role_name,
                    id: node._id
                }
            }))
        }
    }

    const TreeNode = ({ node }) => {
        const [isExpanded, setIsExpanded] = useState(true);
        const [showTreeNode, setShowTreeNode] = useState(true);
        const handleToggle = (node) => {
            setSelectedRoleId((prev) => ({
                ...prev,
                name: node.role_name,
                id: node._id
            }))
            let selectedData = allRoles?.roleData?.filter((item) => item._id === node._id)?.[0];
            setRoleData((prev) => ({
                ...prev,
                role_name: selectedData.role_name,
                reporting_to: selectedData.reporting_to === null ? null : {
                    name: selectedData.reporting_to.name,
                    id: selectedData.reporting_to.id
                },
                share_with_peers: selectedData.share_with_peers,
                description: selectedData.description
            }))
            setShowRolesList(false);
            setAddEditRoleSection(false);
            setDeleteRoleSection(false);
            setShowDetailsView(true);
        };

        const handleDeleteRole = (node) => {
            setDeleteRoleData((prev) => ({
                ...prev,
                name: node.role_name,
                id: node._id
            }))
            if (allRoles?.roleData?.length) {
                let response = getModalNestedStructure(allRoles?.roleData, node._id);
                console.log('RESPONSEEEE=====>', response)
                setFormattedModalRole(response);
            }
            deleteRole();
        }

        console.log('formated role?', formattedRole)
        console.log('formated modal role?', formattedModalRole)
        return (
            <React.Fragment>
                <ul className="nested-list">
                    <li className="nested-item" style={{ cursor: "pointer" }}>
                        <div className="d-inline-block ps-4 role-name-box">
                            <span className="fs-14" onClick={() => handleToggle(node)}>{node.role_name}</span>
                            <span className="label action-btns border-0">
                                <span className="act-btn add-btn rounded-pill cursor-pointer" role="button" title="Create Subordinate Role"
                                    onClick={() => {
                                        newRole();
                                        setRoleData((prev) => ({
                                            ...prev,
                                            reporting_to: {
                                                name: node.role_name,
                                                id: node._id
                                            }
                                        }))
                                    }}
                                >
                                    <i className="fas fa-plus"></i>
                                </span>
                                <span className="act-btn edit-btn rounded-pill cursor-pointer" role="button" title="Edit"
                                    onClick={() => {
                                        editRole(node)
                                    }}
                                >
                                    <i className="fas fa-pencil"></i>
                                </span>
                                {!node?.primary &&
                                    <span className="act-btn delete-btn rounded-pill cursor-pointer" role="button" title="Delete"
                                        onClick={() => handleDeleteRole(node)
                                            //      {
                                            //     deleteRole();
                                            //     setDeleteRoleData((prev) => ({
                                            //         ...prev,
                                            //         name: node.role_name,
                                            //         id: node._id
                                            //     }))
                                            // }
                                        }
                                    >
                                        <i className="fas fa-trash"></i>
                                    </span>
                                }
                            </span>
                        </div>
                        {isExpanded && node.children && (
                            <>
                                <details open={showTreeNode}>
                                    <summary></summary>
                                    {node.children.map((child) => (
                                        <TreeNode key={child._id} node={child} />
                                    ))}
                                </details>
                            </>
                        )}
                    </li>
                </ul>
            </React.Fragment>
        );
    };

    const RoleTreeNode = ({ node }) => {
        const [isExpanded, setIsExpanded] = useState(true);
        const [showTreeNode, setShowTreeNode] = useState(true);
        // if (node?.disabled)
        //     console.log('node dis?', node)
        return (
            <React.Fragment>
                <ul className="nested-list">
                    <li className="nested-item">
                        <div className="d-inline-block ps-4 role-name-box">
                            <span className="fs-14 cursor-pointer"
                                style={node?.disabled && { pointerEvents: 'none', cursor: 'not-allowed', opacity: '.4' }}
                                onClick={() =>
                                    updateRoleData(node)
                                }
                                data-bs-dismiss="modal">{node.role_name}</span>
                        </div>
                        {isExpanded && node.children && (
                            <>
                                <details open={showTreeNode}>
                                    <summary></summary>
                                    {node.children.map((child) => (
                                        <RoleTreeNode key={child._id} node={child} />
                                    ))}
                                </details>
                            </>
                        )}
                    </li>
                </ul>
            </React.Fragment>
        );
    };

    return (
        <>
            <React.Fragment>
                <Loader isVisible={allRoles?.isLoading} />
                <div className="g-settings security-ctrl role-sharing">
                    <div className="card rounded-4 border-0 p-0">
                        {/* Tabs Header Section */}
                        <div className="card-header py-0 fs-14 bg-white rounded-top-4">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item px-3" role="presentation">
                                    <button className="nav-link text-primary position-relative px-0 active" id="pills-tab-1"
                                        data-bs-toggle="pill" data-bs-target="#pills-tab1" type="button" role="tab"
                                        aria-controls="pills-tab1" aria-selected="true">Roles</button>
                                </li>
                                <li className="nav-item px-3" role="presentation">
                                    <button className="nav-link text-primary position-relative px-0" id="pills-tab-2"
                                        data-bs-toggle="pill" data-bs-target="#pills-tab2" type="button" role="tab"
                                        aria-controls="pills-tab2" aria-selected="false">Data Sharing Settings </button>
                                </li>
                            </ul>
                        </div>
                        {/* // Tabs Header Section END // */}

                        <div className="card-body overflow-y-auto pt-0">
                            <div className="tab-content px-3" id="pills-tabContent">
                                {/* Roles Tab */}
                                <div className="tab-pane fade show active" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab-1">
                                    {/* Roles List section */}
                                    <div className={`roles ${showRolesList ? 'd-block' : 'd-none'}`}>
                                        <div className="pt-4">
                                            <h5 className="fw-semibold">Roles</h5>
                                            <p className="w-75">
                                                This page will allow you to define how you share the data among users based
                                                on your organization's role hierarchy. For more information, refer to online help.
                                            </p>
                                        </div>
                                        <div className="btn-group pb-3">
                                            <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3"
                                                onClick={newRole}>
                                                New Role
                                            </button>
                                            <div className="d-flex align-items-center ms-1">
                                                <button type="button" className="btn btn-link text-decoration-none me-0"
                                                    onClick={expandseAll}>
                                                    Expand All
                                                </button>
                                                <span className="vr align-self-center"></span>
                                                <button type="button" className="btn btn-link text-decoration-none"
                                                    onClick={collapseAll}>
                                                    Collapse All
                                                </button>
                                            </div>
                                        </div>

                                        {/* dynamic tree view::start */}
                                        <div>
                                            <ul className="tree mt-3">
                                                <li className="parent">
                                                    <div className="d-inline-block ps-4 role-name-box">{user?.organization?.organizationName}</div>
                                                    <details open={showTreeNode} className="details">
                                                        <summary></summary>
                                                        {formattedRole?.length > 0 && formattedRole.map((node) => (
                                                            // {treeData.map((node) => (
                                                            <TreeNode key={node._id} node={node} />
                                                        ))}
                                                    </details>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* dynamic tree view::end */}

                                        {/* Tree View */}
                                        {/* <ul className="tree mt-3 d-none">
                                            <li className="parent">
                                                <div className="d-inline-block ps-4 role-name-box">{user?.organization?.organizationName}</div>
                                                <details open={showTreeNode} className="details">
                                                    <summary></summary>
                                                    <ul className="nested-list">
                                                        {allRoles?.roleData?.filter((role) => role?.reporting_to === null)?.map((data) => (
                                                            <li className="nested-item">
                                                                <div className="d-inline-block ps-4 role-name-box">
                                                                    <span className="fs-14">{data?.role_name}</span>
                                                                    <span className="label action-btns border-0">
                                                                        <span className="act-btn add-btn rounded-pill cursor-pointer" role="button" title="Create Subordinate Role"
                                                                            onClick={newRole}>
                                                                            <i className="fas fa-plus"></i>
                                                                        </span>
                                                                        <span className="act-btn edit-btn rounded-pill cursor-pointer" role="button" title="Edit"
                                                                            onClick={newRole}>
                                                                            <i className="fas fa-pencil"></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <details open={showTreeNode}>
                                                                    <summary></summary>
                                                                    <ul className="nested-list">
                                                                        <li className="nested-item">
                                                                            <div className="d-inline-block ps-4 role-name-box">
                                                                                <span className="fs-14">Manager</span>
                                                                                <span className="label action-btns border-0">
                                                                                    <span className="act-btn add-btn rounded-pill cursor-pointer" role="button" title="Create Subordinate Role"
                                                                                        onClick={newRole}>
                                                                                        <i className="fas fa-plus"></i>
                                                                                    </span>
                                                                                    <span className="act-btn edit-btn rounded-pill cursor-pointer" role="button" title="Edit"
                                                                                        onClick={newRole}>
                                                                                        <i className="fas fa-pencil"></i>
                                                                                    </span>
                                                                                    <span className="act-btn delete-btn rounded-pill cursor-pointer" role="button" title="Delete"
                                                                                        onClick={deleteRole}>
                                                                                        <i className="fas fa-trash"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <details open={showTreeNode}>
                                                                                <summary></summary>
                                                                                <ul className="nested-list">
                                                                                    <li className="nested-item">
                                                                                        <div className="d-inline-block ps-2 role-name-box">
                                                                                            <span className="fs-14">Lead Manager</span>
                                                                                            <span className="label action-btns border-0">
                                                                                                <span className="act-btn add-btn rounded-pill cursor-pointer" role="button" title="Create Subordinate Role"
                                                                                                    onClick={newRole}>
                                                                                                    <i className="fas fa-plus"></i>
                                                                                                </span>
                                                                                                <span className="act-btn edit-btn rounded-pill cursor-pointer" role="button" title="Edit"
                                                                                                    onClick={newRole}>
                                                                                                    <i className="fas fa-pencil"></i>
                                                                                                </span>
                                                                                                <span className="act-btn delete-btn rounded-pill cursor-pointer" role="button" title="Delete"
                                                                                                    onClick={deleteRole}>
                                                                                                    <i className="fas fa-trash"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </details>
                                                                        </li>
                                                                        <li className="nested-item">
                                                                            <div className="d-inline-block ps-4 role-name-box">
                                                                                <span className="fs-14">Manager 2</span>
                                                                                <span className="label action-btns border-0">
                                                                                    <span className="act-btn add-btn rounded-pill cursor-pointer" role="button" title="Create Subordinate Role"
                                                                                        onClick={newRole}>
                                                                                        <i className="fas fa-plus"></i>
                                                                                    </span>
                                                                                    <span className="act-btn edit-btn rounded-pill cursor-pointer" role="button" title="Edit"
                                                                                        onClick={newRole}>
                                                                                        <i className="fas fa-pencil"></i>
                                                                                    </span>
                                                                                    <span className="act-btn delete-btn rounded-pill cursor-pointer" role="button" title="Delete"
                                                                                        onClick={deleteRole}>
                                                                                        <i className="fas fa-trash"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <details open={showTreeNode}>
                                                                                <summary></summary>
                                                                                <ul className="nested-list">
                                                                                    <li className="nested-item">
                                                                                        <div className="d-inline-block ps-2 role-name-box">
                                                                                            <span className="fs-14">Team Lead</span>
                                                                                            <span className="label action-btns border-0">
                                                                                                <span className="act-btn add-btn rounded-pill cursor-pointer" role="button" title="Create Subordinate Role"
                                                                                                    onClick={newRole}>
                                                                                                    <i className="fas fa-plus"></i>
                                                                                                </span>
                                                                                                <span className="act-btn edit-btn rounded-pill cursor-pointer" role="button" title="Edit"
                                                                                                    onClick={newRole}>
                                                                                                    <i className="fas fa-pencil"></i>
                                                                                                </span>
                                                                                                <span className="act-btn delete-btn rounded-pill cursor-pointer" role="button" title="Delete"
                                                                                                    onClick={deleteRole}>
                                                                                                    <i className="fas fa-trash"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </details>
                                                                        </li>
                                                                    </ul>
                                                                </details>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </details>
                                            </li>
                                        </ul> */}
                                        {/* // Tree View END // */}
                                    </div>
                                    {/* // Roles List section END // */}

                                    {/* New Role ADD / Edit section */}
                                    <div className={`role-add-edit ${addEditRoleSection ? 'd-block' : 'd-none'}`}>
                                        <div className="pt-4">
                                            <h5 className="fw-semibold">{isEdit ? 'Edit' : 'New'} Role</h5>
                                            <p className={`w-75 ${isEdit && 'd-none'}`}>
                                                This page will allow you to create a new role based on your organizational hierarchy.
                                                Create a new role and associate it with a higher role.
                                            </p>
                                        </div>
                                        <form className="mt-3">
                                            <div className="row mb-3">
                                                <label htmlFor="roleName" className="col-12 col-md-3 text-md-end col-xl-2 col-form-label">Role Name</label>
                                                <div className="col-12 col-md-9 col-xl-4 input-control">
                                                    <input type="text" className="form-control" id="roleName" required
                                                        name="role_name" value={roleData?.role_name} onChange={formHandler} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label htmlFor="reportsTo" className="col-12 col-md-3 text-md-end col-xl-2 col-form-label">Reports To</label>
                                                <div className="col-12 col-md-9 col-xl-4 input-control">
                                                    <div className="input-group mt-0" data-bs-toggle="modal" data-bs-target="#chooseRoleModal">
                                                        <input type="text" className="form-control bg-body-tertiary cursor-pointer" id="reportsTo" required readOnly
                                                            value={roleData?.reporting_to?.name} />
                                                        <span className="input-group-text fs-14 cursor-pointer"><i className="fa-solid fa-user-plus"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label htmlFor="shareDataWithPeers" className="col-12 col-md-3 text-md-end col-xl-2 col-form-label pt-0">Share Data with Peers</label>
                                                <div className="col-12 col-md-9 col-xl-4 input-control">
                                                    <input className="form-check-input cursor-pointer" type="checkbox"
                                                        id="shareDataWithPeers" aria-label="Share Data with Peers"
                                                        name="share_with_peers" value=""
                                                        onChange={formHandler} checked={roleData?.share_with_peers} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label htmlFor="roleDescription" className="col-12 col-md-3 text-md-end col-xl-2 col-form-label">Description</label>
                                                <div className="col-12 col-md-9 col-xl-4 input-control">
                                                    <textarea className="form-control" id="roleDescription" rows="3"
                                                        // defaultValue={"Users belonging to this role cannot see data for admin users."}
                                                        name="description" value={roleData?.description} onChange={formHandler}></textarea>
                                                </div>
                                            </div>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-light flat-btn rounded-pill"
                                                    onClick={showRoleList}>Cancel</button>
                                                <button type="button" className="btn btn-primary btn-digy-dark rounded-pill"
                                                    onClick={showDetails}>Save</button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* // New Role ADD / Edit section END // */}

                                    {/* Role Delete section */}
                                    <div className={`delete-role ${deleteRoleSection ? 'd-block' : 'd-none'}`}>
                                        <div className="pt-4">
                                            <h5 className="fw-semibold">Delete Role</h5>
                                            <p className="w-75">
                                                Before deleting, you must transfer users and subordinates with this role to a new role.
                                            </p>
                                        </div>
                                        <div className="mt-3">
                                            <h6 className="fw-semibold">Transfer Users and Sub-roles:</h6>
                                            <form className="role-delete">
                                                <div className="row mb-3">
                                                    <label htmlFor="roleToDeleted" className="col-12 col-md-3 text-md-end col-xl-2 col-form-label">Role to be Deleted</label>
                                                    <div className="col-12 col-md-9 col-xl-4 input-control">
                                                        <div className="input-group mt-0">
                                                            <input type="text" className="form-control bg-body-tertiary cursor-pointer" id="roleToDeleted" readOnly value={deleteRoleData.name} />
                                                            <span className="input-group-text fs-14 cursor-pointer"><i className="fa-solid fa-lock"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="transferToRole" className="col-12 col-md-3 text-md-end col-xl-2 col-form-label">Transfer to Role</label>
                                                    <div className="col-12 col-md-9 col-xl-4 input-control">
                                                        <div className="input-group mt-0" data-bs-toggle="modal" data-bs-target="#chooseRoleModal">
                                                            <input type="text" className="form-control bg-body-tertiary cursor-pointer" id="transferToRole" required readOnly value={transferRoleData?.name} />
                                                            <span className="input-group-text fs-14 cursor-pointer"><i className="fa-solid fa-user-plus"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-light flat-btn rounded-pill"
                                                        onClick={showRoleList}>Cancel</button>
                                                    <button type="button" className="btn btn-primary btn-digy-dark rounded-pill"
                                                        onClick={transferAndDelete}>Transfer and Delete</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* // Role Delete section END // */}

                                    {/* Role Details View section */}
                                    <div className={`role-detail-view ${showDetailsView ? 'd-block' : 'd-none'}`}>
                                        <div className="pt-4">
                                            <h5 className="fw-semibold">Role Details</h5>
                                        </div>
                                        <div className="role-detail">
                                            <dl className="row justify-content-start list-detail">
                                                <dd className="col-sm-4 col-lg-3 text-secondary">Role Name</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-semibold">{roleData?.role_name}</dt>
                                                <dd className="col-sm-4 col-lg-3 text-secondary">Reports To</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">
                                                    <span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal" onClick={newRole}>
                                                        {roleData?.reporting_to?.name}
                                                    </span>
                                                </dt>
                                                <dd className="col-sm-4 col-lg-3 text-secondary">Share Data with Peers</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal"><i className={`fa-solid ${roleData?.share_with_peers && 'fa-check'}`}></i></dt>
                                                <dd className="col-sm-4 col-lg-3 text-secondary">Description</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">
                                                    {roleData?.description}
                                                </dt>
                                            </dl>
                                            <dt className="row justify-content-end py-3">
                                                <div className="col-sm-8 col-lg-9">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-light flat-btn rounded-pill"
                                                            onClick={editRoleFromView}>Edit</button>
                                                        <button type="button" className="btn btn-light flat-btn rounded-pill"
                                                            onClick={deleteRoleFromView}>Delete</button>
                                                        <button type="button" className="btn btn-light flat-btn rounded-pill"
                                                            onClick={showUpdatedRoleList}>Go to Roles List</button>
                                                    </div>
                                                </div>
                                            </dt>
                                        </div>
                                        <div className="mt-3">
                                            <h6 className="fw-semibold">Associated Users:</h6>
                                            <div className="tbl-container rounded-table">
                                                <div className="table-responsive">
                                                    <table className="table table-hover hover-icon-table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Email Address</th>
                                                                <th>User Status</th>
                                                                <th>Confirmation Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allUsers?.allUserData?.length > 0 ?
                                                                <>
                                                                    {allUsers?.allUserData?.filter((item) => item?.role?._id === selectedRoleId.id)
                                                                        .length === 0 ? (
                                                                        <tr>
                                                                            <td colSpan={4} className="text-center">No Users Found</td>
                                                                        </tr>
                                                                    ) : (allUsers?.allUserData?.filter((item) => item?.role?._id === selectedRoleId.id)
                                                                        .map((data) => (
                                                                            <tr>
                                                                                <td><a onClick={() => navigate(Utils.getNavigationUrl(`settings/users`))}>{data?.firstName + ' ' + data?.lastName}</a></td>
                                                                                <td><a href={`mailto:${data.email}`}>{data?.email}</a></td>
                                                                                <td>{data?.isActive ? 'Active' : 'Disabled'}</td>
                                                                                <td>{data?.is_email_verified ? 'Confirmed' : 'Not Confirmed'}</td>
                                                                            </tr>
                                                                        ))
                                                                    )
                                                                    }

                                                                    {/* {allUsers?.allUserData?.filter((item) => item?.role?._id === selectedRoleId.id)
                                                                        .map((data) => (
                                                                            <tr>
                                                                                <td><a href="/settings/users">{data?.firstName + ' ' + data?.lastName}</a></td>
                                                                                <td><a href={`mailto:${data.email}`}>{data?.email}</a></td>
                                                                                <td>{data?.isActive ? 'Active' : 'Disabled'}</td>
                                                                                <td>{data?.is_email_verified ? 'Confirmed' : 'Not Confirmed'}</td>
                                                                            </tr>
                                                                        ))
                                                                    } */}
                                                                </>
                                                                :
                                                                <tr>
                                                                    <td colSpan={4} className="text-center">No Users Found</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* // Role Details View END // */}
                                </div>
                                {/* // Roles Tab END // */}

                                {/* Data Sharing Settings */}
                                <div className="tab-pane fade" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab-2">
                                    {/* Data Sharing Settings view */}
                                    <div className={`data-sharing ${dataShareView ? 'd-block' : 'd-none'}`}>
                                        <div className="d-flex flex-row mt-4">
                                            <div className="left-con">
                                                <h5 className="fw-semibold">Data Sharing Settings</h5>
                                                <p className="w-75">
                                                    This page allows you to manage default organization permissions and sharing rules.
                                                    Default organization permissions govern how your records are shared across
                                                    the organization. To further customize your settings, you can add sharing rules,
                                                    which allow you to share records based on record owner or criteria with roles,
                                                    roles and subordinates, groups, and all users.
                                                </p>
                                            </div>
                                            <div className="right-con d-flex flex-column align-items-end ms-auto">
                                                <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn">
                                                    <i className="fa-regular fa-circle-question me-2"></i> Help
                                                </button>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row mt-4 mb-3">
                                            <div className="left-con">
                                                <h6 className="fw-semibold">Default Organization Permissions</h6>
                                            </div>
                                            <div className="right-con d-flex flex-column align-items-end ms-auto">
                                                <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3"
                                                    onClick={showEditPermission}>
                                                    Edit All Default Permissions
                                                </button>
                                            </div>
                                        </div>
                                        <div className="tbl-container rounded-table">
                                            <div className="table-responsive">
                                                <table className="table table-hover hover-icon-table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Module</th>
                                                            <th>Default Access</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Article Master</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Bill Of Material</span></td>
                                                            <td>Public Read/Write</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Lookup Check</span></td>
                                                            <td>Public Read/Write/Delete</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Company Master</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Tax Master</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">User Master</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Production Version</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Price Conditions</span></td>
                                                            <td>Public Read Only</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Routing</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">BP Master</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Source List</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Purchase Info Record</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Article Master Generic</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">BP Vendor</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">BP Sales</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">BP General Data</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Cost Center</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">Profit Center</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#modulePermissionModal">GL Account</span></td>
                                                            <td>Private</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* // Data Sharing Settings view END //*/}

                                    {/* Edit Default Organization Permissions */}
                                    <div className={`edit-sharing-permission ${editSharePermission ? 'd-block' : 'd-none'}`}>
                                        <div className="d-flex flex-row mt-4">
                                            <div className="left-con">
                                                <h5 className="fw-semibold">Edit Default Organization Permissions</h5>
                                            </div>
                                        </div>
                                        <div className="tbl-container rounded-table border-0">
                                            <div className="table-responsive">
                                                <table className="table table-borderless data-share-edit-table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="fw-semibold">Article Master:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m1InlineCheckbox1" name="m1Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m1InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m1InlineCheckbox2" name="m1Option" />
                                                                    <label className="form-check-label" htmlFor="m1InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m1InlineCheckbox3" name="m1Option" />
                                                                    <label className="form-check-label" htmlFor="m1InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m1InlineCheckbox4" name="m1Option" />
                                                                    <label className="form-check-label" htmlFor="m1InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Bill Of Material:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m2InlineCheckbox1" name="m2Option" />
                                                                    <label className="form-check-label" htmlFor="m2InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m2InlineCheckbox2" name="m2Option" />
                                                                    <label className="form-check-label" htmlFor="m2InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m2InlineCheckbox3" name="m2Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m2InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m2InlineCheckbox4" name="m2Option" />
                                                                    <label className="form-check-label" htmlFor="m2InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Lookup Check:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m3InlineCheckbox1" name="m3Option" />
                                                                    <label className="form-check-label" htmlFor="m3InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m3InlineCheckbox2" name="m3Option" />
                                                                    <label className="form-check-label" htmlFor="m3InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m3InlineCheckbox3" name="m3Option" />
                                                                    <label className="form-check-label" htmlFor="m3InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m3InlineCheckbox4" name="m3Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m3InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Company Master:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m4InlineCheckbox1" name="m4Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m4InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m4InlineCheckbox2" name="m4Option" />
                                                                    <label className="form-check-label" htmlFor="m4InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m4InlineCheckbox3" name="m4Option" />
                                                                    <label className="form-check-label" htmlFor="m4InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m4InlineCheckbox4" name="m4Option" />
                                                                    <label className="form-check-label" htmlFor="m4InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Tax Master:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m5InlineCheckbox1" name="m5Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m5InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m5InlineCheckbox2" name="m5Option" />
                                                                    <label className="form-check-label" htmlFor="m5InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m5InlineCheckbox3" name="m5Option" />
                                                                    <label className="form-check-label" htmlFor="m5InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m5InlineCheckbox4" name="m5Option" />
                                                                    <label className="form-check-label" htmlFor="m5InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">User Master:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m6InlineCheckbox1" name="m6Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m6InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m6InlineCheckbox2" name="m6Option" />
                                                                    <label className="form-check-label" htmlFor="m6InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m6InlineCheckbox3" name="m6Option" />
                                                                    <label className="form-check-label" htmlFor="m6InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m6InlineCheckbox4" name="m6Option" />
                                                                    <label className="form-check-label" htmlFor="m6InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Production Version:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m7InlineCheckbox1" name="m7Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m7InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m7InlineCheckbox2" name="m7Option" />
                                                                    <label className="form-check-label" htmlFor="m7InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m7InlineCheckbox3" name="m7Option" />
                                                                    <label className="form-check-label" htmlFor="m7InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m7InlineCheckbox4" name="m7Option" />
                                                                    <label className="form-check-label" htmlFor="m7InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Price Conditions:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m8InlineCheckbox1" name="m8Option" />
                                                                    <label className="form-check-label" htmlFor="m8InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m8InlineCheckbox2" name="m8Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m8InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m8InlineCheckbox3" name="m8Option" />
                                                                    <label className="form-check-label" htmlFor="m8InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m8InlineCheckbox4" name="m8Option" />
                                                                    <label className="form-check-label" htmlFor="m8InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Routing:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m9InlineCheckbox1" name="m9Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m9InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m9InlineCheckbox2" name="m9Option" />
                                                                    <label className="form-check-label" htmlFor="m9InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m9InlineCheckbox3" name="m9Option" />
                                                                    <label className="form-check-label" htmlFor="m9InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m9InlineCheckbox4" name="m9Option" />
                                                                    <label className="form-check-label" htmlFor="m9InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">BP Master:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m10InlineCheckbox1" name="m10Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m10InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m10InlineCheckbox2" name="m10Option" />
                                                                    <label className="form-check-label" htmlFor="m10InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m10InlineCheckbox3" name="m10Option" />
                                                                    <label className="form-check-label" htmlFor="m10InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m10InlineCheckbox4" name="m10Option" />
                                                                    <label className="form-check-label" htmlFor="m10InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Source List:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m11InlineCheckbox1" name="m11Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m11InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m11InlineCheckbox2" name="m11Option" />
                                                                    <label className="form-check-label" htmlFor="m11InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m11InlineCheckbox3" name="m11Option" />
                                                                    <label className="form-check-label" htmlFor="m11InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m11InlineCheckbox4" name="m11Option" />
                                                                    <label className="form-check-label" htmlFor="m11InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Purchase Info Record:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m12InlineCheckbox1" name="m12Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m12InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m12InlineCheckbox2" name="m12Option" />
                                                                    <label className="form-check-label" htmlFor="m12InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m12InlineCheckbox3" name="m12Option" />
                                                                    <label className="form-check-label" htmlFor="m10InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m12InlineCheckbox4" name="m12Option" />
                                                                    <label className="form-check-label" htmlFor="m12InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Article Master Generic:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m13InlineCheckbox1" name="m13Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m13InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m13InlineCheckbox2" name="m13Option" />
                                                                    <label className="form-check-label" htmlFor="m13InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m13InlineCheckbox3" name="m13Option" />
                                                                    <label className="form-check-label" htmlFor="m13InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m13InlineCheckbox4" name="m13Option" />
                                                                    <label className="form-check-label" htmlFor="m13InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">BP Vendor:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m14InlineCheckbox1" name="m14Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m14InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m14InlineCheckbox2" name="m14Option" />
                                                                    <label className="form-check-label" htmlFor="m14InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m14InlineCheckbox3" name="m14Option" />
                                                                    <label className="form-check-label" htmlFor="m14InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m14InlineCheckbox4" name="m14Option" />
                                                                    <label className="form-check-label" htmlFor="m14InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">BP Sales:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m15InlineCheckbox1" name="m15Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m15InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m15InlineCheckbox2" name="m15Option" />
                                                                    <label className="form-check-label" htmlFor="m15InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m15InlineCheckbox3" name="m15Option" />
                                                                    <label className="form-check-label" htmlFor="m15InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m15InlineCheckbox4" name="m15Option" />
                                                                    <label className="form-check-label" htmlFor="m15InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">BP General Data:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m16InlineCheckbox1" name="m16Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m16InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m16InlineCheckbox2" name="m16Option" />
                                                                    <label className="form-check-label" htmlFor="m16InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m16InlineCheckbox3" name="m16Option" />
                                                                    <label className="form-check-label" htmlFor="m16InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m16InlineCheckbox4" name="m16Option" />
                                                                    <label className="form-check-label" htmlFor="m16InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Cost Center:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m17InlineCheckbox1" name="m17Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m17InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m17InlineCheckbox2" name="m17Option" />
                                                                    <label className="form-check-label" htmlFor="m17InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m17InlineCheckbox3" name="m17Option" />
                                                                    <label className="form-check-label" htmlFor="m17InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m17InlineCheckbox4" name="m17Option" />
                                                                    <label className="form-check-label" htmlFor="m17InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">Profit Center:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m18InlineCheckbox1" name="m18Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m18InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m18InlineCheckbox2" name="m18Option" />
                                                                    <label className="form-check-label" htmlFor="m18InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m18InlineCheckbox3" name="m18Option" />
                                                                    <label className="form-check-label" htmlFor="m18InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m18InlineCheckbox4" name="m18Option" />
                                                                    <label className="form-check-label" htmlFor="m18InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-semibold">GL Account:</td>
                                                            <td>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m19InlineCheckbox1" name="m19Option" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="m19InlineCheckbox1">Private</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m19InlineCheckbox2" name="m19Option" />
                                                                    <label className="form-check-label" htmlFor="m19InlineCheckbox2">Public Read Only</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m19InlineCheckbox3" name="m19Option" />
                                                                    <label className="form-check-label" htmlFor="m19InlineCheckbox3">Public Read / Write</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="m19InlineCheckbox4" name="m19Option" />
                                                                    <label className="form-check-label" htmlFor="m19InlineCheckbox4">Public Read / Write / Delete</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            className="form-footer rounded-bottom-4 position-absolute start-0 bottom-0 w-100 bg-white border-top ps-4 py-3 d-flex">
                                            <button type="button" className="btn btn-light flat-btn rounded-pill py-1 px-3"
                                                onClick={showDataShareView}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3 ms-3">Save</button>
                                        </div>
                                    </div>
                                    {/* Edit Default Organization Permissions END */}

                                </div>
                                {/* // Data Sharing Settings END // */}
                            </div>
                        </div>

                    </div>

                    {/* Choose Role Modal */}
                    <div className="modal fade" id="chooseRoleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="chooseRoleLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable choose-rol-modal">
                            <div className="modal-content">
                                <div className='modal-header border-bottom-0 pb-2'>
                                    <h5 className="modal-title fs-5" id="chooseRoleLabel">
                                        Role List
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body pt-0">
                                    <p className="pb-3">Please select one role from the list.</p>
                                    {/* dynamic tree view::start */}
                                    <div>
                                        <ul className="tree mt-3">
                                            <li className="parent">
                                                <div className="d-inline-block ps-4 role-name-box">{user?.organization?.organizationName}</div>
                                                <details open={showTreeNode} className="details">
                                                    <summary></summary>
                                                    {formattedModalRole.length > 0 && formattedModalRole.map((node) => (
                                                        // {treeData.map((node) => (
                                                        <RoleTreeNode key={node._id} node={node} />
                                                    ))}
                                                </details>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* dynamic tree view::end */}
                                    {/* Tree View */}
                                    {/* <ul className="tree mt-3 d-none">
                                        <li className="parent">
                                            <div className="d-inline-block ps-4 role-name-box">
                                                <span className="fs-14 link cursor-pointer"
                                                    onClick={() =>
                                                        setRoleData((prev) => ({
                                                            ...prev,
                                                            reporting_to: {
                                                                name: allRoles?.roleData?.[0]?.role_name,
                                                                id: allRoles?.roleData?.[0]?._id
                                                            }
                                                        }))
                                                    }
                                                    data-bs-dismiss="modal">
                                                    {allRoles?.roleData?.[0]?.role_name}
                                                </span>
                                            </div>
                                            <details open={showTreeNode} className="details d-none">
                                                <summary></summary>
                                                <ul className="nested-list">
                                                    <li className="nested-item">
                                                        <div className="d-inline-block ps-4 role-name-box">
                                                            <span className="fs-14 link cursor-pointer">Manager</span>
                                                        </div>
                                                        <details open={showTreeNode}>
                                                            <summary></summary>
                                                            <ul className="nested-list">
                                                                <li className="nested-item">
                                                                    <div className="d-inline-block ps-4 role-name-box">
                                                                        <span className="fs-14 text-muted">Team Lead</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </details>
                                                    </li>
                                                </ul>
                                            </details>
                                        </li>
                                    </ul> */}
                                    {/* // Tree View END // */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // Choose Role Modal END // */}

                    {/* Module Permission Edit Modal */}
                    <div className="modal fade" id="modulePermissionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modulePermissionLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable module-permission-modal">
                            <div className="modal-content">
                                <div className='modal-header border-bottom-0'>
                                    <h5 className="modal-title fs-5" id="modulePermissionLabel">
                                        Edit Default Organization Permission:
                                        <span className="d-block fs-5 fw-semibold">("Module Name")</span>
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row align-items-center mb-3">
                                        <label htmlFor="setPermission" className="col-sm-2 col-md-auto col-xl-3 px-xl-0 text-md-end col-form-label">
                                            Default Access
                                        </label>
                                        <div className="col-sm-10 col-md-auto col-xl-8">
                                            <select className="form-select" id="setPermission" value="1">
                                                <option value="1">Private</option>
                                                <option value="2">Public Read Only</option>
                                                <option value="3">Public Read / Write</option>
                                                <option value="4">Public Read / Write / Delete</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary rounded-pill btn-blue">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // Module Permission Edit Modal END //*/}
                </div>
            </React.Fragment>
        </>
    )
}
export default RolesAndSharing;