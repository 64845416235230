import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DashboardComponents } from '../../../DashboardComponents';
import { AllChartModel } from '..';

function KpiModel({ show, handleClose, submitData, allList = [] }) {
    let [KpiStyleList] = useState([DashboardComponents["KpiStandard"], DashboardComponents["KpiGrowthIndex"], DashboardComponents["KpiBasic"], DashboardComponents["KpiScorecard"], DashboardComponents["KpiRankings"]]);
    let [KpiStyleName] = useState(["KpiStandard", "KpiGrowthIndex", "KpiBasic", "KpiScorecard", "KpiRankings"]);
    let [showModal, setShowModal] = useState(false);
    let [OpenModel, setModel] = useState(null);

    let onClickCard = (index) => {
        let ChartComponent = AllChartModel[KpiStyleName[index]];
        if (ChartComponent) {
            setModel(() => ChartComponent);
            setShowModal(true);
        } else {}
    }
    return (
        <>
            <Modal show={show && !showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Choose KPI Style</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mt-5">
                        <div className="row g-4">
                            {KpiStyleList.map((KpiComponent, index) => (
                                <div className="col-md-4" key={index} onClick={() => onClickCard(index)}>
                                    <div className="card ">
                                        <div className="card-body text-center">
                                            <KpiComponent isStatic={true}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {(showModal && OpenModel) && <OpenModel show={showModal} handleClose={() => {
                setShowModal(false);
                setModel(null);
            }} submitData={(data) => {
                submitData(data);
                handleClose();
            }} allList={allList}/> }
        </>
    );
}

export default KpiModel;
