import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Utils } from '../../../Utils';
import dayjs from 'dayjs';
import { Helpers } from '../../../Helpers';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader';

function TableAssignment({ data = {}, isStatic = false }) {
    let {subModuleData, isDashboardLoading} = useSelector((state) => state?.dashboardComponents);
    let [subFormlayoutList, setSubFormlayoutList] = useState([]);
    let navigate = useNavigate();
    
    useEffect(() => {
        if (subModuleData?.[data?.moduleId]?.length > 0 && Object?.keys(data)?.length > 0) {
            let datas = Utils.getFilteredRecords(subModuleData?.[data?.moduleId], data?.duration1, data?.duration2);
            let newFilteredData = Utils.filteredDataGet(datas, data) || [];
            let processedData = newFilteredData?.map((item) => {
                return {
                    _id: item?._id,
                    ...item?.data.reduce((acc, curr) => ({
                        ...acc,
                        ...curr?.section_data
                    }), {})
                }
            });
            setSubFormlayoutList(processedData);
        }
    }, [subModuleData?.[data?.moduleId], data]);

    return (
        <>
            {isStatic ?
                <>
                    <div className="h-100 db-table tbl-container rounded-table">
                        <div className="h-100 table-responsive pb-3">
                            <table className="table mb-0 text-wrap">
                                <thead>
                                    <tr>
                                        <th>Subject</th>
                                        <th>Due Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> CRM </td>
                                        <td>11/12/2024</td>
                                        <td>Not Started</td>
                                    </tr>
                                    <tr>
                                        <td> CRM </td>
                                        <td>13/12/2024</td>
                                        <td>In Progress</td>
                                    </tr>
                                    <tr>
                                        <td> CRM </td>
                                        <td>09/12/2024</td>
                                        <td>Not Started</td>
                                    </tr>
                                    <tr>
                                        <td> CRM </td>
                                        <td>10/12/2024</td>
                                        <td>Not Started</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
                : <>
                    {(isDashboardLoading && !subFormlayoutList?.length) ? (
                        <div className='chart-loader'><Loader type={'infinitySpin'} isVisible={true} /></div>
                    ) :
                        subFormlayoutList?.length > 0 ?
                            <div className="h-100 db-table tbl-dashboard tbl-container rounded-table">
                                <div className="h-100 table-responsive pb-3">
                                    <table className="table mb-0 text-wrap">
                                        <thead>
                                            <tr>
                                                {data?.table_headers?.length > 0 && (
                                                    data?.table_headers?.map((x, index) => (
                                                        <th key={index}>{x?.label}</th>
                                                    ))
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subFormlayoutList?.map((x, index) => (
                                                    <tr key={index}>
                                                        {data?.table_headers?.map((y, index2) => {
                                                            let cellValue = x?.[Helpers.replaceData(y?.label)];
                                                            return (
                                                                <td key={`${index}-${index2}`}>
                                                                    {index2 === 0 ?
                                                                        <>
                                                                            <a className="text-link" role="button" onClick={() => navigate(Utils.getNavigationUrl(`form-module/sub-form/update/${data?.moduleId}/${x?._id}`))}>
                                                                                {typeof cellValue === "object"
                                                                                    ? cellValue?.name || "_"
                                                                                    : y?.input_type === "date"
                                                                                        ? dayjs(cellValue).format("MM/D/YY")
                                                                                        : (y?.input_type === "datetime-local" ? dayjs(cellValue).format("MM/D/YY hh:mm A") : cellValue || "_")}
                                                                            </a>
                                                                        </> :
                                                                        <>
                                                                            {typeof cellValue === "object"
                                                                                ? cellValue?.name || "_"
                                                                                : y?.input_type === "date"
                                                                                    ? dayjs(cellValue).format("MM/D/YY")
                                                                                    : (y?.input_type === "datetime-local" ? dayjs(cellValue).format("MM/D/YY hh:mm A") : cellValue || "_")}
                                                                        </>
                                                                    }
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : <div className='h-100 fw-bold align-self-center align-center py-3'>No Data Found</div>}
                </>}
        </>
    );
}

export default TableAssignment;
