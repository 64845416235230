// later will implement encrypt and decrypt

// const encryptData = (data) => {
//     return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
// };

// const decryptData = (encryptedData) => {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
//     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };

export const setItem = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const getItem = (key) => {
    let item = localStorage.getItem(key);
    return item && item !== 'undefined' ? JSON.parse(item) : null;
};

export const removeItem = (key) => {
    localStorage.removeItem(key);
};