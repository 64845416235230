import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import AuthLayout from "../Layout/Auth";
import Login from "../Pages/Auth/Login";
import Signup from "../Pages/Auth/Signup";
import DashboarLayout from "../Layout/Dashboard";
import Dashboard from "../Pages/Dashboard";
import CreateUpdateModule from "../Pages/FormModule/CreateUpdateModule";
import FormModule from "../Pages/FormModule";
import ProtectedRoutes from './protectedRoutes';
import SubModule from "../Pages/FormModule/SubModules";
import CreateUpdateSubModule from "../Pages/FormModule/SubModules/CreateUpdateSubModule";
import Settings from "../Pages/Settings";
import WorkflowRules from "../Pages/WorkflowRules";
import CreateWorkflow from "../Pages/WorkflowRules/CreateWorkflowRule";
import DashboardDetails from "../Pages/Dashboard/DashboardDetails";
import IntegrationAPI from "../Pages/IntegrationAPI";
import BusinessConnect from "../Pages/IntegrationAPI/BusinessConnect";
import IntegrationForm from "../Pages/IntegrationAPI/IntegrationForm";
import WebhooksTable from "../Pages/IntegrationAPI/WebhooksTable";
import PersonalSettings from "../Pages/GeneralSettings/PersonalSettings";
import Users from "../Pages/GeneralSettings/Users";
import CompanySettings from "../Pages/GeneralSettings/CompanySettings";
import Profiles from "../Pages/SecurityControl/Profiles";
import RolesAndSharing from "../Pages/SecurityControl/RolesAndSharing";
import LoginHistory from "../Pages/SecurityControl/LoginHistory";
import AuditLog from "../Pages/SecurityControl/AuditLog";
import Email from "../Pages/Channels/Email";
import BusinessMessaging from "../Pages/Channels/BusinessMessaging";
import NotificationSms from "../Pages/Channels/NotificationSms";
import VariantConfiguration from "../Pages/FormModule/VariantConfiguration";
import ProfilePermissions from "../Pages/SecurityControl/Profiles/profilePermissions";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Analytics from "../Pages/FormModule/Analytics";
import CreateDashboard from "../Pages/FormModule/Analytics/CreateDashboard";
import HomeCustomization from "../Pages/HomeCustomization";
import HomeCustomizationCreate from "../Pages/HomeCustomization/CreateUpdate";
import ConfirmAccount from "../Pages/Auth/ConfirmAccount";
import InvalidAccount from "../Pages/Auth/InvalidAccount";
import FormLayout from "../Pages/FormModule/FormLayout";
import ModuleConvert from "../Pages/FormModule/ModuleConvert";
import RootRedirect from './rootRedirect';
import QuoteTemplate from "../Pages/PrintTemplate/QuoteTemplate";
import SalesOrderTemplate from "../Pages/PrintTemplate/SalesOrderTemplate";
import InvoiceTemplate from "../Pages/PrintTemplate/InvoiceTemplate";
import NewCustomView from "../Pages/FormModule/NewCustomView";
import ChangePassword from "../Pages/Auth/ChangePassword";

const getRoutes = () => {
    return (
        <>
            <Route path="dashboard">
                <Route path="" element={<Dashboard />}></Route>
                <Route path="view/:type" element={<DashboardDetails></DashboardDetails>}></Route>
            </Route>
            <Route path="form-module">
                <Route path="" element={<FormModule />}></Route>
                <Route path="layout/:id" element={<FormLayout />}></Route>
                <Route path="convert/:moduleId/:subFormId" element={<ModuleConvert />}></Route>
                <Route path="create" element={<CreateUpdateModule />}></Route>
                <Route path="update/:id" element={<CreateUpdateModule />}></Route>
                <Route path="sub-form/:id" element={<SubModule />}></Route>
                <Route path="sub-form/create/:id" element={<CreateUpdateSubModule />}></Route>
                <Route path="sub-form/update/:id/:sub_form_id" element={<CreateUpdateSubModule />}></Route>
                <Route path="custom-view/:id" element={<NewCustomView />}></Route>
            </Route>
            <Route path="settings">
                <Route path="" element={<Settings />} />
                <Route path="personal-settings" element={<PersonalSettings />} />
                <Route path="users" element={<Users />} />
                <Route path="company-settings" element={<CompanySettings />} />
                <Route path="profiles">
                    <Route path="" element={<Profiles />} />
                    <Route path="profile-permissions" element={<ProfilePermissions />} />
                    <Route path="profile-permissions/:type" element={<ProfilePermissions />} />
                </Route>
                <Route path="roles-and-sharing" element={<RolesAndSharing />} />
                <Route path="login-history" element={<LoginHistory />} />
                <Route path="audit-log" element={<AuditLog />} />
                <Route path="email" element={<Email />} />
                <Route path="business-messaging" element={<BusinessMessaging />} />
                <Route path="notification-sms" element={<NotificationSms />} />
            </Route>
            <Route path="workflow-rules">
                <Route path="" element={<WorkflowRules />}></Route>
                <Route path="create" element={<CreateWorkflow />}></Route>
                <Route path="edit/:id" element={<CreateWorkflow />}></Route>
            </Route>
            <Route path="integration-api">
                <Route path="" element={<IntegrationAPI />}></Route>
                <Route path="business-connect" element={<BusinessConnect />}></Route>
                <Route path="form" element={<IntegrationForm />}></Route>
                <Route path="webhooks-table" element={<WebhooksTable />}></Route>
            </Route>
            <Route path="variant-configuration">
                <Route path="" element={<VariantConfiguration />}></Route>
            </Route>
            <Route path="analytics">
                <Route path="" element={<Analytics />}></Route>
                <Route path="create-dashboard" element={<CreateDashboard />}></Route>
            </Route>
            <Route path="home-customization">
                <Route index element={<HomeCustomization />} />
                <Route path="create" element={<HomeCustomizationCreate />} />
                <Route path="update/:id" element={<HomeCustomizationCreate />} />
            </Route>
            <Route path="print-template">
                <Route path="quote" element={<QuoteTemplate />}></Route>
                <Route path="sales-order" element={<SalesOrderTemplate />}></Route>
                <Route path="invoice" element={<InvoiceTemplate />}></Route>
            </Route>
            <Route path="change-password" element={<ChangePassword />}></Route>
        </>
    )
}

const Router = () => {
    return (
        <Routers>
            <Routes>
                <Route path="" element={<ProtectedRoutes><DashboarLayout /></ProtectedRoutes>}>
                    <Route path="/:accessUrl">
                        {getRoutes()}
                    </Route>
                </Route>

                <Route path="/auth" element={<RootRedirect><AuthLayout /></RootRedirect>}>
                    <Route path="login" element={<Login />}></Route>
                    <Route path="signup" element={<Signup />}></Route>
                    <Route path="forgot-password" element={<ForgotPassword />}></Route>
                    <Route path="confirm-account" element={<ConfirmAccount />}></Route>
                    <Route path="invalid-account" element={<InvalidAccount />}></Route>
                </Route>
            </Routes>
        </Routers>
    )

}
export default Router;