import React, { useEffect, useState } from "react";
import { getModuleByIdStart } from "../../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Helpers } from "../../../Helpers";
import Loader from "../../../Components/Loader";
import { createWebhookStart, updateWebhookByIdStart } from "../../../Store/Slices/webhookSlice";
import { toast } from 'react-toastify';
import Toast from "../../../Components/Toast";
import { Utils } from "../../../Utils";

const IntegrationForm = () => {
    let {state} = useLocation();
    let { id, webhookId, method, formDatas } = state || { id : "", method : "", formDatas : null, webhookId : "" };
    let getCreateUpdateListByID = useSelector((state) => state?.forms);
    let webhooksData = useSelector((state) => state?.webhooks);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let [storeData, setStoreData] = useState({});
    let [formData, setFormData] = useState({});
    let [errors, setErrors] = useState({});
    let [touched, setTouched] = useState({});
    let [formSubmit, setFormSubmit] = useState(false);
    let [userName, setUserName] = useState(formDatas?.userName || '');
    let [password, setPassword] = useState(formDatas?.password || '');
    let [apiUrl, setApiUrl] = useState(formDatas?.api_url || '');

    useEffect(() => {
        if (webhooksData?.exit && webhooksData?.message?.length > 0 && formSubmit) {
            toast.success(webhooksData?.message, {
                autoClose: 1000,
                onClose: () => {
                    navigate(Utils.getNavigationUrl(`integration-api/webhooks-table`));
                }
            })
            return;
        }
    }, [webhooksData]);

    useEffect(() => {
        if (id) {
            dispatch(getModuleByIdStart(id));
        } else {
            navigate(Utils.getNavigationUrl(`integration-api/business-connect`))
        }
    }, [id]);

    const formatDataStructures = (data) => {
        let format = {};
        data?.sections?.forEach((section, sectionIndex) => {
            section?.dropped_list?.[`drop_${sectionIndex}_area_1`]?.forEach((item) => {
                let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                format[field_label] = formDatas?.data?.[field_label] || '';
            });
            if (section?.layouts === 2 || section?.layouts === 3) {
                section?.dropped_list?.[`drop_${sectionIndex}_area_2`]?.forEach((item) => {
                    let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                    format[field_label] = formDatas?.data?.[field_label] || '';
                });
            }
            if (section?.layouts === 3) {
                section?.dropped_list?.[`drop_${sectionIndex}_area_3`]?.forEach((item) => {
                    let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                    format[field_label] = formDatas?.data?.[field_label] || '';
                });
            }
        });
        setFormData(format);
    }

    useEffect(() => {
        if (getCreateUpdateListByID?.modules?.length > 0 && id) {
            let fields = getCreateUpdateListByID?.modules?.[0].fields;
            setStoreData(fields);
            formatDataStructures(fields);
        }
    }, [getCreateUpdateListByID]);

    const handleChange = (e, field) => {
        let { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    };

    const validateField = (field, value) => {
        switch (field) {
            case "userName":
                if (!value) {
                    return "User Name is required";
                }
                break;
            case "password":
                if (!value) {
                    return "Password is required";
                }
                break;
            case "apiUrl":
                if (!value) {
                    return "A valid API URL is required";
                } else if (!Utils.urlPattern.test(value)) {
                    return "API Url format is invalid";
                }
                break;
            default:
                break;
        }
        return "";
    };

    const validateForm = () => {
        let newErrors = {};
        newErrors.userName = validateField("userName", userName);
        newErrors.password = validateField("password", password);
        newErrors.apiUrl = validateField("apiUrl", apiUrl);
        return newErrors;
    };

    useEffect(() => {
        if (touched.userName) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                userName: validateField("userName", userName),
            }));
        }
    }, [userName, touched.userName]);

    useEffect(() => {
        if (touched.password) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: validateField("password", password),
            }));
        }
    }, [password, touched.password]);

    useEffect(() => {
        if (touched.apiUrl) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                apiUrl: validateField("apiUrl", apiUrl),
            }));
        }
    }, [apiUrl, touched.apiUrl]);

    const handleBlur = (field) => {
        setTouched({
            ...touched,
            [field]: true,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setTouched({
            userName: true,
            password: true,
            apiUrl: true
        });
        let validationErrors = validateForm();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).every((key) => !validationErrors[key])) {
            if (webhookId?.length > 0) {
                dispatch(updateWebhookByIdStart({
                    webhookId,
                    payload: {
                        method,
                        moduleId: id,
                        api_url: apiUrl,
                        userName,
                        password,
                        data: formData
                    }
                }));
            } else {
                dispatch(createWebhookStart({
                    method,
                    moduleId: id,
                    api_url: apiUrl,
                    userName,
                    password,
                    data: formData
                }));
            }
            setFormSubmit(true);
        }
    };

    return (
        <>
            <Toast />
            <Loader isVisible={getCreateUpdateListByID?.isLoading || webhooksData?.isLoading} />
            {/* <div className="main-panel"> */}
                <main className="py-md-4 content-section">
                    <div className="container-fluid">
                        <h5>{storeData?.plural_form}</h5>
                        <main className="col-lg-12 ms-sm-auto px-md-4 pt-md-4 content-section lead-create-form">
                            <form className="mt-4" onSubmit={handleSubmit}>
                                <div className="row mb-40">
                                    <h6 className="sec-header pb-3 pt-2 col-12">Connection</h6>
                                    <div className="col-md-6 left-side">
                                        <div className="form-group mb-4">
                                            <label htmlFor="userName" className="col-auto col-form-label">User Name</label>
                                            <div className="col-auto input-control">
                                                <input
                                                    type="text"
                                                    className={`form-control ${touched.userName && errors.userName ? 'is-invalid' : ''}`}
                                                    id="userName"
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)}
                                                    onBlur={() => handleBlur('userName')}
                                                />
                                                {touched.userName && errors.userName && (
                                                    <div className="invalid-feedback">{errors.userName}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 right-side">
                                        <div className="form-group mb-4">
                                            <label htmlFor="password" className="col-auto col-form-label">Password</label>
                                            <div className="col-auto input-control">
                                                <input
                                                    type="text"
                                                    className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                                    id="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    onBlur={() => handleBlur('password')}
                                                />
                                                {touched.password && errors.password && (
                                                    <div className="invalid-feedback">{errors.password}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 left-side">
                                        <div className="form-group mb-4">
                                            <label htmlFor="apiUrl" className="col-auto col-form-label">Api Url</label>
                                            <div className="col-auto input-control">
                                                <input
                                                    type="text"
                                                    className={`form-control ${touched.apiUrl && errors.apiUrl ? 'is-invalid' : ''}`}
                                                    id="apiUrl"
                                                    value={apiUrl}
                                                    onChange={(e) => setApiUrl(e.target.value)}
                                                    onBlur={() => handleBlur('apiUrl')}
                                                />
                                                {touched.apiUrl && errors.apiUrl && (
                                                    <div className="invalid-feedback">{errors.apiUrl}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {Helpers.arrayLength(storeData?.sections) && (
                                    storeData?.sections.map((section, sectionIndex) => (
                                        <div className="row mb-40" key={sectionIndex}>
                                            <h6 className="sec-header pb-3 pt-2 col-12">{section?.section_name}</h6>
                                            <div className={`col-md-${(section?.layouts === 1) ? '12' : (section?.layouts === 3 ? '4 left-side' : '6 left-side')}`}>
                                                {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_1`]) && (
                                                    section.dropped_list[`drop_${sectionIndex}_area_1`].map((area1, index) => {
                                                        let fieldLabel = Helpers.replaceData(area1?.extra_fields?.field_label);
                                                        return (
                                                            <div className="form-group mb-4" key={index}>
                                                                <label htmlFor={area1?.id} className="col-auto col-form-label">
                                                                    {area1?.extra_fields?.field_label}
                                                                </label>
                                                                <div className="col-auto input-control">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={area1?.id}
                                                                        value={formData?.[fieldLabel]}
                                                                        onChange={(e) => handleChange(e, fieldLabel)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                )}
                                            </div>
                                            {(section?.layouts === 2 || section?.layouts === 3 )&& (
                                                <div className={`col-md-${section?.layouts === 2 ? '6' : '4'} right-side`}>
                                                    {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_2`]) && (
                                                        section.dropped_list[`drop_${sectionIndex}_area_2`].map((area2, index) => {
                                                            let fieldLabel = Helpers.replaceData(area2?.extra_fields?.field_label);
                                                            return (
                                                                <div className="form-group mb-4" key={index}>
                                                                    <label htmlFor={area2?.id} className="col-auto col-form-label">
                                                                        {area2?.extra_fields?.field_label}
                                                                    </label>
                                                                    <div className="col-auto input-control">
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            id={area2?.id}
                                                                            value={formData?.[fieldLabel]}
                                                                            onChange={(e) => handleChange(e, fieldLabel)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            )}

                                            {(section?.layouts === 3) && (
                                                <div className="col-md-4 right-side">
                                                    {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_3`]) && (
                                                        section.dropped_list[`drop_${sectionIndex}_area_3`].map((area2, index) => {
                                                            let fieldLabel = Helpers.replaceData(area2?.extra_fields?.field_label);
                                                            return (
                                                                <div className="form-group mb-4" key={index}>
                                                                    <label htmlFor={area2?.id} className="col-auto col-form-label">
                                                                        {area2?.extra_fields?.field_label}
                                                                    </label>
                                                                    <div className="col-auto input-control">
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            id={area2?.id}
                                                                            value={formData?.[fieldLabel]}
                                                                            onChange={(e) => handleChange(e, fieldLabel)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                )}
                                <button type="submit" className="btn btn-success">{webhookId ? 'Update' : 'Submit'}</button>
                            </form>
                        </main>
                    </div>
                </main>
            {/* </div> */}
        </>
    )
}

export default IntegrationForm;
