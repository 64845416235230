import React from 'react';
import { useDroppable } from '@dnd-kit/core';

const FieldDroppableArea = ({ id, children, sectionIndex, layouts }) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
    data: {
      field: {
        sectionIndex,
        area : id
      },
      fieldDroppable: true
    }
  });
  return (
    <div ref={setNodeRef} className={`col-md-${(layouts === 3) ? '4' : (layouts === 2 ? '6' : '12')} droppable-area ${isOver ? 'droppable-area-hover' : ''}`}>
      {children}
    </div>
  );
};

export default FieldDroppableArea;
