import React, { useEffect, useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getItem } from "../../Services/localService";

const EmailComposer = () => {
    const user = getItem('user');
    let workflowList = useSelector((state) => state?.workflow);

    let [showOffcanvas, setShowOffcanvas] = useState(true);
    let [showTemplateModal, setShowTemplateModal] = useState(false);

    let handleCloseOffcanvas = () => setShowOffcanvas(false);
    let handleShowOffcanvas = () => setShowOffcanvas(true);

    // useEffect(() => {
    //     // dispatch(getEmailTemplateStart(module_id));
    // }, [])

    return (
        <React.Fragment>
            <Offcanvas placement='end' show={showOffcanvas} onHide={handleCloseOffcanvas} className="email-composer timeline-offcanvas" backdrop={true} scroll={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>New Message</Offcanvas.Title>
                </Offcanvas.Header>
                <hr />
                <Offcanvas.Body className="vh-100">
                    <div className="row">
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="left d-flex align-items-start">
                                {/* <h6>{user?.firtName}</h6> */}
                            </div>
                            <div className="ms-auto">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue"
                                        onClick={() => setShowTemplateModal(true)}>
                                        <span>Insert Template</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            {/* template list */}
            <Modal
                show={showTemplateModal}
                onHide={() => setShowTemplateModal(false)}
                backdrop="static"
                keyboard={false}
                className="modal-xl"
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title fs-5" id="emailTemplateFormLabel">
                        Select Template
                    </h5>
                </Modal.Header>
                <Modal.Body style={{ minHeight: '400px' }}>
                    <div className="row">
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="left d-flex align-items-start">
                                <div className="search-box border rounded-pill">
                                    <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    <input type="search" placeholder="Search" className="searchfield bg-white" />
                                </div>
                            </div>
                            <div className="ms-auto">
                                <div className="btn-group">
                                    {/* <button type="button" className="btn btn-primary rounded-pill btn-blue"
                                        onClick={() => setIsShowTemplateList(false)}>
                                        <i className="fa-solid fa-plus me-2"></i>
                                        <span>Create Template</span>
                                    </button> */}
                                    {/* <button type="button" className="btn btn-outline-primary rounded-pill btn-blue"
                                                            onClick={() => { setShowTemplateModal(false); setShowApprovalUserModal(true); }}
                                                        >Cancel</button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="col-md-12">
                                <div className="tbl-container">
                                    <div className="table-responsive">
                                        <table className="table table-hover hover-icon-table users-table mb-0">
                                            <tbody>
                                                {/* {workflowList?.templateData?.length &&
                                                    <>
                                                        {workflowList?.templateData?.map((data) => (
                                                            <tr>
                                                                <td className="fw-semibold">
                                                                    <i class="fa-regular fa-star me-2 fs-10"></i>
                                                                    <span className="cursor-pointer"
                                                                        onClick={() => {
                                                                            setEmailData((form) => ({
                                                                                ...form,
                                                                                templateName: data.rules.templateData.templateName,
                                                                                subject: data.rules.templateData.subject,
                                                                                content: data.rules.templateData.content,
                                                                                signature: null
                                                                            }));
                                                                            setSelectedTemplate(data.rules.templateData);
                                                                            setShowTemplateModal(false);
                                                                            setShowApprovalUserModal(true);
                                                                        }}
                                                                    >{data?.rules?.templateData?.templateName}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="hover-icon" style={{ color: '#338cf0' }}
                                                                        onClick={() => { setSelectedTemplate(data.rules.templateData); setShowPreviewModal(true); }}
                                                                    >Preview</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                } */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default EmailComposer;