import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function HomePageCustomization({ show, handleClose, submitData, name = "", description2= "" }) {
    let [layoutName, setLayoutName] = useState(name);
    let [description, setDescription] = useState(description2);
    let [layoutNameDublicate, setLayoutNameDublicate] = useState(null);
    let subModuleData = useSelector((state) => state?.dashboardComponents);

    let onClickSaveData = () => {
        submitData({
            layoutName,
            description
        });
    }

    useEffect(() => {
        if(subModuleData?.error?.length > 0 && !subModuleData?.status) {
            setLayoutNameDublicate(subModuleData?.error);
        }
    }, [subModuleData?.error]);

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{name} - Edit Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12">
                    <div className="form-floating">
                        <input
                            type="text"
                            className={`form-control ${layoutNameDublicate?.length > 0 ? 'is-invalid' : ''}`}
                            id="homepage"
                            value={layoutName}
                            onChange={(e) => setLayoutName(e?.target?.value)}
                            required
                        />
                        {layoutNameDublicate?.length > 0 && <span className="text-danger mt-1">{layoutNameDublicate}</span>}
                        <label className={`d-inline-block`} htmlFor="homepage">
                            Enter the name
                        </label>
                    </div>
                    <div className="form-floating mt-4">
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e?.target?.value)}
                        />
                        <label className={`d-inline-block`} htmlFor="description">
                            Enter the description
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={!layoutName} onClick={onClickSaveData}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default HomePageCustomization;
