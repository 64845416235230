import { call, put } from "redux-saga/effects";
import { createFiscalYear, getFiscalYear, updateFiscalYear } from "../../../../Services/apiService";
import { createFiscalYearFailure, createFiscalYearSuccess, getFiscalYearFailure, getFiscalYearSuccess, updateFiscalYearFailure, updateFiscalYearSuccess } from "../../../Slices/fiscalYearSlice";

export function* getFiscalYearHandler(actions) {
    try {
        const response = yield call(getFiscalYear, actions?.payload);
        yield put(getFiscalYearSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getFiscalYearFailure(errResponse))
    }
}

export function* createFiscalYearHandler(actions) {
    try {
        const response = yield call(createFiscalYear, actions?.payload);
        yield put(createFiscalYearSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createFiscalYearFailure(errResponse))
    }
}

export function* updateFiscalYearHandler(actions) {
    try {
        const response = yield call(updateFiscalYear, actions?.payload);
        yield put(updateFiscalYearSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateFiscalYearFailure(errResponse))
    }
}