import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    message: null,
    successStatus: null,
    reload: false
}

const personalSettingsSlice = createSlice({
    name: 'personalsettings',
    initialState,
    reducers: {
        getPersonalSettingsStart: (state) => {
            state.isLoading = true;
        },
        getPersonalSettingsSuccess: (state, action) => {
            state.data = action?.payload?.rules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
        },
        getPersonalSettingsFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        updatePersonalSettingsStart: (state, action) => {
            state.isLoading = true;
        },
        updatePersonalSettingsSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        updatePersonalSettingsFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        }
    }
})

export const {
    getPersonalSettingsStart,
    getPersonalSettingsSuccess,
    getPersonalSettingsFailure,
    updatePersonalSettingsStart,
    updatePersonalSettingsSuccess,
    updatePersonalSettingsFailure
} = personalSettingsSlice?.actions

export default personalSettingsSlice?.reducer;