import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Utils } from '../../../Utils';
import { Helpers } from '../../../Helpers';
import Loader from '../../Loader';

function KpiBasic({data = {}, isStatic = false}) {
    let currentOrg = useSelector((state) => state?.org);
    let { subModuleData, isDashboardLoading } = useSelector((state) => state?.dashboardComponents);
    let [countData, setCountData] = useState(0);
    let count = 2000;

    useEffect(() => {
        if (subModuleData?.[data?.moduleId]?.length > 0 && Object?.keys(data)?.length > 0) {
            let datas = Utils.getFilteredRecords(subModuleData?.[data?.moduleId], data?.duration1, data?.duration2);
            let newFilteredData = Utils.filteredDataGet(datas, data); 
            let field = data?.field;
            let calculate = (operation, label) => {
                let values = newFilteredData?.map(record => {
                    let value = 0;
                    for (let section of record?.data || []) {
                        if (section?.section_data?.[label] !== undefined) {
                            value = parseFloat(section.section_data[label]) || 0;
                            break;
                        }
                    }
                    return value;
                });
                switch (operation) {
                    case 'sum': 
                        return values.length ? values.reduce((total, val) => total + val, 0) : 0;
                    case 'min': 
                        return values.length ? Math.min(...values) : 0;
                    case 'max': 
                        return values.length ? Math.max(...values) : 0;
                    case 'average': 
                        return values.length ? values.reduce((total, val) => total + val, 0) / values.length : 0;
                    default: 
                        return 0;
                }                
            };
    
            let getLabel = (prefix) => Helpers.replaceData(field?.replace(new RegExp(`^${prefix} of `), ''));
    
            if (field?.startsWith("Count of - ")) {
                setCountData(newFilteredData?.length || 0);
            } else if (field?.startsWith("Sum of ")) {
                let label = getLabel("Sum");
                setCountData(calculate('sum', label));
            } else if (field?.startsWith("Minimum of ")) {
                let label = getLabel("Minimum");
                setCountData(calculate('min', label));
            } else if (field?.startsWith("Maximum of ")) {
                let label = getLabel("Maximum");
                setCountData(calculate('max', label));
            } else if (field?.startsWith("Average of ")) {
                let label = getLabel("Average");
                setCountData(calculate('average', label));
            }
        }
    }, [subModuleData?.[data?.moduleId], data]);    

    let checkCurrency = (countDatas) => {
        if (/^(Sum|Minimum|Maximum|Average) of /.test(data?.field) || isStatic) {
            let formatter = new Intl.NumberFormat('en', { style: 'currency', currency: (currentOrg?.homeCurrencies?.currency_code || "USD") });
            let valueToFormat = countDatas;
            if (data?.unit?.value) {
                valueToFormat = countDatas / data?.unit?.value;
            }
            let formattedValue = formatter.format(valueToFormat);
            if (data?.unit?.symbol) {
                formattedValue = `${formattedValue.trim()} ${data?.unit?.symbol}`;
            }
            return formattedValue.trim();
        } else {
            return countDatas;
        }
    };      

    return (
        <> 
            {isDashboardLoading && !countData ?
                <div className='chart-loader'><Loader type={'infinitySpin'} isVisible={true} /></div>
                :
                <>
                    {(countData > 0 || isStatic) ? (
                        <div className="count-num fw-bold">{isStatic ? checkCurrency(count) : checkCurrency(countData)}</div>
                    ) : (
                        <div className='h-100 fw-bold align-self-center align-center py-3'>No Data Found</div>
                    )}
                </>
            }
            {isStatic && <small className="text-muted">Basic</small>}
        </>
    );
}
export default KpiBasic;
