import React, { useEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Utils } from '../../Utils';
import { FormModuleModel } from '../ModalPopup/FormModules';
import { handleFieldLabelUpdate } from '../../Utils';  

const SubformItem = ({ subformData, sectionIndex, dispatch, setShowPropertyModal, moduleStoreData, section }) => {
    let dropDownList = Utils.dropDownList?.[subformData?.label?.replace(/\s/g, "")] || [];
    let PropertyModal = FormModuleModel[Utils.propertyModal?.[subformData?.label?.replace(/\s/g, "")]];
    let [showPermissionModal, setShowPermissionModal] = useState(false);
    let [showPropertyModal2, setShowPropertyModal2] = useState(false);
    let dropdownRef = useRef(null);
    let [showDropDown, setShowDropDown] = useState(false);
    let [fieldLabelError, setFieldLabelError] = useState('');

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: subformData.id });
    const currentIndex = moduleStoreData.sections[sectionIndex].selected_list.findIndex(item => item.id === subformData.id);
    const hasLeftField = currentIndex > 0 && moduleStoreData.sections[sectionIndex].selected_list[currentIndex - 1];
    const hasRightField = currentIndex < moduleStoreData.sections[sectionIndex].selected_list.length - 1 && moduleStoreData.sections[sectionIndex].selected_list[currentIndex + 1];

    const sortableStyle = {
        transform: transform ? CSS.Transform.toString({
            ...transform,
            x: hasLeftField || hasRightField ? transform.x : 0,
            y: 0,
        }) : undefined,
        transition,
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const removeSubFormItem = () => {
        dispatch({
            type: 'REMOVE_SUBFORM_ITEM',
            payload: {
                id: subformData?.id,
                sectionIndex
            },
        });
    };

    const editPropertySubmit = (newValue) => {
        dispatch({
            type: 'UPDATE_SUBFORM_EDIT_PROPERTIES',
            payload: {
                sectionIndex,
                id: subformData?.id,
                newValue
            },
        });
    };

    const checkRequired = (key) => {
        return subformData?.extra_fields?.[key];
    };

    const handleAction = (action) => {
        switch (action) {
            case 'Mark as required':
                return () => editPropertySubmit({ ...subformData?.extra_fields, required: !subformData?.extra_fields?.required })
            case 'Do not allow duplicate values':
                return () => editPropertySubmit({ ...subformData?.extra_fields, do_not_allow_duplicate_values: !subformData?.extra_fields?.do_not_allow_duplicate_values })
            case "Remove Field":
                return () => removeSubFormItem();
            case "Set Permission":
                return () => setShowPermissionModal(true);
            case "Edit Properties":
                return () => {
                    setShowPropertyModal(true);
                    setShowPropertyModal2(true);
                }
            default:
                return () => { };
        }
    };

    const handleFieldLabelChange = (event) => {
        let value = event.target.value;
        if(subformData?.input_type === "auto_number") {
            editPropertySubmit({ ...subformData?.extra_fields, field_label: value });
        } else {
            handleFieldLabelUpdate(value, (newLabel) => editPropertySubmit({ ...subformData?.extra_fields, field_label: newLabel }), setFieldLabelError);   
        }
    };

    return (
        <>
            <td className="me-2" style={{ ...sortableStyle, width: (subformData?.input_type === "auto_number") ? '130px' : '208px', whiteSpace: 'nowrap' }}id={subformData?.id} {...attributes} ref={setNodeRef}>
                <div className="input-group">
                    <input type="text" className="form-control" value={subformData?.extra_fields?.field_label} required={subformData?.extra_fields?.required} onChange={handleFieldLabelChange} 
                    />
                    <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" onClick={() => setShowDropDown((flg) => !flg)}>
                        <div className="position-fixed">
                            <ul ref={dropdownRef} className={`dropdown-menu ${showDropDown ? 'show' : ''}`}>
                                {dropDownList?.length > 0 && dropDownList.map((action, dropindex) => (
                                    <li key={dropindex} onClick={handleAction(action)}>
                                        <a className="dropdown-item cursor-pointer">
                                            {(dropindex === 0 && checkRequired('required')) ||
                                            (dropindex === 1 && checkRequired('do_not_allow_duplicate_values')) ? (
                                                <i className="fa fa-check me-1" aria-hidden="true"></i>
                                            ) : null}
                                            {action}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <i className="fa fa-ellipsis-v"></i>
                    </button>
                </div>
                <div {...listeners} className="drag-handle" >
                <small className="form-text text-muted ms-1">{subformData?.label}</small>
                </div>
                {fieldLabelError && (
                    <div
                        className="text-danger"
                        style={{
                            fontSize: '12px',
                            lineHeight: '1.2',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            width: '100%',
                            marginTop: '4px'
                        }}
                    >
                        {fieldLabelError}
                    </div>
                )}
            </td>
            {showPermissionModal && (
                <FormModuleModel.SetPermissionModal
                    show={showPermissionModal}
                    handleClose={() => setShowPermissionModal(false)}
                />
            )}
            {showPropertyModal2 && PropertyModal && (
                <PropertyModal
                    moduleStoreData={moduleStoreData}
                    show={showPropertyModal2}
                    handleClose={() => {
                        setShowPropertyModal(false);
                        setShowPropertyModal2(false);
                    }}
                    item={subformData}
                    editPropertySubmit={editPropertySubmit}
                    section={section}
                />
            )}
        </>
    );
};

export default SubformItem;
