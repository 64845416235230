import { CgProfile } from "react-icons/cg";
import { GoWorkflow } from "react-icons/go";
import { MdDashboard, MdMenuBook } from "react-icons/md";

const routes = [
    {
        name: "Dashboard",
        path: "dashboard",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <h1>Dashboard</h1>,
    },
    {
        name: "Modules",
        path: "form-module",
        icon: <MdMenuBook className="h-6 w-6" />,
        component: <h1>Dashboard</h1>,
        secondary: true,
    },
    {
        name: "Workflow",
        path: "workflow-rules",
        icon: <GoWorkflow className="h-6 w-6" />,
        component: <h1>Dashboard</h1>,
        secondary: true,
    },
    {
        name: "settings",
        icon: <CgProfile className="h-6 w-6" />,
        path: "settings/profile",
        component: <h1>Dashboard</h1>,
    }
];

export default routes;