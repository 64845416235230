const SectionSettingDropDown = ({ handleLayoutChange, section, removeSection }) => {
    return (
        <div className="dropdown">
            <i className="fa fa-cog" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"></i>
            <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                {!section?.subform &&
                    <>
                        <li className="mb-2">
                            <strong>Section Layout</strong>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={"one" + section?.id}
                                    id={"one" + section?.id}
                                    checked={section.layouts === 1}
                                    onChange={() => handleLayoutChange(1)}
                                />
                                <label className="form-check-label" htmlFor={"one" + section?.id}>
                                    Single Column
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={"two" + section?.id}
                                    id={"two" + section?.id}
                                    checked={section.layouts === 2}
                                    onChange={() => handleLayoutChange(2)}
                                />
                                <label className="form-check-label" htmlFor={"two" + section?.id}>
                                    Double Column
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={"three" + section?.id}
                                    id={"three" + section?.id}
                                    checked={section.layouts === 3}
                                    onChange={() => handleLayoutChange(3)}
                                />
                                <label className="form-check-label" htmlFor={"three" + section?.id}>
                                    Three Column
                                </label>
                            </div>
                        </li>
                        <li className="mb-2">
                            <strong>Tab Order</strong>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="tabOrderOptions"
                                    id="leftToRight"
                                    checked={section.tabOrder === 'leftToRight'}
                                    onChange={() => handleLayoutChange({ ...section, tabOrder: 'leftToRight' })}
                                />
                                <label className="form-check-label" htmlFor="leftToRight">
                                    Left to Right
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="tabOrderOptions"
                                    id="topToBottom"
                                    checked={section.tabOrder === 'topToBottom'}
                                    onChange={() => handleLayoutChange({ ...section, tabOrder: 'topToBottom' })}
                                />
                                <label className="form-check-label" htmlFor="topToBottom">
                                    Top to Bottom
                                </label>
                            </div>
                        </li>
                    </>
                }
                {!section?.default && (
                    <li onClick={() => removeSection(section.id)} style={{ cursor: 'pointer', color: 'red' }}>
                        Delete
                    </li>
                )}
            </ul>
        </div>
    );
};

export default SectionSettingDropDown;
