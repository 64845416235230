import React, { useEffect, useState } from "react";
import { convertSelectedModuleListStart, getConvertModuleListStart, getConvertModuleStart, resetState } from "../../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Components/Loader";
import { getSubFormListByIdStart, convertSubformDataStart, resetStateModule } from "../../../Store/Slices/subFormSlice";
import { Helpers } from "../../../Helpers";
import { Utils } from "../../../Utils";

const ModuleConvert = () => {
    let dispatch = useDispatch();
    let { moduleId, subFormId } = useParams();
    let getModuleByID = useSelector((state) => state?.forms);
    let subFormData = useSelector((state) => (state?.subForm || ''));
    let [convertData, setConvertData] = useState(null);
    let [storeData, setStoreData] = useState(null);
    let [convertedDatas, setConvertedDatas] = useState(null);
    let navigate = useNavigate();
    let [skipConvert, setSkipConvert] = useState([]);

    useEffect(() => {
        if(moduleId?.length > 0 && subFormId?.length > 0) {
            dispatch(getSubFormListByIdStart({
                moduleId,
                subModuleId : subFormId
            }));
        }
    }, []);

    useEffect(() => {
        if(moduleId){
            dispatch(convertSelectedModuleListStart(moduleId));
        }
    }, []);

    useEffect(() => {
        if(convertData?.data?.length > 0 && !getModuleByID?.convert_modules?.length) {
            dispatch(getConvertModuleListStart({ selected_modules: convertData?.data?.map(x => ({_id : x?.moduleId, name : x?.name}))}));
        }
    }, [convertData?.data]);
    
    useEffect(() => {
        let rules = getModuleByID?.rules;
        if (rules?.length > 0) {
            let ruleDatas = rules?.[0]?.rules?.selected_modules || [];
            setSkipConvert(ruleDatas?.map(x => ({_id : x?._id, skip : x?.need_confirmation || false})));
            dispatch(getConvertModuleStart(moduleId));
        }
    }, [getModuleByID?.rules]);

    useEffect(() =>{
        if(getModuleByID?.convertData && !convertData){
            setConvertData(getModuleByID?.convertData);
        }
    }, [getModuleByID?.convertData]);

    let formatDataStructures = (data, convertId) => {
        let format = [];
        let assignDefaultValues = (item) => {
            let default_value = item?.extra_fields?.default_value;
            if (item?.input_type === 'multi-select' && default_value && default_value !== "None") {
                return [{ name: default_value }];
            } else {
                return default_value || "";
            }
        }
        data?.sections?.forEach((section, sectionIndex) => {
            if (!format?.[sectionIndex]) {
                format[sectionIndex] = {
                    section_name: section?.section_name,
                    section_data: {}
                }
            }
            if (section?.subform) {
                if (section?.selected_list?.length > 0) {
                    format[sectionIndex].section_data["table_data"] = [
                        Object.assign({}, ...section.selected_list.map(x => ({
                            [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                        })))
                    ];
                }
            } else {
                Object.entries(section?.dropped_list || {}).forEach(([key, value]) => {
                    value?.forEach(item => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                    })
                });
            }
        });
        return {
            moduleId : convertId,
            data: format,
        }
    }

    useEffect(() => {
        if(getModuleByID?.convert_modules?.length > 0) {
            let data = [];
            let subFormNames = [];
            getModuleByID?.convert_modules?.forEach(x => {
                data.push(formatDataStructures(x?.fields, x?._id));
                let subFormName = Utils.getDefaultSubFormNameField(x?.fields?.sections) || "";
                subFormNames.push({
                    _id : x?._id,
                    name : subFormName
                });
            });
            setStoreData({
                moduleId,
                data
            });
            try {
                let ruleDatas = getModuleByID?.rules?.[0]?.rules?.selected_modules || [];
                let updatedSubFormData = [...getModuleByID?.convertData?.data]?.map(item => {
                    let subFormName = subFormNames?.find(x => x?._id === item?.moduleId)?.name || "";
                    let val = "";
                    Object.entries(item?.data || {})?.forEach(([key, value]) => {
                        if (Helpers.replaceData(value) === subFormName) {
                            val = Utils.getFieldToData(key, subFormData?.formsubmodules?.[0]?.data, subFormData?.modules) || "";
                        }
                    })
                    return {
                        ...item,
                        subFormName : val,
                        need_confirmation : ruleDatas?.find(x => x?._id === item?.moduleId)?.need_confirmation || false
                    }
                });
                setConvertData({
                    ...getModuleByID?.convertData,
                    data: updatedSubFormData,
                });
            } catch (err) { }
        }
    }, [getModuleByID?.convert_modules]);

    let convertDatas = (format, keys, data) => {
        return format?.map((module) => ({
            ...module,
            data: module?.data?.map((section) => ({
                ...section,
                section_data: Object.fromEntries(
                    Object.entries(section?.section_data || {}).map(([key, value]) => {
                        let newKey = Helpers.replaceData(key);
                        let moduleKeys = keys?.find((k) => k?.moduleId === module?.moduleId)?.data || {};
                        let mappedKey = Object.entries(moduleKeys).find(
                            ([, v]) => Helpers.replaceData(v) === newKey
                        )?.[0];
                        let newValue = mappedKey && data
                            ?.find((d) => d?.section_data && mappedKey in d?.section_data)
                            ?.section_data?.[mappedKey] || value;
    
                        return [key, newValue];
                    })
                )
            }))
        })) || [];
    }; 

    useEffect(() => {
        if(storeData && subFormData?.formsubmodules?.length > 0) {
            let convertedData = convertDatas(storeData?.data, convertData?.data, subFormData?.formsubmodules?.[0]?.data);
            setConvertedDatas(convertedData);
        }
    }, [storeData, subFormData?.formsubmodules]);

    let convertModuleData = () => {
        let obj = {
            subFormId,
            moduleId,
            name : subFormData?.modules?.name,
            data : convertedDatas?.filter(x => {
                let checkId = skipConvert?.find(y => y._id === x.moduleId);
                return checkId ? !checkId.skip : true;
            })
        }
        dispatch(convertSubformDataStart(obj));
    }

    useEffect(() => {
        if(subFormData?.exit) {
            dispatch(resetStateModule());
            navigate(-1);
        }
    }, [subFormData?.exit]);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        }
    }, []);
    
    return (
        <>
            <Loader isVisible={getModuleByID?.isLoading || subFormData?.isLoading}/>
            <div className="container-fluid p-2">
                <div className="col-md-12">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="card-title">Convert {convertData?.name}</h5>
                            <div className="mt-4">
                                {convertData?.data?.length > 0 && (
                                    convertData?.data?.map((x, index) => (
                                        <div key={index} className="mb-3 d-flex align-items-center">
                                            { x?.need_confirmation && (
                                                <input type="checkbox" className="me-2" onChange={(e) => {
                                                    let skip = [...skipConvert];
                                                    setSkipConvert(skip?.map(y => y?._id === x?.moduleId ? {...y, skip : !e?.target?.checked} : y));
                                                }}/>
                                            )}
                                            <label className="me-3">Create New {x?.name} {x?.subFormName ? '(' + x?.subFormName + ')' : ""}</label>
                                        </div>
                                    ))                                    
                                )}
                                {/* <div className="mb-4">
                                    <label htmlFor="ownerInput" className="form-label">Owner of the New Records</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="ownerInput" value="Kaviraj" readonly />
                                        <span className="input-group-text">
                                            <i className="bi bi-person"></i>
                                        </span>
                                    </div>
                                </div> */}
                                <div className="d-flex">
                                    <button type="submit" className="btn btn-primary me-2" onClick={convertModuleData} disabled={!convertData?.data?.length}>Convert</button>
                                    <button type="button" className="btn btn-secondary" onClick={() => navigate(-1)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModuleConvert;