import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { getModuleByIdStart } from "../../../Store/Slices/formSlice";
import ReactQuill from "react-quill";
import { uploadImageStart } from "../../../Store/Slices/workflowSlice";
import ImgEditor from "./Editor";
import { getCurrentDateTime } from "../../../Components/DateFn";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";
import 'quill/dist/quill.snow.css';

// , _dynamicData 
const EmailTemplateEditor = ({ _content, module_id, id, isEdit, emailData }) => {
    let moduleList = useSelector((state) => state?.forms)?.navbarList;
    let formList = useSelector((state) => state?.forms?.modules);
    let workflowList = useSelector((state) => state?.workflow);
    // console.log('WORKFLOW RESPONSE:', workflowList)

    const dispatch = useDispatch();
    // const quillRef = useRef(null);
    // const { quill, quillRef } = useQuill();

    const { quill, quillRef, Quill } = useQuill({
        modules: { blotFormatter: {} }
    });

    if (Quill && !quill) {
        // const BlotFormatter = require('quill-blot-formatter');
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    const [content, setContent] = useState("");
    const [selectedValue, setSelectedValue] = useState('');
    const [fieldList, setFieldList] = useState([]);
    const [sectionsData, setSectionsData] = useState([]);
    const [templateData, setTemplateData] = useState({
        moduleId: '',
        moduleName: '',
        moduleFieldId: '',
        moduleFieldName: ''
    });
    const [isDisplayOtherModule, setIsDisplayOtherModule] = useState(false);
    let [displayedModules, setDisplayedModules] = useState();

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                updateContent(quill.root.innerHTML)
            });
        }
    }, [quill, Quill]);

    useEffect(() => {
        if (id && isEdit && emailData.rules.templateData.content) {
            setContent(emailData.rules.templateData.content)
        }
    }, [id, isEdit])

    const extractImages = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const images = Array.from(doc.querySelectorAll('img')); // Get all <img> elements
        return images.map((img) => img.src); // Extract the `src` attribute
    };

    const base64ToFile = (base64String, filename) => {
        if (base64String) {
            const arr = base64String?.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
    };

    // useEffect(() => {
    //     if (workflowList.uploadStatus) {
    //         console.log('IMAGE DATA:', workflowList.imageData)

    //         let replacedContent = emailData.content.split(selectedContent).join(workflowList.imageData)
    //         console.log('replaced content:', replacedContent)
    //         _content(replacedContent)
    //     }
    // }, [workflowList.uploadStatus])

    useEffect(() => {
        _content(content);
        // console.log('content:', content)

        const imageUrls = extractImages(content);
        // console.log('extractd values:', imageUrls[0]);
        // console.log('replace--->', JSON.stringify(content).replace(imageUrls[0], "hello"))
        // console.log('content after replace----->', content)
        // let replacedContent = JSON.stringify(content).replace(`/${imageUrls[0]}/g`, "https://accounts.awarious.com/digylax/officeportal/assets/images/logos/opsq.png")
        // let replacedContent = content.split(imageUrls[0]).join('https://accounts.awarious.com/digylax/officeportal/assets/images/logos/opsq.png')
        // console.log('replaced content:', replacedContent)
        if (imageUrls.length) {
            imageUrls.forEach(element => {
                // console.log('is base64?', imageUrls[0].startsWith('data:image/'))
                if (element.startsWith('data:image/')) {
                    // setSelectedContent(element)
                    const file = base64ToFile(element, `uploaded-image-${getCurrentDateTime()}.jpg`);
                    // console.log('file data?', file)
                    const formData = new FormData();
                    formData.append("file", file);
                    try {
                        console.log('try method')
                        // dispatch(uploadImageStart({
                        //     payload: formData
                        // }))
                    } catch (error) {
                        console.error("There was an error uploading the file:", error);
                    }
                }
            });
        }
    }, [content]);

    useEffect(() => {
        if (isDisplayOtherModule) {
            setDisplayedModules(moduleList);
        } else {
            setDisplayedModules(moduleList?.filter((data) => data._id === module_id))
        }
        setTemplateData((form) => ({
            ...form,
            moduleId: '',
            moduleName: '',
            moduleFieldId: '',
            moduleFieldName: ''
        }));
    }, [moduleList, isDisplayOtherModule]);

    useEffect(() => {
        if (templateData?.moduleId && templateData?.moduleName) {
            if (formList?.length > 0) {
                setFieldList([]);
                let sections = formList?.[0]?.fields?.sections;
                setSectionsData(sections.map(option => option.section_name && {
                    label: option.section_name,
                    value: option.section_name
                }))

                for (var i = 0; i < sections.length; i++) {
                    if (sections[i].dropped_list) {
                        let list = sections[i].dropped_list
                        let o_val = Object.values(list)
                        for (var j = 0; j < o_val.length; j++) {
                            for (var k = 0; k < o_val[j].length; k++) {
                                let _value = o_val[j][k];
                                setFieldList(list => [...list, _value])
                            }
                        }
                    }
                }
            }
        }
    }, [formList])

    const handleTemplateData = (e) => {
        const { name, value } = e?.target;
        if (name === 'moduleId') {
            setTemplateData((form) => ({
                ...form,
                [name]: value.split('#')[0],
                moduleName: value.split('#')[1],
                moduleFieldId: '',
                moduleFieldName: ''
            }));
            setFieldList([]);
            dispatch(getModuleByIdStart(value));
        }
        if (name === 'moduleFieldId') {
            setTemplateData((form) => ({
                ...form,
                [name]: value.split('#')[0],
                moduleFieldName: value.split('#')[1]
            }));

            if (templateData.moduleName && value.split('#')[1]) {
                if (quill) {
                    let _selectedValue = '${' + templateData.moduleName + '.' + value.split('#')[1] + '}';
                    const cursorPosition = quill?.getSelection()?.index;
                    if (cursorPosition !== null && cursorPosition) {
                        quill.insertText(cursorPosition, _selectedValue);
                        quill.setSelection(cursorPosition + _selectedValue.length);
                        setContent(quill.root.innerHTML);
                    }
                }
            }

            // if (templateData.moduleName && value.split('#')[1]) {
            //     // new
            //     const quill = quillRef.current.getEditor();
            //     const cursorPosition = quill.getSelection()?.index || 0;
            //     if (cursorPosition) {
            //         let _selectedValue = '${' + templateData.moduleName + '.' + value.split('#')[1] + '}';
            //         quill.insertText(cursorPosition, _selectedValue, "bold");
            //         quill.setSelection(cursorPosition + _selectedValue.length);
            //         setContent(quill.root.innerHTML);  // Save the content after insertion
            //     }
            // }
        }
    }

    const editorRef = React.createRef();

    const handleImageUpload = () => {
        console.log('img upload')
    }

    const updateContent = (content) => {
        console.log('UPDATED CONTENT:', content)
        setContent(content);
    }

    return (
        <div>
            <div className="row align-items-center mb-3">
                <div className="col-12 mb-3">
                    <div className="form-control d-flex p-0"
                        style={{ border: 'none' }}>
                        <input className="form-check-input" type="checkbox" required="" value=""
                            onChange={(e) => setIsDisplayOtherModule(e.target.checked)}
                            name="isDisplayOtherModule"
                            checked={isDisplayOtherModule}
                        />
                        <label className="form-check-label ms-2"> Do you want to include another modules field in the email content?</label>
                    </div>
                </div>
                <label htmlFor="moduleSelect" className="col-2 text-md-end col-form-label">
                    Module
                </label>
                <div className="col-4">
                    <select className="form-select" id="moduleSelect"
                        name="moduleId"
                        value={templateData.moduleId + '#' + templateData.moduleName}
                        onChange={(e) => { handleTemplateData(e) }}>
                        <option>Select Module</option>
                        {
                            displayedModules?.length > 0 && displayedModules?.map((data) => (
                                <option value={data?._id + '#' + data?.name}>{data?.name}</option>
                            ))
                        }
                    </select>
                </div>

                <label htmlFor="moduleSelect" className="col-2 text-md-end col-form-label">
                    Field
                </label>
                <div className="col-4">
                    <select className="form-select" id="moduleSelect"
                        name="moduleFieldId"
                        value={templateData.moduleFieldId + '#' + templateData.moduleFieldName}
                        onChange={(e) => { handleTemplateData(e) }}
                    >
                        <option>Select Field</option>
                        {
                            fieldList?.length > 0 && fieldList?.map((data) => (
                                <option value={data?.id + '#' + data?.extra_fields?.field_label}>{data?.extra_fields?.field_label}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div style={{ height: "200px" }}>
                <div ref={quillRef} />
            </div>

            {/* <ImgEditor updateContent={updateContent} moduleName={templateData.moduleName} moduleFieldName={templateData.moduleFieldName} /> */}
            {/* <ReactQuill
                ref={quillRef}
                theme="snow"
                value={content}
                onChange={(content) => setContent(content)}
                modules={{
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link', 'image'],
                        ['clean']
                    ],
                }}
            /> */}
        </div>
    );
};

export default EmailTemplateEditor;