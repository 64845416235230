import React from 'react';

const Pagination = ({
    currentPage,
    pageSize,
    totalCount,
    onPageSizeChange,
    onPrevPage,
    onNextPage,
}) => {
    let totalPages = Math.ceil(totalCount / pageSize);
    let startRow = (currentPage - 1) * pageSize + 1;
    let endRow = Math.min(currentPage * pageSize, totalCount);
    return (
        <div className="border border-top-0 rounded-bottom ag-paging-panel">
            <span className="ag-paging-page-size">
                <div className="ag-picker-field ag-labeled ag-label-align-left ag-select">
                    <div className="ag-label me-1">Page Size:</div>
                    <select 
                        className="ag-wrapper ag-picker-field-wrapper ag-picker-field-dropdown" 
                        value={pageSize} 
                        onChange={(e) => onPageSizeChange(Number(e.target.value))}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </span>
            <span className="ag-paging-row-summary-panel">
                <span className="ag-paging-row-summary-panel-number">{startRow}</span>
                <span> to </span>
                <span className="ag-paging-row-summary-panel-number">{endRow}</span>
                <span> of </span>
                <span className="ag-paging-row-summary-panel-number">{totalCount}</span>
            </span>

            <span className="ag-paging-page-summary-panel">
                <div 
                    className={`ag-button ag-paging-button ${currentPage === 1 ? 'ag-disabled' : ''}`} 
                    onClick={currentPage > 1 ? onPrevPage : null}
                >
                    <span className="ag-icon ag-icon-previous"></span>
                </div>
                <span className="ag-paging-description">
                    <span className="me-1">Page</span>
                    <span className="ag-paging-number me-1">{currentPage}</span>
                    <span className="me-1">of</span>
                    <span className="ag-paging-number">{totalPages}</span>
                </span>
                <div 
                    className={`ag-button ag-paging-button ${currentPage === totalPages ? 'ag-disabled' : ''}`} 
                    onClick={currentPage < totalPages ? onNextPage : null}
                >
                    <span className="ag-icon ag-icon-next"></span>
                </div>
            </span>
        </div>
    );
};

export default Pagination;
