import React, { useEffect, useState } from "react";
import { createSubFormStart, getSubFormListByIdStart, updateSubFormByIdStart, getSubFormListStart } from "../../../Store/Slices/subFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { Helpers } from "../../../Helpers";
import CustomInput from "../../../Components/formModules/customInputs";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Toast from "../../../Components/Toast";
import Loader from "../../../Components/Loader";
import { existMaterialWebhookStart, resetState, listRefMaterialWebhookStart } from "../../../Store/Slices/webhookSlice";
import DynamicTable from "../../../Components/formModules/dynamicTable";
import { Utils } from "../../../Utils";
import { getAllUserListStart } from "../../../Store/Slices/userSlice";
import AggregateFormItem from "../../../Components/ModalPopup/FormModules/AggregateFormItem";
import { getItem } from "../../../Services/localService";
import AssignmentSubformChange from "../../../Components/ModalPopup/FormModules/AssignmentSubformChange";
import AssignmentModuleChange from "../../../Components/ModalPopup/FormModules/AssignmentModuleChange";
import { getMultiConvertModuleStart, resetState as resetState2 } from "../../../Store/Slices/formSlice";
import { CheckBoxModals } from "../../../Components/ModalPopup/FormModules/CheckBoxModals";
import AddParticipants from "../../../Components/ModalPopup/FormModules/AddParticipants";
import SendInvitationModal from "../../../Components/ModalPopup/FormModules/SendInvitationModal";

const CreateUpdateSubModule = () => {
    let users = useSelector((state) => state?.user);
    let [userList, setUserList] = useState([]);
    let navigate = useNavigate();
    let { id = null, sub_form_id = null } = useParams();
    let dispatch = useDispatch();
    let subFormData = useSelector((state) => (state?.subForm || ''));
    let formList = useSelector((state) => state?.forms);
    let [formSubmit, setFormSubmit] = useState(false);
    let [listItems, setListItems] = useState(null);
    let [storeData, setStoreData] = useState({
        moduleId: id,
        data: []
    });
    let [errors, setErrors] = useState({});
    let [showToast, setShowToast] = useState(false);
    let [productHierarchyValue, setProductHierarchyValue] = useState("");
    let [duplicateCheck, setDuplicateCheck] = useState([]);
    let [matrialNumExistList, setMatrialNumExistList] = useState([]);
    let webhooksData = useSelector((state) => state?.webhooks);
    let [duplicateCheckRefMaterial, setDuplicateCheckRefMaterial] = useState(null);
    let currentUserData = getItem('user');
    // Digylax organization : 67627340b53f54575cf41de6
    let [subFormFieldLabel, setSubFormFieldLabel] = useState("");
    let [subFormName, setSubFormName] = useState("");
    let [mainModuleSubFormChangeModal, setMainModuleSubFormChangeModal] = useState(null);
    let [defaultSubFormChangeData, setDefaultSubFormChangeData] = useState(null);
    let [defaultModuleSubFormChangeData, setDefaultModuleSubFormChangeData] = useState(null);
    let [basedSubFormId, setBasedSubFormId] = useState(null);
    let [basedModuleId, setBasedModuleId] = useState(null);
    let getModuleByID = useSelector((state) => state?.forms);
    let [convertData, setConvertData] = useState(null);
    let updateKeys = {
        formsubmodules : "formsubmodules",
        message : "message",
        exit : "exit",
        isLoading : "isLoading",
        status : "status",
        reload : "reload",
        error : "error"
    }
    let checkKeys = {
        Tasks: ["Lead_Name"],
        Calls: ["Contact_Name"],
        Meetings: ["Contact"]
    }
    let [basedSubFormId2, setBasedSubFormId2] = useState("");
    let [basedModuleId3, setBasedModuleId3] = useState("");
    let [OpenCheckBoxModel, setOpenCheckBoxModel] = useState(null);
    let [openCheckBoxPopup, setOpenCheckBoxPopup] = useState(null);
    let [openParticipantModal, setOpenParticipantModal] = useState(null);
    let [sendInvitationModal, setSendInvitationModal] = useState(false);

    let openCheckBoxFieldModal = (data) => {
        let checkBoxModel = CheckBoxModals[data?.modalName];
        if (checkBoxModel) {
            setOpenCheckBoxModel(() => checkBoxModel);
            setOpenCheckBoxPopup(data?.label);
        } else { 
            setOpenCheckBoxModel(null);
            setOpenCheckBoxPopup(null);
         }
    }
    let setValue = (key, value) => {
        setStoreData((prevStoreData) => {
            return {
                ...prevStoreData,
                data: prevStoreData?.data?.map((item) => {
                    if (item?.section_data?.hasOwnProperty(key)) {
                        return {
                            ...item,
                            section_data: {
                                ...item.section_data,
                                [key]: value,
                            },
                        };
                    }
                    return item;
                }),
            };
        });
        setOpenCheckBoxModel(null);
        setOpenCheckBoxPopup(null);
    }

   useEffect(() => {
        if (defaultModuleSubFormChangeData && mainModuleSubFormChangeModal) {
            if (defaultModuleSubFormChangeData?.moduleId !== basedModuleId) {
                try {
                    if(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label) === "Related_To") {
                        setBasedModuleId3(defaultModuleSubFormChangeData?.name?._id);
                    }
                    setValue(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label), defaultModuleSubFormChangeData?.name)
                    setBasedModuleId(defaultModuleSubFormChangeData?.moduleId);
                    setMainModuleSubFormChangeModal(null);
                } catch (error) { }
            }
        }
    }, [defaultModuleSubFormChangeData]);

    
    useEffect(() => {
        if (defaultSubFormChangeData && mainModuleSubFormChangeModal) {
            if (defaultSubFormChangeData?.subFormId !== basedSubFormId) {
                try {
                    let { moduleId = "", convert = false, field_label = "" } = mainModuleSubFormChangeModal || {};
                    let moduleData = convertData?.[moduleId];
                    let convertKeys = moduleData?.data?.find(x => x?.moduleId === subFormData?.modules?._id)?.data || {};
                    if (moduleData?._id && convert && convertKeys) {
                        Object.entries(convertKeys)?.forEach(([key, value]) => {
                            if(defaultSubFormChangeData?.subFormData?.[key] && Helpers.replaceData(mainModuleSubFormChangeModal?.field_label) !== Helpers.replaceData(value)) {
                                setValue(Helpers.replaceData(value), defaultSubFormChangeData?.subFormData?.[key]);
                            }
                        });
                    }
                    let setValBol = checkKeys[subFormData?.modules?.name || ""]?.includes(Helpers.replaceData(field_label));
                    setBasedSubFormId2(setValBol ? defaultSubFormChangeData?.name?._id : basedSubFormId2);
                    setValue(Helpers.replaceData(field_label), setValBol ? defaultSubFormChangeData?.name?.name : defaultSubFormChangeData?.name);
                    setBasedSubFormId(defaultSubFormChangeData?.subFormId);
                    setMainModuleSubFormChangeModal(null);
                } catch (error) { }
            }
        }
    }, [defaultSubFormChangeData]);

    useEffect(() => {
        let modules = subFormData?.modules;
        if (modules) {
            setSubFormFieldLabel(Utils.getDefaultSubFormNameField(modules?.fields?.sections));
        }
    }, [subFormData?.modules]);

    useEffect(() => {
        if (mainModuleSubFormChangeModal?.moduleId && !convertData?.hasOwnProperty(mainModuleSubFormChangeModal?.moduleId) && mainModuleSubFormChangeModal?.convert) {
            dispatch(getMultiConvertModuleStart(mainModuleSubFormChangeModal?.moduleId));
        }
    }, [mainModuleSubFormChangeModal]);

    useEffect(() => {
        if(getModuleByID?.multiConvertData) {
            setConvertData(getModuleByID?.multiConvertData);
        }
    }, [getModuleByID?.multiConvertData]);

    useEffect(() => {
        if (subFormFieldLabel) {
            let sub = subFormData?.formsubmodules?.[0];
            setSubFormName(Utils.getFieldToData(subFormFieldLabel, sub?.data, subFormData?.modules));
        }
    }, [subFormFieldLabel]);

    useEffect(() => {
        if(users?.data?.length > 0) {
            setUserList(users?.data);
        }
    }, [users?.data]);

    useEffect(() => {
        if (sub_form_id && id) {
            dispatch(getSubFormListByIdStart({
                moduleId: id,
                subModuleId: sub_form_id
            }));
        } else if (id) {
            dispatch(getSubFormListStart({ page: 1, count: 10, id }));
        }
    }, []);

    const formatDataStructures = (data, isValidate = false) => {
        let format = [];
        let newErrors = {};
        let assignDefaultValues = (item) => {
            let default_value = item?.extra_fields?.default_value;
            if (item?.input_type === 'multi-select' && default_value && default_value !== "None") {
                return [{ name: default_value }];
            } else {
                return default_value || "";
            }
        }
        data?.sections?.forEach((section, sectionIndex) => {
            if (!isValidate) {
                if (!format?.[sectionIndex]) {
                    format[sectionIndex] = {
                        section_name: section?.section_name,
                        section_data: {}
                    }
                }
            }
            if (section?.subform && !isValidate) {
                if (section?.selected_list?.length > 0) {
                    format[sectionIndex].section_data["table_data"] = [
                        Object.assign({}, ...section.selected_list.map(x => ({
                            [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                        })))
                    ];
                }
                if (section?.aggregate_list?.length > 0) {
                    format[sectionIndex].section_data["aggregate_list"] = [
                        Object.assign({}, ...section.aggregate_list.map(x => ({
                            [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                        })))
                    ];
                }
            } else {
                Object.entries(section?.dropped_list || {})?.forEach(([key, value]) => {
                    value?.forEach(item => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        if (!isValidate) {
                            format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                        } else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            newErrors[field_label] = errors?.[field_label];
                        } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                        }
                    })
                });
            }
        });
        if (!isValidate) {
            setStoreData({
                moduleId: id,
                data: format
            });
        } else {
            setErrors(newErrors);
        }
        return Object.keys(newErrors).length === 0;
    }

    useEffect(() => {
        if (sub_form_id && id && subFormData?.formsubmodules?.length > 0) {
            let isSubForm = subFormData?.formsubmodules?.[0];
            setStoreData({
                moduleId: isSubForm?.moduleId,
                data: isSubForm?.data,
                ...(basedSubFormId2 ? { basedModuleId: basedSubFormId2 } : {}),
                ...(basedModuleId3 ? { subFormId: basedModuleId3 } : {}),
                ...(subFormData?.modules?.name === "Tasks" && Helpers.isObject(Utils.getSubFormFieldToData("Reminder", storeData?.data)) ? { reminder : { ...Utils.getSubFormFieldToData("Reminder", storeData?.data)}} : {}),
                ...(subFormData?.modules?.name === "Tasks" && Helpers.isObject(Utils.getSubFormFieldToData("Repeat", storeData?.data)) ? { repeat : Utils.generateRepeatDates({ ...Utils.getSubFormFieldToData("Repeat", storeData?.data), dueDate : Utils.getSubFormFieldToData("Due_Date", storeData?.data) || ""})} : {})
            })
        }
    }, [subFormData]);

    useEffect(() => {
        if ((subFormData?.exit) && formSubmit) {
            navigate(Utils.getNavigationUrl(`form-module/sub-form/${id}`));
            return;
        }
    }, [subFormData?.exit, formSubmit]);

    useEffect(() => {
        if (subFormData?.modules?._id && id) {
            let fields = subFormData?.modules?.fields;
            let moduleData = Utils.getModuleFields(fields?.sections || []);
            let moduleFields = [...(moduleData?.moduleFields || []), ...(moduleData?.subformList || [])];
            let user = moduleFields.some(x => x?.fields?.input_type === "user");
            if (user && !userList?.length) {
                dispatch(getAllUserListStart({ page: 1, count: 100 }));
            }
            setListItems(fields);
            if (!sub_form_id) formatDataStructures(fields);
        } else {
            setListItems(null);
        }
    }, [subFormData?.modules]);

    useEffect(() => {
        let checkArray = ["Article Master", "Article Master Generic"]
        if (checkArray.includes(subFormData?.modules?.name)) {
            let productHierarchySection = storeData?.data?.find(x => x?.section_name === "Product Hierarchy")?.section_data;
            if (productHierarchySection) {
                let fields = [
                    productHierarchySection?.Brand_Hierarchy,
                    productHierarchySection?.Footwear_Type,
                    productHierarchySection?.Price_Band_Category,
                    productHierarchySection?.Gender_Group,
                    productHierarchySection?.Category,
                    productHierarchySection?.Fashion_Grade,
                    productHierarchySection?.Sub_Category_Design
                ];
                let newProductHierarchyValue = fields.map(field => Helpers.getNumbersFromString(field) || "").join('');
                if (newProductHierarchyValue !== productHierarchyValue) {
                    setProductHierarchyValue("000" + newProductHierarchyValue);
                }
            }
        }
    }, [storeData?.data, subFormData?.modules?.name]);

    useEffect(() => {
        if (productHierarchyValue) {
            setStoreData(prevData => {
                return {
                    ...prevData,
                    data: prevData.data.map(item =>
                        item.section_name === "Product Hierarchy"
                            ? {
                                ...item,
                                section_data: {
                                    ...item.section_data,
                                    Product_Hierarchy: productHierarchyValue
                                }
                            }
                            : item
                    )
                }
            });
        }
    }, [productHierarchyValue]);

    const handleBlurMaterialNumber = (e) => {
        let value = e.target.value;
        if (!value) return;
        if (!duplicateCheck.includes(value)) {
            dispatch(existMaterialWebhookStart({
                moduleId: storeData?.moduleId,
                Material_Number: value
            }))
            setDuplicateCheck([...duplicateCheck, value]);
        } else if (matrialNumExistList.includes(value)) {
            setErrors({
                ...errors,
                Material_Number: "Material Number Already Exists"
            });
        } else {
            setErrors({
                ...errors,
                Material_Number: ""
            });
        }
    };

    const handleBlurReferenceMaterial = (e) => {
        let value = e.target.value;
        if (!value || duplicateCheckRefMaterial === value) return;
        if (value?.length > 0) {
            dispatch(listRefMaterialWebhookStart({
                moduleId: storeData?.moduleId,
                Material_Number: value
            }));
            setDuplicateCheckRefMaterial(value);
        }
    };

    useEffect(() => {
        let checkArray = ["Article Master", "Article Master Generic"];
        if (webhooksData?.mapping_field && webhooksData?.response?.length > 0 && checkArray.includes(subFormData?.modules?.name)) {
            try {
                let MDMSector = storeData?.data?.find(x => x?.section_name === "MDM Sector")?.section_data;
                let objectFormMDMSector = {};
                let getKeyToValue = (key, value) => {
                    let val = "";
                    for (let x of webhooksData?.response) {
                        if (Array.isArray(x)) {
                            for (let y of x) {
                                if (y[value]) {
                                    val = y[value];
                                    break;
                                }
                            }
                        }
                        if (val) break;
                    }

                    if (val?.length > 0) {
                        let getValueByKey = (key, dataArray) => {
                            for (let item of dataArray) {
                                if (item.startsWith(key)) {
                                    return item;
                                }
                            }
                            return "";
                        };

                        subFormData?.modules?.fields?.sections?.forEach((section, sectionIndex) => {
                            let areaKeys = [`drop_${sectionIndex}_area_1`];
                            if (section?.layouts === 2 || section?.layouts === 3) {
                                areaKeys.push(`drop_${sectionIndex}_area_2`);
                            }
                            if (section?.layouts === 3) {
                                areaKeys.push(`drop_${sectionIndex}_area_3`);
                            }
                            areaKeys.forEach(areaKey => {
                                section?.dropped_list?.[areaKey]?.forEach(item => {
                                    let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                                    if (key === field_label) {
                                        val = getValueByKey(val, item?.extra_fields?.options);
                                    }
                                });
                            });
                        });
                    }

                    return val;
                };

                if (MDMSector) {
                    Object.keys(MDMSector).forEach(key => {
                        let mapValue = webhooksData?.mapping_field?.[key];
                        objectFormMDMSector[key] = mapValue?.length > 0 ? getKeyToValue(key, mapValue) : "";
                    });

                    setStoreData(prevData => ({
                        ...prevData,
                        data: prevData.data.map(item =>
                            item.section_name === "MDM Sector"
                                ? { ...item, section_data: objectFormMDMSector }
                                : item
                        )
                    }));
                }
            } catch (error) {

            }
        }
    }, [webhooksData?.mapping_field, webhooksData?.response]);


    useEffect(() => {
        if (webhooksData?.message?.length > 0) {
            let materialNumberRes = (webhooksData?.matrial_number_exist ? webhooksData?.message : '');
            if (materialNumberRes?.length > 0) {
                setMatrialNumExistList([...matrialNumExistList, duplicateCheck[duplicateCheck.length - 1]]);
            }
            setErrors({
                ...errors,
                Material_Number: materialNumberRes
            });
            dispatch(resetState());
        }
    }, [webhooksData]);

    const submit = (sendEmailModal = false, mailAllow = false) => {
        if (formatDataStructures(subFormData?.modules?.fields, true)) {
            if (sub_form_id) {
                let obj = Utils.getChangeHistory(subFormData?.formsubmodules?.[0]?.data, storeData?.data, `${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`);
                let auditLogMessage = Utils.generateAuditLogMessage(subFormData?.formsubmodules?.[0]?.data, storeData?.data, subFormData?.modules?.name);
                if(["Calls", "Tasks", "Meetings"]?.includes(subFormData?.modules?.name)){
                    obj.details = `Module Name : ${subFormData?.modules?.name || "-"}\n${Utils.getFieldString(storeData?.data, subFormData?.modules)}\nLog : ${obj?.details}`
                }
                let allData = {...storeData, ...(obj?.details?.length > 0 ? { log_details: obj } : {}), audit_log: {msg: auditLogMessage }, ...(basedSubFormId2 ? { basedModuleId: basedSubFormId2 } : {}),
                ...(basedModuleId3 ? { subFormId: basedModuleId3 } : {}),
                ...(subFormData?.modules?.name === "Tasks" && Helpers.isObject(Utils.getSubFormFieldToData("Reminder", storeData?.data)) ? { reminder : { ...Utils.getSubFormFieldToData("Reminder", storeData?.data)}} : {}) }
                dispatch(updateSubFormByIdStart({
                    subModuleId: sub_form_id,
                    payload: allData,
                    updateKeys
                }));
            } else {
                if(Utils.getSubFormFieldToData("Participants", storeData?.data) && !sendEmailModal) {
                    setSendInvitationModal(true);
                    return;
                }
                let auditLogMessage = Utils.generateAuditLogMessage(null, storeData?.data, subFormData?.modules?.name, true);
                let allData = {
                    ...storeData, log_details: {
                        user: `${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`,
                        details: `${subFormName} Created`
                    },
                    audit_log: { msg: auditLogMessage }, ...(basedSubFormId2 ? { basedModuleId: basedSubFormId2 } : {}),
                    ...(basedModuleId3 ? { subFormId: basedModuleId3 } : {}),
                    ...(subFormData?.modules?.name === "Tasks" && Helpers.isObject(Utils.getSubFormFieldToData("Reminder", storeData?.data)) ? { reminder : { ...Utils.getSubFormFieldToData("Reminder", storeData?.data)}} : {})
                };
                dispatch(createSubFormStart({ data : allData}));
            }
            setErrors({});
            setFormSubmit(true);
        } else {
            setShowToast(true);
            toast.error("kindy fill the all required details", {
                autoClose: 1000,
                onClose: () => {
                    setShowToast(false);
                }
            });
        }
    }

    useEffect(() => {
        return () => {
            dispatch(resetState2());
        }
    }, []);

    let addressMove = (index, from, to) => {
        try {
            let cloneStoreData = { ...storeData };
            let fromDatas = subFormData?.modules?.fields?.sections?.[index]?.dropped_list?.[from];
            let toDatas = subFormData?.modules?.fields?.sections?.[index]?.dropped_list?.[to];
            fromDatas?.forEach((x, i) => {
                let fromField = Helpers.replaceData(x?.extra_fields?.field_label);
                let toField = Helpers.replaceData(toDatas?.[i]?.extra_fields?.field_label);
                cloneStoreData.data[index].section_data[toField] = cloneStoreData?.data?.[index]?.section_data?.[fromField] || cloneStoreData.data[index].section_data[toField] || "";
            });
            setStoreData(cloneStoreData);
        } catch (error) { console.log(error)}
    }

    return (
        <>
            <Loader isVisible={subFormData?.isLoading} />
            {showToast && <Toast />}

            <main className="col-lg-12 ms-sm-auto px-md-0 content-section lead-create-form">
                <div className="mb-40">
                    <header className="navbar navbar-expand-lg fixed-top bg-white border-bottom border-light-subtle px-0 tb-header">
                        <nav className="container-fluid flex-wrap flex-lg-nowrap">
                            <ul className="navbar-nav flex-row btn-group">
                                <li className="nav-item text-nowrap">
                                    <div className="sec-title h5 mb-0 ps-2 pe-3">{sub_form_id ? 'Update' : 'Create'} {subFormData?.modules?.name}</div>
                                </li>
                                <li className="nav-item text-nowrap">
                                    <a className="ps-3 cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`form-module/update/${id}`))}>Edit Page Layout</a>
                                </li>
                            </ul>
                            <ul className="navbar-nav flex-row btn-group">
                                <li className="nav-item text-nowrap">
                                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => navigate(Utils.getNavigationUrl(`form-module/sub-form/${id}`))}>
                                        <span>Cancel</span>
                                    </button>
                                </li>
                                <li className="nav-item text-nowrap">
                                    <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => submit(false, false)}>
                                        <span>Save</span>
                                    </button>
                                </li>
                                <li className="nav-item text-nowrap d-md-none">
                                    <button className="nav-link px-3 text-dark" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu"
                                        aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="fa-solid fa-bars"></i>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </header>
                </div>
                <form className="px-md-4 mt-5 pt-2">
                    {Helpers.arrayLength(listItems?.sections) && (
                        listItems.sections.map((section, sectionIndex) => (
                            <div className="row mb-3" key={sectionIndex}>
                                {/* <h6 className="sec-header pb-3 pt-2 col-12">{section?.section_name}</h6> */}
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="sec-header pb-3 pt-2">{section?.section_name}</h6>
                                    {((listItems?.singular_form === "Sales Orders" || listItems?.singular_form === "Quotes") && section?.section_name === "Address Information") && (
                                        <div className="dropdown">
                                            <a className="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                Copy Address
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <li><a className="dropdown-item" onClick={() => addressMove(sectionIndex, "drop_1_area_1", "drop_1_area_2")}>Billing to Shipping</a></li>
                                                <li><a className="dropdown-item" onClick={() => addressMove(sectionIndex, "drop_1_area_2", "drop_1_area_1")}>Shipping to Billing</a></li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                {section?.subform ?
                                    <>
                                        {
                                            (section?.selected_list?.length > 0 && storeData?.data?.[sectionIndex]?.section_data?.["table_data"]?.length > 0) && (
                                                <div className="col-md-12">
                                                    <div className="tbl-container rounded-table">
                                                        <DynamicTable section={section} storeData={storeData} sectionIndex={sectionIndex} setStoreData={setStoreData} preview={false} users={userList} lookupData={subFormData?.lookupData}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (section?.aggregate_list?.length > 0 &&
                                                <AggregateFormItem aggregate_list={section?.aggregate_list} storeData={storeData} setStoreData={setStoreData} sectionIndex={sectionIndex}/>
                                            )
                                        }
                                    </> :
                                    <>
                                        { section?.dropped_list && Object.entries(section?.dropped_list || {}).map(([key, value], index) => (
                                            value?.length > 0 && (
                                                <div key={index} className={`col-md-${section?.layouts === 1 ? '12' : section?.layouts === 2 ? '6' : '4'} ${key?.includes('area_1') ? 'left-side' : 'right-side'}`}>
                                                    {value.map(x => (
                                                        <CustomInput
                                                            key={x?.id}
                                                            items={x}
                                                            sectionIndex={sectionIndex}
                                                            storeData={storeData}
                                                            setStoreData={setStoreData}
                                                            error={errors[Helpers.replaceData(x.extra_fields.field_label)]}
                                                            listItems={listItems}
                                                            setErrors={setErrors}
                                                            preview={false}
                                                            handleBlurMaterialNumber={handleBlurMaterialNumber}
                                                            handleBlurReferenceMaterial={handleBlurReferenceMaterial}
                                                            users={userList}
                                                            lookupData={subFormData?.lookupData}
                                                            variants={subFormData?.variants}
                                                            modules={subFormData?.modules}
                                                            setMainModuleSubFormChangeModal={setMainModuleSubFormChangeModal}
                                                            customInputs={checkKeys[subFormData?.modules?.name || '']}
                                                            openCheckBoxFieldModal={(val) => openCheckBoxFieldModal(val)}
                                                            openParticipantModal={(val => setOpenParticipantModal(val))}
                                                        />
                                                    ))}
                                                </div>
                                            )
                                        ))}
                                    </>
                                }
                            </div>
                        ))
                    )
                    }
                </form>
            </main>
            {(mainModuleSubFormChangeModal && mainModuleSubFormChangeModal?.is_module_lookup) && <AssignmentModuleChange show={!!mainModuleSubFormChangeModal} handleClose={() => {
                setMainModuleSubFormChangeModal(null);
            }} moduleId={(Utils.getSubFormFieldToData(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label), storeData?.data)?._id || "")} setDefaultModuleSubFormChangeData={setDefaultModuleSubFormChangeData} />}
            {(mainModuleSubFormChangeModal && !mainModuleSubFormChangeModal?.is_module_lookup) && <AssignmentSubformChange show={!!mainModuleSubFormChangeModal} handleClose={() => {
                setMainModuleSubFormChangeModal(null);
            }} moduleId={checkKeys[subFormData?.modules?.name || '']?.includes(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label)) ? (Utils.getSubFormFieldToData("Related_To", storeData?.data)?._id || "") : mainModuleSubFormChangeModal?.moduleId} subFormId={checkKeys[subFormData?.modules?.name || '']?.includes(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label)) ? basedSubFormId2 : Utils.getSubFormFieldToData(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label), storeData?.data)?._id || ""} setDefaultSubFormChangeData={setDefaultSubFormChangeData} title={checkKeys[subFormData?.modules?.name || '']?.includes(Helpers.replaceData(mainModuleSubFormChangeModal?.field_label)) ? "" : mainModuleSubFormChangeModal?.related_list_title}/>}
            {openCheckBoxPopup && OpenCheckBoxModel && <OpenCheckBoxModel show={!!openCheckBoxPopup} handleClose={() => {
                setOpenCheckBoxModel(null);
                setOpenCheckBoxPopup(null);
            }} submitData={(data) => {
                setValue(Helpers.replaceData(openCheckBoxPopup), data);
            }} isData={Utils.getSubFormFieldToData(Helpers.replaceData(openCheckBoxPopup), storeData?.data) || {}} />}
            {openParticipantModal && <AddParticipants show={!!openParticipantModal} handleClose={() => {
                setOpenParticipantModal(null);
            }} submitData={(data) => {
                setValue(Helpers.replaceData(openParticipantModal), data);
            }} isData={Utils.getSubFormFieldToData(Helpers.replaceData(openParticipantModal), storeData?.data) || {}}/>}
            { sendInvitationModal && <SendInvitationModal show={sendInvitationModal} handleClose={() => setSendInvitationModal(false)} submitData={(flg) => {
                submit(true, flg);
                setSendInvitationModal(false);
            }}/>}
        </>
    );
};

export default CreateUpdateSubModule;