import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../../formModules/customInputs';
import { Helpers } from '../../../Helpers';
import DynamicTable from "../../../Components/formModules/dynamicTable";
import SubformHistory from './SubformHistory';
import { useDispatch, useSelector } from 'react-redux';
import { subFormModuleLogStart } from '../../../Store/Slices/subFormSlice';
import Loader from '../../Loader';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import { Utils } from '../../../Utils';

const PreviewModal = ({ show, moduleStoreData, handleClose, preview, storeData, subFormId, moduleId }) => {
  let navigate = useNavigate();
  let [showOffcanvas, setShowOffcanvas] = useState(false);
  let handleCloseOffcanvas = () => setShowOffcanvas(false);
  let handleShowOffcanvas = () => setShowOffcanvas(true);
  let dispatch = useDispatch();
  let formsubmodules = useSelector((state) => state?.subForm);
  let [log, setLog] = useState([]);

  useEffect(() => {
    if (moduleId?.length > 0 && showOffcanvas && !formsubmodules?.moduleLogs?.length && !formsubmodules?.moduleMessage) {
      dispatch(subFormModuleLogStart({
        moduleId,
        subFormId
      }))
    }
  }, [showOffcanvas]);

  useEffect(() => {
    if (formsubmodules?.moduleLogs?.length > 0) {
      setLog(formsubmodules?.moduleLogs);
    }
  }, [formsubmodules?.moduleLogs]);

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable size="xl" dialogClassName="preview-modal">
        <Modal.Header closeButton> Preview {moduleStoreData?.plural_form || ''}
          {moduleId?.length > 0 && (
            <>
              <button type="button" title="Edit" className="ms-3 btn px-2 rounded-2 btn-digy-dark-outline" onClick={() => navigate(Utils.getNavigationUrl(`form-module/sub-form/update/${moduleId}/${subFormId}`))}>
                <i className="fa fa-edit"></i>
              </button>
              <button type="button" title="History" className="ms-3 btn px-2 rounded-2 btn-digy-dark-outline" onClick={handleShowOffcanvas}>
                <i className="fa fa-history"></i>
              </button>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <main className="col-lg-12 ms-sm-auto px-md-4 pt-md-4 content-section lead-create-form">
            <div className="mt-4">
              {Helpers.arrayLength(moduleStoreData?.sections) && (
                moduleStoreData?.sections.map((section, sectionIndex) => (
                  <div className="row mb-40" key={sectionIndex}>
                    <h6 className="sec-header pb-3 pt-2 col-12">{section?.section_name}</h6>
                    {
                      section?.subform ?
                        <>
                          {
                            (section?.selected_list
                              ?.filter(x => !['auto_number', 'rollup', 'formula'].includes(x?.input_type))
                              ?.length > 0) && (
                              <div className="col-md-12">
                                <div className="tbl-container rounded-table">
                                  <DynamicTable section={{...section, selected_list: section?.selected_list.filter(x=> !['Auto Number', 'Formula', 'Rollup Summary'].includes(x?.label))}} sectionIndex={sectionIndex} preview={true} />
                                </div>
                              </div>
                            )
                          }
                        </> :
                        <>
                          {section?.dropped_list && Object.entries(section?.dropped_list || {}).map(([key, value], index) => (
                            value?.length > 0 && (
                              <div key={index} className={`col-md-${section?.layouts === 1 ? '12' : section?.layouts === 2 ? '6' : '4'} ${key?.includes('area_1') ? 'left-side' : 'right-side'}`}>
                                {value
                                 .filter(x => !['auto_number', 'rollup', 'formula'].includes(x?.input_type))
                                 .map(x => (
                                  <CustomInput
                                    key={x?.id}
                                    items={x}
                                    sectionIndex={sectionIndex}
                                    preview={preview}
                                    storeData={storeData}
                                  />
                                ))}
                              </div>
                            )
                          ))}
                        </>
                    }
                  </div>
                ))
              )
              }
            </div>
          </main>
        </Modal.Body>
      </Modal>
      <Offcanvas placement='end' show={showOffcanvas} onHide={handleCloseOffcanvas} className="timeline-offcanvas" backdrop={true} scroll={false}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>History</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={`${formsubmodules?.isLoading ? "vh-100" : ""}`}>
          {formsubmodules?.isLoading && (
            <Loader isVisible={true} isModal={true} overall={false} />
          )}
          <SubformHistory log={log} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default PreviewModal;