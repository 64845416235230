import React, { useEffect } from 'react';
import { Helpers } from '../../../Helpers';

function AggregateFormItem({ aggregate_list, storeData, setStoreData, sectionIndex }) {
    let aggregateList = storeData?.data?.[sectionIndex]?.section_data?.aggregate_list || {};
    let tableData = storeData.data[sectionIndex]?.section_data?.table_data || [];

    let storeAggregateValue = (key, value, directAssignVale = null) => {
        setStoreData(prevData => ({
            ...prevData,
            data: prevData.data.map((item, index) =>
                index === sectionIndex
                    ? {
                        ...item,
                        section_data: {
                            ...item.section_data,
                            ...(directAssignVale ? {aggregate_list : directAssignVale} : {aggregate_list: { ...item.section_data?.aggregate_list, [key]: value }})
                        }
                    }
                    : item
            )
        }));
    };

    let getAggregateValue = (item) => {
        let { aggregate_function, subform_field } = item?.extra_fields || {};
        let values = tableData.map(x => Number(x?.[Helpers.replaceData(subform_field)])).filter(Boolean);
        let val = 0;
        if (values.length > 0) {
            switch (aggregate_function) {
                case "SUM":
                    val = values.reduce((acc, curr) => acc + curr, 0);
                    break;
                case "MIN":
                    val = Math.min(...values);
                    break;
                case "MAX":
                    val = Math.max(...values);
                    break;
                case "AVG":
                    val = values.reduce((acc, curr) => acc + curr, 0) / values.length;
                    break;
                default:
                    val = 0;
            }
        }
        return val || 0;
    };

    let getFormulaValue = (item, data) => {
        let expression = item?.extra_fields?.formula_expression;
        let calculateFormula = (formula, product) => (
            formula?.replace(/\${(.*?)}/g, (match, variable) => {
                let keys = variable?.split('.');
                return product?.[Helpers.replaceData(keys?.[1])] || 0;
            })
        );
        if (Object.keys(data).length > 0) {
            let processedFormula = calculateFormula(expression, data);
            let result = eval(processedFormula);
            return result;
        }
        return 0;
    };

    let handleBlurEvent = () => {
        let data = {...aggregateList}
        for (let x of aggregate_list) {
            if (x?.input_type === "formula") {
                let result = getFormulaValue(x, data);
                data[Helpers.replaceData(x?.extra_fields?.field_label)] = result;
            }
        }
        storeAggregateValue("", "", data);
    };

    useEffect(() => {
        if (aggregate_list?.length > 0) {
            let data = {...aggregateList};
            for (let x of aggregate_list) {
                if (x?.input_type === "aggregate") {
                    let result = getAggregateValue(x);
                    data[Helpers.replaceData(x?.extra_fields?.field_label)] = result;
                } else if (x?.input_type === "formula") {
                    let result = getFormulaValue(x, data);
                    data[Helpers.replaceData(x?.extra_fields?.field_label)] = result;
                }
            }
            storeAggregateValue("", "", data);
        }
    }, [tableData]);

    return (
        <div className="row ms-0 pe-0">
            {aggregate_list?.map((x, index) => (
                <div key={index} className="col-md-4 offset-md-8 d-flex justify-content-end mt-3">
                    <div className="form-floating">
                        {x?.input_type === "aggregate" && (
                            <input
                                type="text"
                                className="form-control"
                                placeholder={x?.label}
                                value={aggregateList?.[Helpers.replaceData(x?.extra_fields?.field_label)] || ''}
                                readOnly
                            />
                        )}
                        {x?.input_type === "formula" && (
                            <input
                                type="text"
                                className="form-control"
                                placeholder={x?.label}
                                value={aggregateList?.[Helpers.replaceData(x?.extra_fields?.field_label)] || ''}
                                readOnly
                            />
                        )}
                        {x?.input_type === "number" && (
                            <input
                                type="number"
                                className="form-control"
                                placeholder={x?.label}
                                value={aggregateList?.[Helpers.replaceData(x?.extra_fields?.field_label)] || ''}
                                onChange={(e) => storeAggregateValue(Helpers.replaceData(x?.extra_fields?.field_label), e.target.value)}
                                onBlur={handleBlurEvent}
                            />
                        )}
                        {x?.input_type === "decimal" && (
                            <input
                                type="number"
                                className="form-control"
                                placeholder={x?.label}
                                value={aggregateList?.[Helpers.replaceData(x?.extra_fields?.field_label)] || ''}
                                onChange={(e) => storeAggregateValue(Helpers.replaceData(x?.extra_fields?.field_label), e.target.value)}
                                onBlur={handleBlurEvent}
                                step="any"
                            />
                        )}
                        {x?.input_type === "currency" && (
                            <input
                                type="number"
                                className="form-control"
                                placeholder={x?.label}
                                value={aggregateList?.[Helpers.replaceData(x?.extra_fields?.field_label)] || ''}
                                onChange={(e) => storeAggregateValue(Helpers.replaceData(x?.extra_fields?.field_label), e.target.value)}
                                onBlur={handleBlurEvent}
                                step="any"
                            />
                        )}
                        <label className={`d-inline-block`} htmlFor={x?.id}>
                            {x?.extra_fields?.field_label}
                        </label>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AggregateFormItem;
