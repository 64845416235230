import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function BodyContent({ formData, handleChange, subFormList }) {
    return (
        <Form>
            <div className="mb-3">
                <label htmlFor="fieldInput" className="form-label">Field Label *</label>
                <input
                    name="field_label"
                    type="text"
                    className="form-control"
                    id="fieldInput"
                    placeholder="Enter field label"
                    value={formData.field_label}
                    onChange={handleChange}
                />
            </div>

            <div className="mb-2">
                <label htmlFor="aggregateFunction" className="form-label">Aggregate Function</label>
                <select id="aggregateFunction"
                    name="aggregate_function"
                    className="form-select"
                    value={formData.aggregate_function}
                    onChange={handleChange}>
                    <option value="SUM">SUM</option>
                    <option value="AVG">AVG</option>
                    <option value="MAX">MAX</option>
                    <option value="MIN">MIN</option>
                </select>
            </div>

            <div className="mb-2">
                <label htmlFor="subformField" className="form-label">Subform Field</label>
                <select id="subformField"
                    name="subform_field"
                    className="form-select"
                    value={formData.subform_field}
                    onChange={handleChange}>
                    {subFormList?.map(x => (
                        <option key={x?.extra_fields?.field_label} value={x?.extra_fields?.field_label}>
                            {x?.extra_fields?.field_label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-2">
                <label htmlFor="decimalPlaces" className="form-label">Decimal Places</label>
                <select id="decimalPlaces"
                    name="decimal_places"
                    className="form-select"
                    value={formData.decimal_places}
                    onChange={handleChange}>
                    {Array.from({ length: 10 }, (_, i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>
            </div>
        </Form>
    )
}

function AggregateProperties({ show, handleClose, item, editPropertySubmit, section, newAggregate = false }) {
    let initialData = item?.extra_fields;
    let [subFormList] = useState(section?.selected_list?.filter(x =>( x?.input_type === "number" ||  x?.input_type === "formula")));
    const [formData, setFormData] = useState({
        field_label: initialData?.field_label,
        aggregate_function: initialData?.aggregate_function,
        subform_field: initialData?.subform_field,
        decimal_places: initialData?.decimal_places
    });

    const handleChange = (e) => {
        let { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = () => {
        editPropertySubmit(formData);
        handleClose();
    };

    useEffect(() => {
        if(newAggregate) {
            editPropertySubmit(formData);
        }
    }, [newAggregate, formData]);

    return (
        newAggregate ?
            <>
                <BodyContent formData={formData} handleChange={handleChange} subFormList={subFormList}/>
            </> :  <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Aggregate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BodyContent formData={formData} handleChange={handleChange} subFormList={subFormList}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            Done
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
    );
}
export default AggregateProperties;
