import React from 'react';
import { Modal } from 'react-bootstrap';

function ConfirmationModal({ show, handleClose, data, handleSuccess, alertInfo }) {
    let title = data?.firstName || data?.name || data?.config_name || data?.layout_name || "";
    title = (typeof title == "string" ? title : '');
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{alertInfo?.title ? alertInfo?.title : 'Delete Confirmation'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{alertInfo?.content ? alertInfo?.content : `Are you sure you want to delete ${title} ?`}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>
                    {alertInfo?.btnDeclineTitle ? alertInfo?.btnDeclineTitle : "Cancel"}
                </button>
                <button className="btn btn-danger" onClick={() => handleSuccess(data?._id)}>
                    {alertInfo?.buttonTitle ? alertInfo?.buttonTitle : 'Delete'}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;
