import React, { useState } from "react";
import waProviderImg from '../../../Assets/images/general-settings/whatsapp.png';
import waLogoIcon from '../../../Assets/images/general-settings/whatsapp-logo.png';

const BusinessMessaging = () => {
    const [businessMessaging, SetbusinessMessaging] = useState(true);
    const [whatsAppBusiness, SetwhatsAppBusiness] = useState(false);

    const showBusinessMessaging = () => {
        SetbusinessMessaging(true);
        SetwhatsAppBusiness(false);
    }

    const showWhatsAppBusiness = () => {
        SetbusinessMessaging(false);
        SetwhatsAppBusiness(true);
    }

    const [waIntegrateCheck, SetWaIntegrateCheck] = useState(true);

    const termsWaIntegrateCheck = (event) => {
        SetWaIntegrateCheck(event.target.checked);
    };

    return (
    <>
    <div className="settings bm-services">
        {/* Business messaging */}
        <div className={`busi-mess ${businessMessaging ? 'd-block':'d-none'}`}>
            <div className="d-flex flex-row mt-4">
                <div className="left-con">
                    <h5 className="fw-semibold">Business Messaging</h5>
                    <p className="w-75">
                    If you are looking for ways to make your business more visible & accessible, 
                    communicate instantly with your leads/contacts, you need to integrate your 
                    business messaging platform for your Digylax MDM. When potential customers contact you 
                    through business messaging, they'll automatically land in your sales pipeline.
                    </p>
                </div>
                <div className="right-con d-flex flex-column align-items-end ms-auto">
                    <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn">
                        <i className="fa-regular fa-circle-question me-2"></i> Help
                    </button>
                </div>
            </div>

            <div className="bm-providers-list border-top pt-1 mt-4">
                <p>Business Messaging Providers</p>
                <div className='row'>
                    <div className='col-md-6 col-xl-4 col-xxl-3'>
                        <div className="provider-list border rounded-2 text-center pt-4 mb-3">
                            <div className="provider-img mt-2">
                                <img src={waProviderImg} alt="WhatsApp" width={140} height={42} />
                            </div>
                            <p className="provirder-con p-3 px-xxl-2">
                                Personally connect with your customers and grow your business with the world's most popular messaging app
                            </p>
                            <div className="mt-5 mb-4 d-flex flex-center">
                                <button type="button" className="btn btn-light-info rounded-pill" 
                                onClick={showWhatsAppBusiness}>
                                    Details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* // Business messaging END // */}

        {/* WhatsApp For Business */}
        <div className={`wa-for-busi card p-0 ${whatsAppBusiness ? 'd-block':'d-none'}`}>
            <div className="card-header d-flex align-items-center bm-bg-gray border-light-subtle py-3">
                <div className="d-flex align-items-center me-auto">
                    <i className="fa fa-arrow-left fs-4 text-secondary cursor-pointer" 
                    onClick={showBusinessMessaging}></i>
                    <img className="mx-2" src={waLogoIcon} alt="WhatsApp" width={48} height={48} />
                    <span className="fs-5">WhatsApp For Business</span>
                </div>
                <div className="ms-auto">
                    <div className="btn-group">                        
                        <button type="button" className="btn btn-success rounded-pill me-0" 
                        data-bs-toggle="modal" data-bs-target="#integrateModal">
                            <span>Integrate</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <p>
                    Connect with your customers on Whatsapp in a simple, reliable and secure way. 
                    Converse with your customers instantly, deliver important notifications and grow your business.
                </p>
                <p className="fw-semibold">Key Features</p>
                <ul>
                    <li>Save and reuse messages you frequently send so you can easily answer common questions in no time.</li>
                    <li>Highlight your products and services and send them as a message, document or image through WhatsApp.</li>
                    <li>Generate leads from your WhatsApp business account and add them to your sales pipeline.</li>
                    <li>Make sure your WhatsApp leads never fall through the cracks by associating them with deals, tasks and notes.</li>
                    <li>
                        When a potential texts your brand through WhatsApp, the sales person can view the message 
                        and respond right from the Digylax MDM with 360 degree contextual information
                    </li>
                </ul>
            </div>
        </div>
        {/* // WhatsApp For Business END // */}

        {/* WhatsApp Integrate Modal */}
        <div className="modal fade" id="integrateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="integrateLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable wa-integrate-modal">
                <div className="modal-content">
                    <div className="modal-body pb-0 px-xl-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="modal-title fs-5 fw-semibold" id="integrateLabel">WhatsApp Business</h5>
                            <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn">
                                <i className="fa-regular fa-circle-question me-2"></i> Help
                            </button>
                        </div>
                        <div className="max-w-90p">
                            <p>
                                Please make sure you meet the following prerequisites to successfully 
                                integrate your WhatsApp business account with Digylax MDM :
                            </p>
                            <ol className="ps-3">
                                <li>A verified Facebook Business Manager account.</li>
                                <li>A WhatsApp Business account.</li>
                                <li>
                                    A registered phone number to associate with your WhatsApp Business account.
                                    The phone number must meet the requirements mentioned <a href="https://developers.facebook.com/docs/whatsapp/on-premises/phone-numbers" target="_blank">here</a>.
                                </li>
                            </ol>
                            <p>
                                To use the integration WhatsApp business requires you to agree to the below WhatsApp terms of Service
                            </p>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="waIntegrateCheck" checked={waIntegrateCheck} 
                                onChange={termsWaIntegrateCheck} />
                                <label className="form-check-label" htmlFor="waIntegrateCheck">
                                    I have read and agree to the <a target="_blank">WhatsApp client ISV Terms</a> & <a target="_blank">WhatsApp Client Terms</a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-primary rounded-pill" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary rounded-pill" disabled={!waIntegrateCheck}>Enable</button>
                    </div>
                </div>
            </div>
        </div>
        {/* // WhatsApp Integrate Modal // */}

    </div>
    </>
    )
}
export default BusinessMessaging;