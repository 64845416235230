import React, { useState } from 'react';  
import Modal from 'react-bootstrap/Modal';  
import Form from 'react-bootstrap/Form';  
import Button from 'react-bootstrap/Button';  

function SetPermissionModal({ show, handleClose, item }) {
  const [permissions, setPermissions] = useState({
    administrator: '',
  });

  const handlePermissionChange = (profile, value) => {
    setPermissions((prevState) => ({
      ...prevState,
      [profile]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Permissions:', permissions);
    handleClose();
  };
  
  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
      <Modal.Header closeButton>
        <Modal.Title>Set Permission - Secondary Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Form.Label style={{ width: '20%' }}>Profiles</Form.Label>
            <Form.Label>Read & Write</Form.Label>
            <Form.Label>Read Only</Form.Label>
            <Form.Label>Don't Show</Form.Label>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Form.Label style={{ width: '20%' }}>Administrator</Form.Label>
            <Form.Check
              inline
              type="radio"
              name="administrator"
              onChange={() => handlePermissionChange('administrator', 'readWrite')}
            />
            <Form.Check
              inline
              type="radio"
              name="administrator"
              onChange={() => handlePermissionChange('administrator', 'readOnly')}
            />
            <Form.Check
              inline
              type="radio"
              name="administrator"
              onChange={() => handlePermissionChange('administrator', 'dontShow')}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SetPermissionModal;
