import { useDroppable, DndContext } from '@dnd-kit/core';
import React, { useReducer, useState, useEffect, useRef } from 'react';
import SectionSortableItem from "../../Components/formModules/sectionSortableItem";
import SubformItem from "../../Components/formModules/subformItem";
import { Utils } from '../../Utils';
import Aggregate from "../../Components/ModalPopup/FormModules/Aggregate";
import AggregateItem from "../../Components/formModules/AggregateItem";
import FieldDroppableArea from "../../Components/formModules/FieldDroppableArea";
import { Helpers } from '../../Helpers';
import { horizontalListSortingStrategy,verticalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "../../Components/formModules/sortableItem";
import { useSelector } from 'react-redux';
import { CSS } from "@dnd-kit/utilities";
import "../../index.css";

const SectionDroppableArea = ({ width, moduleStoreData, dispatch, setShowPropertyModal, currentDragStartData }) => {
    let getCreateUpdateListByID = useSelector((state) => state?.forms);
    let [showAggregateModal, setShowAggregateModal] = useState(false);
    let { attributes, listeners, setNodeRef, transform,
        transition } = useDroppable({
            id: "canvas_droppable",
            data: {
                parent: null,
                isContainer: true
            }
        });
    let style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    const scrollRef = useRef(null);
    const lastAddedRef = useRef(null);
    const [newFieldAdded, setNewFieldAdded] = useState(null);

    const handleDragEnd = ({ active, over }) => {
        if (!over) {
            return;
        }
        const activeId = active.id;
        const overId = over.id;

        if (activeId !== overId) {
            const sectionIndex = moduleStoreData.sections.findIndex(section => section.selected_list?.some(item => item.id === activeId));
            if (sectionIndex === -1) {
                return;
            }
            const oldIndex = moduleStoreData.sections[sectionIndex].selected_list.findIndex(item => item.id === activeId);
            const newIndex = moduleStoreData.sections[sectionIndex].selected_list.findIndex(item => item.id === overId);

            if (oldIndex === -1 || newIndex === -1) {
                return;
            }

            dispatch({
                type: 'MOVE_SUBFORM_ITEM',
                payload: {
                    sectionIndex,
                    oldIndex,
                    newIndex
                }
            });
        }
    };

    const aggregateSave = (newItem, sectionIndex) => {
        dispatch({
            type: 'ADD_AGGREGATE_ITEM',
            payload: {
                sectionIndex,
                newItem
            },
        });
    }

    const getModuleStoreData = (sectionIndex, path) => {
        return Helpers.getItemsOrDefault(moduleStoreData.sections[sectionIndex].dropped_list?.[path]) || [];
    }

    const removeSection = (sectionId) => {
        dispatch({
            type: 'REMOVE_SECTION',
            payload: { sectionId },
        });
    };

    const updateSectionName = (newValue, indexSection) => {
        dispatch({
            type: 'UPDATE_SECTION_NAME',
            payload: {
                sectionIndex: indexSection,
                newValue
            },
        });
    }

    const removeItem = (item) => {
        dispatch({
            type: 'REMOVE_ITEM',
            payload: {
                sectionIndex: item.sectionIndex,
                area: item.area,
                itemId: item.id,
            },
        });
    };

    const dublicateCheckForFields = (currentData) => {
        let dublicateLabelLength = 0;
        moduleStoreData.sections?.forEach(x => {
            if (x?.dropped_list && Object.keys(x?.dropped_list)?.length) {
                Object.keys(x.dropped_list).forEach(key => {
                    let items = Helpers.getItemsOrDefault(x.dropped_list?.[key]);
                    if (items) {
                        dublicateLabelLength += items.filter(item => item?.label === currentData?.extra_fields?.field_label).length;
                    }
                });
            } else if (x?.selected_list?.length > 0) {
                dublicateLabelLength += x.selected_list.filter(item => item?.label === currentData?.extra_fields?.field_label).length;
            }
        });
        return dublicateLabelLength;
    }

    const subFormAddField = (sectionIndex, currentData) => {
        let dublicateLabelLength = dublicateCheckForFields(currentData);
        let newItem = {
            id: Utils.idGenerator(),
            label: currentData.label,
            extra_fields: { ...currentData?.extra_fields, field_label: dublicateLabelLength ? currentData.label + ' ' + dublicateLabelLength : currentData.label, ...((currentData?.input_type === "lookup" || currentData?.input_type === "multi-lookup") ? { moduleId: (getCreateUpdateListByID?.navbarList?.[0]?._id || "") } : {}) },
            input_type: currentData?.input_type
        };
        dispatch({
            type: "ADD_SUBFORM_ITEM",
            payload: {
                sectionIndex,
                newItem
            }
        });
        lastAddedRef.current = newItem.id; 
        setNewFieldAdded(true);
    };

    useEffect(() => {
        if (scrollRef.current && lastAddedRef.current) {
            const lastAddedElement = document.getElementById(lastAddedRef.current);
            if (lastAddedElement) {
                lastAddedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
            }
            setNewFieldAdded(false);
        }
    }, [newFieldAdded]);

    return (
        <>
            <div ref={setNodeRef} className="pt-3 pb-3" {...attributes} {...listeners} style={style}>
                {moduleStoreData.sections.map((section, sectionIndex) => (
                    <SectionSortableItem key={sectionIndex} item={{ id: section.id, unique_name: section?.unique_name || (section?.subform ? 'subform' : 'new_section'), sectionIndex }} dispatch={dispatch} removeSection={removeSection} section={section} updateSectionName={updateSectionName}>
                        {
                            (section && section?.type === "section-spacer") ?
                                <>
                                    <div className="create-box-section-drag m-2" style={{ width: width + "px" }} />
                                </>
                                :
                                <>
                                    <div className="card-body">
                                        <div className="row">
                                            {section?.subform ?
                                                <>
                                                    <div className="col-md-12 droppable-area">
                                                        <div className="form-builder-container">
                                                        <div id="scrollbar" ref={scrollRef} className="custom-overflow-auto custom-scrollbar" style={{ overflowY: 'auto', height: '90px' }}>
                                                                <DndContext onDragEnd={handleDragEnd}>
                                                                    <SortableContext items={section.selected_list.map(item => item.id)} strategy={horizontalListSortingStrategy}>
                                                                        <table className="table table-bordered mb-0" style={{ tableLayout: 'fixed', width: '0px' }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    {section?.selected_list?.map((subformData, index) => (
                                                                                        <SubformItem moduleStoreData={moduleStoreData} key={index} subformData={subformData} sectionIndex={sectionIndex} dispatch={dispatch} setShowPropertyModal={setShowPropertyModal} section={section} />
                                                                                    ))}
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </SortableContext>
                                                                </DndContext>
                                                            </div>
                                                            <div className='border'>
                                                                <div className="dropdown-toggle add-fields-box ps-1" id="addFieldsdropdownMenuButton"
                                                                    data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "88px" }}>
                                                                    <small className='cursor-pointer'> Add Field </small>
                                                                </div>

                                                                <div className="position-relative">
                                                                    <ul className="dropdown-menu" aria-labelledby="addFieldsdropdownMenuButton" style={{
                                                                        maxHeight: '200px',
                                                                        overflowY: 'auto',
                                                                    }}>
                                                                        {Utils.formModuleDatas?.sidebar_fields?.map((item, index) => (
                                                                            (item?.input_type?.length > 0 && !["rollup"]?.includes(item?.input_type)) &&
                                                                            <li key={index} className="ps-2 pb-2 cursor-pointer" onClick={() => subFormAddField(sectionIndex, item)}>
                                                                                <img className="me-2" src={item?.image_url} alt="sigle-line-icon" width="20" height="20" />
                                                                                {item.label}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            section?.aggregate_list?.length > 0 &&
                                                            (
                                                                <div className="row mt-2">
                                                                    {section?.aggregate_list?.map((x, index) => (
                                                                        <div key={index} className="col-md-4 offset-md-8 d-flex justify-content-end">
                                                                            <AggregateItem aggregateData={x} sectionIndex={sectionIndex} dispatch={dispatch} setShowPropertyModal={setShowPropertyModal} section={section} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            section?.selected_list?.find(x => ["number", "curency", "decimal"]?.includes(x.input_type)) && (
                                                                <div className="float-end mt-2">
                                                                    <button type="button" className="btn btn-secondary" onClick={() => setShowAggregateModal(true)}>Add Aggregate Field</button>
                                                                </div>
                                                            )
                                                        }
                                                        {showAggregateModal && <Aggregate show={showAggregateModal} section={section} handleClose={() => setShowAggregateModal(false)} aggregateSave={(data) => aggregateSave(data, sectionIndex)} aggregate_list={section?.aggregate_list} />}
                                                    </div>
                                                </> :
                                                <>
                                                    <FieldDroppableArea id={`drop_${sectionIndex}_area_1`} sectionIndex={sectionIndex} layouts={section.layouts}>
                                                        {Helpers.arrayLength(
                                                            (section.layouts === 2 || section.layouts === 3)
                                                                ? getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_1`)
                                                                : (getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_1`).concat(
                                                                    getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_2`)
                                                                )).concat(getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_3`))
                                                        ) ? (
                                                            <SortableContext disabled={currentDragStartData?.field?.unique_name && (currentDragStartData?.field?.unique_name === 'new_section' || currentDragStartData?.field?.unique_name === 'subform') || false} items={
                                                                (section.layouts === 2 || section.layouts === 3)
                                                                    ? getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_1`)
                                                                    : (getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_1`).concat(
                                                                        getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_2`)
                                                                    )).concat(getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_3`))
                                                            }
                                                                strategy={verticalListSortingStrategy}
                                                            >
                                                                {(
                                                                    (section.layouts === 2 || section.layouts === 3)
                                                                        ? getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_1`)
                                                                        : (getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_1`).concat(
                                                                            getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_2`)
                                                                        )).concat(getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_3`))
                                                                ).map((item, overIndex) => (
                                                                    <React.Fragment key={item.id}>
                                                                        <SortableItem overIndex={overIndex} moduleStoreData={moduleStoreData} setShowPropertyModal={setShowPropertyModal} dispatch={dispatch}
                                                                            onRemove={removeItem}
                                                                            item={{
                                                                                ...item,
                                                                                area: `drop_${sectionIndex}_area_1`,
                                                                                sectionIndex,
                                                                            }}
                                                                        />
                                                                    </React.Fragment>
                                                                ))}
                                                            </SortableContext>
                                                        ) : (
                                                            <div className="drop-box d-flex align-items-center justify-content-center">
                                                                DROP HERE
                                                            </div>
                                                        )}
                                                    </FieldDroppableArea>
                                                    {(section.layouts === 2 || section.layouts === 3) && (
                                                        <FieldDroppableArea id={`drop_${sectionIndex}_area_2`} sectionIndex={sectionIndex} layouts={section.layouts}>
                                                            {Helpers.arrayLength(getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_2`)) ? (
                                                                <SortableContext disabled={currentDragStartData?.field?.unique_name && (currentDragStartData?.field?.unique_name === 'new_section' || currentDragStartData?.field?.unique_name === 'subform') || false} items={getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_2`)}
                                                                    strategy={verticalListSortingStrategy}
                                                                >
                                                                    {getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_2`).map((item, overIndex) => (
                                                                        <React.Fragment key={item.id}>
                                                                            <SortableItem overIndex={overIndex} moduleStoreData={moduleStoreData} setShowPropertyModal={setShowPropertyModal} dispatch={dispatch}
                                                                                onRemove={removeItem}
                                                                                item={{
                                                                                    ...item,
                                                                                    area: `drop_${sectionIndex}_area_2`,
                                                                                    sectionIndex,
                                                                                }}
                                                                            />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </SortableContext>
                                                            ) : (
                                                                <div className="drop-box d-flex align-items-center justify-content-center">
                                                                    DROP HERE
                                                                </div>
                                                            )}
                                                        </FieldDroppableArea>
                                                    )}
                                                    {section.layouts === 3 && (
                                                        <FieldDroppableArea id={`drop_${sectionIndex}_area_3`} sectionIndex={sectionIndex} layouts={section.layouts}>
                                                            {Helpers.arrayLength(getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_3`)) ? (
                                                                <SortableContext disabled={currentDragStartData?.field?.unique_name && (currentDragStartData?.field?.unique_name === 'new_section' || currentDragStartData?.field?.unique_name === 'subform') || false}
                                                                    id={`drop_${sectionIndex}_area_3`}
                                                                    items={getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_3`)}
                                                                    strategy={verticalListSortingStrategy}
                                                                >
                                                                    {getModuleStoreData(sectionIndex, `drop_${sectionIndex}_area_3`).map((item, overIndex) => (
                                                                        <React.Fragment key={item.id}>
                                                                            <SortableItem overIndex={overIndex} moduleStoreData={moduleStoreData} setShowPropertyModal={setShowPropertyModal} dispatch={dispatch}
                                                                                onRemove={removeItem}
                                                                                item={{
                                                                                    ...item,
                                                                                    area: `drop_${sectionIndex}_area_3`,
                                                                                    sectionIndex,
                                                                                }}
                                                                            />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </SortableContext>
                                                            ) : (
                                                                <div className="drop-box d-flex align-items-center justify-content-center">
                                                                    DROP HERE
                                                                </div>
                                                            )}
                                                        </FieldDroppableArea>
                                                    )}
                                                </>}
                                        </div>
                                    </div>
                                </>
                        }
                    </SectionSortableItem>
                ))}
            </div>
        </>
    )
}
export default SectionDroppableArea;