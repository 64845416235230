import { Utils } from "../Utils";
import { Helpers } from "../Helpers";

const addDraggableItem = (state, action) => {
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index === action.payload.sectionIndex) {
        let newList = { ...section.dropped_list };
        let { area, newItem, overIndex } = action.payload;
        let targetArray = Helpers.getItemsOrDefault(newList[area]);
        let updatedArray = overIndex >= 0
          ? [...targetArray.slice(0, overIndex), newItem, ...targetArray.slice(overIndex)]
          : [...targetArray, newItem];

        newList[area] = updatedArray;
        return {
          ...section,
          dropped_list: newList,
        };
      }
      return section;
    }),
  };
};

const addSubformItem = (state, action) => {
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index === action.payload.sectionIndex) {
        let updatedArray = [...section.selected_list];
        let { newItem } = action.payload;
        updatedArray.push(newItem);
        return {
          ...section,
          selected_list: updatedArray,
        };
      }
      return section;
    }),
  };
};

const addAggregateItem = (state, action) => {
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index === action.payload.sectionIndex) {
        let { newItem } = action.payload;
        let updatedArray = Array.isArray(section.aggregate_list)
          ? [...section.aggregate_list, newItem]
          : [newItem];
        return {
          ...section,
          aggregate_list: updatedArray,
        };
      }
      return section;
    }),
  };
};

const changeSectionData = (state, action) => {
  let { data, formIndex, toIndex } = action.payload;
  console.log(data, formIndex, toIndex);
  let updatedArray = [...state.sections];
  updatedArray = updatedArray.map(x => (x?.id === data?.id ? data : x));
  if (formIndex !== undefined && toIndex !== undefined && formIndex === toIndex) {
    return {
      ...state,
      sections: updatedArray.map((section, idx) => ({
        ...section,
        dropped_list: Object.fromEntries(
          Object.entries(section?.dropped_list || {}).map(([key, value], index2) => {
            let newKey = `drop_${idx}_area_${index2 + 1}`;
            return [newKey, value];
          })
        )
      }))
    };
  }
  if (formIndex !== undefined && toIndex !== undefined && formIndex !== toIndex) {
    let [movedSection] = updatedArray.splice(formIndex, 1);
    updatedArray.splice(toIndex, 0, movedSection);
  }
  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });

  return {
    ...state,
    sections: reindexedSections
  };
};


const moveSection = (state, action) => {
  let { index } = action.payload;
  let updatedArray = [...state.sections];
  updatedArray.splice(index, 1, {
    id : updatedArray?.[index]?.id,
    type: `section-spacer`,
    layouts : 1,
    unique_name : "new_section"
  });
  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList,
    };
  });
  return {
    ...state,
    sections: reindexedSections,
  };
};

const addSubform = (state, action) => {
  let { index } = action.payload;
  let updatedArray = [...state.sections];
  let newItem = { 
    ...Utils.subFormFormat, 
    id: `section${state.sections.length}`, 
    section_name: `Subform ${state.sections?.filter(x => x.subform)?.length || ''}` 
  };
  if (index >= 0) {
    updatedArray.splice(index, 0, newItem);
  } else {
    updatedArray.push(newItem);
  }
  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });
  return {
    ...state,
    sections: reindexedSections
  };
};

const addSectionSpacer = (state, action) => {
  let { index } = action.payload;
  let updatedArray = [...state.sections];
  let newItem = {
    id : `spacer`,
    type: `section-spacer`,
    layouts : 1,
    unique_name : "new_section"
  };
  if (index >= 0) {
    updatedArray.splice(index, 0, newItem);
  } else {
    updatedArray.push(newItem);
  }
  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });
  return {
    ...state,
    sections: reindexedSections
  };
}

const removeSectionSpacer = (state) => {
  let filteredArray = state.sections.filter(section => (section && section?.type !== "section-spacer"));
  let reindexedSections = filteredArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });
  return {
    ...state,
    sections: reindexedSections
  };
};

const moveSectionSpacer = (state, action) => {
  let { index } = action.payload;
  let updatedArray = [...state.sections];
  let spacerIndex = updatedArray.findIndex(section => (section && section?.type === "section-spacer"));
  if (spacerIndex !== -1) {
    let [spacerItem] = updatedArray.splice(spacerIndex, 1);
    let targetIndex = Math.min(Math.max(index, 0), updatedArray.length);
    updatedArray.splice(targetIndex, 0, spacerItem);
  }
  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });

  return {
    ...state,
    sections: reindexedSections
  };
};

const addFieldSpacer = (state, action) => {
  let { index, areaName, overIndex } = action.payload;
  if (!areaName) return state;

  let updatedArray = state.sections.map(section => ({
    ...section,
    dropped_list: { ...section.dropped_list }
  }));

  let newItem = {
    id: `spacer`,
    type: `field-spacer`
  };

  if (index >= 0) {
    let updatedDroppedList = updatedArray[index]?.dropped_list || {};
    let areaItems = [...(updatedDroppedList[areaName] || [])];
    areaItems.splice(overIndex, 0, newItem);
    updatedDroppedList[areaName] = areaItems;
    updatedArray[index] = {
      ...updatedArray[index],
      dropped_list: updatedDroppedList
    };
  } else {
    updatedArray.push(newItem);
  }

  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });

  return {
    ...state,
    sections: reindexedSections
  };
};


const removeFieldSpacer = (state) => {
  let filteredArray = state.sections.map(section => {
    let filteredDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value]) => {
      filteredDroppedList[key] = value.filter(item => item.type !== "field-spacer");
    });
    return {
      ...section,
      dropped_list: filteredDroppedList
    };
  });

  let reindexedSections = filteredArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });

  return {
    ...state,
    sections: reindexedSections
  };
};

const moveFieldSpacer = (state, action) => {
  let { index, areaName, overIndex } = action.payload;
  if(!areaName) return state;
  let updatedArray = [...state.sections];
  if (index >= 0 && updatedArray[index]?.dropped_list?.[areaName]) {
    let areaItems = [...updatedArray[index].dropped_list[areaName]];
    let spacerIndex = areaItems.findIndex(item => item.type === "field-spacer");
    if (spacerIndex !== -1) {
      let [spacerItem] = areaItems.splice(spacerIndex, 1);
      areaItems.splice(overIndex, 0, spacerItem);
      updatedArray[index] = {
        ...updatedArray[index],
        dropped_list: {
          ...updatedArray[index].dropped_list,
          [areaName]: areaItems
        }
      };
    }
  }
  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });

  return {
    ...state,
    sections: reindexedSections
  };
};

const moveField = (state, action) => {
  let { index, areaName, overIndex } = action.payload;
  if(!areaName) return state;
  let updatedArray = [...state.sections];
  if (updatedArray[index]?.dropped_list?.[areaName]) {
    let areaItems = [...updatedArray[index].dropped_list[areaName]];
    let newItem = {
      id: areaItems?.[overIndex]?.id,
      type: `field-spacer`
    };
    areaItems.splice(overIndex, 1, newItem);
    updatedArray[index] = {
      ...updatedArray[index],
      dropped_list: {
        ...updatedArray[index].dropped_list,
        [areaName]: areaItems
      }
    };
  }

  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });

  return {
    ...state,
    sections: reindexedSections
  };
};

const addSection = (state, action) => {
  let { index } = action.payload;
  let updatedArray = [...state.sections];
  let newItem = { 
    ...Utils.sectionFormat, 
    id: `section${state.sections.length}`, 
    section_name: `Untitled Section ${state.sections?.filter(x => !x?.subform)?.length || ''}` 
  };
  if (index >= 0) {
    updatedArray.splice(index, 0, newItem);
  } else {
    updatedArray.push(newItem);
  }
  let reindexedSections = updatedArray.map((section, idx) => {
    let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });
  return {
    ...state,
    sections: reindexedSections
  };
};

const removeSection = (state, action) => {
  let { sectionId } = action.payload;
  let updatedSections = state.sections.filter(section => section.id !== sectionId);
  let reindexedSections = updatedSections.map((section, idx) => {
  let newDroppedList = {};
    Object.entries(section?.dropped_list || {}).forEach(([key, value], index2) => {
      let newKey = `drop_${idx}_area_${index2 + 1}`;
      newDroppedList[newKey] = value;
    });
    return {
      ...section,
      dropped_list: newDroppedList
    };
  });
  return {
    ...state,
    sections: reindexedSections
  };
};

const removeItem = (state, action) => {
  let { sectionIndex, area, itemId } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index === sectionIndex) {
        let updatedList = {
          ...section.dropped_list,
          [area]: section.dropped_list[area]?.filter(x => x.id !== itemId),
        };
        return {
          ...section,
          dropped_list: updatedList,
        };
      }
      return section;
    })
  };
};

const moveSubformItem = (state, action) => {
  let { sectionIndex, oldIndex, newIndex } = action.payload;
  let updatedSections = [...state.sections];
  let selectedList = [...updatedSections[sectionIndex].selected_list];
  let [movedItem] = selectedList.splice(oldIndex, 1);
  selectedList.splice(newIndex, 0, movedItem);
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    selected_list: selectedList
  };
  return {
    ...state,
    sections: updatedSections
  };
};

const removeSubformItem = (state, action) => {
  let { sectionIndex, id } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index === sectionIndex) {
        let updatedList = section.selected_list?.filter(x => x.id !== id);
        return {
          ...section,
          selected_list: updatedList,
        };
      }
      return section;
    })
  };
};

const removeAggregateItem = (state, action) => {
  let { sectionIndex, id } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index === sectionIndex) {
        let updatedList = section.aggregate_list?.filter(x => x.id !== id);
        return {
          ...section,
          aggregate_list: updatedList,
        };
      }
      return section;
    })
  };
}

const updateLayouts = (state, action) => {
  let { sectionIndex, layouts } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, idx) =>
      idx === sectionIndex ? { ...section, layouts } : section
    ),
  };
};

const editPropertySubmit = (state, action) => {
  let { sectionIndex, area, itemId, newValue } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index !== sectionIndex) return section;

      return {
        ...section,
        dropped_list: {
          ...section.dropped_list,
          [area]: section.dropped_list[area]?.map(item =>
            item.id === itemId
              ? {
                  ...item,
                  extra_fields : newValue
                }
              : item
          )
        }
      };
    })
  };
};

const editSubformPropertySubmit = (state, action) => {
  let { sectionIndex, id, newValue } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index !== sectionIndex) return section;
      return {
        ...section,
        selected_list: section.selected_list.map(item =>
          item.id === id
            ? {
                ...item,
                extra_fields: newValue
              }
            : item
        )
      };
    })
  };
};

const updateAggregateItem = (state, action) => {
  let { sectionIndex, id, newValue } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, index) => {
      if (index !== sectionIndex) return section;
      return {
        ...section,
        aggregate_list: section.aggregate_list.map(item =>
          item.id === id
            ? {
              ...item,
              extra_fields: newValue
            }
            : item
        )
      };
    })
  };
}

const moduleName = (state, action) => {
  return {
    ...state,
    ...action.payload
  }
}

const updateSectionName = (state, action) => {
  let { sectionIndex, newValue } = action.payload;
  return {
    ...state,
    sections: state.sections.map((section, index)  => {
      if (index !== sectionIndex) return section;
      return {
        ...section,
        section_name : newValue
      }
    })
  }
}

const updateDataPage = (state, action) => {
  return {
    ...action.payload
  }
}

const formModuleReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_DRAGABLE_ITEM':
      return addDraggableItem(state, action);
    case 'ADD_SUBFORM_ITEM':
      return addSubformItem(state, action);
    case 'MOVE_SECTION':
      return moveSection(state, action);
    case 'CHANGE_SECTION_DATA':
      return changeSectionData(state, action);
    case 'ADD_SUBFORM':
      return addSubform(state, action);
    case 'ADD_SECTION':
      return addSection(state, action);
    case 'REMOVE_SECTION':
      return removeSection(state, action);
    case 'REMOVE_ITEM':
      return removeItem(state, action);
    case 'MOVE_SUBFORM_ITEM':
      return moveSubformItem(state, action);
    case 'REMOVE_SUBFORM_ITEM':
      return removeSubformItem(state, action);
    case 'UPDATE_LAYOUTS':
      return updateLayouts(state, action);
    case 'UPDATE_EDIT_PROPERTIES':
      return editPropertySubmit(state, action);
    case 'UPDATE_SUBFORM_EDIT_PROPERTIES':
      return editSubformPropertySubmit(state, action);
    case 'MODULE_NAME':
      return moduleName(state, action);
    case 'UPDATE_SECTION_NAME':
        return updateSectionName(state, action);
    case 'UPDATE_DATA_PAGE':
          return updateDataPage(state, action);
    case 'ADD_AGGREGATE_ITEM':
      return addAggregateItem(state, action);
    case 'REMOVE_AGGREGATE_ITEM':
      return removeAggregateItem(state, action);
    case 'UPDATE_AGGREGATE_EDIT_PROPERTIES':
      return updateAggregateItem(state, action);
    case 'ADD_SECTION_SPACER':
      return addSectionSpacer(state, action);
    case 'REMOVE_SECTION_SPACER':
      return removeSectionSpacer(state, action);
    case 'MOVE_SECTION_SPACER':
      return moveSectionSpacer(state, action);
    case 'ADD_FIELD_SPACER':
      return addFieldSpacer(state, action);
    case 'REMOVE_FIELD_SPACER':
      return removeFieldSpacer(state, action);
    case 'MOVE_FIELD_SPACER':
      return moveFieldSpacer(state, action);
    case 'MOVE_FIELD':
      return moveField(state, action);
    default:
      return state;
  }
};

export default formModuleReducer;
