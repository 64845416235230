import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    message: null,
    successStatus: null,
    reload: false
}

const userSettingsSlice = createSlice({
    name: 'usersettings',
    initialState,
    reducers: {
        getUserSettingsStart: (state) => {
            state.isLoading = true;
        },
        getUserSettingsSuccess: (state, action) => {
            state.data = action?.payload?.rules;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
        },
        getUserSettingsFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        createUserSettingsStart: (state, action) => {
            state.isLoading = true;
        },
        createUserSettingsSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        createUserSettingsFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        updateUserSettingsStart: (state, action) => {
            state.isLoading = true;
        },
        updateUserSettingsSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        updateUserSettingsFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        }
    }
})

export const {
    getUserSettingsStart,
    getUserSettingsSuccess,
    getUserSettingsFailure,
    createUserSettingsStart,
    createUserSettingsSuccess,
    createUserSettingsFailure,
    updateUserSettingsStart,
    updateUserSettingsSuccess,
    updateUserSettingsFailure
} = userSettingsSlice?.actions

export default userSettingsSlice?.reducer;