import { call, put } from "redux-saga/effects";
import { changePasswordFailure, changePasswordSuccess, deleteUserFailure, deleteUserSuccess, getAllUserDataFailure, getAllUserDataSuccess, getAllUserListFailure, getAllUserListSuccess, getAllUserTypeListFailure, getAllUserTypeListSuccess, getUserByIdFailure, getUserByIdSuccess, getUserListFailure, getUserListSuccess, updateUserFailure, updateUserSuccess, uploadUserProfileFailure, uploadUserProfileSuccess } from "../../../Slices/userSlice";
import { changePassword, deleteUserById, getAllTypeUsers, getAllUsers, getAllUsersData, getUser, getUserById, updateUserById, uploadProfilePictureById } from "../../../../Services/apiService";

export function* getUserListHandler(actions) {
    try {
        const response = yield call(getUser, actions?.payload);
        yield put(getUserListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getUserListFailure(errResponse))
    }
}

export function* getAllUserListHandler(actions) {
    try {
        const response = yield call(getAllUsers, actions?.payload);
        yield put(getAllUserListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAllUserListFailure(errResponse))
    }
}

export function* getUserByIdHandler(actions) {
    try {
        const response = yield call(getUserById, actions?.payload);
        yield put(getUserByIdSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getUserByIdFailure(errResponse))
    }
}

export function* updateUserHandler(actions) {
    try {
        const response = yield call(updateUserById, actions?.payload);
        yield put(updateUserSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateUserFailure(errResponse))
    }
}

export function* deleteUserHandler(actions) {
    try {
        const response = yield call(deleteUserById, actions?.payload);
        yield put(deleteUserSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteUserFailure(errResponse))
    }
}

export function* uploadUserProfileHandler(actions) {
    try {
        const response = yield call(uploadProfilePictureById, actions?.payload);
        yield put(uploadUserProfileSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(uploadUserProfileFailure(errResponse))
    }
}

export function* getAllUserDataHandler(actions) {
    try {
        const response = yield call(getAllUsersData, actions?.payload);
        yield put(getAllUserDataSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAllUserDataFailure(errResponse))
    }
}

export function* getAllUserTypeListHandler(actions) {
    try {
        const response = yield call(getAllTypeUsers, actions?.payload);
        yield put(getAllUserTypeListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAllUserTypeListFailure(errResponse))
    }
}

export function* changePasswordHandler(actions) {
    try {
        const response = yield call(changePassword, actions?.payload);
        yield put(changePasswordSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(changePasswordFailure(errResponse))
    }
}