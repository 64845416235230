import React, { useRef } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Utils } from '../../../Utils';

const FieldsDroppableArea = ({ children }) => {
    let id = useRef(Utils.idGenerator());
    let { setNodeRef } = useDroppable({
        id : id.current,
        data : {
            fieldDropArea : true
        }
    });
    return (
        <ul className="list-group overflow-auto border" style={{ height: "350px" }} ref={setNodeRef}>
            {children}
        </ul>
    );
};

export default FieldsDroppableArea;
