import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";

const FieldsSortableArea = ({ item, overIndex, removeField}) => {
    let { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: item.id,
        data : {
            field: { ...item, overIndex },
            fieldSortArea : true
        }
    });
    let style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <>
            {item?.type === "field-spacer" ?
                <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
                    <div className="create-box-dragover" />
                </div>
                : <li ref={setNodeRef} {...attributes} {...listeners} style={style} className="list-group-item d-flex justify-content-between align-items-center cursor-move">
                    {item?.extra_fields?.field_label}
                    <span className="cursor-pointer" onClick={() => removeField(item.id)}>-</span>
                </li>
            }
        </>
    );
};

export default FieldsSortableArea;
