import { Outlet, useLocation, useNavigate } from "react-router-dom";
import triangleIcon from '../../Assets/images/icons/triangle-left.svg';
import { Utils } from "../../Utils";
// '../../Assets/images/icons/triangle-left.svg';

const Setup = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <main className="col-lg-12 p-0 content-section">

            <div className="row g-0 setup-card">
                <div className="col-md-2">
                    <div className="card h-100 rounded-start-0 rounded-end-4 border-start-0 border-light-subtle">
                        <div className="card-header h5 bg-transparent border-0 pb-2">Setup</div>
                        <div className="card-body p-0">
                            <div className="px-3">
                                <div className="search-box border rounded-pill position-relative">
                                    <button className="searchbutton bg-white" type="button"><i
                                        className="fa-solid fa-magnifying-glass"></i></button>
                                    <input type="search" placeholder="Search" className="searchfield bg-white" />
                                </div>
                            </div>
                            <ul
                                className="list-group mt-2 px-3 list-group-flush setupbox-height overflow-y-auto">
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start"
                                        data-bs-toggle="collapse" data-bs-target="#collapseGeneral"
                                        aria-expanded="false" aria-controls="collapseGeneral">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        General
                                    </button>
                                    <div className="collapse ms-3 pt-3" id="collapseGeneral">
                                        <div className="list-group rounded-0">
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/personal-settings`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/personal-settings') ? 'active' : ''}`}
                                                aria-current="true">
                                                Personal Settings
                                            </a>
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/users`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/users') ? 'active' : ''}`}
                                                aria-current="true">
                                                Users
                                            </a>
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/company-settings`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/company-settings') ? 'active' : ''}`}
                                                aria-current="true">
                                                Company Settings
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start"
                                        data-bs-toggle="collapse" data-bs-target="#collapseSecurityControl"
                                        aria-expanded="false" aria-controls="collapseSecurityControl">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Security Control
                                    </button>
                                    <div className="collapse ms-3 pt-3" id="collapseSecurityControl">
                                        <div className="list-group rounded-0">
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/profiles`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/profiles') ? 'active' : ''}`}
                                                aria-current="true">
                                                Profiles
                                            </a>
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/roles-and-sharing`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/roles-and-sharing') ? 'active' : ''}`}
                                                aria-current="true">
                                                Roles and Sharing
                                            </a>
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/login-history`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/login-history') ? 'active' : ''}`}
                                                aria-current="true">
                                                Login History
                                            </a>
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/audit-log`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/audit-log') ? 'active' : ''}`}
                                                aria-current="true">
                                                Audit Log
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start"
                                        data-bs-toggle="collapse" data-bs-target="#collapseChannels"
                                        aria-expanded="false" aria-controls="collapseChannels">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Channels
                                    </button>
                                    <div className="collapse ms-3 pt-3" id="collapseChannels">
                                        <div className="list-group rounded-0">
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/email`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/email') ? 'active' : ''}`}
                                                aria-current="true">
                                                Email
                                            </a>
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/business-messaging`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/business-messaging') ? 'active' : ''}`}
                                                aria-current="true">
                                                Business Messaging
                                            </a>
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`settings/notification-sms`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/settings/notification-sms') ? 'active' : ''}`}
                                                aria-current="true">
                                                Notification SMS
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start"
                                        data-bs-toggle="collapse" data-bs-target="#collapseCustomization"
                                        aria-expanded="false" aria-controls="collapseCustomization">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Customization
                                    </button>
                                    <div className="collapse ms-3 pt-3" id="collapseCustomization">
                                        <div className="list-group rounded-0">
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`form-module`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/form-module') ? 'active' : ''}`}
                                                aria-current="true">
                                                Modules and Fields
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start"
                                        data-bs-toggle="collapse" data-bs-target="#collapseAutomation"
                                        aria-expanded="false" aria-controls="collapseAutomation">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Automation
                                    </button>
                                    <div className="collapse ms-3 pt-3" id="collapseAutomation">
                                        <div className="list-group rounded-0">
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`workflow-rules`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/workflow-rules') ? 'active' : ''}`}
                                                aria-current="true">
                                                Workflow Rules
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Project Management
                                    </button>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Experience Center
                                    </button>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Data Administration
                                    </button>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Marketplace
                                    </button>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start"
                                        data-bs-toggle="collapse" data-bs-target="#collapseDeveloperhub"
                                        aria-expanded="false" aria-controls="collapseDeveloperhub">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Developer Hub
                                    </button>
                                    <div className="collapse ms-3 pt-3" id="collapseDeveloperhub">
                                        <div className="list-group rounded-0">
                                            <a onClick={() => navigate(Utils.getNavigationUrl(`integration-api`))}
                                                className={`list-group-item list-group-item-action border-0 ps-2 rounded-1 ${isActive('/integration-api') ? 'active' : ''}`}
                                                aria-current="true">
                                                Connections
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        Zia
                                    </button>
                                </li>
                                <li className="list-group-item pe-0">
                                    <button type="button"
                                        className="btn btn-link text-decoration-none text-body p-0 d-block w-100 text-start">
                                        <img className="me-1" src={triangleIcon}
                                            alt="recent-items" width="13" height="13" />
                                        CPQ
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-md-10 px-3 pt-3">
                    <Outlet />
                </div>
            </div>
        </main >
    )
}
export default Setup;