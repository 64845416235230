import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWebhookListByModuleIdStart, removeWebhookByIdStart } from "../../../Store/Slices/webhookSlice";
import Loader from "../../../Components/Loader";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../Components/ModalPopup/ConfirmationModal";
import { Utils } from "../../../Utils";

const WebhooksTable = () => {
    let formList = useSelector((state) => state?.forms);
    let [ selectedModuleId, setSelectedModuleId ] = useState(null);
    let webhooksData = useSelector((state) => state?.webhooks);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let [removeModalData, setRemoveModalData] = useState(null);

    useEffect(() => {
        let _id = formList?.navbarList?.[0]?._id;
        if (_id?.length > 0) {
            dispatch(getWebhookListByModuleIdStart(_id));
            setSelectedModuleId(_id);
        }
    }, [formList?.navbarList?.[0]?._id]);

    useEffect(() => {
        let reload = webhooksData?.reload;
        if (reload) {
            dispatch(getWebhookListByModuleIdStart(selectedModuleId));
        }
    }, [webhooksData?.reload]);

    const changeTableData = (moduleId) => {
        if(selectedModuleId !== moduleId) {
            dispatch(getWebhookListByModuleIdStart(moduleId));
            setSelectedModuleId(moduleId);
        }
    }

    const updateData = (webhook) => {
        navigate(Utils.getNavigationUrl(`integration-api/form`), { state: { id: webhook?.moduleId?._id, api_url: webhook?.api_url, method: webhook?.method, webhookId : webhook?._id, formDatas : webhook  } });
    }

    const removeData = (webhookId) => {
        dispatch(removeWebhookByIdStart(webhookId));
    }

    return (
        <>
            <Loader isVisible={webhooksData?.isLoading || formList?.isLoading} />
            {/* <div className="main-panel"> */}
                <main className="py-md-4 content-section">
                    <div className="container-fluid">
                        <div className="card rounded-4 border-0 shadow-sm">
                            <div className="card-body">
                                <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                                    <div className="left d-flex align-items-start">
                                        <div className="search-box border rounded-pill">
                                            <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                            <input type="search" placeholder="Search" className="searchfield bg-white" />
                                        </div>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-primary me-2px rounded-start">
                                                Select the Module
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary dropdown-toggle dropdown-toggle-split rounded-end"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className="fa fa-caret-down"></i>
                                                <span className="visually-hidden">Toggle Dropdown</span>
                                            </button>
                                            <ul className="dropdown-menu">
                                                {formList?.navbarList?.length > 0 && formList.navbarList.map((x, index) => (
                                                    <React.Fragment key={index}>
                                                        <li>
                                                            <a className="dropdown-item cursor-pointer" onClick={() => changeTableData(x?._id)}>
                                                                {x?.name}
                                                            </a>
                                                        </li>
                                                        {(formList?.navbarList?.length - 1) !== index && <hr className="dropdown-divider" />}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="tbl-container rounded-table">
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Module Name</th>
                                                        <th scope="col">Api Url</th>
                                                        <th scope="col">Method</th>
                                                        <th scope="col">Update</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {webhooksData?.data && webhooksData?.data.length > 0 ? (
                                                        webhooksData?.data.map((value, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {value?.moduleId?.name}
                                                                </td>
                                                                <td>
                                                                    {value?.api_url}
                                                                </td>
                                                                <td>
                                                                    {value?.method}
                                                                </td>
                                                                <td>
                                                                    {/* <button
                                                                        type="button"
                                                                        className="btn btn-primary rounded-pill" onClick={() => updateData(value)}>
                                                                        Edit
                                                                    </button> */}
                                                                    <span style={{ color : "#29409d"}} className="cursor-pointer" 
                                                                     onClick={() => updateData(value)}>
                                                                        <i className="fas fa-edit"></i>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {/* <button
                                                                        type="button"
                                                                        className="btn btn-danger rounded-pill" onClick={() => setRemoveModalData(value)}>
                                                                        Delete
                                                                    </button> */}
                                                                    <span style={{ color : "#dc3545"}} className="cursor-pointer" 
                                                                    onClick={() => setRemoveModalData(value)}>
                                                                        <i className="fas fa-trash"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td>No Webhooks Data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            {/* </div> */}
            {removeModalData && <ConfirmationModal data={removeModalData} show={true} handleClose={() => setRemoveModalData(null)} handleSuccess={(webhookId) => {
                removeData(webhookId);
                setRemoveModalData(null);
            }} />}
        </>
    );
};

export default WebhooksTable;
