import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    message: null,
    error: null,
    reload: false
}

const variantConfigSlice = createSlice({
    name: 'variantConfig',
    initialState,
    reducers: {
        getVariantConfigStart: (state) => {
            state.isLoading = true;
        },
        getVariantConfigSuccess: (state, action) => {
            state.data = action?.payload?.variants;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getVariantConfigFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        createVariantConfigStart: (state) => {
            state.isLoading = true;
        },
        createVariantConfigSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.reload = true;
        },
        createVariantConfigFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        deleteVariantConfigByIdStart: (state) => {
            state.isLoading = true;
        },
        deleteVariantConfigByIdSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.reload = true;
        },
        deleteVariantConfigByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        updateVariantConfigByIdStart: (state) => {
            state.isLoading = true;
        },
        updateVariantConfigByIdSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.reload = true;
        },
        updateVariantConfigByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        resetReload: (state) => {
            state.reload = false;
        },
    }
})

export const {
    getVariantConfigStart,
    getVariantConfigSuccess,
    getVariantConfigFailure,
    createVariantConfigStart,
    createVariantConfigSuccess,
    createVariantConfigFailure,
    deleteVariantConfigByIdStart,
    deleteVariantConfigByIdSuccess,
    deleteVariantConfigByIdFailure,
    updateVariantConfigByIdStart,
    updateVariantConfigByIdSuccess,
    updateVariantConfigByIdFailure,
    resetReload
} = variantConfigSlice?.actions

export default variantConfigSlice?.reducer;