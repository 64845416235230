import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import SectionSettingDropDown from "../../Components/ModalPopup/FormModules/sectionSettingDropDown";
import { CSS } from '@dnd-kit/utilities';

const SectionSortableItem = ({ item, children, dispatch, removeSection, section, updateSectionName }) => {
    const { attributes, listeners, setNodeRef, transform, transition} = useSortable({
        id: item.id,
        data: {
            field: item,
            sectionSortable: true
        }
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
      };
    return (
        <>
            {
                (section && section?.type === "section-spacer") ?
                    <>
                        <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
                            {children}
                        </div>
                    </>
                    :
                    <>
                        <div className="card drop-box m-2 position-relative" ref={setNodeRef} {...attributes} style={style}>
                            <div className="corner-setting-icon">
                                <SectionSettingDropDown handleLayoutChange={(layouts) => dispatch({
                                    type: 'UPDATE_LAYOUTS',
                                    payload: {
                                        sectionIndex: item?.sectionIndex,
                                        layouts,
                                    },
                                })} removeSection={removeSection} section={section}></SectionSettingDropDown>
                            </div>
                            <h6 className="card-header py-3 fs-14" {...listeners} style={{ cursor: "move" }}>
                                <input onPointerDown={(e) => e.stopPropagation()}
                                    type="text"
                                    id="sectionName"
                                    placeholder="Title"
                                    value={section?.section_name}
                                    onChange={(event) => updateSectionName(event.target.value, item?.sectionIndex)}
                                    className="section-name-input custom-input-border"
                                />
                                  {(!section?.section_name || section?.section_name.trim() === '') && (
                               <span className="text-danger ms-2">Section field cannot be empty</span>
                             )}
                            </h6>
                            {children}
                        </div>
                    </>
            }
        </>
    );
};

export default SectionSortableItem;
