import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    user: null,
    message: null,
    error: null,
    successStatus: null,
    getStatus: null,
    createStatus: null,
    updateStatus: null,
    reload: null,
    permissions: {
        isLoading: false,
        data: null,
        message: null,
        error: null,
        totalCount: null
    },
    shiftData: null
}

const shiftHoursSlice = createSlice({
    name: 'shifthours',
    initialState,
    reducers: {
        getShiftHoursStart: (state) => {
            state.isLoading = true;
            state.getStatus = null;
            state.shiftData = null;
        },
        getShiftHoursSuccess: (state, action) => {
            state.data = action?.payload?.shiftHours;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.getStatus = action?.payload?.success;
            state.shiftData = action?.payload?.shiftHours;
        },
        getShiftHoursFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
            state.getStatus = action?.payload?.success;
        },
        createShiftHoursStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
        },
        createShiftHoursSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        createShiftHoursFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        updateShiftHoursStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.updateStatus = null;
        },
        updateShiftHoursSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updateShiftHoursFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        deleteShiftHoursStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
        },
        deleteShiftHoursSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        deleteShiftHoursFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        }
    }
});
export const {
    getShiftHoursStart,
    getShiftHoursSuccess,
    getShiftHoursFailure,
    createShiftHoursStart,
    createShiftHoursSuccess,
    createShiftHoursFailure,
    updateShiftHoursStart,
    updateShiftHoursSuccess,
    updateShiftHoursFailure,
    deleteShiftHoursStart,
    deleteShiftHoursSuccess,
    deleteShiftHoursFailure
} = shiftHoursSlice?.actions;

export default shiftHoursSlice?.reducer;