import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { handleFieldLabelUpdate } from '../../../Utils';  

function MultiLinePropertiesModal({ show, handleClose, item, editPropertySubmit }) {
  let fields = item?.extra_fields;
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label);
  const [required, setRequired] = useState(fields?.required);
  const [encryptField, setEncryptField] = useState(fields?.encrypt_field);
  const [inputFormat, setInputFormat] = useState(fields?.input_format);
  const [showTooltip, setShowTooltip] = useState(fields?.show_tooltip?.checked);
  const [tooltipText, setTooltipText] = useState(fields?.show_tooltip?.message);
  const [radioValue, setRadioValue] = useState(fields?.show_tooltip?.type);
  const [showPreview, setShowPreview] = useState(false);
  const [labelError, setLabelError] = useState(""); 
  const [characterLimit, setCharacterLimit]  = useState(2000);
  const handleSubmit = () => {
    const formData = {
      field_label: fieldLabel,
      required,
      encrypt_field: encryptField,
      input_format: inputFormat,
      character_limit: characterLimit,
      show_tooltip: {
        checked: showTooltip,
        type: radioValue,
        message: tooltipText,
      },
    };
    editPropertySubmit(formData);
    handleClose();
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  
  const handleFieldLabelChange = (e) => {
    handleFieldLabelUpdate(e.target.value, setFieldLabel, setLabelError); 
  };

  const handleInputFormatChange = (format) => {
    switch (format) {
      case 'plainTextSmall':
        setCharacterLimit(2000);
        break;
      case 'plainTextLarge':
        setCharacterLimit(32000);
        break;
      case 'richText':
        setCharacterLimit(50000);
        break;
      default:
        break;
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Multi-Line Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Field Label</Form.Label>
            <Form.Control
              type="text"
              value={fieldLabel}
              onChange={handleFieldLabelChange} 
            />
            {labelError && <div className="text-danger">{labelError}</div>} 
          </Form.Group>

          <Form.Group controlId="formInputFormat" className="mb-3">
            <Form.Label>Input Format</Form.Label>
            <div className="d-flex">
              <Form.Check
                inline
                label="Plain Text Small"
                type="radio"
                id="plainTextSmall"
                name="inputFormat"
                checked={inputFormat === 'plainTextSmall'}
                onChange={() => {setInputFormat('plainTextSmall'); handleInputFormatChange('plainTextSmall')}}
                className="me-3"
              />
              <Form.Check
                inline
                label="Plain Text Large"
                type="radio"
                id="plainTextLarge"
                name="inputFormat"
                checked={inputFormat === 'plainTextLarge'}
                onChange={() => {setInputFormat('plainTextLarge'); handleInputFormatChange('plainTextLarge')}}
              />
              <Form.Check
                inline
                label="Rich Text"
                type="radio"
                id="richText"
                name="inputFormat"
                checked={inputFormat === 'richText'}
                onChange={() => {setInputFormat('richText'); handleInputFormatChange('richText')}}
              />
            </div>
            <div className="mt-2">
              <small>Character Limit: {characterLimit}</small>
            </div>
          </Form.Group>

          <Form.Group controlId="formRequired" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Required"
              checked={required}
              onChange={() => setRequired(!required)}
            />
          </Form.Group>

          <Form.Group controlId="formEncryptField" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Encrypt Field"
              checked={encryptField}
              onChange={() => setEncryptField(!encryptField)}
            />
          </Form.Group>

      
          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip}
              onChange={() => setShowTooltip(!showTooltip)}
            />
          </Form.Group>

          {showTooltip && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Text (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={tooltipText}
                  onChange={(e) => setTooltipText(e.target.value)}
                  placeholder="Enter Tooltip text"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Tooltip Type</Form.Label>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={radioValue === 'info'}
                  onChange={() => setRadioValue('info')}
                />
                <Form.Check
                  type="radio"
                  label="Static Text"
                  name="tooltipOption"
                  checked={radioValue === 'static'}
                  onChange={() => setRadioValue('static')}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

          
              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {radioValue === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {tooltipText && <span className="ms-2">{tooltipText}</span>}
                    </div>
                  ) : (
                    <p>{tooltipText || 'No tooltip text provided.'}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MultiLinePropertiesModal;
