import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import dayjs from "dayjs";

const ReminderModal = ({ show, isData, handleClose, submitData }) => {
    let [date, setDate] = useState(isData?.date || dayjs().format('YYYY-MM-DD'));
    let [time, setTime] = useState(isData?.time || "12:00");
    let [time24Hr, setTime24Hr] = useState(isData?.time24Hr || "12:00");
    let [period, setPeriod] = useState(isData?.period || "PM");
    let [alert, setAlert] = useState(isData?.alert || "Email");

    let handleTimeChange = (e) => {
        let timeValue = e.target.value;
        setTime24Hr(timeValue);
        if (timeValue) {
          let [hours, minutes] = timeValue.split(":").map(Number);
          let period = hours >= 12 ? "PM" : "AM";
          let hours12 = hours % 12 || 12;
          let formatted = `${hours12}:${minutes.toString().padStart(2, "0")}`;
          setTime(formatted);
          setPeriod(period);
        } else {
          setPeriod("");
          setTime("");
        }
      };

    let handleSave = () => {
        let reminderData = { date, time, time24Hr, period, alert };
        let formattedDateTime = `${date} ${time} ${period}`;
        let dateTime = dayjs(formattedDateTime, "YYYY-MM-DD hh:mm A")?.toISOString();
        submitData({...reminderData, date_time : dateTime});
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="d-flex align-items-center mb-3">
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                id="reminderOn"
                                label="On"
                                defaultChecked
                            />
                        </Form.Group>

                        <Form.Group className="me-2">
                            <Form.Control
                                type="date"
                                placeholder="dd/MM/yyyy"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </Form.Group>

                        <span className="me-2">at</span>

                        <Form.Group>
                            <Form.Control
                                type="time"
                                value={time24Hr}
                                onChange={handleTimeChange}
                            />
                        </Form.Group>
                        
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <div className="me-4">
                            <span>Alert</span>
                        </div>
                        <div>
                            <Form.Select
                                value={alert}
                                onChange={(e) => setAlert(e.target.value)}
                            >
                                <option value="Email">Email</option>
                                <option value="Pop-up">Pop-up</option>
                                <option value="Both">Both</option>
                            </Form.Select>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReminderModal;
