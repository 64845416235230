import { call, put } from "redux-saga/effects";
import { getLoginHistorySuccess, getUserLoginHistorySuccess, getLoginHistoryFailure, getUserLoginHistoryFailure } from "../../../Slices/loginHistorySlice";
import { getLoginUserCount, getLoginUserHistory } from "../../../../Services/apiService";

export function* getLoginHistoryHandler(action) {
    try {
        const response = yield call(getLoginUserCount, action.payload);
        yield put(getLoginHistorySuccess(response));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || "Failed to fetch login history";
        yield put(getLoginHistoryFailure(errorMessage));
    }
}

export function* getUserLoginHistoryHandler(action) {
    try {
        const response = yield call(getLoginUserHistory, action.payload);
        yield put(getUserLoginHistorySuccess(response));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || "Failed to fetch user login history";
        yield put(getUserLoginHistoryFailure(errorMessage));
    }
}
