import React, { useEffect, useRef, useState } from 'react';
import { Utils } from '../../Utils';
import { FormModuleModel } from '../ModalPopup/FormModules';

const AggregateItem = ({ aggregateData, sectionIndex, dispatch, setShowPropertyModal, moduleStoreData, section }) => {
    let dropDownList = Utils.dropDownList?.[aggregateData?.label?.replace(/\s/g, "")] || [];
    let PropertyModal = FormModuleModel[Utils.propertyModal?.[aggregateData?.label?.replace(/\s/g, "")]];
    let [showPermissionModal, setShowPermissionModal] = useState(false);
    let [showPropertyModal2, setShowPropertyModal2] = useState(false);
    let dropdownRef = useRef(null);
    let [showDropDown, setShowDropDown] = useState(false);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropDown(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const removeSubFormItem = () => {
        dispatch({
            type: 'REMOVE_AGGREGATE_ITEM',
            payload: {
                id: aggregateData?.id,
                sectionIndex
            },
        });
    };

    const editPropertySubmit = (newValue) => {
        dispatch({
            type: 'UPDATE_AGGREGATE_EDIT_PROPERTIES',
            payload: {
                sectionIndex,
                id: aggregateData?.id,
                newValue
            },
        });
    };

    const checkRequired = (key) => {
        return aggregateData?.extra_fields?.[key];
    };

    const handleAction = (action) => {
        switch (action) {
            case 'Mark as required':
                return () => editPropertySubmit({ ...aggregateData?.extra_fields, required: !aggregateData?.extra_fields?.required })
            case 'Do not allow duplicate values':
                return () => editPropertySubmit({ ...aggregateData?.extra_fields, do_not_allow_duplicate_values: !aggregateData?.extra_fields?.do_not_allow_duplicate_values })
            case "Remove Field":
                return () => removeSubFormItem();
            case "Set Permission":
                return () => setShowPermissionModal(true);
            case "Edit Properties":
                return () => {
                    setShowPropertyModal(true);
                    setShowPropertyModal2(true);
                }
            default:
                return () => { };
        }
    };

    return (
        <>
            <td className="me-2" style={{ width: '208px', whiteSpace: 'nowrap' }}>
                <div className="input-group">
                    <input type="text" className="form-control" value={aggregateData?.extra_fields?.field_label} onChange={(event) => editPropertySubmit({...aggregateData?.extra_fields, field_label: event.target.value})}
                    />
                    <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" onClick={() => setShowDropDown((flg) => !flg)}>
                        <div className="position-fixed">
                            <ul ref={dropdownRef} className={`dropdown-menu ${showDropDown ? 'show' : ''}`}>
                                {dropDownList?.length > 0 && dropDownList.map((action, dropindex) => (
                                    <li key={dropindex} onClick={handleAction(action)}>
                                        <a className="dropdown-item cursor-pointer">
                                            {(dropindex === 0 && checkRequired('required')) ||
                                                (dropindex === 1 && checkRequired('do_not_allow_duplicate_values')) ? (
                                                <i className="fa fa-check me-1" aria-hidden="true"></i>
                                            ) : null}
                                            {action}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <i className="fa fa-ellipsis-v"></i>
                    </button>
                </div>
                <small className="form-text text-muted ms-1">{aggregateData?.label}</small>
            </td>
            {showPermissionModal && (
                <FormModuleModel.SetPermissionModal
                    show={showPermissionModal}
                    handleClose={() => setShowPermissionModal(false)}
                />
            )}
            {showPropertyModal2 && PropertyModal && (
                <PropertyModal
                    moduleStoreData={moduleStoreData}
                    show={showPropertyModal2}
                    handleClose={() => {
                        setShowPropertyModal(false);
                        setShowPropertyModal2(false);
                    }}
                    item={aggregateData}
                    editPropertySubmit={editPropertySubmit}
                    section={section}
                />
            )}
        </>
    );
};

export default AggregateItem;
