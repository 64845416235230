import { call, put } from "redux-saga/effects";
import { createSubCurrencyFailure, createSubCurrencySuccess, updateSubCurrencyFailure, updateSubCurrencySuccess } from "../../../Slices/subCurrencySlice";
import { createSubCurrency, updateSubCurrency } from "../../../../Services/apiService";

export function* createSubCurrencyHandler(actions) {
    try {
        const response = yield call(createSubCurrency, actions?.payload);
        yield put(createSubCurrencySuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createSubCurrencyFailure(errResponse))
    }
}

export function* updateSubCurrencyHandler(actions) {
    try {
        const response = yield call(updateSubCurrency, actions?.payload);
        yield put(updateSubCurrencySuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateSubCurrencyFailure(errResponse))
    }
}