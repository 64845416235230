import React from 'react';

function KpiGrowthIndex() {
    return (
        <>
            <h6 className="card-title">Revenue This Month</h6>
            <h2 className="fw-bold">$391 <span className="text-danger">▼ 13.11%</span></h2>
            <p>Last Month: $450</p>
            <small className="text-muted">Growth Index</small>
        </>
    );
}

export default KpiGrowthIndex;
