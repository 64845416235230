import React, { useEffect, useState } from "react";
import digyLogo from '../../../Assets/images/crm-logo-final.svg';
import { useNavigate } from "react-router-dom";
import { Utils } from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordStart, userResetState } from "../../../Store/Slices/userSlice";
import { getItem } from "../../../Services/localService";
import Loader from "../../../Components/Loader";
import Toast from "../../../Components/Toast";
import { toast } from "react-toastify";

const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let userId = getItem('user')._id;

    const userData = useSelector((state) => state?.user);

    let [showOPassword, setShowOPassword] = useState(false);
    let [showPassword, setShowPassword] = useState(false);
    let [showCPassword, setShowCPassword] = useState(false);
    let [oldPassword, setOldPassword] = useState('');
    let [newPassword, setNewPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [passwordMatchError, setPasswordMatchError] = useState('');

    useEffect(() => {
        if (userData.passwordUpdateStatus) {
            toast.success(userData?.message, {
                autoClose: 2000,
                onClose: () => {
                    dispatch(userResetState());
                    navigate(Utils.getNavigationUrl(`dashboard`))
                }
            })
        }
        if (userData.passwordUpdateStatus === false) {
            toast.error(userData.message, {
                autoClose: 2000,
            })
        }
    }, [userData.passwordUpdateStatus])

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        if (e.target.name == 'oldPassword') {
            setOldPassword(value)
        }
        if (e.target.name == 'newPassword') {
            setNewPassword(value)
            if (confirmPassword && value !== confirmPassword) {
                setPasswordMatchError("Passwords do not match");
            } else {
                setPasswordMatchError("");
            }
        }
        if (e.target.name == 'confirmPassword') {
            setConfirmPassword(value);
            if (newPassword && value !== newPassword) {
                setPasswordMatchError("Passwords do not match");
            } else {
                setPasswordMatchError("");
            }
        }
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        const payload = {
            old_password: oldPassword,
            new_password: newPassword
        };
        dispatch(changePasswordStart({ userId, payload: payload }))
    };

    return (
        <React.Fragment>
            <Loader isVisible={userData?.isLoading} />
            <Toast />
            <div className="auth-bg"></div>
            <div className="d-flex flex-column flex-root h-100vh">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid auth-page auth-v1 forgot-password">
                    <div className="d-flex flex-column flex-lg-row-fluid order-2 order-lg-1 auth-l-box">
                        <div className="d-flex flex-column flex-lg-row-fluid">

                            <div className="w-lg-100">
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block">
                                        <img src={digyLogo} alt="digylax-mdm-logo" width="146" height="56" />
                                    </a>
                                </div>

                                <div className="mb-4">
                                    <h2 className="h4 text-dark fw-bold mb-2">
                                        <i className="fas fa-arrow-left fs-5 me-2 cursor-pointer"
                                            onClick={() => navigate(Utils.getNavigationUrl(`dashboard`))}
                                        />
                                        Change Password
                                    </h2>
                                    <div className="text-body-tertiary fs-6">
                                        <p>
                                            Enter a new and strong password below to change your password
                                        </p>
                                    </div>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="input-group password">
                                        <span className="input-group-text bg-transparent border-end-0 first-child" id="oldPassword">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
                                        <input
                                            type={showOPassword ? 'text' : 'password'}
                                            className="form-control border-start-0 border-end-0" placeholder="Old Password"
                                            aria-describedby="oldPassword" required
                                            name={'oldPassword'}
                                            onChange={handlePasswordChange}
                                        />
                                        <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                            onClick={() => { oldPassword && setShowOPassword(!showOPassword) }}>
                                            <i className={`fa-regular ${showOPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="input-group password">
                                        <span className="input-group-text bg-transparent border-end-0 first-child" id="newPassword">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="form-control border-start-0 border-end-0" placeholder="New Password"
                                            aria-describedby="newPassword" required
                                            name={'newPassword'}
                                            onChange={handlePasswordChange}
                                        />
                                        <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                            onClick={() => { newPassword && setShowPassword(!showPassword) }}>
                                            <i className={`fa-regular ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="mb-3 icon-input">
                                    <div className="input-group password">
                                        <span className="input-group-text bg-transparent border-end-0 first-child" id="confirmPassword">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
                                        <input
                                            type={showCPassword ? 'text' : 'password'}
                                            className="form-control border-start-0 border-end-0"
                                            placeholder="Confirm Password"
                                            aria-describedby="confirmPassword"
                                            required
                                            name={'confirmPassword'}
                                            onChange={handlePasswordChange}
                                        />
                                        <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                            onClick={() => { confirmPassword && setShowCPassword(!showCPassword) }}>
                                            <i className={`fa-regular ${showCPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </span>
                                    </div>
                                    {passwordMatchError && (
                                        <p className="text-danger mt-1">{passwordMatchError}</p>
                                    )}
                                </div>

                                <div className="d-grid">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handlePasswordReset}
                                        disabled={(newPassword !== confirmPassword) || newPassword == '' && confirmPassword == ''}  // Disable if either field is empty
                                    >
                                        <span className="indicator-label">Change Password</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ChangePassword;