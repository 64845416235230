import React, { useState, useEffect, useRef, useReducer } from "react";
import { DndContext, DragOverlay, useSensor, MouseSensor, TouchSensor, useSensors } from "@dnd-kit/core";
import { verticalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Utils } from "../../Utils/index";
import ModuleName from "../../Components/ModalPopup/FormModules/module_name";
import FormModuleRedux from "../../Redux/index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createFormsListStart } from "../../Store/Slices/formSlice";
import { useParams } from 'react-router-dom';
import { getModuleByIdStart, updateModuleByIdStart, resetState } from "../../Store/Slices/formSlice";
import PlusBlueIcon from "../../Assets/images/plus-blue-icon.svg";
import PaperPlusIcon from "../../Assets/images/paper-plus-icon.svg";
import DocumentIcon from "../../Assets/images/document-icon.svg";
import PlayIcon from "../../Assets/images/play-icon.svg";
import MenuDots from "../../Assets/images/menu-dots-icon.svg";
import PreviewModal from "../../Components/ModalPopup/FormModules/PreviewModal";
import Loader from "../../Components/Loader";
import SectionDroppableArea from "../../Components/formModules/sectionDroppableArea";
import SidebarMenus from "../../Components/formModules/SidebarMenus";
import { Helpers } from "../../Helpers";
import { getItem } from "../../Services/localService";
import { toast } from 'react-toastify';
import Toast from "../../Components/Toast";

const CreateUpdateModule = () => {
  let { id } = useParams();
  let currentUserData = getItem('user');
  // Digylax organization : 67627340b53f54575cf41de6
  let navigate = useNavigate();
  let getCreateUpdateListByID = useSelector((state) => state?.forms);
  let [oldData, setOldData] = useState(null);
  let moduleNameModelPopupRef = useRef(null);
  let [currentDragStartData, setCurrentDragStartData] = useState(null);
  let [moduleNameModelOpen, setModuleNameModelOpen] = useState(false);
  let [formSubmit, setFormSubmit] = useState(false);
  let [moduleStoreData, dispatch] = useReducer(FormModuleRedux.formModuleReducer, Utils.formModuleStoreData);
  let [width, setWidth] = useState(0);
  let elementRef = useRef(null);
  let createUpdateDispatch = useDispatch();
  let [showPropertyModal, setShowPropertyModal] = useState(false);
  let [showPreviewModal, setShowPreviewModal] = useState(false);
  let sectionSpacerInsertedRef = useRef();
  let fieldSpacerInsertedRef = useRef();
  let fieldSpacerInsertedRef2 = useRef();
  let currentSectionDragFieldRef = useRef();
  let currentFieldDragRef = useRef();
  let [fieldSpacer, setFieldSpacer] = useState(null);
  let [fieldSpacerSort, setFieldSpacerSortable] = useState(null);

  useEffect(() => {
    let modulesList = getCreateUpdateListByID?.modules;
    if (modulesList?.length > 0 && id) {
      dispatch({
        type: "UPDATE_DATA_PAGE",
        payload: modulesList?.[0].fields
      });
      setOldData(modulesList?.[0].fields);
    }
  }, [getCreateUpdateListByID?.modules]);

  useEffect(() => {
    if (getCreateUpdateListByID?.exit && formSubmit) {
      dispatch(resetState);
      navigate(Utils.getNavigationUrl(`form-module`))
    }
  }, [getCreateUpdateListByID]);

  useEffect(() => {
    if (id) {
      createUpdateDispatch(getModuleByIdStart(id));
    }
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getData = (prop) => {
    return prop?.data?.current ?? {};
  }

  const sectionSpacerCreater = (overData, over) => {
    if (!sectionSpacerInsertedRef?.current) {
      let sectionIndexNumber = overData?.field?.sectionIndex;
      let nextIndex = sectionIndexNumber > -1 ? sectionIndexNumber : moduleStoreData?.sections?.length;
      dispatch({
        type: 'ADD_SECTION_SPACER',
        payload: {
          index: nextIndex,
        }
      });
      sectionSpacerInsertedRef.current = true;
    } else if (!over) {
      sectionSpacerInsertedRef.current = false;
      dispatch({
        type: 'REMOVE_SECTION_SPACER'
      });
    } else {
      let sectionIndexNumber = overData?.field?.sectionIndex;
      let nextIndex = sectionIndexNumber > -1 ? sectionIndexNumber : moduleStoreData?.sections?.length - 1;
      dispatch({
        type: 'MOVE_SECTION_SPACER',
        payload: {
          index: nextIndex,
        }
      });
    }
  }

  const fieldSpacerCreater = (overData, over) => {
    let sectionIndexNumber = overData?.field?.sectionIndex;
    let nextIndex = sectionIndexNumber > -1 ? sectionIndexNumber : moduleStoreData?.sections?.length;
    let areaName = overData?.field?.area || "";
    let overIndex = overData?.field?.overIndex;
    if (fieldSpacer && (fieldSpacer?.index !== nextIndex || fieldSpacer?.areaName !== areaName)) {
      fieldSpacerInsertedRef.current = false;
      dispatch({
        type: 'REMOVE_FIELD_SPACER'
      });
    }
    if (!fieldSpacerInsertedRef?.current && areaName) {
      dispatch({
        type: 'ADD_FIELD_SPACER',
        payload: {
          index: nextIndex,
          areaName,
          overIndex: overIndex > -1 ? overIndex : moduleStoreData?.sections?.[nextIndex]?.dropped_list?.[areaName]?.length - 1
        }
      });
      setFieldSpacer({
        index: nextIndex,
        areaName,
        overIndex: overIndex > -1 ? overIndex : moduleStoreData?.sections?.[nextIndex]?.dropped_list?.[areaName]?.length - 1
      });
      fieldSpacerInsertedRef.current = true;
    } else if (!over) {
      fieldSpacerInsertedRef.current = false;
      dispatch({
        type: 'REMOVE_FIELD_SPACER'
      });
    } else {
      dispatch({
        type: 'MOVE_FIELD_SPACER',
        payload: {
          index: nextIndex,
          areaName,
          overIndex: overIndex > -1 ? overIndex : moduleStoreData?.sections?.[nextIndex]?.dropped_list?.[areaName]?.length - 1
        }
      });
    }
  }

  const fieldSpacerSortable = (overData, over) => {
    let sectionIndexNumber = overData?.field?.sectionIndex;
    let nextIndex = sectionIndexNumber > -1 ? sectionIndexNumber : moduleStoreData?.sections?.length;
    let areaName = overData?.field?.area || "";
    let overIndex = overData?.field?.overIndex;
    if (fieldSpacerSort && (fieldSpacerSort?.index !== nextIndex || fieldSpacerSort?.areaName !== areaName)) {
      fieldSpacerInsertedRef2.current = false;
      dispatch({
        type: 'REMOVE_FIELD_SPACER'
      });
      dispatch({
        type: 'ADD_FIELD_SPACER',
        payload: {
          index: nextIndex,
          areaName,
          overIndex: overIndex > -1 ? overIndex : moduleStoreData?.sections?.[nextIndex]?.dropped_list?.[areaName]?.length - 1
        }
      });
      setFieldSpacerSortable({
        index: nextIndex,
        areaName,
        overIndex: overIndex > -1 ? overIndex : moduleStoreData?.sections?.[nextIndex]?.dropped_list?.[areaName]?.length - 1
      });
    }
    if (fieldSpacerSort && (fieldSpacerSort?.index === nextIndex || fieldSpacerSort?.areaName === areaName)) {
      dispatch({
        type: 'MOVE_FIELD_SPACER',
        payload: {
          index: nextIndex,
          areaName,
          overIndex: overIndex > -1 ? overIndex : moduleStoreData?.sections?.[nextIndex]?.dropped_list?.[areaName]?.length - 1
        }
      });
    } else if (!over) {
      fieldSpacerInsertedRef2.current = false;
      dispatch({
        type: 'REMOVE_FIELD_SPACER'
      });
    }
  }

  const handleDragOver = (event) => {
    let { active, over } = event;
    let activeData = getData(active);
    let overData = getData(over);
    if (activeData?.fromSidebar && activeData?.field?.id !== 'new_section' && activeData?.field?.id !== 'subform') {
      fieldSpacerCreater(overData, over);
    } else if (activeData?.fromSidebar && activeData?.field?.id === 'new_section') {
      sectionSpacerCreater(overData, over);
    } else if (activeData?.fromSidebar && activeData?.field?.id === 'subform') {
      sectionSpacerCreater(overData, over);
    } else if (activeData?.fieldDroppable || activeData?.fieldSortable || overData?.fieldDroppable || overData?.fieldSortable) {
      fieldSpacerSortable(overData, over);
    }
  }

  const handleClickOutside = (event) => {
    if (moduleNameModelPopupRef.current && !moduleNameModelPopupRef.current.contains(event.target)) {
      setModuleNameModelOpen(false);
    }
  };

  const cleanUp = () => {
    sectionSpacerInsertedRef.current = false;
    fieldSpacerInsertedRef.current = false;
    fieldSpacerInsertedRef2.current = false;
  };

  const dublicateCheckForFields = (currentData) => {
    let dublicateLabelLength = 0;
    moduleStoreData.sections?.forEach(x => {
      if (x?.dropped_list && Object.keys(x?.dropped_list)?.length) {
        Object.keys(x.dropped_list).forEach(key => {
          let items = Helpers.getItemsOrDefault(x.dropped_list?.[key]);
          if (items) {
            dublicateLabelLength += items.filter(item => item?.label === currentData?.extra_fields?.field_label).length;
          }
        });
      } else if (x?.selected_list?.length > 0) {
        dublicateLabelLength += x.selected_list.filter(item => item?.label === currentData?.extra_fields?.field_label).length;
      }
    });
    return dublicateLabelLength;
  }

  const handleDragEnd = (event) => {
    let { active, over } = event;
    let activeData = getData(active);
    let overData = getData(over);
    cleanUp();
    let overIndexNumber = overData?.field?.sectionIndex;
    let nextSectionIndex = overIndexNumber > -1 ? overIndexNumber : moduleStoreData?.sections?.length;
    if (activeData?.fromSidebar && activeData?.field?.id !== 'new_section' && activeData?.field?.id !== 'subform') {
      let dublicateLabelLength = dublicateCheckForFields(activeData?.field);
      let newItem = {
        id: Utils.idGenerator(),
        label: activeData?.field?.label,
        extra_fields: { ...activeData?.field?.extra_fields, field_label: (dublicateLabelLength ? (activeData?.field.label + ' ' + dublicateLabelLength) : activeData?.field.label), ...((activeData?.field?.input_type === "lookup" || activeData?.field?.input_type === "multi-lookup") ? { moduleId: (getCreateUpdateListByID?.navbarList?.[0]?._id || "") } : {}) },
        input_type: activeData?.field?.input_type
      };
      dispatch({
        type: "ADD_DRAGABLE_ITEM",
        payload: {
          sectionIndex: overIndexNumber,
          area: overData?.field?.area,
          overIndex: overData?.field?.overIndex,
          newItem
        }
      });
    } else if (activeData?.fromSidebar && activeData?.field?.id === 'new_section') {
      dispatch({
        type: 'ADD_SECTION',
        payload: {
          index: nextSectionIndex
        }
      });
    } else if (activeData?.fromSidebar && activeData?.field?.id === 'subform') {
      dispatch({
        type: "ADD_SUBFORM",
        payload: {
          index: nextSectionIndex
        }
      });
    } else if (activeData?.sectionSortable && activeData?.field?.unique_name === 'new_section') {
      dispatch({
        type: 'CHANGE_SECTION_DATA',
        payload: {
          data: currentSectionDragFieldRef.current?.data,
          formIndex: activeData?.field?.sectionIndex,
          toIndex: overData?.field?.sectionIndex
        }
      });
    } else if (activeData?.sectionSortable && activeData?.field?.unique_name === 'subform') {
      dispatch({
        type: 'CHANGE_SECTION_DATA',
        payload: {
          data: currentSectionDragFieldRef.current?.data,
          formIndex: activeData?.field?.sectionIndex,
          toIndex: overData?.field?.sectionIndex
        }
      });
    } else if (activeData?.fieldDroppable || activeData?.fieldSortable || overData?.fieldDroppable || overData?.fieldSortable) {
      dispatch({
        type: 'ADD_DRAGABLE_ITEM',
        payload: {
          sectionIndex: overIndexNumber,
          area: overData?.field?.area,
          overIndex: overData?.field?.overIndex,
          newItem: currentFieldDragRef.current?.newItem
        }
      });
    } else if (currentFieldDragRef?.current) {
      dispatch({
        type: 'ADD_DRAGABLE_ITEM',
        payload: currentFieldDragRef.current
      });
    } else if (currentSectionDragFieldRef) {
      dispatch({
        type: 'CHANGE_SECTION_DATA',
        payload: {
          data: currentSectionDragFieldRef.current?.data,
          formIndex: currentSectionDragFieldRef.current?.data?.sectionIndex,
          toIndex: currentSectionDragFieldRef.current?.data?.sectionIndex
        }
      });
    }
    dispatch({
      type: 'REMOVE_SECTION_SPACER'
    });
    dispatch({
      type: 'REMOVE_FIELD_SPACER'
    });
    currentSectionDragFieldRef.current = null;
    currentFieldDragRef.current = null;
    setFieldSpacer(null);
    setFieldSpacerSortable(null);
    setCurrentDragStartData(null);
  };

  const dragStartEvent = (event) => {
    let { active } = event;
    let activeData = getData(active);
    setCurrentDragStartData(activeData);
    console.log(activeData)
    if (activeData?.sectionSortable && activeData?.field?.unique_name === 'new_section') {
      let { field } = activeData;
      currentSectionDragFieldRef.current = {
        index : field?.sectionIndex,
        data : moduleStoreData?.sections?.[field?.sectionIndex]
      };
      dispatch({
        type: 'MOVE_SECTION',
        payload: {
          index: field?.sectionIndex
        }
      });
    } else if (activeData?.sectionSortable && activeData?.field?.unique_name === 'subform') {
      let { field } = activeData;
      currentSectionDragFieldRef.current = {
        index : field?.sectionIndex,
        data : moduleStoreData?.sections?.[field?.sectionIndex]
      };
      dispatch({
        type: 'MOVE_SECTION',
        payload: {
          index: field?.sectionIndex
        }
      });
    } else if (activeData?.fieldSortable && activeData?.field?.id !== 'new_section' && activeData?.field?.id !== 'subform') {
      let { field } = activeData;
      currentFieldDragRef.current = {
        sectionIndex: field?.sectionIndex,
        area: field?.area,
        overIndex: field?.overIndex,
        newItem: moduleStoreData?.sections?.[field?.sectionIndex]?.dropped_list?.[field?.area]?.[field?.overIndex]
      }
      dispatch({
        type: 'MOVE_FIELD',
        payload: {
          index: field?.sectionIndex,
          areaName: field?.area,
          overIndex: field?.overIndex
        }
      });
      setFieldSpacerSortable({
        index: field?.sectionIndex,
        areaName: field?.area,
        overIndex: field?.overIndex
      });
    }
  }

  const moduleSave = async () => {
    if (moduleStoreData?.plural_form) {
      let emptySection = moduleStoreData.sections.find(section => !section.section_name.trim());
      if (emptySection) {
        toast.error("Section name cannot be empty", {
          autoClose: 200
        });
        return;
      }
      try {
        let moduleData = Utils.getModuleFields(moduleStoreData?.sections || []);
        let moduleFields = [...(moduleData?.moduleFields || []), ...(moduleData?.subformList || [])];
        let payload = {
          name: moduleStoreData.plural_form,
          fields: moduleStoreData,
          is_available_lookup: moduleFields.some(x => x?.fields?.input_type === "lookup" && !x?.fields?.extra_fields?.is_module_lookup || x?.fields?.input_type === "multi-lookup" && !x?.fields?.extra_fields?.is_module_lookup),
          lookup_list: moduleFields.filter(x => x?.fields?.input_type === "lookup" && !x?.fields?.extra_fields?.is_module_lookup || x?.fields?.input_type === "multi-lookup" && !x?.fields?.extra_fields?.is_module_lookup)
            .map(x => ({ field_label: x?.fields?.extra_fields?.field_label, moduleId: x?.fields?.extra_fields?.moduleId })),
          is_default : currentUserData?.organization?._id === "6763c15b097862b8d4c4e6e5"
        };
        let userName = `${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`;
        let auditLogData = Utils.logSectionNameChange(oldData, moduleStoreData, userName);
        if (id) {
          let updatedPayload = { ...payload, audit_log: auditLogData };
          createUpdateDispatch(
            updateModuleByIdStart({
              id, payload: updatedPayload
            })
          );
        } else {
          createUpdateDispatch(
            createFormsListStart(payload)
          );
        }
        setFormSubmit(true);
      } catch (err) {
        throw err;
      }
    } else {
      setModuleNameModelOpen(true);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
  );

  return (
    <>
      <Toast />
      <Loader isVisible={getCreateUpdateListByID?.isLoading} />
      <DndContext sensors={sensors} onDragOver={handleDragOver} onDragStart={dragStartEvent} onDragEnd={handleDragEnd} autoScroll={{ layoutShiftCompensation: false, enable: false }}>
        <div className="create-module">
          <div className="container-fluid">
            <div className="row">
              <div className="sidebar col-md-3 col-lg-3 p-0 bg-white h-100vh fixed-top">
                <div className="offcanvas-md offcanvas-end h-100" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                      aria-label="Close"></button>
                  </div>

                  <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 h-100 overflow-y-auto">
                    <div className="row g-0 draggable-btns">
                      {Utils.formModuleDatas.sidebar_fields.map((x, index) => (
                        <SidebarMenus key={index} field={x} />
                      ))}
                       {currentDragStartData && (
                         <DragOverlay>
                          {
                            (() => {
                              if (currentDragStartData?.field?.id !== 'new_section' && currentDragStartData?.field?.id !== 'subform' && !currentDragStartData?.sectionSortable) {
                                return (
                                  <div className="drop-box">
                                    <div className="field-container" style={{ width: "440px" }}>
                                      <div className="w-50">
                                        <input type="text" className="section-name-input custom-input-border w-100 text-start" id="titleOwner"
                                          value={currentDragStartData?.field?.extra_fields?.field_label} onChange={(event) => (event.target.value)}
                                        />
                                      </div>
                                      <div className="w-40 text-center cursor-grab">
                                        <span className="text-label d-block text-truncate ps-2" title={currentDragStartData?.field?.label}>{currentDragStartData?.field?.label}</span>
                                      </div>
                                      <div className="w-10 text-end cursor-pointer">
                                        <img src={MenuDots} alt="dots-icon" width="20" height="20" />
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (currentDragStartData?.field?.id === 'new_section' || currentDragStartData?.sectionSortable && currentDragStartData?.field?.unique_name === 'new_section') {
                                return (
                                  <div className="card drop-box" style={{ width: width + "px" }}>
                                    <h6 className="card-header py-3 fs-14">{moduleStoreData?.sections?.[currentDragStartData?.field?.sectionIndex]?.section_name || 'Untitled Section'}</h6>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-12">
                                          Drag Here
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              } else if (currentDragStartData?.field?.id === 'subform' || currentDragStartData?.sectionSortable && currentDragStartData?.field?.unique_name === 'subform') {
                                return (
                                  <div className="card drop-box" style={{ width: width + "px" }}>
                                    <h6 className="card-header py-3 fs-14">{moduleStoreData?.sections?.[currentDragStartData?.field?.sectionIndex]?.section_name || 'Subform'}</h6>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-12">
                                          Add Fields
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              return null;
                            })()
                          }
                         </DragOverlay>
                       )
                      }
                    </div>
                  </div>
                </div>
              </div>

              <main className="col-md-9 col-lg-9 ms-sm-auto px-md-4 pb-md-4 content-section">
                <header className="navbar navbar-expand-lg sticky-top bg-white border-bottom border-light-subtle px-0 tb-header">
                  <nav className="container-fluid flex-wrap flex-lg-nowrap">
                    <ul className="navbar-nav flex-row btn-group">
                      <li className="nav-item text-nowrap">
                        <button type="button" className="btn btn-outline-primary rounded-pill light-blue">
                          <img className="me-2" src={PlusBlueIcon} alt="plus-icon" width="24" height="24" />
                          <span>Create</span>
                        </button>
                      </li>
                      <li className="nav-item text-nowrap">
                        <button type="button" disabled className="btn btn-outline-primary rounded-pill btn-plain-border light-blue">
                          <img className="me-2" src={PaperPlusIcon} alt="paper-plus-icon" width="24" height="24" />
                          <span>Quick Create</span>
                        </button>
                      </li>
                      <li className="nav-item text-nowrap">
                        <button type="button" disabled className="btn btn-outline-primary rounded-pill btn-plain-border light-blue">
                          <img className="me-2" src={DocumentIcon} alt="doc-icon" width="24" height="24" />
                          <span>Detail View</span>
                        </button>
                      </li>
                    </ul>
                    <ul className="navbar-nav flex-row">
                      <li className="nav-item dropdown">
                        <div className="position-relative">
                          <a className="nav-link dropdown-toggle" onClick={() => setModuleNameModelOpen(true)}>
                            {moduleStoreData?.plural_form || 'User Module Name'}
                          </a>
                          {moduleNameModelOpen && (<div ref={moduleNameModelPopupRef}><ModuleName moduleStoreData={moduleStoreData} dispatch={dispatch} onCLickClose={() => setModuleNameModelOpen(false)} /></div>)}
                        </div>
                      </li>
                    </ul>
                    <ul className="navbar-nav flex-row btn-group">
                      <li className="nav-item text-nowrap">
                        <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => navigate(-1)}>
                          <span>Cancel</span>
                        </button>
                      </li>
                      <li className="nav-item text-nowrap">
                        <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => moduleSave()}>
                          <span>Save</span>
                        </button>
                      </li>
                      <li className="nav-item text-nowrap d-md-none">
                        <button className="nav-link px-3 text-dark" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu"
                          aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                          <i className="fa-solid fa-bars"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </header>
                <div className="d-flex justify-content-end px-md-4 pt-3">
                  <button type="button" className="btn btn-outline-primary rounded-pill white-blue" onClick={() => setShowPreviewModal(true)}>
                    <img className="me-2" src={PlayIcon} alt="play-icon" width="24" height="24" />
                    <span>Preview</span>
                  </button>
                </div>
                <div ref={elementRef}>
                  <SortableContext disabled={showPropertyModal || currentDragStartData?.field?.unique_name && !(currentDragStartData?.field?.unique_name === 'new_section' || currentDragStartData?.field?.unique_name === 'subform')} items={moduleStoreData?.sections?.map((section) => section.id)} strategy={verticalListSortingStrategy}>
                    <SectionDroppableArea width={width} currentDragStartData={currentDragStartData} moduleStoreData={moduleStoreData} dispatch={dispatch} setShowPropertyModal={setShowPropertyModal} />
                  </SortableContext>
                </div>
              </main>
            </div>
          </div>
        </div>
      </DndContext>
      {showPreviewModal && <PreviewModal show={showPreviewModal} preview={true} moduleStoreData={moduleStoreData} handleClose={() => setShowPreviewModal(false)} />}
    </>
  );
};

export default CreateUpdateModule;
