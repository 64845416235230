import SetPermissionModal from './SetPermissionModal';
import ModuleName from './module_name';
import EmailPropertiesModal from "./EmailPropertiesModal";
import LookupPropertiesModal from "./SingleLookupProperties/LookupPropertiesModal";
import MultiLookupProperties from "./MultiLookupProperties";
import PickListProperties from "./PickListProperties";
import DatePropertiesModal from "./DatePropertiesModal";
import DateTimePropertiesModal from "./DateTimePropertiesModal";
import AutoNumberPropertiesModal from "./AutoNumberPropertiesModal";
import UserFieldPropertiesModal from "./UserFieldPropertiesModal";
import RollupSummary from "./RollupSummary";
import CRMFormulaForm from "./CRMFormulaForm";
import SectionSettingDropDown from "./sectionSettingDropDown";
import SingleLinePropertiesModal from "./SingleLinePropertiesModal";
import MultiLinePropertiesModal from "./MultiLinePropertiesModal";
import PreviewModal from "./PreviewModal";
import MultiPickListProperties from "./MultiPickListProperties";
import AggregateProperties from "./AggregateProperties";
import PhonePropertiesModal from "./PhonePropertiesModal";
import CheckboxPropertiesModal from './CheckboxPropertiesModal';
import CurrencyPropertiesModal from './CurrencyPropertiesModal';
import DecimalPropertiesModal from './DecimalPropertiesModal';
import FileUploadPropertiesModal from './FileUploadPropertiesModal';
import ImageUploadPropertiesModal from './ImageUploadPropertiesModal';
import LongIntegerPropertiesModal from './LongIntegerPropertiesModal';
import NumberPropertiesModal from './NumberPropertiesModal';
import PercentPropertiesModal from './PercentPropertiesModal';
import URLPropertiesModal from './URLPropertiesModal';

const FormModuleModel = {
  SetPermissionModal,
  ModuleName,
  EmailPropertiesModal,
  LookupPropertiesModal,
  MultiLookupProperties,
  PickListProperties,
  DatePropertiesModal,
  DateTimePropertiesModal,
  AutoNumberPropertiesModal,
  UserFieldPropertiesModal,
  CRMFormulaForm,
  SectionSettingDropDown,
  RollupSummary,
  SingleLinePropertiesModal,
  MultiLinePropertiesModal,
  PreviewModal,
  MultiPickListProperties,
  AggregateProperties,
  PhonePropertiesModal,
  CheckboxPropertiesModal,
  CurrencyPropertiesModal,
  DecimalPropertiesModal,
  FileUploadPropertiesModal,
  ImageUploadPropertiesModal,
  LongIntegerPropertiesModal,
  NumberPropertiesModal,
  PercentPropertiesModal,
  URLPropertiesModal
};

export { FormModuleModel };