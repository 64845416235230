import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DashboardComponents } from '../../../DashboardComponents';
import { useDispatch, useSelector } from 'react-redux';
import { Utils } from '../../../../Utils';
import FillterData from '../../../FillterData';
import Loader from '../../../Loader';
import { getDashboardModuleByIdStart } from '../../../../Store/Slices/dashboardComponentsSlice';

function QuickChart({ show, handleClose, submitData, isData = null, allList = [] }) {
    let [chartDropdownList] = useState([
        {
            name: "Column chart",
            modal_name: "ColumnChart",
            icon: "fas fa-chart-column me-2"
        },
        {
            name: "Donut chart",
            modal_name: "DonutChart",
            icon: "fas fa-chart-pie me-2"
        },
        {
            name: "Pie chart",
            modal_name: "PieChart",
            icon: "fas fa-chart-pie me-2"
        },
        {
            name: "Bar chart",
            modal_name: "BarChart",
            icon: "fas fa-chart-bar me-2"
        },
        {
            name: "Line chart",
            modal_name: "LineChart",
            icon: "fas fa-chart-line me-2"
        },
        {
            name: "Funnel chart",
            modal_name: "FunnelChart",
            icon: "fas fa-filter me-2"
        }
    ]);
    let [selectedChart, setSelectedChart] = useState(isData?.selectedChart || chartDropdownList[0]);
    let [ShowChart, setShowChart] = useState(null);
    let [moduleId, setModuleId] = useState(isData?.moduleId || "");
    let [companyName, setCompanyName] = useState(isData?.company_name || "");
    let [moduleName, setModuleName] = useState(isData?.module_name || "");
    let [yAxisMeasure, setYAxisMeasure] = useState(isData?.y_axis_measure || "");
    let [grouping, setGrouping] = useState(isData?.grouping || "");
    let [groupingType, setGroupingType] = useState(isData?.grouping_type || "");
    let [groupingOptions, setGroupingOptions] = useState(isData?.grouping_options || []);
    let dispatch = useDispatch();
    let moduleData = useSelector((state) => state?.forms);
    let moduleFieldData = useSelector((state) => state?.dashboardComponents);
    let [numericalList, setNumericalList] = useState([]);
    let [dateList, setDateList] = useState([]);
    let [dublicateCheck, setDublicateCheck] = useState(null);
    let [allTypeDatas, setAllTypeDatas] = useState([]);
    let [allDatas, setAllDatas] = useState([]);
    let [enableCriteria, setEnableCriteria] = useState(isData?.is_enable_criteria || false);
    let [ criteriaFilter, setCriteriaFilter ] = useState({
        criteria : isData?.criteria || [],
        relationships : isData?.relationships || [],
        relationship_pattern : isData?.relationship_pattern || "",
    });
    let [resetCriteria, setResetCriteria] = useState(false);

    useEffect(() => {
        if (moduleFieldData?.moduleDatas) {
            let data = moduleFieldData?.moduleDatas;
            let numericalData = Utils.getModuleFields(data?.fields?.sections, Utils.numberInputTypes);
            let dateData = Utils.getModuleFields(data?.fields?.sections, Utils.dateInputTypes);
            let allData = checkSingleUser(Utils.getModuleFields(data?.fields?.sections, [...Utils.stringInputTypes, ...Utils.numberInputTypes]));
            let allData2 = checkSingleUser(Utils.getModuleFields(data?.fields?.sections, [...Utils.stringInputTypes, ...Utils.numberInputTypes, ...Utils.dateInputTypes]));
            setAllDatas(allData2);
            setAllTypeDatas(allData);
            setNumericalList(numericalData);
            setDateList(dateData);
        }
    }, [moduleFieldData?.moduleDatas]);

    let checkSingleUser = (data) => {
        if (!data?.moduleFields?.length) return [];
        return {
            moduleFields: data?.moduleFields?.filter(x =>
                x?.input_type !== "user" || (x?.input_type === "user" && x?.extra_fields?.user_type === "single_user")
            ) || []
        }
    };

    useEffect(() => {
        if(moduleId) {
            dispatch(getDashboardModuleByIdStart(moduleId));
        }
    }, [moduleId]);

    let onKpiMetricChange = (event) => {
        let moduleIdValue = event?.target?.value;
        let data = moduleData?.navbarList?.find(x => x?._id === moduleIdValue);
        setModuleId(moduleIdValue);
        setModuleName(data?.name);
        setYAxisMeasure(data?.name ? `Count of - ${data?.name}` : "");
        setResetCriteria(true);
    }

    let onClickSaveData = () => {
        let checkDublicate = allList?.some(x => x?.company_name === companyName && isData?.company_name !== companyName);
        if (checkDublicate) {
            setDublicateCheck(`A ${companyName} with this name already exists.`);
        } else {
            submitData({
                moduleId,
                company_name: companyName,
                module_name: moduleName,
                y_axis_measure : yAxisMeasure,
                grouping,
                grouping_type : groupingType,
                grouping_options : selectedChart?.modal_name === "FunnelChart" ? groupingOptions : [],
                modal_name: selectedChart?.modal_name,
                main_modal: "QuickChart",
                selectedChart,
                position: isData ? isData?.position : {
                    x: 0,
                    y: Infinity,
                    w: 5,
                    h: 5,
                    i: Utils.idGenerator(),
                    minW: 3, minH: 3
                },
                ...criteriaFilter,
                is_enable_criteria : enableCriteria
            });
            handleClose();
        }
    }

    let onClickChart = (item) => {
        let ChartComponent = DashboardComponents[item?.modal_name];
        if (ChartComponent) {
            setShowChart(() => ChartComponent);
        } else { }
        setSelectedChart(item);
    }

    useEffect(() => {
        onClickChart(selectedChart);
    }, []);

    let onChangeGrouping = (event) => {
        let target = event?.target;
        let selectedOption = target?.options?.[target?.selectedIndex]
        let parentOptgroupLabel = selectedOption?.parentElement?.label;
        setGrouping(target?.value);
        let checkField = (parentOptgroupLabel === "Period" || parentOptgroupLabel === "Dates") ? 'date' : (allTypeDatas?.moduleFields?.find(x => (x?.fields?.extra_fields?.field_label === target?.value)))?.fields;
        setGroupingType(checkField?.input_type);
        setGroupingOptions(checkField?.input_type === "select" ? (checkField?.extra_fields?.options || []) : []);
    }
    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Add Chart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {moduleFieldData?.isLoadingModule && (
                    <Loader isVisible={true} isModal={true} overall={false} />
                )}
                <nav className="container-fluid d-block">
                    <div className="row">
                        <div className="col-md-6 mt-2">
                            <div className="mb-3 form-floating">
                                <input
                                    type="text"
                                    className={`form-control ${(dublicateCheck?.length > 0) ? 'is-invalid' : ''}`}
                                    id="componentName"
                                    placeholder="Enter Component Name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e?.target?.value)}
                                    required
                                />
                                {(dublicateCheck?.length > 0) && <span className="text-danger mt-1">{dublicateCheck}</span>}
                                <label htmlFor="componentName">Component Name</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="KPIMetric" required value={moduleId} onChange={onKpiMetricChange}>
                                    <option value="">Select Module</option>
                                    {(moduleData?.navbarList?.length > 0) && (
                                        moduleData?.navbarList?.map((x, index) => (
                                            <option key={index} value={x?._id}>{x?.name}</option>
                                        ))
                                    )}
                                </select>
                                <label htmlFor="KPIMetric">Module(s)</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="yAxisMeasure" required value={yAxisMeasure} onChange={(e) => setYAxisMeasure(e?.target?.value)} disabled={!moduleName}>
                                    <option value="">Select Measure ( y-axis )</option>
                                    <option value={`Count of - ${moduleName}`}>Count of - {moduleName}</option>
                                    {numericalList?.moduleFields?.length > 0 && (
                                        numericalList.moduleFields.map((x, index) => {
                                            let fieldLabel = x?.fields?.extra_fields?.field_label;
                                            return (
                                                <React.Fragment key={index}>
                                                    <option value={`Sum of ${fieldLabel}`}>Sum of {fieldLabel}</option>
                                                    <option value={`Minimum of ${fieldLabel}`}>Minimum of {fieldLabel}</option>
                                                    <option value={`Maximum of ${fieldLabel}`}>Maximum of {fieldLabel}</option>
                                                    <option value={`Average of ${fieldLabel}`}>Average of {fieldLabel}</option>
                                                </React.Fragment>
                                            );
                                        })
                                    )}

                                </select>
                                <label htmlFor="yAxisMeasure">Measure ( y-axis )</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="grouping" required value={grouping} onChange={onChangeGrouping} disabled={!moduleName}>
                                    <option value="">Select Grouping</option>
                                    <optgroup label="Period">
                                        <option value="day">By Day</option>
                                        <option value="month">By Month</option>
                                        <option value="year">By Year</option>
                                    </optgroup>
                                    <optgroup label="Dates">
                                        <option value="Created At">Created At</option>
                                        {dateList?.moduleFields?.length > 0 && (
                                            dateList?.moduleFields?.map((x, index) => (
                                                <option key={"dates" + index} value={x?.fields?.extra_fields?.field_label}>{x?.fields?.extra_fields?.field_label}</option>
                                            ))
                                        )}
                                    </optgroup>
                                    <optgroup label="Dimensions">
                                        {allTypeDatas?.moduleFields?.length > 0 && (
                                            allTypeDatas?.moduleFields?.map((x, index) => (
                                                <option key={"dimensions" + index} value={x?.fields?.extra_fields?.field_label}>{x?.fields?.extra_fields?.field_label}</option>
                                            ))
                                        )}
                                    </optgroup>
                                </select>
                                <label htmlFor="grouping">Grouping</label>
                            </div>
                            {(!enableCriteria && moduleName) && (
                                <button type="button" className="btn btn-primary btn-sm me-2" onClick={() => setEnableCriteria(true)}>+ Criteria filter</button>
                            )}
                            {(enableCriteria && moduleName) && (
                                <FillterData title={'Criteria Filter'} onClickRemove={(flg) => setEnableCriteria(flg)}  data={allDatas?.moduleFields} onChangeCriteriaFilter={(items) => setCriteriaFilter(items)} selectedDatas={criteriaFilter} resetCriteria={resetCriteria} setResetCriteria={setResetCriteria}/>
                            )}
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-center">
                            <div className="w-100 d-flex justify-content-end mb-2">
                                <div className="dropdown">
                                    <button className="btn btn-outline-primary dropdown-toggle" type="button" id="chartDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-chart-bar me-2"></i> {selectedChart?.name}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="chartDropdown">
                                        {chartDropdownList.map((x, index) => (
                                            <li key={index} onClick={() => onClickChart(x)}><a className="dropdown-item"><i className={x.icon}></i>{x?.name}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="preview-box rounded p-3 border text-center position-relative" style={{ backgroundColor: '#f8f9fa', width: '100%', height: '300px' }}>
                                {/* <span className="text-muted position-absolute start-50 translate-middle-x mb-2">Visual preview will appear once the module is selected</span> */}
                                {ShowChart && <ShowChart isStatic={true}/>}
                            </div>
                        </div>
                    </div>
                </nav>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={!grouping} onClick={onClickSaveData}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default QuickChart;
