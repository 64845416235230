import ChartModel from "./Chart";
import FromReportChart from "./Chart/FromReportChart";
import QuickChart from "./Chart/QuickChart";
import KpiModel from "./Kpi";
import KpiBasic from "./Kpi/KpiBasic";
import KpiGrowthIndex from "./Kpi/KpiGrowthIndex";
import KpiRankings from "./Kpi/KpiRankings";
import KpiScorecard from "./Kpi/KpiScorecard";
import KpiStandard from "./Kpi/KpiStandard";
import Table from "./Table";
import TableAssignment from "./Table/TableAssignment";
import TargetMeter from "./TargetMeter";
import DialGaugeChart from "./TargetMeter/DialGaugeChart";

const AllChartModel = {
    ChartModel,
    QuickChart,
    FromReportChart,
    KpiModel,
    KpiBasic,
    KpiGrowthIndex,
    KpiStandard,
    KpiRankings,
    KpiScorecard,
    TargetMeter,
    DialGaugeChart,
    Table,
    TableAssignment
}

export { AllChartModel };