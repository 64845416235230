import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { handleFieldLabelUpdate } from '../../../Utils'; 

function EmailPropertiesModal({ show, handleClose, item, editPropertySubmit }) {
  let fields = item?.extra_fields;
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label);
  const [showTooltip, setShowTooltip] = useState({ checked: fields?.show_tooltip?.checked, type: fields?.show_tooltip?.type, message: fields?.show_tooltip?.message });
  const [required, setRequired] = useState(fields?.required);
  const [showPreview, setShowPreview] = useState(false);
  const [doNotAllowDuplicateValues, setDoNotAllowDuplicateValues] = useState(fields?.do_Not_Allow_duplicate_values);
  const [encryptField, setEncryptField] = useState(fields?.encrypt_field);
  const [labelError, setLabelError] = useState(""); 

  const handleDone = () => {
    let formData = {
      field_label: fieldLabel,
      required,
      show_tooltip: showTooltip,
      encrypt_field: encryptField,
      do_not_allow_duplicate_values: doNotAllowDuplicateValues,
    };
    editPropertySubmit(formData);
    handleClose();
  };
  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };


  const handleCancel = () => {
    setFieldLabel(fields?.field_label);
    setRequired(fields?.required);
    setShowTooltip({ checked: false, type: 'static', message: '' });
    setEncryptField(fields?.encrypt_field);
    setDoNotAllowDuplicateValues(fields?.do_not_allow_duplicate_values);
    handleClose();
  };

  const handleFieldLabelChange = (e) => {
    handleFieldLabelUpdate(e.target.value, setFieldLabel, setLabelError);
  }
  
  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
      <Modal.Header closeButton>
        <Modal.Title>Email Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group controlId="formFieldLabel">
            <Form.Label>Field Label</Form.Label>
            <Form.Control 
              type="text" 
              name="field_label" 
              value={fieldLabel} 
              onChange={handleFieldLabelChange} 
            />
            {labelError && <div className="text-danger">{labelError}</div>} 
          </Form.Group>

          <Form.Group controlId="formRequired" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Required"
              checked={required}
              onChange={() => setRequired(!required)}
            />
          </Form.Group>

          <Form.Group controlId="formdoNotAllowDuplicateValues" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Do Not Allow duplicate values"
              checked={doNotAllowDuplicateValues}
              onChange={() => setDoNotAllowDuplicateValues(!doNotAllowDuplicateValues)}
            />
          </Form.Group>

          <Form.Group controlId="formEncryptField" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Encrypt Field"
              checked={encryptField}
              onChange={() => setEncryptField(!encryptField)}
            />
          </Form.Group>

          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip.checked}
              onChange={() => setShowTooltip({ ...showTooltip, checked: !showTooltip.checked })}
            />
          </Form.Group>

          {showTooltip.checked && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Message (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={showTooltip.message}
                  onChange={(e) => setShowTooltip({ ...showTooltip, message: e.target.value })}
                  placeholder="Enter Tooltip message"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={showTooltip.type === 'info'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'info' })}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showTooltip.type === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {showTooltip.message && <span className="ms-2">{showTooltip.message}</span>}
                    </div>
                  ) : (
                    <p>{showTooltip.message}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="primary" onClick={handleDone}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmailPropertiesModal;
