import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    homeCurrencies: null,
    user: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    createStatus: null,
    updateStatus: null,
    companyData: null,
    orgData: null,
    permissions: {
        isLoading: false,
        data: null,
        message: null,
        error: null,
        totalCount: null
    }
}

const orgSlice = createSlice({
    name: 'org',
    initialState,
    reducers: {
        getCompanyListStart: (state) => {
            state.isLoading = true;
        },
        getCompanyListSuccess: (state, action) => {
            state.data = action?.payload?.organizations;
            state.homeCurrencies = action?.payload?.currencies;
            // new state for get data
            state.companyData = action?.payload?.organizations;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getCompanyListFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        uploadCompanyProfileStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
        },
        uploadCompanyProfileSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            // state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        uploadCompanyProfileFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        updateCompanyStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.updateStatus = null;
        },
        updateCompanySuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateOrganization;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        updateCompanyFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        createCompanyStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.successStatus = null;
            state.createStatus = null;
            state.orgData = null;
            state.message = null;
        },
        createCompanySuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.createStatus = action?.payload?.success;
            state.orgData = action?.payload?.savedOrganization;
        },
        createCompanyFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message || action?.payload;
            state.successStatus = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        resetStates: (state) => {
            state.reload = null;
            state.createStatus = null;
            state.successStatus = null;
            state.updateStatus = null;
        }
    }
});

export const {
    getCompanyListStart,
    getCompanyListSuccess,
    getCompanyListFailure,
    uploadCompanyProfileStart,
    uploadCompanyProfileSuccess,
    uploadCompanyProfileFailure,
    updateCompanyStart,
    updateCompanySuccess,
    updateCompanyFailure,
    createCompanyStart,
    createCompanySuccess,
    createCompanyFailure,
    resetStates
} = orgSlice?.actions

export default orgSlice?.reducer;