import { call, put } from "redux-saga/effects";
import { checkExistUser, forgotPassword, login, register, sendOtp, userRegister, verifyEmail, verifyOtpEmail } from "../../../../Services/apiService";
import { forgotPasswordFailure, forgotPasswordSuccess, loginFailure, loginSuccess, sendOtpFailure, sendOtpSuccess, verifyEmailFailure, verifyEmailSuccess, verifyOtpFailure, verifyOtpSuccess } from "../../../Slices/authSlice";
import { registerFailure, registerSuccess, userExistFailure, userExistSuccess, userRegisterFailure, userRegisterSuccess } from "../../../Slices/registerSlice";

export function* loginHandler(actions) {
    try {
        let response = yield call(login, actions?.payload);
        yield put(loginSuccess(response?.data));
    } catch (err) {
        yield put(loginFailure(err?.response?.data || 'Login failed. Please check your username and password.'));
    }
}

export function* registerHandler(actions) {
    try {
        let response = yield call(register, actions?.payload);
        debugger
        yield put(registerSuccess(response?.data));
    } catch (err) {
        debugger
        yield put(registerFailure(err?.response?.data || 'Already Registered'));
    }
}

export function* userRegisterHandler(actions) {
    try {
        let response = yield call(userRegister, actions?.payload);
        debugger
        yield put(userRegisterSuccess(response?.data));
    } catch (err) {
        debugger
        yield put(userRegisterFailure(err?.response?.data || 'Already Registered'));
    }
}

export function* verifyEmailHandler(action) {
    try {
        let response = yield call(verifyEmail, action.payload);
        if (response?.data)
            yield put(verifyEmailSuccess(response?.data));
        if (response.error)
            yield put(verifyEmailSuccess(response?.error));
    } catch (err) {
        yield put(verifyEmailFailure(err?.response?.data || 'Verification failed. Please try again.'));
    }
}


export function* sendOtpHandler(action) {
    try {
        const response = yield call(sendOtp, action.payload);
        if (response?.data) {
            yield put(sendOtpSuccess(response?.data));
        } else {
            throw new Error(response?.error || 'Failed to send OTP');
        }
    } catch (err) {
        yield put(sendOtpFailure(err?.response?.data || 'OTP sending failed. Please try again.'));
    }
}

export function* verifyOtpHandler(action) {
    try {
        const response = yield call(verifyOtpEmail, action.payload);
        if (response?.data)
            yield put(verifyOtpSuccess(response?.data));
        else
            yield put(verifyOtpSuccess(response?.error));
    } catch (err) {
        yield put(verifyOtpFailure(err?.response?.data || 'OTP verification failed.'));
    }
}

export function* forgotPasswordHandler(action) {
    try {
        const response = yield call(forgotPassword, action.payload);
        yield put(forgotPasswordSuccess(response?.data));
    } catch (err) {
        yield put(forgotPasswordFailure(err?.response?.data || 'Password reset failed.'));
    }
}

export function* checkExistUserHandler(actions) {
    try {
        const response = yield call(checkExistUser, actions.payload);
        // debugger
        yield put(userExistSuccess(response));
    } catch (err) {
        yield put(userExistFailure(err));
    }
}