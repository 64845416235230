export const getPreviousYearRange = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const startDate = new Date(date);
    startDate.setFullYear(date.getFullYear() - 1);
    return {
        previousStartDate: formatLocalDate(startDate),
        previousEndDate: formatLocalDate(date)
    };
}

export const getCurrentYearRange = () => {
    const date = new Date();
    const startDate = new Date(date);
    startDate.setFullYear(date.getFullYear() + 1);
    return {
        currentStartDate: formatLocalDate(date),
        currentEndDate: formatLocalDate(startDate)
    };
}

export const getNextYearRange = () => {
    const sdate = new Date();
    sdate.setDate(sdate.getDate() + 1);
    const startDate = new Date(sdate);
    const edate = new Date();
    const endDate = new Date(edate);
    startDate.setFullYear(sdate.getFullYear() + 1);
    endDate.setFullYear(edate.getFullYear() + 2);
    return {
        nextStartDate: formatLocalDate(startDate),
        nextEndDate: formatLocalDate(endDate)
    };
}
const formatLocalDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
export const formatFilterDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
export const formatDate = (_date) => {
    const date = new Date(_date)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}
export const formatMonth = (_date) => {
    const date = new Date(_date)
    return `${date.toLocaleString('en-EN', { month: "short" })} ${date.getDate()}`
}
export const formatDateTime = (_date) => {
    const date = new Date(_date)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
}
export const formatTime = (_date) => {
    const date = new Date(_date)
    return `${date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`
}
export const extractNumber = (value) => {
    const numbersOnly = value.replace(/\D/g, '');
    const numbersAsNumber = parseInt(numbersOnly, 10);
    return numbersAsNumber
}
export const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}