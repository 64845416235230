import React, { useEffect, useState, useRef } from "react";
import { Modal, Button } from 'react-bootstrap';
import { getAllFormModuleTableDataStart } from "../../../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import dayjs from "dayjs";
import Loader from "../../../Loader";

function AssignmentModuleChange({ show, handleClose, moduleId, setDefaultModuleSubFormChangeData }) {
    let gridRef = useRef();
    let dispatch = useDispatch();
    let formList = useSelector((state) => state?.forms);
    let [tableData, setTableData] = useState([]);
    let [columnDefs] = useState([
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            sortable: false,
            suppressMenu: true,
            filter: false,
        },
        { headerName: "Module Name", field: "name", width: 350 },
        {
            headerName: "Created By",
            field: "createdAt",
            valueFormatter: (params) => dayjs(params?.value).format("MM/DD/YYYY"), width: 350 
        },
        {
            headerName: "Modified By",
            field: "createdBy",
            valueFormatter: (params) => {
                let creator = params.value;
                return creator ? `${creator?.firstName} ${creator?.lastName}` : "Unknown";
            },
            width: 350,
        }
    ]);

    let onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setQuickFilter(event.target.value);
    };

    useEffect(() => {
        if (formList?.tableData?.length > 0) {
            setTableData(formList?.tableData);
        }
    }, [formList?.tableData]);

    useEffect(() => {
        if(!formList?.tableData?.length) {
            dispatch(getAllFormModuleTableDataStart());
        }
    }, []);

    let onGridReady = (params) => {
        gridRef.current = params.api;
        params.api.addEventListener('firstDataRendered', () => {
            let node = params.api.getRowNode(moduleId);
            if (node) {
                node.setSelected(true);
            }
        });
    };

    let onRowSelected = (event) => {
        let node = event?.node;
        if (node?.selected && node?.data?._id !== moduleId) {
            let dataName = node?.data?.name || "";
            setDefaultModuleSubFormChangeData({
                name : {
                    _id : node?.data?._id,
                    name : dataName
                },
                moduleId : node?.data?._id
            });
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Choose</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${formList?.isLoading ? "vh-100" : ""}`}>
                {formList?.isLoading && (
                    <Loader isVisible={true} isModal={true} overall={false} />
                )}
                <div className="card rounded-4 border-0 p-0 form-module">
                    <div className="card-body">
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="left d-flex align-items-start">
                                <div className="search-box border rounded-pill">
                                    <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    <input type="search" placeholder="Search" className="searchfield bg-white" onInput={onFilterTextBoxChanged} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="ag-theme-quartz" style={{ height: "calc(100vh - 21vh)", display: "flex", flexDirection: "column", position: "relative" }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={tableData}
                                    columnDefs={columnDefs}
                                    defaultColDef={{
                                        filter: 'agTextColumnFilter',
                                        floatingFilter: true,
                                    }}
                                    rowSelection="single"
                                    pagination={true}
                                    rowHeight={40}
                                    floatingFiltersHeight={40}
                                    headerHeight={40}
                                    onGridReady={onGridReady}
                                    getRowId={(params) => params?.data?._id}
                                    onRowSelected={onRowSelected}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AssignmentModuleChange;