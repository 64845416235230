import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DialGaugeChart2 from '../../../DashboardComponents/TargetMeterComponents/DialGauge';
import { useDispatch, useSelector } from 'react-redux';
import { Utils } from '../../../../Utils';
import FillterData from '../../../FillterData';
import { getDashboardModuleByIdStart } from '../../../../Store/Slices/dashboardComponentsSlice';
import Loader from '../../../Loader';

function DialGaugeChart({ show, handleClose, submitData, isData = null, allList = [] }) {
    let [moduleId, setModuleId] = useState(isData?.moduleId || "");
    let [companyName, setCompanyName] = useState(isData?.company_name || "");
    let [moduleName, setModuleName] = useState(isData?.module_name || "");
    let [field, setField] = useState(isData?.field || "");
    let [fieldType, setFieldType] = useState(isData?.field_type || "");
    let [duration1, setDuration1] = useState(isData?.duration1 || "");
    let [duration2, setDuration2] = useState(isData?.duration2 || "");
    let [target, setTarget] = useState(isData?.target || "");
    let dispatch = useDispatch();
    let moduleData = useSelector((state) => state?.forms);
    let moduleFieldData = useSelector((state) => state?.dashboardComponents);
    let [numericalList, setNumericalList] = useState([]);
    let [dateList, setDateList] = useState([]);
    let [durations] = useState([
        { label: "All", value: "all" },
        { label: "Today", value: "today" },
        { label: "This Week", value: "this_week" },
        { label: "This Month", value: "this_month" },
        { label: "This Quarter", value: "this_quarter" },
        { label: "This Year", value: "this_year" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Previous Week", value: "previous_week" },
        { label: "Previous Month", value: "previous_month" },
        { label: "Previous Quarter", value: "previous_quarter" },
        { label: "Previous Year", value: "previous_year" },
        { label: "Tomorrow", value: "tomorrow" },
        { label: "Next Week", value: "next_week" },
        { label: "Next Month", value: "next_month" },
        { label: "Next Quarter", value: "next_quarter" },
        { label: "Next Year", value: "next_year" },
    ]);
    let [dublicateCheck, setDublicateCheck] = useState(null);
    let [enableCriteria, setEnableCriteria] = useState(isData?.is_enable_criteria || false);
    let [ criteriaFilter, setCriteriaFilter ] = useState({
        criteria : isData?.criteria || [],
        relationships : isData?.relationships || [],
        relationship_pattern : isData?.relationship_pattern || "",
    });
    let [resetCriteria, setResetCriteria] = useState(false);
    let [allDatas, setAllDatas] = useState([]);

    useEffect(() => {
        if (moduleFieldData?.moduleDatas) {
            let data = moduleFieldData?.moduleDatas;
            let numericalData = Utils.getModuleFields(data?.fields?.sections, Utils.numberInputTypes);
            let dateData = Utils.getModuleFields(data?.fields?.sections, Utils.dateInputTypes);
            let allData = checkSingleUser(Utils.getModuleFields(data?.fields?.sections, [...Utils.stringInputTypes, ...Utils.numberInputTypes, ...Utils.dateInputTypes]));
            setAllDatas(allData);
            setNumericalList(numericalData);
            setDateList(dateData);
        }
    }, [moduleFieldData?.moduleDatas]);

    let checkSingleUser = (data) => {
        if (!data?.moduleFields?.length) return [];
        return {
            moduleFields: data?.moduleFields?.filter(x =>
                x?.input_type !== "user" || (x?.input_type === "user" && x?.extra_fields?.user_type === "single_user")
            ) || []
        }
    }

    useEffect(() => {
        if(moduleId) {
            dispatch(getDashboardModuleByIdStart(moduleId));
        }
    }, [moduleId]);

    let onModuleNameChange = (event) => {
        let moduleIdValue = event?.target?.value;
        let data = moduleData?.navbarList?.find(x => x?._id === moduleIdValue);
        setModuleId(moduleIdValue);
        setModuleName(data?.name);
        setField(data?.name ? `Count of - ${data?.name}` : "");
        setResetCriteria(true);
    }

    let onClickSaveData = () => {
        let checkDublicate = allList?.some(x => x?.company_name === companyName && isData?.company_name !== companyName);
        if (checkDublicate) {
            setDublicateCheck(`A ${companyName} with this name already exists.`);
        } else {
            submitData({
                moduleId,
                company_name: companyName,
                module_name: moduleName,
                field,
                field_type : fieldType,
                duration1,
                duration2,
                target,
                modal_name: "DialGaugeChart",
                position: isData ? isData?.position : {
                    x: 0,
                    y: Infinity,
                    w: 3,
                    h: 3,
                    i: Utils.idGenerator(),
                    minW: 3, minH: 3
                },
                ...criteriaFilter,
                is_enable_criteria : enableCriteria
            });
            handleClose();
        }
    }

    let onChageField = (event) => {
        let value = event?.target?.value;
        setField(value);
        numericalList.moduleFields.map((x, index) => {
            let extraFields = x?.fields?.extra_fields;
            if(extraFields?.field_label === value) {
                setFieldType(x?.fields?.input_type);
            }
        })
    }

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Dial Gauge Chart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {moduleFieldData?.isLoadingModule && (
                    <Loader isVisible={true} isModal={true} overall={false} />
                )}
                <nav className="container-fluid d-block">
                    <div className="row">
                        <div className="col-md-6 mt-2">
                            <div className="mb-3 form-floating">
                                <input
                                    type="text"
                                    className={`form-control ${dublicateCheck?.length > 0 ? 'is-invalid' : ''}`}
                                    id="componentName"
                                    placeholder="Enter Component Name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e?.target?.value)}
                                    required
                                />
                                {dublicateCheck?.length > 0 && <span className="text-danger mt-1">{dublicateCheck}</span>}
                                <label htmlFor="componentName">Component Name</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="moduleName" required value={moduleId} onChange={onModuleNameChange}>
                                    <option value="">Select Module</option>
                                    {(moduleData?.navbarList?.length > 0) && (
                                        moduleData?.navbarList?.map((x, index) => (
                                            <option key={index} value={x?._id}>{x?.name}</option>
                                        ))
                                    )}
                                </select>
                                <label htmlFor="moduleName">Module Name</label>
                            </div>
                            <div className="mb-3 form-floating">
                                <select className="form-select" id="field" required value={field} onChange={onChageField} disabled={!moduleName}>
                                    <option value="">Select Field</option>
                                    <option value={`Count of - ${moduleName}`}>Count of - {moduleName}</option>
                                    {numericalList?.moduleFields?.length > 0 && (
                                        numericalList.moduleFields.map((x, index) => {
                                            let fieldLabel = x?.fields?.extra_fields?.field_label;
                                            return (
                                                <React.Fragment key={index}>
                                                    <option value={`Sum of ${fieldLabel}`}>Sum of {fieldLabel}</option>
                                                    <option value={`Minimum of ${fieldLabel}`}>Minimum of {fieldLabel}</option>
                                                    <option value={`Maximum of ${fieldLabel}`}>Maximum of {fieldLabel}</option>
                                                    <option value={`Average of ${fieldLabel}`}>Average of {fieldLabel}</option>
                                                </React.Fragment>
                                            );
                                        })
                                    )}

                                </select>
                                <label htmlFor="field">Count</label>
                            </div>
                            <div className="d-flex gap-3">
                                <div className="col-6 mb-3 form-floating">
                                    <select className="form-select" id="duration1" required value={duration1} onChange={(e) => setDuration1(e?.target?.value)} disabled={!moduleName}>
                                        <option value="">Select Duration</option>
                                        <option value="Created At">Created At</option>
                                        {dateList?.moduleFields?.length > 0 && (
                                            dateList?.moduleFields?.map((x, index) => (
                                                <>
                                                    <option key={index} value={x?.fields?.extra_fields?.field_label}>{x?.fields?.extra_fields?.field_label}</option>
                                                </>
                                            ))
                                    )}
                                    </select>
                                    <label htmlFor="duration1">Count</label>
                                </div>
                                <div className="col-6 mb-3 form-floating">
                                    <select className="form-select" id="duration2" required value={duration2} onChange={(e) => setDuration2(e?.target?.value)} disabled={!moduleName || !duration1}>
                                        <option value="">Select Duration</option>
                                        {durations?.map((x, index) => (
                                            <option key={index} value={x?.value}>{x?.label}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="duration2">Count</label>
                                </div>
                            </div>
                            <div className="mb-3 form-floating">
                                <input
                                    type="number"
                                    className={`form-control`}
                                    id="target"
                                    placeholder="Enter Target"
                                    value={target}
                                    onChange={(e) => setTarget(e?.target?.value)}
                                    required
                                />
                                <label htmlFor="target">Target</label>
                            </div>
                            {(!enableCriteria && moduleName) && (
                                <button type="button" className="btn btn-primary btn-sm me-2" onClick={() => setEnableCriteria(true)}>+ Criteria filter</button>
                            )}
                            {(enableCriteria && moduleName) && (
                                <FillterData title={'Criteria Filter'} onClickRemove={(flg) => setEnableCriteria(flg)} data={allDatas?.moduleFields} onChangeCriteriaFilter={(items) => setCriteriaFilter(items)} selectedDatas={criteriaFilter} resetCriteria={resetCriteria} setResetCriteria={setResetCriteria} />
                            )}
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <div className='rounded p-3 border text-center' style={{ backgroundColor: '#f8f9fa', height: '100%', width: '100%' }}>
                                <div className="container-fluid">
                                    <div className="row g-4">
                                        <div className="col-md-12">
                                            <div className="card ">
                                                <div className="card-body p-0" style={{height : "400px"}}>
                                                    <DialGaugeChart2 isStatic={true}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={!duration2} onClick={onClickSaveData}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DialGaugeChart;
