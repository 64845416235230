import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import "./css/PickList.css";
import { Helpers } from "../../../../Helpers";
import PicklistHistoryTracking from "./PicklistHistoryTracking";
import BulkOptions from "./BulkOptions";
import PredefinedChoices from "./PredefinedChoices";
import { handleFieldLabelUpdate } from '../../../../Utils';

function PickListProperties({ show, handleClose, moduleStoreData, item, editPropertySubmit }) {
  let data = item?.extra_fields;
  let globalSetValues = {
    "Source": [
      "Advertisement",
      "Cold Call",
      "Employee Referral",
      "External Referral",
      "Partner",
      "Public Relations",
      "Trade Show",
      "Seminar Partner",
      "Online Store",
      "Chat",
      "Sales Email Alias",
      "Internal Seminar",
      "Web Download",
      "Web Research",
      "X (Twitter)",
      "Facebook"
    ],
    "Industry" : [
      "ASP (Application Service Provider)",
      "Data/Telecom OEM",
      "ERP (Enterprise Resource Planning)",
      "Government/Military",
      "Large Enterprise",
      "ManagementISV",
      "MSP (Management Service Provider)",
      "Network Equipment Enterprise",
      "Non-management ISV",
      "Optical Networking",
      "Service Provider",
      "Small/Medium Enterprise",
      "Storage Equipment",
      "Storage Service Provider",
      "Systems Integrator",
      "Wireless Industry"
  ]
  }
  let [fieldLabel, setFieldLabel] = useState(data?.field_label);
  let [list, setList] = useState(data?.options);
  let [leadStatus, setLeadStatus] = useState(data?.lead_status || ["Open", "Open"]);
  let [defaultValue, setDefaultValue] = useState(data?.default_value);
  let [orderPreference, setOrderPreference] = useState(data?.order_preference);
  let [enableColorOptions, setEnableColorOptions] = useState(data?.color_options);
  let [required, setRequired] = useState(data?.required);
  let [showTooltip, setShowTooltip] = useState(data?.show_tooltip?.checked);
  let [tooltipText, setTooltipText] = useState(data?.show_tooltip?.message);
  let [radioValue] = useState(data?.show_tooltip?.type);
  let [pickListType, setPickListType] = useState(data?.pick_list_type);
  let [showHistoryModal, setShowHistoryModal] = useState(false);
  let [showBulkOptionsModal, setShowBulkOptionsModal] = useState(false);
  let [showPredefinedChoicesModal, setShowPredefinedChoicesModal] = useState(false);
  let [localErrors, setLocalErrors] = useState([]);
  let [selectedSource, setSelectedSource] = useState(data?.global_select);
  let [globalErrors, setGlobalErrors] = useState(false);
  let [labelError, setLabelError] = useState('');

  const handleFieldLabelChange = (value) => {
    handleFieldLabelUpdate(value, (newLabel) => {
      setFieldLabel(newLabel);
    }, setLabelError); 
  };
  const changeListValue = (event, index, check) => {
    let value = event?.target?.value;
    setList(prevList => {
      let updatedList = [...prevList];
      if (check === "CHANGE") {
        updatedList[index] = value;
      } else if (check === "ADD") {
        updatedList.splice(index + 1, 0, "");
      } else if (check === "REMOVE") {
        if (updatedList.length > 1) {
          if(defaultValue === (updatedList?.find((x, i) => i === index))) {
            setDefaultValue("");
          }
          updatedList = updatedList.filter((_, i) => i !== index);
        }
      }
      return updatedList;
    });
    if (moduleStoreData?.singular_form === "Leads" && data?.field_label === "Lead Status") {
      setLeadStatus(prevList => {
        let updatedList = [...prevList];
        if (check === "ADD") {
          updatedList.splice(index + 1, 0, "Open");
        } else if (check === "REMOVE") {
          if (updatedList.length > 1) {
            updatedList = updatedList.filter((_, i) => i !== index);
          }
        }
        return updatedList;
      });
    }
    setLocalErrors([]);
  };

  const changePickListType = (e) => {
    let { name, value } = e.target;
    setPickListType(value);
    if (name === 'Local') {
      setList(["Option 1", "Option 2"]);
      setOrderPreference("Entered order");
      setSelectedSource("");
    } else {
      setList([]);
      setEnableColorOptions(false);
      setOrderPreference("");
    }
    setDefaultValue("");
  }

  const handleSelectChange = (event) => {
    let value = event.target.value;
    setSelectedSource(value);
    setList(globalSetValues[value]);
    setGlobalErrors(false);
  }

  const validateForm = () => {
    if (pickListType === "Local") {
      let newErrors = [];
      list?.forEach((x, index) => {
        if (!x || x.trim() === "") {
          newErrors[index] = `Option ${index + 1} cannot be empty`;
        }
      });
      setLocalErrors(newErrors);
      return newErrors.length === 0;
    } else {
      setGlobalErrors(!selectedSource);
      return selectedSource?.length > 0;
    }
  };

  const submit = () => {
      if(validateForm() && !labelError) {
      editPropertySubmit({
        field_label :fieldLabel,
        pick_list_type : pickListType,
        global_select : selectedSource,
        options : list,
        ...((moduleStoreData?.singular_form === "Leads" && data?.field_label === "Lead Status") ? { lead_status : leadStatus } :  {}),
        default_value : defaultValue,
        order_preference : orderPreference,
        color_options : enableColorOptions,
        required : required,
        show_tooltip : {
          checked : showTooltip,
          type : radioValue,
          message : tooltipText
        }
      });
      handleClose();
    }
  }

  let changeLeadStatus = (e, index) => {
    let statusData = [...leadStatus]?.map((x, i) => (i === index ? e?.target?.value : x));
    setLeadStatus(statusData);
  }

  return (
    <>
      <Modal show={(show && !showHistoryModal && !showBulkOptionsModal && !showPredefinedChoicesModal)} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
        <Modal.Header closeButton>
          <Modal.Title>Pick List Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="mb-3">
              <label htmlFor="fieldInput" className="form-label">Field Label *</label>
              <input
                type="text"
                className="form-control"
                id="fieldInput"
                placeholder="Enter field label"
                value={fieldLabel} onChange={(e) => handleFieldLabelChange(e.target.value)} />
                {labelError && <div className="text-danger">{labelError}</div>}
            </div>

            <div className="mt-3 mb-3 d-flex align-items-center">
              <label className="form-label me-3" htmlFor="pickListType">Pick List Type</label>
              <div className="d-flex align-items-center">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Local"
                    id="local"
                    value="Local"
                    checked={pickListType === 'Local'}
                    onChange={(e) => changePickListType(e)}
                  />
                  <label className="form-check-label" htmlFor="local">
                    Local
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Global"
                    id="global"
                    value="Global"
                    checked={pickListType === 'Global'}
                    onChange={(e) => changePickListType(e)}
                  />
                  <label className="form-check-label" htmlFor="global">
                    Global
                  </label>
                </div>
              </div>
              {pickListType === 'Global' && (
                  <div className="mb-2">
                    <select id="globalSelect"
                      className={`form-select ${globalErrors ? 'is-invalid' : ''}`}
                      value={selectedSource}
                      onChange={handleSelectChange}>
                      <option value="">Select</option>
                      <option value="Source">Source</option>
                      <option value="Industry">Industry</option>
                    </select>
                  </div>
                )}
            </div>

            {pickListType === 'Local' && (
              <div className="pick-list-option">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5>Pick List Option</h5>
                  <div className="dropdown">
                    <div className="settings-icon" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fas fa-cog"></i>
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      {/* <li><a className="dropdown-item">View/ Add Unused Values</a></li> */}
                      <li><a className="dropdown-item" onClick={() => setShowPredefinedChoicesModal(true)}>Add Predefined Choices</a></li>
                      <li><a className="dropdown-item" onClick={() => setShowBulkOptionsModal(true)}>Add Bulk Options</a></li>
                    </ul>
                  </div>
                </div>
                <div className="pick-list-box bg-light p-3">
                  {Helpers.arrayLength(list) && (
                    list.map((x, index) => (
                      <div key={index} className="form-group mb-3 d-flex align-items-center">
                        <span className="drag-handle fa fa-bars me-2"></span>
                        <input type="text" className={`form-control pl-40 me-2 ${localErrors?.[index] ? 'is-invalid' : ''}`} value={x} onChange={(event) => changeListValue(event, index, "CHANGE")} />
                        {(moduleStoreData?.singular_form === "Leads" && data?.field_label === "Lead Status") && (
                          <select className="form-select me-2" value={leadStatus?.[index]} onChange={(e) => changeLeadStatus(e, index)}>
                            <option value="open">Open</option>
                            <option value="junk">Junk</option>
                            <option value="not_qualified">Not Qualified</option>
                          </select>
                        )}
                        <i className="fa fa-plus me-2 rounded-circle bg-light p-2 border" onClick={(event) => changeListValue(event, index, "ADD")}></i>
                        <i className="fa fa-minus rounded-circle bg-light p-2 border" onClick={(event) => changeListValue(event, index, "REMOVE")}></i>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            {pickListType === 'Global' && (
              <div className="pick-list-option">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5>Pick List Option</h5>
                </div>
                <div className="pick-list-box bg-light p-3">
                  {Helpers.arrayLength(list) ? (
                    list.map((x, index) => (
                      <div key={index} className="mb-3 d-flex align-items-center">
                          {x}
                      </div>
                    ))
                  ) : (
                      <span>Select a Global set to display related options. Note: Matching local and global set values gets auto mapped</span>
                  )}
                </div>
              </div>
            )}

            <>
              <span>Select default value</span>
              <div className="dropdown mb-2">
                <button className="btn bg-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  {defaultValue || 'None'}
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                  <li onClick={() => setDefaultValue("")}><a className="dropdown-item">None</a></li>
                  {list?.length > 0 && (
                    list.map((x, index) => (
                      x && (
                        <li key={index} onClick={() => setDefaultValue(x)}>
                          <a className={`dropdown-item ${defaultValue === x ? 'active' : ''}`}>
                            {x}
                          </a>
                        </li>
                      )
                    ))
                  )}
                </ul>
              </div>
            </>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="historyTracking" onChange={(e) => setShowHistoryModal(e.target.checked)} />
              <label className="form-check-label" htmlFor="historyTracking">
                Enable history tracking for picklist values
              </label>
            </div>

            {pickListType === 'Local' && (
              <>
                <div className="mb-2" id="sortOrderPreference">
                  <label htmlFor="sortOrderPreference" className="form-label">Sort Order Preference</label>
                  <div className="d-flex">
                    <div className="form-check me-3">
                      <input type="radio" className="form-check-input" id="enteredOrder" name="sortOrderPreference" value="Entered order" onChange={(e) => setOrderPreference(e.target.value)} checked={orderPreference === "Entered order"} />
                      <label className="form-check-label" htmlFor="enteredOrder">Entered order</label>
                    </div>
                    <div className="form-check">
                      <input type="radio" className="form-check-input" id="alphabeticalOrder" name="sortOrderPreference" value="Alphabetical order" onChange={(e) => setOrderPreference(e.target.value)} checked={orderPreference === "Alphabetical order"} />
                      <label className="form-check-label" htmlFor="alphabeticalOrder">Alphabetical order</label>
                    </div>
                  </div>
                </div>

                <div className="custom-box bg-light mb-2">
                  {orderPreference == "Entered order" ? (
                    <p className="card-text">The order customized here will be followed in List views, reports and dashboards where sorting is applied for the picklist field.</p>
                  ) : (
                    <p className="card-text">Values will be sorted alphabetically in respective languages.</p>
                  )}
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="enableColorOptions" onChange={(e) => setEnableColorOptions(e.target.checked)} checked={enableColorOptions} />
                  <label className="form-check-label" htmlFor="enableColorOptions">
                    Enable color for picklist options.
                  </label>
                </div>
              </>
            )}

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="required" onChange={(e) => setRequired(e.target.checked)} checked={required} />
              <label className="form-check-label" htmlFor="required">
                Required
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Tooltip" onChange={(e) => setShowTooltip(e.target.checked)} checked={showTooltip} />
              <label className="form-check-label" htmlFor="Tooltip">
                Show Tooltip
              </label>
            </div>

            {showTooltip && (
              <div className="tooltip-container position-relative">
                <div className="float-right">Max of 255 characters</div>
                <div className="mb-3">
                  <textarea className="form-control" rows="3" placeholder="Type tooltip message" value={tooltipText} onChange={(e) => setTooltipText(e.target.value)}></textarea>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="infoIcon" id="infoIcon" checked={radioValue === 'info'} onChange={(e) => e.target.checked} />
                  <label className="form-check-label info-icon" htmlFor="infoIcon">
                    Info Icon
                  </label>
                </div>
              </div>
            )}

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submit}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      { showHistoryModal && <PicklistHistoryTracking moduleStoreData={moduleStoreData} show={showHistoryModal} handleClose={() => setShowHistoryModal(false)}/>}

      { showBulkOptionsModal && <BulkOptions show={showBulkOptionsModal} handleClose={(bulkData) => {
          setShowBulkOptionsModal(false);
          setList((items) => [...items, ...bulkData]);
          if (moduleStoreData?.singular_form === "Leads" && data?.field_label === "Lead Status") {
            setLeadStatus((items) => [...items, ...Array(bulkData?.length).fill("Open")]);
          }
        }}/>
      }

      { showPredefinedChoicesModal && <PredefinedChoices show={showPredefinedChoicesModal} handleClose={(data) => {
          setShowPredefinedChoicesModal(false);
          setList((items) => [...items, ...data]);
          if (moduleStoreData?.singular_form === "Leads" && data?.field_label === "Lead Status") {
            setLeadStatus((items) => [...items, ...Array(data?.length).fill("Open")]);
          }
       }}/> }
    </>
  );
}

export default PickListProperties;
