import { call, put } from "redux-saga/effects";
import { createRole, deleteRole, deleteToTransferRole, getRoles, updateRole } from "../../../../Services/apiService";
import { createRoleFailure, createRoleSuccess, deleteRoleFailure, deleteRoleSuccess, deleteToTransferRoleFailure, deleteToTransferRoleSuccess, getRoleFailure, getRoleSuccess, updateRoleFailure, updateRoleSuccess } from "../../../Slices/roleSlice";

export function* getRolesHandler(actions) {
    try {
        const response = yield call(getRoles, actions?.payload);
        yield put(getRoleSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getRoleFailure(errResponse))
    }
}

export function* createRoleHandler(actions) {
    try {
        const response = yield call(createRole, actions?.payload);
        yield put(createRoleSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createRoleFailure(errResponse))
    }
}

export function* updateRoleHandler(actions) {
    try {
        const response = yield call(updateRole, actions?.payload);
        yield put(updateRoleSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateRoleFailure(errResponse))
    }
}

export function* deleteRoleHandler(actions) {
    try {
        const response = yield call(deleteRole, actions?.payload);
        yield put(deleteRoleSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteRoleFailure(errResponse))
    }
}

export function* deleteToTransferRoleHandler(actions) {
    try {
        const response = yield call(deleteToTransferRole, actions?.payload);
        yield put(deleteToTransferRoleSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteToTransferRoleFailure(errResponse))
    }
}