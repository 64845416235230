import React, { Fragment } from 'react';
import crmLoader from '../../Assets/images/crm-loader.gif';
import { ColorRing, ThreeDots, Triangle, TailSpin, InfinitySpin, RotatingLines } from 'react-loader-spinner';

const Loader = ({
    type = 'crmLoader',
    isVisible = false,
    height = '200',
    width = '200',
    color = '#1d4ed8',
    radius = 1,
    isModal = false,
    overall = true
}) => {

    const loaderClass = isModal ? 'inset-0 position-fixed digy-bg-opacity' : 'inset-0 position-fixed digy-bg-opacity';

    // UI
    return (
        <React.Fragment>
            {
                isVisible && <div className={
                    `${overall ? `${loaderClass} page-loader` : 'modal-loader position-absolute inset-0 bg-body-tertiary '}`
                }>
                    <div className="rounded-full d-flex align-items-center justify-content-center h-100">
                        { 
                            type === 'crmLoader' && 
                            <img src={crmLoader} alt='crm-loader' width={75} height={75} className='img-fluid' />
                        }
                        {
                            type === 'threeDots' && <ThreeDots
                                visible={isVisible}
                                height={height}
                                width={width}
                                color={color}
                            />
                        }
                        {
                            type === 'triangle' && <Triangle
                                visible={isVisible}
                                height={height}
                                width={width}
                                color={color}
                            />
                        }
                        {
                            type === 'tailSpin' && <TailSpin
                                height={height}
                                width={width}
                                color={color}
                                ariaLabel="tail-spin-loading"
                                radius={radius}
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={isVisible}
                            />
                        }
                        {
                            type === 'infinitySpin' && <InfinitySpin
                                visible={true}
                                width={width}
                                color={color}
                                ariaLabel="infinity-spin-loading"
                            />
                        }
                        {
                            type === 'rotatingLines' && <RotatingLines
                                visible={true}
                                height={height}
                                width={width}
                                color={color}
                                strokeWidth="5"
                                strokeColor={color}
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        }
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Loader