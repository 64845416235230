import KpiBasic from "./KpiComponents/Basic";
import KpiGrowthIndex from "./KpiComponents/GrowthIndex";
import KpiRankings from "./KpiComponents/Rankings";
import KpiScorecard from "./KpiComponents/Scorecard";
import KpiStandard from "./KpiComponents/Standard";
import BarChart from "./ChartComponents/BarChart";
import DonutChart from "./ChartComponents/DonutChart";
import FunnelChart from "./ChartComponents/FunnelChart";
import LineChart from "./ChartComponents/LineChart";
import PieChart from "./ChartComponents/PieChart";
import ColumnChart from "./ChartComponents/ColumnChart";
import DialGaugeChart from "./TargetMeterComponents/DialGauge";
import TableAssignment from "./TableComponents/Assignment";

const DashboardComponents = {
    KpiBasic,
    KpiGrowthIndex,
    KpiRankings,
    KpiScorecard,
    KpiStandard,
    BarChart,
    DonutChart,
    FunnelChart,
    LineChart,
    PieChart,
    ColumnChart,
    DialGaugeChart,
    TableAssignment
}

export { DashboardComponents }