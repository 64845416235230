import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCurrencyStart, getCurrencyStart, updateCurrencyStart } from '../../../Store/Slices/currencySlice';
import { formatDate } from '../../../Components/DateFn';
import { getItem } from '../../../Services/localService';
import Loader from '../../../Components/Loader';
import { createSubCurrencyStart, updateSubCurrencyStart } from '../../../Store/Slices/subCurrencySlice';
import '../../../Assets/css/currency.css';
import { Utils} from "../../../Utils";

const Currency = () => {
    const dispatch = useDispatch();
    let profile_picture = "https://campussafetyconference.com/wp-content/uploads/2020/08/iStock-476085198.jpg";
    let currentUser = useSelector((state) => state?.user);
    let currentCurrencyData = useSelector((state) => state?.currency);
    let currentSubCurrencyResponse = useSelector((state) => state?.subcurrency);
    let companyId = useSelector((state) => state?.org)?.companyData?.[0]?._id;
    let currencyList = currentCurrencyData?.allCurrencies
    let currentSubCurrencyData = currentCurrencyData?.data?.[0]?.subCurrencyList;
    let id = getItem('user')?._id;
    if (Array.isArray(currentUser?.userData)) {
        profile_picture = currentUser?.userData.filter((data) => data._id === id)?.[0]?.profilePicture;
    } else {
        profile_picture = currentUser?.userData?.profilePicture
    }

    // pre-def
    let decimalPlace0 = ['1,234,567', '1.234.567', '1 234 567', '12,34,567'];
    let decimalPlace2 = ['1,234,567.89', '1.234.567,89', '1 234 567.89', '1 234 567,89', '12,34,567.89'];
    let decimalPlace3 = ['1,234,567.899', '1.234.567,899', '1 234 567.899', '1 234 567,899', '12,34,567.899'];
    let decimalPlace4 = ['1,234,567.8999', '1.234.567,8999', '1 234 567.8999', '1 234 567,8999', '12,34,567.8999'];
    let decimalPlace5 = ['1,234,567.89999', '1.234.567,89999', '1 234 567.89999', '1 234 567,89999', '12,34,567.89999'];
    let decimalPlace6 = ['1,234,567.899999', '1.234.567, 899999', '1 234 567.899999', '1 234 567,899999', '12,34,567.899999'];

    // states
    const [value, setValue] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [activeType, setActiveType] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [updateSubcurrencyId, setUpdateSubcurrencyId] = useState({
        id: '',
        currency_name: '',
        symbol: '',
        isActive: '',
    });
    const [currencyData, setCurrencyData] = useState({
        currency_name: '',
        currency_code: '',
        code: '',
        symbol: '',
        digit_seperator: '1,234,567.89',
        decimal_place: '2',
        organization: '',
        exchange_rate: ''
    })

    // functions
    const showModal = (type, data) => {
        setModalType(type)
        if (type == 'create-sub-currency') {
            clearState();
            setCurrencyData((prev) => ({
                ...prev,
                decimal_place: '2',
                digit_seperator: '1,234,567.89'
            }));
            setIsModalOpen(true);
        }
        if (type == 'edit-currency') {
            clearState();
            let currentData = currentCurrencyData?.data?.[0]
            setCurrencyData((prev) => ({
                ...prev,
                currency_name: currentData?.currency_name,
                currency_code: currentData?.currency_code,
                symbol: currentData?.symbol,
                code: currentData?.code,
                digit_seperator: currentData?.digit_seperator,
                decimal_place: currentData?.decimal_place,
                organization: companyId,
            }));
            setIsModalOpen(true);
        }
        if (type == 'edit-sub-currency') {
            clearState();
            setCurrencyData((prev) => ({
                ...prev,
                currency_name: data?.currency_name,
                currency_code: data?.currency_code,
                symbol: data?.symbol,
                code: data?.code,
                digit_seperator: data?.digit_seperator,
                decimal_place: data?.decimal_place,
                organization: companyId,
                exchange_rate: data?.exchange_rate
            }));
            setIsModalOpen(true);
        }
    }
    const hideModal = () => setIsModalOpen(false);
    const showAlertModal = () => {
        setIsAlertModalOpen(true)
    }
    const hideAlertModal = () => setIsAlertModalOpen(false);
    const clearState = () => {
        setCurrencyData((prev) => ({
            ...prev,
            currency_name: '',
            currency_code: '',
            symbol: '',
            code: '',
            digit_seperator: '',
            decimal_place: '',
            organization: '',
            exchange_rate: ''
        }));
    }

    // useeffect
    useEffect(() => {
        dispatch(getCurrencyStart());
        setUpdateSubcurrencyId((prev) => ({
            ...prev,
            id: '',
            currency_name: '',
            symbol: '',
            isActive: ''
        }));
    }, []);

    useEffect(() => {
        if (currentCurrencyData.createStatus) {
            dispatch(getCurrencyStart());
        }
        if (currentCurrencyData.updateStatus || currentSubCurrencyResponse.updateStatus) {
            hideModal();
            dispatch(getCurrencyStart());
            setUpdateSubcurrencyId((prev) => ({
                ...prev,
                id: '',
                currency_name: '',
                symbol: '',
                isActive: ''
            }));
        }
    }, [currentCurrencyData.createStatus, currentCurrencyData.updateStatus, currentSubCurrencyResponse.updateStatus]);

    useEffect(() => {
        if (currencyData?.exchange_rate !== '') {
            setValidForm(true)
        } else {
            setValidForm(false);
        }
    }, [currencyData])

    // event handler
    const updateStatus = (e, data) => {
        setUpdateSubcurrencyId((prev) => ({
            ...prev,
            id: data?._id,
            currency_name: data?.currency_name,
            symbol: data?.symbol,
            isActive: e?.target?.checked
        }));
        if (e?.target?.checked == false) {
            setActiveType('deactivate');
        } else {
            setActiveType('activate');
        }
        showAlertModal();
    }

    const formHandler = (e) => {
        try {
            const { name, value } = e?.target;
            setCurrencyData((prev) => ({
                ...prev,
                [name]: value
            }));
            if (name == 'currency_name') {
                const filterData = currencyList.filter((data) => data.name == value)?.[0]
                setCurrencyData((prev) => ({
                    ...prev,
                    currency_code: filterData?.code || '',
                    code: filterData?.code || '',
                    symbol: filterData?.symbol || ''
                }));
            }
            if (name == 'decimal_place') {
                let _value;
                if (value == 0) _value = '1,234,567';
                if (value == 2) _value = '1,234,567.89';
                if (value == 3) _value = '1,234,567.899';
                if (value == 4) _value = '1,234,567.8999';
                if (value == 5) _value = '1,234,567.89999';
                if (value == 6) _value = '1,234,567.899999';
                setCurrencyData((prev) => ({
                    ...prev,
                    digit_seperator: _value
                }));
            }
        } catch (err) {
            throw err
        }
    }

    const handleSave = () => {
        if (modalType == 'create-currency') {
            let payload = currencyData;
            payload['organization'] = companyId;
            const { exchange_rate, ...obj } = payload
            dispatch(createCurrencyStart(obj))
        }
        if (modalType == 'create-sub-currency') {
            let payload = currencyData;
            payload['symbol'] = currencyData?.currency_code;
            payload['organization'] = companyId;
            dispatch(createSubCurrencyStart({ payload: payload, id: currentCurrencyData?.data?.[0]?._id }))
        }
        if (modalType == 'edit-currency') {
            let payload = currencyData;
            const auditLog = Utils.currencyLog(currentCurrencyData?.data, payload, 'Currency');
            dispatch(updateCurrencyStart({ payload: { ...payload, audit_log: auditLog }, id: currentCurrencyData?.data?.[0]?._id }));
        }
        if (modalType == 'edit-sub-currency') {
            let payload = currencyData;
            payload['symbol'] = currencyData?.currency_code;
            payload['organization'] = companyId;
            payload['sub_Cur_Id'] = updateSubcurrencyId.id;
            payload['isActive'] = updateSubcurrencyId.isActive;
            const auditLog =Utils.currencyLog(currentCurrencyData?.data?.[0]?.subCurrencyList, payload, 'SubCurrency');
            dispatch(updateSubCurrencyStart({ payload: { ...payload, audit_log: auditLog }, id: currentCurrencyData?.data?.[0]?._id }));
        }
    }

   const handleActiveDeactive = () => {
     const oldData = currentCurrencyData?.data?.[0];
     const newData = { ...oldData, subCurrencyList: oldData.subCurrencyList.map((subCurrency) => subCurrency._id === updateSubcurrencyId.id ? { ...subCurrency, isActive: activeType === 'activate' ? true : false } : subCurrency) };
     const auditLog = Utils.currencyLog(oldData, newData, 'Multi Currency');
   
        let payload = {
            sub_Cur_Id: updateSubcurrencyId.id,
            isActive: activeType == 'activate' ? true : false
        }
        dispatch(updateSubCurrencyStart({ payload: { ...payload, audit_log: auditLog },  id: currentCurrencyData?.data?.[0]?._id }));
    }

    // ui
    return (
        <React.Fragment>
            <Loader isVisible={currentCurrencyData?.isLoading} />
            <div className='currencies pt-4'>
                <div className='d-flex flex-row justify-content-between'>
                    <h5 className='fw-semibold'>Currencies</h5>
                    <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn">
                        <i className="fa-regular fa-circle-question me-2"></i> Help
                    </button>
                </div>
                <p className='w-75'>
                    This page helps you to define the currency preferences used by your organization.
                    Home currency is the primary transactional currency of your organization.
                    Multiple currencies allow you to handle financial operation across several regions.
                </p>

                <div className='content d-inline-flex bg-light-blue border border-light-blue rounded-3 p-3 my-4'>
                    {currentCurrencyData?.data?.length > 0 ?
                        <>
                            <div className='me-4'>
                                <span>Home Currency</span>
                                <a className='text-decoration-none fw-semibold ps-2'
                                    data-bs-toggle="modal" data-bs-target="#viewCurrencyModal">
                                    {currentCurrencyData?.data?.[0]?.currency_name}-{currentCurrencyData?.data?.[0]?.currency_code}
                                </a>
                            </div>
                            <div className='ms-4'>
                                <span>Currency Format</span>
                                <span className='fw-semibold px-2'>{currentCurrencyData?.data?.[0]?.currency_code} {currentCurrencyData?.data?.[0]?.digit_seperator}</span>
                                <a className='text-decoration-none'
                                    // data-bs-toggle="modal" data-bs-target="#addEditCurModal"
                                    onClick={() => showModal('edit-currency', '')}>
                                    Edit
                                </a>
                            </div>
                        </>
                        :
                        <>
                            <div className='me-4'>
                                <span>Home Currency</span>
                                <a className='text-decoration-none fw-semibold ps-2'
                                    onClick={() => setModalType('create-currency')}
                                    data-bs-toggle="modal" data-bs-target="#setupCurrencyModal">
                                    Setup Currency
                                </a>
                            </div>
                        </>
                    }
                </div>
                {currentCurrencyData?.data?.length > 0 && (
                    <>
                        <div className='d-flex align-items-center justify-content-between py-4'>
                            <div className="d-flex align-items-start">
                                <div className="search-box border rounded-pill">
                                    <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    <input type="search" placeholder="Search Currency" className="searchfield bg-white"
                                        value={value}
                                        onChange={e => setValue(e.target.value)} />
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3"
                                // data-bs-toggle="modal" data-bs-target="#addEditCurModal"
                                onClick={() => showModal('create-sub-currency', '')}
                            >
                                Add Currency
                            </button>
                        </div>
                        <div className="tbl-container rounded-table">
                            <div className="table-responsive">
                                <table className="table table-hover hover-icon-table currency-table mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Currency Name</th>
                                            <th>Format</th>
                                            <th>Exchange Rate</th>
                                            <th>Last Modified By</th>
                                            <th>Status - All Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentSubCurrencyData?.length > 0 ?
                                            <>
                                                {currentSubCurrencyData
                                                    .filter(item => {
                                                        if (!value) return true
                                                        if (item.currency_name.includes(value) || item.symbol.includes(value) ||
                                                            item.digit_seperator.includes(value) || item.exchange_rate.includes(value)) {
                                                            return true
                                                        }
                                                    })
                                                    .map((data) => (
                                                        <tr>
                                                            <td className="text-center">
                                                                <i className="fa-solid fa-pencil hover-icon edit-icon"
                                                                    // data-bs-toggle="modal" data-bs-target="#addEditCurModal"
                                                                    onClick={() => {
                                                                        showModal('edit-sub-currency', data);
                                                                        setUpdateSubcurrencyId((prev) => ({
                                                                            ...prev,
                                                                            id: data?._id,
                                                                            isActive: data?.isActive
                                                                            // currency_name: data?.currency_name,
                                                                            // symbol: data?.symbol
                                                                        }));
                                                                    }}
                                                                ></i>
                                                            </td>
                                                            <td>
                                                                <a onClick={() => setSelectedCurrency(data)} data-bs-toggle="modal" data-bs-target="#viewSelectedRowCurrencyModal">{data?.currency_name}</a>
                                                            </td>
                                                            <td>{data?.symbol + ' ' + data?.digit_seperator}</td>
                                                            <td>{data?.exchange_rate}</td>
                                                            <td>
                                                                <div className='d-inline-flex align-items-center'>
                                                                    <img className='opacity-50' src={profile_picture} width={30} height={30} alt="profile-icon" />
                                                                    <span className='ms-2'>
                                                                        {formatDate(data?.updatedAt)}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {/* <td>₹ 12,34,567.89</td>
                                    <td>100</td>
                                    <td>
                                        <div className='d-inline-flex align-items-center'>
                                            <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" />
                                            <span className='ms-2'>
                                                Oct 14, 2024
                                            </span>
                                        </div>
                                    </td> */}
                                                            <td>
                                                                <div className="form-check form-check-success form-switch" style={{ fontSize: 20 + 'px' }}
                                                                // data-bs-toggle="modal" data-bs-target="#curDeactivateAlertModal"
                                                                >
                                                                    <input className="form-check-input cursor-pointer" type="checkbox" role="switch" id="currencyStatus1"
                                                                        onChange={(e) => updateStatus(e, data)}
                                                                        checked={data?.isActive} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={6} className='text-center'>No Data Found</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}

                {/* Setup Initial Currency Modal */}
                <div className="modal fade"
                    id="setupCurrencyModal" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabIndex="-1" aria-labelledby="setupCurrencyModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable add-edit-cur-modal">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className="modal-title fs-5" id="setupCurrencyModalLabel">
                                    Setup Home Currency
                                </h5>
                            </div>
                            <div className="modal-body pb-0">
                                {/* <form> */}
                                <div className="mb-3 row align-items-center">
                                    <label htmlFor="selectCurrency" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Currency</label>
                                    <div className="col-sm-10 col-md-auto col-xl-8">
                                        <select className="form-select" id="selectCurrency"
                                            name='currency_name'
                                            value={currencyData?.currency_name}
                                            onChange={formHandler}
                                        >
                                            <option>None</option>
                                            {currencyList?.map((item) => (
                                                <option value={item.name}>{item?.name} - {item?.code}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {currencyData?.currency_name &&
                                    <>
                                        <h6 className='sec-header pb-3 pt-2 col-12'>Format</h6>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Format Preview</label>
                                            <div className="col-sm-10 col-md-auto col-xl-8">
                                                <span className='content fw-semibold bg-light-blue border border-light-blue rounded-3 py-1 px-3'>
                                                    <span>{currencyData?.currency_code}</span> {currencyData?.digit_seperator}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label htmlFor="codeSymbol" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Code/Symbol</label>
                                            <div className="col-sm-10 col-md-auto col-xl-8">
                                                <select className="form-select" id="codeSymbol"
                                                    name='currency_code'
                                                    value={currencyData?.currency_code}
                                                    onChange={formHandler}
                                                >
                                                    {currentCurrencyData.allCurrencies.filter((data) => data.name == currencyData?.currency_name).map((data) => (
                                                        <>
                                                            <option value={data?.code}>{data?.code}</option>
                                                            <option value={data?.symbol}>{data?.symbol}</option>
                                                        </>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label htmlFor="digitSeparators" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Digit Separators</label>
                                            <div className="col-sm-10 col-md-auto col-xl-8">
                                                <select className="form-select" id="codeSymbol"
                                                    name='digit_seperator'
                                                    value={currencyData?.digit_seperator}
                                                    onChange={formHandler}
                                                >
                                                    {currencyData?.decimal_place == 0 && decimalPlace0.map((data) => (
                                                        <option value={data}>{data}</option>
                                                    ))}
                                                    {currencyData?.decimal_place == 2 && decimalPlace2.map((data) => (
                                                        <option value={data}>{data}</option>
                                                    ))}
                                                    {currencyData?.decimal_place == 3 && decimalPlace3.map((data) => (
                                                        <option value={data}>{data}</option>
                                                    ))}
                                                    {currencyData?.decimal_place == 4 && decimalPlace4.map((data) => (
                                                        <option value={data}>{data}</option>
                                                    ))}
                                                    {currencyData?.decimal_place == 5 && decimalPlace5.map((data) => (
                                                        <option value={data}>{data}</option>
                                                    ))}
                                                    {currencyData?.decimal_place == 6 && decimalPlace6.map((data) => (
                                                        <option value={data}>{data}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label htmlFor="decimalPlaces" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Decimal Places</label>
                                            <div className="col-sm-10 col-md-auto col-xl-8">
                                                <select className="form-select" id="decimalPlaces"
                                                    name='decimal_place'
                                                    value={currencyData?.decimal_place}
                                                    onChange={formHandler}
                                                >
                                                    <option value="0">0</option>
                                                    {
                                                        new Array(5).fill().map((d, i) => ++i &&
                                                            <option value={i + 1}>{i + 1}</option>
                                                        )
                                                    }
                                                    {/* <option selected value="1">0</option>
                                            <option value="2">2</option>
                                            <option value="2">3</option>
                                            <option value="2">4</option>
                                            <option value="2">5</option>
                                            <option value="2">6</option> */}
                                                </select>
                                            </div>
                                        </div>
                                        {modalType !== 'edit-currency' && modalType !== 'create-currency' &&
                                            <div className="mb-3 row align-items-center">
                                                <label htmlFor="exchangeRate" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">
                                                    Exchange Rate <span className='fw-semibold text-dark'>1 {currentCurrencyData?.data?.[0]?.currency_code}</span>
                                                </label>
                                                <div className="col-sm-10 col-md-auto col-xl-8">
                                                    <div className="input-group mt-0">
                                                        <span className="input-group-text fs-14" id="exchangeRate">{currencyData?.currency_code}</span>
                                                        <input type="text" className="form-control w-auto" aria-label="exchangeRate" aria-describedby="exchangeRate"
                                                            name='exchange_rate'
                                                            value={currencyData?.exchange_rate}
                                                            onChange={formHandler} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {/* </form> */}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={hideModal} data-bs-dismiss="modal">Cancel</button>
                                {/* {modalType === 'create-sub-currency' &&
                                    <button type="button" className="btn btn-primary rounded-pill btn-blue" disabled>Save and New</button>
                                } */}
                                {/* {modalType == 'edit-currency' ? */}
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { handleSave(); hideModal() }} data-bs-dismiss="modal">Save</button>
                                {/* :
                                    <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSave()} disabled={!validForm}>Save</button>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Setup Initial Currency modal // */}

                {/* Add & Edit Currency Modal */}
                {isModalOpen && (
                    <div className="modal modal-backdrop fade show d-block"
                        id="addEditCurModal" data-bs-backdrop="static" data-bs-keyboard="false"
                        tabIndex="-1" aria-labelledby="addEditCurModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable add-edit-cur-modal">
                            <div className="modal-content">
                                <div className='modal-header'>
                                    {modalType == 'edit-currency' &&
                                        < h5 className="modal-title fs-5" id="addEditCurModalLabel">
                                            {/* Edit Curreny state use this below back arrow */}
                                            {/* <i className='fa-solid fa-arrow-left cursor-pointer me-2'
                                                data-bs-toggle="modal" data-bs-target="#viewCurrencyModal"></i> */}
                                            Edit {currentCurrencyData?.data?.[0]?.currency_name} - {currentCurrencyData?.data?.[0]?.currency_code}
                                            <span className='badge rounded-pill text-bg-warning text-white ms-2'>Home Currency</span>
                                        </h5>
                                    }
                                    {modalType == 'create-sub-currency' &&
                                        <h5 className="modal-title fs-5" id="addEditCurModalLabel">
                                            Add Currency
                                        </h5>
                                    }
                                    {modalType == 'edit-sub-currency' &&
                                        <h5 className="modal-title fs-5" id="addEditCurModalLabel">
                                            Edit {currencyData?.currency_name}-{currencyData?.symbol}
                                        </h5>
                                    }
                                </div>
                                <div className="modal-body pb-0">
                                    {/* <form> */}
                                    {modalType === 'create-sub-currency' &&
                                        <div className="mb-3 row align-items-center">
                                            <label htmlFor="selectCurrency" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Currency</label>
                                            <div className="col-sm-10 col-md-auto col-xl-8">
                                                <select className="form-select" id="selectCurrency"
                                                    name='currency_name'
                                                    value={currencyData?.currency_name}
                                                    onChange={formHandler}
                                                >
                                                    <option>None</option>
                                                    {currentSubCurrencyData.length == 0 ?
                                                        <>
                                                            {currencyList?.filter(x => { return x.name !== currentCurrencyData?.data?.[0]?.currency_name })?.map((item) => (
                                                                <option value={item.name}>{item?.name} - {item?.code}</option>
                                                            ))}
                                                        </>
                                                        :
                                                        <>
                                                            {currencyList.filter(x => {
                                                                return !currentSubCurrencyData.some(t => t.currency_name === x.name || x.name === currentCurrencyData?.data?.[0]?.currency_name);
                                                            })?.map((item) => (
                                                                <option value={item.name}>{item?.name} - {item?.code}</option>
                                                            ))}
                                                        </>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {currencyData?.currency_name &&
                                        <>
                                            <h6 className='sec-header pb-3 pt-2 col-12'>Format</h6>
                                            <div className="mb-3 row align-items-center">
                                                <label className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Format Preview</label>
                                                <div className="col-sm-10 col-md-auto col-xl-8">
                                                    <span className='content fw-semibold bg-light-blue border border-light-blue rounded-3 py-1 px-3'>
                                                        <span>{currencyData?.currency_code}</span> {currencyData?.digit_seperator}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mb-3 row align-items-center">
                                                <label htmlFor="codeSymbol" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Code/Symbol</label>
                                                <div className="col-sm-10 col-md-auto col-xl-8">
                                                    <select className="form-select" id="codeSymbol"
                                                        name='currency_code'
                                                        value={currencyData?.currency_code}
                                                        onChange={formHandler}
                                                    >
                                                        {currentCurrencyData.allCurrencies.filter((data) => data.name == currencyData?.currency_name).map((data) => (
                                                            <>
                                                                <option value={data?.code}>{data?.code}</option>
                                                                <option value={data?.symbol}>{data?.symbol}</option>
                                                            </>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-3 row align-items-center">
                                                <label htmlFor="digitSeparators" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Digit Separators</label>
                                                <div className="col-sm-10 col-md-auto col-xl-8">
                                                    <select className="form-select" id="codeSymbol"
                                                        name='digit_seperator'
                                                        value={currencyData?.digit_seperator}
                                                        onChange={formHandler}
                                                    >
                                                        {currencyData?.decimal_place == 0 && decimalPlace0.map((data) => (
                                                            <option value={data}>{data}</option>
                                                        ))}
                                                        {currencyData?.decimal_place == 2 && decimalPlace2.map((data) => (
                                                            <option value={data}>{data}</option>
                                                        ))}
                                                        {currencyData?.decimal_place == 3 && decimalPlace3.map((data) => (
                                                            <option value={data}>{data}</option>
                                                        ))}
                                                        {currencyData?.decimal_place == 4 && decimalPlace4.map((data) => (
                                                            <option value={data}>{data}</option>
                                                        ))}
                                                        {currencyData?.decimal_place == 5 && decimalPlace5.map((data) => (
                                                            <option value={data}>{data}</option>
                                                        ))}
                                                        {currencyData?.decimal_place == 6 && decimalPlace6.map((data) => (
                                                            <option value={data}>{data}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-3 row align-items-center">
                                                <label htmlFor="decimalPlaces" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">Decimal Places</label>
                                                <div className="col-sm-10 col-md-auto col-xl-8">
                                                    <select className="form-select" id="decimalPlaces"
                                                        name='decimal_place'
                                                        value={currencyData?.decimal_place}
                                                        onChange={formHandler}
                                                    >
                                                        <option value="0">0</option>
                                                        {
                                                            new Array(5).fill().map((d, i) => ++i &&
                                                                <option value={i + 1}>{i + 1}</option>
                                                            )
                                                        }
                                                        {/* <option selected value="1">0</option>
                                            <option value="2">2</option>
                                            <option value="2">3</option>
                                            <option value="2">4</option>
                                            <option value="2">5</option>
                                            <option value="2">6</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            {(modalType !== 'edit-currency' && modalType !== 'create-currency') &&
                                                <div className="mb-3 row align-items-center">
                                                    <label htmlFor="exchangeRate" className="col-sm-2 col-md-auto col-xl-4 text-md-end col-form-label min-w-140px">
                                                        Exchange Rate <span className='fw-semibold text-dark'>1 {currentCurrencyData?.data?.[0]?.currency_code}</span>
                                                    </label>
                                                    <div className="col-sm-10 col-md-auto col-xl-8">
                                                        <div className="input-group mt-0">
                                                            <span className="input-group-text fs-14" id="exchangeRate">{currencyData?.currency_code}</span>
                                                            <input type="text" className="form-control w-auto" aria-label="exchangeRate" aria-describedby="exchangeRate"
                                                                name='exchange_rate'
                                                                value={currencyData?.exchange_rate}
                                                                onChange={formHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    {/* </form> */}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal" onClick={hideModal}>Cancel</button>
                                    {/* {modalType === 'create-sub-currency' &&
                                        <button type="button" className="btn btn-primary rounded-pill btn-blue" disabled>Save and New</button>
                                    } */}
                                    {modalType == 'edit-currency' ?
                                        <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { handleSave(); hideModal() }}>Save</button>
                                        :
                                        <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { handleSave(); hideModal() }} disabled={!validForm}>Save</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
                {/* // Add & Edit Currency modal // */}

                {/* View Currency Modal */}
                <div className="modal fade" id="viewCurrencyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="viewCurrencyLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable view-cur-modal">
                        <div className="modal-content">
                            <div className='modal-header border-bottom-0 pb-0'>
                                <h5 className="modal-title d-flex align-items-center" id="viewCurrencyLabel">
                                    {currentCurrencyData?.data?.[0]?.currency_name} - {currentCurrencyData?.data?.[0]?.currency_code}
                                    <span className='badge rounded-pill text-bg-warning text-white ms-2'>Home Currency</span>
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-0">
                                <ul className="nav nav-pills border-bottom" id="currenncy-tab" role="tablist">
                                    <li className="nav-item px-3" role="presentation">
                                        <button className="nav-link text-primary position-relative px-0 active" id="currency-tab-1"
                                            data-bs-toggle="pill" data-bs-target="#currency-tab1" type="button" role="tab"
                                            aria-controls="currency-tab1" aria-selected="true">Info</button>
                                    </li>
                                    <li className="nav-item px-3" role="presentation">
                                        <button className="nav-link text-primary position-relative px-0" id="currency-tab-2"
                                            data-bs-toggle="pill" data-bs-target="#currency-tab2" type="button" role="tab"
                                            aria-controls="currency-tab2" aria-selected="false">Logs</button>
                                    </li>
                                </ul>
                                <div className="tab-content px-3" id="currency-tabContent">
                                    <div className="tab-pane fade show active" id="currency-tab1" role="tabpanel" aria-labelledby="currency-tab-1">
                                        <div className='d-flex align-items-center justify-content-between py-4'>
                                            <h6 className='mb-0'>Format</h6>
                                            <button type='button' className='btn btn-light rounded-pill py-1 px-3 flat-btn'
                                                // data-bs-toggle="modal" data-bs-target="#addEditCurModal"
                                                data-bs-dismiss="modal"
                                                onClick={() => showModal('edit-currency', '')}>
                                                Edit
                                            </button>
                                        </div>

                                        <div className='px-3 readonly-form'>
                                            <dl className="row g-3 justify-content-start list-detail">
                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Currency Format</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">
                                                    <span className='content fw-semibold bg-light-blue border border-light-blue rounded-3 py-1 px-3'>
                                                        <span>{currentCurrencyData?.data?.[0]?.currency_code}</span> {currentCurrencyData?.data?.[0]?.digit_seperator}
                                                    </span>
                                                </dt>

                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Code/Symbo</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">{currentCurrencyData?.data?.[0]?.currency_code}</dt>

                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Digit Separators</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">{currentCurrencyData?.data?.[0]?.digit_seperator}</dt>

                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Decimal Places</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">{currentCurrencyData?.data?.[0]?.decimal_place}</dt>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="currency-tab2" role="tabpanel" aria-labelledby="currency-tab-2">
                                        <div className='pt-xl-4 pt-3'>
                                            <div className='tree-cv'>
                                                <div className='date-box min-w-120px'>
                                                    <span className='text-center fw-semibold bg-light-blue border border-light-blue rounded-3 py-1 px-3'>
                                                        Sep 25, 2024
                                                    </span>
                                                </div>
                                                <div className='content my-3'>
                                                    <div className='time-box'>
                                                        10:28 PM
                                                    </div>
                                                    <div className='confirm-text'>
                                                        <span>
                                                            <span className='fw-semibold cur-symbol pe-2'>US Dollar - USD</span>
                                                            was confirmed as home currency with format
                                                            <span className='fw-semibold cur ps-2'>$ 1,234,567.89</span>
                                                        </span>
                                                        <div className='text-muted small pt-2'>by Info.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* // View Currency Modal // */}

                {/* View Selected Row Currency Modal */}
                <div className="modal fade" id="viewSelectedRowCurrencyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="viewCurrencyLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable view-cur-modal">
                        <div className="modal-content">
                            <div className='modal-header border-bottom-0 pb-0'>
                                <h5 className="modal-title d-flex align-items-center" id="viewCurrencyLabel">
                                    {selectedCurrency?.currency_name} - {selectedCurrency?.symbol}
                                    {/* <span className='badge rounded-pill text-bg-warning text-white ms-2'>Home Currency</span> */}
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-0">
                                <ul className="nav nav-pills border-bottom" id="currenncy-tab" role="tablist">
                                    <li className="nav-item px-3" role="presentation">
                                        <button className="nav-link text-primary position-relative px-0 active" id="currency-tab-1"
                                            data-bs-toggle="pill" data-bs-target="#currency-tab1" type="button" role="tab"
                                            aria-controls="currency-tab1" aria-selected="true">Info</button>
                                    </li>
                                    <li className="nav-item px-3" role="presentation">
                                        <button className="nav-link text-primary position-relative px-0" id="currency-tab-2"
                                            data-bs-toggle="pill" data-bs-target="#currency-tab2" type="button" role="tab"
                                            aria-controls="currency-tab2" aria-selected="false">Logs</button>
                                    </li>
                                </ul>
                                <div className="tab-content px-3" id="currency-tabContent">
                                    <div className="tab-pane fade show active" id="currency-tab1" role="tabpanel" aria-labelledby="currency-tab-1">
                                        <div className='d-flex align-items-center justify-content-between py-4'>
                                            <h6 className='mb-0'>Format</h6>
                                            {/* <button type='button' className='btn btn-light rounded-pill py-1 px-3 flat-btn'
                                                // data-bs-toggle="modal" data-bs-target="#addEditCurModal"
                                                data-bs-dismiss="modal"
                                                onClick={() => showModal('edit-currency', '')}>
                                                Edit
                                            </button> */}
                                        </div>

                                        <div className='px-3 readonly-form'>
                                            <dl className="row g-3 justify-content-start list-detail">
                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Currency Format</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">
                                                    <span className='content fw-semibold bg-light-blue border border-light-blue rounded-3 py-1 px-3'>
                                                        <span>{selectedCurrency?.symbol}</span> {selectedCurrency?.digit_seperator}
                                                    </span>
                                                </dt>

                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Code/Symbo</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">{selectedCurrency?.symbol}</dt>

                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Digit Separators</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">{selectedCurrency?.digit_seperator}</dt>

                                                <dd className="col-sm-4 col-lg-3 text-dark text-opacity-75">Decimal Places</dd>
                                                <dt className="col-sm-8 col-lg-9 fw-normal">{selectedCurrency?.decimal_place}</dt>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="currency-tab2" role="tabpanel" aria-labelledby="currency-tab-2">
                                        <div className='pt-xl-4 pt-3'>
                                            <div className='tree-cv'>
                                                <div className='date-box min-w-120px'>
                                                    <span className='text-center fw-semibold bg-light-blue border border-light-blue rounded-3 py-1 px-3'>
                                                        Sep 25, 2024
                                                    </span>
                                                </div>
                                                <div className='content my-3'>
                                                    <div className='time-box'>
                                                        10:28 PM
                                                    </div>
                                                    <div className='confirm-text'>
                                                        <span>
                                                            <span className='fw-semibold cur-symbol pe-2'>US Dollar - USD</span>
                                                            was confirmed as home currency with format
                                                            <span className='fw-semibold cur ps-2'>$ 1,234,567.89</span>
                                                        </span>
                                                        <div className='text-muted small pt-2'>by Info.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* // View Selected Row Currency Modal // */}

                {/* Currency Deactivate Alert Modal */}
                {isAlertModalOpen && (
                    <div className="modal modal-backdrop- fade show d-block" id="curDeactivateAlertModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="curDeactivateAlertLabel" aria-hidden="true">
                        <div className="modal-dialog alert-modal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <h4 className='pb-3'>{activeType == 'activate' ? 'Activate' : 'Deactivate'} - <span className='fs-4'>{updateSubcurrencyId?.currency_name}-{updateSubcurrencyId?.symbol}</span></h4>
                                    {activeType == 'activate' ?
                                        <>
                                            <p className='fw-semibold'>
                                                Are you sure you want to activate this currency?
                                            </p></>
                                        :
                                        <>
                                            <h6 className='mb-3 fs-14 fw-semibold'>When you deactivate a currency</h6>
                                            <ul className='ps-3 mb-3'>
                                                <li className='pb-3'>It will not be available when you create a record.</li>
                                                <li className='pb-3'>Any user's preferred currency will be replaced with the home currency.</li>
                                                <li className='pb-3'>Any create record action associated with it will be replaced with the home currency.</li>
                                            </ul>
                                            <p className='fw-semibold'>
                                                Are you sure you want to deactivate this currency?
                                            </p>
                                        </>
                                    }
                                </div>
                                <div className="modal-footer border-top-0">
                                    <button type="button" className="btn btn-outline-secondary rounded-pill flat-btn text-secondary" data-bs-dismiss="modal" onClick={hideAlertModal}>Cancel</button>
                                    <button type="button" className="btn btn-danger rounded-pill btn-blue" onClick={() => { handleActiveDeactive(); hideAlertModal(); }}>
                                        {activeType == 'activate' ? 'Yes, Activate' : 'Yes, Deactivate'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* // Currency Deactivate Alert Modal // */}
            </div >
        </React.Fragment >
    )
}
export default Currency;