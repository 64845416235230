import React, { useEffect,  useState } from 'react'; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function AutoNumberPropertiesModal({ show, handleClose, item, editPropertySubmit }) {
 let fields = item?.extra_fields;
  const [fieldLabel, setFieldLabel] = React.useState(fields?.field_label);
  const [prefix, setPrefix] = React.useState(fields?.prefix);
  const [startingNumber, setStartingNumber] = React.useState(fields?.startingNumber);
  const [suffix, setSuffix] = React.useState(fields?.suffix);
  const [showTooltip, setShowTooltip] = useState({ checked: fields?.show_tooltip?.checked, type: fields?.show_tooltip?.type, message: fields?.show_tooltip?.message });
  const [showPreview, setShowPreview] = useState(false);
  const [labelError, setLabelError] = React.useState(''); 
  const [previewValue, setPreviewValue] = useState('');

  useEffect(() => {
    updatePreviewValue();
  }, [prefix, startingNumber, suffix]);

  const handleSubmit = () => {
    let formData = {
      field_label: fieldLabel,
      prefix: prefix,
      startingNumber: startingNumber,
      suffix: suffix,
      show_tooltip: showTooltip,
      preview: previewValue
    }
  
    editPropertySubmit(formData);
    handleClose();
  };

  const handleCancel = () => {
    setFieldLabel('Auto-Number');
    setPrefix('');
    setStartingNumber('');
    setSuffix('');
    setShowTooltip({ checked: false, type: 'static', message: '' });
    handleClose();
    setPreviewValue('');
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handlePrefixChange = (value) => {
    setPrefix(value);
    updatePreviewValue();
  };

  const handleStartingNumberChange = (value) => {
    setStartingNumber(value);
    updatePreviewValue();
  };

  const handleSuffixChange = (value) => {
    setSuffix(value);
    updatePreviewValue();
  };

  const updatePreviewValue = () => {
    let preview = `${prefix || ""}${startingNumber || ""}${suffix || ""}`;
    setPreviewValue(preview);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Auto-Number Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Field Label</Form.Label>
            <Form.Control type="text" value={fieldLabel}/>
            {labelError && <div className="text-danger">{labelError}</div>} 
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Auto-Number Format</Form.Label>

             </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Prefix</Form.Label>
            <Form.Control type="text" value={prefix} onChange={(e) => handlePrefixChange(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Starting Number</Form.Label>
            <Form.Control type="number" value={startingNumber} onChange={(e) => handleStartingNumberChange(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Suffix</Form.Label>
            <Form.Control type="text" value={suffix} onChange={(e) => handleSuffixChange(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Preview</Form.Label>
            <Form.Control type="text" value={previewValue} readOnly />
          </Form.Group>

          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip.checked}
              onChange={() => setShowTooltip({ ...showTooltip, checked: !showTooltip.checked })}
            />
          </Form.Group>

          {showTooltip.checked && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Message (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={showTooltip.message}
                  onChange={(e) => setShowTooltip({ ...showTooltip, message: e.target.value })}
                  placeholder="Enter Tooltip message"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={showTooltip.type === 'info'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'info' })}
                />
              
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showTooltip.type === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {showTooltip.message && <span className="ms-2">{showTooltip.message}</span>}
                    </div>
                  ) : (
                    <p>{showTooltip.message}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AutoNumberPropertiesModal;
