import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isLoading: false,
    error: null,
    history: null,
    userLoginHistory: null
};
const loginHistorySlice = createSlice({
    name: 'loginhistory',
    initialState,
    reducers: {
        getLoginHistoryStart: (state) => {
            state.isLoading = true;
            state.error = null;
            state.history = null;
        },
        getLoginHistorySuccess: (state, action) => {
            state.isLoading = false;
            state.history = action.payload.users_history;
        },
        getLoginHistoryFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        getUserLoginHistoryStart: (state) => {
            state.isLoading = true;
            state.error = null;
            state.userLoginHistory = null;
        },
        getUserLoginHistorySuccess: (state, action) => {
            state.isLoading = false;
            state.userLoginHistory = action.payload.users_login_history;
        },
        getUserLoginHistoryFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getLoginHistoryStart,
    getLoginHistorySuccess,
    getLoginHistoryFailure,
    getUserLoginHistoryStart,
    getUserLoginHistorySuccess,
    getUserLoginHistoryFailure,
} = loginHistorySlice.actions;

export default loginHistorySlice.reducer;
