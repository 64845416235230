import { createSlice } from "@reduxjs/toolkit";

const DashboardComponentsSlice = createSlice({
    name: 'dashboardComponents',
    initialState: {
        subModuleData: {},
        components : [],
        layouts : [],
        moduleDatas: {},
        isLoading: false,
        isDashboardLoading: false,
        isLoadingModule: false,
        reloadGetAllModuleApi : false,
        reloadGetAllModuleLayoutApi : false,
        componentCreationSuccess : false,
        layoutCreationSuccess : false,
        layoutUpdateSuccess : false,
        layoutDataById : null,
        moduleId : 0,
        error: null,
        message: null,
        status : false
    },
    reducers: {
        getDashboardModuleByIdStart: (state) => {
            state.isLoadingModule = true;
        },
        getDashboardModuleByIdSuccess: (state, action) => {
            state.moduleDatas = action?.payload?.modules?.[0] || {};
            state.message = action?.payload?.message;
            state.isLoadingModule = false;
            state.status = action?.payload?.status;
            state.reload = false;
            state.exit = false;
        },
        getDashboardModuleByIdFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoadingModule = false;
        },
        getAllSubFormModulesStart: (state, action) => {
            state.moduleId = action?.payload;
            state.isDashboardLoading = true;
        },
        getAllSubFormModulesSuccess: (state, action) => {
            let data = action?.payload?.formsubmodules;
            if (data?.length > 0) {
                state.subModuleData = {
                    ...state.subModuleData,
                    [data?.[0].moduleId]: data
                };
            } else {
                state.subModuleData = {
                    ...state.subModuleData,
                    [state.moduleId]: []
                };
            }
            state.isDashboardLoading = false;
            state.error = null;
            state.status = action.payload.status;
        },        
        getAllSubFormModulesFailure: (state, action) => {
            state.isDashboardLoading = false;
        },
        createDashboardComponentStart: (state) => {
            state.isLoading = true;
        },
        createDashboardComponentSuccess: (state, action) => {
            state.components = [...state.components, action.payload.savedDashboard];
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
            state.componentCreationSuccess = true;
        },        
        createDashboardComponentFailure: (state, action) => {
            if (action?.payload?.length > 0) {
                let match = action?.payload?.match(/dup key: { dashboard_name: "(.*)" }/);
                let duplicateName = match ? match[1] : "Unknown";
                state.error = `A ${duplicateName} with this name already exists.`;
            } else {
                state.error = "Api error";
            }
            state.status = false;
            state.isLoading = false;
        },
        updateDashboardComponentStart: (state) => {
            state.isLoading = true;
        },
        updateDashboardComponentSuccess: (state, action) => {
            let updateData = action.payload.updateDashboard;
            state.components = state.components?.map(x => (x?._id === updateData?._id ? updateData : x));
            state.isLoading = false;
            state.error = null;
            state.status = false;
        },        
        updateDashboardComponentFailure: (state, action) => {
            if (action?.payload?.length > 0) {
                let match = action?.payload?.match(/dup key: { dashboard_name: "(.*)" }/);
                let duplicateName = match ? match[1] : "Unknown";
                state.error = `A ${duplicateName} with this name already exists.`;
            } else {
                state.error = "Api error";
            }
            state.status = false;
            state.isLoading = false;
        },
        getAllDashboardComponentStart: (state) => {
            state.isLoading = true;
        },
        getAllDashboardComponentSuccess: (state, action) => {
            state.components = action.payload.dashboard;
            state.reloadGetAllModuleApi = false;
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
        },        
        getAllDashboardComponentFailure: (state, action) => {
            state.isLoading = false;
        },
        getByIdDashboardComponentStart: (state) => {
            state.isLoading = true;
        },
        getByIdDashboardComponentSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
        },        
        getByIdDashboardComponentFailure: (state, action) => {
            state.isLoading = false;
        },
        deleteDashboardComponentStart: (state) => {
            state.isLoading = true;
        },
        deleteDashboardComponentSuccess: (state, action) => {
            state.reloadGetAllModuleApi = true;
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
        },        
        deleteDashboardComponentFailure: (state, action) => {
            state.isLoading = false;
        },
        createDashboardComponentLayoutStart: (state) => {
            state.isLoading = true;
        },
        createDashboardComponentLayoutSuccess: (state, action) => {
            state.layouts = [...state.layouts, action.payload.savedDashboardLayouts];
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
            state.layoutCreationSuccess = true;
        },        
        createDashboardComponentLayoutFailure: (state, action) => {
            if (action?.payload?.length > 0) {
                let match = action?.payload?.match(/dup key: { layout_name: "(.*)" }/);
                let duplicateName = match ? match[1] : "Unknown";
                state.error = `A ${duplicateName} with this name already exists.`;
            } else {
                state.error = "Api error";
            }
            state.status = false;
            state.isLoading = false;
            state.layoutCreationSuccess = false;
        },
        updateDashboardComponentLayoutStart: (state) => {
            state.isLoading = true;
        },
        updateDashboardComponentLayoutSuccess: (state, action) => {
            let updateData = action.payload.updateDashboardLayouts;
            state.layouts = state.layouts?.map(x => (x?._id === updateData?._id ? updateData : x));
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
            state.layoutUpdateSuccess = true;
        },        
        updateDashboardComponentLayoutFailure: (state, action) => {
            if (action?.payload?.length > 0) {
                let match = action?.payload?.match(/dup key: { layout_name: "(.*)" }/);
                let duplicateName = match ? match[1] : "Unknown";
                state.error = `A ${duplicateName} with this name already exists.`;
            } else {
                state.error = "Api error";
            }
            state.status = false;
            state.layoutUpdateSuccess = false;
            state.isLoading = false;
        },
        getAllDashboardComponentLayoutStart: (state) => {
            state.isLoading = true;
        },
        getAllDashboardComponentLayoutSuccess: (state, action) => {
            state.reloadGetAllModuleLayoutApi = false;
            state.layouts = action.payload.dashboard_layouts;
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
        },        
        getAllDashboardComponentLayoutFailure: (state, action) => {
            state.isLoading = false;
        },
        getByIdDashboardComponentLayoutStart: (state) => {
            state.isLoading = true;
        },
        getByIdDashboardComponentLayoutSuccess: (state, action) => {
            state.layoutDataById = action.payload.dashboard_layouts?.[0];
            state.isLoading = false;
            state.error = null;
        },        
        getByIdDashboardComponentLayoutFailure: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.status = false;
        },
        deleteDashboardComponentLayoutStart: (state) => {
            state.isLoading = true;
        },
        deleteDashboardComponentLayoutSuccess: (state, action) => {
            state.reloadGetAllModuleLayoutApi = true;
            state.isLoading = false;
            state.error = null;
            state.status = action.payload.status;
        },        
        deleteDashboardComponentLayoutFailure: (state, action) => {
            state.isLoading = false;
        },
        reState: (state, action) => {
            state.componentCreationSuccess = false;
            state.layoutCreationSuccess = false;
            state.layoutUpdateSuccess = false;
            state.subModuleData = {};
            state.layouts = [];
        },
        destoryData: (state) => {
            state.moduleDatas = {};
        },
        destorylayoutDataById: (state) => {
            state.layoutDataById = null;
        }
    }
});

export const {
    getDashboardModuleByIdStart,
    getDashboardModuleByIdSuccess,
    getDashboardModuleByIdFailure,
    getAllSubFormModulesStart,
    getAllSubFormModulesSuccess,
    getAllSubFormModulesFailure,
    createDashboardComponentStart,
    createDashboardComponentSuccess,
    createDashboardComponentFailure,
    updateDashboardComponentStart,
    updateDashboardComponentSuccess,
    updateDashboardComponentFailure,
    getAllDashboardComponentStart,
    getAllDashboardComponentSuccess,
    getAllDashboardComponentFailure,
    getByIdDashboardComponentStart,
    getByIdDashboardComponentSuccess,
    getByIdDashboardComponentFailure,
    deleteDashboardComponentStart,
    deleteDashboardComponentSuccess,
    deleteDashboardComponentFailure,
    createDashboardComponentLayoutStart,
    createDashboardComponentLayoutSuccess,
    createDashboardComponentLayoutFailure,
    updateDashboardComponentLayoutStart,
    updateDashboardComponentLayoutSuccess,
    updateDashboardComponentLayoutFailure,
    getAllDashboardComponentLayoutStart,
    getAllDashboardComponentLayoutSuccess,
    getAllDashboardComponentLayoutFailure,
    getByIdDashboardComponentLayoutStart,
    getByIdDashboardComponentLayoutSuccess,
    getByIdDashboardComponentLayoutFailure,
    deleteDashboardComponentLayoutStart,
    deleteDashboardComponentLayoutSuccess,
    deleteDashboardComponentLayoutFailure,
    reState,
    destoryData,
    destorylayoutDataById
} = DashboardComponentsSlice?.actions;

export default DashboardComponentsSlice?.reducer;