import React from "react";
import { useNavigate } from "react-router-dom";
import { Utils } from "../../Utils";

const IntegrationAPI = () => {
    let navigate = useNavigate();
    
    return (
        // <div className="main-panel">
            <main className="py-md-4 content-section">
                <div className="container-fluid">
                    <div className="integration-header text-center">
                        <h1>Integration API</h1>
                        <button className="btn btn-success mt-3 me-3" onClick={() => navigate(Utils.getNavigationUrl(`integration-api/business-connect`))}>Enable Integration</button>
                        <button className="btn btn-success mt-3" onClick={() => navigate(Utils.getNavigationUrl(`integration-api/webhooks-table`))}>View Integration</button>
                    </div>
                    <div className="integration-section text-center">
                        <h2>How it works?</h2>
                        <div className="integration-flow mt-5">
                            <div className="integration-box col-lg-5">
                                <h5><span className="text-primary">CRM</span></h5>
                                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu odio porttitor, vehicula purus eu</p>
                            </div>

                            <div className="arrow">
                                &rarr;
                            </div>

                            <div className="integration-box col-lg-5">
                                <h5><span className="text-danger">SAP</span></h5>
                                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu odio porttitor, vehicula purus eu</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        // </div>
    )
}

export default IntegrationAPI;