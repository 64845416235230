import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import dayjs from "dayjs";

const WorkIcon = () => (
    <div
        style={{
            height: "40px",
            width: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <i className="fa fa-flag"></i>
    </div>
);

const SubformHistory = ({ log = [] }) => {
    return (
        <>
            <VerticalTimeline layout="1-column-left">
                {log?.length > 0 && (
                    log?.map((x, index) => (
                        <VerticalTimelineElement
                            key={index} className="vertical-timeline-element--work"
                            contentStyle={{
                                background: "#f4f7ff",
                                borderRadius: "8px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                            iconStyle={{ background: "#f4f7ff", color: "#000" }}
                            icon={<WorkIcon />}
                        >
                            <div style={{ fontSize: "14px", color: "#666" }}>
                                <strong className="fs-14">{dayjs(x?.createdAt).format("MMM D, YYYY hh:mm A")}</strong>
                            </div>
                            <p style={{ marginTop: "10px", color: "#333" }} className="text-new-line fs-14">
                                {x?.details}
                            </p>
                            <span> by <strong>{`${x?.user?.firstName} ${x?.user?.lastName}`}</strong></span>
                        </VerticalTimelineElement>
                    ))
                )}
            </VerticalTimeline>
        </>
    );
};

export default SubformHistory;
