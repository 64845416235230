import { call, put } from "redux-saga/effects";
import {
    getModuleByIdLookUp, removeModuleById, getAllFormModuleTableData, getAllFormModuleData, getForms, createModule, getFormModuleList, getModuleById, updateModuleById, getManageModules,
    createManageModule,
    updateManageModule,
    getAllFormModuleList,
    convertSelectedModuleList,
    getConvertModuleList,
    createConvertModule,
    getConvertModule,
    getByIDConvertModule,
    updateConvertModule
} from "../../../../Services/apiService";
import {
    getModuleByIdLookUpSuccess, getModuleByIdLookUpFailure, removeModuleByIdSuccess, removeModuleByIdFailure, getAllFormModuleSuccess, getAllFormModuleFailure, updateModuleByIdSuccess, updateModuleByIdFailure, getModuleByIdSuccess, getModuleByIdFailure, getFormModuleListSuccess, getFormModuleListFailure, getFormListFailure, getFormListSuccess, createFormsListSuccess, createFormsListFailure, getManageModulesSuccess, getManageModulesFailure,
    createManageModuleSuccess,
    createManageModuleFailure,
    updateManageModuleSuccess,
    updateManageModuleFailure,
    getAllFormModuleListSuccess,
    getAllFormModuleListFailure,
    getAllFormModuleTableDataSuccess,
    getAllFormModuleTableDataFailure,
    convertSelectedModuleListSuccess,
    convertSelectedModuleListFailure,
    getConvertModuleListSuccess,
    getConvertModuleListFailure,
    createConvertModuleSuccess,
    createConvertModuleFailure,
    getConvertModuleSuccess,
    getConvertModuleFailure,
    getByIDConvertModuleSuccess,
    getByIDConvertModuleFailure,
    updateConvertModuleSuccess,
    updateConvertModuleFailure,
    getMultiConvertModuleSuccess,
    getMultiConvertModuleFailure
} from "../../../Slices/formSlice";

export function* getAllFormModuleTableDataHandler(actions) {
    try {
        const response = yield call(getAllFormModuleTableData, actions?.payload);
        yield put(getAllFormModuleTableDataSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAllFormModuleTableDataFailure(errResponse));
    }
}

export function* getAllFormModule(actions) {
    try {
        const response = yield call(getAllFormModuleData, actions?.payload);
        yield put(getAllFormModuleSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAllFormModuleFailure(errResponse));
    }
}

export function* getFormsList(actions) {
    try {
        const response = yield call(getForms, actions?.payload);
        yield put(getFormListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getFormListFailure(errResponse));
    }
}

export function* getAllFormModuleListHandler(actions) {
    try {
        let response = yield call(getAllFormModuleList, actions?.payload);
        yield put(getAllFormModuleListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAllFormModuleListFailure(errResponse))
    }
}

export function* getFormModuleListHandler(actions) {
    try {
        let response = yield call(getFormModuleList, actions?.payload);
        yield put(getFormModuleListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getFormModuleListFailure(errResponse))
    }
}

export function* getModuleByIdLookUpHandler(actions) {
    try {
        let response = yield call(getModuleByIdLookUp, actions?.payload);
        yield put(getModuleByIdLookUpSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getModuleByIdLookUpFailure(errResponse))
    }
}

export function* getModuleByIdHandler(actions) {
    try {
        let response = yield call(getModuleById, actions?.payload);
        yield put(getModuleByIdSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getModuleByIdFailure(errResponse))
    }
}

export function* updateModuleByIdHandler(actions) {
    try {
        console.log(actions)
        let response = yield call(updateModuleById, actions?.payload);
        yield put(updateModuleByIdSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateModuleByIdFailure(errResponse))
    }
}

export function* createFormsHandler(actions) {
    try {
        let response = yield call(createModule, actions?.payload);
        yield put(createFormsListSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createFormsListFailure(errResponse))
    }
}

export function* removeModuleModuleHandler(actions) {
    try {
        let response = yield call(removeModuleById, actions?.payload);
        yield put(removeModuleByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(removeModuleByIdFailure(errResponse))
    }
}

export function* getManageModulesHandler(actions) {
    try {
        let response = yield call(getManageModules, actions?.payload);
        yield put(getManageModulesSuccess(response.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message || "An error occurred.";
        yield put(getManageModulesFailure(errResponse));
    }
}


export function* createManageModuleHandler(actions) {
    try {
        let response = yield call(createManageModule, actions?.payload);
        yield put(createManageModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createManageModuleFailure(errResponse));
    }
}


export function* updateManageModuleHandler(actions) {
    try {
        let response = yield call(updateManageModule, actions?.payload);
        yield put(updateManageModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateManageModuleFailure(errResponse));
    }
}

export function* convertSelectedModuleListHandler(actions) {
    try {
        let response = yield call(convertSelectedModuleList, actions?.payload);
        yield put(convertSelectedModuleListSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(convertSelectedModuleListFailure(errResponse));
    }
}

export function* getConvertModuleListHandler(actions) {
    try {
        let response = yield call(getConvertModuleList, actions?.payload);
        yield put(getConvertModuleListSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getConvertModuleListFailure(errResponse));
    }
}

export function* createConvertModuleHandler(actions) {
    try {
        let response = yield call(createConvertModule, actions?.payload);
        yield put(createConvertModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createConvertModuleFailure(errResponse));
    }
}

export function* getConvertModuleHandler(actions) {
    try {
        let response = yield call(getConvertModule, actions?.payload);
        yield put(getConvertModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getConvertModuleFailure(errResponse));
    }
}

export function* getMultiConvertModuleHandler(actions) {
    try {
        let response = yield call(getConvertModule, actions?.payload);
        yield put(getMultiConvertModuleSuccess({...response?.data, moduleId : actions?.payload}));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getMultiConvertModuleFailure(errResponse));
    }
}

export function* getByIDConvertModuleHandler(actions) {
    try {
        let response = yield call(getByIDConvertModule, actions?.payload);
        yield put(getByIDConvertModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getByIDConvertModuleFailure(errResponse));
    }
}

export function* updateConvertModuleHandler(actions) {
    try {
        let response = yield call(updateConvertModule, actions?.payload);
        yield put(updateConvertModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateConvertModuleFailure(errResponse));
    }
}