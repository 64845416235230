import { call, put } from "redux-saga/effects";
import { getAuditLogs } from "../../../../Services/apiService";
import { getAuditLogsFailure, getAuditLogsSuccess } from "../../../Slices/auditLogSlice";

export function* auditLogHandler(action) {
    try {
        const response = yield call(getAuditLogs, { params: action.payload });
        yield put(getAuditLogsSuccess(response));
    } catch (err) {
        yield put(getAuditLogsFailure(err?.response?.data || "Failed to fetch audit logs."));
    }
}