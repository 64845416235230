import React, { useEffect, useRef, useState } from 'react';
import MutiSelectDropDown from "../../Components/ModalPopup/FormModules/MutiSelectDropDown";
import { Helpers } from '../../Helpers';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { Utils } from '../../Utils';
import { useSelector } from 'react-redux';

const CustomInput = ({ items, sectionIndex, storeData, setStoreData, error, setErrors, preview, listItems, handleBlurMaterialNumber, handleBlurReferenceMaterial, users, lookupData = null, variants = null, modules = null, setMainModuleSubFormChangeModal, customInputs = [], openCheckBoxFieldModal, openParticipantModal }) => {
  let formList = useSelector((state) => state?.forms);
  let currentOrg = useSelector((state) => state?.org);
  let [extra_fields] = useState(items?.extra_fields || {});
  let [fieldLabel] = useState(extra_fields?.field_label || '');
  let [lookupDatas, setLookupDatas] = useState([]);
  let [key] = useState(Helpers.replaceData(fieldLabel));
  let [maxLength] = useState(extra_fields?.number_of_characters_allowed || undefined);
  let [charLimit] = useState(extra_fields?.character_limit || undefined);
  let [showTooltip] = useState(extra_fields?.show_tooltip || null);
  let [isShowIconHoverTooltip] = useState(showTooltip?.checked && showTooltip?.type === 'info' && showTooltip?.message?.length > 0);
  let [isShowInputHoverTooltip] = useState(showTooltip?.checked && showTooltip?.type === 'static' && showTooltip?.message?.length > 0);
  let utilsScriptUrl = "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js";
  let inputRef = useRef(null);
  let iti = null;

  useEffect(() => {
    if (lookupData?.hasOwnProperty(fieldLabel) && !preview) {
      let list = lookupData[fieldLabel];
      let data = [];
      if (list?.length > 0) {
        for (let x of list) {
          if (x?.data?.length > 0) {
            for (let y of x?.data) {
              let title = extra_fields?.related_list_title?.trim();
              let possibleKeys = [Helpers.replaceData(title), Helpers.replaceData(title + "_")];
              for (let key of possibleKeys) {
                let val = y.section_data[key] || null;
                if (y?.section_data?.hasOwnProperty(key) && !Array.isArray(val) && val) {
                  data.push({_id : x?._id, name : (typeof val === "string" ? val : (val?.name || ""))});
                  break;
                }
              }
            }
          }
        }
      }
      setLookupDatas(data);
    } else if(items?.input_type === "lookup" && extra_fields?.is_module_lookup) {
      setLookupDatas(formList?.navbarList);
    } else {
      setLookupDatas([]);
    }
  }, [lookupData]);

  let checkFunction = () => {
    let data = [];
    variants?.forEach(x => {
      storeData?.data?.forEach(y => {
        if ((y?.section_data?.[Helpers.replaceData(x?.parent_field)] === x?.[Helpers.replaceData(x?.parent_field)]) && (Helpers.replaceData(x?.child_field) === key)) {
          data = x?.[Helpers.replaceData(x?.child_field)];
        }
      });
    });
    return data;
  }
  let pickListOptions = checkFunction();
  let getValues = () => {
    let val = extra_fields?.default_value || '';
    let possibleKeys = [Helpers.replaceData(key), Helpers.replaceData(key + "_")];
    for (let key2 of possibleKeys) {
      let section_data = storeData?.data?.[sectionIndex]?.section_data;
      if (section_data?.hasOwnProperty(key2) && section_data?.[key2] !== undefined && section_data?.[key2]) {
        val = section_data[key2];
        break
      }
    }
    return val;
  }
  let labelValue = getValues();
  let [checkModuleNames] = useState(["Article Master", "Article Master Generic"]);

  let handleInputChange = (e) => {
    let target = e?.target;
    let value = target?.type === "checkbox" ? (target.checked ? "X" : "") : target.value;
    if (preview || !storeData) return value;
    if (charLimit && value.length > charLimit) {
      value = value.substring(0, charLimit);
    }
    setStoreData((prevStoreData) => {
      let updatedData = JSON.parse(JSON.stringify(prevStoreData));
      for (let i = 0; i <= sectionIndex; i++) {
        if (!updatedData?.data?.[i]?.hasOwnProperty("section_name") || !updatedData?.data?.[i]?.hasOwnProperty("section_data")) {
          updatedData.data[i] = {
            section_name: listItems?.sections?.[i]?.section_name,
            section_data: {}
          };
        }
      }
      updatedData.data[sectionIndex]["section_data"][key] = value;
      return updatedData;
    });
    setErrors((error) => ({ Material_Number: error?.["Material_Number"] || "" }));
  };

  useEffect(() => {
    let handleChange = () => {
      let fullNumber = iti?.getNumber();
      handleInputChange({
        target: {
          value: fullNumber
        }
      })
    };
    if (inputRef?.current && items?.input_type === "tel") {
      iti = intlTelInput(inputRef.current, {
        initialCountry: "in",
        utilsScript: utilsScriptUrl,
      });
      inputRef.current.addEventListener("input", handleChange);
    }
    return () => {
      if (iti) {
        iti = null;
      }
      if (inputRef?.current) {
        inputRef.current.removeEventListener("input", handleChange);
      }
    };
  }, [items?.input_type]); 
  
  let getFormFloatClass = () => {
    return ["multi-select", "multi-lookup", "user", "lookup"]?.includes(items?.input_type);
  }

  let formulaBlur = () => {
    let listOfFormula = Utils.getModuleFields(modules?.fields?.sections, ["formula"])?.moduleFields;
    if (listOfFormula?.length > 0) {
      try {
        let updatedStoreData = { ...storeData };
        let calculateFormula = (formula, objData) => {
          return formula?.replace(/\${(.*?)}/g, (match, variable) => {
            let keys = variable?.split('.');
            return objData?.[Helpers.replaceData(keys?.[1])] || 0;
          });
        };
        let processedData = storeData?.data?.reduce((acc, curr) => ({
          ...acc,
          ...curr?.section_data
        }), {});
        listOfFormula?.forEach((checkFormula) => {
          let expressionExtraFields = checkFormula?.fields?.extra_fields;
          if (expressionExtraFields?.formula_expression) {
            let result;
            let processedFormula = calculateFormula(expressionExtraFields?.formula_expression, processedData);
            result = eval(processedFormula);
            updatedStoreData.data[checkFormula?.sectionIndex].section_data[Helpers.replaceData(expressionExtraFields?.field_label)] = result;
          }
        });
        setStoreData(updatedStoreData);
      } catch (err) { }
    }
  }

  let getDisplayValue = (key, labelValue, display) => {
    let valueTemplate = display[key] || display?.default;
    return valueTemplate.replace(/\${(.*?)}/g, (_, key) => labelValue[key] || '');
  };

  return (
    <>
      <div className={`mb-3 form-fields-page ${(isShowIconHoverTooltip || ["tel", "lookup", "multi-lookup"]?.includes(items?.input_type)) ? (getFormFloatClass() ? 'form-normal' : 'form-floating') : ''}`}>
        <OverlayTrigger
          placement="bottom-start"
          overlay={
            <Tooltip id={`tooltip-${items?.id}`}>
              {showTooltip?.message || ""}
            </Tooltip>
          } {...(isShowInputHoverTooltip ? {} : { show: false })}>
          <div className={`${items?.input_type === "text" && extra_fields?.field_label === "Participants" ? "d-flex justify-content-between align-items-center" : ""} ${(isShowIconHoverTooltip  || ["tel", "lookup", "multi-lookup"]?.includes(items?.input_type)) ? 'input-group' : (getFormFloatClass() ? 'form-normal' : 'form-floating')}`}>
            {(items?.input_type === 'text' && !customInputs?.includes(key)) && (
              <>
                {key === "Product_Hierarchy" && checkModuleNames.includes(modules?.name) ? (
                  <>
                    <input
                      type="text"
                      className={`form-control ${error ? 'is-invalid' : ''}`}
                      id={items?.id} placeholder={fieldLabel || ""}
                      onChange={handleInputChange}
                      value={labelValue || ''}
                      maxLength={maxLength}
                      required={extra_fields?.required}
                      readOnly
                    />
                    <span className="badge bg-primary mt-1">Read Only</span>
                  </>
                ) : (key === "Material_Number" && checkModuleNames.includes(modules?.name) ?
                  <>
                    <input
                      type="text"
                      className={`form-control ${error ? 'is-invalid' : (labelValue ? 'is-valid' : '')}`}
                      id={items?.id} placeholder={fieldLabel || ""}
                      onChange={handleInputChange}
                      onBlur={handleBlurMaterialNumber}
                      value={labelValue || ''}
                      maxLength={maxLength}
                      required={extra_fields?.required}
                    />

                  </> : <>
                      {extra_fields?.field_label === "Participants" ?
                        <>
                          <div className="d-flex">
                            <span>Participants : <span className='cursor-pointer' onClick={() => preview ? {} : openParticipantModal(key)}>Selected({
                              ((labelValue?.selectedData || [])
                                ?.reduce((acc, item) => acc + (item?.data?.length || 0), 0) || 0)
                              + (labelValue?.inviteEmails?.length || 0)
                            })</span>
                            </span>
                            <a className='cursor-pointer ms-2 text-link' onClick={() => preview ? {} : openParticipantModal(key)}>+ Add</a>
                          </div>
                        </> :
                        <input
                          type="text"
                          className={`form-control ${error ? 'is-invalid' : ''}`}
                          id={items?.id}
                          placeholder={fieldLabel || ""}
                          onChange={handleInputChange}
                          onBlur={handleBlurReferenceMaterial}
                          value={labelValue || ''}
                          maxLength={maxLength}
                          required={extra_fields?.required}
                        />
                      }
                  </>
                )}
              </>
            )}
            {items?.input_type === 'formula' && (
              <input
                type="text"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                value={labelValue || ''}
                maxLength={maxLength}
                required={extra_fields?.required}
                disabled
              />
            )}
            {items?.input_type === 'email' && (
              <input
                type="email"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onChange={handleInputChange}
                value={labelValue || ''}
                maxLength={maxLength}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === 'number' && (
              <input
                type="number"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                onChange={handleInputChange}
                onBlur={formulaBlur}
                value={labelValue || ''}
                maxLength={maxLength}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === 'percent' && (
              <input
                type="number"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                onChange={handleInputChange}
                value={labelValue || ''}
                maxLength={maxLength}
                required={extra_fields?.required}
                onBlur={formulaBlur}
              />
            )}
            {items?.input_type === 'auto_number' && (
              <input
                type="text"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                value={`${extra_fields?.prefix}${extra_fields?.startingNumber}${extra_fields?.suffix}`}
                onChange={handleInputChange}
                maxLength={maxLength}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === 'decimal' && (
              <input
                type="number"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onChange={handleInputChange}
                value={labelValue || ''}
                maxLength={maxLength}
                required={extra_fields?.required}
                onBlur={formulaBlur}
                step="any"
              />
            )}
            {items?.input_type === 'longinteger' && (
              <input
                type="text"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onChange={handleInputChange}
                value={labelValue || ''}
                maxLength={maxLength}
                required={extra_fields?.required}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              />
            )}
            {items?.input_type === 'password' && (
              <input type="password" className={`form-control ${error ? 'is-invalid' : ''}`} id={items?.id} placeholder={fieldLabel || ""} onChange={handleInputChange} value={labelValue || ''} maxLength={maxLength} required={extra_fields?.required} />
            )}
            {(items?.input_type === 'checkbox' && !extra_fields?.check_box_modal_name) && (
              <div className={`form-check pt-1 ${error ? 'is-invalid' : ''}`}>
                <input
                  className="form-check-input mt-2 border-dark-subtle"
                  type="checkbox"
                  id={items?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  checked={extra_fields?.checked || labelValue === 'X'}
                  required={extra_fields?.required}
                />
              </div>
            )}
            {(items?.input_type === 'checkbox' && extra_fields?.check_box_modal_name) && (
              <>
                <div className={`form-check pt-1 ${error ? 'is-invalid' : ''}`}>
                  <input
                    className="form-check-input mt-2 border-dark-subtle"
                    type="checkbox"
                    id={items?.id}
                    placeholder={fieldLabel || ""}
                    onChange={(e) => {
                      if (!preview) {
                        let checked = e?.target?.checked;
                        if (checked) {
                          openCheckBoxFieldModal({
                            label: fieldLabel || "",
                            modalName: extra_fields?.check_box_modal_name
                          });
                        } else {
                          handleInputChange(e);
                        }
                      }
                    }}
                    checked={Object.keys(labelValue || {})?.length > 0}
                    required={extra_fields?.required}
                    disabled={extra_fields?.validation_field ? !Utils.getSubFormFieldToData(Helpers.replaceData(extra_fields?.validation_field), storeData?.data) || null : false}
                  />
                </div>
                {Object?.keys(labelValue || {})?.length > 0 ? <span className="cursor-pointer" onClick={() => {
                  if (!preview) {
                    openCheckBoxFieldModal({
                      label: fieldLabel || "",
                      modalName: extra_fields?.check_box_modal_name
                    });
                  }
                }}>( { Object.keys(Helpers.getValidObjectOrEmpty(extra_fields?.display)).reduce((acc, key) => {
                  if (labelValue.hasOwnProperty(key)) {
                    acc = getDisplayValue(key, Helpers.getValidObjectOrEmpty(labelValue), Helpers.getValidObjectOrEmpty(extra_fields?.display));
                  }
                  return acc;
                }, getDisplayValue('default', Helpers.getValidObjectOrEmpty(labelValue), Helpers.getValidObjectOrEmpty(extra_fields?.display)))} )</span> : <></>}
              </>
            )}
            {items?.input_type === 'radio' && (
              <div className={`form-check pt-1 ${error ? 'is-invalid' : ''}`}>
                <input type="radio" className="form-check-input mt-2" id={items?.id} placeholder={fieldLabel || ""} name={extra_fields?.name} onChange={handleInputChange} checked={labelValue === 'X'} />
              </div>
            )}
            {items?.input_type === 'textarea' && (
              <textarea rows="4" cols="50" style={{ height: "auto" }} className={`form-control ${error ? 'is-invalid' : ''}`} id={items?.id} placeholder={fieldLabel || ""} onChange={handleInputChange} value={labelValue || ''} maxLength={maxLength} required={extra_fields?.required}></textarea>
            )}
            {items?.input_type === 'select' && (
              <>
                <select
                  className={`form-select ${error ? 'is-invalid' : ''}`}
                  id={items?.id}
                  placeholder={fieldLabel || ""}
                  value={labelValue || ''}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {(pickListOptions?.length > 0 ? pickListOptions : extra_fields?.options)?.map((option, index) => (
                    <option value={option} key={`${sectionIndex}-${index}`}>
                      {option}
                    </option>
                  ))}
                </select>
              </>
            )}
            {items?.input_type === 'lookup' && (
              <div className={`form-control multi-select border-0 p-0 ${extra_fields?.required ? "unique" : ""}`}>
                <label htmlFor={items?.id}>
                  {fieldLabel}
                </label>
                <MutiSelectDropDown preview={preview} options={preview ? (typeof labelValue === "string" ? null : [labelValue]) : lookupDatas} handleInputChange={handleInputChange} default_value={typeof labelValue === "string" ? null : labelValue} singleSelect={true} />
              </div>
            )}
            {items?.input_type === 'multi-select' && (
              <div className={`form-control multi-select border-0 p-0 ${extra_fields?.required ? "unique" : ""}`}>
                <label htmlFor={items?.id}>
                  {fieldLabel}
                </label>
                <MutiSelectDropDown
                  preview={preview}
                  options={extra_fields?.options}
                  handleInputChange={handleInputChange}
                  default_value={labelValue}
                  required={extra_fields?.required}
                />
              </div>
            )}
            {(items?.input_type === 'multi-lookup') && (
              <div className={`form-control multi-select border-0 p-0 ${extra_fields?.required ? "unique" : ""}`}>
                <label htmlFor={items?.id}>
                  {fieldLabel}
                </label>
                { preview ? 
                <MutiSelectDropDown preview={preview} options={labelValue || []} handleInputChange={handleInputChange} default_value={labelValue} /> : <MutiSelectDropDown preview={preview} options={lookupDatas} handleInputChange={handleInputChange} default_value={labelValue} />}
              </div>
            )}
            {items?.input_type === 'currency' && (
              <div className="input-group mt-0">
                <span className={`input-group-text bg-white ${extra_fields?.required ? "unique" : ""}`}>{Utils.currencySymbols(currentOrg?.homeCurrencies?.currency_code || "")}</span>
                <input type="text" className={`form-control ${error ? 'is-invalid' : ''} border-start-0`} id={items?.id} placeholder={fieldLabel || ""} onChange={handleInputChange} value={labelValue || ""} maxLength={maxLength} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9\.]/g, ''); }} onBlur={formulaBlur}/>
                {isShowIconHoverTooltip && (
                  <span className="input-group-text">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip className="position-absolute" id={`tooltip-${items?.id}`}>{showTooltip?.message}</Tooltip>}>
                      <div style={{ display: 'inline-block' }}>
                        <i className="fa-regular fa-circle-question"></i>
                      </div>
                    </OverlayTrigger>
                  </span>
                )}
              </div>
            )}
            {
              customInputs?.includes(key) && (
                <div className="input-group mt-0">
                  <input type="text" className={`form-control ${error ? 'is-invalid' : ''} border-start-0`} id={items?.id} placeholder={fieldLabel || ""} value={labelValue || ""} readOnly />
                  <span className={`input-group-text bg-white ${extra_fields?.required ? "unique" : ""}`} onClick={() => preview ? {} : setMainModuleSubFormChangeModal(extra_fields)}><i className="fa fa-search"></i></span>
                </div>
              )
            }
            {items?.input_type === 'date' && (
              <input
                type="date"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={items?.label}
                onChange={handleInputChange}
                value={labelValue}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === 'datetime-local' && (
              <input
                type="datetime-local"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={items?.label}
                onChange={handleInputChange}
                value={labelValue}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === 'file' && (
              <input
                type="file"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onChange={handleInputChange}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === "image" && (
              <input
                type="file"
                className={`form-control ${error ? "is-invalid" : ""}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onChange={handleInputChange}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === 'url' && (
              <input
                type="url"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onChange={handleInputChange}
                value={labelValue || ''}
                maxLength={maxLength}
                required={extra_fields?.required}
              />
            )}
            {items?.input_type === 'tel' && (
              <input style={{ width: '100%' }}
                type="tel" ref={inputRef}
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={items?.id}
                placeholder={fieldLabel || ""}
                onChange={() => {}}
                value={labelValue || ""}
                maxLength={maxLength}
                required={extra_fields?.required}
              />
            )}
            {(items?.input_type === 'user' && extra_fields?.user_type === 'single_user') && (
              <div className={`form-control multi-select border-0 p-0 ${extra_fields?.required ? "unique" : ""}`}>
                <label htmlFor={items?.id}>
                  {fieldLabel}
                </label>
                <MutiSelectDropDown preview={preview} options={preview ? (Object?.keys(labelValue)?.length > 0 ? [labelValue] : []) : (users || [])} handleInputChange={handleInputChange} default_value={typeof labelValue === "string" ? null : labelValue} singleSelect={true} isSingleUser={true} isUser={true} />
              </div>
            )}
            {(items?.input_type === 'user' && extra_fields?.user_type === 'multiple_user') && (
              <div className={`form-control multi-select border-0 p-0 ${extra_fields?.required ? "unique" : ""}`}>
                <label htmlFor={items?.id}>
                  {fieldLabel}
                </label>
                <MutiSelectDropDown preview={preview} options={preview ? labelValue || [] : (users || [])} handleInputChange={handleInputChange} default_value={typeof labelValue === "string" ? [] : labelValue} isUser={true} />
              </div>
            )}
            {(isShowIconHoverTooltip && !['currency']?.includes(items?.input_type)) && (
              <span className="input-group-text">
                <OverlayTrigger placement="bottom" overlay={<Tooltip className="position-absolute" id={`tooltip-${items?.id}`}>{showTooltip?.message}</Tooltip>}>
                  <div style={{ display: 'inline-block' }}>
                    <i className="fa-regular fa-circle-question"></i>
                  </div>
                </OverlayTrigger>
              </span>
            )}
            {(['lookup']?.includes(items?.input_type)) && (
              <span className="input-group-text" onClick={() => preview ? {} : setMainModuleSubFormChangeModal(extra_fields)}>
                <i className="fa fa-search"></i>
              </span>
            )}
            {error && <div id={items?.id} placeholder={fieldLabel || ""} className="invalid-feedback">
              {error}
            </div>
            }
            {(!isShowIconHoverTooltip && items?.input_type !== "tel" && extra_fields?.field_label !== "Participants") && (
              <>
                <label className={`${getFormFloatClass() ? 'd-none' : 'd-inline-block'}`} htmlFor={items?.id}>
                  {fieldLabel}
                </label>
              </>
            )}
          </div>
        </OverlayTrigger>
        {(isShowIconHoverTooltip && extra_fields?.field_label !== "Participants" || items?.input_type === "tel" && extra_fields?.field_label !== "Participants") && (
          <>
            <label className={`${getFormFloatClass() ? 'd-none' : 'd-inline-block'}`} htmlFor={items?.id}>
              {fieldLabel}
            </label>
          </>
        )}
      </div >
    </>
  );
};

export default CustomInput;
