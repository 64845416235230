import { call, put } from "redux-saga/effects";
import { createShiftHoursFailure, createShiftHoursSuccess, deleteShiftHoursFailure, deleteShiftHoursSuccess, getShiftHoursFailure, getShiftHoursSuccess, updateShiftHoursFailure, updateShiftHoursSuccess } from "../../../Slices/shiftHoursSlice";
import { createShiftHours, deleteShiftHours, getShiftHours, updateShiftHours } from "../../../../Services/apiService";

export function* getShiftHoursHandler(actions) {
    try {
        const response = yield call(getShiftHours, actions?.payload);
        yield put(getShiftHoursSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getShiftHoursFailure(errResponse))
    }
}

export function* createShiftHoursHandler(actions) {
    try {
        const response = yield call(createShiftHours, actions?.payload);
        yield put(createShiftHoursSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createShiftHoursFailure(errResponse))
    }
}

export function* updateShiftHoursHandler(actions) {
    try {
        const response = yield call(updateShiftHours, actions?.payload);
        yield put(updateShiftHoursSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateShiftHoursFailure(errResponse))
    }
}

export function* deleteShiftHoursHandler(actions) {
    try {
        const response = yield call(deleteShiftHours, actions?.payload);
        yield put(deleteShiftHoursSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteShiftHoursFailure(errResponse))
    }
}