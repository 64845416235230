import routes from "../Routes/routes";
import { v4 as uuid4 } from "uuid";
import { arrayMove as dndKitArrayMove } from "@dnd-kit/sortable";
import singleLineIcon from '../Assets/images/icons/sigle-line-icon.svg';
import multiLineIcon from '../Assets/images/icons/multi-line-icon.svg';
import mailIcon from '../Assets/images/icons/mail-icon.svg';
import phoneIcon from '../Assets/images/icons/phone-number-icon.svg';
import pickListIcon from '../Assets/images/icons/picklist-icon.svg';
import multiSelectIcon from '../Assets/images/icons/multi-select-icon.svg';
import dateIcon from '../Assets/images/icons/date-icon.svg';
import dateTimeIcon from '../Assets/images/icons/date-time-icon.svg';
import autoNumberIcon from '../Assets/images/icons/auto-number-icon.svg';
import currencyIcon from '../Assets/images/icons/currency-icon.svg';
import decimalIcon from '../Assets/images/icons/decimal-icon.svg';
import percentIcon from '../Assets/images/icons/percentage-icon.svg';
import checkboxIcon from '../Assets/images/icons/checkbox-icon.svg';
import urlIcon from '../Assets/images/icons/url-icon.svg';
import lookupIcon from '../Assets/images/icons/lookup-icon.svg';
import formulaIcon from '../Assets/images/icons/formula-icon.svg';
import userIcon from '../Assets/images/icons/user-icon.svg';
import fileUploadIcon from '../Assets/images/icons/file-upload-icon.svg';
import imageUploadIcon from '../Assets/images/icons/image-upload-icon.svg';
import rollupSummaryIcon from '../Assets/images/icons/rollup-summary.svg';
import multiSelectLookupIcon from '../Assets/images/icons/subform-icon.svg';
import subformIcon from '../Assets/images/icons/subform-icon.svg';
import newSectionIcon from '../Assets/images/icons/new-section-icon.svg';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { Helpers } from "../Helpers";
import minMax from "dayjs/plugin/minMax";
import { getItem } from "../Services/localService";
import { Navigate } from "react-router-dom";
dayjs.extend(minMax);

let stringInputTypes = ["text", "email", "tel", "select", "url", "lookup", "user"];

let numberInputTypes = ["number", "currency", "decimal", "percent", "longinteger", "formula"];

let dateInputTypes = ["date", "datetime-local"];

let currencySymbols = (code) => {
  let code2 = (code === undefined || !code ? "USD" : code);
  let symbols = {
    USD: "$",    // United States Dollar
    EUR: "€",    // Euro
    GBP: "£",    // British Pound Sterling
    INR: "₹",    // Indian Rupee
    JPY: "¥",    // Japanese Yen
    CNY: "¥",    // Chinese Yuan Renminbi
    AUD: "A$",   // Australian Dollar
    CAD: "C$",   // Canadian Dollar
    CHF: "CHF",  // Swiss Franc
    HKD: "HK$",  // Hong Kong Dollar
    NZD: "NZ$",  // New Zealand Dollar
    SEK: "kr",   // Swedish Krona
    KRW: "₩",    // South Korean Won
    SGD: "S$",   // Singapore Dollar
    NOK: "kr",   // Norwegian Krone
    MXN: "$",    // Mexican Peso
    BRL: "R$",   // Brazilian Real
    ZAR: "R",    // South African Rand
    RUB: "₽",    // Russian Ruble
    TRY: "₺",    // Turkish Lira
    THB: "฿",    // Thai Baht
    IDR: "Rp",   // Indonesian Rupiah
    PLN: "zł",   // Polish Zloty
    TWD: "NT$",  // New Taiwan Dollar
    SAR: "﷼",   // Saudi Riyal
    MYR: "RM",   // Malaysian Ringgit
    AED: "د.إ", // UAE Dirham
    VND: "₫",    // Vietnamese Dong
    ARS: "$",    // Argentine Peso
    DKK: "kr",   // Danish Krone
    ILS: "₪",    // Israeli New Shekel
    PKR: "₨",    // Pakistani Rupee
    HUF: "Ft",   // Hungarian Forint
    CZK: "Kč",   // Czech Koruna
    CLP: "$",    // Chilean Peso
    PHP: "₱",    // Philippine Peso
    COP: "$",    // Colombian Peso
    EGP: "£",    // Egyptian Pound
    NGN: "₦",    // Nigerian Naira
    BDT: "৳",    // Bangladeshi Taka
    KZT: "₸",    // Kazakhstani Tenge
    LKR: "₨",    // Sri Lankan Rupee
    MMK: "K",    // Myanmar Kyat
    UAH: "₴",    // Ukrainian Hryvnia
    BGN: "лв",   // Bulgarian Lev
    HRK: "kn",   // Croatian Kuna
    RON: "lei",  // Romanian Leu
    PEN: "S/",   // Peruvian Sol
    ISK: "kr",   // Icelandic Krona
    JOD: "د.ا", // Jordanian Dinar
  }
  return symbols[code2];
};

const idGenerator = () => {
  return uuid4();
};

const removeAtIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const insertAtIndex = (array, index, item) => {
  return [...array.slice(0, index), item, ...array.slice(index)];
};

const arrayMove = (array, oldIndex, newIndex) => {
  return dndKitArrayMove(array, oldIndex, newIndex);
};

export const getSideBarRoutes = () => {
  try {
    let route = routes
    return route;
  } catch (err) {
    throw err;
  }
}

export const TABLE = {
  FORMS: (formList) => {
    return formList?.map((form) => {
      return {
        id: form?._id,
        title: form?.name,
        description: form?.description,
        createdBy: `${form?.createdBy?.firstName} ${form?.createdBy?.lastName || ''}`,
        noOfSubmission: form?.noOfSubmission,
        isModule: form?.isModule,
        module: form?.module
      }
    })
  }
}

const showTooltip = {
  checked: false,
  type: "info",
  message: ""
}
const propertyModal = {
  SingleLine: "SingleLinePropertiesModal",
  MultiLine: "MultiLinePropertiesModal",
  Email: "EmailPropertiesModal",
  Phone: "PhonePropertiesModal",
  PickList: "PickListProperties",
  MultiPickList: "MultiPickListProperties",
  Date: "DatePropertiesModal",
  DateTime: "DateTimePropertiesModal",
  Number: "NumberPropertiesModal",
  AutoNumber: "AutoNumberPropertiesModal",
  Currency: "CurrencyPropertiesModal",
  Decimal: "DecimalPropertiesModal",
  Percent: "PercentPropertiesModal",
  LongInteger: "LongIntegerPropertiesModal",
  Checkbox: "CheckboxPropertiesModal",
  URL: "URLPropertiesModal",
  Lookup: "LookupPropertiesModal",
  Formula: "CRMFormulaForm",
  User: "UserFieldPropertiesModal",
  FileUpload: "FileUploadPropertiesModal",
  ImageUpload: "ImageUploadPropertiesModal",
  // RollupSummary: "RollupSummary",
  Subform: "",
  MultiSelectLookup: "MultiLookupProperties",
  Aggregate: "AggregateProperties"
}

const dropDownValues = ["Mark as required","Edit Properties", "Remove Field"];
const dropDownValues2 = ["Edit Properties", "Remove Field"];

const createEmailDropdown = (baseValues) => {
  let emailDropdown = [...baseValues];
  emailDropdown.splice(1, 0, "Do not allow duplicate values");
  return emailDropdown;
};
const multiSelectLookupDropdown = () => {
  return ["Set Permission", "Edit Properties", "Remove Field"];
};
const dropDownList = {
  SingleLine: dropDownValues,
  MultiLine: dropDownValues,
  Email: createEmailDropdown(dropDownValues),
  Phone: dropDownValues,
  PickList: dropDownValues,
  MultiPickList: dropDownValues,
  Date: dropDownValues,
  DateTime: dropDownValues,
  Number: dropDownValues,
  AutoNumber: dropDownValues2,
  Currency: dropDownValues,
  Decimal: dropDownValues,
  Percent: dropDownValues,
  LongInteger: dropDownValues,
  Checkbox: dropDownValues2,
  URL: dropDownValues,
  Lookup: dropDownValues,
  Formula: dropDownValues2,
  User: dropDownValues,
  FileUpload: dropDownValues2,
  ImageUpload: dropDownValues2,
  RollupSummary: dropDownValues,
  Subform: dropDownValues,
  MultiSelectLookup: multiSelectLookupDropdown(),
  Aggregate: dropDownValues
}

const formModuleDatas = {
  sidebar_fields: [
    {
      id: "single_line",
      image_url: singleLineIcon,
      label: "Single Line",
      input_type: "text",
      extra_fields: {
        field_label: "Single Line",
        number_of_characters_allowed: 255,
        required: false,
        do_not_allow_duplicate_values: false,
        encrypt_field: false,
        set_as_external_field: false,
        external_field_type: "",
        show_tooltip: {
          checked: false,
          type: "static",
          message: ""
        }
      }
    },
    {
      id: "multi_line",
      image_url: multiLineIcon,
      label: "Multi Line",
      input_type: "textarea",
      extra_fields: {
        field_label: "Multi-Line",
        required: true,
        encrypt_field: false,
        input_format: "plainTextSmall",
        show_tooltip: {
          checked: false,
          type: "info",
          message: ""
        }
      }
    },
    {
      id: "email",
      image_url: mailIcon,
      label: "Email",
      input_type: "email",
      extra_fields: {
        field_label: "Email",
        required: false,
        do_not_allow_duplicate_values: false,
        encrypt_field: false,
        show_tooltip: {
          checked: false,
          message: "",
        },
      }
    },
    {
      id: "phone",
      image_url: phoneIcon,
      label: "Phone",
      input_type: "tel",
      extra_fields: {
        field_label: "Phone",
        number_of_characters_allowed: 30,
        required: false,
        do_not_allow_duplicate_values: false,
        encrypt_field: false,
        show_tooltip: {
          checked: false,
          type: "info",
          message: "",
        },
      }
    },
    {
      id: "pick_list",
      image_url: pickListIcon,
      label: "Pick List",
      input_type: "select",
      extra_fields: {
        field_label: "Pick List",
        pick_list_type: "Local",
        global_select: "",
        options: ["Option 1", "Option 2"],
        default_value: "",
        order_preference: "Entered order",
        color_options: false,
        required: false,
        show_tooltip: {
          checked: false,
          type: "info",
          message: ""
        }
      }
    },
    {
      id: "multi_pick_list",
      image_url: multiSelectIcon,
      label: "Multi Pick List",
      input_type: "multi-select",
      extra_fields: {
        field_label: "Multi Pick List",
        options: [{ name: "Option 1" }, { name: "Option 2" }],
        default_value: "",
        alphabetical_order: false,
        required: false,
        show_tooltip: {
          checked: false,
          type: "info",
          message: ""
        }
      }
    },
    {
      id: "date",
      image_url: dateIcon,
      label: "Date",
      input_type: "date",
      extra_fields: {
        field_label: "Date",
        show_tootltip: showTooltip
      }
    },
    {
      id: "date_time",
      image_url: dateTimeIcon,
      label: "Date Time",
      input_type: "datetime-local",
      extra_fields: {
        field_label: "Date Time",
        show_tootltip: showTooltip
      }
    },
    {
      id: "number",
      image_url: autoNumberIcon,
      label: "Number",
      input_type: "number",
      extra_fields: {
        field_label: "Number",
        show_tooltip: showTooltip
      }
    },
    {
      id: "auto_number",
      image_url: autoNumberIcon,
      label: "Auto Number",
      input_type: "auto_number",
      extra_fields: {
        field_label: "Auto Number",
        show_tooltip: showTooltip
      }
    },
    {
      id: "currency",
      image_url: currencyIcon,
      label: "Currency",
      input_type: "currency",
      extra_fields: {
        field_label: "Currency",
        show_tooltip: showTooltip
      }
    },
    {
      id: "decimal",
      image_url: decimalIcon,
      label: "Decimal",
      input_type: "decimal",
      extra_fields: {
        field_label: "Decimal",
        show_tooltip: showTooltip
      }
    },
    {
      id: "percent",
      image_url: percentIcon,
      label: "Percent",
      input_type: "percent",
      extra_fields: {
        field_label: "Percent",
        show_tooltip: showTooltip
      }
    },
    {
      id: "long_integer",
      image_url: checkboxIcon,
      label: "Long Integer",
      input_type: "longinteger",
      extra_fields: {
        field_label: "Long integer",
        show_tooltip: showTooltip
      }
    },
    {
      id: "checkbox",
      image_url: checkboxIcon,
      label: "Checkbox",
      input_type: "checkbox",
      extra_fields: {
        field_label: "Checkbox",
        show_tooltip: showTooltip
      }
    },
    {
      id: "url",
      image_url: urlIcon,
      label: "URL",
      input_type: "url",
      extra_fields: {
        field_label: "URL",
        show_tooltip: showTooltip
      }
    },
    {
      id: "lookup",
      image_url: lookupIcon,
      label: "Lookup",
      input_type: "lookup",
      extra_fields: {
        field_label: "Lookup",
        moduleId: "",
        related_list_title: "",
        filter_lookup_records_checked: false,
        filter_lookup_records: [
          {
            field_id: "",
            match: "",
            value: ""
          }
        ],
        required: false,
        show_tooltip: {
          checked: false,
          type: "info",
          message: ""
        }
      }
    },
    {
      id: "formula",
      image_url: formulaIcon,
      label: "Formula",
      input_type: "formula",
      extra_fields: {
        field_label: "Formula",
        return_type: "Decimal",
        decimal_place: 0,
        formula_expression: ""
      }
    },
    {
      id: "user",
      image_url: userIcon,
      label: "User",
      input_type: "user",
      extra_fields: {
        field_label: "User",
        user_type: "single_user",
        allow_record_accessibility: true,
        permission: "Full Access",
        required: false,
        show_tooltip: {
          checked: false,
          type: "info",
          message: ""
        }
      }
    },
    {
      id: "file_upload",
      image_url: fileUploadIcon,
      label: "File Upload",
      input_type: "file",
      extra_fields: {
        field_label: "File Upload",
        show_tooltip: showTooltip
      }
    },
    {
      id: "image_upload",
      image_url: imageUploadIcon,
      label: "Image Upload",
      input_type: "file",
      extra_fields: {
        field_label: "Image Upload",
        show_tooltip: showTooltip
      }
    },
    // {
    //   id: "rollup_summary",
    //   image_url: rollupSummaryIcon,
    //   label: "Rollup Summary",
    //   input_type: "rollup",
    //   extra_fields: {
    //     field_label: "Rollup Summary",
    //   }
    // },

    {
      id: "subform",
      image_url: subformIcon,
      label: "Subform",
      unique_name: "subform"
    },
    {
      id: "new_section",
      image_url: newSectionIcon,
      label: "New Section",
      unique_name: "new_section"
    },
    {
      id: "multi-select-lookup",
      image_url: multiSelectLookupIcon,
      column_class_name: "col-md-12",
      label: "Multi Select Lookup",
      input_type: "multi-lookup",
      extra_fields: {
        field_label: "Multi Lookup",
        moduleId: "",
        related_list_title: "Related List Label 1"
      }
    }
  ]
};

const getDefaultFields = (id, labelName) => {
  let singleLineField = formModuleDatas.sidebar_fields.find(x => x.id === id);
  let { image_url, ...rest } = singleLineField;
  let newField = {
    ...rest,
    id: idGenerator(),
    extra_fields: { ...singleLineField?.extra_fields, field_label: (labelName || singleLineField?.extra_fields?.field_label) }
  };
  return newField;
}

const sectionFormat = {
  id: 'section',
  section_name: "Untitled Section",
  layouts: 2,
  orders: "",
  show_drop_down: false,
  unique_name: "new_section",
  dropped_list: {}
}

const subFormFormat = {
  id: 'subform',
  section_name: "Subform",
  unique_name: "subform",
  show_drop_down: false,
  subform: true,
  selected_list: [],
  aggregate_list: []
}

let formModuleStoreData = {
  plural_form: "",
  singular_form: "",
  description: "Enter the description",
  image: {
    disabled: true
  },
  sections: [
    {
      ...sectionFormat, dropped_list: {
        drop_0_area_1: [
          getDefaultFields('single_line', ''),
          getDefaultFields('email', ''),
          getDefaultFields('single_line', 'Created By')
        ],
        drop_0_area_2: [
          getDefaultFields('user', 'Untitled Owner'),
          getDefaultFields('email', 'Secondary Email'),
          getDefaultFields('single_line', 'Modified By'),
          getDefaultFields('checkbox', 'Email Opt Out')
        ]
      }, default: true
    }
  ]
}

const exportData = (datas, file_name, sheet_name = "Sheet1") => {
  let worksheet = XLSX.utils.aoa_to_sheet(datas);
  let workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheet_name);
  XLSX.writeFile(workbook, `${file_name}.xlsx`);
};

const urlPattern = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'); // fragment locator

const getModuleFields = (sections = [], checkIncludes = []) => {
  let data = {
    moduleFields: [],
    subformList: []
  };
  sections.forEach((section, sectionIndex) => {
    let droppedList = section?.dropped_list || {};
    let selectedList = section?.selected_list || [];
    if (droppedList && Object.keys(droppedList)?.length) {
      Object.values(droppedList).forEach((area) => {
        area.forEach((item) => {
          if (checkIncludes?.length > 0 ? checkIncludes.includes(item.input_type) : true) {
            data.moduleFields.push({ sectionIndex, fields: item });
          }
        });
      });
    } else if (selectedList?.length > 0) {
      selectedList.forEach((item) => {
        if (checkIncludes?.length > 0 ? checkIncludes.includes(item.input_type) : true) {
          data.subformList.push({ sectionIndex, fields: item });
        }
      });
    }
  });
  return data;
}

const defaultSubFormNameUpdate = (sections = [], fieldId = "", bool = false) => {
  return sections.map((section) => {
    let droppedList = section?.dropped_list || {};
    let updatedDroppedList = Object.fromEntries(
      Object.entries(droppedList).map(([key, area]) => {
        let updatedArea = area?.map((item) => {
          if (item.id === fieldId) {
            return { ...item, is_default_subform_name: bool };
          } else {
            return { ...item, is_default_subform_name: false };
          }
        });
        return [key, updatedArea];
      })
    );
    return { ...section, dropped_list: updatedDroppedList };
  });
};

const getDefaultSubFormNameField = (sections = []) => {
  let fieldLabel = "";
  sections.forEach((section) => {
    let droppedList = section?.dropped_list || {};
    if (droppedList && Object.keys(droppedList)?.length) {
      Object.values(droppedList).forEach((area) => {
        area.forEach((item) => {
          if (item?.is_default_subform_name) {
            fieldLabel = Helpers.replaceData(item?.extra_fields?.field_label);
          }
        });
      });
    }
  });
  return fieldLabel;
};

let getFilteredRecords = (datas, duration1, duration2, isBetweenDate = null) => {
  dayjs.extend(quarterOfYear);
  let durationMap = {
    "all": (record) => true,
    "today": (record) => dayjs(record?.createdAt).isSame(dayjs(), 'day'),
    "this_week": (record) => dayjs(record?.createdAt).isSame(dayjs(), 'week'),
    "this_month": (record) => dayjs(record?.createdAt).isSame(dayjs(), 'month'),
    "this_quarter": (record) => dayjs(record?.createdAt).isSame(dayjs(), 'quarter'),
    "this_year": (record) => dayjs(record?.createdAt).isSame(dayjs(), 'year'),
    "yesterday": (record) => dayjs(record?.createdAt).isSame(dayjs().subtract(1, 'day'), 'day'),
    "previous_week": (record) => dayjs(record?.createdAt).isSame(dayjs().subtract(1, 'week'), 'week'),
    "previous_month": (record) => dayjs(record?.createdAt).isSame(dayjs().subtract(1, 'month'), 'month'),
    "previous_quarter": (record) => dayjs(record?.createdAt).isSame(dayjs().subtract(1, 'quarter'), 'quarter'), // last month - 1 month
    "previous_year": (record) => dayjs(record?.createdAt).isSame(dayjs().subtract(1, 'year'), 'year'),
    "tomorrow": (record) => dayjs(record?.createdAt).isSame(dayjs().add(1, 'day'), 'day'),
    "next_week": (record) => dayjs(record?.createdAt).isSame(dayjs().add(1, 'week'), 'week'),
    "next_month": (record) => dayjs(record?.createdAt).isSame(dayjs().add(1, 'month'), 'month'),
    "next_quarter": (record) => dayjs(record?.createdAt).isSame(dayjs().add(1, 'quarter'), 'quarter'),
    "next_year": (record) => dayjs(record?.createdAt).isSame(dayjs().add(1, 'year'), 'year')
  };
  if (datas?.length > 0) {
    if (duration1 === "Created At") {
      if (isBetweenDate) {
        let { start_date, end_date } = isBetweenDate;
        return datas.filter(record => {
          let createdAt = dayjs(record?.createdAt);
          return createdAt.isBetween(dayjs(start_date), dayjs(end_date), null, '[]');
        });
      }
      return datas.filter(record => durationMap[duration2]?.(record));
    } else {
      if (isBetweenDate) {
        let { start_date, end_date } = isBetweenDate;
        return datas.filter(record =>
          record.data.some(section => {
            let date = section?.section_data?.[Helpers.replaceData(duration1)];
            return date && dayjs(date).isBetween(dayjs(start_date), dayjs(end_date), null, '[]');
          })
        );
      }
      return datas.filter(record =>
        record.data.some(section => {
          let date = section?.section_data?.[Helpers.replaceData(duration1)];
          return date && durationMap[duration2]?.({ createdAt: date });
        })
      );
    }
  }
  return [];
};

export const validateFieldLabel = (value) => {
  const regex = /^[a-zA-Z0-9\s]*$/;
  if (value.startsWith(" ")) {
    return "Field label cannot start with a space.";
  }
  if (!regex.test(value)) {
    return "Special characters are not allowed in the field label.";
  }
  if (value.length > 25) {
    return "Field label cannot exceed 25 characters.";
  }
  return "";
};

export const handleFieldLabelUpdate = (value, updateFieldLabel, setLabelError) => {
  const error = validateFieldLabel(value);
  if (error) {
    setLabelError(error);
  } else {
    setLabelError('');
    updateFieldLabel(value);
  }
};


let removeDuplicatesAndTotalCount = (items = [], counts = []) => {
  let { uniqueItems, aggregatedCounts } = items?.reduce((acc, item, index) => {
    let count = counts?.[index];
    let itemIndex = acc?.uniqueItems?.indexOf(item);
    if (itemIndex === -1) {
      acc.uniqueItems.push(item);
      acc.aggregatedCounts.push(count);
    } else {
      acc.aggregatedCounts[itemIndex] += count;
    }
    return acc;
  }, { uniqueItems: [], aggregatedCounts: [] });
  return { uniqueItems, aggregatedCounts };
}

let handleStringCondition = (inputString = "", conditionValue = "", comparisonValue = "") => {
  inputString = inputString?.toString();
  switch (conditionValue) {
    case "IS":
      return inputString === comparisonValue;

    case "ISN'T":
      return inputString !== comparisonValue;

    case "CONTAINS":
      return inputString.includes(comparisonValue);

    case "DOESN'T MATCH":
      return inputString !== comparisonValue;

    case "STARTS WITH":
      return inputString.startsWith(comparisonValue);

    case "ENDS WITH":
      return inputString.endsWith(comparisonValue);

    case "IS EMPTY":
      return inputString.trim() === "";

    case "IS NOT EMPTY":
      return inputString.trim() !== "";

    default:
      return false;
  }
}

let handleNumberCondition = (inputNumber, conditionValue, comparisonValue1, comparisonValue2 = null) => {
  switch (conditionValue) {
    case "=":
      return inputNumber === comparisonValue1;

    case "!=":
      return inputNumber !== comparisonValue1;

    case "<":
      return inputNumber < comparisonValue1;

    case "<=":
      return inputNumber <= comparisonValue1;

    case ">":
      return inputNumber > comparisonValue1;

    case ">=":
      return inputNumber >= comparisonValue1;

    case "BETWEEN":
      if (comparisonValue2 === null) {
        return false;
      }
      return inputNumber >= comparisonValue1 && inputNumber <= comparisonValue2;

    case "NOT BETWEEN":
      if (comparisonValue2 === null) {
        return false;
      }
      return inputNumber < comparisonValue1 || inputNumber > comparisonValue2;

    case "IS EMPTY":
      return inputNumber === null || inputNumber === undefined || inputNumber === "";

    case "IS NOT EMPTY":
      return inputNumber !== null && inputNumber !== undefined && inputNumber !== "";

    default:
      return false;
  }
}

let handleDateCondition = (inputDate, conditionValue, comparisonDate1 = null, comparisonDate2 = null) => {
  let today = dayjs();
  let parsedInputDate = inputDate ? dayjs(inputDate) : null;
  switch (conditionValue) {
    case "IS":
      return parsedInputDate.isSame(dayjs(comparisonDate1), 'day');

    case "ISN'T":
      return !parsedInputDate.isSame(dayjs(comparisonDate1), 'day');

    case "IS BEFORE":
      return parsedInputDate.isBefore(dayjs(comparisonDate1), 'day');

    case "LTE":
      return parsedInputDate.isAfter(dayjs(comparisonDate1), 'day');

    case "BETWEEN":
      if (!comparisonDate1 || !comparisonDate2) {
        return false;
      }
      return parsedInputDate.isBetween(dayjs(comparisonDate1), dayjs(comparisonDate2), null, '[]');

    case "NOT BETWEEN":
      if (!comparisonDate1 || !comparisonDate2) {
        return false;
      }
      return !parsedInputDate.isBetween(dayjs(comparisonDate1), dayjs(comparisonDate2), null, '[]');

    case "TODAY":
      return parsedInputDate.isSame(today, 'day');

    case "TOMORROW":
      return parsedInputDate.isSame(today.add(1, 'day'), 'day');

    case "STARTING TOMORROW":
      return parsedInputDate.isSameOrAfter(today.add(1, 'day'), 'day');

    case "YESTERDAY":
      return parsedInputDate.isSame(today.subtract(1, 'day'), 'day');

    case "TILL YESTERDAY":
      return parsedInputDate.isBefore(today, 'day');

    case "LAST MONTH":
      return parsedInputDate.isSame(today.subtract(1, 'month'), 'month');

    case "CURRENT MONTH":
      return parsedInputDate.isSame(today, 'month');

    case "NEXT MONTH":
      return parsedInputDate.isSame(today.add(1, 'month'), 'month');

    case "IS EMPTY":
      return !inputDate;

    case "IS NOT EMPTY":
      return !!inputDate;

    default:
      return false;
  }
}

let buildNestedPattern = (criteria, relationships) => {
  if (criteria.length === 0) return "";
  let pattern = criteria[0].id.toString();
  for (let i = 1; i < criteria.length; i++) {
    pattern = `( ${pattern} ${relationships[i - 1]} ${criteria[i].id} )`;
  }
  return pattern;
}

let validatePattern = (pattern, criteriaLength) => {
  let stack = [];
  let validOperators = ["and", "or"];
  let ids = Array.from({ length: criteriaLength }, (_, i) => (i + 1).toString());
  let tokens = pattern
    .replace(/\s+/g, " ")
    .replace(/\(/g, " ( ")
    .replace(/\)/g, " ) ")
    .split(/\s+/)
    .filter((token) => token);

  let lastToken = null;
  let lastOperator = null;
  let invalidPosition = null;
  for (let i = 0; i < tokens?.length; i++) {
    let token = tokens[i];
    if (token === "(") {
      stack.push(token);
    } else if (token === ")") {
      if (stack.length === 0) return { isValid: false, position: i + 1 };
      stack.pop();
    } else if (validOperators.includes(token)) {
      if (!lastToken || validOperators?.includes(lastToken) || lastToken === "(") {
        invalidPosition = i + 1;
        return { isValid: false, position: invalidPosition };
      }
      lastOperator = token;
    } else if (!ids.includes(token)) {
      invalidPosition = i + 1;
      return { isValid: false, position: invalidPosition };
    }
    lastToken = token;
  }
  if (stack?.length !== 0) return { isValid: false, position: tokens?.length + 1 };
  if (validOperators?.includes(lastToken)) return { isValid: false, position: tokens?.length };
  return { isValid: true, position: null };
}

let validateOrder = (criteria, pattern) => {
  let idsInPattern = pattern?.match(/\d+/g);
  let criteriaIds = criteria?.map((criterion) => criterion?.id?.toString());
  if (JSON.stringify(idsInPattern) !== JSON.stringify(criteriaIds)) {
    return { isValid: false, position: -1 };
  }
  return { isValid: true };
}

let evaluateLogicalExpression = (expression) => {
  try {
    let formattedExpression = expression?.replace(/\band\b/g, "&&")?.replace(/\bor\b/g, "||");
    return eval(formattedExpression);
  } catch (error) {
    return false;
  }
};

let filteredDataGet = (datas, data) => {
  return datas?.filter(record => {
    let isTrue = false;
    if (data?.is_enable_criteria && data?.criteria?.length > 0) {
      let resultArray = [...data.criteria].map((x) => {
        let foundResult = false;
        for (let section of record?.data || []) {
          let fieldLabel = Helpers.replaceData(x?.field_label);
          let val1 = section?.section_data?.[fieldLabel];
          if (val1 !== undefined) {
            if (stringInputTypes?.includes(x?.input_type)) {
              let stringVal = "";
              if (val1 && !Array.isArray(val1) && typeof val1 === "object") {
                stringVal = val1?.name || "";
              } else {
                stringVal = val1 || "";
              }
              foundResult = handleStringCondition(stringVal, x?.operator, x?.value1);
            } else if (numberInputTypes.includes(x?.input_type)) {
              let numberVal = (val) => parseFloat(val) || 0;
              foundResult = handleNumberCondition(numberVal(val1), x?.operator, numberVal(x?.value1), numberVal(x?.value2));
            } else if (dateInputTypes.includes(x?.input_type)) {
              let stringVal = (val) => val || "";
              foundResult = handleNumberCondition(stringVal(val1), x?.operator, stringVal(x?.value1), stringVal(x?.value2));
            }
            break;
          }
        }
        return foundResult || false;
      });
      isTrue = evaluateLogicalExpression(buildNestedPattern(resultArray?.map(x => ({ id: x })), data?.relationships));
    } else {
      isTrue = true;
    }
    return isTrue;
  });
}

let chartFormatDatas = (conditionValue = "", labels = [], values = [], inputType = "", chartType = "") => {
  let formatLabel = (key, groupBy) => {
    switch (groupBy) {
      case "day":
        return dayjs(key).format("DD MMM YYYY");
      case "month":
        return dayjs(key).format("MMMM YYYY");
      case "year":
        return key;
      default:
        throw new Error("Invalid groupBy value. Use 'day', 'month', or 'year'.");
    }
  };

  let generateAllKeys = (labels, groupBy) => {
    let startDate = dayjs.min(labels.map((d) => dayjs(d))).startOf(groupBy);
    let endDate = dayjs.max(labels.map((d) => dayjs(d))).startOf(groupBy);
    let keys = [];
    while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
      keys.push(startDate.format(groupBy === "day" ? "YYYY-MM-DD" : groupBy === "month" ? "YYYY-MM" : "YYYY"));
      startDate = startDate.add(1, groupBy);
    }
    return keys;
  };

  let prepareChartData = (labels, values, groupBy) => {
    let dataMap = labels.reduce((acc, date, index) => {
      let key = dayjs(date).format(groupBy === "day" ? "YYYY-MM-DD" : groupBy === "month" ? "YYYY-MM" : "YYYY");
      acc[key] = (acc[key] || 0) + values[index];
      return acc;
    }, {});

    return generateAllKeys(labels, groupBy).map((key) => ({
      label: formatLabel(key, groupBy),
      value: dataMap[key] || 0,
    }));
  };

  // || ["dount", "pie", "funnel"].includes(chartType)
  if (!["day", "month", "year"].includes(conditionValue)) {
    if (inputType === "date") {
      labels = labels?.map(date => dayjs(date).format("DD MMM YYYY"));
    }
    return { labels, values };
  }

  let chartData = prepareChartData(labels, values, conditionValue);
  return {
    labels: chartData.map((item) => item.label),
    values: chartData.map((item) => item.value),
  };
};

let getChangeHistory = (oldData, newData, userName) => {
  let changes = [];
  let formatValue = (value) => (!value ? "Blank Value" : value);
  oldData.forEach((oldSection) => {
    let newSection = newData?.find(
      (section) => section?.section_name === oldSection?.section_name
    );
    if (newSection) {
      Object.keys(oldSection.section_data || {}).forEach((field) => {
        let oldValue = formatValue(oldSection?.section_data?.[field]);
        let newValue = formatValue(newSection?.section_data?.[field]);
        if (Array.isArray(oldSection?.section_data?.[field]) && Array.isArray(newSection?.section_data?.[field])) {
          if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            changes.push(`${field?.replace(/_/g, " ")} was updated from ${oldValue?.map(item => item?.name).join(", ") || "Unknown"} to ${newValue?.map(item => item?.name).join(", ") || "Unknown"}`);
          }
        } else if (typeof oldSection?.section_data?.[field] === "object" && typeof newSection?.section_data?.[field] === "object") {
          if (JSON.stringify(oldSection.section_data[field]) !== JSON.stringify(newSection.section_data[field])) {
            changes.push(`${field?.replace(/_/g, " ")} was updated from ${oldValue?.name || "Unknown"} to ${newValue?.name || "Unknown"}`);
          }
        }
        else if (oldValue !== newValue) {
          changes.push(`${field?.replace(/_/g, " ")} was updated from ${oldValue} to ${newValue}`);
        }
      });
    }
  });
  return {
    user: userName,
    details: changes.join(", "),
  };
}

const getParticularsModuleFields = (sections = [], field = "") => {
  let data = null;
  sections.forEach((section) => {
    let droppedList = section?.dropped_list || {};
    if (droppedList && Object.keys(droppedList)?.length) {
      Object.values(droppedList).forEach((area) => {
        area.forEach((item) => {
          if (field === item?.extra_fields?.field_label) {
            data = item;
          }
        });
      });
    }
  });
  return data;
}

let getFieldString = (data, moduleData) => {
  return data?.map((section) => {
    let entries = Object.entries(section?.section_data || {}).map(([key, value]) => {
      let inputType = getParticularsModuleFields(moduleData?.fields?.sections, key?.replace(/_/g, " "));
      value = value === undefined ? "" : value;
      if (Array.isArray(value)) {
        let names = value.map((item) => item.name).join(", ");
        return `${key?.replace(/_/g, " ")} : ${names || "-"}`;
      } else if (typeof value === "object" && value !== null) {
        return `${key?.replace(/_/g, " ")} : ${value?.name || "-"}`;
      } else if (value && inputType?.input_type === "date" || value && inputType?.input_type === "datetime-local") {
        return dayjs(value).format("MMM D, YYYY hh:mm A");
      } else {
        return `${key?.replace(/_/g, " ")} : ${value || "-"}`;
      }
    });
    return entries.join("\n");
  }).join("\n");
};

let getFieldToData = (field = "", data = [], moduleData) => {
  let name = "";
  data?.forEach((section) => {
    Object.entries(section?.section_data || {}).forEach(([key, value]) => {
      value = value === undefined ? "" : value;
      let inputType = getParticularsModuleFields(moduleData?.fields?.sections, key?.replace(/_/g, " "));
      if (field === key) {
        if (Array.isArray(value)) {
          name = value.map((item) => item?.name).join(", ") || "-";
        } else if (typeof value === "object" && value !== null) {
          name = value?.name || "-";
        } else if (value && inputType?.input_type === "date" || value && inputType?.input_type === "datetime-local") {
          return dayjs(value).format("MMM D, YYYY hh:mm A");
        } else {
          name = value || "-";
        }
      }
    });
  });
  return name;
};

let getSubFormFieldToData = (field = "", data = []) => {
  let val = null;
  data?.forEach((section) => {
    Object.entries(section?.section_data || {}).forEach(([key, value]) => {
      value = value === undefined ? "" : value;
      if (field === key) {
        val = value || null;
      }
    });
  });
  return val;
};

const logSectionNameChange = (oldData, newData, userName) => {
  try {
    if (!oldData || !newData) return [];
    let auditLogData = [];

    if (oldData.plural_form !== newData.plural_form) {
      auditLogData.push({
        msg: `Updated a module named ${newData.plural_form} by ${userName}`,
      });
    }

    if (oldData.sections && newData.sections) {
      oldData.sections.forEach((oldSection) => {
        const newSection = newData.sections.find(
          (newSec) => newSec.id === oldSection.id
        );

        if (oldSection.unique_name === "subform") {
          if (!newSection) {
            auditLogData.push({
              msg: `Removed a subform named ${oldSection.section_name} in the ${oldData.plural_form} Module`,
            });
          } else if (oldSection.section_name !== newSection.section_name) {
            auditLogData.push({
              msg: `Updated a subform named ${newSection.section_name} in the ${newData.plural_form} Module`,
            });
          }
        } else {
          if (!newSection) {
            auditLogData.push({
              msg: `Deleted a Section named ${oldSection.section_name} in the ${oldData.plural_form} Module`,
            });
          } else if (oldSection.section_name !== newSection.section_name) {
            auditLogData.push({
              msg: `Updated a section named ${newSection.section_name} in the ${newData.plural_form} Module`,
            });
          }
        }
      });

      newData.sections.forEach((newSection) => {
        const oldSection = oldData.sections.find(
          (oldSec) => oldSec.id === newSection.id
        );

        if (newSection.unique_name === "subform") {
          if (!oldSection) {
            auditLogData.push({
              msg: `Created a subform named ${newSection.section_name} in the ${newData.plural_form} Module`,
            });
          }
        } else {
          if (!oldSection) {
            auditLogData.push({
              msg: `Created a section ${newSection.section_name} in the ${newData.plural_form} Module`,
            });
          }
        }
      });
    }

    if (oldData.sections && newData.sections) {
      oldData.sections.forEach((oldSection) => {
        const newSection = newData.sections.find(
          (newSec) => newSec.id === oldSection.id
        );

        if (newSection) {
          if (
            Array.isArray(oldSection.selected_list) &&
            Array.isArray(newSection.selected_list)
          ) {
            const oldSubformFields = oldSection.selected_list;
            const newSubformFields = newSection.selected_list;

            newSubformFields.forEach((newField) => {
              const oldField = oldSubformFields.find(
                (field) => field.id === newField.id
              );

              if (!oldField) {
                const fieldLabel =
                  newField.extra_fields?.field_label || "Unknown Field";
                auditLogData.push({
                  msg: `Created Custom Field named ${fieldLabel} in the ${oldSection.section_name} subform of the ${newData.plural_form} module`,
                });
              }
            });

            oldSubformFields.forEach((oldField) => {
              const newField = newSubformFields.find(
                (field) => field.id === oldField.id
              );

              if (!newField) {
                const fieldLabel = oldField.extra_fields?.field_label;
                auditLogData.push({
                  msg: `Removed Custom Field named ${fieldLabel} in the ${oldSection.section_name} subform of the ${oldData.plural_form} module`,
                });
              }
            });

            oldSubformFields.forEach((oldField) => {
              const newField = newSubformFields.find(
                (field) => field.id === oldField.id
              );

              if (newField && oldField) {
                const oldFieldLabel = oldField.extra_fields?.field_label;
                const newFieldLabel = newField.extra_fields?.field_label;

                if (oldFieldLabel !== newFieldLabel) {
                  auditLogData.push({
                    msg: `Updated Custom Field label from ${oldFieldLabel} to ${newFieldLabel} in the ${oldSection.section_name} subform of the ${newData.plural_form} module`,
                  });
                }

                const { field_label: oldFieldLabelToExclude, ...oldExtra } =
                  oldField.extra_fields;
                const { field_label: newFieldLabelToExclude, ...newExtra } =
                  newField.extra_fields;

                if (JSON.stringify(oldExtra) !== JSON.stringify(newExtra)) {
                  auditLogData.push({
                    msg: `Updated Custom Field properties for ${newFieldLabel} in the ${oldSection.section_name} subform of the ${newData.plural_form} module`,
                  });
                }
              }
            });
          }

          if (oldSection.dropped_list && newSection.dropped_list) {
            Object.keys(newSection.dropped_list).forEach((dropAreaKey) => {
              const oldFields = oldSection.dropped_list[dropAreaKey] || [];
              const newFields = newSection.dropped_list[dropAreaKey] || [];

              newFields.forEach((newField) => {
                const oldField = oldFields.find(
                  (field) => field.id === newField.id
                );

                if (!oldField) {
                  const fieldLabel =
                    newField.extra_fields?.field_label || "Unknown Field";
                  auditLogData.push({
                    msg: `Created Custom Field named ${fieldLabel} in the ${oldSection.section_name} of the ${newData.plural_form} module`,
                  });
                }
              });

              oldFields.forEach((oldField) => {
                const newField = newFields.find(
                  (field) => field.id === oldField.id
                );

                if (!newField) {
                  const fieldLabel = oldField.extra_fields?.field_label;
                  auditLogData.push({
                    msg: `Removed Custom Field named ${fieldLabel} in the ${oldSection.section_name} of the ${oldData.plural_form} module`,
                  });
                }
              });

              oldFields.forEach((oldField) => {
                const newField = newFields.find(
                  (field) => field.id === oldField.id
                );

                if (newField && oldField) {
                  const oldFieldLabel = oldField.extra_fields?.field_label;
                  const newFieldLabel = newField.extra_fields?.field_label;

                  if (oldFieldLabel !== newFieldLabel) {
                    auditLogData.push({
                      msg: `Updated Custom Field label from ${oldFieldLabel} to ${newFieldLabel} in the ${oldSection.section_name} of the ${newData.plural_form} module`,
                    });
                  }

                  const { field_label: oldFieldLabelToExclude, ...oldExtra } =
                    oldField.extra_fields;
                  const { field_label: newFieldLabelToExclude, ...newExtra } =
                    newField.extra_fields;

                  if (JSON.stringify(oldExtra) !== JSON.stringify(newExtra)) {
                    auditLogData.push({
                      msg: `Updated Custom Field properties for ${newFieldLabel} in the ${oldSection.section_name} of the ${newData.plural_form} module`,
                    });
                  }
                }
              });
            });
          }
        }
      });
    }
    return auditLogData;
  } catch (error) {
    return [];
  }
};
const generateAuditLogMessage = (oldData, newData, moduleName, isCreate) => {
  try {
    if (isCreate && oldData === null) {
      const sectionKeys = Object.keys(newData[0].section_data);
      const firstSectionKey = sectionKeys[0];
      const secondSectionKey = sectionKeys[1];
      const keyToUse =
        firstSectionKey === "Untitled_Owner"
          ? secondSectionKey
          : firstSectionKey;
      const value = newData[0].section_data[keyToUse];
      return `Added a ${moduleName} named ${typeof value === "object" ? value.name || value._id : value}`;
    }
    const sectionKeys = Object.keys(oldData[0].section_data);
    const firstSectionKey = sectionKeys[0];
    const secondSectionKey = sectionKeys[1];
    const keyToUse =
      firstSectionKey === "Untitled_Owner" ? secondSectionKey : firstSectionKey;
    if (isCreate) {
      const value = newData[0].section_data[keyToUse];
      return `Added a ${moduleName} named ${typeof value === "object" ? value.name || value._id : value}`;
    } else {
      let hasChanges = false;
      oldData.forEach((oldSection, index) => {
        const newSection = newData[index];
        Object.keys(oldSection.section_data).forEach((key) => {
          if (oldSection.section_data[key] !== newSection.section_data[key]) {
            hasChanges = true;
          }
        });
      });
      if (hasChanges) {
        const value = newData[0].section_data[keyToUse];
        return `Updated a ${moduleName} named ${typeof value === "object" ? value.name || value._id : value}`;
      } else {
        return "";
      }
    }
  } catch (err) { return "" }
};

const logChanges = (oldData, newData, profilesList = null, roleList = null) => {
  try {
    const changes = [];
    const getProfileNameById = (profileId) => {
      if (profilesList) {
        const profile = profilesList.find((p) => p._id === profileId);
        if (profile) {
          return profile.profile_name;
        } else {
          return profileId;
        }
      } else {
        return profileId;
      }
    };

    const getRoleNameById = (roleId) => {
      if (roleList) {
        const role = roleList.find((r) => r._id === roleId);
        if (role) {
          return role.role_name;
        } else {
          return roleId;
        }
      } else {
        return roleId;
      }
    };

    Object.keys(newData).forEach((key) => {
      if (key === "audit_log") return;

      let oldValue = Array.isArray(oldData) ? oldData[0][key] : oldData[key];
      let newValue = newData[key];

      if (oldValue !== newValue) {
        if (key === "Profile") {
          const oldProfileName = oldValue.profile_name || oldValue;
          const newProfileName = getProfileNameById(newValue);
          if (oldProfileName !== newProfileName) {
            changes.push(
              `Profile changed from ${oldProfileName} to ${newProfileName}`
            );
          }
        } else if (key === "role") {
          const oldRoleName = oldValue.role_name || oldValue;
          const newRoleName = getRoleNameById(newValue);
          if (oldRoleName !== newRoleName) {
            changes.push(`Role changed from ${oldRoleName} to ${newRoleName}`);
          }
        } else {
          const oldValueDisplay = oldValue === '' || oldValue === ' ' || oldValue === undefined? 'Blank value' : oldValue;
          const newValueDisplay = newValue === '' || newValue === ' ' || newValue === undefined? 'Blank value' : newValue;
          changes.push(`${key} changed from ${oldValueDisplay} to ${newValueDisplay}`);
        }
      }
    });

    return changes.join(", ");
  } catch (err) {
    return "";
  }
};
const logAuditLog = (oldData, newData) => {
  try {
    const auditLog = [];

    if (oldData.shift_name !== newData.shift_name) {
      auditLog.push({
        msg: `Renamed a shift from ${oldData.shift_name} to ${newData.shift_name}`,
      });
    }

    if (oldData.timeZone !== newData.timeZone) {
      auditLog.push({
        msg: `Updated the timezone for shift ${newData.shift_name} from ${oldData.timeZone} to ${newData.timeZone}`,
      });
    }

    if (
      JSON.stringify(oldData.shiftHoursManagementData) !==
      JSON.stringify(newData.shiftHoursManagementData)
    ) {
      auditLog.push({
        msg: `Updated Shift hours for shift ${newData.shift_name}`,
      });
    }

    if (
      JSON.stringify(
        oldData.shiftHoursManagementData.breakHours1ManagementData
      ) !==
      JSON.stringify(newData.shiftHoursManagementData.breakHours1ManagementData)
    ) {
      auditLog.push({
        msg: `Updated Break hour
        
        s 1 for shift ${newData.shift_name}`,
      });
    }

    if (
      JSON.stringify(
        oldData.shiftHoursManagementData.breakHours2ManagementData
      ) !==
      JSON.stringify(newData.shiftHoursManagementData.breakHours2ManagementData)
    ) {
      auditLog.push({
        msg: `Updated Break hours 2 for shift ${newData.shift_name}`,
      });
    }

    if (
      JSON.stringify(oldData.assignedUsers) !==
      JSON.stringify(newData.assignedUsers)
    ) {
      auditLog.push({
        msg: `Updated assigned users for shift ${newData.shift_name}`,
      });
    }

    return auditLog;
  } catch (err) {
    return [];
  }
};

  const logUpdatedFields = (oldData, newData, type) => {
    try {
      const auditLog = {};

      if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
        switch (type) {
          case "fiscalYear":
            if (newData.fiscalYearType === "custom") {
              if (
                JSON.stringify(oldData.custom) !==
                JSON.stringify(newData.custom)
              ) {
                auditLog.msg = "Updated custom fiscal year";
              }
            } else if (newData.fiscalYearType === "standard") {
              if (
                JSON.stringify(oldData.standard) !==
                JSON.stringify(newData.standard)
              ) {
                auditLog.msg = "Updated standard fiscal year";
              }
            }
            break;
          case "businessHour":
            if (oldData.businessHours !== newData.businessHours) {
              auditLog.msg = `Updated business hours timing from ${oldData.businessHours} to ${newData.businessHours}`;
            }
            if (
              JSON.stringify(oldData.businessHoursManagementData) !==
              JSON.stringify(newData.businessHoursManagementData)
            ) {
              const oldBusinessDays =
                oldData.businessHoursManagementData.businessHours5Days.businessDays.join(
                  ", "
                );
              const newBusinessDays =
                newData.businessHoursManagementData.businessHours5Days.businessDays.join(
                  ", "
                );
              auditLog.msg = `Updated business days from ${oldBusinessDays} to ${newBusinessDays}`;
            }
            //  if (JSON.stringify(oldData.businessHoursManagementData) !== JSON.stringify(newData.businessHoursManagementData)) {
            //    auditLog.msg = 'Updated Business Days';
            //  }
            if (oldData.weeksStartsOn !== newData.weeksStartsOn) {
              auditLog.msg = `Updated weeksStartsOn from ${oldData.weeksStartsOn} to ${newData.weeksStartsOn}`;
            }
            break;
        }
      }

      return auditLog;
    } catch (err) {
      return {};
    }
  };

  const currencyLog = (oldData, newData, type) => {
    try {
      const oldDataArray = Array.isArray(oldData) ? oldData : [oldData];
      const newDataObject = { ...newData };

      if (oldDataArray.length > 0) {
        const auditLog = {};
        Object.keys(newDataObject).forEach((key) => {
          const oldValue = oldDataArray.find(
            (item) => item._id === newDataObject._id
          )?.[key];
          if (oldValue !== newDataObject[key]) {
            if (type !== "Multi Currency") {
              auditLog.msg = `Updated ${type} ${newDataObject.currency_name}`;
            }
          }
        });

        const oldSubCurrencyList = oldDataArray[0].subCurrencyList;
        const newSubCurrencyList = newDataObject.subCurrencyList;

        if (oldSubCurrencyList && newSubCurrencyList) {
          oldSubCurrencyList.forEach((oldSubCurrency, index) => {
            const newSubCurrency = newSubCurrencyList[index];
            if (
              oldSubCurrency._id === newSubCurrency._id &&
              oldSubCurrency.isActive !== newSubCurrency.isActive
            ) {
              if (newSubCurrency.isActive) {
                auditLog.msg = `Activated ${type} ${newSubCurrency.currency_name}`;
              } else {
                auditLog.msg = `Deactivated ${type} ${newSubCurrency.currency_name}`;
              }
            }
          });
        }
        return auditLog;
      }
    } catch (err) {
      return {};
    }
  };

  const workflowLog = (oldData, newData) => {
    try {
      const changes = [];

      if (
        oldData.rules.workflow_base_type !== newData.rules.workflow_base_type
      ) {
        changes.push({
          msg: `Updated an execution condition from ${oldData.rules.workflow_base_type} to ${newData.rules.workflow_base_type} in workflow rule named ${newData.ruleName} for ${oldData.moduleId.name} module`,
        });
      }

      if (
        JSON.stringify(oldData.approvalUserList) !==
        JSON.stringify(newData.approvalUserList)
      ) {
        changes.push({
          msg: `Updated an approval user list for workflow rule named ${newData.ruleName} for ${oldData.moduleId.name} module`,
        });

        newData.approvalUserList.forEach((newApprovalUser, index) => {
          const oldApprovalUser = oldData.approvalUserList[index];

          if (!oldApprovalUser) {
            changes.push({
              msg: `Created new approval user with Sections: ${newApprovalUser.approvalSections
                .map((section) => section.label)
                .join(", ")}, Primary Field: ${
                newApprovalUser.primaryField
              }, Status: ${newApprovalUser.status}`,
            });
          } else if (
            JSON.stringify(oldApprovalUser) !== JSON.stringify(newApprovalUser)
          ) {
            changes.push({
              msg: `Updated approval user with Sections: ${oldApprovalUser.approvalSections
                .map((section) => section.label)
                .join(", ")}, Primary Field: ${
                oldApprovalUser.primaryField
              }, Status: ${
                oldApprovalUser.status
              } to Sections:${newApprovalUser.approvalSections
                .map((section) => section.label)
                .join(", ")}, Primary Field:${
                newApprovalUser.primaryField
              }, Status:${newApprovalUser.status}`,
            });
          }
        });

        oldData.approvalUserList.forEach((oldApprovalUser, index) => {
          const newApprovalUser = newData.approvalUserList[index];

          if (!newApprovalUser) {
            changes.push({
              msg: `Deleted approval user with Sections: ${oldApprovalUser.approvalSections
                .map((section) => section.label)
                .join(", ")}, Primary Field: ${
                oldApprovalUser.primaryField
              }, Status: ${oldApprovalUser.status}`,
            });
          }
        });
      }

      if (
        JSON.stringify(oldData.rules.conditions) !==
        JSON.stringify(newData.rules.conditions)
      ) {
        changes.push({
          msg: `Updated an execution condition of workflow rule named ${newData.ruleName} for ${oldData.moduleId.name} module`,
        });

        // newData.rules.conditions.forEach((newCondition, index) => {
        //   const oldCondition = oldData.rules.conditions[index];

        //   if (!oldCondition) {
        //     changes.push({ msg: `Created new execution with Field: ${newCondition.field}, Condition: ${newCondition.condition}, Value: ${newCondition.value}` });
        //   } else if (JSON.stringify(oldCondition) !== JSON.stringify(newCondition)) {
        //     changes.push({ msg: `Updated execution with Field: ${oldCondition.field}, Condition: ${oldCondition.condition}, Value: ${oldCondition.value} to Field: ${newCondition.field}, Condition: ${newCondition.condition}, Value: ${newCondition.value}` });
        //   }
        // });

        // oldData.rules.conditions.forEach((oldCondition, index) => {
        //   const newCondition = newData.rules.conditions[index];

        //   if (!newCondition) {
        //     changes.push({ msg: `Deleted execution with Field: ${oldCondition.field} Condition:${oldCondition.condition} Value: ${oldCondition.value}` });
        //   }
        // });
      }

      return changes;
    } catch (err) {
      return [];
    }
  };


let getNavigationUrl = (path = "") => {
  let accessUrl = getItem('user')?.organization?.access_url;
  if (!accessUrl) {
    localStorage.clear();
    return <Navigate to="/auth/login" />
  }
  if (!path) {
    return accessUrl;
  }
  return `/${accessUrl}/${path}`?.replace(/\/\//g, "/");;
}

let generateRepeatDates = (data) => {
  let {dueDate = "", repeatType = "", frequencyType = "", endOption = "", times = 1, endDate = "", interval = 1, selectedWeeklyDayNames = [],
  selectedRadioOption = 1, selectedDay = 1, selectedDayName = "", selectedOptionName = "", selectedMonthName = ""} = data  || {};
  try {
      if (dueDate) {
          let startDate = dayjs(dueDate);
          let repeatDatesFormat = [];
          let iterations = 365;
          if (repeatType === "Custom" && frequencyType === "Weekly") {
              iterations = 105;
          } else if (repeatType === "Weekly") {
              iterations = 52;
          } else if (repeatType === "Custom" && frequencyType === "Monthly") {
              iterations = 30;
          } else if (repeatType === "Monthly") {
              iterations = 12;
          } else if (repeatType === "Yearly" || (repeatType === "Custom" && frequencyType === "Yearly")) {
              iterations = 10;
          }
          if (endOption === "After") iterations = times;
          let addTime = (unit, value) => {
              let nextDate = startDate.add(value, unit);
              repeatDatesFormat.push(nextDate.format("YYYY-MM-DD"));
          };
          let repeatLogic = (unit) => {
              let i = 0;
              let maxIterations = iterations;
              while (i < iterations) {
                  try {
                      addTime(unit, i);
                      i++;
                      if (i >= maxIterations || (endOption === "On" && endDate && dayjs(repeatDatesFormat[repeatDatesFormat.length - 1]).isAfter(dayjs(endDate)))) break;
                  } catch (e) {
                      break;
                  }
              }
          };
          if (repeatType === "Daily") repeatLogic("day");
          if (repeatType === "Weekly") repeatLogic("week");
          if (repeatType === "Monthly") repeatLogic("month");
          if (repeatType === "Yearly") repeatLogic("year");
          if (repeatType === "Custom" && frequencyType === "Daily") {
              let intervalValue = interval || 1;
              let i = 0;
              let maxIterations = iterations;
              while (i < iterations) {
                  try {
                      let nextDate = dayjs(startDate).add(i * intervalValue, "day");
                      repeatDatesFormat.push(nextDate.format("YYYY-MM-DD"));
                      i++;
                      if (i >= maxIterations || (endOption === "On" && endDate && nextDate.isAfter(dayjs(endDate)))) {
                          break;
                      }
                  } catch (e) {
                      break;
                  }
              }
          }                
          if (repeatType === "Custom" && frequencyType === "Weekly") {
              let intervalValue = interval || 1;
              let maxIterations = iterations;
              let selectedDays = (selectedWeeklyDayNames || []).map(x => x.toLowerCase());
              let currentDate = dayjs(startDate);
              let i = 0;
              while (i < iterations) {
                  try {
                      for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
                          let checkDate = currentDate.add(dayOffset, "day");
                          if (selectedDays.includes(checkDate.format("dddd").toLowerCase())) {
                              repeatDatesFormat.push(checkDate.format("YYYY-MM-DD"));
                              i++;
                              if (i >= maxIterations || (endOption === "On" && endDate && checkDate.isAfter(dayjs(endDate)))) {
                                  break;
                              }
                          }
                      }
                      currentDate = currentDate.add(intervalValue, "week");
                  } catch (e) {
                      break;
                  }
              }
          }
          if (repeatType === "Custom" && frequencyType === "Monthly") {
              let intervalValue = interval || 1;
              let i = 0;
              let currentDate = dayjs(startDate);
              let getTargetDate = () => {
                  if (selectedRadioOption === 1) {
                      let date = currentDate.date(selectedDay);
                      return date.isValid() && date.month() === currentDate.month() ? date : currentDate.endOf("month");
                  } else if (selectedRadioOption === 2) {
                      let weekOffsets = { First: 0, Second: 7, Third: 14, Fourth: 21, Last: 28 };
                      let targetDayIndex = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(selectedDayName);
                      if (targetDayIndex === -1) return null;
                      let targetDate = currentDate.startOf("month").day(targetDayIndex);
                      if (targetDate.isBefore(currentDate.startOf("month"))) {
                          targetDate = targetDate.add(7, "days");
                      }
                      let weekOffset = weekOffsets[selectedOptionName];
                      targetDate = targetDate.add(weekOffset, "days");
                      return targetDate.month() === currentDate.month() ? targetDate : targetDate.subtract(7, "days");
                  }
                  return null;
              };
              while (i < iterations) {
                  let nextDate = getTargetDate();
                  if (!nextDate || !nextDate.isValid() || nextDate.month() !== currentDate.month()) {
                      break;
                  }
                  if (nextDate.isBefore(startDate, 'day')) {
                      currentDate = currentDate.add(intervalValue, "month");
                      iterations++;
                      i++;
                      continue;
                  }
                  repeatDatesFormat.push(nextDate.format("YYYY-MM-DD"));
                  i++;
                  if (endOption === "On" && endDate && nextDate.isAfter(dayjs(endDate))) {
                      break;
                  }
                  currentDate = currentDate.add(intervalValue, "month");
              }                    
          }
          if (repeatType === "Custom" && frequencyType === "Yearly") {
              let intervalValue = interval || 1;
              let i = 0;
              let currentDate = dayjs(startDate);
              let getTargetDate = () => {
                  if (selectedRadioOption === 1) {
                      let monthIndex = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].indexOf(selectedMonthName);
                      if (monthIndex === -1) return null;
                      let targetDate = currentDate.month(monthIndex).date(selectedDay);
                      return targetDate.isValid() && targetDate.year() === currentDate.year() ? targetDate : null;
                  } else if (selectedRadioOption === 2) {
                      let monthIndex = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].indexOf(selectedMonthName);
                      if (monthIndex === -1) return null;
                      let weekOffsets = { First: 0, Second: 7, Third: 14, Fourth: 21, Last: 28 };
                      let targetDayIndex = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(selectedDayName);
                      if (targetDayIndex === -1) return null;
                      let targetDate = currentDate.startOf("year").month(monthIndex).startOf("month").day(targetDayIndex);
                      if (targetDate.isBefore(currentDate.startOf("year").month(monthIndex))) {
                          targetDate = targetDate.add(7, "days");
                      }
                      let weekOffset = weekOffsets[selectedOptionName];
                      targetDate = targetDate.add(weekOffset, "days");
                      return targetDate.month() === monthIndex ? targetDate : targetDate.subtract(7, "days");
                  }
                  return null;
              };
              while (i < iterations) {
                  let nextDate = getTargetDate();
                  if (!nextDate || !nextDate.isValid() || nextDate.year() !== currentDate.year()) {
                      break;
                  }
                  if (nextDate.isBefore(startDate, 'day')) {
                      currentDate = currentDate.add(intervalValue, "year");
                      iterations++;
                      i++;
                      continue;
                  }
                  repeatDatesFormat.push(nextDate.format("YYYY-MM-DD"));
                  i++;
                  if (endOption === "On" && endDate && nextDate.isAfter(dayjs(endDate))) {
                      break;
                  }
                  currentDate = currentDate.add(intervalValue, "year");
              }
          }                
          return repeatDatesFormat;
      }
      return [];
  } catch (e) {
      return [];
  }
};

export const Utils = {
  idGenerator,
  removeAtIndex,
  insertAtIndex,
  arrayMove,
  formModuleDatas,
  formModuleStoreData,
  sectionFormat,
  subFormFormat,
  propertyModal,
  dropDownList,
  exportData,
  urlPattern,
  getModuleFields,
  getFilteredRecords,
  removeDuplicatesAndTotalCount,
  handleStringCondition,
  handleNumberCondition,
  handleDateCondition,
  buildNestedPattern,
  validatePattern,
  validateOrder,
  evaluateLogicalExpression,
  filteredDataGet,
  handleFieldLabelUpdate,
  removeDuplicatesAndTotalCount,
  chartFormatDatas,
  getChangeHistory,
  getFieldString,
  defaultSubFormNameUpdate,
  getDefaultSubFormNameField,
  getFieldToData,
  getParticularsModuleFields,
  currencySymbols,
  getNavigationUrl,
  getSubFormFieldToData,
  logSectionNameChange,
  generateAuditLogMessage,
  logChanges,
  logAuditLog,
  logUpdatedFields,
  currencyLog,
  workflowLog,
  stringInputTypes,
  numberInputTypes,
  dateInputTypes,
  generateRepeatDates
}