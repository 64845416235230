import React, { useRef } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Helpers } from "../../Helpers";
import { Utils } from '../../Utils';

const SidebarMenus = ({ field }) => {
    const id = useRef(Utils.idGenerator());
    const { attributes, listeners, setNodeRef } = useDraggable({
        id: id.current,
        data: {
            field,
            fromSidebar: true
        }
    });

    return (
        <div ref={setNodeRef} {...listeners} {...attributes} className={(Helpers.isUndefined(field.column_class_name) ? 'col-md-6' : field.column_class_name) + ` p-1`} key={field.id}>
            <div className="btn btn-outline-dark">
                <img className="me-2" src={field?.image_url} alt="sigle-line-icon" width="20" height="20" />
                {field.label}
            </div>
        </div>
    );
};

export default SidebarMenus;
