import React, { useEffect, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllDashboardComponentLayoutStart, getAllSubFormModulesStart, updateDashboardComponentLayoutStart } from "../../Store/Slices/dashboardComponentsSlice";
import { DashboardComponents } from "../../Components/DashboardComponents";
import { useNavigate } from "react-router-dom";
import { getApprovalStart } from "../../Store/Slices/workflowSlice";
import Loader from "../../Components/Loader";
import { Utils } from "../../Utils";
const ReactGridLayout = WidthProvider(RGL);
const Dashboard = () => {
    let dispatch = useDispatch();
    let subModuleData = useSelector((state) => state?.dashboardComponents);
    let [dashboardLayoutComponentDatas, setDashboardLayoutComponentDatas] = useState([]);
    let [selectedLayoutIndex, setSelectedLayoutIndex] = useState(0);
    let [layoutPostion, setLayoutPostion] = useState([]);
    let [resize, setResize] = useState(false);
    let workflowList = useSelector((state) => state?.workflow);
    let navigate = useNavigate();

    useEffect(() => {
        let components = dashboardLayoutComponentDatas?.[selectedLayoutIndex]?.components;
        if (components?.length > 0) {
            setLayoutPostion(components?.map(item => ({
                ...item?.position
            })) || []);
            if (components?.length > 0) {
                let uniqueModuleIds = [...new Set(components?.map(item => item.moduleId))];
                uniqueModuleIds?.forEach((moduleId) => {
                    let hasModuleId = subModuleData?.subModuleData?.hasOwnProperty(moduleId);
                    if (!hasModuleId && moduleId) {
                        dispatch(getAllSubFormModulesStart(moduleId));
                    }
                });
            }
        }
    }, [selectedLayoutIndex, dashboardLayoutComponentDatas]);

    useEffect(() => {
        if (subModuleData?.reloadGetAllModuleLayoutApi) {
            dispatch(getAllDashboardComponentLayoutStart());
        }
    }, [subModuleData?.reloadGetAllModuleLayoutApi]);

    useEffect(() => {
        if (!subModuleData?.layouts?.length) {
            dispatch(getAllDashboardComponentLayoutStart());
        }
        dispatch(getApprovalStart());
        // return () => {
        //     dispatch(reState());
        // }
    }, []);

    useEffect(() => {
        if (subModuleData?.layouts?.length > 0) {
            setDashboardLayoutComponentDatas(subModuleData?.layouts?.filter(x => x?.isActive));
        }
    }, [subModuleData?.layouts]);

    let updateDashboard = () => {
        let layoutData = dashboardLayoutComponentDatas?.[selectedLayoutIndex];
        let updatedData = layoutData?.components?.map(item => {
            let matchingLayout = layoutPostion.find(layoutItem => layoutItem.i === item?.position?.i);
            if (matchingLayout) {
                return {
                    ...item,
                    position: { ...matchingLayout }
                };
            }
            return item;
        });
        let obj = {
            id: layoutData._id,
            payload: {
                ...layoutData,
                components: updatedData,
            }
        }
        dispatch(updateDashboardComponentLayoutStart(obj));
        setResize(false);
    }

    let onLayoutChange = (layout2) => {
        setLayoutPostion(layout2);
    }

    return (
        <React.Fragment>
            <Loader isVisible={subModuleData?.isLoading} />
            {/* <!-- Main panel --> */}
            <div className="dashboard">
                <main className="py-md-3 content-section">
                    {/* <div className="container-fluid mt-3 mt-md-0">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-3">
                            <div className="col">
                                <div className="card rounded-4 border-0" onClick={() => navigate(`/dashboard/view/inbox`)}>
                                    <div className="card-body">
                                        <div className="sub-title">Inbox</div>
                                        <div className="count-num fw-bold">{workflowList?.data?.length}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card rounded-4 border-0">
                                    <div className="card-body">
                                        <div className="sub-title">My Untouched Deals</div>
                                        <div className="count-num fw-bold">10</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card rounded-4 border-0">
                                    <div className="card-body">
                                        <div className="sub-title">My Calls Today</div>
                                        <div className="count-num fw-bold">10</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card rounded-4 border-0">
                                    <div className="card-body">
                                        <div className="sub-title">My Leads</div>
                                        <div className="count-num fw-bold">10</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="container-fluid mt-3 mt-md-0">
                        {dashboardLayoutComponentDatas?.length > 0 && (
                            <div className="d-flex justify-content-between align-items-center px-3">
                                <div>
                                    <span className="fs-5">Welcome {dashboardLayoutComponentDatas?.[selectedLayoutIndex]?.layout_name}</span>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    {resize ? (
                                        <>
                                            <>
                                                <button type="button" className="btn btn-light btn-sm me-2" onClick={() => setResize(false)}>Cancel</button>
                                                <button type="button" className="btn btn-primary btn-sm me-2" onClick={updateDashboard}>Save</button>
                                            </>
                                        </>) :
                                        (
                                            <>
                                                <div className="dropdown">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-primary me-2px rounded-start btn-digy-dark">
                                                            {dashboardLayoutComponentDatas?.[selectedLayoutIndex]?.layout_name}
                                                        </button>
                                                        <button type="button" className="btn btn-primary btn-digy-dark dropdown-toggle dropdown-toggle-split rounded-end" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="fa fa-caret-down"></i>
                                                            <span className="visually-hidden">Toggle Dropdown</span>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            {dashboardLayoutComponentDatas?.length > 0 && (
                                                                dashboardLayoutComponentDatas?.map((x, index) => (
                                                                    <li key={index} onClick={() => setSelectedLayoutIndex(index)}><a className="dropdown-item">{x?.layout_name}</a></li>
                                                                ))
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="dropdown">
                                                    <button className="btn btn-outline-secondary dropdown-toggle rounded-1 py-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item" onClick={() => setResize(true)}>Reorder</li>
                                                    </ul>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        )}
                        <div className={`${resize ? 'bg-image-box' : ''} grid-main-container p-0`}>
                            <ReactGridLayout
                                className={`${resize ? 'bg-image-box' : ''} grid-container p-0`}
                                layout={layoutPostion || []}
                                isResizable={resize}
                                isDraggable={resize}
                                rowHeight={30}
                                preventCollision={false}
                                compactType="vertical"
                                draggableCancel='.top-right-menu, .dropdown-menu, .dropdown ul'
                                cols={12}
                                onLayoutChange={onLayoutChange}
                            >
                                {dashboardLayoutComponentDatas?.[selectedLayoutIndex]?.components?.length > 0 && (
                                    dashboardLayoutComponentDatas?.[selectedLayoutIndex]?.components.map((x, index) => {
                                        if (x?.company_name === "Inbox" && x?.default) {
                                            return (<div key={x.position.i} className="grid-item" onClick={() => ((!resize && workflowList?.data?.length > 0) ? navigate(Utils.getNavigationUrl(`dashboard/view/inbox`)) : {})}>
                                                <div className="content card">
                                                    <h5 className="card-title fs-14 ps-3 text-start fw-semibold">Inbox</h5>
                                                    <div className="card-body p-0 overflow-auto">
                                                        {workflowList?.data?.length > 0 ? <div className="count-num fw-bold">{workflowList?.data?.length}</div> : <div className='h-100 fw-bold align-self-center align-center py-3'>No Data Found</div> }
                                                    </div>
                                                </div>
                                            </div>)
                                            
                                        } else {
                                            let ComponentToRender = DashboardComponents[x?.modal_name];
                                            return ComponentToRender ? (
                                                <div key={x.position.i} className="grid-item">
                                                     <div className="content card">
                                                        <h5 className="card-title fs-14 ps-3 text-start fw-semibold">{x?.company_name}</h5>
                                                        <div className="card-body p-0 overflow-auto">
                                                            <ComponentToRender data={x} isStatic={false} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null;
                                        }
                                    })
                                )}
                            </ReactGridLayout>
                        </div>
                    </div>
                </main>
            </div>
        </React.Fragment>
    )
}
export default Dashboard;