import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import "./css/PickList.css";

function PicklistHistoryTracking({ show, handleClose, moduleStoreData }) {
    let [realtedFieldLabel, setRelateFieldLabel] = useState('Pick List 1 History');
    let [selectedOptions, setSelectedOptions] = useState([]);
    let [options, setOptions] = useState([]);

    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;
        setSelectedOptions((prev) => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter((option) => option !== value);
            }
        });
    };

    useEffect(() => {
        if (moduleStoreData) {
            let fieldLabels = [];
            moduleStoreData?.sections.forEach(section => {
                Object.entries(section?.dropped_list || {}).forEach(([key, value]) => {
                    value?.forEach(item => {
                        if (item?.extra_fields?.field_label) {
                            fieldLabels.push(item?.extra_fields?.field_label);
                        }
                    });
                });
            });
            console.log(fieldLabels)
            setOptions(fieldLabels);
        }
    }, []);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Picklist History Tracking</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="mb-3">
                        <label htmlFor="relatedFieldInput" className="form-label">Related List Name *</label>
                        <input
                            type="text"
                            className="form-control"
                            id="relatedFieldInput"
                            value={realtedFieldLabel} onChange={(e) => setRelateFieldLabel(e.target.value)} />
                    </div>

                    <div className="mb-3">
                        <span>Related List Columns</span>
                        <div className="pick-list-box bg-light p-3">
                            <div className="row">
                                {options.map((option, index) => (
                                    <div className="col-md-6" key={index}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`option-${index}`}
                                                value={option}
                                                onChange={handleCheckboxChange}
                                                checked={selectedOptions.includes(option)}
                                            />
                                            <label className="form-check-label" htmlFor={`option-${index}`}>
                                                {option}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PicklistHistoryTracking;
