import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDispatch } from 'react-redux';
import { restrictToParentElement } from '@dnd-kit/modifiers';

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="d-flex align-items-center mb-2 border p-2" ref={setNodeRef} style={style} {...attributes}>
      <span className="me-3 cursor-grab" {...listeners}>
        <i className="fa fa-grip-vertical fs-5" aria-hidden="true"></i>
      </span>
      <Form.Check
        type="checkbox"
        label={props.header.label}
        checked={props.header.show}
        onChange={() => props.onChange(props.index)}
        className="flex-grow-1"
      />
    </div>
  );
}

function ManageColumn({ show, handleClose, getValue, setValue, data, createApi, updateApi, isNavbarChange = false }) {
  let [tempHeaders, setTempHeaders] = useState([]);
  let [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const modalBodyRef = useRef(null);
  const modalRef = useRef(null);
  useEffect(() => {
    setTempHeaders(getValue || []);
  }, [getValue]);

  const handleCheckboxChange = (index) => {
    let updatedHeaders = tempHeaders.map((header, i) =>
      i === index ? { ...header, show: !header.show } : header
    );
    setTempHeaders(updatedHeaders);
  };

  const handleSave = () => {
    if (!isNavbarChange) {
      setValue(tempHeaders);
      if (data?.tableColumnId?.length > 0) {
        let obj = {
          tableColumnId: data?.tableColumnId,
          payload: {
            moduleId: data?.moduleId,
            data: tempHeaders
          }
        }
        dispatch(updateApi(obj));
      } else {
        dispatch(createApi({
          moduleId: data?.moduleId,
          data: tempHeaders
        }));
      }
    } else {
      let datas = [...tempHeaders?.map(x => ({_id : x?._id, name : x?.label, show : x?.show}))];
      if(data?._id?.length > 0) {
        let obj = {
          id : data?._id,
          payload: {
            data : datas
          }
        }
        dispatch(updateApi(obj));
      } else {
        dispatch(createApi({
          data: datas
        }));
      }
    }
    handleClose();
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleDragEnd = (event) => {
    let { active, over } = event;
    if (active.id !== over.id && !searchText?.length) {
      let oldIndex = tempHeaders.findIndex((item) => item.label === active.id);
      let newIndex = tempHeaders.findIndex((item) => item.label === over.id);
      setTempHeaders((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <Modal show={show} onHide={handleClose} ref={modalRef}>
      <Modal.Header closeButton>
        <Modal.Title>Manage {isNavbarChange ? 'Navbar' : 'Columns' }</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalBodyRef}>
        <Form.Control
          type="text"
          placeholder="Search"
          onChange={handleSearch}
          value={searchText}
          className="mb-3"
        />
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[ restrictToParentElement, ]}>
          <SortableContext items={tempHeaders.map((header) => header.label)} strategy={verticalListSortingStrategy}>
            {tempHeaders
              .filter(header => header.label.toLowerCase().includes(searchText.toLowerCase()))
              .map((header, index) => (
                <SortableItem key={index} id={header.label} index={index} header={header} onChange={handleCheckboxChange} />
              ))}
          </SortableContext>
        </DndContext>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ManageColumn;
