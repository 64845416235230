import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({ series, options, width = '100%', height = '100%' }) => {
    return (
        <React.Fragment>
            <Chart options={options}
                series={series}
                width={width}
                height={height}
                type="donut" />
        </React.Fragment>
    );
};
export default PieChart;