import { createSlice } from "@reduxjs/toolkit";

const auditLogSlice = createSlice({
    name: "auditlog",
    initialState: {
        isLoading: false,
        logs: [],
        message: null,
        successStatus: null,
    },
    reducers: {
        getAuditLogsStart: (state, action) => {
            state.isLoading = true;
        },
        getAuditLogsSuccess: (state, action) => {
            state.logs = action?.payload?.audit_logs || [];
            state.message = action?.payload?.message;
            state.successStatus = action?.payload?.success;
            state.isLoading = false;
        },
        getAuditLogsFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload;
            state.successStatus = false;
        },
    },
});

export const {
    getAuditLogsStart,
    getAuditLogsSuccess,
    getAuditLogsFailure,
} = auditLogSlice.actions;

export default auditLogSlice.reducer;