import React, { useState } from 'react';  
import Modal from 'react-bootstrap/Modal';  
import Form from 'react-bootstrap/Form';  
import Button from 'react-bootstrap/Button';  
import InputGroup from 'react-bootstrap/InputGroup';  
import {handleFieldLabelUpdate} from '../../../Utils';
import { Currency } from 'lucide-react';

function CurrencyPropertiesModal({ show, handleClose,item, editPropertySubmit }) {
  let fields = item?.extra_field;
  const [fieldLabel, setFieldLabel] = useState('Currency'); 
  const [maxDigits, setMaxDigits] = useState(fields?.number_of_characters_allowed); 
  const [decimalPlaces, setDecimalPlaces] = useState(9); 
  const [roundingOption, setRoundingOption] = useState(0); 
  const [required, setRequired] = useState(false); 
  const [encryptField, setEncryptField] = useState(false); 
  const [showTooltip, setShowTooltip] = useState({ checked: fields?.show_tooltip?.checked, type: fields?.show_tooltip?.type, message: fields?.show_tooltip?.message });
  const [showPreview, setShowPreview] = useState(false); 
  const [labelError, setLabelError] = useState('');

  const handleCancel = () => {
    setFieldLabel('Currency');
    setMaxDigits(16);
    setDecimalPlaces(9);
    setRoundingOption(0);
    setRequired(false);
    setEncryptField(false);
    setShowTooltip(false);
    handleClose();
  };

  const handleDone = () => {
    const formData = {
      field_label: fieldLabel,
      number_of_characters_allowed: maxDigits,
      number_of_decimal_places: decimalPlaces,
      rounding_option: {
        type: 'Round Down',
        precision: roundingOption,
      },
      required,
      encrypt_field: encryptField,
      show_tooltip: showTooltip,
    };

    editPropertySubmit(formData);
    handleClose();
  };

  const handleFieldLabelChange =(e) => {
    handleFieldLabelUpdate(e.target.value, setFieldLabel, setLabelError);
  }
  
  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };
  const handleCharLimitChange = (e) => {
    let value = e.target.value;
    const numericValue = value === '' ? '' : Math.min(Math.max(parseInt(value, 10), 1), 255);
    setMaxDigits(numericValue);
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
      <Modal.Header closeButton>
        <Modal.Title>Currency Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFieldLabel">
            <Form.Label>Field Label</Form.Label>
            <Form.Control
              type="text"
              value={fieldLabel}
              onChange={handleFieldLabelChange}
              placeholder="Field Label"
              required
            />
            {labelError && <div className="text-danger">{labelError}</div>}
          </Form.Group>

          <Form.Group controlId="formMaxDigits" className="mt-3">
            <Form.Label>Maximum Digits Allowed</Form.Label>
            <InputGroup>
              <Form.Control
                as="select"
                value={maxDigits}
                onChange={handleCharLimitChange}
                required
              >
                {[...Array(17).keys()].map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Control>
              <InputGroup.Text>Eg: 12345.55</InputGroup.Text>
            </InputGroup>
          </Form.Group>

        <Form.Group controlId="formDecimalPlaces" className="mt-3">
          <Form.Label>Number of Decimal Places</Form.Label>
          <InputGroup>
            <Form.Control
              as="select"
              value={decimalPlaces}
              onChange={(e) => setDecimalPlaces(parseInt(e.target.value, 10) || '')}
              required
            >
              {[...Array(10).keys()].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </Form.Control>
            <InputGroup.Text>Eg: 2</InputGroup.Text>
          </InputGroup>
        </Form.Group>

          <Form.Group controlId="formRoundingOption" className="mt-3">
            <Form.Label>Rounding Option</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={roundingOption}
                onChange={(e) => setRoundingOption(parseInt(e.target.value, 10) || '')}
                min={0}
                max={10}
                required
              />
              <InputGroup.Text>Digits</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formRequired" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Required"
              checked={required}
              onChange={() => setRequired(!required)}
            />
          </Form.Group>

          <Form.Group controlId="formEncryptField" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Encrypt Field"
              checked={encryptField}
              onChange={() => setEncryptField(!encryptField)}
            />
          </Form.Group>

          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip.checked}
              onChange={() => setShowTooltip({ ...showTooltip, checked: !showTooltip.checked })}
            />
          </Form.Group>

          {showTooltip.checked && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Message (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={showTooltip.message}
                  onChange={(e) => setShowTooltip({ ...showTooltip, message: e.target.value })}
                  placeholder="Enter Tooltip message"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={showTooltip.type === 'info'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'info' })}
                />
                <Form.Check
                  type="radio"
                  label="Static Text"
                  name="tooltipOption"
                  checked={showTooltip.type === 'static'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'static' })}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showTooltip.type === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {showTooltip.message && <span className="ms-2">{showTooltip.message}</span>}
                    </div>
                  ) : (
                    <p>{showTooltip.message}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleDone}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CurrencyPropertiesModal;
