import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { deleteUserStart, getAllUserListStart, getAllUserTypeListStart, updateUserStart, uploadUserProfileStart } from "../../../Store/Slices/userSlice";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/formModules/Pagination";
import { AgGridReact } from "@ag-grid-community/react";
import ConfirmationModal from "../../../Components/ModalPopup/ConfirmationModal";
import { getItem } from "../../../Services/localService";
import { Helpers } from "../../../Helpers";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import setCanvasPreview from "../../../Components/canvasPreview";
import { userExistStart, userRegisterStart } from "../../../Store/Slices/registerSlice";
import { toast } from "react-toastify";
import Toast from "../../../Components/Toast";
import { resetState } from "../../../Store/Slices/registerSlice";
import { userResetState } from "../../../Store/Slices/userSlice";
import { Modal } from "react-bootstrap";
import AddUserModal from "../../../Components/ModalPopup/UsersModal/addUserModal";
import {Utils} from "../../../Utils";

const ASPECT_RATIO = '';
const MIN_DIMENSION = 150;

const Users = () => {
    let gridRef = useRef();
    const dispatch = useDispatch();
    const avatarUrl = useRef();
    let id = getItem('user')?._id;
    let user = getItem('user');
    const register = useSelector((state) => state?.register);
    const allUsers = useSelector((state) => state?.user);
    let defaultProfile = "https://campussafetyconference.com/wp-content/uploads/2020/08/iStock-476085198.jpg";
    let profilesList = useSelector((state) => state?.profile)?.profileData;
    let roleList = useSelector((state) => state?.role)?.roleData;

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    let [currentPage, setCurrentPage] = useState(1);
    let [pageSize, setPageSize] = useState(10);
    let [isActive, setIsActive] = useState(true);
    let [removeModalData, setRemoveModalData] = useState(null);

    const [firstNameTouched, setFirstNameTouched] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [phoneTouched, setPhoneTouched] = useState(false);
    const [roleTouched, setRoleTouched] = useState(false);
    const [profileTouched, setProfileTouched] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const [showRoleListModal, setShowRoleListModal] = useState(false)
    const [showImgUploadModal, setShowImgUploadModal] = useState(false)
    const [showUserInfoModal, setShowUserInfoModal] = useState(false)
    const [showLocaleInfoModal, setShowLocaleInfoModal] = useState(false)

    const onSwitchChange = (params, event) => {
        dispatch(updateUserStart({
            id: params?.data?._id, payload: { isActive: event.target.checked }
        }))
    };

    const SwitchCellRenderer = (params) => {
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    checked={params.value}
                    onChange={(event) => onSwitchChange(params, event)} />
            </div>
        );
    };

    let [columnDefs] = useState([
        {
            headerName: "Name",
            valueGetter: (params) => `${params.data.firstName} ${params.data.lastName}`
        },
        { headerName: "Email", field: "email" },
        {
            headerName: "Role",
            valueGetter: (params) => `${params?.data?.role?.role_name || ''}`
        },
        {
            headerName: "Profile",
            valueGetter: (params) => `${params?.data?.Profile?.profile_name || ''}`
        },
        { headerName: "User Status", field: "isActive", cellRenderer: SwitchCellRenderer }
    ]);
    let [alertInfo, setAlertInfo] = useState(null);
    let [userInfo, setUserInfo] = useState({});
    let [listItems, setListItems] = useState(null);
    let [storeData, setStoreData] = useState({
        moduleId: id,
        data: []
    });
    let [errors, setErrors] = useState({});
    let [displayNameProp, setDisplayNameProp] = useState({
        nameFormat: '',
        sortOrder: ''
    });
    let [newUserData, setNewUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        Profile: '',
        phone_no: parseInt('')
    });
    let [formValid, setFormValid] = useState(false)
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState();
    const [error, setError] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [oldUserData, setOldUserData] = useState([]);
    const [userData, setUserData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        Alias: '',
        Date_of_Birth: '',
        Fax: '',
        Mobile: '',
        phone_no: '',
        Profile: '',
        Website: '',
        City: '',
        Country: '',
        State: '',
        Street: '',
        Zip_Code: '',
        Country_Locale: '',
        Date_Format: '',
        Language: '',
        Preferred_Currency: '',
        Shift_Hour: '',
        Time_Format: '',
        Time_Zone: '',
        Decimal: '',
        Grouping: ''
    });
    let [existValidationType, setExistValidationType] = useState('');
    let [formattedRole, setFormattedRole] = useState([]);


    useEffect(() => {
        if(allUsers){
            setOldUserData(allUsers?.data)
        }
        
    },[allUsers?.data])
    
    const formHandler = (e) => {
        try {
            setUserData((prev) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value
            }));
        } catch (err) {
            throw err;
        }
    };

    const userHandler = (e) => {
        try {
            setEmailError('');
            setPhoneError('');
            setNewUserData((prev) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value
            }))
            if (e.target.name === 'email' && e.target.value === '') {
                setIsReadOnly(true)
            }
            if (e.target.name === 'phone_no') {
                if (e.target.value.length > 0 && e.target.value.length !== 10) {
                    setPhoneError('Phone number must be exactly 10 digits.');
                } else {
                    setPhoneError('');
                }
            }
        } catch (err) {
            throw err;
        }
    }

    let page = 1;
    let perPage = 10;

    function getNestedStructure(array) {
        const idMap = new Map();
        array.forEach(item => {
            idMap.set(item._id, { ...item, children: [] });
        });
        const result = [];
        array.forEach(item => {
            if (item.reporting_to !== null && idMap.has(item.reporting_to.id)) {
                const parent = idMap.get(item.reporting_to.id);
                parent.children.push(idMap.get(item._id));
            } else {
                if (item.reporting_to === null) {
                    idMap.get(item._id)['primary'] = true
                    result.push(idMap.get(item._id));
                }
            }
        });

        return result;
    }

    useEffect(() => {
        if (newUserData.firstName !== '' && newUserData.email !== '' &&
            newUserData.role !== '' && newUserData.Profile !== '' && newUserData.phone_no !== '') {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }, [newUserData])

    useEffect(() => {
        if (roleList?.length) {
            let response = getNestedStructure(roleList);
            setFormattedRole(response);
        }
    }, [roleList])

    useEffect(() => {
        if (register.registerStatus) {
            toast.success('User registered successfully.', {
                autoClose: 3000,
            });
            dispatch(getAllUserListStart({ page: currentPage, count: pageSize }));
            dispatch(getAllUserTypeListStart({ page: currentPage, count: pageSize }));
        }
        if (register.registerStatus == false) {
            toast.error(register?.message, {
                autoClose: 3000,
            });
        }
        clearUserState();
    }, [register.registerStatus])

    useEffect(() => {
        if (allUsers?.data?.length) {
            let data = allUsers?.data?.[0]
            setSelectedUser(allUsers?.data?.[0]);

            setUserData((prev) => ({
                ...prev,
                email: data?.email || '',
                firstName: data?.firstName || '',
                lastName: data?.lastName || '',
                role: data?.role?._id || '',
                Alias: data?.Alias || '',
                Date_of_Birth: data?.Date_of_Birth || '',
                Fax: data?.Fax || '',
                Mobile: data?.Mobile || '',
                // Phone: data?.Phone || '',
                phone_no: data?.phone_no || '',
                // Profile: data?.Profile || '',
                Profile: data?.Profile?._id || '',
                Website: data?.Website || '',
                City: data?.City || '',
                Country: data?.Country || '',
                State: data?.State || '',
                Street: data?.Street || '',
                Zip_Code: data?.Zip_Code || '',
                Country_Locale: data?.Country_Locale || '',
                Date_Format: data?.Date_Format || '',
                Language: data?.Language || '',
                Preferred_Currency: data?.Preferred_Currency || '',
                Shift_Hour: data?.Shift_Hour || '',
                Time_Format: data?.Time_Format || '',
                Time_Zone: data?.Time_Zone || '',
                Decimal: data?.Decimal || '',
                Grouping: data?.Grouping || '',
            }));

            setDisplayNameProp({
                ['nameFormat']: data?.nameFormat,
                ['sortOrder']: data?.sortOrder
            });
        } else {
            setSelectedUser(null);
        }

        const img = new Image();
        img.src = selectedUser?.profilePicture;
        img.onload = () => {
            setImageLoaded(true);
        };
    }, [allUsers]);

    useEffect(() => {
        setUserData((prev) => ({
            ...prev,
            email: selectedUser?.email || '',
            firstName: selectedUser?.firstName || '',
            lastName: selectedUser?.lastName || '',
            role: selectedUser?.role?._id || '',
            Alias: selectedUser?.Alias || '',
            Date_of_Birth: selectedUser?.Date_of_Birth || '',
            Fax: selectedUser?.Fax || '',
            Mobile: selectedUser?.Mobile || '',
            phone_no: selectedUser?.phone_no || '',
            Profile: selectedUser?.Profile?._id || '',
            Website: selectedUser?.Website || '',
            City: selectedUser?.City || '',
            Country: selectedUser?.Country || '',
            State: selectedUser?.State || '',
            Street: selectedUser?.Street || '',
            Zip_Code: selectedUser?.Zip_Code || '',
            Country_Locale: selectedUser?.Country_Locale || '',
            Date_Format: selectedUser?.Date_Format || '',
            Language: selectedUser?.Language || '',
            Preferred_Currency: selectedUser?.Preferred_Currency || '',
            Shift_Hour: selectedUser?.Shift_Hour || '',
            Time_Format: selectedUser?.Time_Format || '',
            Time_Zone: selectedUser?.Time_Zone || '',
            Decimal: selectedUser?.Decimal || '',
            Grouping: selectedUser?.Grouping || '',
        }));

        setDisplayNameProp({
            ['nameFormat']: selectedUser?.nameFormat,
            ['sortOrder']: selectedUser?.sortOrder
        });
    }, [selectedUser])

    const clearUserState = () => {
        setNewUserData((prev) => ({
            ...prev,
            firstName: '',
            lastName: '',
            email: '',
            role: '',
            Profile: ''
        }))
        setFirstNameTouched(false);
        setEmailTouched(false);
        setPhoneTouched(false);
        setRoleTouched(false);
        setProfileTouched(false);
        setEmailError('');
        setPhoneError('');
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        if (!selectedFile) {
            alert("Please select a file first.");
            return;
        }
        const formData = new FormData();
        formData.append("profile", selectedFile);
        try {
            if (selectedUser) {
                dispatch(uploadUserProfileStart({
                    id: selectedUser._id, payload: formData
                }))
            }
        } catch (error) {
            console.error("There was an error uploading the file:", error);
        }
    };

    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        let totalPages = Math.ceil(allUsers?.totalCount / pageSize);
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
    };

    const handleDeactivateUser = () => {
        if (selectedUser) {
            dispatch(updateUserStart({
                id: selectedUser._id, payload: { isActive: false }
            }))
        }
    }

    const handleActivateUser = () => {
        if (selectedUser) {
            dispatch(updateUserStart({
                id: selectedUser._id, payload: { isActive: true }
            }))
        }
    }

    const handleDeleteUser = () => {
        if (selectedUser) {
            dispatch(deleteUserStart(selectedUser._id))
        }
    }

    useEffect(() => {
        dispatch(getAllUserTypeListStart({ page: currentPage, count: pageSize }));
    }, [])

    useEffect(() => {
        setPreviewImage(null);
        dispatch(getAllUserListStart({ page: currentPage, count: pageSize, isActive: isActive }));
        updateAvatar("");
        setCrop("");
        setImgSrc("");
    }, [currentPage, pageSize, isActive]);

    useEffect(() => {
        if (allUsers?.reload || allUsers?.deleteStatus || allUsers?.updateStatus) {
            setPreviewImage(null);
            dispatch(getAllUserListStart({ page: currentPage, count: pageSize, isActive: isActive }));
            updateAvatar("");
            setCrop("");
            setImgSrc("");
        }
    }, [allUsers?.reload, allUsers?.deleteStatus, allUsers?.updateStatus]);



    useEffect(() => {
        dispatch(resetState());
        dispatch(userResetState());
    }, [allUsers?.successStatus])

    useEffect(() => {
        if (allUsers?.updateStatus) {
            dispatch(getAllUserTypeListStart({ page: currentPage, count: pageSize }));
        }
    }, [allUsers?.updateStatus])

    useEffect(() => {
        if (register?.existError?.includes('Registered') || register?.existError?.includes('Invalid')) {
            if (existValidationType == 'email') {
                setEmailError(register.existError);
                setPhoneError('');
                // setNewUserData((prev) => ({
                //     ...prev,
                //     email: ''
                // }));
            }
            if (existValidationType == 'phone') {
                setPhoneError(register.existError);
                setEmailError('');
                // setNewUserData((prev) => ({
                //     ...prev,
                //     phone_no: ''
                // }));
            }
        }

        if (register?.existError?.includes('Proceed')) {
            if (existValidationType == 'email') {
                setEmailError('');
                setPhoneError('');
                setIsReadOnly(false);
                // setNewUserData((prev) => ({
                //     ...prev,
                //     email: ''
                // }));
            }
        }
    }, [register])

    const handleSubmit = () => {
        // let payload = { sections: storeData?.data };
        // storeData.data.map((data, i) => {
        //     if (data?.section_name == 'User Information') {
        //         payload['firstName'] = data?.section_data?.First_Name;
        //         payload['lastName'] = data?.section_data?.Last_Name;
        //     }
        // });
        // payload['nameFormat'] = displayNameProp?.nameFormat;
        // payload['sortOrder'] = displayNameProp?.sortOrder;
        let payload = userData;

        setOldUserData(userData);
        const auditLog = Utils.logChanges(oldUserData, payload, profilesList, roleList);
        payload.audit_log = { msg: auditLog };

        dispatch(updateUserStart({
            id: selectedUser?._id, payload: payload, auditLog
        }))
    }

    const displayPropertyHandler = (e) => {
        try {
            setDisplayNameProp((prop) => ({
                ...prop,
                [e.target?.name]: e?.target?.value
            }))

            dispatch(updateUserStart({
                id: selectedUser?._id, payload: { [e.target?.name]: e?.target?.value }
            }))
        } catch (err) {
            throw err;
        }
    }

    const formatDataStructures = (data, isValidate = false) => {
        let format = [];
        let newErrors = {};
        const assignDefaultValues = (item) => {
            let default_value = item?.extra_fields?.default_value;
            if (item?.input_type === 'multi-select' && default_value && default_value !== "None") {
                return [{ name: default_value }];
            } else {
                return default_value;
            }
        }
        if (data?.sections) {
            data?.sections?.forEach((section, sectionIndex) => {
                if (!isValidate) {
                    if (!format?.[sectionIndex]) {
                        // format[sectionIndex] = {
                        //     section_name: section?.section_name,
                        //     section_data: {}
                        // }
                        format[sectionIndex] = {
                            section_name: section?.section_name,
                            section_data: section?.section_data
                        }
                    }
                }
                if (section?.subform) {
                    if (section?.selected_list?.length > 0 && !isValidate) {
                        format[sectionIndex].section_data["table_data"] = [
                            Object.assign({}, ...section.selected_list.map(x => ({
                                [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                            })))
                        ];
                    }
                } else {
                    section?.dropped_list?.[`drop_${sectionIndex}_area_1`]?.forEach((item) => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        if (!isValidate) {
                            format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                        }
                        //  else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                        //     newErrors[field_label] = errors?.[field_label];
                        // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                        //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                        // }
                    });
                    if (section?.layouts === 2) {
                        section?.dropped_list?.[`drop_${sectionIndex}_area_2`]?.forEach((item) => {
                            let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                            if (!isValidate) {
                                format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                            }
                            // else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            //     newErrors[field_label] = errors?.[field_label];
                            // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                            // }
                        });
                    }
                }
            });
        }

        if (data?.data) {
            data?.data?.forEach((section, sectionIndex) => {
                if (!isValidate) {
                    if (!format?.[sectionIndex]) {
                        // format[sectionIndex] = {
                        //     section_name: section?.section_name,
                        //     section_data: {}
                        // }
                        format[sectionIndex] = {
                            section_name: section?.section_name,
                            section_data: section?.section_data
                        }
                    }
                }
                if (section?.subform) {
                    if (section?.selected_list?.length > 0 && !isValidate) {
                        format[sectionIndex].section_data["table_data"] = [
                            Object.assign({}, ...section.selected_list.map(x => ({
                                [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                            })))
                        ];
                    }
                } else {
                    section?.dropped_list?.[`drop_${sectionIndex}_area_1`]?.forEach((item) => {
                        let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                        if (!isValidate) {
                            format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                        }
                        //  else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                        //     newErrors[field_label] = errors?.[field_label];
                        // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                        //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                        // }
                    });
                    if (section?.layouts === 2) {
                        section?.dropped_list?.[`drop_${sectionIndex}_area_2`]?.forEach((item) => {
                            let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                            if (!isValidate) {
                                format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                            }
                            // else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            //     newErrors[field_label] = errors?.[field_label];
                            // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                            // }
                        });
                    }
                }
            });
        }

        if (!data?.sections && !data?.data) {
            if (!data?.sections && !data?.data) {
                let temp = [
                    {
                        section_name: "User Information",
                        section_data: {
                            First_Name: selectedUser?.firstName,
                            Last_Name: selectedUser?.lastName,
                            Email: selectedUser?.email,
                            // Role: selectedUser?.role
                        }
                    }
                ];

                temp?.forEach((section, sectionIndex) => {
                    if (!isValidate) {
                        if (!format?.[sectionIndex]) {
                            format[sectionIndex] = {
                                section_name: section?.section_name,
                                section_data: section?.section_data
                            }
                        }
                    }
                    if (section?.subform) {
                        if (section?.selected_list?.length > 0 && !isValidate) {
                            format[sectionIndex].section_data["table_data"] = [
                                Object.assign({}, ...section.selected_list.map(x => ({
                                    [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
                                })))
                            ];
                        }
                    } else {
                        section?.dropped_list?.[`drop_${sectionIndex}_area_1`]?.forEach((item) => {
                            let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                            if (!isValidate) {
                                format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                            }
                            //  else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                            //     newErrors[field_label] = errors?.[field_label];
                            // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                            //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                            // }
                        });
                        if (section?.layouts === 2) {
                            section?.dropped_list?.[`drop_${sectionIndex}_area_2`]?.forEach((item) => {
                                let field_label = Helpers.replaceData(item?.extra_fields?.field_label);
                                if (!isValidate) {
                                    format[sectionIndex].section_data[field_label] = assignDefaultValues(item);
                                }
                                // else if (["Article Master", "Article Master Generic"].includes(data?.plural_form) && item?.extra_fields?.required && field_label === "Material_Number" && errors?.[field_label]?.length > 0) {
                                //     newErrors[field_label] = errors?.[field_label];
                                // } else if (item?.extra_fields?.required && !storeData.data?.[sectionIndex]?.section_data?.[field_label]) {
                                //     newErrors[field_label] = `${item?.extra_fields?.field_label} is required`;
                                // }
                            });
                        }
                    }
                });
            }
        }
        if (!isValidate) {
            setStoreData({
                moduleId: id,
                data: format,
            });
        } else {
            setErrors(newErrors);
        }
        return Object.keys(newErrors).length === 0;
    }

    const updateAvatar = (imgSrc) => {
        avatarUrl.current = imgSrc;
    };

    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;

            imageElement.addEventListener("load", (e) => {
                if (error) setError("");
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("Image must be at least 150 x 150 pixels.");
                    return setImgSrc("");
                }
            });
            setImgSrc(imageUrl);
        });
        reader.readAsDataURL(file);
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    const base64ToBlob = (base64Data, contentType) => {
        const byteCharacters = atob(base64Data.split(',')[1]); // Remove the base64 header
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    };

    const handleUpdateProfile = () => {
        if (avatarUrl?.current) {
            const contentType = 'image/jpeg';
            const blob = base64ToBlob(avatarUrl?.current, contentType);

            const formData = new FormData();
            formData.append('profile', blob, 'image.jpeg')

            try {
                if (selectedUser) {
                    dispatch(uploadUserProfileStart({
                        id: selectedUser._id, payload: formData
                    }))
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }

    const dynamicProfileStyle = {
        opacity: selectedUser?.profilePicture !== defaultProfile ? '1' : '.3'
    }

    const [showTreeNode, setShowTreeNode] = useState(true);

    const createUser = () => {
        try {
            newUserData['organization'] = getItem('organization');
            dispatch(userRegisterStart(newUserData));
        } catch (error) {
            throw error;
        }
    }

    const handleEmailOnBlur = (e) => {
        setIsReadOnly(true)
        setExistValidationType('email');
        setEmailTouched(true);
        handleEmailBlur(e);
    };

    const handleEmailBlur = async () => {
        if (newUserData.email) {
            dispatch(userExistStart({ email: newUserData.email }));
        }
    };

    const handlePhoneNoBlur = (e) => {
        setExistValidationType('phone');
        setPhoneTouched(true);
        handlePhoneBlur();
    };

    const handlePhoneBlur = async () => {
        if (newUserData.phone_no) {
            dispatch(userExistStart({ phone_no: newUserData.phone_no }));
        }
    };

    // let formattedRole = [];
    // if (roleList?.length) {
    //     roleList.forEach(element => {
    //         if (element.reporting_to === null) {
    //             formattedRole.push({
    //                 id: element._id,
    //                 label: element.role_name,
    //                 primary: true,
    //             })
    //         } else {
    //             let data = formattedRole.filter((item) => item.id === element.reporting_to.id);
    //             if (data?.length) {
    //                 if (data[0].children) {
    //                     data?.[0]?.children.push({
    //                         id: element._id,
    //                         label: element.role_name
    //                     })
    //                 } else {
    //                     data[0]['children'] = [];
    //                     data?.[0]?.children.push({
    //                         id: element._id,
    //                         label: element.role_name
    //                     })
    //                 }
    //             } else {

    //             }
    //         }
    //     });
    // }

    const RoleTreeNode = ({ node }) => {
        const [isExpanded, setIsExpanded] = useState(true);
        const [showTreeNode, setShowTreeNode] = useState(true);

        return (
            <React.Fragment>
                <ul className="nested-list">
                    <li className="nested-item">
                        <div className="d-inline-block ps-4 role-name-box">
                            <span className="fs-14 cursor-pointer"
                                onClick={() => {
                                    setNewUserData((prev) => ({
                                        ...prev,
                                        role: node._id
                                    }));
                                    handleCloseRoleModal()
                                }}
                            >{node.role_name}</span>
                        </div>
                        {isExpanded && node.children && (
                            <>
                                <details open={showTreeNode}>
                                    <summary></summary>
                                    {node.children.map((child) => (
                                        <RoleTreeNode key={child._id} node={child} />
                                    ))}
                                </details>
                            </>
                        )}
                    </li>
                </ul>
            </React.Fragment>
        );
    };

    const handleCloseUserModal = () => {
        setShowAddUserModal(false)
    }

    const handleCloseRoleModal = () => {
        setShowRoleListModal(false)
    }

    const handleCloseImgUploadModal = () => {
        setShowImgUploadModal(false)
    }

    const handleCloseUserInfoModal = () => {
        setShowUserInfoModal(false)
    }

    const handleCloseLocaleInfoModal = () => {
        setShowLocaleInfoModal(false)
    }

    return (
        <>
            <Loader isVisible={allUsers?.isLoading || register.isLoading} />
            {(register?.message) && <Toast />}
            {/* Users Settings Card */}
            <div className="g-settings">
                <div className="card rounded-4 border-0 p-0 users-settings">
                    <div className="card-header py-0 fs-14 bg-white rounded-top-4">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item px-3" role="presentation">
                                <button className="nav-link text-primary position-relative px-0 active" id="pills-tab-1"
                                    data-bs-toggle="pill" data-bs-target="#pills-tab1" type="button" role="tab"
                                    aria-controls="pills-tab1" aria-selected="true"
                                >Users</button>
                                {/* onClick={() => { setCurrentPage(1); setPageSize(10) }} */}
                            </li>
                            <li className="nav-item px-3" role="presentation">
                                <button className="nav-link text-primary position-relative px-0" id="pills-tab-2"
                                    data-bs-toggle="pill" data-bs-target="#pills-tab2" type="button" role="tab"
                                    aria-controls="pills-tab2" aria-selected="false">Activate Users </button>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body pt-0">
                        <div className="tab-content px-3" id="pills-tabContent">
                            {/* Users Tab */}
                            <div className="tab-pane fade show active" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab-1">
                                <div className="row g-0">
                                    <div className="col-md-5 border-end">
                                        <div className="d-flex flex-row align-items-center pt-3">
                                            <select className="form-select w-auto" name="select-user-menu"
                                                onChange={(e) => setIsActive(e?.target?.value === 'active')}>
                                                <option disabled Value="">Users</option>
                                                <option value="active">Active Users{isActive && '(' + (allUsers?.totalCount) + ')'}</option>
                                                <option Value="inactive">Inactive Users{!isActive && '(' + (allUsers?.totalCount) + ')'}</option>
                                                {/*<option Value="Unconfirmed Users">Unconfirmed Users</option>
                                                <option Value="Deleted Users">Deleted Users</option>
                                                <option Value="Developers">Developers</option> */}
                                            </select>

                                            <div className="btn-group ms-auto">
                                                <button type="button" className="btn btn-primary me-2px rounded-start btn-digy-dark"
                                                    // data-bs-toggle="modal" data-bs-target="#addNewUser"
                                                    onClick={() => setShowAddUserModal(true)}>
                                                    <i className="fa fa-plus me-2"></i> New User
                                                </button>
                                                <button type="button" className="btn btn-primary btn-digy-dark dropdown-toggle dropdown-toggle-split rounded-end" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-caret-down"></i>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li><a className="dropdown-item cursor-pointer"><i className="fa fa-file-import me-2"></i> Import Users</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="mb-3 user-list">
                                            {allUsers?.data?.length > 0 && allUsers?.data?.map((user) => {
                                                const dynamicStyle = {
                                                    opacity: user?.profilePicture !== defaultProfile ? '1' : '.3'
                                                }
                                                return (
                                                    < div className={`d-flex align-items-center px-3 py-2 mt-3 user-list-item ${selectedUser?._id == user?._id && 'active'}`
                                                    }
                                                        onClick={() => { setSelectedUser(user) }}>
                                                        <div className="position-relative d-inline-block">
                                                            <div className="profile-icon">
                                                                {imageLoaded &&
                                                                    <img src={user?.profilePicture} alt="profile-icon"
                                                                        style={dynamicStyle} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="px-3 r-cont">
                                                            <div className="fw-semibold user-name">{user?.firstName + ' ' + user?.lastName}</div>
                                                            <div className="designation">
                                                                <span>{user?.role?.role_name}</span>,
                                                                {/* <span className="badge rounded-pill text-bg-warning ms-2">Super Admin</span> */}
                                                            </div>
                                                            <div className="user-email">{user?.email}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {/* <div className="d-flex align-items-center px-3 py-2 mt-3 user-list-item">
                                                <div className="position-relative d-inline-block">
                                                    <div className="profile-icon">
                                                        <img src={userIcon} alt="profile-icon" />
                                                    </div>
                                                </div>
                                                <div className="px-3 r-cont">
                                                    <div className="fw-semibold user-name">Kadin Rhiel Madsen</div>
                                                    <div className="designation">
                                                        <span>Manager</span>,
                                                        <span className="badge rounded-pill text-bg-warning ms-2">Admin</span>
                                                    </div>
                                                    <div className="user-email">kadinrhuel@gmail.com</div>
                                                </div>
                                            </div> */}
                                        </div>
                                        {allUsers?.data?.length > 0 &&
                                            // <Paginate items={allUsers} />
                                            <div className="ag-theme-quartz" style={{ height: "auto", display: "flex", flexDirection: "column", position: "relative" }}>
                                                {/* <AgGridReact
                                                    ref={gridRef}
                                                    rowData={allUsers?.data || []}
                                                    rowCount={1000}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={{
                                                        filter: 'agTextColumnFilter',
                                                        floatingFilter: true,
                                                    }}
                                                    rowSelection="single"
                                                    pagination={false}
                                                    paginationPageSize={pageSize}
                                                    paginationPageSizeSelector={[10, 25, 50]}
                                                    rowHeight={46}
                                                /> */}
                                                <Pagination
                                                    currentPage={currentPage}
                                                    pageSize={pageSize}
                                                    totalCount={allUsers?.totalCount}
                                                    onPageSizeChange={handlePageSizeChange}
                                                    onPrevPage={handlePrevPage}
                                                    onNextPage={handleNextPage}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {selectedUser &&
                                        <div className="col-md-7 px-3 s-user-detail">
                                            {/* User Details Top */}
                                            <div className="d-flex align-items-center py-2 mt-3 user-details">
                                                <div className="photo-upload position-relative d-inline-block cursor-pointer" 
                                                onClick={() => setShowImgUploadModal(true)}>
                                                {/* data-bs-toggle="modal" data-bs-target="#imgUploadModal" */}
                                                    <div className="hover-img">
                                                        <div className="photo-icon"></div>
                                                    </div>
                                                    <div className="profile-icon">
                                                        {imageLoaded &&
                                                            <img src={selectedUser?.profilePicture} alt="profile-icon"
                                                                style={dynamicProfileStyle} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="px-3 r-cont">
                                                    <div className="fw-semibold user-name pb-1">
                                                        {selectedUser?.firstName + ' ' + selectedUser?.lastName}
                                                        {/* <span className="badge rounded-pill text-bg-warning bg-opacity-25 border border-warning ms-2">Super Admin</span> */}
                                                        <i className="fa-solid fa-pencil ms-2 cursor-pointer" 
                                                        onClick={() => setShowUserInfoModal(true)}></i>
                                                        {/* data-bs-toggle="modal" data-bs-target="#userInfo" */}
                                                    </div>
                                                    <div className="designation text-secondary pb-1">
                                                        <span>{selectedUser?.role?.role_name}</span>
                                                        {/* <span>at DIGY</span> */}
                                                    </div>
                                                    <div className="user-email">
                                                        <i className="fa-regular fa-envelope me-2"></i>
                                                        {selectedUser?.email}
                                                    </div>
                                                    <div className="user-ph">
                                                        <i className="fa-solid fa-phone-volume me-2" style={{ transform: "rotate(-30deg)" }}></i>
                                                        {selectedUser?.Mobile}
                                                    </div>
                                                </div>
                                                {selectedUser?.isActive ?
                                                    <div className="float-end">
                                                        {/* onClick={handleDeactivateUser} */}
                                                        <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline" onClick={() => {
                                                            setRemoveModalData(selectedUser);
                                                            setAlertInfo({
                                                                key: 'deactivate',
                                                                title: 'Deactivate Confirmation',
                                                                content: 'Are you sure you want to deactivate?',
                                                                buttonTitle: 'Deactivate'
                                                            })
                                                        }}>Deactivate</button>
                                                    </div>
                                                    :
                                                    <div className="float-end">
                                                        {/* onClick={handleActivateUser} */}
                                                        <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline"
                                                            onClick={() => {
                                                                setRemoveModalData(selectedUser);
                                                                setAlertInfo({
                                                                    key: 'activate',
                                                                    title: 'Activate Confirmation',
                                                                    content: 'Are you sure you want to Activate?',
                                                                    buttonTitle: 'Activate'
                                                                })
                                                            }} >Activate</button>
                                                    </div>
                                                }
                                            </div>
                                            {/* // User Details Top END // */}

                                            {/* Show / Hide User information */}
                                            <div className="user-details pt-3">
                                                <button type="button"
                                                    className="btn btn-link text-decoration-none text-body p-0 text-start collapse-btn"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseUserDetail"
                                                    aria-expanded="true" aria-controls="collapseUserDetail">
                                                    Show Details
                                                    <i className="fa-solid fa-chevron-down ms-3"></i>
                                                </button>
                                                <div className="collapse fade show" id="collapseUserDetail">
                                                    <h6 className="sec-header fw-semibold pb-3 pt-2">User information</h6>
                                                    <dl className="row justify-content-center list-detail">
                                                        <dd className="col-sm-9 col-lg-5">First Name</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.firstName}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Last Name</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.lastName}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Email</dd>
                                                        <dt className="col-sm-3 col-lg-5">
                                                            <a href="mailto:kadinrhuel@gmail.com">{selectedUser?.email}</a>
                                                        </dt>

                                                        <dd className="col-sm-9 col-lg-5">Role</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.role?.role_name}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Profile</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Profile?.profile_name}</dt>
                                                        {/* <dt className="col-sm-3 col-lg-5">{profilesList?.filter((item) => item._id === selectedUser?.Profile)?.[0]?.profile_name}</dt> */}

                                                        {/* <dd className="col-sm-9 col-lg-5">Added By</dd>
                                                        <dt className="col-sm-3 col-lg-5">
                                                            Kadin Rhiel Madsen <br />
                                                            <span className="text-secondary fw-normal" role="datetime">Mon, 9 Sep 2024 12:28 PM</span>
                                                        </dt> */}

                                                        <dd className="col-sm-9 col-lg-5">Alias</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Alias}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Phone</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.phone_no}</dt>
                                                        {/* <dt className="col-sm-3 col-lg-5">{selectedUser?.Phone}</dt> */}

                                                        <dd className="col-sm-9 col-lg-5">Mobile</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Mobile}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Website</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Website}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Fax</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Fax}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Date of Birth</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Date_of_Birth}</dt>
                                                    </dl>

                                                    <h6 className="sec-header fw-semibold pb-3 pt-2">Address information</h6>
                                                    <dl className="row justify-content-center list-detail">
                                                        <dd className="col-sm-9 col-lg-5">Street</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Street}</dt>

                                                        <dd className="col-sm-9 col-lg-5">City</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.City}</dt>

                                                        <dd className="col-sm-9 col-lg-5">State</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.State}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Zip Code</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Zip_Code}</dt>

                                                        <dd className="col-sm-9 col-lg-5">Country</dd>
                                                        <dt className="col-sm-3 col-lg-5">{selectedUser?.Country}</dt>
                                                    </dl>
                                                </div>
                                            </div>
                                            {/* // Show / Hide User information END // */}

                                            <div className="social pt-3">
                                                <h6 className="sec-header fw-semibold pb-3 pt-2">Social Profiles</h6>
                                                <dl className="row justify-content-center list-detail">
                                                    <dd className="col-sm-9 col-lg-5">Twitter</dd>
                                                    <dt className="col-sm-3 col-lg-5">
                                                        <a >+ Add</a>
                                                    </dt>
                                                </dl>
                                            </div>

                                            <div className="local-info pt-3">
                                                <h6 className="sec-header fw-semibold pb-3 pt-2">
                                                    Locale information
                                                    <i className="fa-solid fa-pencil ms-2 cursor-pointer" 
                                                    onClick={() => setShowLocaleInfoModal(true)}></i>
                                                    {/* data-bs-toggle="modal" data-bs-target="#localeInfo" */}
                                                </h6>
                                                <dl className="row justify-content-center list-detail">
                                                    <dd className="col-sm-9 col-lg-5">Language</dd>
                                                    <dt className="col-sm-3 col-lg-5">{selectedUser?.Language}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Country Locale</dd>
                                                    <dt className="col-sm-3 col-lg-5">{selectedUser?.Country_Locale}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Date Format</dd>
                                                    <dt className="col-sm-3 col-lg-5">{selectedUser?.Date_Format}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Time Format</dd>
                                                    <dt className="col-sm-3 col-lg-5">{selectedUser?.Time_Format}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Time Zone</dd>
                                                    <dt className="col-sm-3 col-lg-5">{selectedUser?.Time_Zone}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Shift Hour</dd>
                                                    <dt className="col-sm-3 col-lg-5">{selectedUser?.Shift_Hour}</dt>

                                                    <dd className="col-sm-9 col-lg-5">Preferred Currency</dd>
                                                    <dt className="col-sm-3 col-lg-5">{selectedUser?.Preferred_Currency}</dt>

                                                    {/* <dd className="col-sm-9 col-lg-5">Number Format</dd>
                                                    <dt className="col-sm-3 col-lg-5">123,456.789</dt> */}
                                                </dl>
                                            </div>

                                            <div className="group-info pt-3">
                                                <h6 className="sec-header fw-semibold pb-3 pt-2">Groups</h6>
                                                <dl className="row justify-content-center list-detail">
                                                    <dd className="col-sm-9 col-lg-5">Member in</dd>
                                                    <dt className="col-sm-3 col-lg-5">-</dt>
                                                </dl>
                                            </div>

                                            <div className="prefer-info pt-3">
                                                <h6 className="sec-header fw-semibold pb-0 pt-2">Display Name Format & Preferences</h6>
                                                <p>It applies to full name in column of list view, lookup fields and user name</p>
                                                <div className="mb-3 row">
                                                    <label htmlFor="nameFormat" className="col-sm-4 text-sm-end col-form-label">Name Format</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-select" id="nameFormat" aria-label="Name Format"
                                                            name="nameFormat" value={displayNameProp?.nameFormat} onChange={(e) => { displayPropertyHandler(e) }}>
                                                            <option value="Salutation,First Name,Last Name">Salutation, First Name, Last Name</option>
                                                            <option value="Salutation,Last Name,First Name">Salutation, Last Name, First Name</option>
                                                            <option value="First Name,Salutation,Last Name">First Name, Salutation, Last Name</option>
                                                            <option value="First Name,Last Name,Salutation">First Name, Last Name, Salutation</option>
                                                            <option value="Last Name,Salutation,First Name">Last Name, Salutation, First Name</option>
                                                            <option value="Last Name,First Name,Salutation">Last Name, First Name, Salutation</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="sortOrderPrefer" className="col-sm-4 text-sm-end col-form-label">Sort order preference</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-select" id="sortOrderPrefer" aria-label="Sort Order Prefer"
                                                            name="sortOrder" value={displayNameProp.sortOrder} onChange={(e) => { displayPropertyHandler(e) }}>
                                                            <option value="First Name,Last Name">First Name, Last Name</option>
                                                            <option value="Last Name,First Name">Last Name, First Name</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="float-end">
                                                {/* onClick={handleDeleteUser} */}
                                                <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline" onClick={() => {
                                                    setRemoveModalData(selectedUser);
                                                    setAlertInfo({
                                                        key: 'delete',
                                                        title: 'Delete Confirmation',
                                                        content: 'Are you sure you want to delete?',
                                                        buttonTitle: 'Delete'
                                                    })
                                                }}>Delete this user</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* // Users Tab END // */}

                            {/* Activate Users Tab */}
                            <div className="tab-pane fade activate-users" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab-2">
                                <div className="d-flex flex-row mt-4">
                                    <div className="left-con">
                                        <h5 className="fw-semibold">Activate Users</h5>
                                        <p>
                                            This page allows you to activate and deactivate users.
                                            Note: Your organization cannot have more active users than user licenses.
                                        </p>
                                    </div>
                                    <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn align-self-start ms-auto">
                                        <i className="fa-regular fa-circle-question me-2"></i>
                                        Help
                                    </button>
                                </div>

                                {/* datatable::start */}

                                <div className="card rounded-4 border-0 form-module px-0">
                                    <div className="card-body p-0">
                                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                                            <div className="left d-flex align-items-start">
                                                <div className="search-box border rounded-pill">
                                                    <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                                    <input type="search" placeholder="Search" className="searchfield bg-white" />
                                                    {/*  onInput={onFilterTextBoxChanged} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {allUsers?.userTypeData?.length && <div className="ag-theme-quartz" style={{ height: 450, display: "flex", flexDirection: "column", position: "relative" }}>
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={allUsers?.userTypeData || []}
                                                    rowCount={1000}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={{
                                                        filter: 'agTextColumnFilter',
                                                        floatingFilter: true,
                                                    }}
                                                    rowSelection="single"
                                                    pagination={false}
                                                    paginationPageSize={pageSize}
                                                    paginationPageSizeSelector={[10, 25, 50]}
                                                    rowHeight={46}
                                                />
                                                <Pagination
                                                    currentPage={currentPage}
                                                    pageSize={pageSize}
                                                    totalCount={allUsers?.totalCount}
                                                    onPageSizeChange={handlePageSizeChange}
                                                    onPrevPage={handlePrevPage}
                                                    onNextPage={handleNextPage}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                                {/* datatable::end */}

                                {/* static table::begin */}
                                {/* <div className="tbl-container rounded-table">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Email Address</th>
                                                    <th>Role</th>
                                                    <th>Profile</th>
                                                    <th>User Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Kadin Rhiel Madsen</td>
                                                    <td><a href="mailto:kadinrhuel@gmail.com">kadinrhuel@gmail.com</a></td>
                                                    <td><a >CEO</a></td>
                                                    <td><a >Super Admin</a></td>
                                                    <td>
                                                        <label className="custom-switch switch switch-success">
                                                            <input type="checkbox" checked />
                                                            <span className="switch-slider"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Kadin Rhiel Madsen</td>
                                                    <td><a href="mailto:kadinrhuel@gmail.com">kadinrhuel@gmail.com</a></td>
                                                    <td><a >Manager</a></td>
                                                    <td><a >Admin</a></td>
                                                    <td>
                                                        <label className="custom-switch switch switch-success">
                                                            <input type="checkbox" checked />
                                                            <span className="switch-slider"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}
                                {/* static table::end */}
                            </div>
                            {/* // Activate Users Tab END // */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Photo Upload Modal */}
            <Modal show={showImgUploadModal} onHide={handleCloseImgUploadModal}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="fs-5 fw-semibold">Select Image</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    {!imgSrc &&
                        <div className="file-upload-content">
                            <div className="mb-4">Upload your photo here.</div>
                            <span className="btn btn-primary btn-file">
                                Browse Local Files <input type="file" accept="image/*" onChange={onSelectFile} />
                            </span>
                        </div>
                    }

                    {imgSrc && (
                        <div className="flex flex-col items-center">
                            <ReactCrop
                                crop={crop}
                                onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                                keepSelection
                                aspect={ASPECT_RATIO}
                                minWidth={MIN_DIMENSION}
                            >
                                <img
                                    ref={imgRef}
                                    src={imgSrc}
                                    alt="Upload"
                                    style={{ maxHeight: "70vh" }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                            <div className="d-flex">
                                <button
                                    className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline"
                                    onClick={() => {
                                        setCanvasPreview(
                                            imgRef.current, // HTMLImageElement
                                            previewCanvasRef.current, // HTMLCanvasElement
                                            convertToPixelCrop(
                                                crop,
                                                imgRef.current.width,
                                                imgRef.current.height
                                            )
                                        );
                                        const dataUrl = previewCanvasRef.current.toDataURL();
                                        updateAvatar(dataUrl);
                                        handleUpdateProfile(); 
                                        handleCloseImgUploadModal();
                                        // closeModal();
                                    }}
                                    // data-bs-dismiss="modal"
                                >
                                    Crop and Set
                                </button>
                                <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline ms-2" onClick={() => { setCrop(""); setImgSrc(""); handleCloseImgUploadModal() }}>Cancel</button>
                                {/*  data-bs-dismiss="modal" */}
                            </div>
                        </div>
                    )}

                    {crop && (
                        <canvas
                            ref={previewCanvasRef}
                            className="mt-4"
                            style={{
                                display: "none",
                                border: "1px solid black",
                                objectFit: "contain",
                                width: 150,
                                height: 150,
                            }}
                        />
                    )}
                    {/* <form action="#" method="post"> */}
                        {/* {!previewImage ?
                            (
                                <div className="file-upload-content">
                                    <div className="mb-4">Upload your photo here.</div>
                                    <span className="btn btn-primary btn-file">
                                        Browse Local Files <input type="file" accept="image/*" onChange={handleFileChange} />
                                    </span>
                                </div>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <h3>Image Preview:</h3>
                                        <img src={previewImage} alt="Profile Preview" style={{ width: "150px" }} />
                                    </div>
                                    <div className="float-end">
                                        <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline" onClick={handleUpload} data-bs-dismiss="modal">Upload</button>
                                        <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline ms-2" data-bs-dismiss="modal" onClick={() => { setPreviewImage(null); setSelectedFile(null) }}>Cancel</button>
                                    </div>
                                </>
                            )} */}
                    {/* </form> */}
                </Modal.Body>
            </Modal>
            {/* // Photo Upload Modal // */}
            
            {/* // Users Settings Card END // */}
            {/* confirmation popup */}
            {
                removeModalData && (
                    <ConfirmationModal
                        data={removeModalData}
                        show={true}
                        handleClose={() => setRemoveModalData(null)}
                        handleSuccess={() => {
                            alertInfo?.key === 'deactivate' && handleDeactivateUser();
                            alertInfo?.key === 'activate' && handleActivateUser();
                            alertInfo?.key === 'delete' && handleDeleteUser();
                            setRemoveModalData(null);
                        }}
                        alertInfo={alertInfo}
                    />
                )
            }

            {/* user info modal */}
            <Modal show={showUserInfoModal} scrollable dialogClassName="com-details-form" onHide={handleCloseUserInfoModal} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="fs-5 fw-semibold">Account Information</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <div className="row mb-40">
                        <h6 className="sec-header pb-3 pt-2 col-12">User Information</h6>
                        <div className="col-md-12">
                            <div className="form-group mb-4">
                                <label htmlFor="firstName" className="col-auto col-form-label">First Name</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="firstName" required name="firstName"
                                        onChange={formHandler} value={userData?.firstName} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="lastName" className="col-auto col-form-label">Last Name</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="lastName" name="lastName"
                                        onChange={formHandler} value={userData?.lastName} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="emailBox" className="col-auto col-form-label">Email</label>
                                <div className="col-auto input-control">
                                    <input type="email" className="form-control" id="emailBox" required name="email"
                                        onChange={formHandler} value={userData?.email} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="role" className="col-auto col-form-label">Role</label>
                                <div className="col-auto input-control">
                                    {/* <input type="text" className="form-control" id="role" required name="role"
                                        onChange={formHandler} value={userData?.role} /> */}
                                    <select className="form-select"
                                        name='role' value={userData?.role} onChange={formHandler}
                                    >
                                        <option value="">Select Role</option>
                                        {
                                            roleList?.length > 0 && roleList?.map((data) => (
                                                <option value={data?._id}>{data?.role_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="profile" className="col-auto col-form-label">Profile</label>
                                <div className="col-auto input-control">
                                    <select className="form-select"
                                        name='Profile' value={userData?.Profile} onChange={formHandler}
                                    >
                                        <option value="">Select Profile</option>
                                        {
                                            profilesList?.length > 0 && profilesList?.map((data) => (
                                                <option value={data?._id}>{data?.profile_name}</option>
                                            ))
                                        }
                                    </select>
                                    {/* <input type="text" className="form-control" id="profile" required name="Profile"
                                        onChange={formHandler} value={userData?.Profile} /> */}
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="alias" className="col-auto col-form-label">Alias</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="alias" name="Alias"
                                        onChange={formHandler} value={userData?.Alias} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="phone" className="col-auto col-form-label">Phone</label>
                                <div className="col-auto input-control">
                                    <input type="number" className="form-control" id="phone" name="phone_no"
                                        onChange={formHandler} value={userData?.phone_no} />
                                    {/* <input type="number" className="form-control" id="phone" name="Phone"
                                        onChange={formHandler} value={userData?.Phone} /> */}
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="mobNum" className="col-auto col-form-label">Mobile</label>
                                <div className="col-auto input-control">
                                    <input type="number" className="form-control" id="mobNum" name="Mobile"
                                        onChange={formHandler} value={userData?.Mobile} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="website" className="col-auto col-form-label">Website</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="website" name="Website"
                                        onChange={formHandler} value={userData?.Website} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="fax" className="col-auto col-form-label">Fax</label>
                                <div className="col-auto input-control">
                                    <input type="number" className="form-control" id="fax" name="Fax"
                                        onChange={formHandler} value={userData?.Fax} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="dob" className="col-auto col-form-label">Date of Birth</label>
                                <div className="col-auto input-control">
                                    <input type="date" className="form-control" id="dob" name="Date_of_Birth"
                                        onChange={formHandler} value={userData?.Date_of_Birth} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-40">
                        <h6 className="sec-header pb-3 pt-2 col-12">Address Information</h6>
                        <div className="col-md-12">
                            <div className="form-group mb-4">
                                <label htmlFor="street" className="col-auto col-form-label">Street</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="street" name="Street"
                                        onChange={formHandler} value={userData?.Street} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="city" className="col-auto col-form-label">City</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="city" name="City"
                                        onChange={formHandler} value={userData?.City} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="state" className="col-auto col-form-label">State</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="state" name="State"
                                        onChange={formHandler} value={userData?.State} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="zipCode" className="col-auto col-form-label">Zip Code</label>
                                <div className="col-auto input-control">
                                    <input type="number" maxLength={6} className="form-control" id="zipCode" name="Zip_Code"
                                        onChange={formHandler} value={userData?.Zip_Code} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="country" className="col-auto col-form-label">Country</label>
                                <div className="col-auto input-control">
                                    <input type="text" className="form-control" id="country" name="Country"
                                        onChange={formHandler} value={userData?.Country} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* subform::start */}
                    {/* {Helpers.arrayLength(listItems?.sections) && (
                        listItems.sections.map((section, sectionIndex) =>
                            (section?.section_name == 'User Information' || section?.section_name == 'Address Information') && (
                                <div className="row mb-40" key={sectionIndex}>
                                    <h6 className="sec-header pb-3 pt-2 col-12">{section?.section_name}</h6>
                                    <>
                                        <div className={`col-md-${section?.layouts === 1 ? '12' : '6 left-side'}`}>
                                            {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_1`]) && (
                                                section.dropped_list[`drop_${sectionIndex}_area_1`].map(area1 => (
                                                    <ModalCustomInput
                                                        key={area1?.id}
                                                        items={area1}
                                                        sectionIndex={sectionIndex}
                                                        storeData={storeData}
                                                        setStoreData={setStoreData}
                                                        error={errors[Helpers.replaceData(area1.extra_fields.field_label)]}
                                                        listItems={listItems}
                                                        setErrors={setErrors}
                                                        preview={false}
                                                        subFormData={subFormData}
                                                    />))
                                            )}
                                        </div>
                                        {section?.layouts === 2 && (
                                            <div className="col-md-6 right-side">
                                                {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_2`]) && (
                                                    section.dropped_list[`drop_${sectionIndex}_area_2`].map(area2 => (
                                                        <ModalCustomInput
                                                            key={area2?.id}
                                                            items={area2}
                                                            sectionIndex={sectionIndex}
                                                            storeData={storeData}
                                                            setStoreData={setStoreData}
                                                            error={errors[Helpers.replaceData(area2.extra_fields.field_label)]}
                                                            listItems={listItems}
                                                            setErrors={setErrors}
                                                            preview={false}
                                                            subFormData={subFormData}
                                                        />
                                                    ))
                                                )}
                                            </div>
                                        )}
                                    </>
                                </div>
                            ))
                    )} */}
                    {/* subform::end */}
                    {/* <form>
                        <div className="form-group mb-3">
                            <label htmlFor="comName" className="col-auto col-form-label">Company Name</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="comName" required />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="comNameAlias" className="col-auto col-form-label">Alias</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="comNameAlias" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="empCount" className="col-auto col-form-label">Employee Count</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="empCount" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="phone" className="col-auto col-form-label">Phone</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="phone" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="mob" className="col-auto col-form-label">Mobile</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="mob" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="fax" className="col-auto col-form-label">Fax</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="fax" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="website" className="col-auto col-form-label">Website</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="website" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description" className="col-auto col-form-label">Description</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="description" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="superAdmin" className="col-auto col-form-label">Super Admin</label>
                            <div className="col-auto input-control">
                                <select className='form-select' id='superAdmin'>
                                    <option value="kadinrhuel@gmail.com">kadinrhuel@gmail.com</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="street" className="col-auto col-form-label">Street</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="street" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="city" className="col-auto col-form-label">City</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="city" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="state" className="col-auto col-form-label">State</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="state" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="zip" className="col-auto col-form-label">Zip</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="zip" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="country" className="col-auto col-form-label">Country</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="country" />
                            </div>
                        </div>
                    </form> */}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={()=> handleCloseUserInfoModal()}>Cancel</button>
                    <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { handleSubmit(); handleCloseUserInfoModal() }}>Save</button>
                </Modal.Footer>
            </Modal>
            {/* user info modal end */}

            {/* locale modal */}
            <Modal show={showLocaleInfoModal} scrollable dialogClassName="com-details-form" onHide={handleCloseLocaleInfoModal} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="fs-5 fw-semibold">Locale Information</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <form>
                        <div className="row mb-40">
                            <h6 className="sec-header pb-3 pt-2 col-12">Locale Information</h6>
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label htmlFor="language" className="col-auto col-form-label">Language</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="language" name="Language"
                                            onChange={formHandler} value={userData?.Language}>
                                            <option value="">Select</option>
                                            <option value="English (United Kingdom)">English (United Kingdom)</option>
                                            <option value="English (United States)">English (United States)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="countryLocale" className="col-auto col-form-label">Country Locale</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="countryLocale" name="Country_Locale"
                                            onChange={formHandler} value={userData?.Country_Locale}>
                                            <option value="">Select</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="dateFormat" className="col-auto col-form-label">Date Format</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="dateFormat" name="Date_Format"
                                            onChange={formHandler} value={userData?.Date_Format}>
                                            <option value="">Select</option>
                                            <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                                            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="timeFormat" className="col-auto col-form-label" >Time Format</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="timeFormat" name="Time_Format"
                                            onChange={formHandler} value={userData?.Time_Format}>
                                            <option value="">Select</option>
                                            <option value="12 Hours">12 Hours</option>
                                            <option value="24 Hours">24 Hours</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="timeZone" className="col-auto col-form-label">Time Zone</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="timeZone" name="Time_Zone"
                                            onChange={formHandler} value={userData?.Time_Zone}>
                                            <option value="">Select</option>
                                            <option value="(GMT 5:30) India Standard Time (Asia/Kolkata)">(GMT 5:30) India Standard Time (Asia/Kolkata)</option>
                                            <option value="(GMT 5:30) India Standard Time (IST)">(GMT 5:30) India Standard Time (IST)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-40">
                            <h6 className="sec-header pb-3 pt-2 col-12">Number Format</h6>
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label htmlFor="grouping" className="col-auto col-form-label">Grouping</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="grouping" name="Grouping"
                                            onChange={formHandler} value={userData?.Grouping}>
                                            <option value="">Select</option>
                                            <option value="Space">Space</option>
                                            <option value="Comma">Comma</option>
                                            <option value="Period">Period</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="decimal" className="col-auto col-form-label">Decimal</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="decimal" name="Decimal"
                                            onChange={formHandler} value={userData?.Decimal}>
                                            <option value="">Select</option>
                                            <option value="Comma">Comma</option>
                                            <option value="Period">Period</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>

                    {/* subform::start */}
                    {/* {Helpers.arrayLength(listItems?.sections) && (
                        listItems.sections.map((section, sectionIndex) =>
                            (section?.section_name === 'Locale Information' || section?.section_name === 'Number Format') && (
                                <div className="row mb-40" key={sectionIndex}>
                                    <h6 className="sec-header pb-3 pt-2 col-12">{section?.section_name}</h6>
                                    <>
                                        <div className={`col-md-${section?.layouts === 1 ? '12' : '6 left-side'}`}>
                                            {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_1`]) && (
                                                section.dropped_list[`drop_${sectionIndex}_area_1`].map(area1 => (
                                                    <ModalCustomInput
                                                        key={area1?.id}
                                                        items={area1}
                                                        sectionIndex={sectionIndex}
                                                        storeData={storeData}
                                                        setStoreData={setStoreData}
                                                        error={errors[Helpers.replaceData(area1.extra_fields.field_label)]}
                                                        listItems={listItems}
                                                        setErrors={setErrors}
                                                        preview={false}
                                                        subFormData={subFormData}
                                                    />))
                                            )}
                                        </div>
                                        {section?.layouts === 2 && (
                                            <div className="col-md-6 right-side">
                                                {Helpers.arrayLength(section?.dropped_list?.[`drop_${sectionIndex}_area_2`]) && (
                                                    section.dropped_list[`drop_${sectionIndex}_area_2`].map(area2 => (
                                                        <ModalCustomInput
                                                            key={area2?.id}
                                                            items={area2}
                                                            sectionIndex={sectionIndex}
                                                            storeData={storeData}
                                                            setStoreData={setStoreData}
                                                            error={errors[Helpers.replaceData(area2.extra_fields.field_label)]}
                                                            listItems={listItems}
                                                            setErrors={setErrors}
                                                            preview={false}
                                                            subFormData={subFormData}
                                                        />
                                                    ))
                                                )}
                                            </div>
                                        )}
                                    </>
                                </div>
                            ))
                    )} */}
                    {/* subform::end */}
                    {/* <form>
                        <div className="form-group mb-3">
                            <label htmlFor="comName" className="col-auto col-form-label">Company Name</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="comName" required />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="comNameAlias" className="col-auto col-form-label">Alias</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="comNameAlias" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="empCount" className="col-auto col-form-label">Employee Count</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="empCount" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="phone" className="col-auto col-form-label">Phone</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="phone" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="mob" className="col-auto col-form-label">Mobile</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="mob" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="fax" className="col-auto col-form-label">Fax</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="fax" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="website" className="col-auto col-form-label">Website</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="website" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description" className="col-auto col-form-label">Description</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="description" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="superAdmin" className="col-auto col-form-label">Super Admin</label>
                            <div className="col-auto input-control">
                                <select className='form-select' id='superAdmin'>
                                    <option value="kadinrhuel@gmail.com">kadinrhuel@gmail.com</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="street" className="col-auto col-form-label">Street</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="street" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="city" className="col-auto col-form-label">City</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="city" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="state" className="col-auto col-form-label">State</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="state" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="zip" className="col-auto col-form-label">Zip</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="zip" />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="country" className="col-auto col-form-label">Country</label>
                            <div className="col-auto input-control">
                                <input type="text" className="form-control" id="country" />
                            </div>
                        </div>
                    </form> */}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={()=> handleCloseLocaleInfoModal()}>Cancel</button>
                    <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => {handleSubmit(); handleCloseLocaleInfoModal()}}>Save</button>
                </Modal.Footer>
            </Modal>
            {/* locale modal end */}

            {/* {showAddUserModal &&
                <AddUserModal
                    show={showAddUserModal}
                    handleClose={() => handleCloseUserModal}
                    newUserData={newUserData}
                    userHandler={() => userHandler}
                    handleEmailOnBlur={() => handleEmailOnBlur}
                    roleList={roleList}
                    profilesList={profilesList}
                    setShowRoleListModal={() => setShowRoleListModal(true)}
                    clearUserState={() => clearUserState}
                    handleCloseUserModal={() => handleCloseUserModal}
                    setFirstNameTouched={() => setFirstNameTouched(true)}
                    firstNameTouched={firstNameTouched}
                    emailTouched={emailTouched}
                    emailError={emailError}
                    handlePhoneNoBlur={() => handlePhoneNoBlur}
                    isReadOnly={isReadOnly}
                    phoneTouched={phoneTouched}
                    phoneError={phoneError}
                    createUser={() => createUser}
                    formValid={formValid}
                    setRoleTouched={setRoleTouched}
                    setProfileTouched={setProfileTouched}
                    profileTouched={profileTouched}
                />
            } */}

            {/* add new user modal */}
            <Modal show={showAddUserModal} onHide={handleCloseUserModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row mb-4">
                                <label htmlFor="newUserFirstName" className="col-sm-3 text-sm-end col-form-label">First Name</label>
                                <div className="col-sm-9 input-control">
                                    <input type="text" className="form-control" id="newUserFirstName" required
                                        name="firstName" value={newUserData?.firstName} onChange={userHandler}
                                        onBlur={() => setFirstNameTouched(true)}
                                    />
                                    <div className={`${!newUserData?.firstName && firstNameTouched ? 'has-validation' : ''}`}>
                                        <div className="invalid-feedback">
                                            First Name cannot be empty.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="newUserLastName" className="col-sm-3 text-sm-end col-form-label">Last Name</label>
                                <div className="col-sm-9 input-control">
                                    <input type="text" className="form-control" id="newUserLastName"
                                        name="lastName" value={newUserData?.lastName} onChange={userHandler} />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="newUserEmailBox" className="col-sm-3 text-sm-end col-form-label">Email</label>
                                <div className="col-sm-9 input-control">
                                    <input type="email" className="form-control" id="newUserEmailBox" required
                                        placeholder="An invitation will be sent to this email address."
                                        name="email" value={newUserData?.email} onChange={userHandler}
                                        onBlur={handleEmailOnBlur} />
                                    <div className={`${!newUserData?.email && emailTouched ? 'has-validation' : ''}`}>
                                        {(!newUserData?.email && emailTouched) && (
                                            <div className="invalid-feedback">
                                                Email cannot be empty.
                                            </div>
                                        )}
                                        {emailError && <div className="error-message text-danger">{emailError}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="newUserEmailBox" className="col-sm-3 text-sm-end col-form-label">Phone Number</label>
                                <div className="col-sm-9 input-control">
                                    <input type="number" className="form-control" id="newUserEmailBox" required
                                        name="phone_no" value={newUserData?.phone_no} onChange={userHandler}
                                        onBlur={handlePhoneNoBlur} readOnly={isReadOnly} />
                                    <div className={`${!newUserData?.phone_no && phoneTouched ? 'has-validation' : ''}`}>
                                        {(!newUserData?.phone_no && !newUserData.email && phoneTouched) && (
                                            <div className="invalid-feedback">
                                                Please enter the email first.
                                            </div>
                                        )}
                                        {(!newUserData?.phone_no && newUserData.email && phoneTouched) && (
                                            <div className="invalid-feedback">
                                                Phone Number cannot be empty.
                                            </div>
                                        )}
                                        {phoneError && <div className="error-message">{phoneError}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="newUserRole" className="col-sm-3 text-sm-end col-form-label">Role</label>
                                <div className="col-sm-9 input-control">
                                    <div className="input-group mt-0" onClick={() => setShowRoleListModal(true)}>
                                        <input type="text" className="form-control bg-body-tertiary cursor-pointer"
                                            id="newUserRole" required readOnly
                                            name="role"
                                            value={roleList?.filter((item) => item._id === newUserData?.role)?.[0]?.role_name}
                                            onChange={userHandler}
                                            onBlur={() => setRoleTouched(true)} />
                                        <span className="input-group-text fs-14 cursor-pointer">
                                            <i className="fa-solid fa-user-plus"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="profile" className="col-sm-3 text-sm-end col-form-label">Profile</label>
                                <div className="col-sm-9 input-control">
                                    <select className="form-select" required
                                        name="Profile" value={newUserData?.Profile} onChange={userHandler}
                                        onBlur={() => setProfileTouched(true)}>
                                        <option value="">Select Profile</option>
                                        {
                                            profilesList?.length > 0 && profilesList?.map((data) => (
                                                <option value={data?._id}>{data?.profile_name}</option>
                                            ))
                                        }
                                    </select>
                                    <div className={`${!newUserData?.Profile && profileTouched ? 'has-validation' : ''}`}>
                                        <div className="invalid-feedback">
                                            Profile cannot be empty.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => { clearUserState(); handleCloseUserModal() }}>Cancel</button>
                    <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { createUser(); handleCloseUserModal() }}
                        disabled={!formValid}>Save</button>
                </Modal.Footer>
            </Modal>
            {/* add new user modal end*/}
            {/* role list modal */}
            <Modal show={showRoleListModal} onHide={handleCloseRoleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Role List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="pb-3">Please select one role from the list.</p>
                    <div>
                        <ul className="tree mt-3">
                            <li className="parent">
                                <div className="d-inline-block ps-4 role-name-box">{user?.organization?.organizationName}</div>
                                <details open={showTreeNode} className="details">
                                    <summary></summary>
                                    {formattedRole.map((node) => (
                                        <RoleTreeNode key={node.id} node={node} />
                                    ))}
                                </details>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={handleCloseRoleModal}>Cancel</button>
                </Modal.Footer>
            </Modal>
            {/* role list modal end */}
            {/* Add New User Modal */}
            {/* <div className="modal fade" id="addNewUser" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addNewUserLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable add-new-user">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fs-5" id="addNewUserLabel">Add New User</h5>
                        </div>
                        <div className="modal-body pb-0">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row mb-4">
                                        <div className={`input-group ${!newUserData?.firstName && firstNameTouched ? 'has-validation' : ''}`}>
                                            <label htmlFor="newUserFirstName" className="col-sm-3 text-sm-end col-form-label">First Name</label>
                                            <div className="col-sm-9 input-control">
                                                <input type="text" className="form-control" id="newUserFirstName" required
                                                    name="firstName" value={newUserData?.firstName} onChange={userHandler}
                                                    onBlur={() => setFirstNameTouched(true)}
                                                />

                                                <div className="invalid-feedback">
                                                    First Name cannot be empty.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label htmlFor="newUserLastName" className="col-sm-3 text-sm-end col-form-label">Last Name</label>
                                        <div className="col-sm-9 input-control">
                                            <input type="text" className="form-control" id="newUserLastName"
                                                name="lastName" value={newUserData?.lastName} onChange={userHandler} />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className={`input-group ${!newUserData?.email && emailTouched ? 'has-validation' : ''}`}>
                                            <label htmlFor="newUserEmailBox" className="col-sm-3 text-sm-end col-form-label">Email</label>
                                            <div className="col-sm-9 input-control">
                                                <input type="email" className="form-control" id="newUserEmailBox" required
                                                    placeholder="An invitation will be sent to this email address."
                                                    name="email" value={newUserData?.email} onChange={userHandler}
                                                    onBlur={handleEmailOnBlur} />

                                                {(!newUserData?.email && emailTouched) && (
                                                    <div className="invalid-feedback">
                                                        Email cannot be empty.
                                                    </div>
                                                )}
                                                {emailError && <div className="error-message text-danger">{emailError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className={`input-group ${!newUserData?.phone_no && phoneTouched ? 'has-validation' : ''}`}>
                                            <label htmlFor="newUserEmailBox" className="col-sm-3 text-sm-end col-form-label">Phone Number</label>
                                            <div className="col-sm-9 input-control">
                                                <input type="number" className="form-control" id="newUserEmailBox" required
                                                    name="phone_no" value={newUserData?.phone_no} onChange={userHandler}
                                                    onBlur={handlePhoneNoBlur} readOnly={isReadOnly} />
                                                {(!newUserData?.phone_no && !newUserData.email && phoneTouched) && (
                                                    <div className="invalid-feedback">
                                                        Please enter the email first.
                                                    </div>
                                                )}
                                                {(!newUserData?.phone_no && newUserData.email && phoneTouched) && (
                                                    <div className="invalid-feedback">
                                                        Phone Number cannot be empty.
                                                    </div>
                                                )}
                                                {phoneError && <div className="error-message">{phoneError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className={`input-group ${!newUserData?.role && roleTouched ? 'has-validation' : ''}`}>
                                            <label htmlFor="newUserRole" className="col-sm-3 text-sm-end col-form-label">Role</label>
                                            <div className="col-sm-9 input-control">
                                                <div className="input-group mt-0" data-bs-toggle="modal" data-bs-target="#chooseRoleModal">
                                                    <input type="text" className="form-control bg-body-tertiary cursor-pointer"
                                                        id="newUserRole" required readOnly
                                                        name="role"
                                                        value={roleList?.filter((item) => item._id === newUserData?.role)?.[0]?.role_name}
                                                        onChange={userHandler}
                                                        onBlur={() => setRoleTouched(true)} />
                                                    <span className="input-group-text fs-14 cursor-pointer">
                                                        <i className="fa-solid fa-user-plus"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className={`input-group ${!newUserData?.Profile && profileTouched ? 'has-validation' : ''}`}>
                                            <label htmlFor="profile" className="col-sm-3 text-sm-end col-form-label">Profile</label>
                                            <div className="col-sm-9 input-control">
                                                <select className="form-select" required
                                                    name="Profile" value={newUserData?.Profile} onChange={userHandler}
                                                    onBlur={() => setProfileTouched(true)}>
                                                    <option value="">Select Profile</option>
                                                    {
                                                        profilesList?.length > 0 && profilesList?.map((data) => (
                                                            <option value={data?._id}>{data?.profile_name}</option>
                                                        ))
                                                    }
                                                </select>

                                                <div className="invalid-feedback">
                                                    Profile cannot be empty.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={clearUserState} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary rounded-pill btn-blue" data-bs-dismiss="modal" onClick={() => createUser()}
                                disabled={!formValid}>Save</button>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* // Add New User Modal END // */}

            {/* Choose Role Modal */}
            {/* <div className="modal fade" id="chooseRoleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="chooseRoleLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable choose-rol-modal">
                    <div className="modal-content">
                        <div className='modal-header border-bottom-0 pb-2'>
                            <h5 className="modal-title fs-5" id="chooseRoleLabel">
                                Role List
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0">
                            <p className="pb-3">Please select one role from the list.</p>
                            <div>
                                <ul className="tree mt-3">
                                    <li className="parent">
                                        <div className="d-inline-block ps-4 role-name-box">{user?.organization?.organizationName}</div>
                                        <details open={showTreeNode} className="details">
                                            <summary></summary>
                                            {formattedRole.map((node) => (
                                                // {treeData.map((node) => (
                                                <RoleTreeNode key={node.id} node={node} />
                                            ))}
                                        </details>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        </div>
                    </div>
                </div>
            </div > */}
            {/* // Choose Role Modal END // */}
        </>
    )
}
export default Users;