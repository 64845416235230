import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    createStatus: null,
    updateStatus: null,
    deleteStatus: null,
    roleData: null,
    deleteTransferStatus: null
}

const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        getRoleStart: (state) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
            state.roleData = null;
        },
        getRoleSuccess: (state, action) => {
            state.data = action?.payload?.roles;
            state.roleData = action?.payload?.roles;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        getRoleFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
            state.reload = action?.payload?.success;
        },
        createRoleStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
        },
        createRoleSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.savedRole;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        createRoleFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.createStatus = action?.payload?.success;
        },
        updateRoleStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.createStatus = null;
            state.updateStatus = null;
        },
        updateRoleSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateRole;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updateRoleFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        deleteRoleStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.deleteStatus = null;
            state.deleteTransferStatus = null;
        },
        deleteRoleSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.deleteStatus = action?.payload?.success;
        },
        deleteRoleFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.deleteStatus = action?.payload?.success;
        },
        deleteToTransferRoleStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.deleteStatus = null;
            state.deleteTransferStatus = null;
        },
        deleteToTransferRoleSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.deleteTransferStatus = action?.payload?.success;
        },
        deleteToTransferRoleFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
            state.deleteTransferStatus = action?.payload?.success;
        }
    }
});
export const {
    getRoleStart,
    getRoleSuccess,
    getRoleFailure,
    createRoleStart,
    createRoleSuccess,
    createRoleFailure,
    updateRoleStart,
    updateRoleSuccess,
    updateRoleFailure,
    deleteRoleStart,
    deleteRoleSuccess,
    deleteRoleFailure,
    deleteToTransferRoleStart,
    deleteToTransferRoleSuccess,
    deleteToTransferRoleFailure
} = roleSlice?.actions;

export default roleSlice?.reducer;