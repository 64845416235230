import React, { useEffect, useState } from 'react';
import MutiSelectDropDown from "../../Components/ModalPopup/FormModules/MutiSelectDropDown";
import { Helpers } from '../../Helpers';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Utils } from '../../Utils';
import { useSelector } from 'react-redux';

const SubformInputs = ({ item, error, handleInputChange, handleBlur, labelValue, users, preview = false, lookupData = null }) => {
  let currentOrg = useSelector((state) => state?.org);
  let [extra_fields] = useState(item?.extra_fields || {});
  let [fieldLabel] = useState(extra_fields?.field_label || '');
  let [lookupDatas, setLookupDatas] = useState([]);
  let [maxLength] = useState(extra_fields?.number_of_characters_allowed || undefined);
  let [showTooltip] = useState(extra_fields?.show_tooltip || null);
  let [isShowIconHoverTooltip] = useState(showTooltip?.checked && showTooltip?.type === 'info' && showTooltip?.message?.length > 0);
  let [isShowInputHoverTooltip] = useState(showTooltip?.checked && showTooltip?.type === 'static' && showTooltip?.message?.length > 0);

  useEffect(() => {
    if (lookupData?.hasOwnProperty(fieldLabel) && !preview) {
      let list = lookupData[fieldLabel];
      let data = [];
      if (list?.length > 0) {
        for (let x of list) {
          if (x?.data?.length > 0) {
            for (let y of x?.data) {
              let title = extra_fields?.related_list_title?.trim();
              let possibleKeys = [Helpers.replaceData(title), Helpers.replaceData(title + "_")];
              for (let key of possibleKeys) {
                if (y?.section_data?.hasOwnProperty(key) && typeof y.section_data[key] === "string" && y.section_data[key]?.length > 0) {
                  data.push(y.section_data[key]);
                  break;
                }
              }
            }
          }
        }
      }
      setLookupDatas(data);
    } else {
      setLookupDatas([]);
    }
  }, [lookupData]);

  return (
    <>
      <div className="form-group">
        <div className={`col-auto input-control`}>
          <OverlayTrigger placement="bottom-start" overlay={
            <Tooltip id={`tooltip-${item?.id}`}>
              {showTooltip?.message || ""}
            </Tooltip>
          } {...(isShowInputHoverTooltip ? {} : { show: false })}>
            <div className={`${isShowIconHoverTooltip ? 'input-group' : 'none-input-group'}`}>
              {item?.input_type === "text" && (
                <input
                  type="text"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                  placeholder={fieldLabel || ""}
                />
              )}
              {item?.input_type === "email" && (
                <input
                  type="email"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  required={item?.extra_fields?.required}
                />
              )}
              {item?.input_type === "number" && (
                <input
                  type="number"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                  onBlur={handleBlur}
                />
              )}

              {item?.input_type === "percent" && (
                <input
                  type="number"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                />
              )}

              {item?.input_type === "auto_number" && (
                <input
                  type="text"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  value={`${item?.extra_fields?.prefix || ""}${item?.extra_fields?.startingNumber || ""}${item?.extra_fields?.suffix || ""}`}
                  onChange={handleInputChange}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                />
              )}
              {item?.input_type === "decimal" && (
                <input
                  type="text"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                  step="any"
                  onBlur={handleBlur}
                />
              )}

              {item?.input_type === "longinteger" && (
                <input
                  type="text"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              )}
              {item?.input_type === 'password' && (
                <input type="password" className={`form-control ${error ? 'is-invalid' : ''}`} id={item?.id} placeholder={fieldLabel || ""} onChange={handleInputChange} value={labelValue} required={item?.extra_fields?.required} maxLength={maxLength} />
              )}
              {item?.input_type === "checkbox" && (
                <input
                  className={`form-check-input mt-2 border-dark-subtle ${error ? "is-invalid" : ""}`}
                  type="checkbox"
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  checked={extra_fields?.checked || labelValue === "X"}
                  required={extra_fields?.required}
                />
              )}
              {item?.input_type === 'radio' && (
                <div className={`form-check ${error ? 'is-invalid' : ''}`}>
                  <input type="radio" className="form-check-input mt-2" id={item?.id} name={item?.extra_fields?.name} onChange={handleInputChange} checked={labelValue === 'X'} />
                </div>
              )}
              {item?.input_type === 'textarea' && (
                <textarea className={`form-control ${error ? 'is-invalid' : ''}`} id={item?.id} rows="3" placeholder={fieldLabel || ""} onChange={handleInputChange} value={labelValue} required={item?.extra_fields?.required} maxLength={maxLength}></textarea>
              )}
              {item?.input_type === "select" && (
                <>
                  <select
                    className={`form-select ${error ? "is-invalid" : ""}`}
                    id={item?.id}
                    value={labelValue}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {item?.extra_fields?.options?.map((option, index) => (
                      <option value={option} key={`${index}`}>
                        {option}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {item?.input_type === "lookup" && (
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa fa-search position-relative z-index-3"></i>
                  </span>
                  <select
                    className={`form-select ${error ? "is-invalid" : ""}`}
                    id={item?.id}
                    placeholder={fieldLabel || ""}
                    value={labelValue || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {lookupDatas?.map((option, index) => (
                      <option value={option} key={`${index}`}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {isShowIconHoverTooltip && (
                    <span className="input-group-text">
                      <OverlayTrigger placement="bottom" overlay={<Tooltip className="position-absolute" id={`tooltip-${item?.id}`}>{showTooltip?.message}</Tooltip>}>
                        <div style={{ display: 'inline-block' }}>
                          <i className="fa-regular fa-circle-question"></i>
                        </div>
                      </OverlayTrigger>
                    </span>
                  )}
                </div>
              )}
              {item?.input_type === "multi-select" && (
                <div className={`multi-select ${error ? "is-invalid" : ""}`}>
                  <MutiSelectDropDown
                    preview={preview}
                    options={extra_fields?.options}
                    handleInputChange={handleInputChange}
                    default_value={labelValue}
                  />
                </div>
              )}
              {(item?.input_type === 'multi-lookup') && (
                <div className={`multi-select ${error ? 'is-invalid' : ''}`}>
                  <MutiSelectDropDown preview={preview} options={lookupDatas?.map(x => ({ name: x }))} handleInputChange={handleInputChange} default_value={labelValue} />
                </div>
              )}
              {item?.input_type === "currency" && (
                <div className="input-group mt-0">
                  <span className={`input-group-text bg-white ${extra_fields?.required ? "unique" : ""}`}>{Utils.currencySymbols(currentOrg?.homeCurrencies?.currency_code || "")}</span>
                  <input
                    type="text"
                    className="form-control border-start-0"
                    aria-label="Amount (to the nearest dollar)"
                    id={item?.id}
                    placeholder={fieldLabel || ""}
                    onChange={handleInputChange}
                    value={labelValue || ""}
                    maxLength={maxLength}
                    required={item?.extra_fields?.required}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9\.]/g, "");
                    }}
                    onBlur={handleBlur}
                  />
                  {isShowIconHoverTooltip && (
                    <span className="input-group-text">
                      <OverlayTrigger placement="bottom" overlay={<Tooltip className="position-absolute" id={`tooltip-${item?.id}`}>{showTooltip?.message}</Tooltip>}>
                        <div style={{ display: 'inline-block' }}>
                          <i className="fa-regular fa-circle-question"></i>
                        </div>
                      </OverlayTrigger>
                    </span>
                  )}
                </div>
              )}
              {item?.input_type === "date" && (
                <input
                  type="date"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  required={item?.extra_fields?.required}
                />
              )}
              {item?.input_type === "datetime-local" && (
                <input
                  type="datetime-local"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  required={item?.extra_fields?.required}
                />
              )}

              {item?.input_type === "image" && (
                <input
                  type="file"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  required={extra_fields?.required}
                />
              )}
              {item?.input_type === "file" && (
                <input
                  type="file"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  required={extra_fields?.required}
                />
              )}
              {item?.input_type === "url" && (
                <input
                  type="url"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                />
              )}
              {item?.input_type === "tel" && (
                <input
                  type="tel"
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  id={item?.id}
                  placeholder={fieldLabel || ""}
                  onChange={handleInputChange}
                  value={labelValue || ""}
                  maxLength={maxLength}
                  required={item?.extra_fields?.required}
                />
              )}
              {(item?.input_type === 'user' && extra_fields?.user_type === 'single_user') && (
                <div className={`multi-select ${error ? 'is-invalid' : ''}`}>
                  <label htmlFor={item?.id}>
                    {fieldLabel}
                  </label>
                  <MutiSelectDropDown preview={preview} options={preview ? (Object?.keys(labelValue)?.length > 0 ? [labelValue] : []) : (users || [])} handleInputChange={handleInputChange} default_value={typeof labelValue === "string" ? null : labelValue} singleSelect={true} isSingleUser={true} isUser={true} />
                </div>
              )}
              {(item?.input_type === 'user' && extra_fields?.user_type === 'multiple_user') && (
                <div className={`multi-select ${error ? 'is-invalid' : ''}`}>
                  <label htmlFor={item?.id}>
                    {fieldLabel}
                  </label>
                  <MutiSelectDropDown preview={preview} options={preview ? labelValue || [] : (users || [])} handleInputChange={handleInputChange} default_value={typeof labelValue === "string" ? [] : labelValue} isUser={true} />
                </div>
              )}

              {(isShowIconHoverTooltip && !['currency', 'lookup']?.includes(item?.input_type)) && (
                <span className="input-group-text">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip className="position-absolute" id={`tooltip-${item?.id}`}>{showTooltip?.message}</Tooltip>}>
                    <div style={{ display: 'inline-block' }}>
                      <i className="fa-regular fa-circle-question"></i>
                    </div>
                  </OverlayTrigger>
                </span>
              )}

              {error && <div id={item?.id} placeholder={fieldLabel || ""} className="invalid-feedback">
                {error}
              </div>
              }
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
};

export default SubformInputs;
