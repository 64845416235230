import { call, put } from "redux-saga/effects";
import { existMaterialWebhook, listRefMaterialWebhook, createWebhook, getWebhookListByModuleId, getWebhookListById, updateWebhookById, removeWebhookById } from "../../../../Services/apiService";
import { existMaterialWebhookSuccess, existMaterialWebhookFailure, listRefMaterialWebhookSuccess, listRefMaterialWebhookFailure, createWebhookSuccess, createWebhookFailure, getWebhookListByModuleIdSuccess, getWebhookListByModuleIdFailure, getWebhookListByIdSuccess, getWebhookListByIdFailure, updateWebhookByIdSuccess, updateWebhookByIdFailure, removeWebhookByIdSuccess, removeWebhookByIdFailure } from "../../../Slices/webhookSlice";
    
export function* createWebhookHandler(actions) {
    try {
        let response = yield call(createWebhook, actions?.payload);
        yield put(createWebhookSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createWebhookFailure(errResponse))
    }
}

export function* getWebhookListByModuleIdHandler(actions) {
    try {
        let response = yield call(getWebhookListByModuleId, actions?.payload);
        yield put(getWebhookListByModuleIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getWebhookListByModuleIdFailure(errResponse))
    }
}

export function* getWebhookListByIdHandler(actions) {
    try {
        let response = yield call(getWebhookListById, actions?.payload);
        yield put(getWebhookListByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getWebhookListByIdFailure(errResponse))
    }
}

export function* updateWebhookByIdHandler(actions) {
    try {
        let response = yield call(updateWebhookById, actions?.payload);
        yield put(updateWebhookByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateWebhookByIdFailure(errResponse))
    }
}

export function* removeWebhookByIdHandler(actions) {
    try {
        let response = yield call(removeWebhookById, actions?.payload);
        yield put(removeWebhookByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(removeWebhookByIdFailure(errResponse))
    }
}

export function* existMaterialWebhookHandler(actions) {
    try {
        let response = yield call(existMaterialWebhook, actions?.payload);
        yield put(existMaterialWebhookSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(existMaterialWebhookFailure(errResponse))
    }
}

export function* listRefMaterialWebhookHandler(actions) {
    try {
        let response = yield call(listRefMaterialWebhook, actions?.payload);
        yield put(listRefMaterialWebhookSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(listRefMaterialWebhookFailure(errResponse))
    }
}