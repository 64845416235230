import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import "./css/MultiPickList.css";
import { Helpers } from "../../../../Helpers";
import MultiBulkOptions from "./MultiBulkOptions";
import MultiPredefinedChoices from "./MultiPredefinedChoices";
import { handleFieldLabelUpdate } from '../../../../Utils';

function MultiPickListProperties({ show, handleClose, item, editPropertySubmit }) {
  let data = item?.extra_fields;
  let [fieldLabel, setFieldLabel] = useState(data?.field_label);
  let [list, setList] = useState(data?.options);
  let [defaultValue, setDefaultValue] = useState(data?.default_value);
  let [required, setRequired] = useState(data?.required);
  let [showTooltip, setShowTooltip] = useState(data?.show_tooltip?.checked);
  let [tooltipText, setTooltipText] = useState(data?.show_tooltip?.message);
  let [radioValue] = useState(data?.show_tooltip?.type);
  let [showBulkOptionsModal, setShowBulkOptionsModal] = useState(false);
  let [showPredefinedChoicesModal, setShowPredefinedChoicesModal] = useState(false);
  let [localErrors, setLocalErrors] = useState([]);
  let [alphabeticalOrder, setAlphabeticalOrder] = useState(data?.alphabetical_order);
  let [labelError, setLabelError] = useState(''); 

  
  const handleFieldLabelChange = (value) => {
    handleFieldLabelUpdate(value, (newLabel) => {
      setFieldLabel(newLabel);
    }, setLabelError); 
  };

  const changeListValue = (event, index, check) => {
    let value = event?.target?.value;
    setList(prevList => {
      let updatedList = [...prevList];
      if (check === "CHANGE") {
        updatedList[index] = {name : value};
      } else if (check === "ADD") {
        updatedList.splice(index + 1, 0, { name : ""});
      } else if (check === "REMOVE") {
        if (updatedList.length > 1) {
          if(defaultValue === (updatedList?.find((x, i) => i === index))?.name) {
            setDefaultValue("");
          }
          updatedList = updatedList.filter((_, i) => i !== index);
        }
      }
      return updatedList;
    });
    setLocalErrors([]);
  };

  const validateForm = () => {
    let newErrors = [];
      list?.forEach((x, index) => {
        if (!x || x?.name.trim() === "") {
          newErrors[index] = `Option ${index + 1} cannot be empty`;
        }
      });
      setLocalErrors(newErrors);
      return newErrors.length === 0;
  };

  const submit = () => {
    if(validateForm() && !labelError) {  
      editPropertySubmit({
        field_label :fieldLabel,
        options : list,
        default_value : defaultValue,
        alphabetical_order : alphabeticalOrder,
        required : required,
        show_tooltip : {
          checked : showTooltip,
          type : radioValue,
          message : tooltipText
        }
      });
      handleClose();
    }
  }
  
  return (
    <>
      <Modal show={(show && !showBulkOptionsModal && !showPredefinedChoicesModal)} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
        <Modal.Header closeButton>
          <Modal.Title>Multi Pick List Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="mb-3">
              <label htmlFor="fieldInput" className="form-label">Field Label *</label>
              <input
                type="text"
                className="form-control"
                id="fieldInput"
                placeholder="Enter field label"
                value={fieldLabel} onChange={(e) => handleFieldLabelChange(e.target.value)} />
              {labelError && <div className="text-danger">{labelError}</div>} 
            </div>

            <div className="pick-list-option">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5>Multi Pick List Option</h5>
                  <div className="dropdown">
                    <div className="settings-icon" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fas fa-cog"></i>
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                       {/* <li><a className="dropdown-item">View/ Add Unused Values</a></li> */}
                      <li><a className="dropdown-item" onClick={() => setShowPredefinedChoicesModal(true)}>Add Predefined Choices</a></li>
                      <li><a className="dropdown-item" onClick={() => setShowBulkOptionsModal(true)}>Add Bulk Options</a></li>
                    </ul>
                  </div>
                </div>
                <div className="pick-list-box bg-light p-3">
                  {Helpers.arrayLength(list) && (
                    list.map((x, index) => (
                      <div key={index} className="form-group mb-3 d-flex align-items-center">
                        <span className="drag-handle fa fa-bars me-2"></span>
                        <input type="text" className={`form-control pl-40 me-2 ${localErrors?.[index] ? 'is-invalid' : ''}`} value={x?.name} onChange={(event) => changeListValue(event, index, "CHANGE")} />
                        <i className="fa fa-plus me-2 rounded-circle bg-light p-2 border" onClick={(event) => changeListValue(event, index, "ADD")}></i>
                        <i className="fa fa-minus rounded-circle bg-light p-2 border" onClick={(event) => changeListValue(event, index, "REMOVE")}></i>
                      </div>
                    ))
                  )}
                </div>
              </div>

            <>
            <span>Select default value</span>
            <div className="dropdown mb-2">
              <button className="btn bg-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                {defaultValue || 'None'}
              </button>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                <li><a className="dropdown-item">None</a></li>
                {list?.length > 0 && (
                  list.map((x, index) => (
                    x?.name && (
                      <li key={index} onClick={() => setDefaultValue(x?.name)}>
                        <a className={`dropdown-item ${defaultValue === x?.name ? 'active' : ''}`}>
                          {x?.name}
                        </a>
                      </li>
                    )
                  ))
                )}
              </ul>
            </div>
            </>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="alphabeticalOrder" onChange={(e) => setAlphabeticalOrder(e.target.checked)} checked={alphabeticalOrder} />
              <label className="form-check-label" htmlFor="alphabeticalOrder">
                Display values alphabetically, instead of in the order entered.
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="required" onChange={(e) => setRequired(e.target.checked)} checked={required} />
              <label className="form-check-label" htmlFor="required">
                Required
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Tooltip" onChange={(e) => setShowTooltip(e.target.checked)} checked={showTooltip} />
              <label className="form-check-label" htmlFor="Tooltip">
                Show Tooltip
              </label>
            </div>

            {showTooltip && (
              <div className="tooltip-container position-relative">
                <div className="float-right">Max of 255 characters</div>
                <div className="mb-3">
                  <textarea className="form-control" rows="3" placeholder="Type tooltip message" value={tooltipText} onChange={(e) => setTooltipText(e.target.value)}></textarea>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="infoIcon" id="infoIcon" checked={radioValue === 'info'} onChange={(e) => e.target.checked} />
                  <label className="form-check-label info-icon" htmlFor="infoIcon">
                    Info Icon
                  </label>
                </div>
              </div>
            )}

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submit}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      { showBulkOptionsModal && <MultiBulkOptions show={showBulkOptionsModal} handleClose={(bulkData) => {
          setShowBulkOptionsModal(false);
          setList((items) => [...items, ...bulkData]);
        }}/>
      }

      { showPredefinedChoicesModal && <MultiPredefinedChoices show={showPredefinedChoicesModal} handleClose={(data) => {
          setShowPredefinedChoicesModal(false);
          setList((items) => [...items, ...data]);
       }}/> }
    </>
  );
}

export default MultiPickListProperties;
