import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoading: false,
        user: null,
        token: null,
        message: null,
        successStatus: null,
        emailVerified: null,
        otpSent: false,
        otpVerified: null,
        passwordUpdateSuccess: false,
        data: null
    },
    reducers: {
        loginStart: (state) => {
            state.isLoading = true;
            state.user = null;
            state.message = null;
            state.successStatus = null;
        },
        loginSuccess: (state, action) => {
            state.user = action?.payload?.userResponse;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
        },
        loginFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        verifyEmailStart: (state) => {
            state.isLoading = true;
            state.message = null;
            state.emailVerified = null
            state.data = null;
        },
        verifyEmailSuccess: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message;
            state.emailVerified = action?.payload?.success;
            state.data = action?.payload?.VerifyUser;
        },
        verifyEmailFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message;
            state.emailVerified = action?.payload?.success;
            state.data = null;
        },
        sendOtpStart: (state) => {
            state.isLoading = true;
            state.message = null;
            state.otpSent = null;
        },
        sendOtpSuccess: (state, action) => {
            state.isLoading = false;
            state.otpSent = action?.payload?.success;
            state.message = action?.payload?.message || "OTP sent successfully!";
        },
        sendOtpFailure: (state, action) => {
            state.isLoading = false;
            state.otpSent = false;
            state.message = action?.payload?.message || "Failed to send OTP.";
        },
        verifyOtpStart: (state) => {
            state.isLoading = true;
            state.message = null;
            state.otpVerified = null;
        },
        verifyOtpSuccess: (state, action) => {
            state.isLoading = false;
            state.otpVerified = action?.payload?.success;
            state.message = action.payload.message;
        },
        verifyOtpFailure: (state, action) => {
            state.isLoading = false;
            state.otpVerified = action?.payload?.success;
            state.message = action.payload.message;
        },
        forgotPasswordStart: (state) => {
            state.isLoading = true;
        },
        forgotPasswordSuccess: (state, action) => {
            state.isLoading = false;
            state.passwordUpdateSuccess = true;
            state.message = action?.payload?.message || 'Password reset successful.';
        },
        forgotPasswordFailure: (state, action) => {
            state.isLoading = false;
            state.passwordUpdateSuccess = false;
            state.message = action?.payload?.message || 'Password reset failed.';
        },
        resetState: (state) => {
            state.isLoading = false;
            state.user = null;
            state.token = null;
            state.message = null;
            state.successStatus = null;
            state.emailVerified = null;
            state.otpSent = false;
            state.otpVerified = null;
            state.passwordUpdateSuccess = false;
        },
    }
});

export const {
    loginStart,
    loginSuccess,
    loginFailure,
    resetState,
    verifyEmailStart,
    verifyEmailSuccess,
    verifyEmailFailure,
    sendOtpStart,
    sendOtpSuccess,
    sendOtpFailure,
    verifyOtpStart,
    verifyOtpSuccess,
    verifyOtpFailure,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailure
} = authSlice.actions

export default authSlice?.reducer;