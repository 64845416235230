import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import QuickChart from './QuickChart';
import FromReportChart from './FromReportChart';

function ChartModel({ show, handleClose, submitData, allList = [] }) {
    let [showQuickChartModal, setShowQuickChartModal] = useState(false);
    let [showFromReportsModal, setShowFromReportsModal] = useState(false);
    return (
        <>
            <Modal show={show && !showQuickChartModal && !showFromReportsModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Chart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        <div className="col-6 col-md-4 rounded p-3 m-2 border cursor-pointer" onClick={() => setShowQuickChartModal(true)}>
                            <h5>Quick Chart</h5>
                            <p>Create charts instantly</p>
                        </div>
                        <div className="col-6 col-md-4 rounded p-3 m-2 border cursor-pointer" onClick={() => setShowFromReportsModal(true)}>
                            <h5>From Reports</h5>
                            <p>Get from existing reports</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {showQuickChartModal && <QuickChart show={showQuickChartModal} handleClose={() => {
                setShowQuickChartModal(false);
            }} submitData={(data) => {
                submitData(data);
                handleClose();
            }} allList={allList}/>}
            {showFromReportsModal && <FromReportChart show={showFromReportsModal} handleClose={() => {
                setShowFromReportsModal(false);
            }} submitData={(data) => {
                submitData(data);
                handleClose();
            }} allList={allList}/>}
        </>
    );
}

export default ChartModel;
