import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
import Toast from "../../../Components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Dropdown, Row } from "react-bootstrap";
import { conditions, moduleForm } from "../../../Constant/moduleForm";
import MutiSelectDropDown from "../../../Components/ModalPopup/FormModules/MutiSelectDropDown";
import { FaPencil } from "react-icons/fa6";
import { toast } from "react-toastify";
import { createWorkflowStart, getEmailTemplateStart, getWorkflowByIdStart, getWorkflowModuleStart, getWorkflowStart, updateWorkflowStart, uploadImageStart } from "../../../Store/Slices/workflowSlice";
import { getAllUserDataStart } from "../../../Store/Slices/userSlice";
import { getModuleByIdStart } from "../../../Store/Slices/formSlice";
import { ApprovalUserModal, EmailNotificationModal, PreviewModal, ShowParticipantModal, TemplateModal } from "../Modals";
import { Utils } from "../../../Utils";
import { components } from 'react-select';
import { getCurrentDateTime } from "../../../Components/DateFn";
import { uploadWorkflowImage } from "../../../Services/apiService";

const CreateWorkflow = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let getAllUsers = useSelector((state) => state?.user);
    let allUsers = useSelector((state) => state?.user);
    let allRoles = useSelector((state) => state?.role);
    let workflowList = useSelector((state) => state?.workflow);
    console.log('list?', workflowList);
    let workflowResponse = workflowList?.selectedData?.[0];
    let subformList = useSelector((state) => state?.forms);
    let moduleList = subformList?.navbarList;
    let formList = subformList?.modules;

    const { id } = useParams();
    const isEdit = Boolean(id);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let data = location?.state || null;
    let module_id = data?.moduleId?._id || null;
    let module_name = data?.moduleId?.name || null;
    let rule_name = data?.ruleName || null;
    let rule_desc = data?.ruleDescription || null;

    let [showLoader, setShowLoader] = useState(false)
    let [subOptions, setSubOptions] = useState([]);
    let [displayOption, setDisplayOption] = useState({
        section1: true,
        section2: null,
        section3: null,
    });
    let [workflowData, setWorkflowData] = useState({
        approvalPermission: ['create'],
        rules: {
            workflow_base: null,
            workflow_base_type: 'create',
            contactType: 'matching_contact',
            instantActionType: '',
            selected_modules: []
        }
    });
    let [matchingContactData, setMatchingContactData] = useState({
        rules: {
            conditions: [{
                id: '',
                field: '',
                type: '',
                condition: '',
                value: ''
            }]
        }
    });
    let [approvalUserDataBkp, setApprovalUserDataBkp] = useState({
        approvalUserList: [{
            position: '1',
            userId: '',
            status: 'Approval 1',
            approvalSections: '',
            primaryField: ''
        }]
    })
    let [approvalUserData, setApprovalUserData] = useState({
        approvalUserList: []
    })
    let [isBaseCardComplete, setIsBaseCardComplete] = useState(false);
    let [editBase, setEditBase] = useState(false);
    let [editCondition, setEditCondition] = useState(false);
    let [isShowContact, setIsShowContact] = useState(true);
    let [fieldList, setFieldList] = useState([]);
    let [sectionsData, setSectionsData] = useState([]);
    let [showApprovalUserModal, setShowApprovalUserModal] = useState(false);
    let [showEmailNotificationModal, setShowEmailNotificationModal] = useState(false);
    let [validApprovalUser, setValidApprovalUser] = useState(false);
    let [showApprovalUserTable, setShowApprovalUserTable] = useState(false);

    let [emailData, setEmailData] = useState({
        rules: {
            name: '',
            to: [],
            recipients: [],
            isSingleMail: false,
            cc: [],
            cc_recipients: [],
            bcc: [],
            bcc_recipients: [],
            isSendAtBestTime: false,
            templateData: {
                templateName: '',
                subject: '',
                content: ''
            }
        }
    })
    let [departmentOptions, setDepartmentOptions] = useState([]);
    let [userData, setUserData] = useState([]);
    let [roleData, setRoleData] = useState([]);
    let [recipientSelectionType, setReceipientSelctionType] = useState('');
    let [value, setValue] = useState([]);
    let [showBcc, isShowBcc] = useState(false);
    let [showParticipantModal, setShowParticipantModal] = useState(false);
    let [inputValue, setInputValue] = useState('');
    let [isValidRecipient, setIsValidRecipient] = useState(false);
    let [selectedTemplate, setSelectedTemplate] = useState();
    let [showTemplateModal, setShowTemplateModal] = useState(false);
    let [showPreviewModal, setShowPreviewModal] = useState(false);
    let [isShowTemplateList, setIsShowTemplateList] = useState(true);
    let [validEmailTemplate, setValidEmailTemplate] = useState(false);
    let [selectedContent, setSelectedContent] = useState();

    useEffect(() => {
        dispatch(getWorkflowStart());
        dispatch(getAllUserDataStart());
        dispatch(getEmailTemplateStart(module_id));
        dispatch(getModuleByIdStart(module_id));
        dispatch(getWorkflowModuleStart(module_id));
    }, [])

    useEffect(() => {
        if (id && isEdit) {
            if (!workflowResponse)
                setShowLoader(true)
            else {
                setWorkflowData((prev) => ({
                    ...prev,
                    approvalPermission: workflowResponse.approvalPermission,
                    rules: {
                        ...prev.rules,
                        workflow_base: workflowResponse?.rules?.workflow_base,
                        workflow_base_type: workflowResponse?.rules?.workflow_base_type,
                        contactType: workflowResponse?.rules?.contactType,
                        instantActionType: workflowResponse?.rules?.instantActionType
                    }
                }));
                if (workflowResponse?.rules?.workflow_base === 'convert') {
                    setWorkflowData((prev) => ({
                        ...prev,
                        rules: {
                            ...prev.rules,
                            selected_modules: workflowResponse.rules.selected_modules
                        }
                    }))
                }
                if (workflowResponse.rules.contactType === 'matching_contact') {
                    setMatchingContactData((prev) => ({
                        ...prev,
                        rules: {
                            ...prev.rules,
                            conditions: workflowResponse.rules.conditions
                        }
                    }));
                }
                if (workflowResponse.rules.instantActionType === 'approval_user') {
                    setApprovalUserData((prev) => ({
                        ...prev,
                        approvalUserList: workflowResponse.approvalUserList
                    }));
                    setApprovalUserDataBkp((prev) => ({
                        ...prev,
                        approvalUserList: workflowResponse.approvalUserList
                    }));
                    setShowApprovalUserTable(true);
                }
                if (workflowResponse.rules.instantActionType === 'email') {
                    setEmailData((prev) => ({
                        ...prev,
                        rules: {
                            ...prev.rules,
                            name: workflowResponse.rules.emailData.name,
                            to: workflowResponse.rules.emailData.to,
                            recipients: workflowResponse.rules.emailData.recipients,
                            isSingleMail: workflowResponse.rules.emailData.isSingleMail,
                            cc: workflowResponse.rules.emailData.cc,
                            cc_recipients: workflowResponse.rules.emailData.cc_recipients,
                            bcc: workflowResponse.rules.emailData.bcc,
                            bcc_recipients: workflowResponse.rules.emailData.bcc_recipients,
                            isSendAtBestTime: workflowResponse.rules.emailData.isSendAtBestTime,
                            templateData: {
                                ...prev.rules.templateData,
                                templateName: workflowResponse.rules.emailData.templateData.templateName,
                                subject: workflowResponse.rules.emailData.templateData.subject,
                                content: workflowResponse.rules.emailData.templateData.content
                            }
                        }
                    }))
                }
                // add-on
                const selected = moduleForm[0].rule_base_options.find(
                    (option) => option.value === workflowResponse?.rules?.workflow_base
                );
                if (selected?.subOptions) {
                    setSubOptions(selected.subOptions);
                } else {
                    setSubOptions([]);
                }

                if (workflowResponse?.rules?.contactType == 'matching_contact') {
                    setIsShowContact(true);
                }
                if (workflowResponse?.rules?.contactType == 'all_contact') {
                    setIsShowContact(false);
                }
                // add-on end
                setDisplayOption({ section1: true, section2: true, section3: true });
                setShowLoader(false);
            }
        } else {
            setShowLoader(false)
        }
    }, [workflowResponse])

    useEffect(() => {
        if (workflowData.rules.workflow_base && workflowData.rules.workflow_base_type) {
            setIsBaseCardComplete(true)
        } else {
            setIsBaseCardComplete(false)
        }
    }, [workflowData.rules.workflow_base, workflowData.rules.workflow_base_type])

    useEffect(() => {
        if (formList?.length > 0) {
            setFieldList([]);
            let sections = formList?.[0]?.fields?.sections;
            setSectionsData(sections.map(option => option.section_name && {
                label: option.section_name,
                value: option.section_name
            }));
            for (var i = 0; i < sections.length; i++) {
                if (sections[i].dropped_list) {
                    let list = sections[i].dropped_list
                    let o_val = Object.values(list)
                    for (var j = 0; j < o_val.length; j++) {
                        for (var k = 0; k < o_val[j].length; k++) {
                            let _value = o_val[j][k];
                            setFieldList(list => [...list, _value])
                        }
                    }
                }
            }
        }
    }, [formList])

    useEffect(() => {
        approvalUserDataBkp.approvalUserList.forEach(element => {
            if (element.userId !== '' && element.approvalSections.length > 0 && element.primaryField !== '') {
                setValidApprovalUser(true)
            } else {
                setValidApprovalUser(false);
            }
        });
    }, [approvalUserDataBkp?.approvalUserList])

    useEffect(() => {
        if (workflowList?.updateStatus || workflowList.createStatus) {
            navigate(Utils.getNavigationUrl('/workflow-rules'))
        }
    }, [workflowList?.updateStatus, workflowList.createStatus]);

    useEffect(() => {
        if (isEdit) {
            dispatch(getWorkflowByIdStart(id));
        }
    }, [id, isEdit]);

    useEffect(() => {
        if (allUsers?.allUserData?.length > 0) {
            let arr = []
            allUsers?.allUserData?.forEach(element => {
                arr.push({ value: element._id, label: element.firstName + ' ' + element.lastName, email: element.email, type: 'user' })
            });
            setUserData(arr)
        }
    }, [allUsers])

    useEffect(() => {
        if (allRoles?.roleData?.length > 0) {
            let arr = []
            allRoles?.roleData?.forEach(element => {
                arr.push({ value: element._id, label: element.role_name, type: 'role' })
            });
            setRoleData(arr)
        }
    }, [allRoles])

    useEffect(() => {
        if (roleData.length > 0 && userData.length > 0)
            if (!emailData.rules.isSingleMail) {
                setDepartmentOptions([
                    {
                        label: "Roles",
                        options: roleData
                    },
                    {
                        label: "Users",
                        options: userData
                    }
                ]);
            }
    }, [userData, roleData])

    useEffect(() => {
        setEmailData((prev) => ({
            ...prev,
            rules: {
                ...prev.rules,
                to: []
            }
        }))
        if (emailData.rules.isSingleMail && userData.length) {
            setDepartmentOptions([
                {
                    label: "Users",
                    options: userData
                }
            ]);
        } else {
            if (roleData.length && userData.length)
                setDepartmentOptions([
                    {
                        label: "Roles",
                        options: roleData
                    },
                    {
                        label: "Users",
                        options: userData
                    }
                ]);
        }
    }, [emailData.rules.isSingleMail])

    useEffect(() => {
        value.length && value.forEach(element => {
            setIsValidRecipient(emailRegex.test(element.value))
        });
    }, [value])

    useEffect(() => {
        if (emailData.rules.templateData.templateName !== '' && emailData.rules.templateData.subject !== ''
            // && emailData.rules.templateData.content !== ''
        ) {
            setValidEmailTemplate(true);
        } else {
            setValidEmailTemplate(false);
        }
    }, [emailData.rules.templateData.templateName, emailData.rules.templateData.subject, emailData.rules.templateData.content])

    let createOption = (label) => ({
        label,
        value: label,
    });

    useEffect(() => {
        if (workflowList.uploadStatus) {
            console.log('IMAGE DATA:', workflowList.imageData)
            console.log('selected content:', selectedContent)
            let replacedContent = emailData.rules.templateData.content.split(selectedContent).join(workflowList.imageData)
            console.log('replaced content:', replacedContent)
            setUpdatedContent('')
        }
    }, [workflowList.uploadStatus])

    const formHandler = (e) => {
        try {
            const { name, value } = e?.target;
            setWorkflowData((prev) => ({
                ...prev,
                rules: {
                    ...prev.rules,
                    [name]: value
                }
            }))
            if (name == 'workflow_base') {
                const selected = moduleForm[0].rule_base_options.find(
                    (option) => option.value === value
                );
                if (selected?.subOptions) {
                    setSubOptions(selected.subOptions);
                } else {
                    setSubOptions([]);
                }
            }
            if (name == 'workflow_base_type') {
                setWorkflowData((form) => ({
                    ...form,
                    approvalPermission: (value).split("_")
                }))
            }
            if (name == 'contactType' && value == 'matching_contact') {
                setIsShowContact(true);
                setMatchingContactData((form) => ({
                    ...form,
                    rules: {
                        ...form.rules,
                        conditions: [{
                            field: '',
                            condition: '',
                            value: ''
                        }]
                    }
                }))
            }
            if (name == 'contactType' && value == 'all_contact') {
                setIsShowContact(false);
                setMatchingContactData((form) => ({
                    ...form,
                    rules: {
                        ...form.rules,
                        conditions: 'all_contacts'
                    }
                }))
            }
        } catch (err) {
            throw err;
        }
    }

    const showHideSections = (type) => {
        if (type == 'base') {
            if (editBase == true)
                setDisplayOption({ ...displayOption, section1: false })
            else
                setDisplayOption({ ...displayOption, section1: false, section2: true })
        }
        else {
            let type = typeof matchingContactData?.rules?.conditions;
            if (type === 'object') {
                let conditionList = matchingContactData?.rules?.conditions;
                if (conditionList.length > 0) {
                    for (let i = 0; i < conditionList.length; i++) {
                        if (conditionList[i].condition === "" || conditionList[i].field === "" || (conditionList[i].condition !== "is_empty" && conditionList[i].condition !== "isnt_empty" && conditionList[i].value === "")) {
                            toast.info('Please provide a valid criteria to proceed.', {
                                autoClose: 2000
                            });
                            return;
                        } else {
                            if (editCondition == true)
                                setDisplayOption({ ...displayOption, section2: false })
                            else
                                setDisplayOption({ ...displayOption, section2: false, section3: true })
                        }
                    }
                }
            }
            else if (type === 'string') {
                if (editCondition == true)
                    setDisplayOption({ ...displayOption, section2: false })
                else
                    setDisplayOption({ ...displayOption, section2: false, section3: true })
            }
        }
    }

    const formConditionHandler = (e, index) => {
        try {
            const { name, value } = e?.target;
            if (name !== 'field') {
                setMatchingContactData((prevUser) => ({
                    ...prevUser,
                    rules: {
                        ...prevUser.rules,
                        conditions: prevUser.rules.conditions.map((condition, i) =>
                            i === index ? {
                                ...condition,
                                [name]: value
                            } : condition
                        ),
                    },
                }));
            } else {
                let id = value.split('#')[0];
                let type = value.split('#')[2];
                setMatchingContactData((prevUser) => ({
                    ...prevUser,
                    rules: {
                        ...prevUser.rules,
                        conditions: prevUser.rules.conditions.map((condition, i) =>
                            i === index ? {
                                ...condition,
                                [name]: value,
                                'type': type,
                                'id': id
                            } : condition
                        ),
                    },
                }));
            }
        } catch (err) {
            throw err;
        }
    }

    const handleAddField = () => {
        const newItem = {
            field: '',
            condition: '',
            value: ''
        }
        setMatchingContactData((prevUser) => ({
            ...prevUser,
            rules: {
                ...prevUser.rules,
                conditions: [...prevUser.rules.conditions, newItem]
            },
        }));
    }

    const handleRemoveField = (index) => {
        if (matchingContactData.rules.conditions.length > 1)
            setMatchingContactData((prev) => ({
                ...prev,
                rules: {
                    ...prev.rules,
                    conditions: prev.rules.conditions.filter((_, i) => i !== index)
                }
            }));
    }

    const formApprovalUserHandler = (e, index) => {
        try {
            setApprovalUserDataBkp((prevstate) => {
                const updatedCondition = prevstate.approvalUserList.map((user, rowIndex) =>
                    rowIndex === index ? {
                        ...user,
                        [e?.target?.name]: e?.target?.value,
                        position: rowIndex + 1,
                        status: 'Approval ' + (rowIndex + 1),
                        approvalSections: []
                    } : user
                );
                return {
                    ...prevstate,
                    approvalUserList: updatedCondition
                };
            })
        } catch (err) {
            throw err;
        }
    }

    const formApprovalSectionHandler = (item, index) => {
        try {
            setApprovalUserDataBkp((prevstate) => {
                const updatedCondition = prevstate.approvalUserList.map((user, rowIndex) =>
                    rowIndex === index ? {
                        ...user,
                        approvalSections: item
                    } : user
                );
                return {
                    ...prevstate,
                    approvalUserList: updatedCondition
                };
            });
        } catch (err) {
            throw err;
        }
    }

    const formPrimaryFieldHandler = (e, index) => {
        try {
            setApprovalUserDataBkp((prevstate) => {
                const updatedCondition = prevstate.approvalUserList.map((user, rowIndex) =>
                    rowIndex === index ? {
                        ...user,
                        primaryField: e?.target?.value
                    } : user
                );
                return {
                    ...prevstate,
                    approvalUserList: updatedCondition
                };
            });
        } catch (err) {
            throw err;
        }
    }

    const handleAddUser = (rowIndex) => {
        let type = typeof approvalUserDataBkp.approvalUserList;
        if (type === 'object') {
            let conditionList = approvalUserDataBkp.approvalUserList;
            if (conditionList.length > 0) {
                if (conditionList[rowIndex].userId === "" || conditionList[rowIndex].approvalSections.length === 0 || conditionList[rowIndex].primaryField === "") {
                    toast.info('Please provide a valid information to proceed.', {
                        autoClose: 2000
                    });
                    return;
                }
            }
        }
        const newItem = {
            position: '',
            userId: '',
            status: '',
            approvalSections: '',
            primaryField: ''
        }
        setApprovalUserDataBkp({
            ...approvalUserDataBkp,
            approvalUserList: [...approvalUserDataBkp.approvalUserList, newItem]
        })
    }

    const handleRemoveUser = (index) => {
        if (approvalUserDataBkp.approvalUserList.length > 1) {
            setApprovalUserDataBkp((prev) => ({
                ...prev,
                approvalUserList: prev.approvalUserList.filter((_, i) => i !== index),
            }));
            updateApprovalUserHandler()
        }
    }

    const updateApprovalUserHandler = () => {
        try {
            setApprovalUserDataBkp((prevstate) => {
                const updatedCondition = prevstate.approvalUserList.map((user, rowIndex) =>
                ({
                    ...user,
                    position: rowIndex + 1,
                    status: 'Approval ' + (rowIndex + 1)
                })
                );
                return {
                    ...prevstate,
                    approvalUserList: updatedCondition
                };
            })
        } catch (err) {
            throw err;
        }
    }

    const handleSaveApprovalUser = () => {
        if (workflowData.rules.instantActionType === 'approval_user') {
            let type = typeof approvalUserDataBkp.approvalUserList;
            if (type === 'object') {
                let conditionList = approvalUserDataBkp.approvalUserList;
                if (conditionList.length > 0) {
                    for (var i = 0; i < conditionList.length; i++) {
                        if (conditionList[i].userId === "" || conditionList[i].approvalSections.length === 0 || conditionList[i].primaryField === "") {
                            toast.info('Please provide a valid information to proceed.', {
                                autoClose: 2000
                            });
                            return;
                        }
                    }
                }
            }
        }
        setApprovalUserData((prev) => ({
            ...prev,
            approvalUserList: approvalUserDataBkp.approvalUserList
        }));
        setShowApprovalUserModal(false);
        setShowApprovalUserTable(true);
    }

    const mailHandler = (e) => {
        const { name, value, checked } = e.target
        setEmailData((prev) => ({
            ...prev,
            rules: {
                ...prev.rules,
                [name]: name === 'name' ? value : checked
            }
        }))
    }

    const HideGroupHeading = (props) => {
        return (
            <div
                className="collapse-group-heading"
                onClick={() => {
                    document
                        .querySelector(`#${props.id}`)
                        .parentElement.parentElement.classList.toggle("collapsed-group");
                }}
            >
                <components.GroupHeading {...props} />
            </div>
        );
    };

    const HideGroupMenuList = (props) => {
        let new_props = {
            ...props,
            children: Array.isArray(props.children)
                ? props.children.map((c, idx) =>
                    idx === 0
                        ? c
                        : { ...c, props: { ...c.props, className: "collapsed-group" } }
                )
                : props.children,
        };

        return <components.MenuList {...new_props} />;
    };

    const handleToUserChange = (selected) => {
        let recipients = [];
        selected.length && selected.forEach(element => {
            if (element.type === 'user') {
                recipients.push(element.email)
            } else if (element.type === 'role') {
                allUsers?.allUserData?.filter((user) => user.role.role_name === element.label).map((data) => recipients.push(data.email));
            }
        });
        setEmailData((prev) => ({
            ...prev,
            rules: {
                ...prev.rules,
                to: selected,
                recipients: recipients
            }
        }))
    }

    const handleCcUserChange = (selected) => {
        let recipients = [];
        selected.length && selected.forEach(element => {
            if (element.type === 'user') {
                recipients.push(element.email)
            } else if (element.type === 'role') {
                allUsers?.allUserData?.filter((user) => user.role.role_name === element.label).map((data) => recipients.push(data.email));
            }
        });
        setEmailData((prev) => ({
            ...prev,
            rules: {
                ...prev.rules,
                cc: selected,
                cc_recipients: recipients
            }
        }))
    }

    const handleBccUserChange = (selected) => {
        let recipients = [];
        selected.length && selected.forEach(element => {
            if (element.type === 'user') {
                recipients.push(element.email)
            } else if (element.type === 'role') {
                allUsers?.allUserData?.filter((user) => user.role.role_name === element.label).map((data) => recipients.push(data.email));
            }
        });
        setEmailData((prev) => ({
            ...prev,
            rules: {
                ...prev.rules,
                bcc: selected,
                bcc_recipients: recipients
            }
        }))
    }

    const handleEmailData = (e) => {
        const { name, value } = e?.target;
        setEmailData((form) => ({
            ...form,
            rules: {
                ...form.rules,
                templateData: {
                    ...form.rules.templateData,
                    [name]: value
                }
            }
        }));
    }

    const extractImages = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const images = Array.from(doc.querySelectorAll('img')); // Get all <img> elements
        return images.map((img) => img.src); // Extract the `src` attribute
    };

    const base64ToFile = (base64String, filename) => {
        if (base64String) {
            const arr = base64String?.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
    };

    const replaceBase64WithUrl = (html) => {
        // Define a regex pattern to match Base64 strings in HTML
        const base64Regex = /data:image\/[a-zA-Z]+;base64,[^\s"]+/g;

        // Replace Base64 strings with a normal URL
        // For example: Replace with placeholder URLs like "/path/to/image.jpg"
        const updatedHtml = html.replace(base64Regex, (match) => {
            console.log('Found Base64:', match); // Log the Base64 string
            return '/path/to/your/image.jpg'; // Replace with your desired URL
        });

        return updatedHtml;
    };

    let [uploadedUrl, setUploadedUrl] = useState([]);
    let [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        let content = emailData.rules.templateData.content;
        for (var i = 0; i < uploadedUrl.length; i++) {
            content = content.replace(uploadedUrl[i].baseUrl, uploadedUrl[i].apiUrl)
        }
        setEmailData((prev) => ({
            ...prev,
            rules: {
                ...prev.rules,
                templateData: {
                    ...prev.rules.templateData,
                    content: content
                }
            }
        }));
        setShowEmailNotificationModal(false);
    }, [uploadedUrl])

    const handleSaveEmailNotification = () => {
        setImageUrls(extractImages(emailData.rules.templateData.content))
        if (imageUrls.length) {
            imageUrls.forEach(async (element) => {
                if (element.startsWith('data:image/')) {
                    setSelectedContent(element);
                    const file = base64ToFile(element, `uploaded-image-${getCurrentDateTime()}.jpg`);
                    console.log('file data?', file)
                    const formData = new FormData();
                    formData.append("file", file);
                    try {
                        const response = await uploadWorkflowImage({ payload: formData });
                        if (response?.data?.s3Url) {
                            let obj = {
                                baseUrl: element,
                                apiUrl: response?.data?.s3Url
                            }
                            setUploadedUrl((prevItems) => [...prevItems, obj])
                        }
                    } catch (error) {
                        console.error("There was an error uploading the file:", error);
                    }
                } else {
                    setShowEmailNotificationModal(false)
                }
            })
        } else {
            setShowEmailNotificationModal(false);
        }
    }

    // const handleSaveEmailNotificationOld = () => {
    //     console.log('save email', emailData.rules.templateData)
    //     // setShowEmailNotificationModal(false);
    //     // const imageUrls = extractImages(emailData.rules.templateData.content);
    //     setImageUrls(extractImages(emailData.rules.templateData.content))
    //     if (imageUrls.length) {
    //         imageUrls.forEach(async (element) => {
    //             if (element.startsWith('data:image/')) {
    //                 setSelectedContent(element);
    //                 const file = base64ToFile(element, `uploaded-image-${getCurrentDateTime()}.jpg`);
    //                 console.log('file data?', file)
    //                 const formData = new FormData();
    //                 formData.append("file", file);
    //                 try {
    //                     // dispatch(uploadImageStart({
    //                     //     payload: formData
    //                     // }))
    //                     const response = await uploadWorkflowImage({ payload: formData });
    //                     console.log('respose-----', response)
    //                     if (response?.data?.s3Url) {
    //                         setUploadedUrl((prevItems) => [...prevItems, response?.data?.s3Url])

    //                         // const base64Regex = /data:image\/[a-zA-Z]+;base64,[^\s"]+/g;
    //                         // const updatedHtml = emailData.rules.templateData.content.replace(base64Regex, (match) => {
    //                         //     console.log('Found Base64:', match); // Log the Base64 string
    //                         //     return response.data.s3Url; // Replace with your desired URL
    //                         // });
    //                         // console.log('UPDATED HTML:', updatedHtml)
    //                     }
    //                 } catch (error) {
    //                     console.error("There was an error uploading the file:", error);
    //                 }
    //             }
    //         })
    //     }
    // }
    // new

    // const [htmlContent, setHtmlContent] = useState('');
    // const [processedHtml, setProcessedHtml] = useState('');

    // const extractBase64Urls = (html) => {
    //     const base64Regex = /data:image\/[a-zA-Z]+;base64,([^\s"]+)/g;
    //     const base64Urls = [];
    //     let match;
    //     while ((match = base64Regex.exec(html)) !== null) {
    //         base64Urls.push(match[0]);
    //     }
    //     return base64Urls;
    // };

    // const _replaceBase64WithUrl = async (html) => {
    //     const base64Urls = extractBase64Urls(html); // Get all Base64 URLs from the HTML


    //     // Iterate over each Base64 URL and replace it with the dynamic URL
    //     const updatedHtmlPromises = base64Urls.map(async (base64Url) => {
    //         try {
    //             // Prepare FormData to send the Base64 URL to the API
    //             const file = base64ToFile(base64Url, `uploaded-image-${getCurrentDateTime()}.jpg`);
    //             const formData = new FormData();
    //             formData.append("file", file);

    //             // Send the FormData to the API (replace with your API URL)
    //             const response = await uploadWorkflowImage({ payload: formData });

    //             console.log('REPLACE:', html.replace(base64Url, response.data.s3Url))
    //             // Replace Base64 URL with the dynamic URL returned from the API
    //             return html.replace(base64Url, response.data.s3Url); // Assuming 'url' is returned by API
    //         } catch (error) {
    //             console.error('Error fetching dynamic URL:', error);
    //             return html; // Return the original HTML if there's an error
    //         }
    //     });

    //     console.log('html:', html)

    //     // Wait for all promises to resolve and get the final updated HTML
    //     const updatedHtmlArray = await Promise.all(updatedHtmlPromises);
    //     console.log('upt:', updatedHtmlArray)


    //     return updatedHtmlArray[0]; // Return the processed HTML
    // };

    // const handleSaveEmailNotificationNew = async () => {
    //     // const updatedHtml = await _replaceBase64WithUrl(emailData.rules.templateData.content);
    //     // setProcessedHtml(updatedHtml);
    //     // console.log('updhtml:', updatedHtml)
    //     // const updatedHtml = await replaceBase64WithUrl(emailData.rules.templateData.content);
    // }

    let handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => [...prev, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    const handleRecipients = () => {
        let recipients = [];
        value.length && value.forEach(element => {
            recipients.push(element.value)
        });

        if (recipientSelectionType === 'base')
            setEmailData((prev) => ({
                ...prev,
                rules: {
                    ...prev.rules,
                    to: [...prev.rules.to, ...value],
                    recipients: [...prev.rules.recipients, ...recipients]
                }
            }))
        if (recipientSelectionType === 'cc')
            setEmailData((prev) => ({
                ...prev,
                rules: {
                    ...prev.rules,
                    cc: [...prev.rules.cc, ...value],
                    cc_recipients: [...prev.rules.cc_recipients, ...recipients]
                }
            }))
        if (recipientSelectionType === 'bcc')
            setEmailData((prev) => ({
                ...prev,
                rules: {
                    ...prev.rules,
                    bcc: [...prev.rules.bcc, ...value],
                    bcc_recipients: [...prev.rules.bcc_recipients, ...recipients]
                }
            }))
    }

    const setUpdatedContent = (content) => {
        setEmailData((prev) => ({
            ...prev,
            rules: {
                ...prev.rules,
                templateData: {
                    ...prev.rules.templateData,
                    content: content
                }
            }
        }))
    }

    const convertContentToHtml = () => {
        let obj = emailData.rules.templateData;
        let htmlHeader = `<html lang="en">
        <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>Email Template</title>
    
            <style media="all" type="text/css">
                body{
                    margin: 0;
                }
            </style>
        </head>
    <body>`
        let htmlFooter = ` </body>
    </html>`;
        obj.content = htmlHeader + emailData?.rules?.templateData?.content + htmlFooter;
        return obj;
    }

    const handleSubmit = () => {
        if (workflowData.rules.workflow_base !== 'convert') {
            if (workflowData.rules.instantActionType === '') {
                toast.info('You cannot have a Workflow condition without any action.', {
                    autoClose: 2000
                })
                return;
            }
            if (workflowData.rules.instantActionType === 'approval_user') {
                let userList = approvalUserData.approvalUserList;
                if (userList.length === 0 && !workflowData?.rules?.selected_modules?.length) {
                    toast.info('You cannot have a Workflow condition without any action.', {
                        autoClose: 2000
                    })
                    return;
                }
            }
        }
        let obj = {
            moduleId: module_id,
            ruleDescription: rule_desc,
            ruleName: rule_name,
            rules: {
                workflow_base: workflowData.rules.workflow_base,
            }
        }
        if (workflowData.rules.workflow_base === 'convert') {
            obj.rules['selected_modules'] = workflowData?.rules?.selected_modules
        } else {
            obj.rules['instantActionType'] = workflowData.rules.instantActionType
            obj['approvalPermission'] = workflowData.approvalPermission
            obj.rules['contactType'] = workflowData.rules.contactType
            obj.rules['workflow_base_type'] = workflowData.rules.workflow_base_type
        }
        if (workflowData.rules.contactType === 'matching_contact') {
            obj.rules['conditions'] = matchingContactData.rules.conditions
        }
        if (workflowData.rules.instantActionType === 'approval_user') {
            obj['approvalUserList'] = approvalUserData.approvalUserList
        }
        if (workflowData.rules.instantActionType === 'email') {
            obj.rules['emailData'] = {
                name: emailData.rules.name,
                to: emailData.rules.to,
                recipients: emailData.rules.recipients,
                isSingleMail: emailData.rules.isSingleMail,
                cc: emailData.rules.cc,
                cc_recipients: emailData.rules.cc_recipients,
                bcc: emailData.rules.bcc,
                bcc_recipients: emailData.rules.bcc_recipients,
                isSendAtBestTime: emailData.rules.isSendAtBestTime
            }
            let data = convertContentToHtml();
            obj.rules.emailData['templateData'] = data
        }
        dispatch(createWorkflowStart(obj));
        console.log('payload:', obj)
    }

    const handleUpdate = () => {
        if (workflowData.rules.workflow_base !== 'convert') {
            if (workflowData.rules.instantActionType === '') {
                toast.info('You cannot have a Workflow condition without any action.', {
                    autoClose: 2000
                })
                return;
            }
            if (workflowData.rules.instantActionType === 'approval_user') {
                let userList = approvalUserData.approvalUserList;
                if (userList.length === 0 && !workflowData?.rules?.selected_modules?.length) {
                    toast.info('You cannot have a Workflow condition without any action.', {
                        autoClose: 2000
                    })
                    return;
                }
            }
        }
        let obj = {
            moduleId: module_id,
            ruleDescription: rule_desc,
            ruleName: rule_name,
            rules: {
                workflow_base: workflowData.rules.workflow_base,
            }
        }
        if (workflowData.rules.workflow_base === 'convert') {
            obj.rules['selected_modules'] = workflowData?.rules?.selected_modules
        } else {
            obj.rules['instantActionType'] = workflowData.rules.instantActionType
            obj['approvalPermission'] = workflowData.approvalPermission
            obj.rules['contactType'] = workflowData.rules.contactType
            obj.rules['workflow_base_type'] = workflowData.rules.workflow_base_type
        }
        if (workflowData.rules.contactType === 'matching_contact') {
            obj.rules['conditions'] = matchingContactData.rules.conditions
        }
        if (workflowData.rules.instantActionType === 'approval_user') {
            obj['approvalUserList'] = approvalUserData.approvalUserList
        }
        if (workflowData.rules.instantActionType === 'email') {
            obj.rules['emailData'] = {
                name: emailData.rules.name,
                to: emailData.rules.to,
                recipients: emailData.rules.recipients,
                isSingleMail: emailData.rules.isSingleMail,
                cc: emailData.rules.cc,
                cc_recipients: emailData.rules.cc_recipients,
                bcc: emailData.rules.bcc,
                bcc_recipients: emailData.rules.bcc_recipients,
                isSendAtBestTime: emailData.rules.isSendAtBestTime
            }
            let data = convertContentToHtml();
            obj.rules.emailData['templateData'] = data
        }
        if (id) {
            dispatch(updateWorkflowStart({
                id, payload: obj
            }))
        }
        // console.log('UPDATE PAYLOAD:', obj)
    }

    return (
        <React.Fragment>
            <Loader isVisible={showLoader || workflowList?.isLoading} />
            {<Toast />}
            <main className="py-md-4 content-section wf-content">
                <div className="container-fluid">
                    <h6 className="text-start fw-semibold">{rule_name}</h6>
                    <p className="text-start m-0">@ {module_name}</p>
                    <p className="text-start">{rule_desc}</p>
                    <Row>
                        <Col lg={8} style={{ marginBottom: '65px' }}>
                            <div className="digy-workflow-card">
                                {displayOption?.section1 && (
                                    <>
                                        <p className="fw-semibold">WHEN</p>
                                        <div className="form-group card mb-2 card-border">
                                            <p className="text-start">Execute this workflow rule based on</p>
                                            <select
                                                className="form-select mb-3"
                                                name="workflow_base"
                                                value={workflowData.rules.workflow_base}
                                                onChange={formHandler}
                                                required
                                                disabled={id && isEdit}
                                            >
                                                <option value="" disabled selected>Select</option>
                                                {moduleForm[0].rule_base_options.map((option, i) => (
                                                    <option value={option.value}
                                                        disabled={option.disabled ? true : null}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {subOptions.length > 0 && (
                                                <select
                                                    className="form-select mb-3"
                                                    id="sub-select"
                                                    name="workflow_base_type"
                                                    value={workflowData.rules.workflow_base_type}
                                                    onChange={formHandler}
                                                    required>
                                                    {subOptions.map((subOption) => (
                                                        <option value={subOption.value}
                                                            disabled={subOption.disabled ? true : null}>
                                                            {subOption.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                            {workflowData.rules.workflow_base == 'convert' && (
                                                <>
                                                    < div className="mb-3">
                                                        <MutiSelectDropDown preview={false} options={moduleList?.filter(x => x?._id !== workflowData?.moduleId) || []} handleInputChange={(event) => {
                                                            setWorkflowData((prev) => ({
                                                                ...prev,
                                                                rules: {
                                                                    ...prev.rules,
                                                                    ["selected_modules"]: event?.target?.value
                                                                }
                                                            }));
                                                        }} default_value={workflowData?.rules?.selected_modules || []} needConfirmation={true} />
                                                    </div>
                                                </>
                                            )}
                                            {workflowData.rules.workflow_base !== 'convert' &&
                                                <button type="button" className="btn btn-primary float-end w-25" onClick={() => showHideSections('base')}
                                                    disabled={!isBaseCardComplete}>Next</button>
                                            }
                                        </div>
                                    </>
                                )}
                                {!displayOption?.section1 && (
                                    <div className="form-group card text-start mb-2">
                                        <p>This rule will be executed when a <b>{module_name}</b> is <b>{workflowData?.rules?.workflow_base_type}</b>
                                            <FaPencil className="float-end" onClick={() => { setEditBase(true); setDisplayOption({ ...displayOption, section1: true }) }} />
                                        </p>
                                    </div>
                                )}
                                {(displayOption?.section2 && workflowData.rules.workflow_base !== 'convert') && (
                                    <>
                                        <p className="fw-semibold">CONDITION</p>
                                        <div className={`form-group card mb-2 form-group card mb-2 ${!displayOption?.section1 && 'card-border'}`}>
                                            <p className="text-start">Which contacts would you like to apply the rule to?</p>
                                            <div className="form-control d-flex">
                                                <div>
                                                    <input type="radio" value="matching_contact" name="contactType" onChange={formHandler} checked={workflowData?.rules?.contactType === "matching_contact"} /> Contacts matching certain conditions
                                                </div>
                                                <div>
                                                    <input className="ms-5" type="radio" value="all_contact" name="contactType" onChange={formHandler} checked={workflowData?.rules?.contactType === "all_contact"} /> All Records
                                                </div>
                                            </div>
                                            {typeof matchingContactData?.rules?.conditions == 'object' &&
                                                <>
                                                    {isShowContact && matchingContactData?.rules?.conditions?.map((dta, index) => (
                                                        <Row className="mt-3 m-0">
                                                            <select
                                                                className="form-select mb-3 me-3 col-3 w-22"
                                                                id="sub-select"
                                                                name="field"
                                                                value={dta?.field}
                                                                onChange={(e) => formConditionHandler(e, index)}>
                                                                <option value="" disabled selected>Select</option>
                                                                {fieldList.map((data) => (
                                                                    <option value={data?.id + '#' + data?.extra_fields?.field_label + '#' + data?.input_type}>
                                                                        {data?.extra_fields?.field_label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <select
                                                                className="form-select mb-3 me-3 col-3 w-22"
                                                                id="sub-select"
                                                                name="condition"
                                                                value={dta.condition}
                                                                onChange={(e) => formConditionHandler(e, index)}
                                                            >
                                                                <option value="" disabled selected>Select</option>
                                                                {conditions.map((condition) => (
                                                                    <option value={condition.name}>
                                                                        {condition.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {dta?.condition !== 'is_empty' && dta?.condition !== 'isnt_empty' && <>
                                                                {dta?.type == 'select' || dta?.type == 'multi-select' ?
                                                                    <select
                                                                        className="form-select mb-3 me-3 col-3 w-22"
                                                                        id="sub-select"
                                                                        name="value"
                                                                        value={dta.value}
                                                                        onChange={(e) => formConditionHandler(e, index)}
                                                                    >
                                                                        <option value="" disabled selected>Select</option>
                                                                        {dta?.type == 'select' && fieldList.map((field) =>
                                                                            field.id == dta.id && (
                                                                                <>
                                                                                    {field?.extra_fields?.options.map((item) => (
                                                                                        <option key={item} value={item}>
                                                                                            {item}
                                                                                        </option>
                                                                                    ))}
                                                                                </>
                                                                            ))
                                                                        }
                                                                        {dta?.type == 'multi-select' && fieldList.map((field) =>
                                                                            field.id == dta.id && (
                                                                                <>
                                                                                    {field?.extra_fields?.options.map((item) => (
                                                                                        <option key={item.name} value={item.name}>
                                                                                            {item.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    :
                                                                    <input
                                                                        type="text"
                                                                        name="value"
                                                                        className="form-control me-3 col-3 w-22"
                                                                        value={dta.value}
                                                                        onChange={(e) => formConditionHandler(e, index)}
                                                                    />
                                                                }
                                                            </>
                                                            }
                                                            <button type="button" className="form-control btn btn-light col-3 me-2 w-10 rounded-circle"
                                                                onClick={handleAddField}>+</button>
                                                            <button type="button" className="form-control btn btn-light col-3 w-10 rounded-circle"
                                                                onClick={() => handleRemoveField(index)}>-</button>
                                                        </Row>
                                                    ))}
                                                </>
                                            }
                                            <button type="button" className="btn btn-primary float-end w-25 mt-2" onClick={() => showHideSections('condition')}>Next</button>
                                        </div>
                                    </>
                                )}
                                {displayOption?.section2 != null && !displayOption?.section2 && workflowData.rules.workflow_base !== 'convert' && (
                                    <div className="form-group card wf-card mb-2">
                                        {typeof matchingContactData?.rules?.conditions == 'object' ?
                                            <>
                                                {matchingContactData?.rules?.conditions.map((list, index) => (
                                                    <>
                                                        <p className="text-start"><span>{index + 1}.</span>{list.field.split('#')[1]} <b>{list.condition}</b> {list.value}
                                                            {index == 0 &&
                                                                <FaPencil className="float-end" onClick={() => { setEditCondition(true); setDisplayOption({ ...displayOption, section2: true }) }} />
                                                            }
                                                        </p>
                                                    </>
                                                ))}
                                            </> : <>
                                                <p className="text-start">All records
                                                    <FaPencil className="float-end" onClick={() => { setEditCondition(true); setDisplayOption({ ...displayOption, section2: true }) }} />
                                                </p>
                                            </>
                                        }
                                    </div>
                                )}
                                {(displayOption?.section3 && workflowData.rules.workflow_base !== 'convert') && (
                                    <>
                                        {(!id && !isEdit) &&
                                            <div className="form-group card mb-2">
                                                <Dropdown className='me-3' autoClose={`outside`}>
                                                    <Dropdown.Toggle variant='light' id="time-filter-dropdown" className='form-select- mb-3 me-3 col-3 w-22 flat-btn bg-white with-arrow text-dark text-start'>
                                                        <span className='pe-3'>Instant Actions</span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='p-0 fs-14'>
                                                        <Dropdown.Item eventKey="Approval" onClick={() => {
                                                            setWorkflowData((prev) => ({
                                                                ...prev,
                                                                rules: {
                                                                    ...prev.rules,
                                                                    instantActionType: 'approval_user'
                                                                }
                                                            }));
                                                            setShowApprovalUserModal(true)
                                                        }}>
                                                            Approval
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="Email Notification" onClick={() => {
                                                            setWorkflowData((prev) => ({
                                                                ...prev,
                                                                rules: {
                                                                    ...prev.rules,
                                                                    instantActionType: 'email'
                                                                }
                                                            }));
                                                            setShowEmailNotificationModal(true)
                                                        }}>
                                                            Email Notification
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        }
                                        {/* approval user table::start*/}
                                        {workflowData?.rules?.instantActionType === 'approval_user' && showApprovalUserTable && approvalUserData?.approvalUserList.length > 0 &&
                                            <div className="">
                                                <div className="form-group card mb-2">
                                                    <h6 className="fs-5">Instant Action - <span className="fw-semibold">User Approval</span>
                                                        <span>
                                                            <FaPencil className="float-end" style={{ fontSize: '14px' }}
                                                                onClick={() => {
                                                                    setWorkflowData((prev) => ({
                                                                        ...prev,
                                                                        rules: {
                                                                            ...prev.rules,
                                                                            instantActionType: 'approval_user'
                                                                        }
                                                                    }));
                                                                    setApprovalUserDataBkp((prev) => ({
                                                                        ...prev,
                                                                        approvalUserList: approvalUserData.approvalUserList
                                                                    }));
                                                                    setShowApprovalUserModal(true);
                                                                }}
                                                            />
                                                        </span>
                                                    </h6>
                                                    <div className="tbl-container rounded-table d-block">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover hover-icon-table shift-hour-table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Approval User</th>
                                                                        <th>Approval Section</th>
                                                                        <th>Primary Field</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {approvalUserData?.approvalUserList?.map((dta, index) =>
                                                                        dta?.approvalSections?.length > 0 &&
                                                                        dta?.primaryField !== '' &&
                                                                        dta?.userId !== '' && (
                                                                            <tr>
                                                                                <td>{getAllUsers?.allUserData?.filter((data) => data._id === dta?.userId)?.[0]?.firstName + ' ' + getAllUsers?.allUserData?.filter((data) => data._id === dta?.userId)?.[0]?.lastName}</td>
                                                                                <td>{dta?.approvalSections?.length > 0 && dta?.approvalSections?.map(item => item.value)?.join(', ')}</td>
                                                                                <td>{dta?.primaryField}</td>
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div>
                                        }
                                        {workflowData?.rules?.instantActionType === 'email' &&
                                            <div>
                                                <div className="form-group card mb-2">
                                                    <h6 className="fs-5">Instant Action - <span className="fw-semibold">Email Notification</span>
                                                        <span><FaPencil className="float-end" style={{ fontSize: '14px' }}
                                                            onClick={() => {
                                                                setWorkflowData((prev) => ({
                                                                    ...prev,
                                                                    rules: {
                                                                        ...prev.rules,
                                                                        instantActionType: 'email'
                                                                    }
                                                                }));
                                                                setSelectedTemplate('');
                                                                setShowEmailNotificationModal(true);
                                                            }} /></span>
                                                    </h6>
                                                    <>
                                                        <div className="tbl-container rounded-table d-block">
                                                            <div className="table-responsive">
                                                                <table className="table table-hover hover-icon-table shift-hour-table mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Selected Template</th>
                                                                            <th>Is Single Mail</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{emailData?.rules?.name}</td>
                                                                            <td>{emailData?.rules?.templateData?.templateName}</td>
                                                                            <td>{emailData?.rules?.templateData?.isSingleMail ? 'Yes' : 'No'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </>
                                                </div >
                                            </div>
                                        }
                                        {/* tables::end */}
                                    </>
                                )}
                            </div>
                        </Col>
                        <Col lg={12} className="text-start ps-0 form-footer position-absolute bottom-0 w-100 bg-white border-top ps-4 py-3 d-flex
                                                position-fixed">
                            <button className="btn btn-outline-primary rounded-pill btn-blue me-2" type="button"
                                onClick={() => navigate(Utils.getNavigationUrl('/workflow-rules'))}>Cancel</button>
                            {(id && isEdit) ?
                                <button
                                    className="btn btn-primary rounded-pill btn-blue"
                                    type="button"
                                    onClick={() => handleUpdate()}
                                    disabled={workflowData.rules.workflow_base !== 'convert' && !isBaseCardComplete}>
                                    Update
                                </button>
                                :
                                <button
                                    className="btn btn-primary rounded-pill btn-blue"
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    disabled={!isBaseCardComplete}>
                                    Submit
                                </button>
                            }
                        </Col>
                    </Row>
                </div>
            </main>
            {showApprovalUserModal &&
                <ApprovalUserModal
                    showApprovalUserModal={showApprovalUserModal}
                    setShowApprovalUserModal={setShowApprovalUserModal}
                    module_name={module_name}
                    approvalUserDataBkp={approvalUserDataBkp}
                    getAllUsers={getAllUsers}
                    formApprovalUserHandler={formApprovalUserHandler}
                    formApprovalSectionHandler={formApprovalSectionHandler}
                    formPrimaryFieldHandler={formPrimaryFieldHandler}
                    sectionsData={sectionsData}
                    fieldList={fieldList}
                    handleAddUser={handleAddUser}
                    handleRemoveUser={handleRemoveUser}
                    handleSaveApprovalUser={handleSaveApprovalUser}
                    validApprovalUser={validApprovalUser}
                />
            }
            {showEmailNotificationModal &&
                <EmailNotificationModal
                    showEmailNotificationModal={showEmailNotificationModal}
                    setShowEmailNotificationModal={setShowEmailNotificationModal}
                    module_name={module_name}
                    handleSaveEmailNotification={handleSaveEmailNotification}
                    mailHandler={mailHandler}
                    emailData={emailData}
                    departmentOptions={departmentOptions}
                    HideGroupHeading={HideGroupHeading}
                    HideGroupMenuList={HideGroupMenuList}
                    handleToUserChange={handleToUserChange}
                    handleCcUserChange={handleCcUserChange}
                    handleBccUserChange={handleBccUserChange}
                    setReceipientSelctionType={setReceipientSelctionType}
                    setValue={setValue}
                    showBcc={showBcc}
                    isShowBcc={isShowBcc}
                    setShowParticipantModal={setShowParticipantModal}
                    setIsShowTemplateList={setIsShowTemplateList}
                    setShowTemplateModal={setShowTemplateModal}
                    selectedTemplate={selectedTemplate}
                    setShowPreviewModal={setShowPreviewModal}
                />
            }
            {showParticipantModal &&
                <ShowParticipantModal
                    showParticipantModal={showParticipantModal}
                    setShowParticipantModal={setShowParticipantModal}
                    handleKeyDown={handleKeyDown}
                    inputValue={inputValue}
                    value={value}
                    setValue={setValue}
                    setInputValue={setInputValue}
                    setReceipientSelctionType={setReceipientSelctionType}
                    isValidRecipient={isValidRecipient}
                    handleRecipients={handleRecipients}
                />
            }
            {showTemplateModal &&
                <TemplateModal
                    showTemplateModal={showTemplateModal}
                    setShowTemplateModal={setShowTemplateModal}
                    isShowTemplateList={isShowTemplateList}
                    setIsShowTemplateList={setIsShowTemplateList}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    setSelectedTemplate={setSelectedTemplate}
                    setShowPreviewModal={setShowPreviewModal}
                    handleEmailData={handleEmailData}
                    validEmailTemplate={validEmailTemplate}
                    workflowList={workflowList}
                    setUpdatedContent={setUpdatedContent}
                    module_id={module_id}
                    id={id}
                    isEdit={isEdit}
                />
            }
            {showPreviewModal &&
                <PreviewModal
                    showPreviewModal={showPreviewModal}
                    setShowPreviewModal={setShowPreviewModal}
                    selectedTemplate={selectedTemplate}
                />
            }
        </React.Fragment>
    )
}
export default CreateWorkflow;