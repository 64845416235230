import React, { Fragment } from "react";
import BarChart from "./barChart";
import LineChart from "./lineChart";
import PieChart from "./pieChart";

const Charts = ({ type = 'line', options, series, width = '100%', height = '100%' }) => {
    debugger
    return (
        <React.Fragment>
            {type === 'line' && <LineChart options={options} series={series} width={width} height={height} />}
            {type === 'bar' && <BarChart options={options} series={series} width={width} height={height} />}
            {type === 'donut' && <PieChart options={options} series={series} width={width} height={height} />}
        </React.Fragment>
    )
}
export default Charts;