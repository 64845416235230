import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getSubFormListStart } from '../../../../Store/Slices/subFormSlice';
import Loader from '../../../Loader';
import { Utils } from '../../../../Utils';
import { getAllUserListStart } from '../../../../Store/Slices/userSlice';
import { Helpers } from '../../../../Helpers';

function AddParticipants({ show, handleClose, isData, submitData, basedSubFormId = "", moduleName = ""}) {
    let [selectedModuleId, setSelectedModuleId] = useState("");
    let [selectedData, setSelectedData] = useState(isData?.selectedData || []);
    let [selectedTab, setSelectedTab] = useState("All");
    let [searchData, setSearchData] = useState("");
    let [showWithoutEmail, setShowWithoutEmail] = useState([]);
    let formList = useSelector((state) => state?.forms);
    let keys = {
        "variants": "participantSubformVariants",
        "tableColumns": "participantSubformTableColumns",
        "formsubmodules": "participantSubformFormsubmodules",
        "totalCount": "participantSubformTotalCount",
        "modules": "participantSubformModules",
        "lookupData": "participantSubformLookupData",
        "workflowRules": "participantSubformWorkflowRules",
        "sapStatus": "participantSubformSapStatus",
        "message": "participantSubformMessage",
        "isLoading": "participantSubformIsLoading",
        "status": "participantSubformStatus"
    }
    let [formatedData, setFormatedData] = useState(null);
    let [inputValue, setInputValue] = useState('');
    let [inviteEmails, setInviteEmails] = useState(isData?.inviteEmails || []);
    let formsubmodules = useSelector((state) => state?.subForm);
    let dispatch = useDispatch();
    let [moduleList, setModuleList] = useState([]);
    let users = useSelector((state) => state?.user);
    
    let handleKeyDown = (event) => {
        if (!inputValue) return;
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (emailPattern.test(inputValue)) {
                    let emailExists = inviteEmails.some((email) => email.value === inputValue);
                    if (!emailExists) {
                        setInviteEmails((prev) => [
                            ...prev,
                            {
                                label: inputValue,
                                value: inputValue,
                            },
                        ]);
                        setInputValue('');
                    }
                }
                event.preventDefault();
                break;
        }
    };     

    useEffect(() => {
        if (!formList?.navbarList?.length) return;
        let filteredData = formList?.navbarList?.filter(x => x?.name === "Leads" || x?.name === "Contacts");
        setSelectedModuleId(filteredData?.[0]?._id);
        setModuleList([
            ...filteredData,
            {
                _id: "users",
                name: "Users"
            }
        ]);
    }, [formList?.navbarList]);    

    useEffect(() => {
        if (selectedModuleId?.length > 0 && !formatedData?.hasOwnProperty(selectedModuleId) && selectedModuleId !== "users") {
            dispatch(getSubFormListStart({ id: selectedModuleId, keys }));
        } else if (selectedModuleId?.length > 0 && !formatedData?.hasOwnProperty("users") && selectedModuleId === "users") {
            dispatch(getAllUserListStart({ page: 1, count: 100 }));
        }
    }, [selectedModuleId]);

    useEffect(() => {
        if (formsubmodules?.participantSubformFormsubmodules && selectedModuleId === formsubmodules?.participantSubformModules?._id) {
            let list = formsubmodules?.participantSubformFormsubmodules || [];
            let subformModules = formsubmodules?.participantSubformModules;
            if (subformModules?.name === "Contacts" && moduleName === "Accounts") {
                list = list?.filter(x => Utils.getSubFormFieldToData(Helpers.replaceData("Account_Name"), x?.data)?._id === basedSubFormId);
            } else if (subformModules?.name === "Leads" && moduleName === "Leads") {
                list = list?.filter(x => x?._id === basedSubFormId);
            } else if (subformModules?.name === "Contacts" && moduleName === "Leads") {
                let basedModuleId = formList?.navbarList?.find(x => x?.name === "Leads")?._id;
                if (formatedData?.hasOwnProperty(basedModuleId)) {
                    list = list?.filter(x => formatedData[basedModuleId]?.data?.map(y => y?.email)?.includes(Utils.getSubFormFieldToData(Helpers.replaceData("Account_Name"), x?.data)?.name || ""));
                }
            }
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setFormatedData(prev => ({
                ...prev, [subformModules?._id]: {
                    name: subformModules?.name,
                    data: list?.map(x => ({
                        _id: x?._id,
                        name: `${Utils.getFieldToData("First_Name", x?.data, subformModules)} ${Utils.getFieldToData("Last_Name", x?.data, subformModules)}`,
                        email: emailPattern.test(Utils.getFieldToData("Email", x?.data, subformModules)) ? Utils.getFieldToData("Email", x?.data, subformModules) : ""
                    }))
                }
            }))
        }
    }, [formsubmodules?.participantSubformFormsubmodules]);

    useEffect(() => {
        if(users?.data?.length > 0) {
            // isActive
            setFormatedData(prev => ({
                ...prev, ["users"]: {
                    name: "Users",
                    data: users?.data?.filter(x => x?.isActive)?.map(x => ({
                        _id: x?._id,
                        name: `${x?.firstName} ${x?.lastName}`,
                        email: x?.email
                    }))
                }
            }))
        }
    }, [users?.data]);

    let handleSubmit = () => {
        submitData({
            selectedData,
            inviteEmails
        });
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Participants</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(formsubmodules?.participantSubformIsLoading || users?.isLoading) && (
                    <Loader isVisible={true} isModal={true} overall={false} />
                )}
                <div className="mb-3 d-flex align-items-center justify-content-between">
                    <select className="form-select w-auto" disabled={selectedTab === "Selected"} value={selectedModuleId} onChange={(e) => setSelectedModuleId(e?.target?.value)}>
                        {moduleList?.length > 0 && (
                            moduleList?.map((x, i) => (
                                <option key={i} value={x?._id}>{x?.name}</option>
                            ))
                        )}
                    </select>
                    <input type="text" className="form-control w-50" placeholder="Search" disabled={selectedTab === "Selected"} value={searchData} onChange={(e) => setSearchData(e?.target?.value)} />
                    <div className="d-flex flex-column cursor-pointer">
                        <span className={selectedTab === "All" ? "active" : "text-secondary"} onClick={() => setSelectedTab("All")}>All</span>
                        <span className={selectedTab === "Selected" ? "active" : "text-secondary"} onClick={() => setSelectedTab("Selected")}>Selected ({selectedData?.reduce((acc, item) => acc + (item?.data?.length || 0), 0) + inviteEmails?.length})</span>
                    </div>
                </div>
                <div className="contact-list mb-3">
                    {selectedTab === "All" ? (
                        formatedData?.[selectedModuleId]?.data?.filter((x) => {
                            let searchLower = searchData.toLowerCase();
                            return (
                                (!searchData || x?.name?.toLowerCase()?.includes(searchLower) || x?.email?.toLowerCase()?.includes(searchLower)) &&
                                (x?.email || showWithoutEmail?.some(x => x === selectedModuleId))
                            );
                        })?.length === 0 ? (
                            <div className="h-100 fw-bold text-center py-3">No Data Found</div>
                        ) : (
                            formatedData?.[selectedModuleId]?.data?.filter((x) => {
                                let searchLower = searchData.toLowerCase();
                                return (
                                    (!searchData || x?.name?.toLowerCase()?.includes(searchLower) || x?.email?.toLowerCase()?.includes(searchLower)) &&
                                    (x?.email || showWithoutEmail?.some(x => x === selectedModuleId))
                                );
                            }).map((x, i) => (
                                (x?.email || showWithoutEmail?.some(x => x === selectedModuleId)) && (
                                    <div key={i} className="form-check mb-2">
                                        <input
                                            disabled={(selectedData?.reduce((acc, item) => acc + (item?.data?.length || 0), 0) + inviteEmails?.length) >= 10 ? !selectedData?.some((item) =>
                                                item?.name === formatedData?.[selectedModuleId]?.name && item?.data?.some((dataItem) => dataItem?._id === x?._id)
                                            ) : false}
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`checkbox-${i}`}
                                            checked={selectedData?.some((item) =>
                                                item?.name === formatedData?.[selectedModuleId]?.name && item?.data?.some((dataItem) => dataItem?._id === x?._id)
                                            )}
                                            onChange={(e) => {
                                                let name = formatedData?.[selectedModuleId]?.name;
                                                if (e?.target?.checked) {
                                                    if (selectedData?.find(y => y?.name === name)) {
                                                        setSelectedData((prev) => {
                                                            return prev.map((item) =>
                                                                item?.name === name
                                                                    ? { ...item, data: [...item?.data, x] }
                                                                    : item
                                                            );
                                                        });
                                                    } else {
                                                        setSelectedData((prev) => [...prev, {
                                                            name,
                                                            data: [x]
                                                        }]);
                                                    }
                                                } else {
                                                    setSelectedData((prev) =>
                                                        prev.map((item) =>
                                                            item?.name === name
                                                                ? { ...item, data: item?.data?.filter((dataItem) => dataItem?._id !== x?._id) }
                                                                : item
                                                        ).filter((item) => item?.data?.length > 0)
                                                    );
                                                }
                                            }}
                                        />
                                        <label className="form-check-label d-flex flex-column" htmlFor={`checkbox-${i}`}>
                                            <span>{x?.name}</span>
                                            <span className="text-muted">{x?.email}</span>
                                        </label>
                                    </div>
                                )
                            ))
                        )
                    ) : selectedData?.reduce((acc, item) => acc + (item?.data?.length || 0), 0) === 0 ? (
                        <div className="h-100 fw-bold text-center py-3">No Participants Selected</div>
                    ) : (
                        selectedData?.map((x, i) => (
                            <div key={i}>
                                <h5>{x?.name}</h5>
                                {x?.data?.map((y, ind) => {
                                    let isChecked = selectedData?.some((item) =>
                                        item?.name === x?.name && item?.data?.some((dataItem) => dataItem?._id === y?._id)
                                    );
                                    return (
                                        <div key={ind} className="form-check mb-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${ind}`}
                                                checked={isChecked}
                                                onChange={(e) => {
                                                    let name = selectedData?.[i]?.name;
                                                    setSelectedData((prev) =>
                                                        prev.map((item) =>
                                                            item?.name === name
                                                                ? {
                                                                    ...item,
                                                                    data: item?.data?.filter((dataItem) => dataItem?._id !== y?._id)  // Remove item from data
                                                                }
                                                                : item
                                                        ).filter((item) => item?.data?.length > 0) // Remove entry if no data remains
                                                    );
                                                }}
                                            />
                                            <label className="form-check-label d-flex flex-column" htmlFor={`checkbox-${ind}`}>
                                                <span>{y?.name}</span>
                                                <span className="text-muted">{y?.email}</span>
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        ))
                    )}
                </div>
                {(selectedData?.reduce((acc, item) => acc + (item?.data?.length || 0), 0) + inviteEmails?.length) >= 10 && (
                    <div className="max-participants-warning">
                        <i className="bi bi-exclamation-triangle-fill"></i>
                        <span>You cannot add more than 10 participants</span>
                    </div>
                )}
                <div className="form-check mt-3">
                    <input className="form-check-input" type="checkbox" id="showWithoutEmails" checked={showWithoutEmail?.some(x => x === selectedModuleId)} onChange={(e) => {
                        let checked = e?.target?.checked;
                        if(checked) {
                            setShowWithoutEmail(prev => ([...prev, selectedModuleId]));
                        } else {
                            let withoutEmail = [...showWithoutEmail]?.filter(x => x !== selectedModuleId);
                            setShowWithoutEmail(withoutEmail);
                        }
                    }} />
                    <label className="form-check-label d-flex flex-column" htmlFor="showWithoutEmails">
                        Show {formatedData?.[selectedModuleId]?.name} without email addresses as well.
                    </label>
                </div>
                <div className="mt-3">
                    <CreatableSelect
                        inputValue={(selectedData?.reduce((acc, item) => acc + (item?.data?.length || 0), 0) + inviteEmails?.length) >= 10 ? "" : inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={(newValue) => setInviteEmails(newValue)}
                        onInputChange={(newValue) => setInputValue(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder="Add Email"
                        value={inviteEmails}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddParticipants;