import React, { useEffect, useRef, useState } from "react";
import { Modal } from 'react-bootstrap';
import { getSubFormListStart } from "../../../../Store/Slices/subFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { Utils } from "../../../../Utils";
import { Helpers } from "../../../../Helpers";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import Pagination from "../../../../Components/formModules/Pagination";
import dayjs from "dayjs";
import Loader from "../../../Loader";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

function AssignmentSubformChange({ show, handleClose, moduleId = "", subFormId = "", setDefaultSubFormChangeData, title = "" }) {
    let formList = useSelector((state) => state?.form);
    let formsubmodules = useSelector((state) => state?.subForm);
    let [id] = useState(moduleId);
    let dispatch = useDispatch();
    let [subFormlayoutList, setSubFormlayoutList] = useState([]);
    let [columnDefs, setColumnDefs] = useState([]);
    let gridRef = useRef();
    let [currentPage, setCurrentPage] = useState(1);
    let [pageSize, setPageSize] = useState(10);
    let keys = {
        "variants": "assignmentSubformVariants",
        "tableColumns": "assignmentSubformTableColumns",
        "formsubmodules": "assignmentSubformFormsubmodules",
        "totalCount": "assignmentSubformTotalCount",
        "modules": "assignmentSubformModules",
        "lookupData": "assignmentSubformLookupData",
        "workflowRules": "assignmentSubformWorkflowRules",
        "sapStatus": "assignmentSubformSapStatus",
        "message": "assignmentSubformMessage",
        "isLoading": "assignmentSubformIsLoading",
        "status": "assignmentSubformStatus"
    }
    let [subFormId2, setSubFormId2] = useState("");
    let [subFormFieldLabel, setSubFormFieldLabel] = useState("");

    useEffect(() => {
        if(subFormId?.length > 0){
            setSubFormId2(subFormId);
        } else {
            setSubFormId2("");
        }
    }, [subFormId]);

    useEffect(() => {
        if (formsubmodules?.assignmentSubformModules) {
            let moduleStoreData = formsubmodules.assignmentSubformModules?.fields;
            setSubFormFieldLabel(title || Utils.getDefaultSubFormNameField(moduleStoreData?.sections));
        }
    }, [formsubmodules?.assignmentSubformModules]);

    useEffect(() => {
        if (formsubmodules?.assignmentSubformFormsubmodules?.length > 0 && id) {
            let processedData = formsubmodules.assignmentSubformFormsubmodules.map((item) => {
                return {
                    _id : item?._id,
                    ...item.data.reduce((acc, curr) => ({
                        ...acc,
                        ...curr.section_data
                    }), {}),
                    User_Name: item?.userId?.firstName ? (item?.userId?.firstName + ' ' + item?.userId?.lastName) : '_',
                    SAP_Status: (item.sapResponse?.sapStatus || '_'),
                    SAP_Message: (item.sapResponse?.sapMsg || '_'),
                    ...(formsubmodules?.assignmentSubformWorkflowRules ? { Status: item.status || '_' } : {})
                }
            });
            setSubFormlayoutList(processedData);
        } else {
            setSubFormlayoutList([]);
        }
        if (formsubmodules?.assignmentSubformTableColumns?.data?.length > 0) {
            let tableColumns = formsubmodules.assignmentSubformTableColumns.data || [];
            let staticColumns = [
                { label: "User Name", show: true, static: true },
                { label: "SAP Status", show: true, static: true },
                { label: "SAP Message", show: true, static: true }
            ];
            let staticStatusColumn = { label: "Status", show: true, static: true };
            let doesLabelExist = (label) => tableColumns.some(col => col.label === label);
            if (formsubmodules?.assignmentSubformWorkflowRules && !doesLabelExist(staticStatusColumn.label)) {
                tableColumns = [staticStatusColumn, ...tableColumns];
            }
            if (formsubmodules?.assignmentSubformSapStatus) {
                let filteredStaticColumns = staticColumns.filter(col => !doesLabelExist(col.label));
                tableColumns = [...filteredStaticColumns, ...tableColumns];
            }
            setColumnData(tableColumns);

        } else {
            if (formsubmodules?.assignmentSubformModules?._id && id) {
                let moduleStoreData = formsubmodules.assignmentSubformModules?.fields;
                if (moduleStoreData) {
                    let fieldLabels = [
                        ...(formsubmodules?.assignmentSubformSapStatus
                            ? [
                                { label: "User Name", show: true, static: true },
                                { label: "SAP Status", show: true, static: true },
                                { label: "SAP Message", show: true, static: true }
                            ]
                            : []
                        ),
                        ...(formsubmodules?.assignmentSubformWorkflowRules ? [{ label: "Status", show: true, static: true }] : [])
                    ];
                    moduleStoreData?.sections.forEach(section => {
                        Object.entries(section?.dropped_list || {}).forEach(([key, value]) => {
                            value?.forEach(item => {
                                let label = item?.extra_fields?.field_label;
                                if (label && (["text", "textarea", "email", "tel", "select", "date", "datetime-local", "number", "currency", "decimal", "percent", "longinteger", "url", "lookup", "user"].includes(item?.input_type))) {
                                    fieldLabels.push({ label, show: true, static: false });
                                }
                            });
                        });
                    });
                    setColumnData(fieldLabels);
                }
            }
        }
    }, [formsubmodules?.assignmentSubformModules, formsubmodules?.assignmentSubformTableColumns, formsubmodules?.assignmentSubformFormsubmodules]);

    const setColumnData = (data) => {
        let columns = data?.filter(x => x.show)?.map((x) => ({
            headerName: x.label,
            field: Helpers.replaceData(x.label),
            cellRenderer: (params) => {
                let headerName = params?.colDef?.headerName;
                let inputType = Utils.getParticularsModuleFields(formsubmodules?.assignmentSubformModules?.fields?.sections, headerName);
                let value = params?.value === undefined ? "" : params?.value;
                if (Array.isArray(value)) {
                    let names = value?.map((item) => item?.name).join(", ");
                    return names;
                } else if (typeof value === "object" && value !== null) {
                    return value?.name;
                } else if (value && inputType?.input_type === "date" || value && inputType?.input_type === "datetime-local") {
                    return dayjs(value).format("MMM D, YYYY hh:mm A");
                } else {
                    return value || "_";
                }
            }
        })) || [];

        columns.unshift({
            headerCheckboxSelection: true,
            checkboxSelection: true,
            filter: false,
            sortable: false,
            width: 50
        })
        setColumnDefs(columns);
    }

    let onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            event.target.value,
        );
    }

    useEffect(() => {
        if (id?.length > 0) {
            dispatch(getSubFormListStart({ page: currentPage, count: pageSize, id, keys }));
        }
    }, [id, currentPage, pageSize]);

    let handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    let handleNextPage = () => {
        let totalPages = Math.ceil(formsubmodules?.assignmentSubformTotalCount / pageSize);
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    let handlePageSizeChange = (size) => {
        setPageSize(size);
    };

    let onRowSelected = (event) => {
        let node = event?.node;
        if (node?.selected && node?.data?._id !== subFormId2) {
            let dataName = node?.data?.[Helpers.replaceData(subFormFieldLabel)] || "";
            setDefaultSubFormChangeData({
                name : {
                    _id : node?.data?._id,
                    name : (typeof dataName === "object" ? dataName?.name : dataName)
                },
                subFormId : node?.data?._id,
                subFormData : node?.data
            });
        }
    };

    useEffect(() => {
        if (gridRef.current && subFormId2?.length > 0 && !subFormId?.length) {
            try {
                let api = gridRef.current.api;
                if (api && typeof api?.getRowNode === "function") {
                    let node = api.getRowNode(subFormId2);
                    if (node) {
                        node.setSelected(true);
                    }
                }
            } catch (error) {}
        }
    }, [subFormId2, subFormId, gridRef]);
    
    
    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Choose</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${(formsubmodules?.assignmentSubformIsLoading || formList?.isLoading) ? "vh-100" : ""}`}>
                {(formsubmodules?.assignmentSubformIsLoading || formList?.isLoading) && (
                    <Loader isVisible={true} isModal={true} overall={false} />
                )}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card rounded-4 border-0 shadow-sm sub-form-module">
                                <div className="card-body p-0">
                                    <div className="col-md-12 setup-actions d-flex pb-2 pt-0">
                                        <div className="left d-flex align-items-start">
                                            <div className="search-box border rounded-pill">
                                                <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                                <input type="search" placeholder="Search" className="searchfield bg-white" onInput={onFilterTextBoxChanged} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        {columnDefs?.length > 0 && <div className={`ag-theme-quartz without-charts`} style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={subFormlayoutList || []}
                                                columnDefs={columnDefs}
                                                defaultColDef={{
                                                    filter: 'agTextColumnFilter',
                                                    floatingFilter: true,
                                                }}
                                                rowSelection="single"
                                                pagination={false}
                                                rowHeight={40}
                                                floatingFiltersHeight={40}
                                                headerHeight={40}
                                                onFirstDataRendered={(params) => {
                                                    if(subFormId) {
                                                        let node = params.api.getRowNode(subFormId2);
                                                        if (node) {
                                                            node.setSelected(true);
                                                        }
                                                    }
                                                }}
                                                getRowId={(params) => params?.data?._id}
                                                onRowSelected={onRowSelected}
                                            />
                                            <Pagination
                                                currentPage={currentPage}
                                                pageSize={pageSize}
                                                totalCount={formsubmodules?.assignmentSubformTotalCount}
                                                onPageSizeChange={handlePageSizeChange}
                                                onPrevPage={handlePrevPage}
                                                onNextPage={handleNextPage}
                                            />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AssignmentSubformChange;