import { call, put } from "redux-saga/effects";
import { getVariantConfig, createVariantConfig, deleteVariantConfigById, updateVariantConfigById } from "../../../../Services/apiService";
import { getVariantConfigSuccess, getVariantConfigFailure, createVariantConfigSuccess, createVariantConfigFailure, deleteVariantConfigByIdSuccess, deleteVariantConfigByIdFailure, updateVariantConfigByIdSuccess, updateVariantConfigByIdFailure } from "../../../Slices/variantConfigSlice";

export function* getVariantConfigHandler(actions) {
    try {
        let response = yield call(getVariantConfig, actions?.payload);
        yield put(getVariantConfigSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getVariantConfigFailure(errResponse))
    }
}

export function* createVariantConfigHandler(actions) {
    try {
        let response = yield call(createVariantConfig, actions?.payload);
        yield put(createVariantConfigSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createVariantConfigFailure(errResponse))
    }
}

export function* deleteVariantConfigByIdHandler(actions) {
    try {
        let response = yield call(deleteVariantConfigById, actions?.payload);
        yield put(deleteVariantConfigByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(deleteVariantConfigByIdFailure(errResponse))
    }
}

export function* updateVariantConfigByIdHandler(actions) {
    try {
        let response = yield call(updateVariantConfigById, actions?.payload);
        yield put(updateVariantConfigByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateVariantConfigByIdFailure(errResponse))
    }
}