import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DashboardComponents } from '../../../DashboardComponents';
import { AllChartModel } from '..';

function Table({ show, handleClose, submitData, allList = [] }) {
    let [targetMeterList] = useState([DashboardComponents["TableAssignment"]]);
    let [targetMeterName] = useState(["TableAssignment"]);
    let [showModal, setShowModal] = useState(false);
    let [OpenModel, setModel] = useState(null);

    let onClickCard = (index) => {
        let ChartComponent = AllChartModel[targetMeterName[index]];
        if (ChartComponent) {
            setModel(() => ChartComponent);
            setShowModal(true);
        } else {}
    }
    return (
        <>
            <Modal show={show && !showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Choose Table</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mt-5">
                        <div className="row g-4">
                            {targetMeterList.map((TableComponent, index) => (
                                <div className="col-md-12" key={index} onClick={() => onClickCard(index)}>
                                    <div className="card">
                                        <div className="card-body p-0 overflow-auto">
                                            <TableComponent isStatic={true}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {(showModal && OpenModel) && <OpenModel show={showModal} handleClose={() => {
                setShowModal(false);
                setModel(null);
            }} submitData={(data) => {
                submitData(data);
                handleClose();
            }} allList={allList}/> }
        </>
    );
}

export default Table;
