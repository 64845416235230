import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSideBarRoutes, Utils } from '../../Utils/index';
import homeIcon from '../../Assets/images/sidebar/home-icon.svg';
// import mdmIcon from '../../Assets/images/sidebar/mdm-icon.svg';
import crmIcon from '../../Assets/images/crm-logo-icon.svg';
import deskIcon from '../../Assets/images/sidebar/desk-icon.svg';
import projectIcon from '../../Assets/images/sidebar/projects-icon.svg';
import timePastIcon from '../../Assets/images/sidebar/time-past-icon.svg';

const Sidebar = () => {
    const [routes, setRoutes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setRoutes(getSideBarRoutes())
    }, []);

    return (
        <React.Fragment>
            <div className="sidebar main-sidebar col-lg p-0 bg-white fixed-top">
                <div className="offcanvas-md offcanvas-start h-100" tabIndex="-1" id="sidebarMenu"
                    aria-labelledby="sidebarMenuLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                            data-bs-target="#sidebarMenu" aria-label="Close"></button>
                    </div>

                    <div className="offcanvas-body d-md-flex flex-column p-0 h-100 overflow-y-auto">
                        <div className="list-btns text-center top">
                            <div role="button" className="px-1 py-3 list-item" onClick={() => navigate(Utils.getNavigationUrl(`dashboard`))}>
                                <div className="small">
                                    <img src={homeIcon} alt="Home-icon" width="22"
                                        height="22" />
                                    <div className="text-dark">Home</div>
                                </div>
                            </div>

                            {/* <!-- Use "active" class for this div element--> */}
                            <div role="button" className="px-1 py-3 list-item active"
                                onClick={() => navigate(Utils.getNavigationUrl(`dashboard`))}>
                                <div className="small">
                                    <img src={crmIcon} alt="CRM-icon" width="22"
                                        height="22" />
                                    <div className="text-dark">CRM</div>
                                </div>
                            </div>

                            {/* <!-- <div role="button" className="px-1 py-3 list-item">
                                        <div className="small">
                                            <img src="assets/images/sidebar/sales-iq-icon.svg"
                                                alt="sales-iq-icon" width="22" height="22">
                                                <div className="text-dark">Sales IQ</div>
                                        </div>
                                    </div> --> */}

                            <div role="button" className="px-1 py-3 list-item">
                                <div className="small">
                                    <img src={deskIcon} alt="desk-icon" width="22"
                                        height="22" />
                                    <div className="text-dark">Desk</div>
                                </div>
                            </div>

                            <div role="button" className="px-1 py-3 list-item">
                                <div className="small">
                                    <img src={projectIcon} alt="projects-icon"
                                        width="22" height="22" />
                                    <div className="text-dark">Projects</div>
                                </div>
                            </div>

                            {/* <!-- <div role="button" className="px-1 py-3 list-item">
                                        <div className="small">
                                            <img src="assets/images/sidebar/brand-studio-icon.svg"
                                                alt="brand-studio" width="22" height="22">
                                                <div className="text-dark">Brand Studio</div>
                                        </div>
                                    </div> --> */}

                            <div role="button" className="px-1 py-3 list-item">
                                <div className="small">
                                    <div className="text-dark">...</div>
                                </div>
                            </div>
                        </div>

                        <div className="list-btns text-center bottom mt-auto">
                            <div role="button" className="px-1 py-3 list-item">
                                <div className="small">
                                    <div className="text-dark">In Trail</div>
                                    <div className="text-dark">
                                        <a className="text-primary text-decoration-none small">UPGRADE</a>
                                    </div>
                                </div>
                            </div>
                            <div role="button" className="px-1 py-3 list-item">
                                <div className="small">
                                    <img src={timePastIcon} alt="recent-items"
                                        width="22" height="22" />
                                    <div className="text-dark">Recent Items</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {/* <Outlet /> */}
        </React.Fragment>
    )

}
export default Sidebar;