import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SingleFilterLookupRecords from './SingleFilterLookupRecords';
import { useSelector } from 'react-redux';
import { handleFieldLabelUpdate } from '../../../../Utils';  

function LookupPropertiesModal({ show, handleClose, item, editPropertySubmit }) {
  let fields = item?.extra_fields;
  let formList = useSelector((state) => state?.forms);
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label);
  const [relatedListTitle, setRelatedListTitle] = useState(fields?.related_list_title);
  const [moduleId, setModuleId] = useState(fields?.moduleId);
  const [required, setRequired] = useState(fields?.required);
  const [filterLookupRecords, setFilterLookupRecords] = useState(fields?.filter_lookup_records);
  const [showTooltip, setShowTooltip] = useState(fields?.show_tooltip?.checked);
  const [tooltipText, setTooltipText] = useState(fields?.show_tooltip?.message);
  const [radioValue, setRadioValue] = useState(fields?.show_tooltip?.type);
  let [showFilterLookupRecordsModel, setShowFilterLookupRecordsModel] = useState(false);
  let [isModuleLookup, setIsModuleLookup] = useState(fields?.is_module_lookup || false);
  let [convert, setConvert] = useState(fields?.convert || false);
  const [labelError, setLabelError] = useState('');


  const handleFieldLabelChange = (value) => {
    handleFieldLabelUpdate(value, (newLabel) => {
      setFieldLabel(newLabel);
    }, setLabelError); 
  };

  const handleSubmit = () => {
    if (!labelError) {
      editPropertySubmit({
        field_label: fieldLabel,
        moduleId,
        related_list_title: relatedListTitle,
        filter_lookup_records: filterLookupRecords,
        required: required,
        show_tooltip: {
          checked: showTooltip,
          type: radioValue,
          message: tooltipText
        },
        is_module_lookup : isModuleLookup,
        convert
      });
      handleClose();
    }
  };

  return (
    <>
      <Modal show={show && !showFilterLookupRecordsModel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lookup Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Field Label</Form.Label>
              <Form.Control type="text" value={fieldLabel} onChange={(e) => handleFieldLabelChange(e.target.value)} />
              {labelError && <div className="text-danger">{labelError}</div>} 
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check label="Module Lookup" checked={isModuleLookup} onChange={(e) => setIsModuleLookup(e.target.checked)} />
            </Form.Group>
           
            {!isModuleLookup && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Lookup Module</Form.Label>
                  <Form.Select value={moduleId} onChange={(e) => setModuleId(e.target.value)}>
                    <option value="">Choose Module</option>
                    {formList?.navbarList?.length > 0 && (
                      formList?.navbarList?.map((x, index) => (
                        <option key={index} value={x?._id}>{x?.name}</option>
                      ))
                    )}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Related List Title</Form.Label>
                  <Form.Control required type="text" value={relatedListTitle} onChange={(e) => setRelatedListTitle(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check label="Required" checked={required} onChange={(e) => setRequired(e.target.checked)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check label="Convert" checked={convert} onChange={(e) => setConvert(e.target.checked)} />
                </Form.Group>

                {moduleId && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Check label="Filter Lookup Records" checked={filterLookupRecords?.length > 0 || (filterLookupRecords?.length === 1) ? filterLookupRecords?.every(x => x?.field_label) : false} onClick={() => setShowFilterLookupRecordsModel(true)} />
                    </Form.Group>

                    {filterLookupRecords?.length > 0 && (
                      < div className="card">
                        <ul className="list-group list-group-flush">
                          {filterLookupRecords?.map((x, index) => (
                            <li key={index} className="list-group-item">{x?.field_label} <span className='text-info'>{x?.match}</span> {x?.value}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {/* Tooltip Section */}
            <Form.Group controlId="formShowTooltip" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Show Tooltip"
                checked={showTooltip}
                onChange={() => setShowTooltip(!showTooltip)}
              />
            </Form.Group>

            {showTooltip && (
              <div>
                <Form.Group>
                  <Form.Label>Tooltip Text (Max 255 characters)</Form.Label>
                  <Form.Control
                    as="textarea"
                    maxLength={255}
                    value={tooltipText}
                    onChange={(e) => setTooltipText(e.target.value)}
                    placeholder="Enter Tooltip text"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Tooltip Type</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Info Icon"
                    name="tooltipOption"
                    checked={radioValue === 'info'}
                    onChange={() => setRadioValue('info')}
                  />
                  <Form.Check
                    type="radio"
                    label="Static Text"
                    name="tooltipOption"
                    checked={radioValue === 'static'}
                    onChange={() => setRadioValue('static')}
                  />
                </Form.Group>
              </div>
            )}

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={labelError}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {showFilterLookupRecordsModel && 
      <>
        <SingleFilterLookupRecords show={showFilterLookupRecordsModel} moduleId={moduleId} filterLookupRecords={filterLookupRecords} handleClose={() => {
        setShowFilterLookupRecordsModel(false);
         }} submitData={(data) => {
            setFilterLookupRecords(data);
            setShowFilterLookupRecordsModel(false);
          }} />
      </>}
    </>
  );
}

export default LookupPropertiesModal;
