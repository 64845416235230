import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    user: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    permissions: {
        isLoading: false,
        data: null,
        message: null,
        error: null,
        totalCount: null
    },
    businessData: null,
    getLoading: null
}

const businessHoursSlice = createSlice({
    name: 'businesshours',
    initialState,
    reducers: {
        getBusinessHoursStart: (state) => {
            state.isLoading = true;
            state.businessData = null;
            state.getLoading = null;
        },
        getBusinessHoursSuccess: (state, action) => {
            state.data = action?.payload?.businessHours;
            state.businessData = action?.payload?.businessHours;
            state.message = action?.payload?.message;
            state.isLoading = false;
            state.getLoading = action?.payload?.success;
        },
        getBusinessHoursFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        createBusinessHoursStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
        },
        createBusinessHoursSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        createBusinessHoursFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        updateBusinessHoursStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
        },
        updateBusinessHoursSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
        },
        updateBusinessHoursFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        }
    }
});
export const {
    getBusinessHoursStart,
    getBusinessHoursSuccess,
    getBusinessHoursFailure,
    createBusinessHoursStart,
    createBusinessHoursSuccess,
    createBusinessHoursFailure,
    updateBusinessHoursStart,
    updateBusinessHoursSuccess,
    updateBusinessHoursFailure
} = businessHoursSlice?.actions;

export default businessHoursSlice?.reducer;