import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getAllFormModuleTableDataStart, removeModuleByIdStart, resetReloadState } from "../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import ConfirmationModal from "../../Components/ModalPopup/ConfirmationModal";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import ManageColumn from "../../Components/ModalPopup/FormModules/ManageColumn";
import { createManageModuleStart, updateManageModuleStart } from "../../Store/Slices/formSlice";
import dayjs from "dayjs";
import { getItem } from "../../Services/localService";
import { Utils } from "../../Utils";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const FormModule = () => {
    let currentUserData = getItem('user');
    // Digylax organization : 67627340b53f54575cf41de6
    let gridRef = useRef();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let formList = useSelector((state) => state?.forms);
    let [removeModalData, setRemoveModalData] = useState(null);
    let [showManageColumn, setShowManageColumn] = useState(null);
    let [columnDefs] = useState([
        {
            headerName: "Displayed in tabs as",
            field: "name",
            cellRenderer: (params) => {
                return <a className="text-decoration-none" onClick={() => navigate(Utils.getNavigationUrl(`form-module/update/${params.data._id}`))}>
                        {params.value}
                </a>
            },
            width: 250
        },
        { headerName: "Module Name", field: "name", width: 250 },
        {
            headerName: "Created On",
            field: "createdAt",
            valueFormatter: (params) => dayjs(params?.value).format("MM/DD/YYYY"),
            width: 250
        },
        {
            headerName: "Modified By",
            field: "createdBy",
            valueFormatter: (params) => {
                let creator = params.value;
                return creator ? `${creator?.firstName} ${creator?.lastName}` : "_";
            }, 
            width: 250,
        },
        {
            headerName: "Delete",
            field: "delete",
            filter: false,
            cellRenderer: (params) => {
                let data = params?.data;
                if(currentUserData?.organization?._id === "67627340b53f54575cf41de6" && data?.is_default || !data?.is_default){
                    return <span style={{ color : "#dc3545"}} className="cursor-pointer" onClick={() => setRemoveModalData(params.data)}><i className="fas fa-trash"></i></span>
                }
                return "_"
            },
            width: 100
        }
    ]);

    let onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setGridOption(
            "quickFilterText", 
            event.target.value,
        );
    }

    useEffect(() => {
        dispatch(getAllFormModuleTableDataStart());
    }, []);

    useEffect(() => {
        if (formList?.reload) {
            dispatch(getAllFormModuleTableDataStart());
            dispatch(resetReloadState());
        }
    }, [formList?.reload]);

    const handleDelete = (moduleData) => {
        dispatch(removeModuleByIdStart(moduleData._id));
        let updatedNavbarList = formList?.navbarList?.filter((item) => item._id !== moduleData._id);
        let datas = updatedNavbarList?.map(x => ({
            _id: x?._id, 
            name: x?.name, 
            show: x?.show
        })) || [];
        if (datas.length > 0 && formList?.manageModuleId?.length > 0) {
            let obj = {
                id : formList?.manageModuleId,
                payload: {
                    data: datas
                }
            };
            dispatch(updateManageModuleStart(obj));
        }
        setRemoveModalData(null);
    };  
    
    
        return (
        <>
            <Loader isVisible={formList?.isLoading} />
            <div className="card rounded-4 border-0 p-0 form-module">
                <div className="card-body">
                    <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                        <div className="me-3">
                            <button className="btn btn-primary rounded-pill" onClick={() => navigate(-1)}>
                                <i className="fa fa-arrow-left me-2"></i> Back
                            </button>
                        </div>
                        <div className="left d-flex align-items-start">
                            <div className="search-box border rounded-pill">
                                <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                <input type="search" placeholder="Search" className="searchfield bg-white" onInput={onFilterTextBoxChanged}/>
                            </div>
                        </div>
                        <div className="ms-auto">
                            <div className="btn-group">
                                <button type="button" className="btn btn-secondary rounded-pill btn-blue" onClick={() => setShowManageColumn(true)}>
                                    <i className="fa-solid fa-plus me-2"></i>
                                    <span>Organize Modules</span>
                                </button>
                            </div>
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => navigate(Utils.getNavigationUrl(`form-module/create`))}>
                                    <i className="fa-solid fa-plus me-2"></i>
                                    <span>New Module</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="ag-theme-quartz" style={{ height: "calc(100vh - 21vh)", display: "flex", flexDirection: "column",  position : "relative"}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={formList?.tableData} 
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    filter: 'agTextColumnFilter',
                                    floatingFilter: true,
                                }}
                                rowSelection="single"
                                pagination={true}
                                rowHeight={40}
                                floatingFiltersHeight={40}
                                headerHeight={40}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {removeModalData && (
                <ConfirmationModal
                    data={removeModalData}
                    show={true}
                    handleClose={() => setRemoveModalData(null)}
                    handleSuccess={() => handleDelete(removeModalData)} 
                />
            )}
            {showManageColumn && <ManageColumn show={showManageColumn} getValue={formList?.navbarList?.map(x => ({_id : x?._id, label : x?.name, show : x?.show})) || []} createApi={createManageModuleStart} updateApi={updateManageModuleStart} isNavbarChange={true} data={{_id : formList?.manageModuleId}} handleClose={() => {
                setShowManageColumn(false);
                }}/>}
        </>
    );
};

export default FormModule;

