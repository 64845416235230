import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useNavigate, useParams } from "react-router-dom";
import { getModuleByIdStart, convertSelectedModuleListStart, getConvertModuleListStart, createConvertModuleStart, updateConvertModuleStart, getConvertModuleStart, resetState, updateModuleByIdStart, destoryMsgAndError } from "../../Store/Slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import { Utils } from "../../Utils";
import { Helpers } from "../../Helpers";
import Toast from "../../Components/Toast";
import { toast } from 'react-toastify';

const FormLayout = () => {
    let { id } = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let getModuleByID = useSelector((state) => state?.forms);
    let [moduleData, setModuleData] = useState(null);
    let [rulesList, setRulesList] = useState(null);
    let [convertModules, setConvertModules] = useState([]);
    let [formatedTableData, setFormatedTableData] = useState([]);
    let [allFormatedTableData, setAllFormatedTableData] = useState([]);
    let [secondModuleData, setSecondModuleData] = useState([]);
    let [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (id) {
            dispatch(getModuleByIdStart(id));
        }
    }, []);

    useEffect(() => {
        if (getModuleByID?.convertData) {
            setSelectedOptions(getModuleByID?.convertData?.data);
        }
    }, [getModuleByID?.convertData]);

    useEffect(() => {
        let modules = getModuleByID?.modules;
        if (modules?.length > 0) {
            setModuleData(modules[0]);
            if (!getModuleByID?.rules?.length) {
                dispatch(convertSelectedModuleListStart(id));
            }
        }
    }, [getModuleByID?.modules]);

    useEffect(() => {
        let rules = getModuleByID?.rules;
        if (rules?.length > 0) {
            setRulesList(rules[0]);
            if (!convertModules?.length) {
                dispatch(getConvertModuleListStart({ selected_modules: rules[0]?.rules?.selected_modules }));
                dispatch(getConvertModuleStart(id));
            }
        }
    }, [getModuleByID?.rules]);

    useEffect(() => {
        let convertModuleList = getModuleByID?.convert_modules;
        if (convertModuleList?.length > 0) {
            setConvertModules(convertModuleList);
        }
    }, [getModuleByID?.convert_modules]);

    useEffect(() => {
        if (convertModules?.length > 0) {
            let data = [];
            convertModules?.forEach((x) => {
                let allData = Utils.getModuleFields(x?.fields?.sections, ["text", "textarea", "email", "tel", "select", "multi-select", "date", "datetime-local", "number", "currency", "decimal", "percent", "longinteger", "checkbox", "url", "lookup", "user", "multi-lookup"]);
                data.push({
                    name: x?.name,
                    moduleId: x?._id,
                    fields: allData
                });
            });
            setSecondModuleData(data);
        }
    }, [convertModules]);

    useEffect(() => {
        if (moduleData) {
            let allData = Utils.getModuleFields(moduleData?.fields?.sections, ["text", "textarea", "email", "tel", "select", "multi-select", "date", "datetime-local", "number", "currency", "decimal", "percent", "longinteger", "checkbox", "url", "lookup", "user", "multi-lookup"]);
            let allData2 = Utils.getModuleFields(moduleData?.fields?.sections);
            setFormatedTableData(allData);
            setAllFormatedTableData(allData2);
        }
    }, [moduleData]);

    let getInputTypeBasedData = (field, id1) => {
        if (!secondModuleData?.length) return [];
        let selectedFields = Object.values(selectedOptions?.find(x => x?.moduleId === id1)?.data || {});
        return secondModuleData?.find(x => x?.moduleId === id1)?.fields?.moduleFields?.filter((x) => {
            let fieldLabel = x?.fields?.extra_fields?.field_label;
            let isSelected = selectedFields.includes(fieldLabel);
            let isCurrentField = fieldLabel === selectedOptions?.find(x => x?.moduleId === id1)?.data?.[Helpers.replaceData(field?.extra_fields?.field_label)];
            return (
                x?.fields?.input_type === field?.input_type &&
                (!isSelected || isCurrentField)
            );
        });
    };

    let handleSelectChange = (moduleName, moduleId, fieldLabel, index, value) => {
        setSelectedOptions((prevState) => {
            let newState = [...prevState];
            let key = Helpers.replaceData(fieldLabel);
            if (newState[index]) {
                newState[index] = {
                    ...newState[index],
                    moduleId,
                    name: moduleName,
                    data: {
                        ...newState[index].data,
                        [key]: value,
                    },
                };
            } else {
                newState[index] = {
                    moduleId,
                    name: moduleName,
                    data: {
                        [key]: value,
                    },
                };
            }
            return newState;
        });
    };

    let onClickSave = () => {
        if (rulesList?._id) {
            let obj = {
                moduleId: moduleData?._id,
                workflow_id: rulesList?._id,
                name: moduleData?.name,
                data: selectedOptions
            }
            if (getModuleByID?.convertData?._id?.length > 0) {
                dispatch(updateConvertModuleStart({
                    id: getModuleByID?.convertData?._id,
                    payload: obj
                }));
            } else {
                dispatch(createConvertModuleStart(obj));
            }
        }
    }

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    let changeDefaultSubFormName = (e, fieldId) => {
        let checked = e?.target?.checked;
        let updateData = moduleData;
        let updatedSection = Utils.defaultSubFormNameUpdate(updateData?.fields?.sections, fieldId, checked);
        setModuleData({
            ...updateData,
            fields: {
                ...updateData?.fields,
                sections: updatedSection
            }
        });
        dispatch(
            updateModuleByIdStart({
                id: updateData?._id,
                payload: {
                    ...updateData,
                    fields: {
                        ...updateData?.fields,
                        sections: updatedSection
                    }
                }
            })
        );
    };

    useEffect(() => {
        if (getModuleByID?.messageConvert) {
            toast.success(getModuleByID?.messageConvert, {
                autoClose: 1000,
                onClose : () => {
                    dispatch(destoryMsgAndError());
                }
            })
        } else if (getModuleByID?.errorConvert) {
            toast.success(getModuleByID?.errorConvert, {
                autoClose: 1000,
                onClose : () => {
                    dispatch(destoryMsgAndError());
                }
            })
        }
    }, [getModuleByID?.messageConvert, getModuleByID?.errorConvert]);

    return (
        <>
            {(getModuleByID?.messageConvert || getModuleByID?.errorConvert) && <Toast />}
            <Loader isVisible={getModuleByID?.isLoading} />
            <Tab.Container defaultActiveKey="/layout">
                <div className="container">
                    <h2 className="mb-4">{moduleData?.name}</h2>
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="/layout">
                                Layout
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="/convert">Convert</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="/fields">Fields</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="mt-3">
                        <Tab.Pane eventKey="/layout">
                            <p className="mb-4">Design your own layouts to fit your business processes, then assign them to your user accounts based on permission profiles.</p>
                            <div className="d-flex justify-content-end mb-3">
                                <button className="btn btn-primary">Create New Layout</button>
                            </div>
                            <div class="tbl-container rounded-table">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Shared To</th>
                                                <th>Last Modified</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a className="text-decoration-none" onClick={() => navigate(Utils.getNavigationUrl(`form-module/update/${id}`))}>Standard</a></td>
                                                <td>Administrator and Standard</td>
                                                <td>_</td>
                                                <td>
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox" checked readOnly />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="/convert">
                            <h5 className="card-title mb-3">{moduleData?.name} Conversion Mapping</h5>
                            <p className="card-text text-muted">
                                Map the fields in the {moduleData?.name} module to the fields in {rulesList?.rules?.selected_modules?.map(item => item?.name).join(', ').replace(/, ([^,]*)$/, ' and $1')} modules to make {moduleData?.name} conversion easy. With the mapped fields,
                                converting a {moduleData?.name} will automatically transfer the information you have
                                gathered to a {rulesList?.rules?.selected_modules?.map(item => item?.name).join(', ').replace(/, ([^,]*)$/, ' and $1')}.
                            </p>
                            <div class="tbl-container rounded-table">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">{moduleData?.name}</th>
                                                {rulesList?.rules?.selected_modules?.length > 0 && (
                                                    rulesList?.rules?.selected_modules?.map((x, index) => (
                                                        <th key={index} scope="col">{x?.name}</th>
                                                    ))
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formatedTableData?.moduleFields?.length > 0 && (
                                                formatedTableData?.moduleFields?.map((x, index) => (
                                                    <tr key={index}>
                                                        <td>{x?.fields?.extra_fields?.field_label}</td>
                                                        {rulesList?.rules?.selected_modules?.length > 0 && (
                                                            rulesList?.rules?.selected_modules?.map((y, index2) => (
                                                                <td key={index2}>
                                                                    <select className="form-select" value={selectedOptions?.[index2]?.data?.[Helpers.replaceData(x?.fields?.extra_fields?.field_label)]} onChange={(e) => handleSelectChange(
                                                                        y?.name,
                                                                        y?._id,
                                                                        x?.fields?.extra_fields?.field_label,
                                                                        index2,
                                                                        e.target.value
                                                                    )}>
                                                                        <option value="">None</option>
                                                                        {getInputTypeBasedData(x?.fields, y?._id)?.map((z, index3) => (
                                                                            <option key={index3}>{z?.fields?.extra_fields?.field_label}</option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                            ))
                                                        )}
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <button type="button" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancel</button>
                                <button type="submit" className="btn btn-primary" onClick={onClickSave} disabled={!selectedOptions?.length}>Save</button>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="/fields">
                            <div className="d-flex justify-content-end mb-3">
                                <button className="btn btn-primary" onClick={() => navigate(Utils.getNavigationUrl(`form-module/update/${id}`))}>Create and Edit Fields</button>
                            </div>

                            <div class="tbl-container rounded-table">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Fields</th>
                                                <th>Data Type</th>
                                                <th>Unique Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allFormatedTableData?.moduleFields?.length > 0 && (
                                                allFormatedTableData?.moduleFields?.map((x, index) => (
                                                    <tr key={index}>
                                                        <td>{x?.fields?.extra_fields?.field_label}</td>
                                                        <td>{x?.fields?.label}</td>
                                                        <th>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" checked={x?.fields?.is_default_subform_name} disabled={["multi-select", "multi-lookup"]?.includes(x?.fields?.input_type) || (x?.fields?.input_type === "user" && x?.fields?.extra_fields?.user_type === "single_user")} type="checkbox" onChange={(e) => changeDefaultSubFormName(e, x?.fields?.id)} />
                                                            </div>
                                                        </th>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </>
    )
}
export default FormLayout;