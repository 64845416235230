import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Utils } from '../../../Utils';
import { Helpers } from '../../../Helpers';
import ReactEcharts from "echarts-for-react";

const DialGaugeChart = ({ data = {}, isStatic = false }) => {
    let getOptions = (valueNumber = 100) => {
        valueNumber = Number(valueNumber);
        let targetNumber = isStatic ? 240 : Number(data?.target);
        return {
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    type: 'gauge',
                    center: ['50%', '60%'],
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: targetNumber,
                    splitNumber: targetNumber,
                    progress: {
                        show: true,
                        width: 30
                    },
                    axisLine: {
                        lineStyle: {
                            width: 30
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        distance: -40,
                        color: '#000',
                        fontSize: 14,
                        formatter: (value) => {
                            if (value === valueNumber) return valueNumber;
                            return '';
                        },
                    },
                    detail: {
                        offsetCenter: [0, '30%'],
                        formatter: (value) => `Remaining: ${targetNumber - value}`,
                        fontSize: 16,
                        color: '#333'
                    },
                    data: [
                        {
                            value : valueNumber
                        }
                    ]
                }
            ]
        }
    }
    let [gaugeChartOptions, setGaugeChartOptions] = useState(getOptions());
    let subModuleData = useSelector((state) => state?.dashboardComponents?.subModuleData);
    let [width, setWidth] = useState("0%");

    useEffect(() => {
        if (subModuleData?.[data?.moduleId]?.length > 0 && Object?.keys(data)?.length > 0 && !isStatic) {
            let datas = Utils.getFilteredRecords(subModuleData?.[data?.moduleId], data?.duration1, data?.duration2);
            let newFilteredData = Utils.filteredDataGet(datas, data); 
            let field = data?.field;
            let calculate = (operation, label) => {
                let values = newFilteredData?.map(record => {
                    let value = 0;
                    for (let section of record?.data || []) {
                        if (section?.section_data?.[label] !== undefined) {
                            value = parseFloat(section.section_data[label]) || 0;
                            break;
                        }
                    }
                    return value;
                });
                switch (operation) {
                    case 'sum':
                        return values.length ? values.reduce((total, val) => total + val, 0) : 0;
                    case 'min':
                        return values.length ? Math.min(...values) : 0;
                    case 'max':
                        return values.length ? Math.max(...values) : 0;
                    case 'average':
                        return values.length ? values.reduce((total, val) => total + val, 0) / values.length : 0;
                    default:
                        return 0;
                }
            };

            let getLabel = (prefix) => Helpers.replaceData(field?.replace(new RegExp(`^${prefix} of `), ''));

            if (field?.startsWith("Count of - ")) {
                setGaugeChartOptions(getOptions(newFilteredData?.length || 0));
            } else if (field?.startsWith("Sum of ")) {
                let label = getLabel("Sum");
                setGaugeChartOptions(getOptions(calculate('sum', label)));
            } else if (field?.startsWith("Minimum of ")) {
                let label = getLabel("Minimum");
                setGaugeChartOptions(getOptions(calculate('min', label)));
            } else if (field?.startsWith("Maximum of ")) {
                let label = getLabel("Maximum");
                setGaugeChartOptions(getOptions(calculate('max', label)));
            } else if (field?.startsWith("Average of ")) {
                let label = getLabel("Average");
                setGaugeChartOptions(getOptions(calculate('average', label)));
            }
            setTimeout(() => {
                setWidth("100%");
            }, 100);
        } else if(isStatic) {
            setTimeout(() => {
                setWidth("100%");
            }, 100);
        }
    }, [subModuleData?.[data?.moduleId], data]);

    return (
        <ReactEcharts option={gaugeChartOptions} style={{ height: "100%", width: width }} />
    );
}

export default DialGaugeChart;

