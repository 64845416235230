import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { handleFieldLabelUpdate } from '../../../../Utils'; 

function MultiLookupPropertiesModal({ show, handleClose, item, editPropertySubmit }) {
  let fields = item?.extra_fields;
  let formList = useSelector((state) => state?.forms);
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label);
  const [relatedListTitle, setRelatedListTitle] = useState(fields?.related_list_title);
  const [moduleId, setModuleId] = useState(fields?.moduleId);
  const [labelError, setLabelError] = useState(''); 

  
  const handleFieldLabelChange = (value) => {
    handleFieldLabelUpdate(value, (newLabel) => {
      setFieldLabel(newLabel);
    }, setLabelError); 
  };

  const handleSubmit = () => {
    if (labelError) return; 

    editPropertySubmit({
      field_label: fieldLabel,
      moduleId,
      related_list_title: relatedListTitle
    });
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lookup Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Field Label</Form.Label>
              <Form.Control required type="text" value={fieldLabel} onChange={(e) => handleFieldLabelChange(e.target.value)} />
              {labelError && <div className="text-danger">{labelError}</div>} 
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Select Related Module</Form.Label>
              <Form.Select required value={moduleId} onChange={(e) => setModuleId(e.target.value)}>
                <option value="">Choose Module</option>
                {formList?.navbarList?.length > 0 && (
                  formList?.navbarList?.map((x, index) => (
                    <option key={index} value={x?._id}>{x?.name}</option>
                  ))
                )}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Field Label in Related Module</Form.Label>
              <Form.Control required type="text" value={relatedListTitle} onChange={(e) => setRelatedListTitle(e.target.value)} />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={labelError}> 
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MultiLookupPropertiesModal;
