import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { handleFieldLabelUpdate } from '../../../Utils'; 

function SingleLinePropertiesModal({ show, handleClose, item, editPropertySubmit }) {
  let fields = item?.extra_fields;
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label);
  const [fieldLabelError, setFieldLabelError] = useState('');
  const [charLimit, setCharLimit] = useState(fields?.number_of_characters_allowed); 
  const [required, setRequired] = useState(fields?.required);
  const [doNotAllowDuplicateValues, setDoNotAllowDuplicateValues] = useState(fields?.allow_duplicate_values);
  const [encryptField, setEncryptField] = useState(fields?.encrypt_field);
  const [setAsExternalField, setSetAsExternalField] = useState(fields?.set_as_external_field);
  const [showTooltip, setShowTooltip] = useState(fields?.show_tooltip?.checked);
  const [tooltipText, setTooltipText] = useState(fields?.show_tooltip?.message);
  const [radioValue, setRadioValue] = useState(fields?.show_tooltip?.type);
  const [showPreview, setShowPreview] = useState(false);

  const handleCancel = () => {
    setFieldLabel('');
    setCharLimit(25);
    setRequired(false);
    setDoNotAllowDuplicateValues(false);
    setEncryptField(false);
    setSetAsExternalField(false);
    setShowTooltip(false);
    setTooltipText('');
    setFieldLabelError('');
    handleClose();
  };

  const handleDone = () => {
    const normalizedFieldLabel = fieldLabel.trim().replace(/\s+/g, ' ');

    let formData = {
      field_label: normalizedFieldLabel,
      number_of_characters_allowed: charLimit,
      required,
      do_not_allow_duplicate_values: doNotAllowDuplicateValues,
      encrypt_field: encryptField,
      set_as_external_field: setAsExternalField,
      show_tooltip: {
        checked: showTooltip,
        type: radioValue,
        message: tooltipText,
      },
    };
    editPropertySubmit(formData);
    handleClose();
  };

  const handleFieldLabelChange = (e) => {
    let value = e.target.value;
    
    handleFieldLabelUpdate(value, setFieldLabel, setFieldLabelError);
  };

  const handleCharLimitChange = (e) => {
    let value = parseInt(e.target.value, 10);
    setCharLimit(Math.min(Math.max(value, 1), 255));
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
      <Modal.Header closeButton>
        <Modal.Title>Single Line Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFieldLabel">
            <Form.Label>Field Label</Form.Label>
            <Form.Control
              type="text"
              value={fieldLabel}
              onChange={handleFieldLabelChange} 
              placeholder="Enter field label"
              required
            />
            {fieldLabelError && <div className="text-danger">{fieldLabelError}</div>}
          </Form.Group>

          <Form.Group controlId="formCharLimit" className="mt-3">
            <Form.Label>Number of characters allowed</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={charLimit}
                onChange={handleCharLimitChange}  
                max={255}
                min={1}
                required
              />
              <InputGroup.Text>Max of 255 characters</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formRequired" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Required"
              checked={required}
              onChange={() => setRequired(!required)}
            />
          </Form.Group>

          <Form.Group controlId="formDoNotAllowDuplicateValues" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Do not allow duplicate values"
              checked={doNotAllowDuplicateValues}
              onChange={(e) => setDoNotAllowDuplicateValues(e?.target?.checked)}
            />
          </Form.Group>

          <Form.Group controlId="formEncryptField" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Encrypt Field"
              checked={encryptField}
              onChange={(e) => setEncryptField(e?.target?.checked)}
            />
          </Form.Group>

          <Form.Group controlId="formSetAsExternalField" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Set as External Field"
              checked={setAsExternalField}
              onChange={(e) => setSetAsExternalField(e?.target?.checked)}
            />
          </Form.Group>

          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip}
              onChange={() => setShowTooltip(!showTooltip)}
            />
          </Form.Group>

          {showTooltip && (
            <>
              <Form.Group controlId="formTooltipMessage" className="mt-3">
                <Form.Label>Tooltip Message</Form.Label>
                <Form.Control
                  type="text"
                  value={tooltipText}
                  onChange={(e) => setTooltipText(e.target.value)}
                  placeholder="Enter tooltip message"
                />
              </Form.Group>

              <Form.Group controlId="formTooltipType" className="mt-3">
                <Form.Label>Tooltip Type</Form.Label>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={radioValue === 'info'}
                  onChange={() => setRadioValue('info')}
                />
                <Form.Check
                  type="radio"
                  label="Static Text"
                  name="tooltipOption"
                  checked={radioValue === 'static'}
                  onChange={() => setRadioValue('static')}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              {/* Tooltip Preview Modal */}
              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {radioValue === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {tooltipText && <span className="ms-2">{tooltipText}</span>}
                    </div>
                  ) : (
                    <p>{tooltipText}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleDone}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SingleLinePropertiesModal;
