import { useNavigate } from "react-router-dom";
import React, {useState} from "react";
import {OverlayTrigger, Tooltip, Button, Modal, Dropdown} from 'react-bootstrap';
import { Utils } from "../../../Utils";
import { TbInfoCircleFilled, TbRefresh, TbUpload } from "react-icons/tb";
import { GoQuestion } from "react-icons/go";
import { Settings, Trash } from "lucide-react";

// Tooltip Component
const TooltipTrig = ({ id, children, title, customClass, placement }) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip className="pdf-temp-setting-tooltip" id={id}>{title}</Tooltip>}>
      {children}
    </OverlayTrigger>
);

// Color picker Component
const ColorPicker = ({ id, color, onColorChange, disabled }) => {
    return (
        <div className="input-group mb-0 choose-color">
            <input
                type="text"
                className="form-control"
                id={`${id}`}
                name={`${id}_Input`}
                value={color}
                onChange={(e) => onColorChange(e.target.value)}
                aria-label={`${id} palette`}
                aria-describedby={`${id}_addon`}
                placeholder="Enter Hex color value" 
                disabled={disabled}
            />
            <span className="input-group-text p-0" id={`${id}_addon`}>
                <input
                    type="color"
                    className="form-control-color h-100 p-0 border-0"
                    value={color}
                    onChange={(e) => onColorChange(e.target.value)}
                    title="Choose your color" 
                    disabled={disabled}
                />
            </span>
        </div>
    );
};

// Single Text Input with checkbox Component
const SingleTextCheckbox = ({checkboxId, checkboxLabel, isCheckboxEnabled, isCheckboxDisabled, inputId, inputValue, onValueChange, disabled, onCheckboxChange }) => {
    const handleCheckboxChange = (e) => {
        onCheckboxChange(e.target.checked); 
    };

    return (
        <div className="mb-3 row row-cols-2 justify-content-between">
            <div className="col-auto">
                <div className="form-check col-form-label">
                    <input type="checkbox" className={`form-check-input ${!isCheckboxDisabled ? 'cursor-pointer':''}`}
                        id={checkboxId}
                        checked={isCheckboxEnabled}
                        onChange={handleCheckboxChange} 
                        disabled={isCheckboxDisabled}
                    />
                    <label className={`form-check-label ${!isCheckboxDisabled ? 'cursor-pointer':''}`} htmlFor={checkboxId}>{checkboxLabel}</label>
                </div>
            </div>
            <div className="col-6">
                <input type="text" className="form-control"
                    id={inputId}
                    name={`${inputId}_Input`}
                    value={inputValue}
                    onChange={(e) => onValueChange(e.target.value)}
                    placeholder={`Enter ${inputValue} label text`}
                    disabled={disabled || !isCheckboxEnabled} 
                />
            </div>
        </div>
    );
};

// Table settings component - Field border, font, BG colors
const FieldColorCheckbox = ({labelText, haveCheck, checkboxId, isCheckboxEnabled, inputColorId, inputColorValue, onValueChange, disabled, onCheckboxChange }) => { 
    const handleCheckboxChange = (e) => {
        onCheckboxChange(e.target.checked);
    };

    return (
        <div className={`row ${!haveCheck ? 'justify-content-lg-between':''}`}>
            <label htmlFor={inputColorId} className="col-form-label pe-0 col-lg-5">{labelText}</label>
            {haveCheck ? (
            <div className="col-lg-1 pe-0 text-end">
                <div className="form-check pt-2">
                    <input type="checkbox"
                        className="form-check-input cursor-pointer"
                        id={checkboxId}
                        checked={isCheckboxEnabled}
                        onChange={handleCheckboxChange} 
                    />
                </div>
            </div>
            ):('')}
            <div className="col-lg-6">
                <ColorPicker
                    id={inputColorId}
                    color={inputColorValue}
                    onColorChange={onValueChange}
                    disabled={disabled || !isCheckboxEnabled} 
                />
            </div>
        </div>
    );
};

// Table settings component - Field font Size
const FieldTextSize = ({labelText, id, size, onValueChange }) => { 
    return (
        <div className="row justify-content-lg-between">
            <label htmlFor={id} className="col-form-label col-lg-6">{labelText}</label>
            <div className="col-lg-6">
                <div className="input-group mb-0">
                    <input
                        type="text"
                        className="form-control"
                        id={`${id}`}
                        name={`${id}_Input`}
                        value={size}
                        onChange={(e) => onValueChange(e.target.value)}
                        aria-label={`${id} group`}
                        aria-describedby={`${id}_addon`}
                        placeholder="Enter size"
                    />
                    <span className="input-group-text" id={`${id}_addon`}>pt</span>
                </div>
            </div>
        </div>
    );
};

// Header & Footer Modal component
const SimpleModal = (props) => {
    const { show, handleClose, size, modalTitle } = props;
    return (
        <Modal onClose={handleClose} size={size} show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title className="fw-normal">{modalTitle}</Modal.Title>
                <p className="btn-modal-close mb-0 ms-auto fs-5" onClick={() => handleClose()}>
                    <i className="fas fa-times text-danger text-opacity-75"></i>
                </p>
            </Modal.Header>
            <form>
                <Modal.Body>{props.children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="py-1" onClick={handleClose}>
                        Preview
                    </Button>
                    <Button variant="outline-secondary" className="py-1" onClick={handleClose}>
                        Cancel
                    </Button>
                    <button type="reset" className="btn btn-link p-0 py-2 ms-auto text-decoration-none">Clear Content</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};


const QuoteTemplate = () => {
    const navigate = useNavigate();

    // BEGIN GENERAL SETTINGS //
    // General settings -> Template Properties
    const [paperSize, setPaperSize] = useState("A4");
    const [orientation, setOrientation] = useState('Portrait');
    const [tempTopMargin, setTempTopMargin] = useState('0.7');
    const [tempBottomMargin, setTempBottomMargin] = useState('0.7');
    const [tempLeftMargin, setTempLeftMargin] = useState('0.55');
    const [tempRightMargin, setTempRightMargin] = useState('0.4');

    // General settings -> Font
    const [allFontFamily, setAllFontFamily] = useState("Ubuntu");
    const [allLabelColor, setAllLabelColor] = useState('#000000');
    const [allFontColor, setAllFontColor] = useState('#000000');
    const [allFontSize, setAllFontSize] = useState('8');

    // General settings -> Background
    const [tempBgImg, setTempBgImg] = useState(null);
    const [tempBgImgError, setTempBgImgError] = useState("");
    const [selectedTempBgPos, setSelectedTempBgPos] = useState('center center');
    const [tempBgColor, setTempBgColor] = useState('transparent');

    // Checkbox Enable/disable temp BG color picker
    const [isTempBgColorEnabled, setIsTempBgColorEnabled] = useState(true);
    const tempBgColorCheckbox = (e, setter) => {
        setter(e.target.checked);
    };

    // Font Family selection
    const handleFontFamilyChange = (fontName, fontDescription) => {
        setAllFontFamily(fontName);
    };

    // Map of fonts and their descriptions
    const fonts = [
        {
            name: "Open Sans",
            description: "Supports English, German, Spanish, French, Italian, Dutch, Polish, Portugese, Swedish, Catalan, Czech, Maltese, Russian, and Slovenian language characters.",
        },
        {
            name: "Ubuntu",
            description: "Supports English and European languages. This font can also render Indian Rupees Symbol.",
        },
        {
            name: "Roboto", 
            description: "Supports Latin, Greek and Cyrillic and Vietnamese scripts",
        },
        {
            name: "Helvetica",
            description: "Supports all English characters.",
        },
        {
            name: "Noto Sans",
            description: "Supports all European languages.",
        },
        {
            name: "DejaVu Sans",
            description: "Supports Latin, Greek, Hebrew, Georgian, Armanian and Cyrillic scripts.",
        },
        { name: "Arial", description: "A sans-serif font, widely used for both print and digital content." },
        { name: "Times New Roman", description: "A serif font with wide usage in printed documents." },
        { name: "Courier New", description: "A monospaced font, often used for code formatting." },
        { name: "Georgia", description: "A serif font commonly used for online content." },
        { name: "Verdana", description: "A sans-serif font designed for digital screen readability." },
        { name: "Lato", description: "A humanist sans-serif typeface with a warm, friendly feeling." },
        { name: "Montserrat", description: "A geometric sans-serif font with a modern, professional look." },
    ];

    const getTempFontFamily = (fontName) => {
        const fontMap = {
          "Open Sans": "'Open Sans', serif",
          "Ubuntu": "'Ubuntu', serif",
          "Roboto": "'Roboto', sans-serif",
          "Helvetica": "'Helvetica', sans-serif",
          "Noto Sans":"'Noto Sans', serif",
          "DejaVu Sans":"'DejaVu Sans', sans-serif",
          "Arial": "'Arial', sans-serif",
          "Times New Roman": "'Times New Roman', serif",
          "Courier New": "'Courier New', monospace",
          "Georgia": "'Georgia', serif",
          "Verdana": "'Verdana', sans-serif",
          "Lato": "'Lato', sans-serif",
          "Montserrat": "'Montserrat', sans-serif",
        };
    
        return fontMap[fontName] || "'Ubuntu', sans-serif"; // Default to Ubuntu if not found
    };

    // paper size selection
    const handlePaperSizeChange = (event) => {
        setPaperSize(event.target.value);
    };
    // paper orientation change
    const handleOrientationChange = (event) => {
        setOrientation(event.target.value);
    };

    // based on selected paper size width set to main template
    const getTempPaperDimensions = () => {
        let width, height;

        // Determine paper size dimensions (in inches)
        switch (paperSize) {
            case 'A5':
                width = 5.83;
                height = 8.27;
                break;
            case 'Letter':
                width = 8.5;
                height = 11;
                break;
            case 'A4':
            default:
                width = 8.27;
                height = 11.69;
                break;
        }

        // Adjust for orientation (Portrait or Landscape)
        if (orientation === 'Landscape') {
            [width, height] = [height, width]; // Swap width and height
        }

        return { width: `${width}in`, height: `${height}in` };
    };

    const { width, height } = getTempPaperDimensions();

    // Template BG Img
    const tempBGImgChange = (event) => {
        const file = event.target.files[0]; 
        if (file) {
            if (file.type.startsWith("image/")) {
                const fileUrl = URL.createObjectURL(file);
                setTempBgImg({ fileimage: fileUrl, filename: file.name });
                setTempBgImgError("");
            } else {
                setTempBgImgError("Please select a image file only.");
                setTempBgImg(null);
            }
        }
    };
    const removeTempImage = () => {
        setTempBgImg(null); 
        setTempBgImgError("");
    };
    // END GENERAL SETTINGS //
    
    // BEGIN Header Settings
    const [headerBgImg, setHeaderBgImg] = useState(null);
    const [headerBgImgError, setHeaderBgImgError] = useState("");
    // Header BG Change
    const headerBGImgChange = (event) => {
        const file = event.target.files[0]; 
        if (file) {
            if (file.type.startsWith("image/")) {
                const fileUrl = URL.createObjectURL(file);
                setHeaderBgImg({ fileimage: fileUrl, filename: file.name });
                setHeaderBgImgError("");
            } else {
                setHeaderBgImgError("Please select a image file only.");
                setHeaderBgImg(null);
            } 
        }
    };
    const removeHeaderImage = () => {
        setHeaderBgImg(null); 
        setHeaderBgImgError("");
    };

    const [selectedHeaderBgPos, setSelectedHeaderBgPos] = useState('top left');

    // Header BG Color
    const [headerBgColor, setHeaderBgColor] = useState('transparent');
    // Header BG Color Checkbox Enable/disable temp BG color picker
    const [isHeaderBgColorEnabled, setIsHeaderBgColorEnabled] = useState(true);
    const headerBgColorCheckbox = (e, setter) => {
        setter(e.target.checked);
    };

    // Header First page settings eneble/disable
    const [isHfPsettingEnabled, setIsHfPsettingEnabled] = useState(false);
    const headerFpSettingEnable = (e) => {
        setIsHfPsettingEnabled(e.target.checked); 
    };
    // Header First page Height
    const [headerFpHeight, setHeaderFpHeight] = useState('0.55');

    // Header customized content modal
    const [haFtitle, setHaFtitle] = useState(true);
    const [headFootCusContModal, setHeadFootCusContModal] = useState(false);
    function handleHaFmodalShow(modalHeaderTitle) {
        setHaFtitle(modalHeaderTitle);
        setHeadFootCusContModal(true);
    }

    const [hAfCustomValues, setHaFcustomValues] = useState('');
    const customPlaceholders = [
        {
          categoryTitle: 'ORGANIZATION',
          items: ['Organization Name', 'Logo', 'Street Address1', 'Street Address2', 'City', 'ZIP/Postal Code', 'State/Province', 'Country', 'Phone#', 'Fax#', 'Website', 'Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Value 1', 'Value 2', 'Value 3', 'Value 4', 'Value 5', 'CompanyIDLabel', 'CompanyIDValue', 'TaxIDLabel', 'TaxIDValue'],
        },
        {
          categoryTitle: 'QUOTE',
          items: ['Quote Number', 'Quote Date', 'Ref. Number', 'Total'],
        },
        {
          categoryTitle: 'CUSTOMER',
          items: ['Salutation', 'First Name', 'Last Name', 'Email', 'Phone', 'Mobile', 'Department', 'Designation', 'Skype', 'Name', 'Company Name', 'Billing Attention', 'Billing Address', 'Billing City', 'Billing State', 'Billing Code', 'Billing Country', 'Billing Phone', 'Billing Fax', 'Shipping Attention', 'Shipping Address', 'Shipping City', 'Shipping State', 'Shipping Code', 'Shipping Country', 'Shipping Phone', 'Shipping Fax', 'Twitter', 'Facebook', 'Website'],
        },
    ];
    const handleHaFcustomValuesChange = (e) => {
        setHaFcustomValues(e.target.value);
    };
    const handleInsertCustomPlaceholder = (placeholder) => {
        setHaFcustomValues(prev => prev + `\n%${placeholder}%`);
    };
    // END Header Settings //

    // Begin Footer Settings 
    const [footFontColor, setFootFontColor] = useState('#000000');
    const [footFontSize, setFootFontSize] = useState('8');
    const [footBgImg, setFootBgImg] = useState(null);
    const [footBgImgError, setFootBgImgError] = useState("");
    // Footer BG Change
    const footBGImgChange = (event) => {
        const file = event.target.files[0]; 
        if (file) {
            if (file.type.startsWith("image/")) {
                const fileUrl = URL.createObjectURL(file);
                setFootBgImg({ fileimage: fileUrl, filename: file.name });
                setFootBgImgError("");
            } else {
                setFootBgImgError("Please select a image file only.");
                setFootBgImg(null);
            } 
        }
    };
    const removeFootImage = () => {
        setFootBgImg(null); 
        setFootBgImgError("");
    };

    const [selectedFootBgPos, setSelectedFootBgPos] = useState('top left');

    // Footer BG Color
    const [footBgColor, setFootBgColor] = useState('transparent');
    // Footer BG Color Checkbox Enable/disable temp BG color picker
    const [isFootBgColorEnabled, setIsFootBgColorEnabled] = useState(true);
    const footBgColorCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // END FOOTER Settings //

    // Begin:: Transaction Details //
    // Begin:: Organization Details //
    const [isOrgImgCheckEnabled, setIsOrgImgCheckEnabled] = useState(false);
    const tDOrgImgSettingEnable = (e) => {
        setIsOrgImgCheckEnabled(e.target.checked); 
    };
    const [orgImg, setOrgImg] = useState(null);
    const [orgImgError, setOrgImgError] = useState("");
    // Org img Change
    const orgImgChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.startsWith("image/")) {
                const fileUrl = URL.createObjectURL(file);
                setOrgImg({ fileimage: fileUrl, filename: file.name });
                setOrgImgError("");
            } else {
                setOrgImgError("Please select a image file only.");
                setOrgImg(null);
            }
        }
    };

    // Org Name Show/Hide
    const [isOrgNameCheckEnabled, setIsOrgNameCheckEnabled] = useState(true);
    const tDOrgNameSettingEnable = (e) => {
        setIsOrgNameCheckEnabled(e.target.checked); 
    };

    const [orgNameFontColor, setOrgNameFontColor] = useState('#000000');
    const [orgNameFontSize, setOrgNameFontSize] = useState('12');

    // Org Address Check show/hide
    const [isOrgAddressCheckEnabled, setIsOrgAddressCheckEnabled] = useState(true);
    const tDOrgAddressSettingEnable = (e) => {
        setIsOrgAddressCheckEnabled(e.target.checked); 
    };

    // Org Address Format Modal
    const [orgAddressFormatShow, setOrgAddressFormatShow] = useState(false);

    const orgAddressModalClose = () => {
        setOrgAddressFormatShow(false);
        setOrgAddConfigShow(true);
    }
    const orgAddressModalShow = () => {
        setOrgAddressFormatShow(true);
        setOrgAddConfigShow(true);
    }

    // Org Address Format Custom Placeholders change function
    const [orgAddCustomValues, setOrgAddCustomValues] = useState('${ORGANIZATION.CITY} ${ORGANIZATION.STATE} ${ORGANIZATION.POSTAL_CODE}\n${ORGANIZATION.COUNTRY}\n${ORGANIZATION.PHONE}\n${ORGANIZATION.EMAIL}\n${ORGANIZATION.WEBSITE}');
    const orgCustomPlaceholders = [
        {
          categoryTitle: 'ORGANIZATION',
          items: ['Street Address1', 'Street Address2', 'Organization Name', 'City', 'State/Province', 'Country', 'ZIP/Postal Code'],
        },
        {
          categoryTitle: 'QUOTE',
          items: ['Fax Label', 'Fax', 'Phone Label', 'Phone', 'Email', 'Website'],
        },
    ];
    const handleOrgCustomValuesChange = (e) => {
        setOrgAddCustomValues(e.target.value);
    };
    const handleInsertOrgCustomPlaceholder = (placeholder) => {
        setOrgAddCustomValues(prev => prev + '\n${ORGANIZATION.' + `${placeholder}}`);
    };
    const [orgAddConfigShow, setOrgAddConfigShow] = useState(true);
    // END:: Organization Details //

    // Begin:: Customer Details //
    const [cusNameFontColor, setCusNameFontColor] = useState('#000000');
    const [cusNameFontSize, setCusNameFontSize] = useState('8');
    
    // Bill To
    const [isBillToEnabled, setIsBillToEnabled] = useState(true);
    const billToCheckbox = (e, setter) => {
        setter(e.target.checked);
    };

    // Bill To Label Text
    const [billToLabelText, setBillToLabelText] = useState('Bill To');
    const handleBillToLabelChange = (e) => {
        setBillToLabelText(e.target.value);
    };

    // Ship To
    const [isShipToEnabled, setIsShipToEnabled] = useState(true);
    const shipToCheckbox = (e, setter) => {
        setter(e.target.checked);
    };

    // Ship To Label Text
    const [shipToLabelText, setShipToLabelText] = useState('Ship To');
    const handleShipToLabelChange = (e) => {
        setShipToLabelText(e.target.value);
    };

    // Bill To OR Ship To Address Format Modal
    const [billOrShipAddressFormatShow, setBillOrShipAddressFormatShow] = useState(false);

    const billOrShipAddressModalClose = () => {
        setBillOrShipAddressFormatShow(false);
        setBillOrShipAddConfigShow(true);
    }
    const [billOrShipTitle, setBillOrShipTitle] = useState(true);
    const billOrShipAddressModalShow = (headerTitle) => {
        setBillOrShipTitle(headerTitle);
        setBillOrShipAddressFormatShow(true);
        setBillOrShipAddConfigShow(true);
    }

    // Org Address Format Custom Placeholders change function
    const [billOrShipAddCustomValues, setBillOrShipAddCustomValues] = useState('${${CONTACT.CONTACT_DISPLAYNAME}\n${CONTACT.CONTACT_ADDRESS}\n${CONTACT.CONTACT_CITY}\n${CONTACT.CONTACT_CODE} ${CONTACT.CONTACT_STATE}\n${CONTACT.CONTACT_COUNTRY}');
    const billOrShipCustomPlaceholders = [
        {
          categoryTitle: 'CONTACT',
          items: ['Display Name', 'Company Name', 'Website', 'Salutation', 'First Name', 'Last Name', 'Contact Email', 'Mobile Phone', 'Phone Label', 'Phone', 'Department', 'Designation', 'PAN Label', 'PAN', ''],
        },
        {
          categoryTitle: 'ADDRESS',
          items: ['Attention', 'Steet Address', 'Steet Address1', 'Steet Address2', 'City', 'State/Province', 'Country', 'ZIP/Postal Code', 'FAX Label', 'Phone', 'Fax'],
        },
    ];
    const handleBillOrShipCustomValuesChange = (e) => {
        setBillOrShipAddCustomValues(e.target.value);
    };
    const handleInsertBillOrShipCustomPlaceholder = (placeholder) => {
        setBillOrShipAddCustomValues(prev => prev + '\n${CONTACT.' + `${placeholder}}`);
    };
    const [billOrShipAddConfigShow, setBillOrShipAddConfigShow] = useState(true);
    // END:: Customer Details //

    // Begin:: Document Details //
    // Doc Title
    const [isDocTitleEnabled, setIsDocTitleEnabled] = useState(true);
    const docTitleCheckbox = (e, setter) => {
        setter(e.target.checked);
    };

    // Doc Title Label Text
    const [docTitleLabelText, setDocTitleLabelText] = useState('QUOTE');
    const handleDocTitleLabelChange = (e) => {
        setDocTitleLabelText(e.target.value);
    };

    // Doc Title Color & Size
    const [docTitleFontColor, setDocTitleFontColor] = useState('#000000');
    const [docTitleFontSize, setDocTitleFontSize] = useState('22');

    // Document Information //
    const [checkboxes, setCheckboxes] = useState([
        { id: 'numFieldCheckbox', label: 'Number Field', value: '#', placeholderValue: 'QT-17', isChecked: true},
        { id: 'quoteDateCheckbox', label: 'Date Field', value: 'Quote Date', placeholderValue: '07/01/2025', isChecked: true},
        { id: 'expiryDateCheckbox', label: 'Expiry Date', value: 'Expiry Date', placeholderValue: '13/01/2025', isChecked: true},
        { id: 'referenceCheckbox', label: 'Reference Field', value: 'Reference#', placeholderValue: 'SO-17', isChecked: true},
        { id: 'salesPersonCheckbox', label: 'Sales Person', value: 'Sales Person', placeholderValue: 'Richard James', isChecked: true},
        { id: 'projectNameCheckbox', label: 'Project', value: 'Project Name', placeholderValue: 'Design project', isChecked: true },
        { id: 'subjectCheckbox', label: 'Subject', value: 'Subject', placeholderValue: '', isChecked: true }
    ]);

    const handleCheckboxChange = (id, isChecked) => {
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked } : checkbox
            )
        );
    };
    const handleValueChange = (id, value) => {
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, value } : checkbox
            )
        );
    };
    // Document Details "Table Left" section
    const firstArray = checkboxes.filter(checkbox =>
        ['numFieldCheckbox', 'quoteDateCheckbox', 'expiryDateCheckbox', 'referenceCheckbox'].includes(checkbox.id)
    );

    // Document Details "Table Right" section
    const secondArray = checkboxes.filter(checkbox =>
        ['salesPersonCheckbox', 'projectNameCheckbox'].includes(checkbox.id)
    );

    // Document Details "Subject" section
    const thirdArray = checkboxes.filter(checkbox =>
        ['subjectCheckbox'].includes(checkbox.id)
    );

    const [addAttentionContModal, setAddAttentionContModal] = useState(false);
    function handleAddAttentionContModalShow() {
        setAddAttentionContModal(true);
    }
    // END:: Document Details //
    // END:: Transaction Details //

    // Begin:: Table Settings //
    // Label - Items num checkbox
    const [isLineItemNumCheckEnabled, setIsLineItemNumCheckEnabled] = useState(true);
    const lineItemNumCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Items num width
    const [lineNumWidth, setLineNumWidth] = useState('4');
    const handleLineNumWidthChange = (e) => {
        setLineNumWidth(e.target.value);
    };
    // Label - Items num 
    const [lineNumLabelText, setLineNumLabelText] = useState('#');
    const handleLineNumLabelChange = (e) => {
        setLineNumLabelText(e.target.value);
    };

    // Label - Items checkbox
    const [isItemEnabled, setIsItemEnabled] = useState(true);
    const itemCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Items width
    const [itemWidth, setItemWidth] = useState('64');
    const handleItemWidthChange = (e) => {
        setItemWidth(e.target.value);
    };
    // Label - Items  
    const [itemLabelText, setItemLabelText] = useState('Item');
    const handleItemLabelChange = (e) => {
        setItemLabelText(e.target.value);
    };

    // Label - Descrpition checkbox
    const [isDescLabelEnabled, setIsDescLabelEnabled] = useState(true);
    const descLabelCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Descrpition
    const [descLabelText, setDescLabelText] = useState('Description');
    const handleDescLabelChange = (e) => {
        setDescLabelText(e.target.value);
    };

    // Custom Name & Description format Modal
    const [cusNameAndDescModal, setCusNameAndDescModal] = useState(false);
    function handleCusNameAndDescModalShow() {
        setCusNameAndDescModal(true);
    }
    const [cusNameAndDescValues, setCusNameAndDescValues] = useState('');
    const cusNameAndDescPlaceholders = [
        {
          categoryTitle: 'ITEM',
          items: ['Item Name', 'Item SKU', 'Description', 'HSN/SAC Label', 'HSN/SAC Value', 'Item Unit'],
        },
    ];
    const handleCusNameAndDescValuesChange = (e) => {
        setCusNameAndDescValues(e.target.value);
    };
    const handleCusNameAndDescPlaceholder = (placeholder) => {
        setCusNameAndDescValues(prev => prev + `\n%${placeholder}%`);
    };

    // Label - Qty checkbox
    const [isTaQtyEnabled, setIsTaQtyEnabled] = useState(true);
    const taQtyCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Qty width
    const [taQtyWidth, setTaQtyWidth] = useState('11');
    const handleTaQtyWidthChange = (e) => {
        setTaQtyWidth(e.target.value);
    };
    // Label - Qty  
    const [taQtyLabelText, setTaQtyLabelText] = useState('Qty');
    const handleTaQtyLabelChange = (e) => {
        setTaQtyLabelText(e.target.value);
    };
    // Label - Qty Unit checkbox
    const [isTaQtyUnitEnabled, setIsTaQtyUnitEnabled] = useState(true);
    const taQtyUnitCheckbox = (e, setter) => {
        setter(e.target.checked);
    };

    // Label - Rate checkbox
    const [isTaRateEnabled, setIsTaRateEnabled] = useState(true);
    const taRateCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Rate width
    const [taRateWidth, setTaRateWidth] = useState('11');
    const handleTaRateWidthChange = (e) => {
        setTaRateWidth(e.target.value);
    };
    // Label - Rate  
    const [taRateLabelText, setTaRateLabelText] = useState('Rate');
    const handleTaRateLabelChange = (e) => {
        setTaRateLabelText(e.target.value);
    };

    // Label - Discount checkbox
    const [isTaDisEnabled, setIsTaDisEnabled] = useState(false);
    const taDisCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Discount width
    const [taDisWidth, setTaDisWidth] = useState('11');
    const handleTaDisWidthChange = (e) => {
        setTaDisWidth(e.target.value);
    };
    // Label - Discount  
    const [taDisLabelText, setTaDisLabelText] = useState('Discount');
    const handleTaDisLabelChange = (e) => {
        setTaDisLabelText(e.target.value);
    };

    // Label - Tax % checkbox
    const [isTaTaxPerEnabled, setIsTaTaxPerEnabled] = useState(false);
    const taTaxPerCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Tax % width
    const [taTaxPerWidth, setTaTaxPerWidth] = useState('11');
    const handleTaTaxPerWidthChange = (e) => {
        setTaTaxPerWidth(e.target.value);
    };
    // Label - Tax %  
    const [taTaxPerLabelText, setTaTaxPerLabelText] = useState('Tax %');
    const handleTaTaxPerLabelChange = (e) => {
        setTaTaxPerLabelText(e.target.value);
    };

    // Label - Tax Amount checkbox
    const [isTaTaxAmtEnabled, setIsTaTaxAmtEnabled] = useState(false);
    const taTaxAmtCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Tax Amount width
    const [taTaxAmtWidth, setTaTaxAmtWidth] = useState('11');
    const handleTaTaxAmtWidthChange = (e) => {
        setTaTaxAmtWidth(e.target.value);
    };
    // Label - Tax Amount  
    const [taTaxAmtLabelText, setTaTaxAmtLabelText] = useState('Tax');
    const handleTaTaxAmtLabelChange = (e) => {
        setTaTaxAmtLabelText(e.target.value);
    };

    // Label - Amount checkbox
    const [isTaAmtEnabled, setIsTaAmtEnabled] = useState(true);
    const taAmtCheckbox = (e, setter) => {
        setter(e.target.checked);
    };
    // Label - Amount width
    const [taAmtWidth, setTaAmtWidth] = useState('13');
    const handleTaAmtWidthChange = (e) => {
        setTaAmtWidth(e.target.value);
    };
    // Label - Amount  
    const [taAmtLabelText, setTaAmtLabelText] = useState('Amount');
    const handleTaAmtLabelChange = (e) => {
        setTaAmtLabelText(e.target.value);
    };
    // END:: Label Settings //

    // Begin:: Table Layout Settings
    const [tempBorderColor, setTempBorderColor] = useState('#9e9e9e');
    const [isTableBorderEnabled, setIsTableBorderEnabled] = useState(true);
    const handleTempBorderCheckboxChange = (isChecked) => {
        setIsTableBorderEnabled(isChecked);
    };

    // Table Head
    const [taHeadFontSize, setTaHeadFontSize] = useState('8');
    
    const [taHeadBgColor, setTaHeadBgColor] = useState('#f2f3f4');
    const [isTaHeadBgColorEnabled, setIsTaHeadBgColorEnabled] = useState(true);
    const handleTaHeadBgColorCheckboxChange = (isChecked) => {
        setIsTaHeadBgColorEnabled(isChecked);
    };

    const [taHeadFontColor, setTaHeadFontColor] = useState('#000000');

    // Table Body (Item Row)
    const [taBodyFontSize, setTaBodyFontSize] = useState('8');
    
    const [taBodyBgColor, setTaBodyBgColor] = useState('#ffffff');
    const [isTaBodyBgColorEnabled, setIsTaBodyBgColorEnabled] = useState(true);
    const handleTaBodyBgColorCheckboxChange = (isChecked) => {
        setIsTaBodyBgColorEnabled(isChecked);
    };

    const [taBodyFontColor, setTaBodyFontColor] = useState('#000000');
    const [taBodyDescSize, setTaBodyDescSize] = useState('8');
    const [taBodyDescColor, setTaBodyDescColor] = useState('#000000');
    // END:: Table Layout Settings //
    // END:: Table Settings //

    // Begin:: Total Section Settings //
    // Labels section
    const [isAllTotalSetEnabled, setIsAllTotalSetEnabled] = useState(true);
    const allTotalSetChange = (e) => {
        setIsAllTotalSetEnabled(e.target.checked); 
    };

    const [subTotalText, setSubTotalText] = useState("Sub Total");
    const [isSubTotalEnabled, setIsSubTotalEnabled] = useState(true);
    const handleSubTotalCheckboxChange = (isChecked) => {
        setIsSubTotalEnabled(isChecked);
    };

    const [shipChargeText, setShipChargeText] = useState("Shipping Charge");
    const [isShipChargeEnabled, setIsShipChargeEnabled] = useState(true);
    const handleShipChargeCheckboxChange = (isChecked) => {
        setIsShipChargeEnabled(isChecked);
    };

    const [isDiscountSecEnabled, setIsDiscountSecEnabled] = useState(true);
    const discountSecChange = (e) => {
        setIsDiscountSecEnabled(e.target.checked);
    };
    
    // Tatal Taxable Amount checkbox
    const [isToTaxAmtEnabled, setIsToTaxAmtEnabled] = useState(false);
    const toTaxAmtCheckbox = (e) => {
        setIsToTaxAmtEnabled(e.target.checked);
    };

    // Tatal Taxable Amount Label Text
    const [toTaxAmtLabelText, setToTaxAmtLabelText] = useState('Total Taxable Amount');
    const handleToTaxAmtLabelChange = (e) => {
        setToTaxAmtLabelText(e.target.value);
    };

    // Show Tax Details 
    const [isShowTaxDetailEnabled, setIsShowTaxDetailEnabled] = useState(false);
    const showTaxDetailCheckbox = (e) => {
        setIsShowTaxDetailEnabled(e.target.checked);
    };
    
    const [mainTotalText, setMainTotalText] = useState("Total");
    const [isMainTotalEnabled, setIsMainTotalEnabled] = useState(true);
    const handleMainTotalCheckboxChange = (isChecked) => {
        setIsMainTotalEnabled(isChecked);
    };

    // Currency symbol position
    const [isCurSymbolEnabled, setIsCurSymbolEnabled] = useState(true); 
	const [selectedCurSymbolPos, setSelectedCurSymbolPos] = useState("beforeAmt");

    // Quantity Total setting check
    const [isTotalQtySetEnabled, setIsTotalQtySetEnabled] = useState(false);
    const totalQtySetChange = (e) => {
        setIsTotalQtySetEnabled(e.target.checked); 
    };
    const [itemsTotalLabelText, setItemsTotalLabelText] = useState("Items in Total");
    const handleItemsTotalLabelChange = (e) => {
        setItemsTotalLabelText(e.target.value);
    };

    // Show amount in words check
    const [isAmtShowWordsEnabled, setIsAmtShowWordsEnabled] = useState(true);
    const amtShowWordsChange = (e) => {
        setIsAmtShowWordsEnabled(e.target.checked); 
    };
    // END:: Labels Section // 

    // Begin:: Layout Section 
    // Total section layout setiings
    const [totalSecFsize, setTotalSecFsize] = useState('8');
    const [totalSecFcolor, setTotalSecFcolor] = useState('#000000');
    const [totalSecBgColor, setTotalSecBgColor] = useState('#ffffff');
    const [isTotalSecBgColorEnabled, setIsTotalSecBgColorEnabled] = useState(true);
    const handleTotalSecBgColorCheckboxChange = (isChecked) => {
        setIsTotalSecBgColorEnabled(isChecked);
    };

    // Balance Due section layout setiings
    const [balDueSecFsize, setBalDueSecFsize] = useState('9');
    const [balDueSecFcolor, setBalDueSecFcolor] = useState('#000000');
    const [balDueSecBgColor, setBalDueSecBgColor] = useState('#ffffff');
    const [isBalDueSecBgColorEnabled, setIsBalDueSecBgColorEnabled] = useState(true);
    const handleBalDueSecBgColorCheckboxChange = (isChecked) => {
        setIsBalDueSecBgColorEnabled(isChecked);
    };
    // END:: Layout Section //
    // END:: Total Section Settings //

    // Begin:: Other Details Section Settings //
    // Begin:: Document Summary Tab Settings //
    const [notesLabelText, setNotesLabelText] = useState("Notes");
    const handleNotesLabelChange = (e) => {
        setNotesLabelText(e.target.value);
    };
    const [notesFsize, setNotesFsize] = useState('8');

    const [addBankDetailsModal, setAddBankDetailsModal] = useState(false);
    function handleAddBankDetailsModalShow() {
        setAddBankDetailsModal(true);
    }

    // Terms & Conditions
    const [isTermCondSetEnabled, setIsTermCondSetEnabled] = useState(true);
    const termCondSetChange = (e) => {
        setIsTermCondSetEnabled(e.target.checked); 
    };
    const [termCondLabelText, setTermCondLabelText] = useState("Terms & Conditions");
    const handleTermCondLabelChange = (e) => {
        setTermCondLabelText(e.target.value);
    };
    const [termCondFsize, setTermCondFsize] = useState('8');

    // Signature
    const [isSignSetEnabled, setIsSignSetEnabled] = useState(true);
    const signSetChange = (e) => {
        setIsSignSetEnabled(e.target.checked); 
    };
    const [signLabelText, setSignLabelText] = useState("Authorized Signature");
    const handleSignLabelChange = (e) => {
        setSignLabelText(e.target.value);
    };

    // Signature Image
    const [signImg, setSignImg] = useState(null);
    const [signImgError, setSignImgError] = useState("");
    // Signature Image Change
    const signImgChange = (event) => {
        const file = event.target.files[0]; 
        if (file) {
            if (file.type.startsWith("image/")) {
              const fileUrl = URL.createObjectURL(file);
              setSignImg({ fileimage: fileUrl, filename: file.name });
              setSignImgError(""); 
            } else {
              setSignImgError("Please select a image file only."); 
              setSignImg(null); 
            }
        }
    };
    const removeSignImage = () => {
        setSignImg(null); 
        setSignImgError("");
    };

    // Sign Name
    const [signNameText, setSignNameText] = useState("");
    const handleSignNameChange = (e) => {
        setSignNameText(e.target.value);
    };
    // END:: Document Summary Tab Settings //

    // Begin:: Annexure Tab Settings //
    const [addAnnexureModal, setAddAnnexureModal] = useState(false);
    function handleAddAnnexureModalShow() {
        setAddAnnexureModal(true);
    }

    const [annexContValues, setAnnexContValues] = useState('');
    const handleAnnexContValuesChange = (e) => {
        setAnnexContValues(e.target.value);
    };
    const handleInsertAnnexContPlaceholder = (placeholder) => {
        setAnnexContValues(prev => prev + `\n%${placeholder}%`);
    };
    // END:: Annexure Tab Settings //
    // END:: Other Details Section Settings //

    return (<>
        <div className="position-relative pdf-temp-design">
            <div className="navbar sticky-top bg-light-subtle border-bottom border-light-subtle px-0 tb-header">
                <div className="ps-3 me-auto">
                    <span className="text-body fw-medium fs-5">
                        <i className="fa fa-arrow-left me-2 cursor-pointer"
                        onClick={() => navigate(Utils.getNavigationUrl(`settings`))}></i> 
                        Edit Template
                    </span>
                </div>
                <div className="pe-3 ms-auto">
                    <div className="btn-group">
                        <button type="button" className="btn btn-outline-primary rounded-pill btn-blue">
                            <TbRefresh size={16} />
                            <span className="ps-2">Refresh Preview</span>
                        </button>
                        <button type="button" className="btn btn-primary rounded-pill btn-blue">
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="content-section">
                
                <div className="px-5 fw-medium hstack d-md-none mx-auto my-5 py-5">
                    <span>
                        This page best fit to view <span className="fw-semibold">above 768 viewport</span> only.
                    </span>
                </div>

                <div className="d-none d-md-flex h-100">
                    {/* Left side tab with settings forms */}
                    <div className="l-tab-view">
                        <div className="d-flex align-items-start h-100">
                            <div className="position-relative h-100" style={{width: "94px"}}>
                                <div className="nav flex-column nav-pills small position-absolute w-100 h-100 overflow-y-auto border-end" id="v-pills-tab" 
                                role="tablist" aria-orientation="vertical">
                                    <button className="nav-link m-1 px-2 mb-0 border-bottom active" id="v-pills-1-tab" 
                                    data-bs-toggle="pill" data-bs-target="#v-pills-1" type="button" role="tab" 
                                    aria-controls="v-pills-1" aria-selected="true">
                                        General
                                    </button>
                                    <button className="nav-link m-1 mb-0 border-bottom px-2" id="v-pills-2-tab" 
                                    data-bs-toggle="pill" data-bs-target="#v-pills-2" type="button" role="tab" 
                                    aria-controls="v-pills-2" aria-selected="false">
                                        Header & Footer
                                    </button>
                                    <button className="nav-link m-1 mb-0 border-bottom px-2" id="v-pills-3-tab" 
                                    data-bs-toggle="pill" data-bs-target="#v-pills-3" type="button" role="tab" 
                                    aria-controls="v-pills-3" aria-selected="false">
                                        Transaction Details
                                    </button>
                                    <button className="nav-link m-1 mb-0 border-bottom px-2" id="v-pills-4-tab" 
                                    data-bs-toggle="pill" data-bs-target="#v-pills-4" type="button" role="tab" 
                                    aria-controls="v-pills-4" aria-selected="false">
                                        Table
                                    </button>
                                    <button className="nav-link m-1 mb-0 border-bottom px-2" id="v-pills-5-tab" 
                                    data-bs-toggle="pill" data-bs-target="#v-pills-5" type="button" role="tab" 
                                    aria-controls="v-pills-5" aria-selected="false">
                                        Total
                                    </button>
                                    <button className="nav-link m-1 mb-0 border-bottom px-2" id="v-pills-6-tab" 
                                    data-bs-toggle="pill" data-bs-target="#v-pills-6" type="button" role="tab" 
                                    aria-controls="v-pills-6" aria-selected="false">
                                        Other Details
                                    </button>
                                </div>
                            </div>
                            <div className="position-relative h-100" style={{width: "386px"}}>
                                <div className="tab-content position-absolute w-100 h-100 overflow-y-auto border-end" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-1" role="tabpanel" 
                                    aria-labelledby="v-pills-1-tab" tabIndex="0">
                                        <div className="accordion accordion-flush bg-body-tertiary pb-2" id="generalAccordion">
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#generalCollapseOne" aria-expanded="true" aria-controls="generalCollapseOne">
                                                        Template Properties
                                                    </button>
                                                </h2>
                                                <div id="generalCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#generalAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="templateName" className="form-label text-danger">Template Name *</label>
                                                            <input type="text" className="form-control" id="templateName" 
                                                            name="template_name" defaultValue="Spreadsheet Template" 
                                                            placeholder="Enter template name" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="col-form-label">
                                                                <span>Paper Size</span>
                                                                <TooltipTrig id="paperSizeInfo"
                                                                title="If you've used a Header or Footer image from the Gallery and you change the Paper Size, you need to remove and upload the image so that it fits the new paper size.">
                                                                    <span className="ps-1 text-gray">
                                                                        <TbInfoCircleFilled size={16} />
                                                                    </span>
                                                                </TooltipTrig>
                                                            </div>
                                                            <div className="form-check ps-0">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input cursor-pointer" type="radio" name="paperSizeOptions" id="paperSize1" 
                                                                    value="A5" onChange={handlePaperSizeChange} 
                                                                    checked={paperSize === 'A5'} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="paperSize1">A5</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input cursor-pointer" type="radio" name="paperSizeOptions" id="paperSize2" 
                                                                    value="A4" onChange={handlePaperSizeChange} 
                                                                    checked={paperSize === 'A4'} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="paperSize2">A4</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input cursor-pointer" type="radio" name="paperSizeOptions" id="paperSize3" 
                                                                    value="Letter" onChange={handlePaperSizeChange} 
                                                                    checked={paperSize === 'Letter'} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="paperSize3">Letter</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="col-form-label">
                                                                <span>Orientation</span>
                                                            </div>
                                                            <div className="form-check ps-0">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input cursor-pointer" type="radio" name="orientationOptions" id="orientation1" 
                                                                    value="Portrait" onChange={handleOrientationChange} 
                                                                    checked={orientation === 'Portrait'} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="orientation1">Portrait</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input cursor-pointer" type="radio" name="orientationOptions" id="orientation2" 
                                                                    value="Landscape" onChange={handleOrientationChange} 
                                                                    checked={orientation === 'Landscape'} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="orientation2">Landscape</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="col-form-label">
                                                                Margins <span className="text-body-secondary">(in inches)</span>
                                                            </div>
                                                            <div className="d-flex gap-3">
                                                                <div>
                                                                    <label htmlFor="marginTop" className="form-label small">Top</label>
                                                                    <input type="text" className="form-control" id="marginTop" 
                                                                    name="margin_top" value={tempTopMargin} 
                                                                    placeholder="Enter top margin" 
                                                                    onChange={e => setTempTopMargin(e.target.value)} />
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="marginBottom" className="form-label small">Bottom</label>
                                                                    <input type="text" className="form-control" id="marginBottom" 
                                                                    name="margin_bottom" value={tempBottomMargin} 
                                                                    placeholder="Enter bottom margin" 
                                                                    onChange={e => setTempBottomMargin(e.target.value)} />
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="marginLeft" className="form-label small">Left</label>
                                                                    <input type="text" className="form-control" id="marginLeft" 
                                                                    name="margin_left" value={tempLeftMargin} 
                                                                    placeholder="Enter left margin" 
                                                                    onChange={e => setTempLeftMargin(e.target.value)} />
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="marginRight" className="form-label small">Right</label>
                                                                    <input type="text" className="form-control" id="marginRight" 
                                                                    name="margin_right" value={tempRightMargin} 
                                                                    placeholder="Enter right margin" 
                                                                    onChange={e => setTempRightMargin(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#generalCollapseTwo" aria-expanded="false" aria-controls="generalCollapseTwo">
                                                        Font
                                                    </button>
                                                </h2>
                                                <div id="generalCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#generalAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="pdfFont" className="col-form-label">PDF Font</label>
                                                            <div className="dropdown custom-dropdown w-100">
                                                                <button type="button" id="pdfFont" data-bs-toggle="dropdown" aria-expanded="false"
                                                                className="btn btn-link border dropdown-toggle with-arrow text-decoration-none text-dark w-100 justify-content-start">
                                                                    {allFontFamily}
                                                                </button>
                                                                <ul className="dropdown-menu w-100 max-h-200px">
                                                                    {fonts.map((font) => (
                                                                        <li key={font.name}>
                                                                            <button
                                                                                className={`dropdown-item rounded-0 ps-3 mb-2 ${allFontFamily === font.name ? 'active' : ''}`}
                                                                                type="button"
                                                                                onClick={() => handleFontFamilyChange(font.name, font.description)}
                                                                            >
                                                                                <span className="d-block ps-3">
                                                                                    <span className="fw-medium">{font.name}</span> <br />
                                                                                    <span className="fw-light small">{font.description}</span>
                                                                                </span>
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="d-block text-secondary small mt-2">
                                                                {fonts.find((font) => font.name === allFontFamily)?.description}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="label_color" className="col-form-label">Label Color</label>
                                                            <ColorPicker
                                                                id="label_color"
                                                                color={allLabelColor}
                                                                onColorChange={setAllLabelColor}
                                                            />
                                                        </div>
                                                        <div className="row row-cols-2">
                                                            <div className="col">
                                                                <label htmlFor="font_color" className="col-form-label">Font Color</label>
                                                                <ColorPicker
                                                                    id="font_color"
                                                                    color={allFontColor}
                                                                    onColorChange={setAllFontColor}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label htmlFor="baseFontSize" className="col-form-label">Font Size</label>
                                                                <div className="input-group mb-0">
                                                                    <input type="text" className="form-control" id="baseFontSize" 
                                                                    name="base_font_size" value={allFontSize} 
                                                                    placeholder="Enter font size" 
                                                                    aria-label="base font size" 
                                                                    aria-describedby="base-font-size-1" 
                                                                    onChange={e => setAllFontSize(e.target.value)} />
                                                                    <span className="input-group-text" id="base-font-size-1">pt</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#generalCollapseThree" aria-expanded="false" aria-controls="generalCollapseThree">
                                                        Background
                                                    </button>
                                                </h2>
                                                <div id="generalCollapseThree" className="accordion-collapse collapse" data-bs-parent="#generalAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="pdfBgImg" className="col-form-label fw-medium">Background Image</label>
                                                            {!tempBgImg ? (
                                                                <>
                                                                    <div className="choose-img">
                                                                        <span className="btn btn-outline-secondary btn-file d-inline-flex py-2 mt-2">
                                                                            Browse From Desktop
                                                                            <input
                                                                                type="file"
                                                                                accept="image/*"
                                                                                id="pdfBgImg"
                                                                                onChange={tempBGImgChange} // Handle the file selection
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    {tempBgImgError && (
                                                                        <div style={{ color: "red", marginTop: "8px" }}>
                                                                            <small>{tempBgImgError}</small>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="selected-img">
                                                                        <img
                                                                            src={tempBgImg.fileimage}
                                                                            alt="pdf-bg-img"
                                                                            style={{ height: "212px", width: "162px", border: "1px solid #dddddd" }}
                                                                        />
                                                                        <span className="d-block link-danger mt-2" role="button" onClick={removeTempImage}>
                                                                            Remove
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="pdfBgImgPos" className="col-form-label">Image Position</label>
                                                            <select className="form-select" id='pdfBgImgPos' 
                                                            name='selectPdfBgImgPos' value={selectedTempBgPos}
                                                            onChange={e => setSelectedTempBgPos(e.target.value)}>
                                                                <option value="top left">Top Left</option>
                                                                <option value="top center">Top Center</option>
                                                                <option value="top right">Top Right</option>
                                                                <option value="center left">Center Left</option>
                                                                <option value="center center">Center Center</option>
                                                                <option value="center right">Center Right</option>
                                                                <option value="bottom left">Bottom Left</option>
                                                                <option value="bottom center">Bottom Center</option>
                                                                <option value="bottom right">Bottom Right</option>
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="background_color" className="col-form-label fw-medium">Background Color</label>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="checkbox" 
                                                                        className="form-check-input"
                                                                        id="temp_bgColorCheckbox"
                                                                        checked={isTempBgColorEnabled}
                                                                        onChange={(e) => tempBgColorCheckbox(e, setIsTempBgColorEnabled)}
                                                                    />
                                                                </div>
                                                                <ColorPicker
                                                                    id="background_color"
                                                                    color={tempBgColor}
                                                                    onColorChange={setTempBgColor} 
                                                                    disabled={!isTempBgColorEnabled}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-2" role="tabpanel" 
                                    aria-labelledby="v-pills-2-tab" tabIndex="0">
                                        <div className="accordion accordion-flush bg-body-tertiary pb-2" id="hAfAccordion">
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#hAfCollapseOne" aria-expanded="true" aria-controls="hAfCollapseOne">
                                                        Header
                                                    </button>
                                                </h2>
                                                <div id="hAfCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#hAfAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="headerBgImg" className="col-form-label fw-medium">Background Image</label>
                                                            {!headerBgImg ? (
                                                                <>
                                                                    <div className="choose-img">
                                                                        <span className="btn btn-outline-secondary btn-file d-inline-flex py-2 mt-2">
                                                                            Browse From Desktop
                                                                            <input
                                                                                type="file"
                                                                                accept="image/*"
                                                                                id="headerBgImg"
                                                                                onChange={headerBGImgChange} 
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    {headerBgImgError && (
                                                                        <div style={{ color: "red", marginTop: "8px" }}>
                                                                            <small>{headerBgImgError}</small>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="selected-img d-flex justify-content-between">
                                                                        <img
                                                                            src={headerBgImg.fileimage}
                                                                            alt="header-bg-img"
                                                                            style={{ height: "31px", width: "232px", border: "1px solid #dddddd" }}
                                                                        />
                                                                        <button type="button" onClick={removeHeaderImage} 
                                                                        className="btn btn-outline-light text-danger px-2 py-1"
                                                                        style={{ textDecoration: "none", color: "red" }}>
                                                                            <Trash size={16} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="headerBgImgPos" className="col-form-label">Image Position</label>
                                                            <select className="form-select" id='headerBgImgPos' 
                                                            name='selectHeaderBgImgPos' value={selectedHeaderBgPos}
                                                            onChange={e => setSelectedHeaderBgPos(e.target.value)}>
                                                                <option value="top left">Top Left</option>
                                                                <option value="top center">Top Center</option>
                                                                <option value="top right">Top Right</option>
                                                                <option value="center left">Center Left</option>
                                                                <option value="center center">Center Center</option>
                                                                <option value="center right">Center Right</option>
                                                                <option value="bottom left">Bottom Left</option>
                                                                <option value="bottom center">Bottom Center</option>
                                                                <option value="bottom right">Bottom Right</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="h_bg_color" className="col-form-label fw-medium">Background Color</label>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="checkbox" 
                                                                        className="form-check-input"
                                                                        id="header_bgColorCheckbox"
                                                                        checked={isHeaderBgColorEnabled}
                                                                        onChange={(e) => headerBgColorCheckbox(e, setIsHeaderBgColorEnabled)}
                                                                    />
                                                                </div>
                                                                <ColorPicker
                                                                    id="h_bg_color"
                                                                    color={headerBgColor}
                                                                    onColorChange={setHeaderBgColor} 
                                                                    disabled={!isHeaderBgColorEnabled}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <button type="button" className="btn btn-outline-secondary rounded-2 w-100" 
                                                            onClick={()=> handleHaFmodalShow('Customize your header content')}>
                                                                <Settings size={16} /> <span className="ps-2">Customize your header content</span>
                                                            </button>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-check">
                                                                <input className="form-check-input cursor-pointer" type="checkbox" id="hFPcheckBox" 
                                                                checked={isHfPsettingEnabled}
                                                                onChange={headerFpSettingEnable}/>
                                                                <label className="form-check-label cursor-pointer" htmlFor="hFPcheckBox">
                                                                    Apply to first page only
                                                                </label>
                                                            </div>
                                                            <div className={`${isHfPsettingEnabled ? "d-block":"d-none"}`}>
                                                                <div className={`row row-cols-2 mb-3 align-items-center`}>
                                                                    <label htmlFor="hFpHeight" className="col-form-label">Header height for first page</label>
                                                                    <div className="col">
                                                                        <div className="input-group mb-0">
                                                                            <input type="text" className="form-control" id="hFpHeight"
                                                                                name="base_font_size" value={headerFpHeight}
                                                                                placeholder="Enter top margin"
                                                                                aria-label="fP Top margin"
                                                                                aria-describedby="fp-top-margin"
                                                                                onChange={e => setHeaderFpHeight(e.target.value)} />
                                                                            <span className="input-group-text" id="fp-top-margin">in</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <button type="button" className="btn btn-outline-secondary rounded-2 w-100 px-1" 
                                                                    onClick={()=> handleHaFmodalShow('Customize your header content')}>
                                                                        <Settings size={14} /> <span className="ps-1 small">Customize your header content for other pages</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#hAfCollapseTwo" aria-expanded="true" aria-controls="hAfCollapseTwo">
                                                        Footer
                                                    </button>
                                                </h2>
                                                <div id="hAfCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#hAfAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3 row row-cols-2 justify-content-between">
                                                            <label htmlFor="footFontSize" className="col-auto col-form-label">Font Size</label>
                                                            <div className="col-7">
                                                                <div className="input-group mb-0">
                                                                    <input type="text" className="form-control" id="footFontSize"
                                                                        name="base_font_size" value={footFontSize}
                                                                        placeholder="Enter font size"
                                                                        aria-label="base font size"
                                                                        aria-describedby="foot-fs-1"
                                                                        onChange={e => setFootFontSize(e.target.value)} />
                                                                    <span className="input-group-text" id="foot-fs-1">pt</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row row-cols-2 justify-content-between">
                                                            <label htmlFor="foot_font_color" className="col-auto col-form-label">Font Color</label>
                                                            <div className="col-7">
                                                                <ColorPicker
                                                                    id="foot_font_color"
                                                                    color={footFontColor}
                                                                    onColorChange={setFootFontColor}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="footBgImg" className="col-form-label fw-medium">Background Image</label>
                                                            {!footBgImg ? (
                                                                <>
                                                                    <div className="choose-img">
                                                                        <span className="btn btn-outline-secondary btn-file d-inline-flex py-2 mt-2">
                                                                            Browse From Desktop
                                                                            <input
                                                                                type="file"
                                                                                accept="image/*"
                                                                                id="footBgImg"
                                                                                onChange={footBGImgChange} 
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    {footBgImgError && (
                                                                        <div style={{ color: "red", marginTop: "8px" }}>
                                                                            <small>{footBgImgError}</small>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="selected-img d-flex justify-content-between">
                                                                        <img
                                                                            src={footBgImg.fileimage}
                                                                            alt="foot-bg-img"
                                                                            style={{ height: "31px", width: "232px", border: "1px solid #dddddd" }}
                                                                        />
                                                                        <button type="button" onClick={removeFootImage} 
                                                                        className="btn btn-outline-light text-danger px-2 py-1"
                                                                        style={{ textDecoration: "none", color: "red" }}>
                                                                            <Trash size={16} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="footBgImgPos" className="col-form-label">Image Position</label>
                                                            <select className="form-select" id='footBgImgPos' 
                                                            name='selectFootBgImgPos' value={selectedFootBgPos}
                                                            onChange={e => setSelectedFootBgPos(e.target.value)}>
                                                                <option value="top left">Top Left</option>
                                                                <option value="top center">Top Center</option>
                                                                <option value="top right">Top Right</option>
                                                                <option value="center left">Center Left</option>
                                                                <option value="center center">Center Center</option>
                                                                <option value="center right">Center Right</option>
                                                                <option value="bottom left">Bottom Left</option>
                                                                <option value="bottom center">Bottom Center</option>
                                                                <option value="bottom right">Bottom Right</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="f_bg_color" className="col-form-label fw-medium">Background Color</label>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        type="checkbox" 
                                                                        className="form-check-input"
                                                                        id="foot_bgColorCheckbox"
                                                                        checked={isFootBgColorEnabled}
                                                                        onChange={(e) => footBgColorCheckbox(e, setIsFootBgColorEnabled)}
                                                                    />
                                                                </div>
                                                                <ColorPicker
                                                                    id="f_bg_color"
                                                                    color={footBgColor}
                                                                    onColorChange={setFootBgColor} 
                                                                    disabled={!isFootBgColorEnabled}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input cursor-pointer" id="footPageNumCheck"/>
                                                                <label className="form-check-label cursor-pointer" htmlFor="footPageNumCheck">Show Page Number</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <button type="button" className="btn btn-outline-secondary rounded-2 w-100" 
                                                            onClick={()=> handleHaFmodalShow('Customize your footer content')}>
                                                                <Settings size={16} /> <span className="ps-2">Customize your footer content</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-3" role="tabpanel" 
                                    aria-labelledby="v-pills-3-tab" tabIndex="0">
                                        <div className="accordion accordion-flush bg-body-tertiary pb-2" id="tdAccordion">
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#tdCollapseOne" aria-expanded="true" aria-controls="tdCollapseOne">
                                                        Organization Details
                                                    </button>
                                                </h2>
                                                <div id="tdCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#tdAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input cursor-pointer" id="orgLogoShowCheck" 
                                                                checked={isOrgImgCheckEnabled}
                                                                onChange={tDOrgImgSettingEnable} />
                                                                <label className="form-check-label cursor-pointer" htmlFor="orgLogoShowCheck">Show Organization Logo</label>
                                                            </div>
                                                        </div>
                                                        <div className={`${isOrgImgCheckEnabled ? "d-block mb-3":"d-none"}`}>
                                                            {!orgImg ? (
                                                                <>
                                                                    <div className="choose-img">
                                                                        <span className="file-upload-box file-upload-small mt-2">
                                                                            <span className="hstack opacity-75">
                                                                                <TbUpload size={16} /> 
                                                                                <span className="ps-2 text-center fs-13">Upload your logo file</span>
                                                                            </span>
                                                                            <input
                                                                                type="file"
                                                                                accept="image/gif,image/jpeg,image/png,image/bmp"
                                                                                id="orgImg" 
                                                                                className="file-upload-input"
                                                                                onChange={orgImgChange}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    {orgImgError && (
                                                                        <div style={{ color: "red", marginTop: "8px" }}>
                                                                            <small>{orgImgError}</small>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="org-img-preview rounded-2 p-2" style={{maxHeight: "86px", border: "1px dashed #dddddd"}}>
                                                                        <img
                                                                            src={orgImg.fileimage}
                                                                            alt="org-img" 
                                                                            className="img-fluid"
                                                                            style={{ maxHeight: "70px" }}
                                                                        />
                                                                    </div>
                                                                    <div className="text-muted text-opacity-75">
                                                                        <TbInfoCircleFilled />
                                                                        <span className="fst-italic small">You can change the logo in Organization Profile.</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input cursor-pointer" id="orgNameShowCheck" 
                                                                checked={isOrgNameCheckEnabled}
                                                                onChange={tDOrgNameSettingEnable} />
                                                                <label className="form-check-label cursor-pointer" htmlFor="orgNameShowCheck">Show Organization Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="row row-cols-2 mb-3">
                                                            <div className="col">
                                                                <label htmlFor="orgName_f_color" className="col-form-label">Font Color</label>
                                                                <ColorPicker
                                                                    id="orgName_f_color"
                                                                    color={orgNameFontColor}
                                                                    onColorChange={setOrgNameFontColor}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label htmlFor="orgNameFSize" className="col-form-label">Font Size</label>
                                                                <div className="input-group mb-0">
                                                                    <input type="text" className="form-control" id="orgNameFSize" 
                                                                    name="orgName_font_size" value={orgNameFontSize} 
                                                                    placeholder="Enter font size" 
                                                                    aria-label="org name font size" 
                                                                    aria-describedby="orgName-font-size-1" 
                                                                    onChange={e => setOrgNameFontSize(e.target.value)} />
                                                                    <span className="input-group-text" id="orgName-font-size-1">pt</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input cursor-pointer" id="orgAddressCheck"
                                                                    checked={isOrgAddressCheckEnabled}
                                                                    onChange={tDOrgAddressSettingEnable} />
                                                                <label className="form-check-label cursor-pointer" htmlFor="orgAddressCheck">Show Organization Address</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-0">
                                                            <button type="button" className="btn btn-outline-secondary rounded-2 w-100" 
                                                            onClick={()=> orgAddressModalShow()}>
                                                                <Settings size={16} /> <span className="ps-2 small">Organization Address Format (Custom)</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#tdCollapseTwo" aria-expanded="false" aria-controls="tdCollapseTwo">
                                                        Customer Details
                                                    </button>
                                                </h2>
                                                <div id="tdCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#tdAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <div className="fw-semibold mb-2">Customer Name</div>
                                                            <div className="row row-cols-2 mb-3">
                                                                <div className="col">
                                                                    <label htmlFor="cusName_f_color" className="col-form-label">Font Color</label>
                                                                    <ColorPicker
                                                                        id="cusName_f_color"
                                                                        color={cusNameFontColor}
                                                                        onColorChange={setCusNameFontColor}
                                                                    />
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="cusNameFSize" className="col-form-label">Font Size</label>
                                                                    <div className="input-group mb-0">
                                                                        <input type="text" className="form-control" id="cusNameFSize"
                                                                            name="cusName_f_size" value={cusNameFontSize}
                                                                            placeholder="Enter font size"
                                                                            aria-label="cus name font size"
                                                                            aria-describedby="cusName-font-size-1"
                                                                            onChange={e => setCusNameFontSize(e.target.value)} />
                                                                        <span className="input-group-text" id="cusName-font-size-1">pt</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="form-check">
                                                                <input type="checkbox" 
                                                                className="form-check-input cursor-pointer" 
                                                                id="billToCheck"
                                                                checked={isBillToEnabled}
                                                                onChange={(e) => billToCheckbox(e, setIsBillToEnabled)}/>
                                                                <label className="form-check-label cursor-pointer" htmlFor="billToCheck">Bill To</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input type="text" className="form-control" id="billToInput"
                                                            name="billTo_name" 
                                                            placeholder="Enter bill header" 
                                                            value={billToLabelText} 
                                                            onChange={(e) => handleBillToLabelChange(e, setBillToLabelText)}
                                                            disabled={!isBillToEnabled} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Button variant="link" className="p-0 text-decoration-none"
                                                            onClick={()=> billOrShipAddressModalShow('Billing')} 
                                                            disabled={!isBillToEnabled}>
                                                                <small>Billing Address Format</small>
                                                            </Button>
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="form-check">
                                                                <input type="checkbox" 
                                                                className="form-check-input cursor-pointer" 
                                                                id="shipToCheck"
                                                                checked={isShipToEnabled}
                                                                onChange={(e) => shipToCheckbox(e, setIsShipToEnabled)}/>
                                                                <label className="form-check-label cursor-pointer" htmlFor="shipToCheck">Ship To</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input type="text" className="form-control" id="shipToInput"
                                                            name="shipTo_name" 
                                                            placeholder="Enter ship header" 
                                                            value={shipToLabelText} 
                                                            onChange={(e) => handleShipToLabelChange(e, setShipToLabelText)}
                                                            disabled={!isShipToEnabled} />
                                                        </div>
                                                        <div className="mb-0">
                                                            <Button variant="link" className="p-0 text-decoration-none"
                                                            onClick={()=> billOrShipAddressModalShow('Shipping')}  
                                                            disabled={!isShipToEnabled}>
                                                                <small>Shipping Address Format</small>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#tdCollapseThree" aria-expanded="false" aria-controls="tdCollapseThree">
                                                        Document Details
                                                    </button>
                                                </h2>
                                                <div id="tdCollapseThree" className="accordion-collapse collapse" data-bs-parent="#tdAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-2">
                                                            <div className="form-check">
                                                                <input type="checkbox"
                                                                    className="form-check-input cursor-pointer"
                                                                    id="docTitleCheck"
                                                                    checked={isDocTitleEnabled}
                                                                    onChange={(e) => docTitleCheckbox(e, setIsDocTitleEnabled)} />
                                                                <label className="form-check-label cursor-pointer" htmlFor="docTitleCheck">Show Document Title</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input type="text" className="form-control" id="docTitleInput"
                                                                name="docTitle_name"
                                                                placeholder="Enter document title"
                                                                value={docTitleLabelText}
                                                                onChange={(e) => handleDocTitleLabelChange(e, setDocTitleLabelText)}
                                                                disabled={!isDocTitleEnabled} />
                                                        </div>
                                                        <div className="fw-semibold mt-3">Document Title</div>
                                                        <div className="row row-cols-2 mb-3">
                                                            <div className="col">
                                                                <label htmlFor="docTitle_f_color" className="col-form-label">Font Color</label>
                                                                <ColorPicker
                                                                    id="docTitle_f_color"
                                                                    color={docTitleFontColor}
                                                                    onColorChange={setDocTitleFontColor}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label htmlFor="docTitleFSize" className="col-form-label">Font Size</label>
                                                                <div className="input-group mb-0">
                                                                    <input type="text" className="form-control" id="docTitleFSize"
                                                                        name="docTitle_f_size" value={docTitleFontSize}
                                                                        placeholder="Enter font size"
                                                                        aria-label="doc title font size"
                                                                        aria-describedby="docTitle-font-size-1"
                                                                        onChange={e => setDocTitleFontSize(e.target.value)} />
                                                                    <span className="input-group-text" id="docTitle-font-size-1">pt</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row row-cols-2 mb-3">
                                                            <div className="col">
                                                                <label htmlFor="docPhoneLabel" className="col-form-label">Phone</label>
                                                                <input type="text" className="form-control" id="docPhoneLabel"
                                                                name="docPhone_label"
                                                                placeholder="Enter phone label"
                                                                defaultValue="Phone" />
                                                            </div>
                                                            <div className="col">
                                                                <label htmlFor="docFaxLabel" className="col-form-label">Fax Number</label>
                                                                <input type="text" className="form-control" id="docFaxLabel"
                                                                name="docFax_label"
                                                                placeholder="Enter fax label"
                                                                defaultValue="Fax" />
                                                            </div>
                                                        </div>
                                                        <div className="fw-semibold pt-3 mb-3">Document Information</div>
                                                        {checkboxes.map((checkbox) => (
                                                            <SingleTextCheckbox
                                                                key={checkbox.id}
                                                                checkboxId={checkbox.id}
                                                                isCheckboxDisabled={checkbox.id === 'subjectCheckbox'}
                                                                checkboxLabel={checkbox.label}
                                                                isCheckboxEnabled={checkbox.isChecked}
                                                                inputId={`${checkbox.id}Input`}
                                                                inputValue={checkbox.value}
                                                                onValueChange={(value) => handleValueChange(checkbox.id, value)}
                                                                disabled={false}
                                                                onCheckboxChange={(checked) => handleCheckboxChange(checkbox.id, checked)}
                                                            />
                                                        ))}
                                                        <div className="mb-0">
                                                            <button type="button" className="btn btn-outline-secondary rounded-2 w-100" 
                                                            onClick={()=> handleAddAttentionContModalShow()}>
                                                                <Settings size={16} /> <span className="ps-2">Add Attention Content</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-4" role="tabpanel" 
                                    aria-labelledby="v-pills-4-tab" tabIndex="0">
                                        <div className="accordion accordion-flush bg-body-tertiary pb-2" id="tAccordion">
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#tCollapseOne" aria-expanded="true" aria-controls="tCollapseOne">
                                                        Labels Section
                                                    </button>
                                                </h2>
                                                <div id="tCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#tAccordion">
                                                    <div className="accordion-body">
                                                        <div className="fs-6 pb-3">Table Properties</div>
                                                        <div className="row align-items-center py-1 border-top border-bottom mb-3">
                                                            <div className="col-lg-4">
                                                                <small className="fw-medium text-uppercase text-secondary text-opacity-75">Field</small>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <small className="fw-medium text-uppercase text-secondary text-opacity-75">Width(%)</small>
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <small className="fw-medium text-uppercase text-secondary text-opacity-75">Label</small>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="lineItemNumCheck"
                                                                        checked={isLineItemNumCheckEnabled}
                                                                        onChange={(e) => lineItemNumCheckbox(e, setIsLineItemNumCheckEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="lineItemNumCheck">Line Item Number</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="lineNumWidthInput"
                                                                name="lineNumWidth_name"
                                                                placeholder="Enter width"
                                                                value={lineNumWidth}
                                                                onChange={(e) => handleLineNumWidthChange(e, setLineNumWidth)}
                                                                disabled={!isLineItemNumCheckEnabled} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="lineNumLabelInput"
                                                                name="lineNumLabel_name"
                                                                placeholder="Enter Label"
                                                                value={lineNumLabelText}
                                                                onChange={(e) => handleLineNumLabelChange(e, setLineNumLabelText)}
                                                                disabled={!isLineItemNumCheckEnabled} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="itemCheck"
                                                                        checked={isItemEnabled}
                                                                        onChange={(e) => itemCheckbox(e, setIsItemEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="itemCheck">Item</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="itemWidthInput"
                                                                name="itemWidth_name"
                                                                placeholder="Enter width"
                                                                value={itemWidth}
                                                                onChange={(e) => handleItemWidthChange(e, setItemWidth)}
                                                                disabled={!isItemEnabled} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="itemLabelInput"
                                                                name="itemLabel_name"
                                                                placeholder="Enter Label"
                                                                value={itemLabelText}
                                                                onChange={(e) => handleItemLabelChange(e, setItemLabelText)}
                                                                disabled={!isItemEnabled} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-7">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="descLabelCheck"
                                                                        checked={isDescLabelEnabled}
                                                                        onChange={(e) => descLabelCheckbox(e, setIsDescLabelEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="descLabelCheck">Description</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="descLabelInput"
                                                                name="descLabel_name"
                                                                placeholder="Enter Label"
                                                                value={descLabelText}
                                                                onChange={(e) => handleDescLabelChange(e, setDescLabelText)}
                                                                disabled={!isDescLabelEnabled} />
                                                            </div>
                                                        </div>
                                                        <div className="mb-4">
                                                            <button type="button" className="btn btn-outline-secondary rounded-2 w-100" 
                                                            onClick={()=> handleCusNameAndDescModalShow()}>
                                                                <Settings size={16} /> <span className="small ps-2">Customize Item Name & Description</span>
                                                            </button>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="cusTaFieldCheck"
                                                                        checked={true} 
                                                                        disabled={true} />
                                                                    <label className="form-check-label cursor-pointer opacity-100" htmlFor="cusTaFieldCheck">
                                                                        <TooltipTrig id="cusTaFieldInfo"
                                                                            title='The width entered shall be applicable for all the custom fields created with "Show in all PDF" option.'>
                                                                            <span className="text-body text-dashed-underline">
                                                                                Custom Fields
                                                                            </span>
                                                                        </TooltipTrig>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="cusTaFieldWidthInput"
                                                                name="cusTaFieldWidth_name"
                                                                placeholder="Enter width"
                                                                defaultValue={11} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="cusTaFieldLabelInput"
                                                                name="cusTaFieldLabel_name"
                                                                placeholder="Enter Label"
                                                                defaultValue="%Custom Field Label%" 
                                                                disabled={true} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-start mb-3">
                                                            <div className="col-lg-4 col-form-label">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="taQtyCheck"
                                                                        checked={isTaQtyEnabled}
                                                                        onChange={(e) => taQtyCheckbox(e, setIsTaQtyEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="taQtyCheck">Quantity</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="taQtyWidthInput"
                                                                name="taQtyWidth_name"
                                                                placeholder="Enter width"
                                                                value={taQtyWidth}
                                                                onChange={(e) => handleTaQtyWidthChange(e, setTaQtyWidth)}
                                                                disabled={!isTaQtyEnabled} />
                                                            </div>                                                            
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="taQtyLabelInput"
                                                                name="taQtyLabel_name"
                                                                placeholder="Enter Label"
                                                                value={taQtyLabelText}
                                                                onChange={(e) => handleTaQtyLabelChange(e, setTaQtyLabelText)}
                                                                disabled={!isTaQtyEnabled} />
                                                                {!isTaQtyEnabled ? (''):(
                                                                <div className="col-auto pt-2">
                                                                    <div className="form-check">
                                                                        <input type="checkbox"
                                                                            className="form-check-input cursor-pointer"
                                                                            id="taQtyUnitCheck"
                                                                            checked={isTaQtyUnitEnabled}
                                                                            onChange={(e) => taQtyUnitCheckbox(e, setIsTaQtyUnitEnabled)} />
                                                                        <label className="form-check-label cursor-pointer" htmlFor="taQtyUnitCheck">Show Unit</label>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="taRateCheck"
                                                                        checked={isTaRateEnabled}
                                                                        onChange={(e) => taRateCheckbox(e, setIsTaRateEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="taRateCheck">Rate</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="taRateWidthInput"
                                                                name="taRateWidth_name"
                                                                placeholder="Enter width"
                                                                value={taRateWidth}
                                                                onChange={(e) => handleTaRateWidthChange(e, setTaRateWidth)}
                                                                disabled={!isTaRateEnabled} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="taRateLabelInput"
                                                                name="taRateLabel_name"
                                                                placeholder="Enter Label"
                                                                value={taRateLabelText}
                                                                onChange={(e) => handleTaRateLabelChange(e, setTaRateLabelText)}
                                                                disabled={!isTaRateEnabled} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="taTaxPerCheck"
                                                                        checked={isTaTaxPerEnabled}
                                                                        onChange={(e) => taTaxPerCheckbox(e, setIsTaTaxPerEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="taTaxPerCheck">Tax (%)</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="taTaxPerWidthInput"
                                                                name="taTaxPerWidth_name"
                                                                placeholder="Enter width"
                                                                value={taTaxPerWidth}
                                                                onChange={(e) => handleTaTaxPerWidthChange(e, setTaTaxPerWidth)}
                                                                disabled={!isTaTaxPerEnabled} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="taTaxPerLabelInput"
                                                                name="taTaxPerLabel_name"
                                                                placeholder="Enter Label"
                                                                value={taTaxPerLabelText}
                                                                onChange={(e) => handleTaTaxPerLabelChange(e, setTaTaxPerLabelText)}
                                                                disabled={!isTaTaxPerEnabled} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="taTaxAmtCheck"
                                                                        checked={isTaTaxAmtEnabled}
                                                                        onChange={(e) => taTaxAmtCheckbox(e, setIsTaTaxAmtEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="taTaxAmtCheck">Tax Amount</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="taTaxAmtWidthInput"
                                                                name="taTaxAmtWidth_name"
                                                                placeholder="Enter width"
                                                                value={taTaxAmtWidth}
                                                                onChange={(e) => handleTaTaxAmtWidthChange(e, setTaTaxAmtWidth)}
                                                                disabled={!isTaTaxAmtEnabled} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="taTaxAmtLabelInput"
                                                                name="taTaxAmtLabel_name"
                                                                placeholder="Enter Label"
                                                                value={taTaxAmtLabelText}
                                                                onChange={(e) => handleTaTaxAmtLabelChange(e, setTaTaxAmtLabelText)}
                                                                disabled={!isTaTaxAmtEnabled} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="taDisCheck"
                                                                        checked={isTaDisEnabled}
                                                                        onChange={(e) => taDisCheckbox(e, setIsTaDisEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="taDisCheck">Discount</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="taDisWidthInput"
                                                                name="taDisWidth_name"
                                                                placeholder="Enter width"
                                                                value={taDisWidth}
                                                                onChange={(e) => handleTaDisWidthChange(e, setTaDisWidth)}
                                                                disabled={!isTaDisEnabled} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="taDisLabelInput"
                                                                name="taDisLabel_name"
                                                                placeholder="Enter Label"
                                                                value={taDisLabelText}
                                                                onChange={(e) => handleTaDisLabelChange(e, setTaDisLabelText)}
                                                                disabled={!isTaDisEnabled} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-lg-4">
                                                                <div className="form-check">
                                                                    <input type="checkbox"
                                                                        className="form-check-input cursor-pointer"
                                                                        id="taAmtCheck"
                                                                        checked={isTaAmtEnabled}
                                                                        onChange={(e) => taAmtCheckbox(e, setIsTaAmtEnabled)} />
                                                                    <label className="form-check-label cursor-pointer" htmlFor="taAmtCheck">Amount</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 px-0">
                                                                <input type="number" className="form-control" id="taAmtWidthInput"
                                                                name="taAmtWidth_name"
                                                                placeholder="Enter width"
                                                                value={taAmtWidth}
                                                                onChange={(e) => handleTaAmtWidthChange(e, setTaAmtWidth)}
                                                                disabled={!isTaAmtEnabled} />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" id="taAmtLabelInput"
                                                                name="taAmtLabel_name"
                                                                placeholder="Enter Label"
                                                                value={taAmtLabelText}
                                                                onChange={(e) => handleTaAmtLabelChange(e, setTaAmtLabelText)}
                                                                disabled={!isTaAmtEnabled} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#tCollapseTwo" aria-expanded="false" aria-controls="tCollapseTwo">
                                                        Layout Section
                                                    </button>
                                                </h2>
                                                <div id="tCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#tAccordion">
                                                    <div className="accordion-body px-3">
                                                        <div className="fs-6 pb-3 border-bottom">Table Properties</div>
                                                        <div className="pt-3">
                                                            <FieldColorCheckbox
                                                                labelText="Table Border"
                                                                checkboxId="tempTableBorder"
                                                                isCheckboxEnabled={isTableBorderEnabled}
                                                                inputColorId="temp_border_color"
                                                                inputColorValue={tempBorderColor}
                                                                onValueChange={setTempBorderColor}
                                                                disabled={false}
                                                                onCheckboxChange={handleTempBorderCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="fw-semibold mt-3 mb-2 fs-6">Table Header</div>
                                                        <div className="mb-3">
                                                            <FieldTextSize
                                                                labelText="Font Size"
                                                                id="ta_head_font_size"
                                                                size={taHeadFontSize}
                                                                onValueChange={setTaHeadFontSize}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Background Color"
                                                                haveCheck={true}
                                                                checkboxId="taHeadBgColor"
                                                                isCheckboxEnabled={isTaHeadBgColorEnabled}
                                                                inputColorId="ta_Head_bg_color"
                                                                inputColorValue={taHeadBgColor}
                                                                onValueChange={setTaHeadBgColor}
                                                                disabled={false}
                                                                onCheckboxChange={handleTaHeadBgColorCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Font Color"
                                                                haveCheck={false}
                                                                isCheckboxEnabled={true}
                                                                inputColorId="ta_Head_font_color"
                                                                inputColorValue={taHeadFontColor}
                                                                onValueChange={setTaHeadFontColor}
                                                            />
                                                        </div>
                                                        <div className="fw-semibold mt-3 mb-2 fs-6">Item Row</div>
                                                        <div className="mb-3">
                                                            <FieldTextSize
                                                                labelText="Font Size"
                                                                id="ta_body_font_size"
                                                                size={taBodyFontSize}
                                                                onValueChange={setTaBodyFontSize}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Background Color"
                                                                haveCheck={true}
                                                                checkboxId="taBodyBgColor"
                                                                isCheckboxEnabled={isTaBodyBgColorEnabled}
                                                                inputColorId="ta_body_bg_color"
                                                                inputColorValue={taBodyBgColor}
                                                                onValueChange={setTaBodyBgColor}
                                                                disabled={false}
                                                                onCheckboxChange={handleTaBodyBgColorCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Font Color"
                                                                haveCheck={false}
                                                                isCheckboxEnabled={true}
                                                                inputColorId="ta_Body_font_color"
                                                                inputColorValue={taBodyFontColor}
                                                                onValueChange={setTaBodyFontColor}
                                                            />
                                                        </div>
                                                        <div className="fw-semibold mt-3 mb-2 fs-6">Item Description</div>
                                                        <div className="mb-3">
                                                            <FieldTextSize
                                                                labelText="Font Size"
                                                                id="ta_body_desc_size"
                                                                size={taBodyDescSize}
                                                                onValueChange={setTaBodyDescSize}
                                                            />
                                                        </div>
                                                        <div className="mb-0">
                                                            <FieldColorCheckbox
                                                                labelText="Font Color"
                                                                haveCheck={false}
                                                                isCheckboxEnabled={true}
                                                                inputColorId="ta_Body_desc_color"
                                                                inputColorValue={taBodyDescColor}
                                                                onValueChange={setTaBodyDescColor}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-5" role="tabpanel" 
                                    aria-labelledby="v-pills-5-tab" tabIndex="0">
                                        <div className="accordion accordion-flush bg-body-tertiary pb-2" id="toAccordion">
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#toCollapseOne" aria-expanded="true" aria-controls="toCollapseOne">
                                                        Labels Section
                                                    </button>
                                                </h2>
                                                <div id="toCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#toAccordion">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input cursor-pointer" id="allTotalSetCheck"
                                                                checked={isAllTotalSetEnabled}
                                                                onChange={allTotalSetChange} />
                                                                <label className="form-check-label cursor-pointer" htmlFor="allTotalSetCheck">Show Total Section</label>
                                                            </div>
                                                        </div>
                                                        {isAllTotalSetEnabled ? (
                                                            <>
                                                                <SingleTextCheckbox
                                                                    checkboxId="subTotalCheckbox"
                                                                    isCheckboxDisabled={false}
                                                                    checkboxLabel="Sub Total"
                                                                    isCheckboxEnabled={isSubTotalEnabled}
                                                                    inputId="subTotalCheckboxInput"
                                                                    inputValue={subTotalText}
                                                                    onValueChange={setSubTotalText}
                                                                    disabled={false}
                                                                    onCheckboxChange={handleSubTotalCheckboxChange}
                                                                />
                                                                <SingleTextCheckbox
                                                                    checkboxId="shipChargeCheckbox"
                                                                    isCheckboxDisabled={true}
                                                                    checkboxLabel="Shipping Charges"
                                                                    isCheckboxEnabled={isShipChargeEnabled}
                                                                    inputId="shipChargeCheckboxInput"
                                                                    inputValue={shipChargeText}
                                                                    onValueChange={setShipChargeText}
                                                                    disabled={false}
                                                                    onCheckboxChange={handleShipChargeCheckboxChange}
                                                                />
                                                                <div className="mb-3">
                                                                    <div className="form-check">
                                                                        <input type="checkbox" className="form-check-input cursor-pointer" id="discountSecCheck"
                                                                            checked={isDiscountSecEnabled}
                                                                            onChange={discountSecChange} />
                                                                        <label className="form-check-label cursor-pointer" htmlFor="discountSecCheck">
                                                                            Discount 
                                                                            <TooltipTrig id="discountSecInfo"
                                                                            title="This will display the discount applied on a transaction level.">
                                                                                <span className="ps-1 text-gray">
                                                                                    <TbInfoCircleFilled size={16} />
                                                                                </span>
                                                                            </TooltipTrig>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 row row-cols-2 justify-content-between">
                                                                    <div className="col-6">
                                                                        <div className="form-check col-form-label">
                                                                            <input type="checkbox" className="form-check-input cursor-pointer"
                                                                                id="toTaxAmtCheck"
                                                                                checked={isToTaxAmtEnabled}
                                                                                onChange={(e) => toTaxAmtCheckbox(e, setIsToTaxAmtEnabled)} />
                                                                            <label className="form-check-label cursor-pointer" htmlFor="toTaxAmtCheck">
                                                                                Total Taxable Amount 
                                                                                <TooltipTrig id="toTaxAmtInfo"
                                                                                title="This field will not be shown in the Quote PDF if you've not associated tax for any items in the Quote.">
                                                                                <span className="ps-1 text-gray">
                                                                                    <TbInfoCircleFilled size={16} />
                                                                                </span>
                                                                            </TooltipTrig>
                                                                            </label>
                                                                        </div>
                                                                    </div>                                                                
                                                                    <div className="col-6">
                                                                        <input type="text" className="form-control" 
                                                                            id="toTaxAmtInput"
                                                                            name="toTaxAmt_name"
                                                                            value={toTaxAmtLabelText}
                                                                            onChange={(e) => handleToTaxAmtLabelChange(e, setToTaxAmtLabelText)}
                                                                            placeholder={`Enter ${toTaxAmtLabelText} label text`}
                                                                            disabled={!isToTaxAmtEnabled} />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="form-check">
                                                                        <input type="checkbox" className="form-check-input cursor-pointer" 
                                                                        id="showTaxDetailCheck"
                                                                        checked={isShowTaxDetailEnabled}
                                                                        onChange={showTaxDetailCheckbox} />
                                                                        <label className="form-check-label cursor-pointer" htmlFor="showTaxDetailCheck">
                                                                            Show Tax Details
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <SingleTextCheckbox
                                                                    checkboxId="mainTotalCheckbox"
                                                                    isCheckboxDisabled={true}
                                                                    checkboxLabel="Total"
                                                                    isCheckboxEnabled={isMainTotalEnabled}
                                                                    inputId="mainTotalCheckboxInput"
                                                                    inputValue={mainTotalText}
                                                                    onValueChange={setMainTotalText}
                                                                    disabled={false}
                                                                    onCheckboxChange={handleMainTotalCheckboxChange}
                                                                />
                                                                <div className="mb-3 row row-cols-2 justify-content-between">
                                                                    <div className="col-6">
                                                                        <div className="form-check col-form-label">
                                                                            <input type="checkbox" className="form-check-input"
                                                                                id="curSymbolCheck"
                                                                                checked={isCurSymbolEnabled}
                                                                                onChange={(e) => setIsCurSymbolEnabled(e.target.checked)}
                                                                                disabled={true} />
                                                                            <label className="form-check-label" htmlFor="curSymbolCheck">Currency Symbol</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <select className="form-select" id='curSymbolPos'
                                                                            name='selectCurSymbolPos' value={selectedCurSymbolPos}
                                                                            onChange={e => setSelectedCurSymbolPos(e.target.value)}>
                                                                            <option value="beforeAmt">Before Amount</option>
                                                                            <option value="afterAmt">After Amount</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <div className="form-check form-check-inline">
                                                                        <input type="checkbox" className="form-check-input cursor-pointer"
                                                                            id="totalQtySetCheck"
                                                                            checked={isTotalQtySetEnabled}
                                                                            onChange={totalQtySetChange} />
                                                                        <label className="form-check-label cursor-pointer" htmlFor="totalQtySetCheck">Quantity</label>
                                                                    </div>
                                                                </div>
                                                                {isTotalQtySetEnabled ? (
                                                                <div className="mb-0 row row-cols-2 justify-content-between">
                                                                    <label className="col-form-label col-auto" htmlFor="items_total_label">
                                                                        Items in Total
                                                                    </label>
                                                                    <div className="col-6">
                                                                        <input type="text" className="form-control"
                                                                            id="items_total_label"
                                                                            name="itemsTotal_name"
                                                                            value={itemsTotalLabelText}
                                                                            onChange={(e) => handleItemsTotalLabelChange(e, setItemsTotalLabelText)}
                                                                            placeholder={`Enter label text`} />
                                                                    </div>
                                                                </div>
                                                                ) : ('')}
                                                            </>
                                                        ):('')}
                                                        <hr />
                                                        <div className="mb-3">
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input cursor-pointer" 
                                                                id="amtShowWordsCheck"
                                                                checked={isAmtShowWordsEnabled}
                                                                onChange={amtShowWordsChange} />
                                                                <label className="form-check-label cursor-pointer" htmlFor="amtShowWordsCheck">
                                                                    Show amount in words 
                                                                    <TooltipTrig id="showAmountWordsInfo"
                                                                    title="The amount in words will be displayed only in the organization's language.">
                                                                        <span className="ps-1 text-gray">
                                                                            <TbInfoCircleFilled size={16} />
                                                                        </span>
                                                                    </TooltipTrig>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item mb-0">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#toCollapseTwo" aria-expanded="false" aria-controls="toCollapseTwo">
                                                        Layout Section
                                                    </button>
                                                </h2>
                                                <div id="toCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#toAccordion">
                                                    <div className="accordion-body">
                                                        <div className="fw-semibold mb-2 fs-6">Total (Subtotal, Tax)</div>
                                                        <div className="mb-3">
                                                            <FieldTextSize
                                                                labelText="Font Size"
                                                                id="total_sec_fsize"
                                                                size={totalSecFsize}
                                                                onValueChange={setTotalSecFsize}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Font Color"
                                                                haveCheck={false}
                                                                isCheckboxEnabled={true}
                                                                inputColorId="total_sec_Fcolor"
                                                                inputColorValue={totalSecFcolor}
                                                                onValueChange={setTotalSecFcolor}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Background Color"
                                                                haveCheck={true}
                                                                checkboxId="totalSecBgColorCheck"
                                                                isCheckboxEnabled={isTotalSecBgColorEnabled}
                                                                inputColorId="total_sec_bgColor"
                                                                inputColorValue={totalSecBgColor}
                                                                onValueChange={setTotalSecBgColor}
                                                                disabled={false}
                                                                onCheckboxChange={handleTotalSecBgColorCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="fw-semibold mt-3 mb-2 fs-6">Balance Due</div>
                                                        <div className="mb-3">
                                                            <FieldTextSize
                                                                labelText="Font Size"
                                                                id="balDue_sec_fsize"
                                                                size={balDueSecFsize}
                                                                onValueChange={setBalDueSecFsize}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Font Color"
                                                                haveCheck={false}
                                                                isCheckboxEnabled={true}
                                                                inputColorId="balDue_sec_Fcolor"
                                                                inputColorValue={balDueSecFcolor}
                                                                onValueChange={setBalDueSecFcolor}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldColorCheckbox
                                                                labelText="Background Color"
                                                                haveCheck={true}
                                                                checkboxId="balDueSecBgColorCheck"
                                                                isCheckboxEnabled={isBalDueSecBgColorEnabled}
                                                                inputColorId="balDue_sec_bgColor"
                                                                inputColorValue={balDueSecBgColor}
                                                                onValueChange={setBalDueSecBgColor}
                                                                disabled={false}
                                                                onCheckboxChange={handleBalDueSecBgColorCheckboxChange}
                                                            />
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-6" role="tabpanel" 
                                    aria-labelledby="v-pills-6-tab" tabIndex="0">
                                        <div className="accordion accordion-flush bg-body-tertiary pb-2" id="odAccordion">
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#odCollapseOne" aria-expanded="true" aria-controls="odCollapseOne">
                                                        Document Summary
                                                    </button>
                                                </h2>
                                                <div id="odCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#odAccordion">
                                                    <div className="accordion-body">
                                                        <div className="fw-semibold mt-0 mb-2 fs-6">Notes</div>
                                                        <div className="mb-3 row row-cols-2 justify-content-between">
                                                            <label className="col-form-label col-auto" htmlFor="notes_label">
                                                                Label
                                                            </label>
                                                            <div className="col-6">
                                                                <input type="text" className="form-control"
                                                                    id="notes_label"
                                                                    name="notesLabel_name"
                                                                    value={notesLabelText}
                                                                    onChange={(e) => handleNotesLabelChange(e, setNotesLabelText)}
                                                                    placeholder={`Enter label text`} />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <FieldTextSize
                                                                labelText="Font Size"
                                                                id="notes_fsize"
                                                                size={notesFsize}
                                                                onValueChange={setNotesFsize}
                                                            />
                                                        </div>
                                                        <div className="mb-4">
                                                            <button type="button" className="btn btn-outline-secondary rounded-2 w-100" 
                                                            onClick={()=> handleAddBankDetailsModalShow()}>
                                                                <Settings size={16} /> <span className="ps-2">Add your bank details</span>
                                                            </button>
                                                        </div>
                                                        <div className="mb-2 pt-2">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input cursor-pointer"
                                                                    id="termAndCondSetCheck"
                                                                    checked={isTermCondSetEnabled}
                                                                    onChange={termCondSetChange} />
                                                                <label className="form-check-label fw-semibold cursor-pointer" htmlFor="termAndCondSetCheck">Terms & Conditions</label>
                                                            </div>
                                                        </div>
                                                        {isTermCondSetEnabled ? (
                                                            <>
                                                            <div className="mb-3 row row-cols-2 justify-content-between">
                                                                <label className="col-form-label col-auto" htmlFor="term_cond_label">
                                                                    Label
                                                                </label>
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control"
                                                                        id="term_cond_label"
                                                                        name="termCond_name"
                                                                        value={termCondLabelText}
                                                                        onChange={(e) => handleTermCondLabelChange(e, setTermCondLabelText)}
                                                                        placeholder={`Enter label text`} />
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <FieldTextSize
                                                                    labelText="Font Size"
                                                                    id="termCond_fsize"
                                                                    size={termCondFsize}
                                                                    onValueChange={setTermCondFsize}
                                                                />
                                                            </div>
                                                            </>
                                                        ) : ('')}
                                                        <div className="mb-2 pt-2">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" className="form-check-input cursor-pointer"
                                                                    id="signSetCheck"
                                                                    checked={isSignSetEnabled}
                                                                    onChange={signSetChange} />
                                                                <label className="form-check-label fw-semibold cursor-pointer" htmlFor="signSetCheck">Signature</label>
                                                            </div>
                                                        </div>
                                                        {isSignSetEnabled ? (
                                                        <>
                                                        <div className="mb-3 row row-cols-2 justify-content-between">
                                                            <label className="col-form-label col-auto" htmlFor="sign_label">
                                                                Label
                                                            </label>
                                                            <div className="col-6">
                                                                <input type="text" className="form-control"
                                                                    id="sign_label"
                                                                    name="signLabel_name"
                                                                    value={signLabelText}
                                                                    onChange={(e) => handleSignLabelChange(e, setSignLabelText)}
                                                                    placeholder={`Enter label text`} />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="col-form-label mb-1" htmlFor="signImgChoose">Signature image</label>
                                                            {!signImg ? (
                                                                <>
                                                                    <div className="choose-img">
                                                                        <span className="file-upload-box file-upload-small">
                                                                            <span className="hstack opacity-75">
                                                                                <TbUpload size={16} /> 
                                                                                <span className="ps-2 text-center fs-13">Upload your file</span>
                                                                            </span>
                                                                            <input
                                                                                type="file"
                                                                                accept="image/gif,image/jpeg,image/png,image/bmp"
                                                                                id="signImgChoose" 
                                                                                className="file-upload-input"
                                                                                onChange={signImgChange}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    {signImgError && (
                                                                        <div style={{ color: "red", marginTop: "8px" }}>
                                                                            <small>{signImgError}</small>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                <div className="selected-img d-flex justify-content-between">
                                                                    <img
                                                                        src={signImg.fileimage}
                                                                        alt="sign-img" 
                                                                        className="sign-img-prev"
                                                                        style={{ height: "31px", width: "232px", border: "1px solid #dddddd" }}
                                                                    />
                                                                    <button type="button" onClick={removeSignImage} 
                                                                    className="btn btn-outline-light text-danger px-2 py-1"
                                                                    style={{ textDecoration: "none", color: "red" }}>
                                                                        <Trash size={16} />
                                                                    </button>
                                                                </div>
                                                                </>
                                                            )}
                                                        
                                                        </div>
                                                        <div className="mb-3 row row-cols-2 justify-content-between">
                                                            <label className="col-form-label col-auto" htmlFor="signName_text">
                                                                Signature Name
                                                            </label>
                                                            <div className="col-6">
                                                                <input type="text" className="form-control"
                                                                    id="signName_text"
                                                                    name="signText_name"
                                                                    value={signNameText}
                                                                    onChange={(e) => handleSignNameChange(e, setSignNameText)} />
                                                            </div>
                                                        </div>
                                                        </>
                                                        ) : ('')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item mb-0">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button bg-transparent fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#odCollapseTwo" aria-expanded="false" aria-controls="odCollapseTwo">
                                                        Annexure
                                                    </button>
                                                </h2>
                                                <div id="odCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#odAccordion">
                                                    <div className="accordion-body">
                                                        <p>
                                                            Click {' '}
                                                            <b>Add Annexure Content</b> to enter additional 
                                                            information apart from your Terms & Conditions. 
                                                            It can include by-laws, clauses and other details pertaining 
                                                            to your organization. This will be included on a separate 
                                                            page at the end of every Quote.
                                                        </p>
                                                        <div className="mb-0">
                                                            <button type="button" className="btn btn-outline-secondary rounded-2 w-100" 
                                                            onClick={()=> handleAddAnnexureModalShow()}>
                                                                <Settings size={16} /> <span className="ps-2">Add Annexure Content</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // Left side tab with settings forms END // */}

                    <div className="temp-view flex-md-grow-1">
                        <div className="overflow-auto bg-body-tertiary h-100 temp-main">

                            <div 
                            className={`temp-details bg-white ${orientation === 'Landscape' ? 'orientation-landscape' : ''}`} 
                            style={{width: width, minWidth: width}}>
                                
                                <div className="temp-section" style={{minHeight: "800px"}}>
                                    {/* PDF output area start */}
                                    <div className="temp-page" 
                                    style={{fontFamily: getTempFontFamily(allFontFamily), 
                                    fontSize: `${allFontSize}pt`, color: allFontColor, 
                                    backgroundColor: tempBgImg ? "transparent" : tempBgColor && isTempBgColorEnabled ? tempBgColor : "transparent", 
                                    backgroundImage: tempBgImg ? `url(${tempBgImg.fileimage})` : 'none',
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: selectedTempBgPos,
                                    backgroundSize: `${width} 100%`}}>

                                        <div className="temp-header" 
                                        style={{height: isHfPsettingEnabled ? `${headerFpHeight}0000in` : `${tempTopMargin}0000in`, 
                                        padding: `0in ${tempRightMargin}0000in 0in ${tempLeftMargin}0000in`, 
                                        fontSize: "8pt", color: "#000000",
                                        backgroundColor: headerBgImg ? "transparent" : headerBgColor && isHeaderBgColorEnabled ? headerBgColor : "transparent", 
                                        backgroundImage: headerBgImg ? `url(${headerBgImg.fileimage})` : 'none',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: selectedHeaderBgPos,
                                        backgroundSize: `${width} 100%` }}>
                                            {/* QT-17
                                            <div>Richard</div>
                                            <div>%ContactDepartment%</div>
                                            <div className="d-flex justify-content-between">
                                                <div>30/01/2025</div>
                                                <div>11324</div>
                                            </div> */}
                                        </div>

                                        <div className="temp-body" style={{padding: `0in ${tempRightMargin}0000in 0in ${tempLeftMargin}0000in`, }}>
                                            <div className="temp-bodySection" 
                                            style={{border: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`}}>
                                                <table style={{width: "100%"}}>
                                                    <tbody>
                                                        <tr>
                                                            {isOrgImgCheckEnabled ? (
                                                                <>
                                                                {!orgImg ? (
                                                                    ''
                                                                ) : (
                                                                    <>
                                                                    <td style={{ width: "10%", padding: "2px 10px", verticalAlign: "middle" }}>
                                                                        <img src={orgImg.fileimage} alt="org logo" style={{ width: "150px" }} />
                                                                    </td>
                                                                    </>
                                                                )}
                                                                </> 
                                                            ) : ('')}
                                                            <td style={{width:"50%",padding: "2px 10px",verticalAlign: "middle"}}>
                                                                <div>
                                                                    <span style={{fontSize: `${orgNameFontSize}pt`, color: orgNameFontColor, 
                                                                    fontWeight: "bold", display: `${isOrgNameCheckEnabled ? 'block':'none'}`}} 
                                                                    className={`temp-orgName`}>Digylax</span>
                                                                    {!isOrgAddressCheckEnabled ? (''):(
                                                                        <span className="d-block" 
                                                                        style={{fontSize: `${allFontSize}pt`, color: allFontColor}}>
                                                                            Tamilnadu <br/>
                                                                            India <br/>
                                                                            7894561237 <br/>
                                                                            info@digylax.com 
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td style={{width:"40%",padding: "5px",verticalAlign: "bottom"}} align="right">
                                                                {!isDocTitleEnabled ? ('') : (
                                                                    <div className="temp-title" style={{ fontSize: `${docTitleFontSize}pt`, color: docTitleFontColor }}>{docTitleLabelText}</div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="temp-detailstable" cellSpacing="0" cellPadding="0" border="0" 
                                                style={{width: "100%", tableLayout: "fixed", wordWrap: "break-word"}}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: "50%"}}></th>
                                                            <th style={{width: "50%"}}></th>
                                                        </tr>
                                                        </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ paddingBottom: "10px",
                                                            borderTop: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`,
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`}}>
                                                                {firstArray.map((checkbox) => (
                                                                    checkbox.isChecked && (
                                                                        <div className="info" key={checkbox.id}>
                                                                            <span className="temp-label" style={{ color: allLabelColor }}>
                                                                                {checkbox.value}
                                                                            </span>
                                                                            <span style={{ fontWeight: '600' }}>: {checkbox.placeholderValue}</span>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </td>
                                                            <td style={{paddingBottom: "10px", 
                                                                borderTop: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`}}>
                                                                {secondArray.map((checkbox) => (
                                                                    checkbox.isChecked && (
                                                                        <div className="info" key={checkbox.id}>
                                                                            <span className="temp-label" style={{ color: allLabelColor }}>
                                                                                {checkbox.value}
                                                                            </span>
                                                                            <span style={{ fontWeight: '600' }}>: {checkbox.placeholderValue}</span>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div style={{clear: "both"}}></div>
                                                <table className="temp-addresstable" border="0" cellSpacing="0" cellPadding="0" 
                                                style={{borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`}}>
                                                    <thead>
                                                        <tr>
                                                            {!isBillToEnabled ? ('') : (
                                                                <th style={{ borderTop: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }}>
                                                                    <div style={{ color: allLabelColor, marginBottom: "0px", display: "block" }} className="temp-label"><b>{billToLabelText}</b></div>
                                                                </th>
                                                            )}
                                                            {!isShipToEnabled ? ('') : (
                                                                <th style={{ borderTop: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`,borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }}>
                                                                    <div style={{ color: allLabelColor, marginBottom: "0px", display: "block" }} className="temp-label"><b>{shipToLabelText}</b></div>
                                                                </th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {!isBillToEnabled ? ('') : (
                                                                <td style={{ borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, paddingBottom: "10px" }} valign="top">
                                                                    <span style={{ whiteSpace: "pre-wrap", lineHeight: "15px" }}>
                                                                        <strong><span className="temp-customer-name" style={{ fontSize: `${cusNameFontSize}pt`, color: cusNameFontColor }}>Rob &amp; Joe Traders</span></strong><br />
                                                                        34, Riche Street <br />
                                                                        Chennai <br />
                                                                        631603 Tamil Nadu <br />
                                                                        India <br />
                                                                    </span>
                                                                </td>
                                                            )}
                                                            {!isShipToEnabled ? ('') : (
                                                                <td style={{ paddingBottom: "10px" }}>
                                                                    <div>
                                                                        <span style={{ whiteSpace: "pre-wrap", lineHeight: "15px" }}>
                                                                            34, Riche Street <br />
                                                                            Chennai <br />
                                                                            631603 Tamil Nadu <br />
                                                                            India <br />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div style={{clear: "both"}}></div>
                                                <div className="subject-block" style={{marginTop: "0", padding: "10px"}}>
                                                    {thirdArray.map((checkbox) => (
                                                        checkbox.isChecked && (
                                                            <div className="temp-label subject-block-label" key={checkbox.id} style={{ color: allLabelColor }}>
                                                                {checkbox.value} :
                                                            </div>
                                                        )
                                                    ))}
                                                    <div className="subject-block-value" 
                                                    style={{whiteSpace:"pre-wrap",lineHeight:"14px"}}>
                                                        Description 
                                                        {/* Attention content binding section */}
                                                        {/* %EstimateNumber%
                                                        %CompanyName%
                                                        %ContactEMailIds%
                                                        %FirstName%
                                                        %ContactMobile% */}
                                                    </div>
                                                </div>
                                                <table style={{width: "100%", tableLayout: "fixed", clear: "both", borderTop: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`}} 
                                                className="temp-itemtable" cellSpacing="0" cellPadding="0" border="0">
                                                    <thead>
                                                        <tr style={{height: "17px"}}>
                                                            {!isLineItemNumCheckEnabled ? ('') : (
                                                            <td 
                                                            style={{padding: "5px 5px 2px 5px", width: `${lineNumWidth}%`, textAlign: "center",
                                                                wordWrap: "break-word", fontWeight: "normal",
                                                                fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                                borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`,
                                                                borderLeft: "0px"
                                                                }}
                                                                valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>{lineNumLabelText}</b>
                                                            </td>
                                                            )}                                                            
                                                            <td 
                                                            style={{padding: "5px 7px 2px 7px",width: `${itemWidth}%`,textAlign: "left",
                                                            wordWrap: "break-word", fontWeight: "normal", 
                                                            fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }} 
                                                            valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>
                                                                    {!isItemEnabled ? ('') : (itemLabelText)} {isItemEnabled && isDescLabelEnabled ? (<>&amp;</>) : ('')}
                                                                    {!isDescLabelEnabled ? (''):(<>{' '} {descLabelText}</>)}
                                                                </b>
                                                            </td>
                                                            {!isTaQtyEnabled ? (''):(
                                                            <td 
                                                            style={{padding: "5px 7px 2px 7px",width: `${taQtyWidth}%`,textAlign: "right",
                                                            wordWrap: "break-word", fontWeight: "normal", 
                                                            fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }} 
                                                            valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>{taQtyLabelText}</b>
                                                            </td>
                                                            )}
                                                            {!isTaRateEnabled ? (''):(
                                                            <td 
                                                            style={{padding: "5px 7px 2px 7px",width: `${taRateWidth}%`,textAlign: "right",
                                                            wordWrap: "break-word", fontWeight: "normal", 
                                                            fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }} 
                                                            valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>{taRateLabelText}</b>
                                                            </td>
                                                            )}
                                                            {!isTaDisEnabled ? (''):(
                                                            <td 
                                                            style={{padding: "5px 7px 2px 7px",width: `${taDisWidth}%`,textAlign: "right",
                                                            wordWrap: "break-word", fontWeight: "normal", 
                                                            fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }} 
                                                            valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>{taDisLabelText}</b>
                                                            </td>
                                                            )}
                                                            {!isTaTaxPerEnabled ? (''):(
                                                            <td 
                                                            style={{padding: "5px 7px 2px 7px",width: `${taTaxPerWidth}%`,textAlign: "right",
                                                            wordWrap: "break-word", fontWeight: "normal", 
                                                            fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }} 
                                                            valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>{taTaxPerLabelText}</b>
                                                            </td>
                                                            )}
                                                            {!isTaTaxAmtEnabled ? (''):(
                                                            <td 
                                                            style={{padding: "5px 7px 2px 7px",width: `${taTaxAmtWidth}%`,textAlign: "right",
                                                            wordWrap: "break-word", fontWeight: "normal", 
                                                            fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}` }} 
                                                            valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>{taTaxAmtLabelText}</b>
                                                            </td>
                                                            )}
                                                            {!isTaAmtEnabled ? (''):(
                                                            <td 
                                                            style={{padding: "5px 7px 2px 7px",width: `${taAmtWidth}%`,textAlign: "right",  
                                                            wordWrap: "break-word", fontWeight: "normal", 
                                                            fontSize: `${taHeadFontSize}pt`, color: taHeadFontColor, backgroundColor: `${isTaHeadBgColorEnabled ? taHeadBgColor : 'transparent'}`, 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            borderRight: "0px"}} 
                                                            valign="bottom" rowSpan="2" className="temp-itemtable-header temp-itemtable-breakword">
                                                                <b>{taAmtLabelText}</b>
                                                            </td>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="itemBody">
                                                        <tr className="breakrow-inside breakrow-after" style={{height:"20px", pageBreakAfter: "auto", pageBreakInside: "avoid"}}>
                                                            {!isLineItemNumCheckEnabled ? ('') : (
                                                                <td valign="top" className="temp-item-row" 
                                                                style={{textAlign: "center", padding: "1px 5px", wordWrap: "break-word", 
                                                                borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                                fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor,
                                                                borderLeft: "0"}}>
                                                                    1
                                                                </td>
                                                            )}
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <div>
                                                                    <span style={{wordWrap: "break-word"}}>Brochure Design</span><br/>
                                                                    <span style={{whiteSpace: "pre-wrap",wordWrap: "break-word", color: taBodyDescColor, fontSize: `${taBodyDescSize}pt`}} className="temp-item-desc">Brochure Design Single Sided Color</span><br/>
                                                                </div>
                                                            </td>
                                                            {!isTaQtyEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                1.00
                                                                {!isTaQtyUnitEnabled ? (''):(<div>Nos</div>)}
                                                            </td>
                                                            )}
                                                            {!isTaRateEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                300.00
                                                            </td>
                                                            )}
                                                            {!isTaDisEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                0.00
                                                            </td>
                                                            )}
                                                            {!isTaTaxPerEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <span>7.00%</span>
                                                            </td>
                                                            )}
                                                            {!isTaTaxAmtEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <span>21.00</span>
                                                            </td>
                                                            )}
                                                            {!isTaAmtEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor,
                                                            borderRight: "0"}}>
                                                                300.00
                                                            </td>
                                                            )}
                                                        </tr>
                                                        <tr className="breakrow-inside breakrow-after" style={{height:"20px", pageBreakAfter: "auto", pageBreakInside: "avoid"}}>
                                                            {!isLineItemNumCheckEnabled ? ('') : (
                                                                <td valign="top" className="temp-item-row" 
                                                                style={{textAlign: "center", padding: "1px 5px", wordWrap: "break-word", 
                                                                borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                                fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor,
                                                                borderLeft: "0"}}>
                                                                    2
                                                                </td>
                                                            )}
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <div>
                                                                    <span style={{wordWrap: "break-word"}}>Web Design Packages(Template) - Basic</span><br />
                                                                    <span style={{whiteSpace: "pre-wrap",wordWrap: "break-word", color: taBodyDescColor, fontSize: `${taBodyDescSize}pt`}} className="temp-item-desc">Custom Themes for your business. Inclusive of 10 hours of marketing and annual training</span><br />
                                                                </div>
                                                            </td>
                                                            {!isTaQtyEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                1.00
                                                                {!isTaQtyUnitEnabled ? (''):(<div>Nos</div>)}
                                                            </td>
                                                            )}
                                                            {!isTaRateEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                250.00
                                                            </td>
                                                            )}
                                                            {!isTaDisEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                0.00
                                                            </td>
                                                            )}
                                                            {!isTaTaxPerEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <span>4.70%</span>
                                                            </td>
                                                            )}
                                                            {!isTaTaxAmtEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <span>11.75</span>
                                                            </td>
                                                            )}
                                                            {!isTaAmtEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor,
                                                            borderRight: "0"}}>
                                                                250.00
                                                            </td>
                                                            )}
                                                        </tr>
                                                        <tr className="breakrow-inside breakrow-after" style={{height:"20px", pageBreakAfter: "auto", pageBreakInside: "avoid"}}>
                                                            {!isLineItemNumCheckEnabled ? ('') : (
                                                                <td valign="top" className="temp-item-row" 
                                                                style={{textAlign: "center", padding: "1px 5px", wordWrap: "break-word", 
                                                                borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                                fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor,
                                                                borderLeft: "0"}}>
                                                                    3
                                                                </td>
                                                            )}
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <div>
                                                                    <span style={{wordWrap: "break-word"}}>Print Ad - Basic - Color</span><br/>
                                                                    <span style={{whiteSpace: "pre-wrap",wordWrap: "break-word", color: taBodyDescColor, fontSize: `${taBodyDescSize}pt`}} className="temp-item-desc">Print Ad 1/8 size Color</span><br/>
                                                                </div>
                                                            </td>
                                                            {!isTaQtyEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                1.00
                                                                {!isTaQtyUnitEnabled ? (''):(<div>Nos</div>)}
                                                            </td>
                                                            )}
                                                            {!isTaRateEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                80.00
                                                            </td>
                                                            )}
                                                            {!isTaDisEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                0.00
                                                            </td>
                                                            )}
                                                            {!isTaTaxPerEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <span></span>
                                                            </td>
                                                            )}
                                                            {!isTaTaxAmtEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor}}>
                                                                <span></span>
                                                            </td>
                                                            )}
                                                            {!isTaAmtEnabled ? (''):(
                                                            <td valign="top" className="temp-item-row" 
                                                            style={{textAlign: "right", padding: "1px 5px", wordWrap: "break-word", 
                                                            borderRight: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`, 
                                                            fontSize: `${taBodyFontSize}pt`, backgroundColor: `${isTaBodyBgColorEnabled && !tempBgImg ? taBodyBgColor : 'transparent'}`, color: taBodyFontColor,
                                                            borderRight: "0"}}>
                                                                80.00
                                                            </td>
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div style={{width: "100%", marginTop: "1px"}}>
                                                    <div style={{width: "50%", padding: "4px 4px 6px 7px",float: "left"}}>
                                                        {isTotalQtySetEnabled ? (
                                                        <div style={{margin:"3px 0 5px"}}>
                                                            <div>
                                                                <span style={{fontSize: `${totalSecFsize}pt`, 
                                                                color: totalSecFcolor, 
                                                                backgroundColor: `${isTotalSecBgColorEnabled && !tempBgImg ? totalSecBgColor : 'transparent'}`, 
                                                                marginBottom: "6.5px", display: "inline-block"}} 
                                                                className="temp-totals">{itemsTotalLabelText}</span>{' '}
                                                                <span>3.00</span>
                                                            </div>
                                                        </div>
                                                        ):('')}
                                                        {isAmtShowWordsEnabled ? (
                                                        <div style={{margin: "10px 0 5px"}}>
                                                            <div style={{color: allLabelColor, paddingRight: "10px"}}>Total In Words</div>
                                                            <span>
                                                                <b>
                                                                    <i>Indian Rupee Six Hundred Sixty-Two and Seventy-Five Paise Only</i>
                                                                </b>
                                                            </span>
                                                        </div>
                                                        ):('')}
                                                        <div style={{paddingTop: "10px"}}>
                                                            <span style={{color: allLabelColor}} className="temp-label">{notesLabelText}</span>
                                                            <p style={{fontSize: `${notesFsize}pt`, fontFamily:"inherit", whiteSpace: "pre-wrap",wordWrap: "break-word"}} 
                                                            className="temp-notes">
                                                                Looking forward for your business.
                                                            </p>
                                                        </div>
                                                        {/* Bank Details section */}
                                                        {/* <div style={{marginTop:"10px", width:"100%",wordWrap:"break-word"}} className="bank-details-section"><div>%SalesPersonName%</div><div>%SalesEmail%</div><div>%CompanyStreetAddress1%</div><div>%CompanyStreetAddress2%</div><div>%CompanyCountry%</div><div>%CompanyPhoneNumber%</div></div> */}
                                                        {/* // END Bank Details section // */}
                                                        {isTermCondSetEnabled ? (
                                                        <div style={{clear:"both",marginTop: "20px",width:"100%"}}>
                                                            <div style={{color: allLabelColor}} className="temp-label">{termCondLabelText}</div>
                                                            <div style={{fontSize: `${termCondFsize}pt`,whiteSpace: "pre-wrap",wordWrap: "break-word"}} className="temp-terms">
                                                                Your company's Terms and Conditions will be displayed here. 
                                                                You can add it in the Estimate Preferences page under Settings.
                                                            </div>
                                                        </div>
                                                        ):('')}
                                                    </div>
                                                    <div style={{width: "43.6%",float: "right", 
                                                        fontSize: `${totalSecFsize}pt`, 
                                                        color: totalSecFcolor, 
                                                        backgroundColor: `${isTotalSecBgColorEnabled && !tempBgImg ? totalSecBgColor : 'transparent'}`
                                                    }} className="temp-totals">
                                                        <table style={isAllTotalSetEnabled ? {borderLeft: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`}: {}} 
                                                        className="temp-totaltable" 
                                                        cellSpacing="0" border="0" width="100%">
                                                            {isAllTotalSetEnabled ? (
                                                            <tbody>
                                                                {isSubTotalEnabled ? (
                                                                <tr>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign:"right"}}>{subTotalText}</td>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign:"right",width:"110px"}}>630.00</td>
                                                                </tr>
                                                                ):('')}
                                                                {isDiscountSecEnabled ? (
                                                                <tr>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign: "right"}}>Discount</td>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign: "right",width: "110px"}}>
                                                                        0.00
                                                                    </td>
                                                                </tr>
                                                                ):('')}
                                                                {isToTaxAmtEnabled ? (
                                                                <tr style={{height:"10px"}}>
                                                                    <td valign="middle" align="right" style={{padding:"4px 7px 0",textAlign: "right"}}>{toTaxAmtLabelText}</td>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign: "right",width:"110px"}}>630.00</td>
                                                                </tr>
                                                                ):('')}
                                                                {isShowTaxDetailEnabled ? (
                                                                <>
                                                                <tr style={{height:"10px"}}>
                                                                    <td valign="middle" align="right" style={{padding:"4px 7px 0",textAlign: "right"}}>Sample Tax1 (4.70%)</td>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign: "right",width:"110px"}}>11.75</td>
                                                                </tr>
                                                                <tr style={{height:"10px"}}>
                                                                    <td valign="middle" align="right" style={{padding:"4px 7px 0",textAlign: "right"}}>Sample Tax2 (7.00%)</td>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign: "right",width:"110px"}}>21.00</td>
                                                                </tr>
                                                                </>
                                                                ):('')}
                                                                {isMainTotalEnabled ? (
                                                                <tr>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign: "right"}}><b>{mainTotalText}</b></td>
                                                                    <td valign="middle" style={{padding:"4px 7px 0",textAlign: "right",width:"110px"}}>
                                                                        <b>
                                                                            {selectedCurSymbolPos === "beforeAmt" ? "₹" : ""}
                                                                            662.75
                                                                            {selectedCurSymbolPos === "afterAmt" ? "₹" : ""}
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                ):('')}

                                                                <tr>
                                                                    <td style={{borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`}} colSpan="2"></td>
                                                                </tr>
                                                            </tbody>
                                                            ):('')}
                                                            {isSignSetEnabled ? (
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{textAlign: "center",paddingTop: "5px"}} colSpan="2">
                                                                        <div style={{paddingRight: "5px"}} className="temp-totals">{signNameText}</div>
                                                                        {signImg ? (
                                                                            <img src={signImg.fileimage} style={{maxWidth: "240px", maxHeight: "240px"}} />
                                                                        ):(
                                                                            <div style={{minHeight: "75px"}}></div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="2" 
                                                                    style={isAllTotalSetEnabled ? {borderBottom: `1px solid ${isTableBorderEnabled ? tempBorderColor : 'transparent'}`,textAlign: "center"} : {}}>
                                                                        <div style={{fontSize: `${totalSecFsize}pt`, 
                                                                        color: totalSecFcolor, 
                                                                        backgroundColor: `${isTotalSecBgColorEnabled && !tempBgImg ? totalSecBgColor : 'transparent'}`,
                                                                        textAlign: "center"}} 
                                                                        className="temp-totals">{signLabelText}</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            ):('')}
                                                        </table>
                                                    </div>
                                                    <div style={{clear: "both"}}></div>
                                                </div>
                                                {annexContValues.length > 0 ? (
                                                <div style={{width:"99%",padding:"7px"}}>
                                                    <div style={{pageBreakBefore: "always",marginTop:"20px",pageBreakInside: "auto", whiteSpace: "pre"}} 
                                                    className="annexure-plain">
                                                        {annexContValues}
                                                    </div>
                                                </div>
                                                ):('')}
                                            </div>
                                        </div>

                                        <div className="temp-footer" 
                                        style={{height: `${tempBottomMargin}0000in`, 
                                        padding: `0in ${tempRightMargin}0000in 0in ${tempLeftMargin}0000in`, 
                                        fontSize: `${footFontSize}pt`, color: footFontColor, 
                                        backgroundColor: footBgImg ? "transparent" : footBgColor && isFootBgColorEnabled ? footBgColor : "transparent", 
                                        backgroundImage: footBgImg ? `url(${footBgImg.fileimage})` : 'none',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: selectedFootBgPos,
                                        backgroundSize: `${width} 100%` }}>
                                            {/* <div style={{borderTop:"0",wordWrap:"break-word"}} className="temp-footer-content">
                                                <div>Digylax Pvt Ltd</div>
                                                <div className="page-num" style={{textAlign: "right", fontSize: "8pt", color: "#000000"}}>1</div>
                                            </div> */}
                                        </div>
                                        
                                    </div>
                                    {/* // PDF output area END // */}
                                </div>
                                {/* // Template section // */}
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        {/* Header & Footer - Custom Content Modal */}
        <SimpleModal
            show={headFootCusContModal}
            handleClose={() => setHeadFootCusContModal(false)}
            handleShow={() => setHeadFootCusContModal(true)}
            size="lg"
            modalTitle={haFtitle}
        >
            <div className="card p-0">
                <div className="card-header d-flex justify-content-between">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" className="with-arrow px-3" id="headFootCont_dropdownBtn">
                            <span className="pe-3">Insert Placeholders</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="max-h-400px">
                            <div className="hstack align-items-start">
                                {customPlaceholders.map((categoryTitle, index) => (
                                    <div key={index} className={index !== 0 ? 'ps-2 border-start' : 'pe-2'}>
                                        <Dropdown.ItemText className="fw-medium dropdown-header">{categoryTitle.categoryTitle}</Dropdown.ItemText>
                                        {categoryTitle.items.map((item, itemIndex) => (
                                            <Dropdown.Item key={itemIndex} onClick={() => handleInsertCustomPlaceholder(item)}>
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <textarea rows={9} id="header_custom_cont_input" className="form-control border-0" 
                value={hAfCustomValues} onChange={handleHaFcustomValuesChange}></textarea>
            </div>
            <div className="mt-4 form-text text-muted">
                <span className="fw-semibold pe-2">Note:</span>
                If your content exceeds the template margin, you can adjust the margin values accordingly.
            </div>
        </SimpleModal>
        {/* // Header & Footer - Custom Content Modal END // */}

        {/* Org Address Format Modal */}
        <Modal show={orgAddressFormatShow} onHide={orgAddressModalClose} size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title className="font-17 fw-normal">
                    <span className="align-middle">Organization Address Format (Displayed in PDF only)</span>
                    <TooltipTrig id="orgAddressFormatInfo" placement={"right"}
                        title={<span className="pe-4 d-inline-block">Placeholders and characters like comma, space, -, ., : are allowed.</span>}>
                        <span className="ps-1 text-gray">
                            <GoQuestion size={20} />
                        </span>
                    </TooltipTrig>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {orgAddConfigShow ? (
                    <>
                        <textarea rows={9} id="org_custom_cont_input" className="form-control border-0 text-uppercase"
                            value={orgAddCustomValues} disabled></textarea>
                        <div className="mt-2 form-text text-muted">
                            This is the common address format. To edit this address format, go to
                            <span className="fw-semibold ps-1">Settings &gt; Preferences &gt; General</span>.
                        </div>
                        <div className="mt-4 mb-0 alert alert-light d-flex align-items-center" role="alert">
                            <div className="text-info">
                                <i className="fa-solid fa-circle-info fs-5"></i>
                            </div>
                            <div className="ms-2 small">
                                Do you want to have a custom address format only for this template ?
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn btn-link text-decoration-none small px-0"
                                onClick={() => setOrgAddConfigShow()}>
                                    <Settings size={16} /> <span className="ps-1">Configure</span>
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card p-0">
                            <div className="card-header d-flex justify-content-between">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" className="with-arrow px-3" id="orgCont_dropdownBtn">
                                        <span className="pe-3">Insert Placeholder</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="max-h-400px">
                                        <div className="hstack align-items-start">
                                            {orgCustomPlaceholders.map((categoryTitle, index) => (
                                                <div key={index} className={index !== 0 ? 'ps-2 border-start' : 'pe-2'}>
                                                    {/* <Dropdown.ItemText className="fw-medium dropdown-header">{categoryTitle.categoryTitle}</Dropdown.ItemText> */}
                                                    {categoryTitle.items.map((item, itemIndex) => (
                                                        <Dropdown.Item key={itemIndex} onClick={() => handleInsertOrgCustomPlaceholder(item)}>
                                                            {item}
                                                        </Dropdown.Item>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <textarea rows={9} id="org_cont_input" className="form-control border-0 text-uppercase"
                                value={orgAddCustomValues} onChange={handleOrgCustomValuesChange}></textarea>
                        </div>
                        <div className="mt-3 alert alert-warning d-flex" role="alert">
                            <div className="text-warning">
                                <i className="fa-solid fa-circle-info fs-6"></i>
                            </div>
                            <div className="ms-2 small pe-3">
                                This custom address format will be used only in this template.
                                If you leave the custom address format empty,
                                the common address format will be used.
                            </div>
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {orgAddConfigShow ? (
                    <Button variant="outline-secondary" className="py-1 me-auto" onClick={orgAddressModalClose}>
                        Close
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" className="py-1" onClick={orgAddressModalClose}>
                            Preview
                        </Button>
                        <Button variant="outline-secondary" className="py-1" onClick={orgAddressModalClose}>
                            Cancel
                        </Button>
                        <Button variant="link" type="reset" className="p-0 py-2 ms-auto text-decoration-none" 
                        onClick={() => setOrgAddConfigShow(true)}>Use Common Format</Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
        {/* // Org Address Format Modal END // */}

        {/* Bill To And Ship To Address Format Modal */}
        <Modal show={billOrShipAddressFormatShow} onHide={billOrShipAddressModalClose} size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title className="font-17 fw-normal">
                    <span className="align-middle">{billOrShipTitle} Address Format (Displayed in PDF only)</span>
                    <TooltipTrig id="billOrShipAddressFormatInfo" placement={"right"}
                        title={<span className="pe-4 d-inline-block">Placeholders and characters like comma, space, -, ., : are allowed.</span>}>
                        <span className="ps-1 text-gray">
                            <GoQuestion size={20} />
                        </span>
                    </TooltipTrig>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {billOrShipAddConfigShow ? (
                    <>
                        <textarea rows={9} id="billOrShip_add_format_input" className="form-control border-0 text-uppercase"
                            value={billOrShipAddCustomValues} disabled></textarea>
                        <div className="mt-2 form-text text-muted">
                            This is the common address format. To edit this address format, go to
                            <span className="fw-semibold ps-1">Settings &gt; Preferences &gt; Customers and Vendors</span>.
                        </div>
                        <div className="mt-4 mb-0 alert alert-light d-flex align-items-center" role="alert">
                            <div className="text-info">
                                <i className="fa-solid fa-circle-info fs-5"></i>
                            </div>
                            <div className="ms-2 small">
                                Do you want to have a custom address format only for this template ?
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn btn-link text-decoration-none small px-0"
                                onClick={() => setBillOrShipAddConfigShow()}>
                                    <Settings size={16} /> <span className="ps-1">Configure</span>
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card p-0">
                            <div className="card-header d-flex justify-content-between">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" className="with-arrow px-3" id="billOrShipCont_dropdownBtn">
                                        <span className="pe-3">Insert Placeholder</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="max-h-400px">
                                        <div className="hstack align-items-start">
                                            {billOrShipCustomPlaceholders.map((categoryTitle, index) => (
                                                <div key={index} className={index !== 0 ? 'ps-2 border-start' : 'pe-2'}>
                                                    <Dropdown.ItemText className="fw-medium dropdown-header">{categoryTitle.categoryTitle}</Dropdown.ItemText>
                                                    {categoryTitle.items.map((item, itemIndex) => (
                                                        <Dropdown.Item key={itemIndex} onClick={() => handleInsertBillOrShipCustomPlaceholder(item)}>
                                                            {item}
                                                        </Dropdown.Item>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <textarea rows={9} id="billOrShip_cont_input" className="form-control border-0 text-uppercase"
                                value={billOrShipAddCustomValues} onChange={handleBillOrShipCustomValuesChange}></textarea>
                        </div>
                        <div className="mt-3 alert alert-warning d-flex" role="alert">
                            <div className="text-warning">
                                <i className="fa-solid fa-circle-info fs-6"></i>
                            </div>
                            <div className="ms-2 small pe-3">
                                This custom address format will be used only in this template.
                                If you leave the custom address format empty,
                                the common address format will be used.
                            </div>
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {billOrShipAddConfigShow ? (
                    <Button variant="outline-secondary" className="py-1 me-auto" onClick={billOrShipAddressModalClose}>
                        Close
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" className="py-1" onClick={billOrShipAddressModalClose}>
                            Preview
                        </Button>
                        <Button variant="outline-secondary" className="py-1" onClick={billOrShipAddressModalClose}>
                            Cancel
                        </Button>
                        <Button variant="link" type="reset" className="p-0 py-2 ms-auto text-decoration-none" 
                        onClick={() => setBillOrShipAddConfigShow(true)}>Use Common Format</Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
        {/* // Bill To And Ship To Address Format Modal END // */}

        {/* Add Attention Content Modal */}
        <SimpleModal
            show={addAttentionContModal}
            handleClose={() => setAddAttentionContModal(false)}
            handleShow={() => setAddAttentionContModal(true)}
            size="lg" 
            modalTitle="Add Attention Content"
        >
            <div className="card p-0">
                <div className="card-header d-flex justify-content-between">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" className="with-arrow px-3" id="headFootCont_dropdownBtn">
                            <span className="pe-3">Insert Placeholders</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="max-h-400px">
                            <div className="hstack align-items-start">
                                {customPlaceholders.map((categoryTitle, index) => (
                                    <div key={index} className={index !== 0 ? 'ps-2 border-start' : 'pe-2'}>
                                        <Dropdown.ItemText className="fw-medium dropdown-header">{categoryTitle.categoryTitle}</Dropdown.ItemText>
                                        {categoryTitle.items.map((item, itemIndex) => (
                                            <Dropdown.Item key={itemIndex} onClick={() => handleInsertCustomPlaceholder(item)}>
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <textarea rows={9} id="header_custom_cont_input" className="form-control border-0" 
                value={hAfCustomValues} onChange={handleHaFcustomValuesChange}></textarea>
            </div>
            <div className="mt-4 form-text text-muted">
                <span className="fw-semibold pe-2">Note:</span>
                Attention content will be displayed above the "Item Table".
            </div>
        </SimpleModal>
        {/* // Add Attention Content Modal END // */}

        {/* Customize Item Name & Description Modal */}
        <SimpleModal
            show={cusNameAndDescModal}
            handleClose={() => setCusNameAndDescModal(false)}
            handleShow={() => setCusNameAndDescModal(true)}
            size="lg" 
            modalTitle="Customize Item Name & Description Format"
        >
            <div className="card p-0">
                <div className="card-header d-flex justify-content-between">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" className="with-arrow px-3" id="headFootCont_dropdownBtn">
                            <span className="pe-3">Insert Placeholders</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="max-h-400px">
                            <div className="hstack align-items-start">
                                {cusNameAndDescPlaceholders.map((categoryTitle, index) => (
                                    <div key={index} className={index !== 0 ? 'ps-2 border-start' : 'pe-2'}>
                                        <Dropdown.ItemText className="fw-medium dropdown-header">{categoryTitle.categoryTitle}</Dropdown.ItemText>
                                        {categoryTitle.items.map((item, itemIndex) => (
                                            <Dropdown.Item key={itemIndex} onClick={() => handleCusNameAndDescPlaceholder(item)}>
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <textarea rows={9} id="header_custom_cont_input" className="form-control border-0" 
                value={cusNameAndDescValues} onChange={handleCusNameAndDescValuesChange}></textarea>
            </div>
            <div className="mt-4 form-text text-muted">
                <span className="fw-semibold pe-2">Note:</span>
                If your content exceeds the template margin, you can adjust the margin values accordingly.
            </div>
        </SimpleModal>
        {/* // Customize Item Name & Description Modal END // */}

        {/* Add Bank details Modal */}
        <SimpleModal
            show={addBankDetailsModal}
            handleClose={() => setAddBankDetailsModal(false)}
            handleShow={() => setAddBankDetailsModal(true)}
            size="lg" 
            modalTitle="Add your bank details"
        >
            <div className="card p-0">
                <div className="card-header d-flex justify-content-between">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" className="with-arrow px-3" id="headFootCont_dropdownBtn">
                            <span className="pe-3">Insert Placeholders</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="max-h-400px">
                            <div className="hstack align-items-start">
                                {customPlaceholders.map((categoryTitle, index) => (
                                    <div key={index} className={index !== 0 ? 'ps-2 border-start' : 'pe-2'}>
                                        <Dropdown.ItemText className="fw-medium dropdown-header">{categoryTitle.categoryTitle}</Dropdown.ItemText>
                                        {categoryTitle.items.map((item, itemIndex) => (
                                            <Dropdown.Item key={itemIndex} onClick={() => handleInsertCustomPlaceholder(item)}>
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <textarea rows={9} id="header_custom_cont_input" className="form-control border-0" 
                value={hAfCustomValues} onChange={handleHaFcustomValuesChange}></textarea>
            </div>
            <div className="mt-4 form-text text-muted">
                <span className="fw-semibold pe-2">Note:</span>
                Bank details will be displayed below "Customer Notes".
            </div>
        </SimpleModal>
        {/* // Add Bank details Modal END // */}

        {/* Add Annexure Content Modal */}
        <SimpleModal
            show={addAnnexureModal}
            handleClose={() => setAddAnnexureModal(false)}
            handleShow={() => setAddAnnexureModal(true)}
            size="lg" 
            modalTitle="Add Annexure Content"
        >
            <div className="card p-0">
                <div className="card-header d-flex justify-content-between">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" className="with-arrow px-3" id="headFootCont_dropdownBtn">
                            <span className="pe-3">Insert Placeholders</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="max-h-400px">
                            <div className="hstack align-items-start">
                                {customPlaceholders.map((categoryTitle, index) => (
                                    <div key={index} className={index !== 0 ? 'ps-2 border-start' : 'pe-2'}>
                                        <Dropdown.ItemText className="fw-medium dropdown-header">{categoryTitle.categoryTitle}</Dropdown.ItemText>
                                        {categoryTitle.items.map((item, itemIndex) => (
                                            <Dropdown.Item key={itemIndex} onClick={() => handleInsertAnnexContPlaceholder(item)}>
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <textarea rows={9} id="header_custom_cont_input" className="form-control border-0" 
                value={annexContValues} onChange={handleAnnexContValuesChange}></textarea>
            </div>
        </SimpleModal>
        {/* // Add Annexure Content Modal END // */}

    </>);
}

export default QuoteTemplate;