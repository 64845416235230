import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    user: null,
    message: null,
    error: null,
    successStatus: null,
    reload: null,
    updateStatus: null,
    permissions: {
        isLoading: false,
        data: null,
        message: null,
        error: null,
        totalCount: null
    }
}

const subCurrencySlice = createSlice({
    name: 'subcurrency',
    initialState,
    reducers: {
        createSubCurrencyStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.updateStatus = null;
        },
        createSubCurrencySuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        createSubCurrencyFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        },
        updateSubCurrencyStart: (state, action) => {
            state.isLoading = true;
            state.reload = null;
            state.updateStatus = null;
        },
        updateSubCurrencySuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.data = action?.payload?.updateFormModules;
            state.isLoading = false;
            state.successStatus = action?.payload?.success;
            state.reload = action?.payload?.success;
            state.updateStatus = action?.payload?.success;
        },
        updateSubCurrencyFailure: (state, action) => {
            state.isLoading = false;
            state.message = action?.payload?.message
            state.successStatus = action?.payload?.success;
        }
    }
});
export const {
    createSubCurrencyStart,
    createSubCurrencySuccess,
    createSubCurrencyFailure,
    updateSubCurrencyStart,
    updateSubCurrencySuccess,
    updateSubCurrencyFailure
} = subCurrencySlice?.actions;

export default subCurrencySlice?.reducer;