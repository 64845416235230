import { useNavigate } from 'react-router-dom';
import userAvatar from '../../../Assets/images/icons/user-profile-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { createProfileStart, deleteProfileStart, deleteToTransferProfileStart, getProfileStart, updatePermissionStart } from '../../../Store/Slices/profileSlice';
import { formatMonth } from '../../../Components/DateFn';
import Loader from '../../../Components/Loader';
import { getAllUserListStart } from '../../../Store/Slices/userSlice';
import { Utils } from '../../../Utils';

const Profiles = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let currentProfiles = useSelector((state) => state?.profile);
    let allUsers = useSelector((state) => state?.user)?.data;
    let companyId = useSelector((state) => state?.org)?.companyData?.[0]?._id;
    let formList = useSelector((state) => state?.forms);
    // states
    const [value, setValue] = useState('');
    const [modalValue, setModalValue] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [profileData, setProfileData] = useState({
        profile_name: '',
        profile_desc: '',
        clone_profile: '',
    });
    const [profileId, setProfileId] = useState('');
    const [modalType, setModalType] = useState('new');
    const [selectedProfile, setSelectedProfile] = useState('');
    const [transferProfileId, setTransferProfileId] = useState('');

    // useeffect
    useEffect(() => {
        dispatch(getAllUserListStart({ page: 1, count: 100 }));
    }, []);

    useEffect(() => {
        if (currentProfiles?.profileData?.length <= 1) {
            setValidForm(profileData?.profile_name !== '' ? true : false);
        } else {
            setValidForm((profileData?.profile_name !== '' && profileData?.clone_profile !== '') ? true : false);
        }
    }, [profileData]);

    useEffect(() => {
        if (currentProfiles?.createStatus) {
            dispatch(getProfileStart());
            navigate(Utils.getNavigationUrl(`settings/profiles/profile-permissions`), { state: currentProfiles?.profileData })
        }
    }, [currentProfiles.createStatus]);

    useEffect(() => {
        if (currentProfiles?.updateStatus || currentProfiles?.deleteStatus) {
            clearState();
            setProfileId('');
            setSelectedProfile('');
            dispatch(getProfileStart());
        }
        if (currentProfiles?.deleteTransferStatus) {
            setTransferProfileId('');
            dispatch(getAllUserListStart({ page: 1, count: 100 }));
            handleDelete();
        }
    }, [currentProfiles.updateStatus, currentProfiles.deleteStatus, currentProfiles.deleteTransferStatus]);

    // functions
    const clearState = () => {
        setProfileData((prev) => ({
            ...prev,
            profile_name: '',
            profile_desc: '',
            clone_profile: ''
        }));
    }

    const getPermissionData = () => {
        if (currentProfiles?.profileData?.length == 0) {
            if (formList?.navbarList?.length > 0) {
                let arr = [];
                let activities = {
                    view: true,
                    create: true,
                    edit: true,
                    delete: true
                }
                formList.navbarList.forEach(element => {
                    arr.push({ id: element._id, name: element.name, activities: activities })
                });
                if (arr.length) {
                    let obj = {
                        modulePermission: {
                            basic: arr
                        }
                    }
                    return obj
                }
            }
        } else {
            return currentProfiles?.profileData?.filter((item) => item.profile_name == profileData?.clone_profile)?.[0]?.permission;
        }
    }

    // handlers
    const formHandler = (e) => {
        try {
            setProfileData((prev) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value
            }));
        } catch (err) {
            throw err
        }
    }

    const handleSubmit = () => {
        let payload = profileData;
        if (modalType == 'new' || modalType == 'clone') {
            payload['organization'] = companyId;
            payload['permission'] = getPermissionData();
            dispatch(createProfileStart(payload));
        } else {
            payload['permission'] = currentProfiles?.profileData?.filter((item) => item.profile_name == profileData?.profile_name)?.[0]?.permission
            dispatch(updatePermissionStart({ payload: payload, id: profileId }));
        }
    }

    const handleRename = (data) => {
        setProfileId(data?._id);
        setProfileData((prev) => ({
            ...prev,
            profile_name: data?.profile_name,
            clone_profile: data?.clone_profile,
            profile_desc: data?.profile_desc
        }));
    }

    const handleClone = (data) => {
        setProfileId(data?._id);
        setProfileData((prev) => ({
            ...prev,
            clone_profile: data?.profile_name
        }));
    }

    const handleDelete = () => {
        dispatch(deleteProfileStart(profileId));
    }

    const handleTransferDelete = () => {
        let payload = {
            oldProfileId: profileId,
            newProfileId: transferProfileId
        }
        dispatch(deleteToTransferProfileStart({ payload: payload }));
    }

    // UI
    return (
        <>
            <Loader isVisible={currentProfiles?.isLoading} />
            <div className="security-ctrl profile-set overflow-y-auto">
                <div className="pt-2">
                    <h5 className="fw-semibold">Profiles</h5>
                </div>
                <p className="w-75">
                    Profile is a set of permissions dealing with module access and operations, setup customizations,
                    and access to various apps. You can provide different set of permissions to various users.
                </p>

                <div className="d-flex align-items-center justify-content-between py-4">
                    <div className="d-flex align-items-start">
                        <div className="search-box border rounded-pill">
                            <label htmlFor="userSearch" className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></label>
                            <input type="search" placeholder="Search Profiles" id="userSearch" className="searchfield bg-white"
                                value={value} onChange={e => setValue(e.target.value)} />
                        </div>
                    </div>
                    <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3"
                        onClick={() => { clearState(); setModalType('new') }}
                        data-bs-toggle="modal" data-bs-target="#addEditUserModal">
                        New Profile
                    </button>
                </div>

                <div className="tbl-container rounded-table">
                    <div className="table-responsive">
                        <table className="table table-hover hover-icon-table users-table mb-0">
                            <thead>
                                <tr>
                                    <th className="w-30">Profile Name</th>
                                    <th className="w-30">Profile Description</th>
                                    <th className="w-20">Modified By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentProfiles?.profileData?.length > 0 ?
                                    <>
                                        {currentProfiles?.profileData?.
                                            filter(item => {
                                                if (!value) return true
                                                if (item.profile_name.includes(value) || item.profile_desc.includes(value)) {
                                                    return true
                                                }
                                            })
                                            .map((data) => (
                                                <tr>
                                                    {/* <td>{data?.profile_name}</td> */}
                                                    <td className="w-30">
                                                        <div className="dropend">
                                                            <a className="text-primary cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`settings/profiles/profile-permissions`), { state: data })}>{data?.profile_name}</a>
                                                            <i className="fa-solid fa-ellipsis ps-2 hover-icon dropdown-toggle" role="button"
                                                                data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                            <ul className="dropdown-menu py-1 px-0">
                                                                {data?.clone_profile !== '' &&
                                                                    <li>
                                                                        <button className="dropdown-item" type="button"
                                                                            onClick={() => { clearState(); setModalType('rename'); handleRename(data) }}
                                                                            data-bs-toggle="modal" data-bs-target="#addEditUserModal">
                                                                            Rename
                                                                        </button>
                                                                    </li>
                                                                }
                                                                <li>
                                                                    <button className="dropdown-item" type="button"
                                                                        onClick={() => { clearState(); setModalType('clone'); handleClone(data) }}
                                                                        data-bs-toggle="modal" data-bs-target="#addEditUserModal">
                                                                        Clone
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button className="dropdown-item" type="button"
                                                                        onClick={() => { setProfileId(data?._id); setSelectedProfile(data?.profile_name) }}
                                                                        data-bs-toggle="modal" data-bs-target="#viewUsersModal">
                                                                        View Users
                                                                    </button>
                                                                </li>
                                                                {data?.clone_profile !== '' &&
                                                                    <li>
                                                                        <button className="dropdown-item" type="button"
                                                                            onClick={() => { setProfileId(data?._id); setSelectedProfile(data?.profile_name) }}
                                                                            data-bs-toggle="modal" data-bs-target="#deleteAlertModal">
                                                                            Delete
                                                                        </button>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>{data?.profile_desc}</td>
                                                    <td>{formatMonth(data?.updatedAt)}</td>
                                                </tr>
                                            ))}
                                    </>
                                    :
                                    <tr>
                                        <td colSpan={3} className='text-center'>No Data Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Add & Edit User Modal */}
                <div className="modal fade" id="addEditUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addEditUserLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable add-user-form">
                        <div className="modal-content">
                            <div className="modal-body pb-0">
                                <h5 className="modal-title fs-5" id="addEditUserLabel">{modalType == 'rename' ? 'Rename Profile' : 'Create New Profile'}</h5>
                                <form className='pt-4'>
                                    <div className="row mb-3">
                                        <label htmlFor="profileName" className="col-auto col-md-3 text-md-end col-form-label">Profile Name</label>
                                        <div className="col-auto col-md-9 col-xl-7 input-control">
                                            <input type="text" className="form-control" id="profileName" required
                                                name='profile_name' value={profileData.profile_name} onChange={formHandler} />
                                        </div>
                                    </div>
                                    {(currentProfiles?.profileData?.length > 1 && modalType !== 'rename') &&
                                        <div className="row mb-3">
                                            <label htmlFor="cloneProfile" className="col-auto col-md-3 text-md-end col-form-label">Clone Profile</label>
                                            <div className="col-auto col-md-9 col-xl-7 input-control">
                                                <select className="form-select" id="cloneProfile"
                                                    name='clone_profile' value={profileData.clone_profile} onChange={formHandler}
                                                    disabled={modalType == 'clone'}
                                                    style={{ cursor: modalType == 'clone' && 'not-allowed' }}>
                                                    <option value="">Select Profile</option>
                                                    {
                                                        currentProfiles?.profileData?.length > 0 && currentProfiles?.profileData?.map((data) => (
                                                            <option value={data?.profile_name}>{data?.profile_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mb-3">
                                        <label htmlFor="profileDesc" className="col-auto col-md-3 text-md-end col-form-label">Profile Description</label>
                                        <div className="col-auto col-md-9 col-xl-7 input-control">
                                            <textarea className="form-control" id="profileDesc" rows="3"
                                                name='profile_desc' value={profileData.profile_desc} onChange={formHandler}></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={handleSubmit} disabled={!validForm} data-bs-dismiss="modal">Create</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Add & Edit User Modal // */}

                {/* View User Modal */}
                <div className="modal fade" id="viewUsersModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="viewUsersLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable add-user-modal">
                        <div className="modal-content">
                            <div className='modal-header border-bottom-0'>
                                <h5 className="modal-title fs-5" id="viewUsersLabel">Users in <span className='fs-5 fw-semibold'> {selectedProfile} </span> profile</h5>
                                <button type="button" className="btn-close" onClick={() => setSelectedProfile('')}
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <div className="d-flex col-4 pb-4">
                                    <div className="search-box border rounded-pill">
                                        <label htmlFor='usersSearch' className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></label>
                                        <input type="search" placeholder="Search" id='usersSearch' className="searchfield bg-white"
                                            value={modalValue} onChange={e => setModalValue(e.target.value)} />
                                    </div>
                                </div>

                                <div className="tbl-container rounded-table mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>User name</th>
                                                    <th>Role</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allUsers?.length > 0 && allUsers
                                                    .filter((user) => user?.Profile?._id === profileId)
                                                    .filter(item => {
                                                        if (!modalValue) return true
                                                        if (item.firstName.includes(modalValue) || item.lastName.includes(modalValue) ||
                                                            item.role.role_name.includes(modalValue)) {
                                                            return true
                                                        }
                                                    })
                                                    .map((data) => (
                                                        < tr >
                                                            <td>
                                                                <div className='d-inline-flex align-items-center'>
                                                                    {/* <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" /> */}
                                                                    <span className='ms-2'>
                                                                        {data?.firstName + ' ' + data?.lastName}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td>{data?.role?.role_name}</td>
                                                            <td>{data?.isActive ? 'Active' : 'Inactive'}</td>
                                                        </tr>
                                                    ))}
                                                {/* <tr>
                                                    <td>
                                                        <div className='d-inline-flex align-items-center'>
                                                            <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" />
                                                            <span className='ms-2'>
                                                                info
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>CEO</td>
                                                    <td>Active</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='d-inline-flex align-items-center'>
                                                            <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" />
                                                            <span className='ms-2'>
                                                                johndoe
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>Manager</td>
                                                    <td>Yet to confirm.</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='d-inline-flex align-items-center'>
                                                            <img className='opacity-50' src={userAvatar} width={30} height={30} alt="profile-icon" />
                                                            <span className='ms-2'>
                                                                dexat50815
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>Manager</td>
                                                    <td>Yet to confirm.</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // View User Modal // */}

                {/* Delete confirmation modal*/}
                <div className="modal fade" id="deleteAlertModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="curDeactivateAlertLabel" aria-hidden="true">
                    <div className="modal-dialog alert-modal">
                        {allUsers?.length > 0 && allUsers?.filter((item) => item.Profile?._id == profileId).length > 0 ? (
                            <div className="modal-content">
                                <div className="modal-body">
                                    <h4 className='pb-3'>Delete Profile</h4>
                                    <p className='fw-semibold'>
                                        There are other users associated with this profile. Please transfer the users to another profile and then delete it.
                                    </p>

                                    <div className="row mb-3">
                                        <label htmlFor="cloneProfile" className="col-auto col-md-3 text-md-end col-form-label">Transfer user(s) to</label>
                                        <div className="col-auto col-md-9 col-xl-7 input-control">
                                            <select className="form-select" id="cloneProfile"
                                                name='transferProfileId' value={transferProfileId}
                                                onChange={(e) => setTransferProfileId(e.target.value)}
                                            >
                                                <option value="">Select Profile</option>
                                                {
                                                    currentProfiles?.profileData?.length > 0 && currentProfiles?.profileData?.
                                                        filter((item) => item.profile_name !== selectedProfile)
                                                        .map((data) => (
                                                            <option value={data?._id}>{data?.profile_name}</option>
                                                        ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-top-0">
                                    <button type="button" className="btn btn-outline-secondary rounded-pill flat-btn text-secondary"
                                        onClick={() => { setProfileId(''); setSelectedProfile('') }} data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-danger rounded-pill btn-blue" data-bs-dismiss="modal"
                                        onClick={() => handleTransferDelete()}
                                    >
                                        Transfer and Delete
                                    </button>
                                </div>
                            </div>
                        ) :
                            (
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <h4 className='pb-3'>Delete Profile</h4>
                                        <p className='fw-semibold'>
                                            Are you sure you want to delete this profile?
                                        </p>
                                    </div>
                                    <div className="modal-footer border-top-0">
                                        <button type="button" className="btn btn-outline-secondary rounded-pill flat-btn text-secondary"
                                            onClick={() => { setProfileId(''); setSelectedProfile('') }} data-bs-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-danger rounded-pill btn-blue" data-bs-dismiss="modal" onClick={() => handleDelete()}>
                                            Yes, Delete
                                        </button>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                {/* Delete confirmation modal */}

            </div >
        </>
    )
}
export default Profiles;