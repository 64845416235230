import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        cards: {
            isLoading: false,
            cards: [],
            card: null,
            error: null,
            message: null
        },
        dashboard: {
            isLoading: false,
            cards: [],
            graphs: [],
            tables: [],
            card: null,
            error: null,
            message: null
        },
        isLoading: false,
        error: null,
        message: null
    },
    reducers: {
        getDashboardStart: (state) => {
            state.dashboard.isLoading = true;
        },
        getDashboardSuccess: (state, action) => {
            state.dashboard.cards = action?.payload?.data?.cards;
            state.dashboard.message = action?.payload?.message;
            state.dashboard.isLoading = false;
        },
        getDashboardFailure: (state, action) => {
            state.dashboard.isLoading = false;
        },
        getDashboardGraphStart: (state, action) => {
            state.dashboard.isLoading = true;
        },
        getDashboardGraphSuccess: (state, action) => {
            state.dashboard.graphs = action?.payload?.data;
            state.message = action?.payload?.message;
            state.dashboard.isLoading = false;
        },
        getDashboardGraphFailed: (state, action) => {
            state.dashboard.isLoading = false;
            state.error = action?.payload;
        }
    }
});

export const {
    getDashboardStart,
    getDashboardSuccess,
    getDashboardFailure,
    getDashboardGraphStart,
    getDashboardGraphSuccess,
    getDashboardGraphFailed
} = dashboardSlice?.actions;

export default dashboardSlice?.reducer;