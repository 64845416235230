import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createShiftHoursStart, deleteShiftHoursStart, getShiftHoursStart, updateShiftHoursStart } from "../../../Store/Slices/shiftHoursSlice";
import Loader from "../../../Components/Loader";
import { Utils} from "../../../Utils";

const EditShiftHours = ({ showBHFn }) => {
    const dispatch = useDispatch();
    let companyId = useSelector((state) => state?.org)?.companyData?.[0]?._id;
    let businessHour = useSelector((state) => state?.businesshours)?.businessData?.[0];
    let businessHourId = businessHour?._id;
    let currentShiftHour = useSelector((state) => state?.shifthours);
    let allUsers = useSelector((state) => state?.user);
    let deleteShiftId = "";

    // pre-defined array's
    const orderedWeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const weekDays = orderedWeekDays;
    const weekDayTime = [
        { day: 'Monday', startTime: '', endTime: '' },
        { day: 'Tuesday', startTime: '', endTime: '' },
        { day: 'Wednesday', startTime: '', endTime: '' },
        { day: 'Thursday', startTime: '', endTime: '' },
        { day: 'Friday', startTime: '', endTime: '' },
        { day: 'Saturday', startTime: '', endTime: '' },
        { day: 'Sunday', startTime: '', endTime: '' }
    ];
    const timeZoneData = [
        '(GMT -12:0) GMT-12:00 (Etc/GMT+12)',
        '(GMT - 11: 0) GMT - 11:00(Etc / GMT + 11)',
        '(GMT 5: 30) India Standard Time(Asia / Colombo)',
        '(GMT 5: 30) India Standard Time(Asia / Kolkata)',
        '(GMT 5: 30) India Standard Time(IST)',
        '(GMT 5: 45) Nepal Time(Asia / Kathmandu)',
        '(GMT 5: 45) Nepal Time(Asia / Katmandu)',
        '(GMT 6: 0) Kirgizstan Time(Asia / Bishkek)',
        '(GMT 6: 0) Bangladesh Time(Asia / Dacca)',
        '(GMT 6: 0) Bangladesh Time(Asia / Dhaka)',
        '(GMT 6: 0) Xinjiang Standard Time(Asia / Kashgar)',
        '(GMT 6: 0) Omsk Time(Asia / Omsk)',
        '(GMT 6: 0) Bhutan Time(Asia / Thimbu)',
        '(GMT 6: 0) Bhutan Time(Asia / Thimphu)'
    ]

    // states
    const [value, setValue] = useState('');
    const [validForm, setValidForm] = useState(false)
    const [showShiftHour, setShowShiftHour] = useState(true);
    const [shiftHoursData, setShiftHoursData] = useState({
        shift_name: '',
        timeZone: '(GMT 5: 30) India Standard Time(Asia / Kolkata)',
        shiftHours: 'same',
        shiftHoursManagementData: {
            sameHoursData: {
                shiftDays: [],
                startTime: '9:00 AM',
                endTime: '5:00 PM'
            },
            differentHoursData: {
                shiftDays: []
            },
            breakHours1: 'none',
            breakHours1ManagementData: {
                sameBreakHours1Data: {
                    shiftDays: [],
                    startTime: '9:00 AM',
                    endTime: '5:00 PM'
                },
                differentBreakHours1Data: {
                    shiftDays: []
                },
            },
            breakHours2: 'none',
            breakHours2ManagementData: {
                sameBreakHours2Data: {
                    shiftDays: [],
                    startTime: '9:00 AM',
                    endTime: '5:00 PM'
                },
                differentBreakHours2Data: {
                    shiftDays: []
                },
            }
        },
        assignedUsers: []
    });
    const [isEdit, setIsEdit] = useState(false)
    const [selectedShiftId, setSelectedShiftId] = useState("")

    // useeffect
    useEffect(() => {
        if (currentShiftHour.createStatus ||
            currentShiftHour.reload ||
            currentShiftHour.updateStatus) {
            dispatch(getShiftHoursStart(businessHour._id));
            deleteShiftId = '';
            setValidForm(false);
            if (currentShiftHour.createStatus || currentShiftHour.updateStatus) {
                setShowShiftHour(true);
                // showBHFn(prev => !prev);
                showBHFn(true);
                setInitialState();
            }
        }
    }, [currentShiftHour.createStatus, currentShiftHour.reload, currentShiftHour.updateStatus])

    useEffect(() => {
        if (shiftHoursData.shift_name != '') {
            setValidForm(true);
        }
    }, [shiftHoursData.shift_name])

    useEffect(() => {
        if (businessHour) {
            if (businessHour.businessHours == '24x7') {
                const weekdayList = [
                    { day: 'Monday', startTime: '9:00 AM', endTime: '5:00 PM' },
                    { day: 'Tuesday', startTime: '9:00 AM', endTime: '5:00 PM' },
                    { day: 'Wednesday', startTime: '9:00 AM', endTime: '5:00 PM' },
                    { day: 'Thursday', startTime: '9:00 AM', endTime: '5:00 PM' },
                    { day: 'Friday', startTime: '9:00 AM', endTime: '5:00 PM' },
                    { day: 'Saturday', startTime: '9:00 AM', endTime: '5:00 PM' },
                    { day: 'Sunday', startTime: '9:00 AM', endTime: '5:00 PM' }
                ];
                setStateOfBH(weekDays, weekdayList);
            }

            if (businessHour.businessHours == '24x5') {
                const weekDays = businessHour.businessHoursManagementData.businessHours5Days.businessDays;
                const weekdayList = weekDays.map(item => { return { day: item, startTime: '9:00 AM', endTime: '5:00 PM' } });
                setStateOfBH(weekDays, weekdayList);
            }

            if (businessHour.businessHours == 'custom') {
                if (businessHour.businessHoursManagementData.businessHoursCustom.businessTiming == 'same') {
                    const weekDays = businessHour.businessHoursManagementData.businessHoursCustom.businessTimingSame.businessDays;
                    const weekdayList = weekDays.map(item => { return { day: item, startTime: '9:00 AM', endTime: '5:00 PM' } });
                    setStateOfBH(weekDays, weekdayList);
                }
                if (businessHour.businessHoursManagementData.businessHoursCustom.businessTiming == 'diff') {
                    const weekdayList = businessHour.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays;
                    const weekDays = weekdayList.map(item => item.day);
                    setStateOfBH(weekDays, weekdayList);
                }
            }
        }
    }, [businessHour]);

    // functions
    const generateTimeOptions = () => {
        const times = [];
        const periods = ['AM', 'PM'];
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 30) {
                const hour = i === 0 || i === 12 ? 12 : i % 12;
                const minute = j === 0 ? '00' : '30';
                const period = i < 12 ? periods[0] : periods[1];
                const time = `${hour}:${minute} ${period}`;
                times.push(<option key={time} value={time}>{time}</option>);
            }
        }
        return times;
    };

    const setStateOfBH = (weekDays, weekdayList) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                sameHoursData: {
                    ...prev.shiftHoursManagementData.sameHoursData,
                    shiftDays: weekDays
                },
                differentHoursData: {
                    ...prev.shiftHoursManagementData.differentHoursData,
                    shiftDays: weekdayList
                },
                breakHours1ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours1ManagementData,
                    sameBreakHours1Data: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData.sameBreakHours1Data,
                        shiftDays: weekDays
                    },
                    differentBreakHours1Data: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data,
                        shiftDays: weekdayList
                    }
                },
                breakHours2ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours2ManagementData,
                    sameBreakHours2Data: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData.sameBreakHours2Data,
                        shiftDays: weekDays
                    },
                    differentBreakHours2Data: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data,
                        shiftDays: weekdayList
                    }
                }
            }
        }));
    }

    const orderPayloads = () => {
        let payload = shiftHoursData;
        // 1st level states
        if (!shiftHoursData.assignedUsers.length) {
            const { assignedUsers, ...rest } = shiftHoursData;
            payload = rest
        }
        // 2nd level states
        if (payload.shiftHours == 'same') {
            const { differentHoursData, ...rest } = payload.shiftHoursManagementData;
            payload.shiftHoursManagementData = rest
        }
        if (payload.shiftHours == 'diff') {
            const { sameHoursData, ...rest } = payload.shiftHoursManagementData;
            payload.shiftHoursManagementData = rest
        }
        if (payload.shiftHoursManagementData.breakHours1 == 'none') {
            const { breakHours1ManagementData, ...rest } = payload.shiftHoursManagementData;
            payload.shiftHoursManagementData = rest
        }
        if (payload.shiftHoursManagementData.breakHours2 == 'none') {
            const { breakHours2ManagementData, ...rest } = payload.shiftHoursManagementData;
            payload.shiftHoursManagementData = rest
        }
        // 3rd level states
        if (payload.shiftHoursManagementData.breakHours1 == 'same') {
            const { differentBreakHours1Data, ...rest } = payload.shiftHoursManagementData.breakHours1ManagementData;
            payload.shiftHoursManagementData.breakHours1ManagementData = rest
        }
        if (payload.shiftHoursManagementData.breakHours1 == 'diff') {
            const { sameBreakHours1Data, ...rest } = payload.shiftHoursManagementData.breakHours1ManagementData;
            payload.shiftHoursManagementData.breakHours1ManagementData = rest
        }
        if (payload.shiftHoursManagementData.breakHours2 == 'same') {
            const { differentBreakHours2Data, ...rest } = payload.shiftHoursManagementData.breakHours2ManagementData;
            payload.shiftHoursManagementData.breakHours2ManagementData = rest
        }
        if (payload.shiftHoursManagementData.breakHours2 == 'diff') {
            const { sameBreakHours2Data, ...rest } = payload.shiftHoursManagementData.breakHours2ManagementData;
            payload.shiftHoursManagementData.breakHours2ManagementData = rest
        }
        return payload;
    }

    const setInitialState = () => {
        setShiftHoursData((prev) => ({
            ...prev,
            shift_name: '',
            timeZone: '(GMT 5: 30) India Standard Time(Asia / Kolkata)',
            shiftHours: 'same',
            assignedUsers: [],
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                sameHoursData: {
                    shiftDays: [],
                    startTime: '9:00 AM',
                    endTime: '5:00 PM'
                },
                differentHoursData: {
                    shiftDays: []
                },
                breakHours1: '',
                breakHours1ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours1ManagementData,
                    sameBreakHours1Data: {
                        shiftDays: [],
                        startTime: '9:00 AM',
                        endTime: '5:00 PM'
                    },
                    differentBreakHours1Data: {
                        shiftDays: []
                    },
                },
                breakHours2: '',
                breakHours2ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours2ManagementData,
                    sameBreakHours2Data: {
                        shiftDays: [],
                        startTime: '9:00 AM',
                        endTime: '5:00 PM'
                    },
                    differentBreakHours2Data: {
                        shiftDays: []
                    },
                }
            },
        }));
        setWeekdays();
    }

    const setWeekdays = () => {
        if (businessHour.businessHours == '24x7') {
            const weekdayList = [
                { day: 'Monday', startTime: '9:00 AM', endTime: '5:00 PM' },
                { day: 'Tuesday', startTime: '9:00 AM', endTime: '5:00 PM' },
                { day: 'Wednesday', startTime: '9:00 AM', endTime: '5:00 PM' },
                { day: 'Thursday', startTime: '9:00 AM', endTime: '5:00 PM' },
                { day: 'Friday', startTime: '9:00 AM', endTime: '5:00 PM' },
                { day: 'Saturday', startTime: '9:00 AM', endTime: '5:00 PM' },
                { day: 'Sunday', startTime: '9:00 AM', endTime: '5:00 PM' }
            ];
            setStateOfBH(weekDays, weekdayList);
        }

        if (businessHour.businessHours == '24x5') {
            const weekDays = businessHour.businessHoursManagementData.businessHours5Days.businessDays;
            const weekdayList = weekDays.map(item => { return { day: item, startTime: '9:00 AM', endTime: '5:00 PM' } });
            setStateOfBH(weekDays, weekdayList);
        }

        if (businessHour.businessHours == 'custom') {
            if (businessHour.businessHoursManagementData.businessHoursCustom.businessTiming == 'same') {
                const weekDays = businessHour.businessHoursManagementData.businessHoursCustom.businessTimingSame.businessDays;
                const weekdayList = weekDays.map(item => { return { day: item, startTime: '9:00 AM', endTime: '5:00 PM' } });
                setStateOfBH(weekDays, weekdayList);
            }
            if (businessHour.businessHoursManagementData.businessHoursCustom.businessTiming == 'diff') {
                const weekdayList = businessHour.businessHoursManagementData.businessHoursCustom.businessTimingDifferent.businessDays;
                const weekDays = weekdayList.map(item => item.day);
                setStateOfBH(weekDays, weekdayList);
            }
        }
    }

    // handler
    const showSHHandler = (value) => {
        // showBHFn(prev => !prev)
        showBHFn(value);
    }

    const handleCheckboxChange = (value) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                sameHoursData: {
                    ...prev.shiftHoursManagementData.sameHoursData,
                    shiftDays:
                        prev.shiftHoursManagementData.sameHoursData.shiftDays.includes(value) ? prev.shiftHoursManagementData.sameHoursData.shiftDays.filter((itemId) => itemId !== value)
                            : [...prev.shiftHoursManagementData.sameHoursData.shiftDays, value]
                }
            }
        }))
    }

    const handleCheckboxChangeBreakHour1 = (value) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                breakHours1ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours1ManagementData,
                    sameBreakHours1Data: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData.sameBreakHours1Data,
                        shiftDays:
                            prev.shiftHoursManagementData.breakHours1ManagementData.sameBreakHours1Data.shiftDays.includes(value) ? prev.shiftHoursManagementData.breakHours1ManagementData.sameBreakHours1Data.shiftDays.filter((itemId) => itemId !== value)
                                : [...prev.shiftHoursManagementData.breakHours1ManagementData.sameBreakHours1Data.shiftDays, value]
                    }
                }
            }
        }))
    }

    const handleCheckboxChangeBreakHour2 = (value) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                breakHours2ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours2ManagementData,
                    sameBreakHours2Data: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData.sameBreakHours2Data,
                        shiftDays:
                            prev.shiftHoursManagementData.breakHours2ManagementData.sameBreakHours2Data.shiftDays.includes(value) ? prev.shiftHoursManagementData.breakHours2ManagementData.sameBreakHours2Data.shiftDays.filter((itemId) => itemId !== value)
                                : [...prev.shiftHoursManagementData.breakHours2ManagementData.sameBreakHours2Data.shiftDays, value]
                    }
                }
            }
        }))
    }

    const handleCheckboxChange1 = (item) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                differentHoursData: {
                    ...prev.shiftHoursManagementData.differentHoursData,
                    shiftDays:
                        prev.shiftHoursManagementData.differentHoursData.shiftDays.some((selectedItem) => selectedItem.day.includes(item.day)) ? prev.shiftHoursManagementData.differentHoursData.shiftDays.filter((itemId) => itemId.day !== item.day)
                            : [...prev.shiftHoursManagementData.differentHoursData.shiftDays, { day: item.day, startTime: '9:00 AM', endTime: '5:00 PM' }]
                }
            }
        }))
    };

    const handleInputChange1 = (day, e) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                differentHoursData: {
                    ...prev.shiftHoursManagementData.differentHoursData,
                    shiftDays: prev.shiftHoursManagementData.differentHoursData.shiftDays.map((dta, i) =>
                        dta.day == day ? {
                            ...dta,
                            [e.target.name]: e.target.value
                        } : dta
                    )
                }
            }
        }))
    };

    const handleCheckboxChange1BreakHour1 = (item) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                breakHours1ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours1ManagementData,
                    differentBreakHours1Data: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data,
                        shiftDays:
                            prev.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data.shiftDays.some((selectedItem) => selectedItem.day.includes(item.day)) ? prev.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data.shiftDays.filter((itemId) => itemId.day !== item.day)
                                : [...prev.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data.shiftDays, { day: item.day, startTime: '9:00 AM', endTime: '5:00 PM' }]
                    }
                }
            }
        }))
    };

    const handleInputChange1BreakHour1 = (day, e) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                breakHours1ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours1ManagementData,
                    differentBreakHours1Data: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data,
                        shiftDays: prev.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data.shiftDays.map((dta, i) =>
                            dta.day == day ? {
                                ...dta,
                                [e.target.name]: e.target.value
                            } : dta
                        )
                    }
                }
            }
        }))
    };

    const handleCheckboxChange1BreakHour2 = (item) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                breakHours2ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours2ManagementData,
                    differentBreakHours2Data: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data,
                        shiftDays:
                            prev.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data.shiftDays.some((selectedItem) => selectedItem.day.includes(item.day)) ? prev.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data.shiftDays.filter((itemId) => itemId.day !== item.day)
                                : [...prev.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data.shiftDays, { day: item.day, startTime: '9:00 AM', endTime: '5:00 PM' }]
                    }
                }
            }
        }))
    };

    const handleInputChange1BreakHour2 = (day, e) => {
        setShiftHoursData((prev) => ({
            ...prev,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                breakHours2ManagementData: {
                    ...prev.shiftHoursManagementData.breakHours2ManagementData,
                    differentBreakHours2Data: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data,
                        shiftDays: prev.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data.shiftDays.map((dta, i) =>
                            dta.day == day ? {
                                ...dta,
                                [e.target.name]: e.target.value
                            } : dta
                        )
                    }
                }
            }
        }))
    };

    const handleChangeAssignedUsers = (item) => {
        setShiftHoursData((prev) => ({
            ...prev,
            assignedUsers:
                prev.assignedUsers?.some((selectedItem) => selectedItem._id.includes(item._id)) ? prev.assignedUsers.filter((itemId) => itemId._id !== item._id)
                    : [...prev.assignedUsers, item]
        }))
    };

    const handleAddRemoveUser = (e) => {
        if (allUsers?.allUserData?.length > 0) {
            if (e.target.checked == true)
                setShiftHoursData((prev) => ({
                    ...prev,
                    assignedUsers: allUsers?.allUserData
                }));
            else
                setShiftHoursData((prev) => ({
                    ...prev,
                    assignedUsers: []
                }));
        }
    }

    const formHandler = (e) => {
        try {
            if (e.target.name == 'shift_name' || e.target.name == 'timeZone' || e.target.name == 'shiftHours') {
                setShiftHoursData((prev) => ({
                    ...prev,
                    [e?.target?.name]: e?.target?.value
                }));
            }
            if (e.target.name == 'startTime' || e.target.name == 'endTime') {
                setShiftHoursData((prev) => ({
                    ...prev,
                    shiftHoursManagementData: {
                        ...prev.shiftHoursManagementData,
                        sameHoursData: {
                            ...prev.shiftHoursManagementData.sameHoursData,
                            [e?.target?.name]: e?.target?.value
                        }
                    }
                }));
            }
            if (e.target.name == 'breakHours1' || e.target.name == 'breakHours2') {
                setShiftHoursData((prev) => ({
                    ...prev,
                    shiftHoursManagementData: {
                        ...prev.shiftHoursManagementData,
                        [e?.target?.name]: e?.target?.value
                    }
                }));
            }
        } catch (err) {
            throw err
        }
    }

    const formHandlerBreakHour1 = (e) => {
        try {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    breakHours1ManagementData: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData,
                        sameBreakHours1Data: {
                            ...prev.shiftHoursManagementData.breakHours1ManagementData.sameBreakHours1Data,
                            [e?.target?.name]: e?.target?.value
                        }
                    }
                }
            }));
        } catch (err) {
            throw err
        }
    }

    const formHandlerBreakHour2 = (e) => {
        try {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    breakHours2ManagementData: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData,
                        sameBreakHours2Data: {
                            ...prev.shiftHoursManagementData.breakHours2ManagementData.sameBreakHours2Data,
                            [e?.target?.name]: e?.target?.value
                        }
                    }
                }
            }));
        } catch (err) {
            throw err
        }
    }

    const handleSubmit = () => {
        // let payload = shiftHoursData;
        // // 1st level states
        // if (!shiftHoursData.assignedUsers.length) {
        //     const { assignedUsers, ...rest } = shiftHoursData;
        //     payload = rest
        // }
        // // 2nd level states
        // if (payload.shiftHours == 'same') {
        //     const { differentHoursData, ...rest } = payload.shiftHoursManagementData;
        //     payload.shiftHoursManagementData = rest
        // }
        // if (payload.shiftHours == 'diff') {
        //     const { sameHoursData, ...rest } = payload.shiftHoursManagementData;
        //     payload.shiftHoursManagementData = rest
        // }
        // if (payload.shiftHoursManagementData.breakHours1 == 'none') {
        //     const { breakHours1ManagementData, ...rest } = payload.shiftHoursManagementData;
        //     payload.shiftHoursManagementData = rest
        // }
        // if (payload.shiftHoursManagementData.breakHours2 == 'none') {
        //     const { breakHours2ManagementData, ...rest } = payload.shiftHoursManagementData;
        //     payload.shiftHoursManagementData = rest
        // }
        // // 3rd level states
        // if (payload.shiftHoursManagementData.breakHours1 == 'same') {
        //     const { differentBreakHours1Data, ...rest } = payload.shiftHoursManagementData.breakHours1ManagementData;
        //     payload.shiftHoursManagementData.breakHours1ManagementData = rest
        // }
        // if (payload.shiftHoursManagementData.breakHours1 == 'diff') {
        //     const { sameBreakHours1Data, ...rest } = payload.shiftHoursManagementData.breakHours1ManagementData;
        //     payload.shiftHoursManagementData.breakHours1ManagementData = rest
        // }
        // if (payload.shiftHoursManagementData.breakHours2 == 'same') {
        //     const { sameBreakHours2Data, ...rest } = payload.shiftHoursManagementData.breakHours2ManagementData;
        //     payload.shiftHoursManagementData.breakHours2ManagementData = rest
        // }
        // if (payload.shiftHoursManagementData.breakHours2 == 'diff') {
        //     const { differentBreakHours2Data, ...rest } = payload.shiftHoursManagementData.breakHours2ManagementData;
        //     payload.shiftHoursManagementData.breakHours2ManagementData = rest
        // }



        // if (shiftHoursData.shiftHoursManagementData.breakHours1 == 'none') {
        //     const updatedObj = shiftHoursData.shiftHoursManagementData = Object.fromEntries(
        //         Object.entries(shiftHoursData.shiftHoursManagementData).
        //             filter(([key]) => key !== 'breakHours1' && key !== 'breakHours1ManagementData')
        //     );
        // }
        // if (shiftHoursData.shiftHoursManagementData.breakHours2 == 'none') {
        //     const updatedObj = shiftHoursData.shiftHoursManagementData = Object.fromEntries(
        //         Object.entries(shiftHoursData.shiftHoursManagementData).
        //             filter(([key]) => key !== 'breakHours2' && key !== 'breakHours2ManagementData')
        //     );
        // }
        // if (shiftHoursData?.shiftHours == 'same') {
        //     const updatedObj = shiftHoursData.shiftHoursManagementData = Object.fromEntries(
        //         Object.entries(shiftHoursData.shiftHoursManagementData).
        //             filter(([key]) => key !== 'differentHoursData')
        //     );
        // }
        let payload = orderPayloads();
        payload['organization'] = companyId
        payload['business_hours_id'] = businessHourId
        // payload['shift_name'] = payload.shift_name
        dispatch(createShiftHoursStart(payload))
        // }
    }

    const handleUpdate = () => {
        let payload = orderPayloads();
        const oldData = currentShiftHour?.shiftData?.find(shift => shift._id === selectedShiftId) || {};
        const auditLog = Utils.logAuditLog(oldData, payload, true);
        payload.audit_log = auditLog;
        dispatch(updateShiftHoursStart({ payload: payload, id: selectedShiftId }))
    }

    const handleDelete = () => {
        if (deleteShiftId) {
            dispatch(deleteShiftHoursStart(deleteShiftId))
        }
    }

    const handleEdit = (data) => {
        setSelectedShiftId(data?._id)
        setIsEdit(true);
        setShowShiftHour(false);
        // showBHFn(prev => !prev);
        showBHFn(false);
        setShiftHoursData((prev) => ({
            ...prev,
            shift_name: data.shift_name,
            timeZone: data.timeZone,
            shiftHours: data.shiftHours,
            shiftHoursManagementData: {
                ...prev.shiftHoursManagementData,
                breakHours1: data.shiftHoursManagementData?.breakHours1,
                breakHours2: data.shiftHoursManagementData?.breakHours2,
            }
        }));
        if (data.shiftHours == 'same') {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    sameHoursData: data.shiftHoursManagementData?.sameHoursData
                }
            }))
        }
        if (data.shiftHours == 'diff') {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    differentHoursData: data.shiftHoursManagementData?.differentHoursData
                }
            }))
        }
        if (data.shiftHoursManagementData.breakHours1 == 'same') {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    breakHours1ManagementData: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData,
                        sameBreakHours1Data: data.shiftHoursManagementData.breakHours1ManagementData.sameBreakHours1Data
                    }
                }
            }))
        }
        if (data.shiftHoursManagementData.breakHours1 == 'diff') {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    breakHours1ManagementData: {
                        ...prev.shiftHoursManagementData.breakHours1ManagementData,
                        differentBreakHours1Data: data.shiftHoursManagementData.breakHours1ManagementData.differentBreakHours1Data
                    }
                }
            }))
        }
        if (data.shiftHoursManagementData.breakHours2 == 'same') {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    breakHours2ManagementData: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData,
                        sameBreakHours2Data: data.shiftHoursManagementData.breakHours2ManagementData.sameBreakHours2Data
                    }
                }
            }))
        }
        if (data.shiftHoursManagementData.breakHours2 == 'diff') {
            setShiftHoursData((prev) => ({
                ...prev,
                shiftHoursManagementData: {
                    ...prev.shiftHoursManagementData,
                    breakHours2ManagementData: {
                        ...prev.shiftHoursManagementData.breakHours2ManagementData,
                        differentBreakHours2Data: data.shiftHoursManagementData.breakHours2ManagementData.differentBreakHours2Data
                    }
                }
            }))
        }
        if (data?.assignedUsers?.length > 0) {
            setShiftHoursData((prev) => ({
                ...prev,
                assignedUsers: data.assignedUsers
            }))
        }
    }

    // ui
    return (
        <React.Fragment>
            <Loader isVisible={currentShiftHour?.isLoading} />
            {showShiftHour ?
                <>
                    <h5 className="fw-semibold">Shift hours</h5>
                    <div className="d-flex flex-row mb-4">
                        <div className="left-con w-75">
                            <p className='mb-0'>
                                Create shift hours and assign shifts based on employee's work hours or time zone.
                                Shift hours enables you to assign activities based on user's availability.
                            </p>
                        </div>
                        <div className="right-con d-flex flex-column align-items-end ms-auto">
                            <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3"
                                onClick={() => {
                                    setShowShiftHour(false); showSHHandler(false); setSelectedShiftId('')
                                    setIsEdit(false); setInitialState();
                                }}>
                                + New shift hours
                            </button>
                        </div>
                    </div>
                    <div className="tbl-container rounded-table d-block">
                        <div className="table-responsive">
                            <table className="table table-hover hover-icon-table shift-hour-table mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Shift name</th>
                                        <th>Shift days</th>
                                        <th>Shift timing</th>
                                        <th>Time Zone</th>
                                        <th>No. of users</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentShiftHour?.shiftData?.length > 0 ?
                                            <>
                                                {
                                                    currentShiftHour?.shiftData?.map((data) => (
                                                        <tr>
                                                            <td className="text-center">
                                                                <i className="fa-regular fa-trash-can hover-icon delete-icon"
                                                                    onClick={() => { deleteShiftId = data._id }}
                                                                    data-bs-toggle="modal" data-bs-target="#confirmAlertModal"
                                                                ></i>
                                                            </td>
                                                            <td><a onClick={() => handleEdit(data)}>{data?.shift_name}</a></td>
                                                            <td>
                                                                {data?.shiftHours == 'same' &&
                                                                    data?.shiftHoursManagementData?.sameHoursData?.shiftDays?.join(', ')
                                                                }
                                                                {data?.shiftHours == 'diff' &&
                                                                    data?.shiftHoursManagementData?.differentHoursData?.shiftDays?.map(item => { return <div>{item.day}</div> })
                                                                }
                                                            </td>
                                                            <td>
                                                                {data?.shiftHours == 'same' &&
                                                                    data?.shiftHoursManagementData?.sameHoursData?.startTime + '-' + data?.shiftHoursManagementData?.sameHoursData?.endTime
                                                                }
                                                                {data?.shiftHours == 'diff' &&
                                                                    data?.shiftHoursManagementData?.differentHoursData?.shiftDays?.map(item => {
                                                                        return <div>{item?.startTime + '-' + item?.endTime}</div>
                                                                    })
                                                                }
                                                            </td>
                                                            <td>{data?.timeZone}</td>
                                                            <td><a >{data?.assignedUsers?.length || 0}</a></td>
                                                        </tr>
                                                    ))
                                                }
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={6} className="text-center">No data found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Confirmation Alert Modal */}
                    <div className="modal fade" id="confirmAlertModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="confirmAlertModalLabel" aria-hidden="true">
                        <div className="modal-dialog alert-modal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className='fs-6'>
                                        Are you sure you want to delete the <span className='fs-6 fw-semibold'>Shift</span> ?
                                    </p>
                                </div>
                                <div className="modal-footer border-top-0">
                                    <button type="button" className="btn btn-outline-secondary rounded-pill flat-btn text-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-danger rounded-pill btn-blue" onClick={() => handleDelete()} data-bs-dismiss="modal">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // Confirmation Alert Modal // */}
                </>
                :
                <>
                    {/* New Shift Hours Form */}
                    <div className='new-shift-form mt-4'>
                        <h5 className="fw-semibold">{isEdit ? 'Edit' : 'Create'} Shift hours</h5>
                        <div className="row mb-3">
                            <label htmlFor="shift_name" className="col-sm-2 col-form-label text-end">Shift Name</label>
                            <div className="col-sm-8 col-xl-5">
                                <input type="text" className="form-control" id="shift_name" required
                                    name="shift_name" value={shiftHoursData.shift_name}
                                    onChange={formHandler} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="timeZone" className="col-sm-2 col-form-label text-end">Time Zone</label>
                            <div className="col-sm-8 col-xl-5">
                                <select className="form-select" id="timeZone" aria-label="Time Zone"
                                    name="timeZone" value={shiftHoursData.timeZone}
                                    onChange={formHandler}>
                                    {
                                        timeZoneData.map((time) => (
                                            <option key={time} value={time}>{time}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <legend className="col-sm-2 col-form-label text-end">Shift hours</legend>
                            <div className="col-sm-8 col-xl-5">
                                <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input mt-0" type="radio" name="shiftHours" id="shiftHourRadio1"
                                        value="same" onChange={formHandler} checked={shiftHoursData?.shiftHours == "same"} />
                                    <label className="form-check-label ms-2 me-3 cursor-pointer" htmlFor="shiftHourRadio1">
                                        Same hours every day
                                    </label>
                                    {shiftHoursData?.shiftHours == 'same' &&
                                        <>
                                            <select className="form-select form-select-sm w-auto"
                                                onChange={formHandler}
                                                name="startTime"
                                                value={shiftHoursData?.shiftHoursManagementData?.sameHoursData?.startTime}>
                                                {generateTimeOptions()}
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm w-auto" onChange={formHandler}
                                                name="endTime"
                                                value={shiftHoursData?.shiftHoursManagementData?.sameHoursData?.endTime}>
                                                {generateTimeOptions()}
                                            </select>
                                        </>
                                    }
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="shiftHours" id="shiftHourRadio2"
                                        value="diff" onChange={formHandler} checked={shiftHoursData?.shiftHours == "diff"} />
                                    <label className="form-check-label cursor-pointer" htmlFor="shiftHourRadio2">
                                        Different hours every day
                                    </label>
                                </div>
                            </div>
                        </div>
                        {shiftHoursData.shiftHours == 'same' &&
                            <div className="row mb-3">
                                <legend className="col-sm-2 col-form-label text-end pt-1">Shift days</legend>
                                <div className="col-sm-8 col-xl-5">
                                    {weekDays?.map((day) => (
                                        <div className="form-check d-flex align-items-center mb-3" key={day}>
                                            <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${day}`}
                                                name="shiftDays"
                                                checked={shiftHoursData?.shiftHoursManagementData?.sameHoursData?.shiftDays?.includes(day)}
                                                onChange={() => handleCheckboxChange(day)} />
                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="shiftDaysCheckbox1">
                                                {day}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        {shiftHoursData.shiftHours == 'diff' &&
                            <div className="row mb-3">
                                <legend className="col-sm-2 col-form-label text-end pt-1">Shift days</legend>
                                <div className="col-sm-8 col-xl-5">
                                    {weekDayTime?.map((value, index) => (
                                        <div className="form-check d-flex align-items-center mb-3" key={value.day}>
                                            <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${value.day}`}
                                                name="day"
                                                value={value?.day}
                                                onChange={() => handleCheckboxChange1(value)}
                                                checked={shiftHoursData.shiftHoursManagementData?.differentHoursData?.shiftDays?.some((selectedItem) => selectedItem.day === value.day)}
                                            />
                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="shiftDaysCheckbox1">
                                                {value.day}
                                            </label>
                                            <select className="form-select form-select-sm"
                                                name="startTime"
                                                defaultValue="9:00 AM"
                                                value={shiftHoursData.shiftHoursManagementData?.differentHoursData?.shiftDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.startTime}
                                                onChange={(e) => handleInputChange1(value.day, e)}
                                            >
                                                {generateTimeOptions()}
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm"
                                                name="endTime"
                                                defaultValue="5:00 PM"
                                                value={shiftHoursData.shiftHoursManagementData?.differentHoursData?.shiftDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.endTime}
                                                onChange={(e) => handleInputChange1(value.day, e)}
                                            >
                                                {generateTimeOptions()}
                                            </select>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }

                        <div className="row mb-3">
                            <label htmlFor="breakHours1" className="col-sm-2 col-form-label text-end">Break hours 1</label>
                            <div className="col-sm-8 col-xl-5">
                                <select className="form-select" id="breakHours1" aria-label="Break hours 1"
                                    name="breakHours1" value={shiftHoursData?.shiftHoursManagementData?.breakHours1} onChange={formHandler}
                                >
                                    <option value="none">No break hours</option>
                                    <option value="same">Same hours every day</option>
                                    <option value="diff">Different hours every day</option>
                                </select>
                                {shiftHoursData?.shiftHoursManagementData?.breakHours1 == 'same' &&
                                    <>
                                        <div className='d-inline-flex mt-3 mb-3'>
                                            <select className="form-select form-select-sm"
                                                onChange={formHandlerBreakHour1}
                                                name="startTime"
                                                value={shiftHoursData?.shiftHoursManagementData?.breakHours1ManagementData?.sameBreakHours1Data?.startTime}
                                            >
                                                {generateTimeOptions()}
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm"
                                                onChange={formHandlerBreakHour1}
                                                name="endTime"
                                                value={shiftHoursData?.shiftHoursManagementData?.breakHours1ManagementData?.sameBreakHours1Data?.endTime}
                                            >
                                                {generateTimeOptions()}
                                            </select>
                                        </div>
                                        <div className='d-block'>
                                            {weekDays?.map((day) => (
                                                <div className="form-check d-flex align-items-center mb-3" key={day}>
                                                    <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${day}`}
                                                        name="shiftDays"
                                                        checked={shiftHoursData?.shiftHoursManagementData?.breakHours1ManagementData?.sameBreakHours1Data?.shiftDays?.includes(day)}
                                                        onChange={() => handleCheckboxChangeBreakHour1(day)}
                                                    />
                                                    <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="sameHoursCheckbox1">
                                                        {day}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                }
                                {shiftHoursData?.shiftHoursManagementData?.breakHours1 == 'diff' &&
                                    <>
                                        {weekDayTime?.map((value, index) => (
                                            <div className="form-check d-flex align-items-center mb-3" key={value.day}>
                                                <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${value.day}`}
                                                    name="day"
                                                    value={value?.day}
                                                    onChange={() => handleCheckboxChange1BreakHour1(value)}
                                                    checked={shiftHoursData.shiftHoursManagementData?.breakHours1ManagementData?.differentBreakHours1Data?.shiftDays?.some((selectedItem) => selectedItem.day === value.day)}
                                                />
                                                <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="shiftDaysCheckbox1">
                                                    {value.day}
                                                </label>
                                                <select className="form-select form-select-sm"
                                                    name="startTime"
                                                    defaultValue="9:00 AM"
                                                    value={shiftHoursData.shiftHoursManagementData?.breakHours1ManagementData?.differentBreakHours1Data?.shiftDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.startTime}
                                                    onChange={(e) => handleInputChange1BreakHour1(value.day, e)}
                                                >
                                                    {generateTimeOptions()}
                                                </select>
                                                <span className='form-check-label mx-3'>to</span>
                                                <select className="form-select form-select-sm"
                                                    name="endTime"
                                                    defaultValue="5:00 PM"
                                                    value={shiftHoursData.shiftHoursManagementData?.breakHours1ManagementData?.differentBreakHours1Data?.shiftDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.endTime}
                                                    onChange={(e) => handleInputChange1BreakHour1(value.day, e)}
                                                >
                                                    {generateTimeOptions()}
                                                </select>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="breakHours2" className="col-sm-2 col-form-label text-end">Break hours 2</label>
                            <div className="col-sm-8 col-xl-5">
                                <select className="form-select" id="breakHours2" aria-label="Break hours 2"
                                    name="breakHours2" value={shiftHoursData?.shiftHoursManagementData?.breakHours2} onChange={formHandler}>
                                    <option value="none">No break hours</option>
                                    <option value="same">Same hours every day</option>
                                    <option value="diff">Different hours every day</option>
                                </select>
                                {shiftHoursData?.shiftHoursManagementData?.breakHours2 == 'same' &&
                                    <>
                                        <div className='d-inline-flex mt-3 mb-3'>
                                            <select className="form-select form-select-sm"
                                                onChange={formHandlerBreakHour2}
                                                name="startTime"
                                                value={shiftHoursData?.shiftHoursManagementData?.breakHours2ManagementData?.sameBreakHours2Data?.startTime}
                                            >
                                                {generateTimeOptions()}
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm"
                                                onChange={formHandlerBreakHour2}
                                                name="endTime"
                                                value={shiftHoursData?.shiftHoursManagementData?.breakHours2ManagementData?.sameBreakHours2Data?.endTime}
                                            >
                                                {generateTimeOptions()}
                                            </select>
                                        </div>
                                        <div className='d-block'>
                                            {weekDays?.map((day) => (
                                                <div className="form-check d-flex align-items-center mb-3" key={day}>
                                                    <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${day}`}
                                                        name="shiftDays"
                                                        checked={shiftHoursData?.shiftHoursManagementData?.breakHours2ManagementData?.sameBreakHours2Data?.shiftDays?.includes(day)}
                                                        onChange={() => handleCheckboxChangeBreakHour2(day)}
                                                    />
                                                    <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="sameHoursCheckbox1">
                                                        {day}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                }
                                {shiftHoursData?.shiftHoursManagementData?.breakHours2 == 'diff' &&
                                    <>
                                        {weekDayTime?.map((value, index) => (
                                            <div className="form-check d-flex align-items-center mb-3" key={value.day}>
                                                <input className="form-check-input mt-0" type="checkbox" id={`checkbox-${value.day}`}
                                                    name="day"
                                                    value={value?.day}
                                                    onChange={() => handleCheckboxChange1BreakHour2(value)}
                                                    checked={shiftHoursData.shiftHoursManagementData?.breakHours2ManagementData?.differentBreakHours2Data?.shiftDays?.some((selectedItem) => selectedItem.day === value.day)}
                                                />
                                                <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="shiftDaysCheckbox1">
                                                    {value.day}
                                                </label>
                                                <select className="form-select form-select-sm"
                                                    name="startTime"
                                                    defaultValue="9:00 AM"
                                                    value={shiftHoursData.shiftHoursManagementData?.breakHours2ManagementData?.differentBreakHours2Data?.shiftDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.startTime}
                                                    onChange={(e) => handleInputChange1BreakHour2(value.day, e)}
                                                >
                                                    {generateTimeOptions()}
                                                </select>
                                                <span className='form-check-label mx-3'>to</span>
                                                <select className="form-select form-select-sm"
                                                    name="endTime"
                                                    defaultValue="5:00 PM"
                                                    value={shiftHoursData.shiftHoursManagementData?.breakHours2ManagementData?.differentBreakHours2Data?.shiftDays?.filter((selectedItem) => selectedItem.day === value.day)?.[0]?.endTime}
                                                    onChange={(e) => handleInputChange1BreakHour2(value.day, e)}
                                                >
                                                    {generateTimeOptions()}
                                                </select>
                                            </div>
                                        ))}
                                    </>
                                }
                                {/* <div className='d-block'>
                                        <div className="form-check d-flex align-items-center mb-3">
                                            <input checked className="form-check-input mt-0" type="checkbox" id="diffHoursCheckbox1" value="" />
                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="diffHoursCheckbox1">
                                                Monday
                                            </label>
                                            <select className="form-select form-select-sm">
                                                <option value="">9:00 AM</option>
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm">
                                                <option value="">5:00 PM</option>
                                            </select>
                                        </div>
                                        <div className="form-check d-flex align-items-center mb-3">
                                            <input checked className="form-check-input mt-0" type="checkbox" id="diffHoursCheckbox2" value="" />
                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="diffHoursCheckbox2">
                                                Tuesday
                                            </label>
                                            <select className="form-select form-select-sm">
                                                <option value="">9:00 AM</option>
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm">
                                                <option value="">5:00 PM</option>
                                            </select>
                                        </div>
                                        <div className="form-check d-flex align-items-center mb-3">
                                            <input checked className="form-check-input mt-0" type="checkbox" id="diffHoursCheckbox3" value="" />
                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="diffHoursCheckbox3">
                                                Wednesday
                                            </label>
                                            <select className="form-select form-select-sm">
                                                <option value="">9:00 AM</option>
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm">
                                                <option value="">5:00 PM</option>
                                            </select>
                                        </div>
                                        <div className="form-check d-flex align-items-center mb-3">
                                            <input checked className="form-check-input mt-0" type="checkbox" id="diffHoursCheckbox4" value="" />
                                            <label className="form-check-label ms-2 me-3 min-w-80px cursor-pointer" htmlFor="diffHoursCheckbox4">
                                                Thursday
                                            </label>
                                            <select className="form-select form-select-sm">
                                                <option value="">9:00 AM</option>
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm">
                                                <option value="">5:00 PM</option>
                                            </select>
                                        </div>
                                        <div className="form-check d-flex align-items-center mb-3">
                                            <input checked className="form-check-input mt-0" type="checkbox" id="diffHoursCheckbox6" value="" />
                                            <label className="form-check-label ms-2 me-3 min-w-80px" htmlFor="diffHoursCheckbox6">
                                                Friday
                                            </label>
                                            <select className="form-select form-select-sm">
                                                <option value="">9:00 AM</option>
                                            </select>
                                            <span className='form-check-label mx-3'>to</span>
                                            <select className="form-select form-select-sm">
                                                <option value="">5:00 PM</option>
                                            </select>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                        <h6 className="fw-semibold">Assign users</h6>
                        <div>
                            {shiftHoursData.assignedUsers?.length == 0 &&
                                <span>No user found</span>
                            }
                            <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3 ms-3"
                                data-bs-toggle="modal" data-bs-target="#ShiftHoursAddUserModal">
                                + Add User
                            </button>
                        </div>
                        {
                            shiftHoursData.assignedUsers?.length > 0 &&
                            <div className="table-responsive">
                                <table className="table table-hover shift-hour-table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>Role</th>
                                            <th>Email</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shiftHoursData.assignedUsers.map((data) => (
                                            <tr>
                                                <th>{data?.firstName + ' ' + data?.lastName}</th>
                                                <th>{data?.role?.role_name}</th>
                                                <th>{data?.email}</th>
                                                <th className="cursor-pointer" onClick={() => handleChangeAssignedUsers(data)}>Delete</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>

                    {/* Button Section */}
                    <div
                        className="form-footer rounded-bottom-4 position-absolute start-0 bottom-0 w-100 bg-white border-top ps-4 py-3 d-flex">
                        <button type="button" className="btn btn-light flat-btn rounded-pill py-1 px-3"
                            onClick={() => { setShowShiftHour(true); showSHHandler(true) }}>
                            Cancel
                        </button>
                        {isEdit ?
                            <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3 ms-3"
                                onClick={handleUpdate} disabled={!validForm}>
                                Update
                            </button>
                            :
                            <button type="button" className="btn btn-primary btn-digy-dark rounded-pill py-1 px-3 ms-3"
                                onClick={() => { setShowShiftHour(true); showSHHandler(true); handleSubmit() }} disabled={!validForm}>
                                Save
                            </button>
                        }
                    </div>
                    {/* // Button Section END // */}

                    {/* // Shift Hours Add User Modal // */}
                    <div className="modal fade" id="ShiftHoursAddUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ShiftHoursAddUserLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable add-user-modal">
                            <div className="modal-content">
                                <div className='modal-header'>
                                    <h5 className="modal-title fs-5" id="ShiftHoursAddUserLabel">Add Users to this shift hours</h5>
                                </div>
                                <div className="modal-body">
                                    <div className='d-flex align-items-center pb-4'>
                                        <div className="dropdown me-3 shift-user-select">
                                            <button data-bs-toggle="dropdown" aria-expanded="false"
                                                className="btn btn-link border dropdown-toggle with-arrow pe-4 text-decoration-none text-dark text-start position-relative">
                                                Active
                                            </button>
                                            <ul className='dropdown-menu'>
                                                <li><a className="dropdown-item" >Active</a></li>
                                                {/* <li><a className="dropdown-item" >Inactive</a></li>
                                                <li><a className="dropdown-item" >Unconfirmed</a></li> */}
                                            </ul>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <div className="search-box border rounded-pill">
                                                <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                                <input type="search" placeholder="Search" className="searchfield bg-white"
                                                    value={value}
                                                    onChange={e => setValue(e.target.value)} />
                                            </div>
                                        </div>
                                        <span className='ms-3'>{shiftHoursData.assignedUsers?.length || 0} user selected.</span>
                                    </div>
                                    <div className="tbl-container rounded-table">
                                        <div className="table-responsive">
                                            <table className="table table-hover shift-hour-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>
                                                            <div>
                                                                <input className="form-check-input" type="checkbox" value="" aria-label="select all user"
                                                                    onChange={(e) => handleAddRemoveUser(e)}
                                                                    checked={allUsers?.allUserData?.length === shiftHoursData?.assignedUsers.length} />
                                                            </div>
                                                        </th>
                                                        <th>Users name</th>
                                                        <th>Email</th>
                                                        <th>Current Shift</th>
                                                        <th>Next Shift</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allUsers?.allUserData?.length > 0 ?
                                                        <>
                                                            {allUsers.allUserData
                                                                .filter(item => {
                                                                    if (!value) return true
                                                                    if (item.firstName.includes(value) || item.lastName.includes(value) ||
                                                                        item.email.includes(value)) {
                                                                        return true
                                                                    }
                                                                })
                                                                .map((data) => (
                                                                    <tr>
                                                                        <td className="text-center">
                                                                            <div>
                                                                                <input className="form-check-input" type="checkbox"
                                                                                    // value="" aria-label="select all user"
                                                                                    onChange={() => handleChangeAssignedUsers(data)}
                                                                                    // name="day"
                                                                                    // value={value?.day}
                                                                                    // onChange={() => handleCheckboxChange1(value)}
                                                                                    checked={shiftHoursData.assignedUsers?.some((selectedItem) => selectedItem._id === data._id)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className='d-inline-flex align-items-center'>
                                                                                <img className='opacity-50' src={data?.profilePicture} width={30} height={30} alt="profile-icon" />
                                                                                <span className='ms-2'>
                                                                                    {data?.firstName + ' ' + data?.lastName}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>{data?.email}</td>
                                                                        <td>-</td>
                                                                        <td>-</td>
                                                                    </tr>
                                                                ))}
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan={6} className='text-center'>No Data Found</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer shadow-top">
                                    <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary rounded-pill btn-blue" data-bs-dismiss="modal">Assign</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // Shift Hours Add User Modal // */}
                </>
            }
        </React.Fragment>
    )
}
export default EditShiftHours;