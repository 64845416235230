import { call, put } from "redux-saga/effects";
import { createProfile, deleteProfile, deleteToTransfer, getProfileById, getProfiles, updatePermission } from "../../../../Services/apiService";
import { createProfileFailure, createProfileSuccess, deleteProfileFailure, deleteProfileSuccess, deleteToTransferProfileFailure, deleteToTransferProfileSuccess, getProfileByIdFailure, getProfileByIdSuccess, getProfileFailure, getProfileSuccess, updatePermissionFailure, updatePermissionSuccess } from "../../../Slices/profileSlice";

export function* getProfilesHandler(actions) {
    try {
        const response = yield call(getProfiles, actions?.payload);
        yield put(getProfileSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getProfileFailure(errResponse))
    }
}

export function* getProfileByIdHandler(actions) {
    try {
        const response = yield call(getProfileById, actions?.payload);
        yield put(getProfileByIdSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(getProfileByIdFailure(errResponse))
    }
}

export function* createProfileHandler(actions) {
    try {
        const response = yield call(createProfile, actions?.payload);
        yield put(createProfileSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(createProfileFailure(errResponse))
    }
}

export function* updatePermissionHandler(actions) {
    try {
        const response = yield call(updatePermission, actions?.payload);
        yield put(updatePermissionSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(updatePermissionFailure(errResponse))
    }
}

export function* deleteProfileHandler(actions) {
    try {
        const response = yield call(deleteProfile, actions?.payload);
        yield put(deleteProfileSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteProfileFailure(errResponse))
    }
}

export function* deleteToTransferProfileHandler(actions) {
    try {
        const response = yield call(deleteToTransfer, actions?.payload);
        yield put(deleteToTransferProfileSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteToTransferProfileFailure(errResponse))
    }
}