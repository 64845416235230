import React from 'react'
import Chart from 'react-apexcharts';

const BarChart = ({ series, options, width = '100%', height = '100%' }) => {
    return (
        <React.Fragment>
            <Chart
                options={options}
                series={series}
                type="bar"
                width={width}
                height={height}
            />
        </React.Fragment>
    )
}

export default BarChart