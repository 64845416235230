import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWorkflowStart, removeWorkflowByIdStart } from "../../Store/Slices/workflowSlice";
import { getItem } from "../../Services/localService";
import Loader from "../../Components/Loader";
import { Utils } from "../../Utils";
import { CreateRuleModal, DeleteWorkflowModal } from "./Modals";

const WorkflowRules = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let workflowList = useSelector((state) => state?.workflow);
    const tableColumn = ['Rule Name', 'All Modules', 'Execute On', 'Last Modified', 'Update', 'Remove'];
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [formData, setFormData] = useState({
        module: '',
        ruleName: '',
        ruleDescription: ''
    });
    const [selectedId, setSelectedId] = useState('');
    const [activeCount, setActiveCount] = useState(0)

    useEffect(() => {
        dispatch(getWorkflowStart());
    }, []);

    useEffect(() => {
        if (workflowList?.reload) {
            setSelectedId('');
            dispatch(getWorkflowStart());
        }
    }, [workflowList?.reload]);

    const editHandler = (id, list) => {
        navigate(Utils.getNavigationUrl(`workflow-rules/edit/${id}`), { state: list })
    }

    // alert modal updates
    const updateAlertModalState = (status) => {
        setShowAlertModal(status)
    }

    const updateSelectedId = (value) => {
        setSelectedId(value)
    }

    const updateRemoveWorkflow = (id) => {
        dispatch(removeWorkflowByIdStart(id));
    }
    // create modal updates
    const updateCreateModalState = (status) => {
        setShowCreateModal(status)
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name == 'module') {
            setActiveCount(workflowList?.allData?.filter((data) => data.moduleId._id === value.split('#')[0])?.length);
            setFormData(prevState => ({ ...prevState, [name]: value.split('#')[0] + '#' + value.split('#')[1] }));
        } else {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    };

    return (
        <React.Fragment>
            <Loader isVisible={workflowList?.isLoading} />
            <div className="card rounded-4 border-0">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                            <div className="left d-flex align-items-start">
                                <div className="search-box border rounded-pill">
                                    <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    <input type="search" placeholder="Search" className="searchfield bg-white" />
                                </div>
                            </div>
                            <div className="ms-auto">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn">Reorder Rules</button>
                                    <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => setShowCreateModal(true)}>
                                        <i className="fa-solid fa-plus me-2"></i>
                                        <span>New Rule</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="tbl-container rounded-table">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                {tableColumn.map((data) => (
                                                    <th key={data}>{data}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {workflowList?.data?.length > 0 ? (
                                                workflowList.data.map((list) => (
                                                    <tr key={list?._id}>
                                                        <td>{list?.ruleName}</td>
                                                        <td>{list?.moduleId?.name}</td>
                                                        <td className="text-capitalize">{list?.rules?.workflow_base === 'convert' ? list?.rules?.workflow_base : (list?.rules?.workflow_base).replace('_', ' ') + '-' + list?.rules?.workflow_base_type}</td>
                                                        <td>{new Date(list?.updatedAt).toLocaleDateString()}</td>
                                                        <td>
                                                            <span style={{ color: "#29409d" }} className="cursor-pointer"
                                                                onClick={() => editHandler(list?._id, list)}>
                                                                <i className="fas fa-edit"></i>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                style={{ color: "#dc3545" }}
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    setSelectedId(list?._id);
                                                                    setShowAlertModal(true)
                                                                }}
                                                            >
                                                                <i className="fas fa-trash"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">No workflow rules found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAlertModal && <DeleteWorkflowModal showAlertModal={showAlertModal} selectedId={selectedId} setShowAlertModal={updateAlertModalState} setSelectedId={updateSelectedId} removeWorkflow={updateRemoveWorkflow} />}
            {showCreateModal && <CreateRuleModal showCreateModal={showCreateModal} setShowCreateModal={updateCreateModalState} formData={formData} handleInputChange={handleInputChange} activeCount={activeCount} />}
        </React.Fragment>
    )
}
export default WorkflowRules;