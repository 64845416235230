import React, { useState } from 'react';
import { Helpers } from '../../Helpers';
import SubformInputs from './subformInputs';

const DynamicTable = ({ section, storeData, sectionIndex, setStoreData, preview, users, lookupData  = null }) => {
    let [rows, setRows] = useState(!preview ? storeData.data[sectionIndex].section_data["table_data"] : [{}] || [{}]);
    let addRow = () => {
        if (!preview) {
            let newRow = Object.assign({}, ...section.selected_list.map(x => ({
                [Helpers.replaceData(x?.extra_fields?.field_label)]: ""
            })));
            let updatedRows = [...rows, newRow];
            setRows(updatedRows);

            let updatedStoreData = JSON.parse(JSON.stringify(storeData));
            updatedStoreData.data[sectionIndex].section_data["table_data"] = updatedRows;
            setStoreData(updatedStoreData);
        }
    };

    let removeRow = (rowIndex) => {
        if (!preview) {
            let updatedRows = rows.filter((_, index) => index !== rowIndex);
            if (updatedRows?.length === 0) return;
            setRows(updatedRows);

            let updatedStoreData = JSON.parse(JSON.stringify(storeData));
            updatedStoreData.data[sectionIndex].section_data["table_data"] = updatedRows;
            setStoreData(updatedStoreData);
        }
    };

    let handleInputChange = async (rowIndex, colIndex, value) => {
        if (!preview) {
            let updatedRows = rows.map((row, rIndex) =>
            {
                if(rIndex === rowIndex) {
                    return { ...row, [Helpers.replaceData(section?.selected_list?.[colIndex]?.extra_fields?.field_label)]: value };
                } else {
                    return row;
                }
            }
            );
            setRows(updatedRows);
            let updatedStoreData = JSON.parse(JSON.stringify(storeData));
            updatedStoreData.data[sectionIndex].section_data["table_data"] = updatedRows;
            setStoreData(updatedStoreData);
        }
    };

    const handleBlur = (rowIndex) => {
        if (!preview) {
            let formulas = section.selected_list?.filter(x => x?.input_type === 'formula');
            let updatedRows = [...rows];
            formulas?.forEach((checkFormula) => {
                let expression = checkFormula?.extra_fields?.formula_expression;
                let checkIndex = section.selected_list?.findIndex(x => x?.extra_fields?.field_label === checkFormula?.extra_fields?.field_label);
                let calculateFormula = (formula, product) => {
                    return formula?.replace(/\${(.*?)}/g, (match, variable) => {
                        let keys = variable?.split('.');
                        return product?.[Helpers.replaceData(keys?.[1])] || 0;
                    });
                };
                if (expression) {
                    let row = updatedRows[rowIndex];
                    let processedFormula = calculateFormula(expression, row);
                    let result;

                    try {
                        result = eval(processedFormula);
                    } catch (error) {
                        console.error("Error evaluating formula:", error);
                        return;
                    }

                    updatedRows[rowIndex] = {
                        ...row,
                        [Helpers.replaceData(section?.selected_list?.[checkIndex]?.extra_fields?.field_label)]: result
                    };
                }
            });
            setRows(updatedRows);
            let updatedStoreData = JSON.parse(JSON.stringify(storeData));
            updatedStoreData.data[sectionIndex].section_data["table_data"] = updatedRows;
            setStoreData(updatedStoreData);
        }
    };

    return (
        <div>
            <div className="table-responsive">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            {section.selected_list.map((x, idx) => (
                                <th style={{ width: x?.extra_fields?.field_label === "S.NO" ? "50px" : "" }} key={idx} scope="col">{x?.extra_fields?.field_label}</th>
                            ))}
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {section.selected_list.map((col, colIndex) => (
                                    <td key={colIndex} className="text-center">
                                        {
                                            col?.input_type === 'formula' ?
                                                <>
                                                    <div className="form-group">
                                                        <div className="col-auto input-control">
                                                            <input
                                                                type={col?.input_type}
                                                                className="form-control"
                                                                value={row[Helpers.replaceData(col?.extra_fields?.field_label)] || ""}
                                                                placeholder={col?.label}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                col?.input_type === "auto_number" ?
                                                    <>
                                                        <span>{rowIndex + 1}</span>
                                                    </> :
                                                    <>
                                                        <SubformInputs item={col} labelValue={row[Helpers.replaceData(col?.extra_fields?.field_label)] || (col?.extra_fields?.default_value || '')} error={false} handleInputChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)} handleBlur={() => handleBlur(rowIndex)} users={users} lookupData={lookupData} preview={preview} />
                                                    </>
                                        }
                                    </td>
                                ))}
                                <td>
                                    <span style={{ color : "#dc3545"}} className="cursor-pointer" onClick={() => removeRow(rowIndex)}>
                                        <i className="fas fa-trash"></i>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button type='button' className="btn btn-primary mt-2" onClick={addRow}>
                Add Row
            </button>
        </div>
    );
};

export default DynamicTable;
