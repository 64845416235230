import { createSlice } from "@reduxjs/toolkit";

const webhookSlice = createSlice({
    name: 'webhooks',
    initialState: {
        isLoading: false,
        data: [],
        mapping_field: {},
        response: [],
        exit: false,
        message: null,
        reload: false,
        matrial_number_exist: false
    },
    reducers: {
        createWebhookStart: (state) => {
            state.isLoading = true;
        },
        createWebhookSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.exit = true;
            state.isLoading = false;
            state.reload = false;
        },
        createWebhookFailure: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        getWebhookListByModuleIdStart: (state) => {
            state.isLoading = true;
        },
        getWebhookListByModuleIdSuccess: (state, action) => {
            state.data = action?.payload?.webhooks;
            state.message = action?.payload?.message;
            state.reload = false;
            state.exit = false;
            state.isLoading = false;
        },
        getWebhookListByModuleIdFailure: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getWebhookListByIdStart: (state) => {
            state.isLoading = true;
        },
        getWebhookListByIdSuccess: (state, action) => {
            state.data = action?.payload?.webhooks;
            state.message = action?.payload?.message;
            state.reload = false;
            state.exit = false;
            state.isLoading = false;
        },
        getWebhookListByIdFailure: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateWebhookByIdStart: (state) => {
            state.isLoading = true;
        },
        updateWebhookByIdSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.reload = false;
            state.exit = true;
            state.isLoading = false;
        },
        updateWebhookByIdFailure: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        removeWebhookByIdStart: (state) => {
            state.isLoading = true;
        },
        removeWebhookByIdSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.reload = true;
            state.exit = false;
            state.isLoading = false;
        },
        removeWebhookByIdFailure: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        existMaterialWebhookStart: (state) => {
            state.isLoading = true;
        },
        existMaterialWebhookSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.reload = false;
            state.exit = false;
            state.isLoading = false;
            state.matrial_number_exist = false;
        },
        existMaterialWebhookFailure: (state, action) => {
            state.message = action?.payload;
            state.isLoading = false;
            state.matrial_number_exist = true;
        },
        listRefMaterialWebhookStart: (state) => {
            state.isLoading = true;
        },
        listRefMaterialWebhookSuccess: (state, action) => {
            let res = action?.payload?.response;
            if (res?.length > 0) {
                let wrappedJsonString = `[${res.replace(/]\s*\[/g, '],[')}]`;
                try {
                    state.response = JSON.parse(wrappedJsonString);
                } catch (error) {
                    state.response = "";
                }
            } else {
                state.response = "";
            }
            state.mapping_field = action?.payload?.mapping_field;
            state.reload = false;
            state.exit = false;
            state.isLoading = false;
        },
        listRefMaterialWebhookFailure: (state, action) => {
            state.message = action?.payload;
            state.isLoading = false;
        },
        resetState: (state) => {
            state.message = null;
            state.reload = false;
            state.exit = false;
            state.matrial_number_exist = false;
        }
    }
});

export const {
    createWebhookStart,
    createWebhookSuccess,
    createWebhookFailure,
    getWebhookListByModuleIdStart,
    getWebhookListByModuleIdSuccess,
    getWebhookListByModuleIdFailure,
    getWebhookListByIdStart,
    getWebhookListByIdSuccess,
    getWebhookListByIdFailure,
    updateWebhookByIdStart,
    updateWebhookByIdSuccess,
    updateWebhookByIdFailure,
    removeWebhookByIdStart,
    removeWebhookByIdSuccess,
    removeWebhookByIdFailure,
    existMaterialWebhookStart,
    existMaterialWebhookSuccess,
    existMaterialWebhookFailure,
    listRefMaterialWebhookStart,
    listRefMaterialWebhookSuccess,
    listRefMaterialWebhookFailure,
    resetState
} = webhookSlice?.actions;

export default webhookSlice?.reducer;