import React, { useState } from 'react';  
import Modal from 'react-bootstrap/Modal'; 
import Form from 'react-bootstrap/Form';  
import Button from 'react-bootstrap/Button';  
import {handleFieldLabelUpdate} from '../../../Utils';


function CheckboxPropertiesModal({ show, handleClose,item, editPropertySubmit}) {
  let fields = item?.extra_fields;
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label || "");
  const [isEnableByDefault, setIsEnableByDefault] = useState(false);
  const [showTooltip, setShowTooltip] = useState({ checked: fields?.show_tooltip?.checked, type: fields?.show_tooltip?.type, message: fields?.show_tooltip?.message });
  const [showPreview, setShowPreview] = useState(false);
  const [labelError, setLabelError] = useState("");
  const [checkBoxModalName, setCheckBoxModalName] = useState(fields?.check_box_modal_name || "");
  const [validationField, setValidationForDisable] = useState(fields?.validation_field || "");

  const modalList = [
    {
      name: "Reminder", value: "ReminderModal", display: {
        "default": "On ${date} at ${time} ${period} by ${alert}"
      }
    },
    {
      name: "Repeat", value: "RepeatModal", display: {
        "times": "${repeatType}, upto ${times} time(s)",
        "endDate": "${repeatType}, until ${endDate}",
        "default": "${repeatType}"
      }
    }
  ];

  const handleSubmit = () => {
    let formData = {
      field_label: fieldLabel,
      enable_default: isEnableByDefault,
      show_tooltip: showTooltip,
      check_box_modal_name: checkBoxModalName || "",
      display: checkBoxModalName ? modalList?.find(x => x?.value === checkBoxModalName)?.display : "",
      validation_field : validationField
    };
    editPropertySubmit(formData);
    handleClose();
  };

  const handleCancel = () => {
    setFieldLabel('Checkbox');
    setIsEnableByDefault(false);
    setShowTooltip({ checked: false, type: 'static', message: '' });
    handleClose();
  }
  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleFieldLabelChange = (e) => {
    handleFieldLabelUpdate(e.target.value, setFieldLabel,setLabelError);
  }
  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
      <Modal.Header closeButton>
        <Modal.Title>Checkbox Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Field Label</Form.Label>
            <Form.Control 
              type="text" 
              value={fieldLabel} 
              onChange={handleFieldLabelChange}
              placeholder="Checkbox"
            />
            {labelError && <div className="text-danger">{labelError}</div>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Modal Name</Form.Label>
            <Form.Select
              value={checkBoxModalName}
              onChange={(e) => setCheckBoxModalName(e.target.value)}
            >
              <option value="">Choose Modal Name</option>
              {modalList?.map((x, i) => (
                <option key={i} value={x?.value}>{x?.name}</option>
              ))}
            </Form.Select>
          </Form.Group>

          {checkBoxModalName && (
            <Form.Group className="mb-3">
              <Form.Label>Validation Field Name</Form.Label>
              <Form.Control
                type="text"
                value={validationField || ""}
                onChange={(e) => setValidationForDisable(e?.target?.value)}
                placeholder="Validation Field Name"
              />
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Check 
              label="Enable by Default" 
              checked={isEnableByDefault} 
              onChange={(e) => setIsEnableByDefault(e.target.checked)} 
            />
          </Form.Group>
          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip.checked}
              onChange={() => setShowTooltip({ ...showTooltip, checked: !showTooltip.checked })}
            />
          </Form.Group>

          {showTooltip.checked && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Message (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={showTooltip.message}
                  onChange={(e) => setShowTooltip({ ...showTooltip, message: e.target.value })}
                  placeholder="Enter Tooltip message"
                />
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={showTooltip.type === 'info'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'info' })}
                />
                <Form.Check
                  type="radio"
                  label="Static Text"
                  name="tooltipOption"
                  checked={showTooltip.type === 'static'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'static' })}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showTooltip.type === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {showTooltip.message && <span className="ms-2">{showTooltip.message}</span>}
                    </div>
                  ) : (
                    <p>{showTooltip.message}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CheckboxPropertiesModal;
