import React from 'react'
import Chart from "react-apexcharts";

const LineChart = ({ series, options, height = '100%', width = '100%' }) => {
    debugger
    return (
        <React.Fragment>
            <Chart
                options={options}
                type="line"
                width={width}
                height={height}
                series={series}
            />
        </React.Fragment>
    )
}

export default LineChart