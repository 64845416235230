import React, { useRef } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Utils } from '../../../Utils';

const FieldsDraggable = ({ field, addField }) => {
    let id = useRef(Utils.idGenerator());
    let { attributes, listeners, setNodeRef } = useDraggable({
        id: id.current,
        data: {
            field,
            fieldDragArea : true
        }
    });

    return (
        <li ref={setNodeRef} {...listeners} {...attributes} className="list-group-item d-flex justify-content-between align-items-center cursor-move">
            {field.extra_fields.field_label}
            <span className="cursor-pointer" onClick={() => addField(field)}>+</span>
        </li>
    );
};

export default FieldsDraggable;
