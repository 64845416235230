import React, { useState } from 'react';
import { Container, Row, Col, Form, Card, Button, ListGroup, Badge, InputGroup } from 'react-bootstrap';
import { Search } from 'lucide-react'; 
// import '../../../App.css'; 
import Modal from 'react-bootstrap/Modal';  

const modules = [
  { name: 'Notes', count: 2 },
  { name: 'Attachments', count: 1 },
  { name: 'Tasks', count: 7 },
  { name: 'Emails', count: 5 },
  { name: 'Calls', count: 10 },
  { name: 'Meetings', count: 6 },
];

const fieldSets = {
  Notes: ['Last Note Added On', 'Total Notes Added'],
  Attachments: ['Total Attachments Added'],
  Tasks: [
    'Total Tasks Created',
    'Total Completed Tasks',
    'Total Open Tasks',
    'Total Overdue Tasks',
    'Last Task Created On',
    'Last Task Completed On',
    'Next Task Due Date',
  ],
  Emails: [
    'Last Email Sent On',
    'Total Outgoing Emails',
    'Total Opened Emails',
    'Total Not Opened Emails',
    'Total Bounced Emails',
  ],
  Calls: [
    'Total Calls',
    'Total Outgoing Calls',
    'Total Incoming Calls',
    'Total Missed Calls',
    'Total Overdue Calls',
    'Total Cancelled Calls',
    'Last Call Made On',
    'Last Call Received On',
    'Upcoming Scheduled Call Date',
    'Average Call Duration (in seconds)',
  ],
  Meetings: [
    'Total Meetings Created',
    'Total Completed Meetings',
    'Total Upcoming Meetings',
    'Last Meeting Created On',
    'Last Meeting Completed On',
    'Next Meeting Time',
  ],
};

const RollupSummary = ({ show, handleClose, item }) => {
  const [selectedModule, setSelectedModule] = useState('Notes');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFields = fieldSets[selectedModule].filter(field =>
    field.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Rollup Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container fluid className="rollup-summary p-3">
          <Card>
            <Card.Header as="h5">Rollup Summary Properties</Card.Header>
            <Card.Body>
              <Row>
                <Col md={3}>
                  <Card>
                    <Card.Header>Related Modules</Card.Header>
                    <ListGroup variant="flush">
                      {modules.map(module => (
                        <ListGroup.Item
                          key={module.name}
                          action
                          active={selectedModule === module.name}
                          onClick={() => setSelectedModule(module.name)}
                        >
                          {module.name} <Badge bg="secondary">{module.count}</Badge>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={9} className="scrollable-rollup-summary">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <Search size={18} />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                  <Row>
                    {filteredFields.map(field => (
                      <Col md={6} key={field} className="mb-3">
                        <Form.Group>
                          <Form.Label>{field}</Form.Label>
                          <Form.Control type="text" placeholder={field} />
                        </Form.Group>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                Note: Notes that are directly added to the CustomModule3 will only be considered for Rollup Calculation. Other Notes displayed in the Related List that are related to the child records of the CustomModule3 will not be considered for the calculation.
              </small>
            </Card.Footer>
          </Card>
        
        </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default RollupSummary;
