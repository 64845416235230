import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllDashboardComponentStart, deleteDashboardComponentStart, updateDashboardComponentStart } from '../../../Store/Slices/dashboardComponentsSlice';
import { useDispatch, useSelector } from "react-redux";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { DashboardComponents } from "../../../Components/DashboardComponents";
import { getAllSubFormModulesStart } from '../../../Store/Slices/dashboardComponentsSlice';
import { AllChartModel } from "../../../Components/ModalPopup/ChartComponents";
import ChooseChartModal from "../../../Components/ModalPopup/ChartComponents/ChooseChartModal";
import Loader from "../../../Components/Loader";
import { Utils } from "../../../Utils";
const ReactGridLayout = WidthProvider(RGL);

const Analytics = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let subModuleData = useSelector((state) => state?.dashboardComponents);
    let [selectedIndex, setSelectedIndex] = useState(0);
    let [layoutPostion, setLayoutPostion] = useState([]);
    let [componentData, setComponentData] = useState(null);
    let [reOrderData, setReOrderData] = useState(false);
    let [updateComponentData, setUpdateComponentData] = useState(null);
    let [openDropdown, setOpenDropdown] = useState(null);
    let [isEditModalData, setIsEditModalData] = useState(null);
    let [OpenChartModel, setOpenChartModel] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [showChooseChartModal, setChooseChartModal] = useState(false);

    useEffect(() => {
        if (!subModuleData?.components?.length) {
            dispatch(getAllDashboardComponentStart());
        }
    }, []);

    useEffect(() => {
        if (subModuleData?.reloadGetAllModuleApi) {
            dispatch(getAllDashboardComponentStart());
        }
    }, [subModuleData?.reloadGetAllModuleApi]);

    useEffect(() => {
        if (subModuleData?.components?.length > 0) {
            let componentDatainitialData = subModuleData?.components?.[selectedIndex];
            setLayoutPostion(componentDatainitialData?.components?.map(item => ({
                ...item?.position
            })) || []);
            if (componentDatainitialData?.components?.length > 0) {
                let uniqueModuleIds = [...new Set(componentDatainitialData?.components?.map(item => item.moduleId))];
                uniqueModuleIds?.forEach((moduleId) => {
                    let hasModuleId = subModuleData?.subModuleData?.hasOwnProperty(moduleId);
                    if (!hasModuleId) {
                        dispatch(getAllSubFormModulesStart(moduleId));
                    }
                });
            }
            setComponentData(componentDatainitialData);
        }
    }, [selectedIndex, subModuleData?.components]);

    let removeDashboard = (id) => {
        dispatch(deleteDashboardComponentStart(id));
    }

    let handleToggle = (index) => {
        setOpenDropdown(prevIndex => (prevIndex === index ? null : index));
    };

    let onLayoutChange = (layout2) => {
        if (componentData && layout2?.length > 0) {
            let data = [...componentData?.components];
            let updatedData = data.map(item => {
                let matchingLayout = layout2.find(layoutItem => layoutItem.i === item?.position?.i);
                if (matchingLayout) {
                    item = { ...item, position: { ...matchingLayout } };
                }
                return item;
            });
            if(reOrderData) {
                setUpdateComponentData({...componentData, components : updatedData});
            }
        }
    }

    let updateComponent = () => {
        let data = {
            id : updateComponentData?._id,
            payload : updateComponentData
        }
        dispatch(updateDashboardComponentStart(data));
        setReOrderData(false);
        setUpdateComponentData(null);
    }

    let removeData = (index) => {
        let data = {id : componentData?._id, payload : {...componentData, components : componentData?.components?.filter((_, i) => i !== index)}};
        dispatch(updateDashboardComponentStart(data));
    }

    let openEditModal = (x) => {
        setIsEditModalData(x);
        let ChartComponent = AllChartModel[x?.main_modal?.length > 0 ? x?.main_modal : x?.modal_name];
        if (ChartComponent) {
            setOpenChartModel(() => ChartComponent);
            setShowModal(true);
        } else { }
    }

    let submitModalData = (data) => {
        let data2 = {id : componentData?._id, payload : {...componentData, components : componentData?.components.map(item => 
            item.position.i === data.position.i ? data : item
        )}};
        dispatch(updateDashboardComponentStart(data2));
    }

    let addComponent = (data) => {
        let data2 = {id : componentData?._id, payload : {...componentData, components : [...componentData?.components, data]}};
        dispatch(updateDashboardComponentStart(data2));
    }
    return (
        <>
            <Loader isVisible={subModuleData?.isLoading} />
            <div className="create-module">
                <div className="container-fluid">
                    <div className="row">
                        <div className="sidebar col-md-2 col-lg-2 p-0 bg-white h-100vh fixed-top">
                            <div className="offcanvas-md offcanvas-end h-100" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                                        aria-label="Close"></button>
                                </div>

                                <div className="offcanvas-body d-md-flex flex-column p-3 pt-lg-3 h-100 overflow-y-auto">
                                    <div className="row g-0 draggable-btns">
                                        <h5 className="mb-4">Dashboards <span className="float-end cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`analytics/create-dashboard`))}><i className="fas fa-plus"></i></span></h5>
                                        <ul className="list-group list-group-flush">
                                            {subModuleData?.components?.length > 0 && (
                                                subModuleData?.components?.map((x, index) => (
                                                    <li key={index} className={`list-group-item ${selectedIndex === index ? 'text-primary' : ''}`} onClick={() => setSelectedIndex(index)}>{x?.dashboard_name}</li>
                                                ))
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <main className="col-md-10 col-lg-10 ms-sm-auto px-md-4 pb-md-4 content-section">
                            <header className="navbar navbar-expand-lg sticky-top bg-white border-bottom border-light-subtle px-0 tb-header">
                                <nav className="container-fluid d-block flex-wrap flex-lg-nowrap">
                                <div className="d-flex justify-content-between align-items-center p-2 bg-white">
                                        <strong className="me-3">{componentData?.dashboard_name}</strong>
                                        <div className="d-flex align-items-center">
                                            {reOrderData ? (
                                                <>
                                                    <button type="button" className="btn btn-light btn-sm me-2" onClick={() => {
                                                        setReOrderData(false);
                                                        setUpdateComponentData(null);
                                                    }}>Cancel</button>
                                                    <button type="button" className="btn btn-primary btn-sm me-2" onClick={updateComponent}>Save</button>
                                                </>
                                            ) : (
                                                    <>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-primary btn-sm rounded-end-1 me-2" onClick={() => setChooseChartModal(true)} disabled={!componentData?._id}>Add Component</button>
                                                        
                                                        <div className="dropstart">
                                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle py-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-v d-none d-lg-inline"></i>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="deashboardDropdown">
                                                                <li>
                                                                    <button type="button" className="dropdown-item" onClick={() => setReOrderData(true)}>Reorder</button>
                                                                </li>
                                                                <li>
                                                                    <button type="button" className="dropdown-item" onClick={() => removeDashboard(componentData?._id)}>Delete</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </div>
                                                    </>
                                            )}
                                        </div>
                                        </div>
                                </nav>
                            </header>
                            <div className={`${reOrderData ? 'bg-image-box' : ''} grid-main-container p-0`}>
                                <ReactGridLayout
                                    rowHeight={30}
                                    height={1200}
                                    width={1200}
                                    className={`grid-container ${reOrderData ? 'bg-image-box' : ''} p-0`}
                                    layout={layoutPostion}
                                    isResizable={reOrderData}
                                    isDraggable={reOrderData}
                                    preventCollision={false}
                                    compactType="vertical"
                                    draggableCancel='.top-right-menu, .dropdown-menu, .dropdown ul'
                                    cols={12}
                                    resizeHandles={['se']}
                                    onLayoutChange={onLayoutChange}
                                >
                                    {componentData?.components?.length > 0 && (
                                        componentData?.components?.map((x, index) => {
                                            let ComponentToRender = DashboardComponents[x?.modal_name];
                                            return ComponentToRender ? (
                                                <div key={x.position.i} className="grid-item">
                                                    {reOrderData ?
                                                        <>
                                                        </> :
                                                        <>
                                                            <div className="top-right-menu dropdown" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={() => handleToggle(index)}>
                                                                <i className="fa fa-ellipsis-v cursor-pointer"></i>
                                                                {(openDropdown === index) && (
                                                                    <div className="dropdown-menu d-block">
                                                                        <ul>
                                                                            <li onClick={() => openEditModal(x)}>Edit</li>
                                                                            <li onClick={() => removeData(index)}>Delete</li>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="content card">
                                                        <h5 className="card-title fs-16 ps-3 text-start fw-semibold">{x?.company_name}</h5>
                                                        <div className="card-body p-0 overflow-auto">
                                                            <ComponentToRender data={x} isStatic={false} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null;
                                        })
                                    )}
                                </ReactGridLayout>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            {(showModal && OpenChartModel) && <OpenChartModel show={showModal} handleClose={() => {
                setShowModal(false);
                setOpenChartModel(null);
                setIsEditModalData(null);
            }} submitData={(data) => submitModalData(data)} isData={isEditModalData} allList={componentData?.components}/>}

            { showChooseChartModal && <ChooseChartModal show={showChooseChartModal} handleClose={() => {setChooseChartModal(false)}} submitData={(datas) => addComponent(datas)} allList={componentData?.components}/>}
        </>
    )
}

export default Analytics;