import BellIcon from "./images/header-icons/bell-icon.svg";
import HomeIcon from "./images/header-icons/home-icon.svg";
import QuestionMarkIcon from "./images/header-icons/question-mark-icon.svg";
import SettingIcon from "./images/header-icons/setting-icon.svg";
import WhatsappIcon from "./images/header-icons/whatsapp-icon.svg";
import digylaxLogo from './images/digylax-logo.png';
import userIcon from './images/user-img.png';
import settingIcon from './images/header-icons/setting-icon.svg';
import PaperPlusIcon from './images/paper-plus-icon.svg';
import WalkaroIcon from './images/walkaroo-logo.png';

const HeaderImages = {
    BellIcon,
    HomeIcon,
    QuestionMarkIcon,
    SettingIcon,
    WhatsappIcon,
    digylaxLogo,
    userIcon,
    settingIcon,
    PaperPlusIcon,
    WalkaroIcon
}

export { HeaderImages };