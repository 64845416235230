import { call, put } from "redux-saga/effects";
import { getSubFormModuleCreatedRecords, getSubFormModuleGraphicalStatus, createTableColumnSubFormModule, updateTableColumnSubFormModule, bulkUploadSubFormModule, removeSubFormModuleById, createSubFormModule, getSubFormModuleList, getSubFormModuleListById, updateSubFormModuleById, convertSubformData, convertedSubformData, subFormModuleLog, createSubFormCustomView, getAllSubFormCustomView, getByIdSubFormCustomView, updateSubFormCustomView } from "../../../../Services/apiService";
import { getSubFormModuleCreatedRecordsSuccess, getSubFormModuleCreatedRecordsFailure, getSubFormModuleGraphicalStatusSuccess, getSubFormModuleGraphicalStatusFailure, createTableColumnSubFormModuleSuccess, createTableColumnSubFormModuleFailure, updateTableColumnSubFormModuleSuccess, updateTableColumnSubFormModuleFailure, bulkUploadSubFormByIdSuccess, bulkUploadSubFormByIdFailure, removeSubFormByIdSuccess, removeSubFormByIdFailure, updateSubFormByIdSuccess, updateSubFormByIdFailure, getSubFormListByIdSuccess, getSubFormListByIdFailure, getSubFormListSuccess, getSubFormListFailure, createSubFormSuccess, createSubFormFailure, convertSubformDataSuccess, convertSubformDataFailure, convertedSubformDataSuccess, convertedSubformDataFailure, subFormModuleLogSuccess, subFormModuleLogFailure, createSubFormCustomViewSuccess, getAllSubFormCustomViewSuccess, getByIdSubFormCustomViewSuccess, updateSubFormCustomViewSuccess, updateSubFormCustomViewFailure, getByIdSubFormCustomViewFailure, getAllSubFormCustomViewFailure, createSubFormCustomViewFailure } from "../../../Slices/subFormSlice";
let keys = {
    "variants": "variants",
    "tableColumns": "tableColumns",
    "formsubmodules": "formsubmodules",
    "totalCount": "totalCount",
    "modules": "modules",
    "lookupData": "lookupData",
    "workflowRules": "workflowRules",
    "sapStatus": "sapStatus",
    "message": "message",
    "isLoading": "isLoading",
    "status": "status"
}

export function* getSubFormModuleListHandler(actions) {
    try {
        let payload = actions?.payload;
        let response = yield call(getSubFormModuleList, payload);
        let isPagination = payload?.hasOwnProperty('page');
        yield put(getSubFormListSuccess({...response?.data, ...(payload?.keys ? {keys : payload?.keys} : { keys }), isPagination}));
    } catch (err) {
        yield put(getSubFormListFailure({...err?.response?.data, keys}))
    }
}

export function* getSubFormModuleListByIdHandler(actions) {
    try {
        let response = yield call(getSubFormModuleListById, actions?.payload);
        yield put(getSubFormListByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getSubFormListByIdFailure(errResponse))
    }
}

export function* updateSubFormModuleByIdHandler(actions) {
    try {
        let response = yield call(updateSubFormModuleById, actions?.payload);
        yield put(updateSubFormByIdSuccess({...response?.data, updateKeys : actions?.payload?.updateKeys}));
    } catch (err) {
        yield put(updateSubFormByIdFailure({...err?.response?.data, updateKeys : actions?.payload?.updateKeys}))
    }
}

export function* createSubFormModuleHandler(actions) {
    try {
        let response = yield call(createSubFormModule, actions?.payload?.data);
        yield put(createSubFormSuccess({...response?.data, ...(actions?.payload?.keys ? {keys : actions?.payload?.keys} : { keys })}));
    } catch (err) {
        yield put(createSubFormFailure({...err?.response?.data, keys}))
    }
}

export function* removeSubFormModuleHandler(actions) {
    try {
        let response = yield call(removeSubFormModuleById, actions?.payload);
        yield put(removeSubFormByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(removeSubFormByIdFailure(errResponse))
    }
}

export function* bulkUploadSubFormModuleHandler(actions) {
    try {
        let response = yield call(bulkUploadSubFormModule, actions?.payload);
        yield put(bulkUploadSubFormByIdSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(bulkUploadSubFormByIdFailure(errResponse))
    }
}

export function* createTableColumnSubFormModuleHandler(actions) {
    try {
        let response = yield call(createTableColumnSubFormModule, actions?.payload);
        yield put(createTableColumnSubFormModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createTableColumnSubFormModuleFailure(errResponse))
    }
}

export function* updateTableColumnSubFormModuleHandler(actions) {
    try {
        let response = yield call(updateTableColumnSubFormModule, actions?.payload);
        yield put(updateTableColumnSubFormModuleSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateTableColumnSubFormModuleFailure(errResponse))
    }
}

export function* getSubFormModuleCreatedRecordsHandler(actions) {
    try {
        let response = yield call(getSubFormModuleCreatedRecords, actions?.payload);
        yield put(getSubFormModuleCreatedRecordsSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getSubFormModuleCreatedRecordsFailure(errResponse))
    }
}

export function* getSubFormModuleGraphicalStatusHandler(actions) {
    try {
        let response = yield call(getSubFormModuleGraphicalStatus, actions?.payload);
        yield put(getSubFormModuleGraphicalStatusSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getSubFormModuleGraphicalStatusFailure(errResponse))
    }
}

export function* convertSubformDataHandler(actions) {
    try {
        let response = yield call(convertSubformData, actions?.payload);
        yield put(convertSubformDataSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(convertSubformDataFailure(errResponse))
    }
}

export function* convertedSubformDataHandler(actions) {
    try {
        let response = yield call(convertedSubformData, actions?.payload);
        yield put(convertedSubformDataSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(convertedSubformDataFailure(errResponse))
    }
}

export function* subFormModuleLogHandler(actions) {
    try {
        let response = yield call(subFormModuleLog, actions?.payload);
        yield put(subFormModuleLogSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(subFormModuleLogFailure(errResponse))
    }
}

export function* createSubFormCustomViewHandler(actions) {
    try {
        let response = yield call(createSubFormCustomView, actions?.payload);
        yield put(createSubFormCustomViewSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(createSubFormCustomViewFailure(errResponse))
    }
}

export function* getAllSubFormCustomViewHandler(actions) {
    try {
        let response = yield call(getAllSubFormCustomView, actions?.payload);
        yield put(getAllSubFormCustomViewSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getAllSubFormCustomViewFailure(errResponse))
    }
}

export function* getByIdSubFormCustomViewHandler(actions) {
    try {
        let response = yield call(getByIdSubFormCustomView, actions?.payload);
        yield put(getByIdSubFormCustomViewSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(getByIdSubFormCustomViewFailure(errResponse))
    }
}

export function* updateSubFormCustomViewHandler(actions) {
    try {
        let response = yield call(updateSubFormCustomView, actions?.payload);
        yield put(updateSubFormCustomViewSuccess(response?.data));
    } catch (err) {
        let errResponse = err?.response?.data?.message;
        yield put(updateSubFormCustomViewFailure(errResponse))
    }
}