export const moduleForm = [
    {
        // label: 'Execute this workflow rule based on',
        // name: 'rule_base',
        // type: 'select',
        // required: true,
        rule_base_options: [
            {
                label: 'Record action',
                value: 'record_action',
                subOptions: [
                    { label: 'Create', value: 'create' },
                    { label: 'Create or Edit', value: 'create_edit' },
                    { label: 'Edit', value: 'edit' },
                    { label: 'Delete', value: 'delete' },
                    // { label: 'Cancel', value: 'cancel' }
                ]
            },
            {
                label: 'Date/Time field',
                value: 'datetime_field',
                subOptions: [
                    { label: 'Created Time', value: 'created_time' },
                    { label: 'Modified Time', value: 'modified_time' },
                    { label: 'Last Activity Time', value: 'last_activity_time' },
                    { label: 'Unsubscribed Time', value: 'unsubscribed_time' },
                    { label: 'Last Enriched Time', value: 'last_enriched_time' },
                    { label: 'Most Recent Visit', value: 'most_recent_visit' },
                    { label: 'First Visit', value: 'first_visit' }
                ],
                // disabled: true
            },
            {
                label: 'Record Score',
                value: 'record_score',
                // disabled: true
            },
            {
                label: 'Record Notes',
                value: 'record_notes',
                subOptions: [
                    { label: 'Note Added', value: 'note_added' },
                    { label: 'Note Added or Modified', value: 'note_added_modified' },
                    { label: 'Note Modified', value: 'note_modifies' },
                    { label: 'Note Deleted  ', value: 'note_deleted' }
                ],
                // disabled: true
            },
            {
                label: 'Convert',
                value: 'convert',
                // disabled: true
            }
        ]
    }
]

export const conditions = [
    { name: 'isnt', value: "isn't" },
    { name: 'contains', value: 'contains' },
    { name: 'doesnt_contain', value: "doesn't contain" },
    { name: 'starts_with', value: 'starts with' },
    { name: 'ends_with', value: 'ends with' },
    { name: 'is_empty', value: 'is empty' },
    { name: 'isnt_empty', value: 'is not empty' }
]

export const editConditions = [
    { name: 'value', value: "the value" },
    { name: 'not_equal', value: 'a value which is not equal to' },
    { name: 'starting_with', value: 'a value starting with' },
    { name: 'ending_with', value: 'a value ending with' },
    { name: 'contain', value: 'a value containing' },
    { name: 'is_empty', value: 'empty' },
    { name: 'isnt_empty', value: 'not empty' }
]