import React, { useEffect, useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import companyAvatar from '../../../Assets/images/general-settings/company-avatar.svg';
import { getItem, setItem } from '../../../Services/localService';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyStart, uploadCompanyProfileStart, getCompanyListStart, createCompanyStart, resetStates } from '../../../Store/Slices/organizationSlice';
import Loader from '../../../Components/Loader';
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import setCanvasPreview from '../../../Components/canvasPreview';
import FiscalYear from './fiscalYear';
import BusinessHours from './businessHours';
import Currency from './currency';
import { Utils } from '../../../Utils';
import { useNavigate } from 'react-router-dom';

const ASPECT_RATIO = '';
const MIN_DIMENSION = 150;
const ACCESS_URL = process.env.REACT_APP_ACCESS_URL;

const CompanySettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let id = getItem('user')?._id;
    const avatarUrl = useRef();
    let currentOrg = useSelector((state) => state?.org);
    let currentCompany = useSelector((state) => state?.org)?.companyData?.[0];
    const [showModal, setShowModal] = useState(false);
    let [companyInfo, setCompanyInfo] = useState({
        organizationName: null
    });
    let [orgId, setOrgId] = useState(null);
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9_]{3,30}$/;
    const [oldCompanyData, setOldCompanyData] = useState({});

    const [companyData, setCompanyData] = useState({
        Alias: '',
        City: '',
        organizationName: 'Digylax',
        Country: '',
        Description: '',
        Email: 'info@digylax.com',
        Employee_Count: '',
        Fax: '',
        Mobile: '',
        Phone: '',
        State: '',
        Street: '',
        Super_Admin: '',
        Website: '',
        Zip_Code: '',
        access_url: '',
        Time_Format: ''
    })

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState();
    const [error, setError] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [urlTouched, setUrlTouched] = useState(false);
    const [urlError, setUrlError] = useState('');

    const [user, setUser] = useState(() => {
        const savedUser = getItem('user');
        return savedUser && savedUser
    });

    useEffect(() => {
        if(currentCompany)
        {
            setOldCompanyData(currentCompany);
        }
        
    }, [currentCompany]);

    const formHandler = (e) => {
        try {
            const { name, value } = e?.target;
            setCompanyData((prev) => ({
                ...prev,
                [name]: name === 'access_url' ? value.toLowerCase() : value
            }));
        } catch (err) {
            throw err;
        }
    };

    const handleUrlOnBlur = (e) => {
        setUrlTouched(true)
        if (regex.test(companyData.access_url)) {
            setUrlError('')
        } else {
            setUrlError('Please enter a valid access URL.')
        }
    }

    useEffect(() => {
        if (currentOrg?.reload) {
            dispatch(getCompanyListStart());
            updateAvatar("");
            setCrop("");
            setImgSrc("");
        }
    }, [currentOrg?.reload])

    useEffect(() => {
        setCompanyInfo({ organizationName: currentCompany?.organizationName });
        setOrgId(currentCompany?._id);

        setCompanyData((prev) => ({
            ...prev,
            Alias: currentCompany?.Alias,
            City: currentCompany?.City,
            organizationName: currentCompany?.organizationName,
            Country: currentCompany?.Country,
            Description: currentCompany?.Description,
            Email: currentCompany?.Email,
            Employee_Count: currentCompany?.Employee_Count,
            Fax: currentCompany?.Fax,
            Mobile: currentCompany?.Mobile,
            Phone: currentCompany?.Phone,
            State: currentCompany?.State,
            Street: currentCompany?.Street,
            Super_Admin: currentCompany?.Super_Admin,
            Website: currentCompany?.Website,
            Zip_Code: currentCompany?.Zip_Code,
            access_url: currentCompany?.access_url,
            Time_Format: currentCompany?.Time_Format
        }));
        const img = new Image();
        img.src = currentCompany?.profilePicture;
        img.onload = () => {
            setImageLoaded(true);
        };
    }, [currentCompany])

    useEffect(() => {
        if (currentOrg?.updateStatus) {
            setShowModal(false);
            setUrlError('');
            setUrlTouched(false);
            if (currentOrg?.data?.access_url && currentOrg?.data?.access_url !== user?.organization?.access_url) {
                const updatedUser = { ...user.organization, access_url: currentOrg?.data?.access_url };
                user['organization'] = updatedUser
                setUser(user);
                setItem('user', user);
                dispatch(resetStates());
                navigate(Utils.getNavigationUrl("settings/company-settings"));
            }
        }
        if (currentOrg?.updateStatus === false) {
            setShowModal(true);
            setUrlError(currentOrg?.message)
        }
    }, [currentOrg?.updateStatus])

    const handleSubmit = () => {
        if (companyData?.access_url && companyData?.access_url !== '') {
            if (regex.test(companyData.access_url)) {
                setUrlError('')
            } else {
                setUrlError('Please enter a valid access URL.')
                return
            }
        }

        if (currentOrg?.companyData?.length) {
            setShowModal(false)
            if (user.organization.access_url === companyData.access_url) {
                const { organizationName, access_url, ...rest } = companyData;
                let payload = rest;
                const auditLog = Utils.logChanges(oldCompanyData, payload)
                payload.audit_log = { msg: auditLog };
                dispatch(updateCompanyStart({
                    id: orgId, payload: payload
                }))
            } else {
                const { organizationName, ...rest } = companyData;
                let payload = rest;
                dispatch(updateCompanyStart({
                    id: orgId, payload: payload
                }))
            }
        } else {
            let payload = companyData;
            payload['organizationName'] = companyData?.organizationName
            dispatch(createCompanyStart(payload))
        }
    }

    const updateAvatar = (imgSrc) => {
        avatarUrl.current = imgSrc;
    };

    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;
            imageElement.addEventListener("load", (e) => {
                if (error) setError("");
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("Image must be at least 150 x 150 pixels.");
                    return setImgSrc("");
                }
            });
            setImgSrc(imageUrl);
        });
        reader.readAsDataURL(file);
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    const base64ToBlob = (base64Data, contentType) => {
        const byteCharacters = atob(base64Data.split(',')[1]); // Remove the base64 header
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    };

    const handleUpdateProfile = () => {
        if (avatarUrl?.current) {
            const contentType = 'image/jpeg';
            const blob = base64ToBlob(avatarUrl?.current, contentType);
            const formData = new FormData();
            formData.append('profile', blob, 'image.jpeg')
            try {
                dispatch(uploadCompanyProfileStart({
                    id: orgId, payload: formData
                }))
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }

    const popover = (
        <Popover className="h-auto w-auto" style={{ maxWidth: "370px" }} id="accessUrlPopover">
            <Popover.Header className="text-primary bg-transparent border-0 px-0" as="h3">
                <i className="fa-solid fa-circle-info mx-3"></i>
                Instructions
            </Popover.Header>
            <Popover.Body className="p-0">
                <ol>
                    <li>Should have character length between 4 and 30.</li>
                    <li>Should contain alphabets.</li>
                    <li>Can contain numbers.</li>
                    <li>Can contain underscore (only allowed special character).</li>
                    <li>Should start either with an alphabet or a number.</li>
                </ol>
            </Popover.Body>
        </Popover>
    );
    return (
        <>
            <Loader isVisible={currentOrg?.isLoading} />
            {/* Company Settings Page */}
            <div className="g-settings company-setting">
                <div className="card rounded-4 border-0 p-0">
                    {/* Tabs Header Section */}
                    <div className="card-header py-0 fs-14 bg-white rounded-top-4">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item px-3" role="presentation">
                                <button className="nav-link text-primary position-relative px-0 active" id="pills-tab-1"
                                    data-bs-toggle="pill" data-bs-target="#pills-tab1" type="button" role="tab"
                                    aria-controls="pills-tab1" aria-selected="true">Company Details</button>
                            </li>
                            <li className="nav-item px-3" role="presentation">
                                <button className="nav-link text-primary position-relative px-0" id="pills-tab-2"
                                    data-bs-toggle="pill" data-bs-target="#pills-tab2" type="button" role="tab"
                                    aria-controls="pills-tab2" aria-selected="false">Fiscal Year</button>
                            </li>
                            <li className="nav-item px-3" role="presentation">
                                <button className="nav-link text-primary position-relative px-0" id="pills-tab-3"
                                    data-bs-toggle="pill" data-bs-target="#pills-tab3" type="button" role="tab"
                                    aria-controls="pills-tab3" aria-selected="false">Business Hours</button>
                            </li>
                            <li className="nav-item px-3" role="presentation">
                                <button className="nav-link text-primary position-relative px-0" id="pills-tab-4"
                                    data-bs-toggle="pill" data-bs-target="#pills-tab4" type="button" role="tab"
                                    aria-controls="pills-tab4" aria-selected="false">Currencies</button>
                            </li>
                        </ul>
                    </div>
                    {/* // Tabs Header Section END // */}
                    <div className="card-body overflow-y-auto pt-0">
                        <div className="tab-content px-3" id="pills-tabContent">
                            {/* Company Details */}
                            <div className="tab-pane fade show active" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab-1">
                                <div className="row g-0">
                                    <div className="col-md-9 px-3">
                                        {/* Company Details Top */}
                                        <div className="d-flex align-items-center py-2 mt-3 user-details">
                                            <div className="photo-upload position-relative d-inline-block align-self-start cursor-pointer"
                                                data-bs-toggle="modal" data-bs-target="#imgUploadModal">
                                                <div className="hover-img">
                                                    <div className="photo-icon"></div>
                                                </div>
                                                <div className="profile-icon company-icon">
                                                    {imageLoaded &&
                                                        <img className='opacity-100 rounded-pill' src={currentCompany?.profilePicture || companyAvatar} alt="company-icon" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="px-3 r-cont">
                                                <div className="fw-semibold user-name pb-1">
                                                    {currentCompany?.organizationName}
                                                    <i className="fa-solid fa-pencil ms-2 cursor-pointer"
                                                        data-bs-toggle="modal" data-bs-target="#comInfoForm"></i>
                                                </div>
                                                <p>{currentCompany?.Description}</p>
                                                <div className="user-email">
                                                    <i className="fa-regular fa-envelope fa-fw me-2"></i>
                                                    {currentCompany?.Email}
                                                </div>
                                                <div className="user-ph mt-2">
                                                    <i className="fa-solid fa-square-phone fa-fw me-2"></i>
                                                    {currentCompany?.Phone}
                                                </div>
                                                <div className="user-mob mt-2">
                                                    <i className="fa-solid fa-phone-volume fa-fw me-2" style={{ transform: "rotate(-30deg)" }}></i>
                                                    {currentCompany?.Mobile}
                                                </div>
                                                <div className="user-fax mt-2">
                                                    <i className="fa-solid fa-fax fa-fw me-2"></i>
                                                    {currentCompany?.Fax}
                                                </div>

                                                <div className="user-link mt-2">
                                                    <i className="fa-solid fa-link fa-fw me-2"></i>
                                                    {currentCompany?.Website}
                                                </div>
                                                <div className="user-location mt-2">
                                                    <i className="fa-solid fa-location-dot fa-fw me-2"></i>
                                                    {currentCompany?.Street && <span>{currentCompany?.Street}</span>}
                                                    {currentCompany?.City && <span> {currentCompany?.City}</span>}
                                                    {currentCompany?.State && <span> {currentCompany?.State}</span>}
                                                    {currentCompany?.Zip_Code && <span> {currentCompany?.Zip_Code}</span>}
                                                    {currentCompany?.Country && <span> {currentCompany?.Country}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* // Company Details Top END // */}

                                        <div className="access-url pt-3">
                                            <h6 className="sec-header fw-semibold pb-3 pt-2">
                                                Access URL
                                                <i className="fa-solid fa-pencil ms-2 cursor-pointer"
                                                    // data-bs-toggle="modal" data-bs-target="#accessurlModal"
                                                    onClick={() => setShowModal(true)}
                                                ></i>
                                                {/* data-bs-toggle="modal" data-bs-target="#comAccessUrlForm" */}
                                            </h6>
                                            <dl className="row justify-content-center list-detail">
                                                <dd className="col-sm-9 col-lg-3">URL</dd>
                                                <dt className="col-sm-3 col-lg-9">
                                                    {ACCESS_URL}{currentCompany?.access_url}
                                                    <i className="fa-regular fa-copy ms-2 cursor-pointer"></i>
                                                </dt>
                                            </dl>
                                        </div>

                                        <div className="local-info pt-3">
                                            <h6 className="sec-header fw-semibold pb-3 pt-2">
                                                Locale Information
                                            </h6>
                                            <dl className="row justify-content-center list-detail">
                                                <dd className="col-sm-9 col-lg-3">Time Zone</dd>
                                                <dt className="col-sm-3 col-lg-9">
                                                    {currentCompany?.Time_Format}
                                                    {/* (GMT 5:30) India Standard Time (Asia/Kolkata) */}
                                                    <i className="fa-solid fa-pencil ms-2 cursor-pointer"
                                                        data-bs-toggle="modal" data-bs-target="#comTimezoneForm"></i>
                                                </dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* // Company Details END // */}

                            {/* Fiscal Year */}
                            <div className="tab-pane fade fiscal-year" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab-2">
                                {/* Fiscal Year Content Section */}
                                <FiscalYear />
                                {/* // Manage Fiscal Year Form END // */}
                            </div>
                            {/* // Fiscal Year END // */}

                            {/* Business Hours */}
                            <div className="tab-pane fade" id="pills-tab3" role="tabpanel" aria-labelledby="pills-tab-3">
                                <BusinessHours />
                            </div>
                            {/* // Business Hours END // */}

                            {/* Currencies */}
                            <div className="tab-pane fade" id="pills-tab4" role="tabpanel" aria-labelledby="pills-tab-4">
                                <Currency />
                            </div>
                            {/* // Currencies END // */}

                        </div>
                    </div>

                </div>

                {/* Photo Upload Modal */}
                <div className="modal fade" id="imgUploadModal" tabIndex="-1" aria-labelledby="imgUploadModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h4 className="modal-title fs-5 fw-semibold" id="imgUploadModalLabel">Select Image</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body pt-0">

                                {!imgSrc &&
                                    <div className="file-upload-content">
                                        <div className="mb-4">Upload your photo here.</div>
                                        <span className="btn btn-primary btn-file">
                                            Browse Local Files <input type="file" accept="image/*" onChange={onSelectFile} />
                                        </span>
                                    </div>
                                }

                                {imgSrc && (
                                    <div className="flex flex-col items-center">
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                                            keepSelection
                                            aspect={ASPECT_RATIO}
                                            minWidth={MIN_DIMENSION}
                                        >
                                            <img
                                                ref={imgRef}
                                                src={imgSrc}
                                                alt="Upload"
                                                style={{ maxHeight: "70vh" }}
                                                onLoad={onImageLoad}
                                            />
                                        </ReactCrop>
                                        <div className='d-flex'>
                                            <button
                                                className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline"
                                                onClick={() => {
                                                    setCanvasPreview(
                                                        imgRef.current, // HTMLImageElement
                                                        previewCanvasRef.current, // HTMLCanvasElement
                                                        convertToPixelCrop(
                                                            crop,
                                                            imgRef.current.width,
                                                            imgRef.current.height
                                                        )
                                                    );
                                                    const dataUrl = previewCanvasRef.current.toDataURL();
                                                    updateAvatar(dataUrl);
                                                    handleUpdateProfile()
                                                    // closeModal();
                                                }}
                                                data-bs-dismiss="modal"
                                            >
                                                Crop and Set
                                            </button>
                                            <button type="button" className="btn btn-outline-primary btn-digy-dark-outline rounded-pill light-blue btn-digy-dark-outline ms-2" onClick={() => { setCrop(""); setImgSrc("") }} data-bs-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                )}

                                {crop && (
                                    <canvas
                                        ref={previewCanvasRef}
                                        className="mt-4"
                                        style={{
                                            display: "none",
                                            border: "1px solid black",
                                            objectFit: "contain",
                                            width: 150,
                                            height: 150,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Photo Upload Modal // */}

                {/* Company information Form Modal */}
                <div className="modal fade" id="comInfoForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="comInfoLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable com-details-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-5" id="comInfoLabel">Company Details</h5>
                            </div>
                            <div className="modal-body pb-0">
                                <div className="form-group mb-3">
                                    <label htmlFor="comName" className="col-auto col-form-label">Company Name</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="comName" name="organizationName" required
                                            onChange={formHandler} value={companyData?.organizationName} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="comNameAlias" className="col-auto col-form-label">Alias</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="comNameAlias" name="Alias"
                                            onChange={formHandler} value={companyData?.Alias} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="empCount" className="col-auto col-form-label">Employee Count</label>
                                    <div className="col-auto input-control">
                                        <input type="number" className="form-control" id="empCount" name="Employee_Count"
                                            onChange={formHandler} value={companyData?.Employee_Count} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="phone" className="col-auto col-form-label">Phone</label>
                                    <div className="col-auto input-control">
                                        <input type="number" className="form-control" id="phone" name="Phone"
                                            onChange={formHandler} value={companyData?.Phone} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="mob" className="col-auto col-form-label">Mobile</label>
                                    <div className="col-auto input-control">
                                        <input type="number" className="form-control" id="mob" name="Mobile"
                                            onChange={formHandler} value={companyData?.Mobile} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="fax" className="col-auto col-form-label">Fax</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="fax" name="Fax"
                                            onChange={formHandler} value={companyData?.Fax} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className="col-auto col-form-label">Email</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="email" name="Email"
                                            onChange={formHandler} value={companyData?.Email} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="website" className="col-auto col-form-label">Website</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="website" name="Website"
                                            onChange={formHandler} value={companyData?.Website} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="description" className="col-auto col-form-label">Description</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="description" name="Description"
                                            onChange={formHandler} value={companyData?.Description} />
                                    </div>
                                </div>
                                {/* <div className="form-group mb-3">
                                        <label htmlFor="superAdmin" className="col-auto col-form-label">Super Admin</label>
                                        <div className="col-auto input-control">
                                            <select className='form-select' id='superAdmin' name="Super_Admin"
                                                onChange={formHandler} value={companyData?.Super_Admin}>
                                                <option value="kadinrhuel@gmail.com">kadinrhuel@gmail.com</option>
                                            </select>
                                        </div>
                                    </div> */}
                                <div className="form-group mb-3">
                                    <label htmlFor="street" className="col-auto col-form-label">Street</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="street" name="Street"
                                            onChange={formHandler} value={companyData?.Street} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="city" className="col-auto col-form-label">City</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="city" name="City"
                                            onChange={formHandler} value={companyData?.City} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="state" className="col-auto col-form-label">State</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="state" name="State"
                                            onChange={formHandler} value={companyData?.State} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="zip" className="col-auto col-form-label">Zip</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="zip" name="Zip_Code"
                                            onChange={formHandler} value={companyData?.Zip_Code} />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="country" className="col-auto col-form-label">Country</label>
                                    <div className="col-auto input-control">
                                        <input type="text" className="form-control" id="country" name="Country"
                                            onChange={formHandler} value={companyData?.Country} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSubmit()} data-bs-dismiss="modal">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Company Information Form Modal // */}

                {/* access url modal::start */}
                {/* <div className="modal fade" id="accessurlModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="comInfoLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable com-details-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-5" id="comInfoLabel">Change Access URL</h5>
                            </div>
                            <div className="modal-body pb-0">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="country" className="col-auto col-form-label">URL</label>
                                        <div className="col-auto input-control">
                                            <input type="text" className="form-control" id="url" name="URL"
                                                onChange={formHandler} value={companyData?.URL} />
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSubmit()} data-bs-dismiss="modal">Save</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* access url::end */}


                {/* locale info::start */}
                <div className="modal fade" id="comTimezoneForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="comInfoLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable com-details-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-5" id="comInfoLabel">Locale Information</h5>
                            </div>
                            <div className="modal-body pb-0">
                                <div className="form-group mb-3">
                                    <label htmlFor="superAdmin" className="col-auto col-form-label">Super Admin</label>
                                    <div className="col-auto input-control">
                                        <select className='form-select' id='time_format' name="Time_Format"
                                            onChange={formHandler} value={companyData?.Time_Format}>
                                            <option value="(GMT 5:30) India Standard Time (Asia/Kolkata)">(GMT 5:30) India Standard Time (Asia/Kolkata)</option>
                                            <option value="(GMT 5:30) India Standard Time (IST)">(GMT 5:30) India Standard Time (IST)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => handleSubmit()} data-bs-dismiss="modal">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* locale inf::end */}

                {/* Company Access URL Modal */}
                {/* <div className="modal fade" id="comAccessUrlForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="comAccessUrlLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable com-access-url-form">
                    <div className="modal-content">
                        <div className="modal-body pb-0">
                            <h5 className="modal-title fs-5" id="comAccessUrlLabel">Change Access URL</h5>
                            <p>
                                You can change the access URL the way you want. Existing URL will stop working eventually.
                            </p>

                            <form>
                                <div className="row mb-3">
                                    <label htmlFor="comName" className="col-auto col-md-3 text-md-end col-form-label">Access URL</label>
                                    <div className="col-auto col-md-9">
                                        <div className="input-group mt-0">
                                            <span className="input-group-text" id="basic-addon3">https://digylax.com/crm/</span>
                                            <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                                                <input type="text" autofocus className="form-control rounded-start-0"/>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary rounded-pill btn-blue">Save</button>
                        </div>
                    </div>
                </div>
            </div> */}
                <Modal dialogClassName="access-url-pop" size="lg" show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Body>
                        <h5 className="modal-title fs-5" id="comAccessUrlLabel">Change Access URL</h5>
                        <p>
                            You can change the access URL the way you want. Existing URL will stop working eventually.
                        </p>
                        <Form>
                            <Form.Group className="mb-3 row">
                                <Form.Label className="col-auto col-md-3 col-xl-2 text-md-end col-form-label">Access URL</Form.Label>
                                <div className="col-auto col-md-9 col-xl-10">
                                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                                        <InputGroup className={`m-0 ${(!companyData?.access_url && urlTouched || urlError !== '') ? 'has-validation' : ''}`}>
                                            <InputGroup.Text id="accessUrlInput">
                                                {ACCESS_URL}
                                            </InputGroup.Text>
                                            <Form.Control className="rounded-start-0" id="accessUrl"
                                                aria-describedby="accessUrlInput" autoFocus
                                                name="access_url"
                                                onChange={formHandler} value={companyData?.access_url}
                                                onBlur={handleUrlOnBlur} />
                                            {(!companyData?.access_url && urlTouched || urlError !== '') && (
                                                <div className="invalid-feedback">{urlError}</div>
                                            )}
                                        </InputGroup>
                                    </OverlayTrigger>
                                </div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => { setShowModal(false); setUrlError(''); setUrlTouched(false) }}>
                            Cancel
                        </button>
                        <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { handleSubmit() }}>
                            Save
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* // Company Access URL Modal // */}

                {/* Company locale information Modal */}
                <div className="modal fade" id="comTimezoneForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="comTimezoneLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable com-timezone-form">
                        <div className="modal-content">
                            <div className="modal-body pb-0">
                                <h5 className="modal-title fs-5" id="comTimezoneLabel">Locale Information</h5>

                                <div className="row mb-3">
                                    <label htmlFor="timeZone" className="col-auto col-md-3 text-md-end col-form-label">Time Zone</label>
                                    <div className="col-auto input-control">
                                        <select className="form-select" id="timeZone">
                                            <option value="(GMT 5:30) India Standard Time (Asia/Kolkata)">(GMT 5:30) India Standard Time (Asia/Kolkata)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary rounded-pill btn-blue">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Company locale information Modal // */}
            </div>
            {/* // Company Settings Page END // */}
        </>
    )
}
export default CompanySettings;