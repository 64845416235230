import React from 'react';

function KpiStandard() {
    return (
        <>
            <h6 className="card-title">Revenue This Month</h6>
            <h2 className="fw-bold">$300 <span className="text-success">▲ 3.09%</span></h2>
            <p>Last Month: $291</p>
            <small className="text-muted">Standard</small>
        </>
    );
}

export default KpiStandard;
