import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Utils } from "../../../../../Utils";

const RepeatSettingsModal = ({ show, isData, handleClose, submitData, dueDate = "" }) => {
    let [repeatType, setRepeatType] = useState(isData?.repeatType || "Daily");
    let [frequencyType, setFrequencyType] = useState(isData?.frequencyType || "Daily");
    let [endOption, setEndOption] = useState(isData?.endOption || "Never");
    let [times, setTimes] = useState(isData?.times || 1);
    let [endDate, setEndDate] = useState(isData?.endDate || "");
    let [selectedDayName, setSelectedDayName] = useState(isData?.selectedDayName || "Monday");
    let [selectedMonthName, setSelectedMonthName] = useState(isData?.selectedMonthName || "January");
    let [selectedMonthName2, setSelectedMonthName2] = useState(isData?.selectedMonthName2 || "January");
    let [selectedOptionName, setSelectedOptionName] = useState(isData?.selectedOptionName || 'First');
    let [selectedDay, setSelectedDay] = useState(isData?.selectedDay || 1);
    let [interval, setInterval] = useState(isData?.interval || 1);
    let [selectedWeeklyDayNames, setSelectedWeeklyDayNames] = useState(isData?.selectedWeeklyDayNames || ["Monday"]);
    let [selectedRadioOption, setSelectedRadioOption] = useState(isData?.selectedRadioOption || 1);

    let daysnames = [
        { name: "Sunday", value: "Sunday", short_name : "S" },
        { name: "Monday", value: "Monday", short_name : "M" },
        { name: "Tuesday", value: "Tuesday", short_name : "T" },
        { name: "Wednesday", value: "Wednesday", short_name : "W" },
        { name: "Thursday", value: "Thursday", short_name : "T" },
        { name: "Friday", value: "Friday", short_name : "F" },
        { name: "Saturday", value: "Saturday", short_name : "S" }
    ];

    let handleSave = () => {
        let repeatSettings = { 
            repeatType,
            ...(repeatType === "Custom" ? { frequencyType } : {}),
            endOption,
            ...(endOption === "After" ? { times } : {}),
            ...(endOption === "On" ? { endDate } : {})
        };
        if(repeatType === "Custom" && frequencyType === "Daily") {
            repeatSettings = {...repeatSettings, interval };
        } else if (repeatType === "Custom" && frequencyType === "Weekly") {
            repeatSettings = {...repeatSettings, interval, selectedWeeklyDayNames };
        } else if (repeatType === "Custom" && frequencyType === "Monthly") {
            repeatSettings = {...repeatSettings, interval, selectedRadioOption, ...(selectedRadioOption === 1 ? { selectedDay } : {selectedOptionName, selectedDayName}) };
        } else if (repeatType === "Custom" && frequencyType === "Yearly") {
            repeatSettings = {...repeatSettings, selectedRadioOption, ...(selectedRadioOption === 1 ? { selectedDay, selectedMonthName, selectedOptionName } : {selectedOptionName, selectedDayName, selectedMonthName2}) };
        }
        console.log(Utils.generateRepeatDates({...repeatSettings, dueDate}))
        submitData(repeatSettings);
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Repeat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="form-floating mb-3">
                        <Form.Select
                            value={repeatType}
                            onChange={(e) => { 
                                setRepeatType(e.target.value);
                                setFrequencyType("Daily");
                            }}
                        >
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Custom">Custom</option>
                        </Form.Select>
                        <Form.Label>Type</Form.Label>
                    </Form.Group>
                    {repeatType === "Custom" && (
                        <Form.Group className="form-floating mb-3">
                            <Form.Select
                                value={frequencyType}
                                onChange={(e) => {
                                    setFrequencyType(e.target.value);
                                    setSelectedDayName("Monday");
                                    setSelectedMonthName("January");
                                    setSelectedMonthName2("January");
                                    setSelectedOptionName("First");
                                    setSelectedDay(1);
                                    setInterval(1);
                                    setSelectedWeeklyDayNames(["Monday"]);
                                    setSelectedRadioOption(1);
                                }}
                            >
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                            </Form.Select>
                            <Form.Label>Frequency</Form.Label>
                        </Form.Group>
                    )}
                    {(frequencyType === "Daily" && repeatType === "Custom") && (
                        <>
                            <div className="d-flex align-items-center mb-2">
                                <span className="me-2">Every</span>
                                <Form.Group>
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        className="ms-2"
                                        style={{ width: "80px" }}
                                        value={interval}
                                        onChange={(e) => setInterval(e?.target?.value)}
                                    />
                                </Form.Group>
                                <span className="ms-2">Days</span>
                            </div>
                        </>
                    )}
                    {(frequencyType === "Weekly" && repeatType === "Custom") && (
                        <div className="mb-3">
                            <div className="d-flex align-items-center mb-2">
                                <span className="me-2">Every</span>
                                <Form.Group>
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        className="ms-2"
                                        style={{ width: "80px" }}
                                        value={interval}
                                        onChange={(e) => setInterval(e?.target?.value)}
                                    />
                                </Form.Group>
                                <span className="ms-2">Weeks</span>
                            </div>
                            <div className="day-selector d-flex gap-2">
                                {daysnames?.map((x, i) => (
                                    <button key={i}
                                        type="button"
                                        className={`btn ${selectedWeeklyDayNames?.includes(x?.value) ? "active" : ""}`}
                                        onClick={() => {
                                            setSelectedWeeklyDayNames((prev) =>
                                                prev.includes(x?.value)
                                                    ? prev.filter(day => day !== x?.value)
                                                    : [...prev, x?.value]
                                            );
                                        }}
                                    >
                                        {x?.short_name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                    {(frequencyType === "Monthly" && repeatType === "Custom") && (
                        <div className="mb-3">
                            <div className="d-flex align-items-center mb-2">
                                <span className="me-2">Every</span>
                                <Form.Group>
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        className="ms-2"
                                        style={{ width: "80px" }}
                                        value={interval}
                                        onChange={(e) => setInterval(e?.target?.value)}
                                    />
                                </Form.Group>
                                <span className="ms-2">Months</span>
                            </div>
                            <div>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="radio"
                                        id="option1"
                                        name="monthlyOptions"
                                        className="me-2"
                                        checked={selectedRadioOption === 1}
                                        onChange={() => setSelectedRadioOption(1)}
                                    />
                                    <label htmlFor="option1">
                                        On
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedDay} onChange={(e) => setSelectedDay(e?.target?.value)}>
                                            {Array.from({ length: 31 }, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <span className="ms-2">of the month</span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <Form.Check
                                        type="radio"
                                        id="option2"
                                        name="monthlyOptions"
                                        className="me-2"
                                        checked={selectedRadioOption === 2}
                                        onChange={() => setSelectedRadioOption(2)}
                                    />
                                    <label htmlFor="option2">
                                        On
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedOptionName} onChange={(e) => setSelectedOptionName(e?.target?.value)}>
                                            <option value="First">First</option>
                                            <option value="Second">Second</option>
                                            <option value="Third">Third</option>
                                            <option value="Fourth">Fourth</option>
                                            <option value="Last">Last</option>
                                        </Form.Select>
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedDayName} onChange={(e) => setSelectedDayName(e?.target?.value)}>
                                            {daysnames?.map((x, i) => (
                                                <option key={i} value={x?.value}>{x?.name}</option>
                                            ))}
                                        </Form.Select>
                                        <span className="ms-2">of the month</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                    {(frequencyType === "Yearly" && repeatType === "Custom") && (
                        <div className="mb-3">
                            <div>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="radio"
                                        id="option1"
                                        name="monthlyOptions"
                                        className="me-2"
                                        checked={selectedRadioOption === 1}
                                        onChange={() => setSelectedRadioOption(1)}
                                    />
                                    <label htmlFor="option1">
                                        On
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedDay} onChange={(e) => setSelectedDay(e?.target?.value)}>
                                            {Array.from({ length: 31 }, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <span className="ms-2">of</span>
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedMonthName} onChange={(e) => setSelectedMonthName(e?.target?.value)}>
                                            <option value="January">January</option>
                                            <option value="February">February</option>
                                            <option value="March">March</option>
                                            <option value="April">April</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July</option>
                                            <option value="August">August</option>
                                            <option value="September">September</option>
                                            <option value="October">October</option>
                                            <option value="November">November</option>
                                            <option value="December">December</option>
                                        </Form.Select>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <Form.Check
                                        type="radio"
                                        id="option2"
                                        name="monthlyOptions"
                                        className="me-2"
                                        checked={selectedRadioOption === 2}
                                        onChange={() => setSelectedRadioOption(2)}
                                    />
                                    <label htmlFor="option2">
                                        On
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedOptionName} onChange={(e) => setSelectedOptionName(e?.target?.value)}>
                                            <option value="First">First</option>
                                            <option value="Second">Second</option>
                                            <option value="Third">Third</option>
                                            <option value="Fourth">Fourth</option>
                                            <option value="Last">Last</option>
                                        </Form.Select>
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedDayName} onChange={(e) => setSelectedDayName(e?.target?.value)}>
                                            {daysnames?.map((x, i) => (
                                                <option key={i} value={x?.value}>{x?.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Select className="d-inline-block w-auto ms-2" value={selectedMonthName2} onChange={(e) => setSelectedMonthName2(e?.target?.value)}>
                                            <option value="January">January</option>
                                            <option value="February">February</option>
                                            <option value="March">March</option>
                                            <option value="April">April</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July</option>
                                            <option value="August">August</option>
                                            <option value="September">September</option>
                                            <option value="October">October</option>
                                            <option value="November">November</option>
                                            <option value="December">December</option>
                                        </Form.Select>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>Ends</Form.Label>
                        <div>
                            <div className="mb-2">
                                <Form.Check
                                    type="radio"
                                    id="never"
                                    label="Never"
                                    value="Never"
                                    checked={endOption === "Never"}
                                    onChange={(e) => setEndOption(e.target.value)}
                                />
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <Form.Check
                                    type="radio"
                                    id="after"
                                    label="After"
                                    value="After"
                                    checked={endOption === "After"}
                                    onChange={(e) => setEndOption(e.target.value)}
                                />
                                <Form.Control
                                    type="number"
                                    min="1"
                                    className="ms-2"
                                    style={{ width: "80px" }}
                                    value={times}
                                    onChange={(e) => setTimes(Number(e.target.value))}
                                    disabled={endOption !== "After"}
                                />
                                <span className="ms-2">Time(s)</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <Form.Check
                                    type="radio"
                                    id="on"
                                    label="On"
                                    value="On"
                                    checked={endOption === "On"}
                                    onChange={(e) => setEndOption(e.target.value)}
                                />
                                <Form.Control
                                    type="date"
                                    className="ms-2"
                                    style={{ width: "180px" }}
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    disabled={endOption !== "On"}
                                />
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RepeatSettingsModal;