import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

const Email = () => {
    const [key, setKey] = useState('compose');

    const [selectedFf, setSelectedFf] = useState('verdana, sans-serif');
    const [selectedFs, setSelectedFs] = useState('10');

    return (
    <>
    <div className="settings email-config">
        <div className="card rounded-4 border-0 p-0">
            {/* Tabs Header Section */}
            <div className="card-header py-0 fs-14 bg-white rounded-top-4">
                <ul className="nav nav-pills" id="underline-tab" role="tablist">
                    <li className="nav-item px-3" role="presentation">
                        <button className="nav-link text-primary position-relative px-0 active" id="underline-tab-1"
                            data-bs-toggle="pill" data-bs-target="#underline-tab1" type="button" role="tab"
                            aria-controls="underline-tab1" aria-selected="true">Email Configuration</button>
                    </li>
                </ul>
            </div>
            {/* // Tabs Header Section END // */}
            <div className="card-body overflow-y-auto">
                <div className="tab-content px-3" id="underline-tabContent">
                    {/* Email configuration Tab */}
                    <div className="tab-pane fade show active" id="underline-tab1" role="tabpanel" aria-labelledby="underline-tab-1">

                        <Tab.Container id="pill-tab" defaultActiveKey={key}>
                            <Nav className='mt-2 d-md-inline-flex rounded-pill border border-primary border-opacity-75 p-1'
                            variant="pills"  
                            onSelect={(k) => setKey(k)}>
                                <Nav.Item>
                                    <Nav.Link className='rounded-pill py-1' eventKey="compose">Compose</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='rounded-pill py-1' eventKey="emailTemplate">Email Template</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='rounded-pill py-1' eventKey="emailWorkflow">Email Workflow</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='rounded-pill py-1' eventKey="connectTo">Connect To</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                {/* Compose Tab */}
                                <Tab.Pane eventKey="compose">
                                    <div className="d-flex flex-row mt-4">
                                        <div className="left-con">
                                            <h5 className="fw-semibold">Compose Settings</h5>
                                            <p>
                                                Configure basic default email compose settings to personalize your composing experience.
                                            </p>
                                        </div>
                                        <div className="right-con d-flex flex-column align-items-end ms-auto">
                                            <button type="button" className="btn btn-light rounded-pill py-1 px-3 flat-btn">
                                                <i className="fa-regular fa-circle-question me-2"></i> Help
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label htmlFor="defaultFf" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                            Default Font Family
                                        </label>
                                        <div className="col-md-auto col-xl-3">
                                            <select className="form-select" id="defaultFf" 
                                            value={selectedFf}
                                            onChange={e => setSelectedFf(e.target.value)}>
                                                <option value="Tahoma, sans-serif">Tahoma</option>
                                                <option value="Times New Roman, sans-serif">Times New Roman</option>
                                                <option value="Trebuchet MS, sans-serif">Trebuchet MS</option>
                                                <option value="verdana, sans-serif" selected>Verdana</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label htmlFor="defaultFs" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                            Default Font Size
                                        </label>
                                        <div className="col-md-auto col-xl-3">
                                            <select className="form-select" id="defaultFs" 
                                            value={selectedFs}
                                            onChange={e => setSelectedFs(e.target.value)}>
                                                <option value='8'>8</option>
                                                <option value='10'>10</option>
                                                <option value='12'>12</option>
                                                <option value='14'>14</option>
                                                <option value='18'>18</option>
                                                <option value='24'>24</option>
                                                <option value='36'>36</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label className="col-md-auto col-xl-2 text-md-end col-form-label"></label>
                                        <div className="col-md-auto col-xl-5">
                                            <div className='compose-preview p-3 border rounded-2'>
                                                <h6 className='fs-14 pb-2 border-bottom boder-opacity-50'>Preview</h6>
                                                <p className='mb-0' style={{fontFamily: selectedFf, fontSize: `${selectedFs}px`}}>The quick brown fox jumped over the lazy dog</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                {/* // Compose Tab END // */}

                                {/* Email Template Tab */}
                                <Tab.Pane eventKey="emailTemplate">
                                    <h5 className="fw-semibold mt-4">Email Template</h5>
                                    <div className="row align-items-center mb-3">
                                        <label htmlFor="moduleSelect" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                            Module Select
                                        </label>
                                        <div className="col-md-auto col-xl-3">
                                            <select className="form-select" id="moduleSelect">
                                                <option value="0" selected disabled>Select Module</option>
                                                <option value="1">Leads</option>
                                                <option value="2">Artical Master</option>
                                                <option value="3">Artical Master Generic</option>
                                                <option value="4">EAN</option>
                                                <option value="5">Tax Master</option>
                                                <option value="6">Price Conditions</option>
                                                <option value="7">Material Master</option>
                                                <option value="8">Production Version</option>
                                                <option value="9">Routing</option>
                                                <option value="10">Bill of Material</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label htmlFor="templateName" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                            Template Name
                                        </label>
                                        <div className="col-md-auto col-xl-3">
                                            <input type="text" className="form-control" id="templateName" placeholder='Template name' />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label htmlFor="subject" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                            Subject
                                        </label>
                                        <div className="col-md-auto col-xl-6">
                                            <input type="text" className="form-control" id="subject" placeholder='Email subject' />
                                        </div>
                                    </div>
                                    <div className="row align-items-start mb-3">
                                        <label htmlFor="templateContent" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                            Content
                                        </label>
                                        <div className="col-md-auto col-xl-6">
                                            <textarea className="form-control" id="templateContent" rows="10" placeholder='Email content'></textarea>
                                        </div>
                                    </div>
                                    <div className="row align-items-start mb-3">
                                        <label htmlFor="templateSignature" className="col-md-auto col-xl-2 text-md-end col-form-label">
                                            Signature
                                        </label>
                                        <div className="col-md-auto col-xl-3">
                                            <input type="file" className="form-control" id="templateSignature" aria-describedby="emailSignatureFileAddon" aria-label="Upload" />
                                        </div>
                                    </div>
                                </Tab.Pane>
                                {/* // Email Template Tab END // */}

                                {/* Email workflow Tab */}
                                <Tab.Pane eventKey="emailWorkflow">
                                    <h5 className="fw-semibold mt-4">Email Workflow</h5>
                                </Tab.Pane>
                                {/* // Email workflow Tab END // */}

                                {/* Email connect to Tab */}
                                <Tab.Pane eventKey="connectTo">
                                    <h5 className="fw-semibold mt-4">Email Connect To</h5>
                                </Tab.Pane>
                                {/* // Email connect to Tab END // */}

                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    {/* // Email configuration Tab END // */}
                </div>
            </div>
        </div>
    </div>
    </>
    )
}
export default Email;