import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { getAllDashboardComponentLayoutStart, deleteDashboardComponentLayoutStart, updateDashboardComponentLayoutStart } from "../../Store/Slices/dashboardComponentsSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../Components/ModalPopup/ConfirmationModal";
import Loader from "../../Components/Loader";
import { Utils } from "../../Utils";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const HomeCustomization = () => {
    let gridRef = useRef();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let subModuleData = useSelector((state) => state?.dashboardComponents);
    let [dashboardComponentDatas, setDashboardComponentDatas ] = useState([]);
    let [removeModalData, setRemoveModalData] = useState(null);
    let changeStatus = (event, data) => {
        if (data?._id) {
            let obj = {
                id: data._id,
                payload: {
                    ...data,
                    isActive : event?.target?.checked
                }
            }
            dispatch(updateDashboardComponentLayoutStart(obj));
        }
    }
    let [columnDefs] = useState([
        { headerName: "Name", field: "layout_name", width: 280 },
        { headerName: "Description", field: "description", width: 280, 
            valueFormatter: (params) => {
                return params.value || "_";
            },
         },
        {
            headerName: "Last Modified",
            field: "updatedAt",
            valueFormatter: (params) => {
                if (!params.value) return '';
                const date = new Date(params.value);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
            },
            width: 280,
        },
        {
            headerName: "Status",
            field: "isActive",
            filter: false,
            cellRenderer: (params) => {
                return (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={params?.value}
                            onChange={(e) => changeStatus(e, params?.data)}
                        />
                    </div>
                )
            },
            width: 280,
        },
        {
            headerName: "Edit",
            filter: false,
            cellRenderer: (params) => (
                <span className="cursor-pointer" onClick={() => navigate(Utils.getNavigationUrl(`home-customization/update/${params?.data?._id}`))}><i className="fas fa-edit"></i></span>
            ),
            width: 140
        },
        {
            headerName: "Delete",
            filter: false,
            cellRenderer: (params) => (
                <span style={{ color : "#dc3545"}} className="cursor-pointer" onClick={() => setRemoveModalData(params?.data)}><i className="fas fa-trash"></i></span>
            ),
            width: 140
        }
    ]);

    useEffect(() => {
        if (subModuleData?.reloadGetAllModuleLayoutApi) {
            dispatch(getAllDashboardComponentLayoutStart());
        }
    }, [subModuleData?.reloadGetAllModuleLayoutApi]);

    useEffect(() => {
        if (!subModuleData?.layouts?.length) {
            dispatch(getAllDashboardComponentLayoutStart());
        }
    }, []);

    useEffect(() => {
        if(subModuleData?.layouts?.length > 0) {
            setDashboardComponentDatas(subModuleData?.layouts);
        } else {
            setDashboardComponentDatas([]);
        }
    }, [subModuleData?.layouts]);

    let onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            event.target.value,
        );
    }

    return (
        <>
            <Loader isVisible={subModuleData?.isLoading} />
            <div className="card rounded-4 border-0 p-0 form-module">
                <div className="card-body">
                    <div className="col-md-12 setup-actions d-flex pb-4 pt-0">
                        <div className="me-3">
                            <button className="btn btn-primary rounded-pill" onClick={() => navigate(-1)}>
                                <i className="fa fa-arrow-left me-2"></i> Back
                            </button>
                        </div>
                        <div className="left d-flex align-items-start">
                            <div className="search-box border rounded-pill">
                                <button className="searchbutton bg-white" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                                <input type="search" placeholder="Search" className="searchfield bg-white" onInput={onFilterTextBoxChanged}/>
                            </div>
                        </div>
                        <div className="ms-auto">
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => navigate(Utils.getNavigationUrl(`home-customization/create`))}>
                                    <i className="fa-solid fa-plus me-2"></i>
                                    <span>New Home Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="ag-theme-quartz" style={{ height: "calc(100vh - 21vh)", display: "flex", flexDirection: "column",  position : "relative"}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={dashboardComponentDatas || []}
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    filter: "agTextColumnFilter",
                                    floatingFilter: true,
                                }}
                                rowSelection="single"
                                pagination={true}
                                rowHeight={40}
                                floatingFiltersHeight={40}
                                headerHeight={40}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {removeModalData && (
                <ConfirmationModal
                    data={removeModalData}
                    show={true}
                    handleClose={() => setRemoveModalData(null)}
                    handleSuccess={(moduleId) => {
                        dispatch(deleteDashboardComponentLayoutStart(moduleId));
                        setRemoveModalData(null);
                    }}
                />
            )}
        </>
    );
}

export default HomeCustomization;