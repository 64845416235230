import React from 'react';
import { Modal } from 'react-bootstrap';

function AddUserModal({ show, handleClose, newUserData, userHandler, handleEmailOnBlur,
    roleList, profilesList, setShowRoleListModal, clearUserState, handleCloseUserModal,
    setFirstNameTouched, firstNameTouched, emailTouched, emailError, handlePhoneNoBlur,
    isReadOnly, phoneTouched, phoneError, setRoleTouched, setProfileTouched, profileTouched,
    createUser, formValid }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row mb-4">
                            <label htmlFor="newUserFirstName" className="col-sm-3 text-sm-end col-form-label">First Name</label>
                            <div className="col-sm-9 input-control">
                                <input type="text" className="form-control" id="newUserFirstName" required
                                    name="firstName" value={newUserData?.firstName} onChange={userHandler}
                                    onBlur={() => setFirstNameTouched(true)}
                                />
                                <div className={`${!newUserData?.firstName && firstNameTouched ? 'has-validation' : ''}`}>
                                    <div className="invalid-feedback">
                                        First Name cannot be empty.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label htmlFor="newUserLastName" className="col-sm-3 text-sm-end col-form-label">Last Name</label>
                            <div className="col-sm-9 input-control">
                                <input type="text" className="form-control" id="newUserLastName"
                                    name="lastName" value={newUserData?.lastName} onChange={userHandler} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label htmlFor="newUserEmailBox" className="col-sm-3 text-sm-end col-form-label">Email</label>
                            <div className="col-sm-9 input-control">
                                <input type="email" className="form-control" id="newUserEmailBox" required
                                    placeholder="An invitation will be sent to this email address."
                                    name="email" value={newUserData?.email} onChange={userHandler}
                                    onBlur={handleEmailOnBlur} />
                                <div className={`${!newUserData?.email && emailTouched ? 'has-validation' : ''}`}>
                                    {(!newUserData?.email && emailTouched) && (
                                        <div className="invalid-feedback">
                                            Email cannot be empty.
                                        </div>
                                    )}
                                    {emailError && <div className="error-message text-danger">{emailError}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label htmlFor="newUserEmailBox" className="col-sm-3 text-sm-end col-form-label">Phone Number</label>
                            <div className="col-sm-9 input-control">
                                <input type="number" className="form-control" id="newUserEmailBox" required
                                    name="phone_no" value={newUserData?.phone_no} onChange={userHandler}
                                    onBlur={handlePhoneNoBlur} readOnly={isReadOnly} />
                                <div className={`${!newUserData?.phone_no && phoneTouched ? 'has-validation' : ''}`}>
                                    {(!newUserData?.phone_no && !newUserData.email && phoneTouched) && (
                                        <div className="invalid-feedback">
                                            Please enter the email first.
                                        </div>
                                    )}
                                    {(!newUserData?.phone_no && newUserData.email && phoneTouched) && (
                                        <div className="invalid-feedback">
                                            Phone Number cannot be empty.
                                        </div>
                                    )}
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label htmlFor="newUserRole" className="col-sm-3 text-sm-end col-form-label">Role</label>
                            <div className="col-sm-9 input-control">
                                <div className="input-group mt-0" onClick={() => setShowRoleListModal(true)}>
                                    <input type="text" className="form-control bg-body-tertiary cursor-pointer"
                                        id="newUserRole" required readOnly
                                        name="role"
                                        value={roleList?.filter((item) => item._id === newUserData?.role)?.[0]?.role_name}
                                        onChange={userHandler}
                                        onBlur={() => setRoleTouched(true)} />
                                    <span className="input-group-text fs-14 cursor-pointer">
                                        <i className="fa-solid fa-user-plus"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label htmlFor="profile" className="col-sm-3 text-sm-end col-form-label">Profile</label>
                            <div className="col-sm-9 input-control">
                                <select className="form-select" required
                                    name="Profile" value={newUserData?.Profile} onChange={userHandler}
                                    onBlur={() => setProfileTouched(true)}>
                                    <option value="">Select Profile</option>
                                    {
                                        profilesList?.length > 0 && profilesList?.map((data) => (
                                            <option value={data?._id}>{data?.profile_name}</option>
                                        ))
                                    }
                                </select>
                                <div className={`${!newUserData?.Profile && profileTouched ? 'has-validation' : ''}`}>
                                    <div className="invalid-feedback">
                                        Profile cannot be empty.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary rounded-pill btn-blue" onClick={() => { clearUserState(); handleCloseUserModal() }}>Cancel</button>
                <button type="button" className="btn btn-primary rounded-pill btn-blue" onClick={() => { createUser(); handleCloseUserModal() }}
                    disabled={!formValid}>Save</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddUserModal;
