import React from 'react';

function KpiScorecard() {
    return (
        <>
            <h6 className="card-title">Top Leads Sources</h6>
            <table className="table align-middle mb-0">
                <thead>
                    <tr>
                        <th>Lead Source</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Advertisement</td>
                        <td>$550 <span className="text-success">▲ 14%</span></td>
                    </tr>
                    <tr>
                        <td>Google+</td>
                        <td>$500 <span className="text-danger">▼ 22%</span></td>
                    </tr>
                    <tr>
                        <td>Cold Call</td>
                        <td>$340 <span className="text-success">▲ 13%</span></td>
                    </tr>
                    <tr>
                        <td>Chat</td>
                        <td>$220 <span className="text-danger">▼ 19%</span></td>
                    </tr>
                    <tr>
                        <td>Twitter</td>
                        <td>$190 <span className="text-success">▲ 20%</span></td>
                    </tr>
                </tbody>
            </table>
            <p className="text-muted small mt-2">* Compared with Last year</p>
            <small className="text-muted">Scorecard</small>
        </>
    );
}

export default KpiScorecard;
