import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Utils } from "../../../../Utils";
import { Helpers } from "../../../../Helpers";
import MutiSelectDropDown from "../MutiSelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserListStart } from "../../../../Store/Slices/userSlice";
import { destroychangeOwnerExit, updateSubFormByIdStart } from "../../../../Store/Slices/subFormSlice";
import Loader from "../../../Loader";
import { getItem } from "../../../../Services/localService";

const OwnerChange = ({ show, handleClose, basedModuleStoreData, basedSubFormData }) => {
    let subFormData = useSelector((state) => (state?.subForm || ''));
    let [selectedUserField, setSelectedUserField] = useState(null);
    let [listUserFields, setListUserFields] = useState([]);
    let [defaultValue, setDefaultValue] = useState(null);
    let users = useSelector((state) => state?.user);
    let [userList, setUserList] = useState([]);
    let dispatch = useDispatch();
    let [storeData, setStoreData] = useState(basedSubFormData);
    let currentUserData = getItem('user');
    let updateKeys = {
        formsubmodules : "changeOwnerFormsubmodules",
        message : "changeOwnerMessage",
        exit : "changeOwnerExit",
        isLoading : "changeOwnerIsLoading",
        status : "changeOwnerStatus",
        reload : "changeOwnerReload",
        error : "changeOwnerError"
    }

    useEffect(() => {
        if(basedSubFormData) {
            setStoreData(basedSubFormData);
        }
    }, [basedSubFormData]);

    useEffect(() => {
        if(!users?.data?.length) {
            dispatch(getAllUserListStart({ page: 1, count: 100 }));
        }
    }, []);

    useEffect(() => {
        if (basedModuleStoreData?.fields) {
            let list = Utils.getModuleFields(basedModuleStoreData?.fields?.sections, ["user"]);
            list = list?.moduleFields?.filter(x => (x?.fields?.extra_fields?.user_type === 'single_user'));
            setSelectedUserField(Helpers.replaceData(list?.[0]?.fields?.extra_fields?.field_label));
            setListUserFields(list);
        }
    }, [basedModuleStoreData?.fields]);

    useEffect(() => {
        if (basedSubFormData?.data?.length > 0) {
            let val = Utils.getSubFormFieldToData(selectedUserField, basedSubFormData?.data);
            setDefaultValue(val);
        }
    }, [basedSubFormData?.data, selectedUserField]);

    useEffect(() => {
        if(users?.data?.length > 0) {
            setUserList(users?.data || []);
        }
    }, [users?.data]);

    useEffect(() => {
        if (defaultValue) {
            try {
                let sectionIndex = listUserFields?.find(x => Helpers.replaceData(x?.fields?.extra_fields?.field_label) === selectedUserField)?.sectionIndex;
                setStoreData((prevStoreData) => {
                    let updatedData = JSON.parse(JSON.stringify(prevStoreData));
                    updatedData.data[sectionIndex]["section_data"][selectedUserField] = defaultValue;
                    return updatedData;
                });
            } catch(err) {}
        }
    }, [defaultValue]);

    let submit = () => {
        if (basedSubFormData) {
            let obj = Utils.getChangeHistory(basedSubFormData?.data, storeData?.data, `${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`);
            let allData = { ...storeData, ...(obj?.details?.length > 0 ? { log_details: obj } : {}) };
            dispatch(updateSubFormByIdStart({
                subModuleId: basedSubFormData?._id,
                payload: allData,
                updateKeys
            }));
        }
    }

    useEffect(() => {
        if (subFormData?.changeOwnerExit) {
            dispatch(destroychangeOwnerExit());
            handleClose();
        }
    }, [subFormData?.changeOwnerExit]);

    return (
        <>
            <Modal show={show} onHide={handleClose} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Change Owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(users?.isLoading || subFormData?.changeOwnerIsLoading) && (
                        <Loader isVisible={true} isModal={true} overall={false} />
                    )}
                    <div className="mb-3 form-floating">
                        <select className="form-select" value={selectedUserField || ""} onChange={(e) => setSelectedUserField(e?.target?.value)}>
                            <option value="">Select User Field</option>
                            {listUserFields?.length > 0 && (
                                listUserFields?.map((x, index) => (
                                    <option key={index} value={Helpers.replaceData(x?.fields?.extra_fields?.field_label)}>{x?.fields?.extra_fields?.field_label}</option>
                                ))
                            )}
                        </select>
                        <label htmlFor="userField">
                            User Field
                        </label>
                    </div>
                    {selectedUserField?.length > 0 && (
                        <div className="form-normal">
                            <div className="multi-select">
                                <label htmlFor="userInput">
                                    {selectedUserField?.replace(/_/g, " ")}
                                </label>
                                <MutiSelectDropDown preview={false} options={userList} handleInputChange={(data) => { setDefaultValue(data?.target?.value) }} default_value={defaultValue} singleSelect={true} isSingleUser={true} isUser={true} />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={submit} disabled={subFormData?.changeOwnerIsLoading || users?.isLoading || !selectedUserField?.length}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default OwnerChange;