import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { getItem, setItem } from '../../../Services/localService';
import { loginStart, verifyEmailStart } from "../../../Store/Slices/authSlice";
import Loader from "../../../Components/Loader";
import Toast from "../../../Components/Toast";
// import digyLogo from '../../../Assets/images/digylax-logo.png';
import digyLogo from '../../../Assets/images/crm-logo-final.svg';
import googleLogo from '../../../Assets/images/auth/google-icon-logo.svg';
import microsoftLogo from '../../../Assets/images/auth/microsoft_logo.svg';
import authImage from '../../../Assets/images/auth/auth-main.png';
import { resetState } from "../../../Store/Slices/authSlice";
import { fetchIp, fetchLocation } from "../../../Services/apiService";
import { createCompanyStart } from "../../../Store/Slices/organizationSlice";
import { Form, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";

const ACCESS_URL = process.env.REACT_APP_ACCESS_URL;

const Login = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state?.auth);
    const currentOrg = useSelector((state) => state?.org);
    const navigate = useNavigate();
    const user = getItem('verifiedUser');
    const email = user?.verifyType == 'email' ? user?.email : user?.phone_no;
    const [loginForm, setLoginForm] = useState({
        email: '',
        password: '',
        phone_no: parseInt(''),
        location_info: {
            ip: '',
            city: '',
            state: '',
            country: '',
        }
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showLoginUi, setShowLoginUi] = useState(true);
    const [showCompanySetupUi, setShowCompanySetupUi] = useState(false);
    const [showWelcomeUi, setShowWelcomeUi] = useState(false);
    const [registerForm, setRegisterForm] = useState({
        organizationName: '',
        Phone: '',
        access_url: ''
    });
    const [organizationNameTouched, setOrganizationNameTouched] = useState(false);
    const [organizationPhoneTouched, setOrganizationPhoneTouched] = useState(false);
    const [accessUrlTouched, setAccessUrlTouched] = useState(false);
    const [urlError, setUrlError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9_]{3,30}$/;

    const registerFormHandler = (e) => {
        try {
            const { name, value } = e.target;

            if (name === 'phone_no' || name === 'Phone') {
                if (value.length > 0 && value.length !== 10) {
                    setPhoneError('Phone number must be exactly 10 digits.');
                } else {
                    setPhoneError('');
                }
            }
            setRegisterForm((registerForm) => ({
                ...registerForm,
                [name]: name === 'access_url' ? value.toLowerCase() : value
            }));

        } catch (err) {
            throw err;
        }
    };

    const handleUrlOnBlur = (e) => {
        setAccessUrlTouched(true)
        if (regex.test(registerForm.access_url)) {
            setUrlError('')
        } else {
            setUrlError('Please enter a valid access URL.')
        }
    }

    const handleCompanySubmit = () => {
        if (registerForm?.access_url && registerForm?.access_url !== '') {
            if (regex.test(registerForm.access_url)) {
                setUrlError('')
            } else {
                setUrlError('Please enter a valid access URL.')
                return
            }
        }
        const companyPayload = {
            organizationName: registerForm.organizationName,
            Phone: registerForm.Phone,
            access_url: registerForm.access_url
        };
        dispatch(createCompanyStart(companyPayload));
    };

    useEffect(() => {
        if (currentOrg?.createStatus) {
            // if (currentOrg?.orgData) {
            //     setItem('organization', currentOrg?.orgData);
            // }
            toast.success(currentOrg?.message, {
                autoClose: 1000,
                onClose: () => {
                    setShowWelcomeUi(true);
                    setShowLoginUi(false);
                    setShowCompanySetupUi(false);
                    setUrlError('');
                    setAccessUrlTouched(false);
                }
            })
        } else if (!currentOrg?.createStatus) {
            setUrlError(currentOrg?.message)
            // toast.error(currentOrg?.message, {
            //     autoClose: 2000
            // })
        }
    }, [currentOrg?.createStatus]);

    const loginFormHandler = (e) => {
        try {
            if (e?.target?.name == 'email') {
                if (isNaN(e?.target?.value))
                    setLoginForm((loginForm) => ({
                        ...loginForm,
                        'email': e?.target?.value,
                        'phone_no': parseInt('')
                    }))
                else
                    setLoginForm((loginForm) => ({
                        ...loginForm,
                        'email': '',
                        'phone_no': parseInt(e?.target?.value)
                    }))
            } else {
                setLoginForm((loginForm) => ({
                    ...loginForm,
                    [e?.target?.name]: e?.target?.value
                }))
            }
        } catch (err) {
            throw err;
        }
    }

    const loginHandler = () => {
        try {
            let obj = {
                password: loginForm.password,
                location_info: loginForm.location_info
            }
            if (loginForm.email !== '')
                obj['email'] = loginForm.email
            else
                obj['phone_no'] = loginForm.phone_no
            dispatch(loginStart(obj))
        } catch (err) {
            throw err;
        }
    }

    const isFormComplete = (loginForm.email || loginForm.phone_no) && loginForm.password;

    useEffect(() => {
        localStorage.removeItem('verifiedUser');
        const fetchDetails = async () => {
            try {
                const ip = await fetchIp(); // Fetch IP address
                const location = await fetchLocation(ip); // Fetch location details using IP
                setLoginForm((prevForm) => ({
                    ...prevForm,
                    location_info: { ip, ...location },
                }));
            } catch (err) {
                console.error('Error fetching IP and location details:', err);
            }
        };
        fetchDetails();
    }, []);

    useEffect(() => {
        if (auth?.message) {
            if (auth?.successStatus) {
                setItem('user', auth?.user);
                setItem('token', auth?.user?.accessToken);
                setItem('organization', auth?.user?.organization?._id);
                setItem('history_Id', auth?.user?.login_history_id)
                setItem('modulePermission', auth?.user?.Profile?.permission?.modulePermission);
                toast.success(auth?.message, {
                    autoClose: 1000,
                    onClose: () => {
                        navigate(`/${auth?.user?.organization?.access_url}/dashboard`)
                        window.location.reload();
                        // navigate('/')
                    }
                })
            } else if (!auth?.successStatus) {
                toast.error(auth?.message, {
                    autoClose: 2000,
                    onClose: () => {
                        dispatch(resetState());
                    }
                });
            }
        }
    }, [auth?.successStatus]);

    useEffect(() => {
        if (auth?.data?.is_company === false) {
            setItem('token', auth?.data?.verifyToken);
            setShowWelcomeUi(false);
            setShowLoginUi(false);
            setShowCompanySetupUi(true);
            setUrlError('');
            setAccessUrlTouched(false);
        }
        if (auth.emailVerified) {
            setShowPassField(true);
            const { email, phone_no, _id } = auth?.data
            let obj = {
                email, phone_no, _id,
                verifyType: loginForm.email !== '' ? 'email' : 'phone_no'
            }
            setItem('verifiedUser', obj);
        }
        if (auth.emailVerified == false) {
            toast.error(auth?.message, {
                autoClose: 1000
            })
        }
    }, [auth.emailVerified]);

    const handleNext = () => {
        // Check if email is entered in the login form
        if (loginForm.email !== '') {
            // Dispatch action to start email verification with the entered email
            dispatch(verifyEmailStart({ email: loginForm.email }));
        } else {
            dispatch(verifyEmailStart({ phone_no: parseInt(loginForm.phone_no) }));
        }
    };

    const [showPassField, setShowPassField] = useState(false);

    const isCompanyFormComplete = registerForm.organizationName && (registerForm.Phone && phoneError === '') && registerForm.access_url;

    const popover = (
        <Popover className="h-auto w-auto" style={{ maxWidth: "370px" }} id="accessUrlPopover">
            <Popover.Header className="text-primary bg-transparent border-0 px-0" as="h3">
                <i className="fa-solid fa-circle-info mx-3"></i>
                Instructions
            </Popover.Header>
            <Popover.Body className="p-0">
                <ol>
                    <li>Should have character length between 4 and 30.</li>
                    <li>Should contain alphabets.</li>
                    <li>Can contain numbers.</li>
                    <li>Can contain underscore (only allowed special character).</li>
                    <li>Should start either with an alphabet or a number.</li>
                </ol>
            </Popover.Body>
        </Popover>
    );

    return (
        <React.Fragment>
            <Loader isVisible={auth?.isLoading || currentOrg?.isLoading} />
            {(auth?.message || auth?.error) && <Toast />}

            {/* New Flow Static Design */}
            <div className="d-flex flex-column flex-root h-100vh">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid auth-page auth-v1">
                    <div className="d-flex flex-column flex-lg-row-fluid order-2 order-lg-1 auth-l-box">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            {/* login ui */}
                            <div className={`w-lg-100 ${showLoginUi ? 'd-block' : 'd-none'}`}>
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-crm-logo" width="146" height="56" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Login to your account </h1>
                                    <div className="text-body-tertiary fw-semibold fs-6"> Enter Your Email or Phone Number </div>
                                </div>

                                <div className={`mb-3 icon-input ${showPassField ? 'd-none' : 'd-block'}`}>
                                    <div className="input-group email">
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="email">
                                            <i className="fa-regular fa-envelope"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child" placeholder="Email address or Mobile number"
                                            aria-describedby="email" required defaultValue={loginForm.email}
                                            name="email" onChange={loginFormHandler} />
                                        <div className="invalid-feedback">Error Message Box</div>
                                    </div>
                                </div>

                                <div className={`d-grid mb-3 ${showPassField ? 'd-none' : 'd-block'}`}>
                                    <button type="button" className="btn btn-primary"
                                        onClick={handleNext}
                                        disabled={!loginForm.email && !loginForm.phone_no}
                                    >
                                        <span className="indicator-label">Next</span>
                                    </button>
                                </div>

                                <div className={`mb-3 icon-input ${showPassField ? 'd-block' : 'd-none'}`}>
                                    <div className="mb-3 icon-input">
                                        <div className="d-inline-flex flex-nowrap align-items-center justify-content-between border rounded-2 w-100">
                                            <div className="py-2 px-3 fw-semibold text-truncate">{email}</div>
                                            <a onClick={() => navigate("/auth/login")} role="button" className="btn btn-link text-decoration-none">
                                                Change
                                            </a>
                                        </div>

                                    </div>
                                    <div className="input-group password">
                                        <span className="input-group-text bg-transparent border-end-0 first-child" id="password">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="form-control border-start-0 border-end-0" placeholder="Password"
                                            aria-describedby="password" required value={loginForm.password}
                                            name="password" onChange={loginFormHandler} />
                                        <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                            onClick={() => { loginForm.password && setShowPassword(!showPassword) }}>
                                            <i className={`fa-regular ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </span>
                                        <div className="invalid-feedback">Error Message Box</div>
                                    </div>
                                </div>
                                <div className={`d-flex flex-stack flex-wrap gap-3 fs-6 fw-semibold mb-3 ${showPassField ? 'd-block' : 'd-none'}`}>
                                    <div></div>
                                    <a onClick={() => navigate("/auth/forgot-password")} className="link-primary text-decoration-none"> Forgot Password ? </a>
                                </div>
                                <div className={`d-grid mb-3 ${showPassField ? 'd-block' : 'd-none'}`}>
                                    <button type="submit" className="btn btn-primary"
                                        onClick={loginHandler} disabled={!loginForm.password}>
                                        <span className="indicator-label">Login</span>
                                    </button>
                                </div>

                                <div className={`separator separator-content my-4 ${!showPassField ? 'd-block' : 'd-none'}`}>
                                    <span className="w-125px fw-semibold fs-7 text-gray-500">Or</span>
                                </div>

                                <div className={`d-flex justify-content-center mb-3 ${!showPassField ? 'd-block' : 'd-none'}`}>
                                    <a className="btn btn-flex btn-outline flex-center text-nowrap border rounded-pill me-3 social-log-btn">
                                        <img alt="brand-logo" src={googleLogo} className="h-15px" />
                                    </a>
                                    <a className="btn btn-flex btn-outline flex-center text-nowrap border rounded-pill social-log-btn">
                                        <img alt="brand-logo" src={microsoftLogo} className="h-15px" />
                                    </a>
                                </div>

                                <div className={`text-gray-500 text-center fw-semibold fs-6 ${!showPassField ? 'd-block' : 'd-none'}`}>
                                    Don’t you have an account?
                                    <a onClick={() => navigate("/auth/signup")} className="link-primary text-decoration-none"> Sign up </a>
                                </div>
                            </div>
                            {/* company setup ui */}
                            <div className={`w-lg-100 ${showCompanySetupUi ? 'd-block' : 'd-none'}`}>
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-crm-logo" width="146" height="56" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Welcome to Digylax </h1>
                                    <div className="text-body fw-semibold fs-6">
                                        You're one step away from getting started
                                    </div>
                                </div>
                                <div className="mb-3 icon-input">
                                    <div className={`input-group ${!registerForm?.organizationName && organizationNameTouched ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="companyName">
                                            <i className="fa-regular fa-building"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child"
                                            placeholder="Company Name"
                                            aria-describedby="companyName"
                                            required
                                            name={'organizationName'}
                                            value={registerForm?.organizationName}
                                            onChange={registerFormHandler}
                                            onBlur={() => setOrganizationNameTouched(true)} />
                                        {!registerForm?.organizationName && organizationNameTouched && (
                                            <div className="invalid-feedback">Company name required</div>
                                        )}
                                        {/* <div className="invalid-feedback">Company name required</div> */}
                                    </div>
                                </div>
                                <div className="mb-3 icon-input">
                                    <div className={`input-group ${!registerForm?.Phone && organizationPhoneTouched ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="companyPhNum">
                                            <i className="fa-solid fa-phone-volume rotate-320"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child" placeholder="Company Phone Number"
                                            aria-describedby="companyPhNum" required
                                            name={'Phone'}
                                            value={registerForm?.Phone}
                                            onChange={(e) => {
                                                if (/^\d*$/.test(e.target.value)) {
                                                    registerFormHandler(e);
                                                }
                                            }}
                                            onBlur={() => setOrganizationPhoneTouched(true)}
                                            onKeyDown={(e) => {
                                                if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {!registerForm?.Phone && organizationPhoneTouched && (
                                            <div className="invalid-feedback">Company phone number required</div>
                                        )}
                                        {phoneError && <div className="error-message">{phoneError}</div>}
                                        {/* {phoneError && <div className="invalid-feedback">{phoneError}</div>} */}
                                    </div>
                                </div>
                                <div className="mb-3 icon-input">
                                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                                        <InputGroup className={`input-group ${(!registerForm?.access_url && accessUrlTouched || urlError !== '') ? 'has-validation' : ''}`}>
                                            <InputGroup.Text id="accessUrlInput">
                                                {ACCESS_URL}
                                            </InputGroup.Text>
                                            <Form.Control className="rounded-start-0" id="accessUrl"
                                                placeholder="Access URL"
                                                aria-describedby="accessUrlInput"
                                                //  autoFocus
                                                name={'access_url'}
                                                value={registerForm?.access_url}
                                                onChange={registerFormHandler}
                                                onBlur={handleUrlOnBlur}
                                            />
                                            {(!registerForm?.access_url && accessUrlTouched || urlError !== '') && (
                                                <div className="invalid-feedback">{urlError}</div>
                                            )}
                                        </InputGroup>
                                    </OverlayTrigger>
                                </div>
                                {/* <div className="mb-3 icon-input">
                                    <div className={`input-group ${(!registerForm?.access_url && accessUrlTouched || urlError !== '') ? 'has-validation' : ''}`}>
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="accessUrl">
                                            <i className="fa fa-link"></i>
                                        </span>
                                        <input type="text" className="form-control border-start-0 last-child"
                                            placeholder="Access URL"
                                            aria-describedby="accessUrl"
                                            required
                                            name={'access_url'}
                                            value={registerForm?.access_url}
                                            onChange={registerFormHandler}
                                            onBlur={handleUrlOnBlur} />
                                        {(!registerForm?.access_url && accessUrlTouched || urlError !== '') && (
                                            <div className="invalid-feedback">{urlError}</div>
                                        )}
                                    </div>
                                </div> */}
                                <div className="d-grid pt-4 my-3">
                                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary"
                                        onClick={() => { handleCompanySubmit() }} disabled={!isCompanyFormComplete}>
                                        <span className="indicator-label">Get Started</span>
                                    </button>
                                </div>
                            </div>
                            {/* welcome ui */}
                            <div className={`w-lg-100 ${showWelcomeUi ? 'd-block' : 'd-none'}`}>
                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-crm-logo" width="146" height="56" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Please verify your email </h1>
                                    <p className="mt-4 mb-0">We will sent an email to <span className="fw-semibold">({loginForm?.email})</span>.</p>
                                    <p>Click the link in the email to verify your account.</p>
                                    {/* <div className="text-body-tertiary fw-semibold fs-6 my-3">
                                        Did’t receive an email?
                                        <a className="ps-2">Try Again</a>
                                    </div> */}
                                    <p className="pt-3">
                                        Already have an account?
                                        <a onClick={() => { localStorage.clear(); window.location.reload() }} className="ps-2 fw-semibold cursor-pointer">Log in</a>.
                                    </p>
                                    {/* setShowLoginUi(true); setShowCompanySetupUi(false); setShowWelcomeUi(false);  */}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex flex-lg-row-fluid order-1 order-lg-2 auth-r-box">
                        <div className="d-flex flex-column flex-center py-3 w-100">
                            <img className="main-img" src={authImage} alt="auth-bg" />
                            <h5 className="heading">
                                MFA for all accounts
                            </h5>
                            <p className="content">
                                Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* // New Flow Static Design // */}

            {/* New Flow Static Design */}
            {/* <div className="d-flex flex-column flex-root h-100vh">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid auth-page auth-v1">
                    <div className="d-flex flex-column flex-lg-row-fluid order-2 order-lg-1 auth-l-box">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            <div className="w-lg-100">

                                <div className="mb-4">
                                    <a className="brand-logo d-inline-block mb-3">
                                        <img src={digyLogo} alt="digylax-crm-logo" width="146" height="56" />
                                    </a>
                                    <h1 className="h4 text-dark fw-bold mb-2"> Login to your account </h1>
                                    <div className="text-body-tertiary fw-semibold fs-6"> Enter your email and password login </div>
                                </div>

                                <div className={`mb-3 icon-input ${showPassField ? 'd-none' : 'd-block'}`}>
                                    <div className="input-group email">
                                        <span className="input-group-text first-child bg-transparent border-end-0" id="email">
                                            <i className="fa-regular fa-envelope"></i>
                                        </span>
                                        <input type="email" className="form-control border-start-0 last-child" placeholder="Email"
                                            aria-describedby="email" required value={loginForm?.email}
                                            name={'email'} onChange={(e) => { loginFormHandler(e) }} />
                                        <div className="invalid-feedback">Error Message Box</div>
                                    </div>
                                </div>
                                
                                <div className={`mb-3 icon-input ${!showPassField ? 'd-none' : 'd-block'}`}>
                                    <div className="d-flex flex-nowrap align-items-center justify-content-between border rounded-2">
                                        <div className="py-2 px-3 fw-semibold">johndoe@example.com</div>
                                        <button type="button" className="btn btn-link text-decoration-none" 
                                        onClick={() => { setShowPassField(!showPassField) }}>
                                            Change
                                        </button>
                                    </div>
                                </div>

                                <div className={`d-grid mb-3 ${showPassField ? 'd-none' : 'd-block'}`}>
                                    <button type="submit" className="btn btn-primary"
                                        onClick={() => { setShowPassField(!showPassField) }} disabled={!isFormComplete}>
                                        <span className="indicator-label">Next</span>
                                    </button>
                                </div>

                                <div className={`mb-3 icon-input ${showPassField ? 'd-block' : 'd-none'}`}>
                                    <div className="input-group password">
                                        <span className="input-group-text bg-transparent border-end-0 first-child" id="password">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="form-control border-start-0 border-end-0" placeholder="Password"
                                            aria-describedby="password" required value={loginForm?.password}
                                            name={'password'} onChange={(e) => { loginFormHandler(e) }} />
                                        <span className="input-group-text bg-transparent border-start-0 last-child cursor-pointer"
                                            onClick={() => { loginForm?.password && setShowPassword(!showPassword) }}>
                                            <i className={`fa-regular ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </span>
                                        <div className="invalid-feedback">Error Message Box</div>
                                    </div>
                                </div>
                                <div className={`d-flex flex-stack flex-wrap gap-3 fs-6 fw-semibold mb-3 ${showPassField ? 'd-block' : 'd-none'}`}>
                                    <div></div>
                                    <a className="link-primary text-decoration-none"> Forgot Password ? </a>
                                </div>
                                <div className={`d-grid mb-3 ${showPassField ? 'd-block' : 'd-none'}`}>
                                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary"
                                        onClick={() => { loginHandler() }} disabled={!isFormComplete}>
                                        <span className="indicator-label">Login</span>
                                    </button>
                                </div>

                                <div className={`separator separator-content my-4 ${!showPassField ? 'd-block' : 'd-none'}`}>
                                    <span className="w-125px fw-semibold fs-7 text-gray-500">Or</span>
                                </div>

                                <div className={`d-flex justify-content-center mb-3 ${!showPassField ? 'd-block' : 'd-none'}`}>
                                    <a className="btn btn-flex btn-outline flex-center text-nowrap border rounded-pill me-3 social-log-btn">
                                        <img alt="brand-logo" src={googleLogo} className="h-15px" />
                                    </a>
                                    <a className="btn btn-flex btn-outline flex-center text-nowrap border rounded-pill social-log-btn">
                                        <img alt="brand-logo" src={microsoftLogo} className="h-15px" />
                                    </a>
                                </div>

                                <div className={`text-gray-500 text-center fw-semibold fs-6 ${!showPassField ? 'd-block' : 'd-none'}`}>
                                    Don’t you have an account?
                                    <a className="link-primary text-decoration-none"> Sign up </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex flex-lg-row-fluid order-1 order-lg-2 auth-r-box">
                        <div className="d-flex flex-column flex-center py-3 w-100">
                            <img className="main-img" src={authImage} alt="auth-bg" />
                            <h5 className="heading">
                                MFA for all accounts
                            </h5>
                            <p className="content">
                                Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* // New Flow Static Design // */}
        </React.Fragment>
    )
}
export default Login;