import React, { useState } from 'react';  
import Modal from 'react-bootstrap/Modal';  
import Form from 'react-bootstrap/Form';  
import Button from 'react-bootstrap/Button';  
import InputGroup from 'react-bootstrap/InputGroup';  
import {handleFieldLabelUpdate} from '../../../Utils';

function LongIntegerPropertiesModal({ show, handleClose, item, editPropertySubmit }) {
  let fields = item?.extra_fields;
  const [fieldLabel, setFieldLabel] = useState(fields?.field_label);
  const [maximumDigitsAllowed, setMaximumDigitsAllowed] = useState(fields?.number_of_characters_allowed);
  const [allowDuplicateValues, setAllowDuplicateValues] = useState(false);
  const [required, setRequired] = useState(false);
  const [encryptField, setEncryptField] = useState(false);
  const [displayWithNumberSeparator, setDisplayWithNumberSeparator] = useState(false);
  const [labelError, setLabelError] = useState('');
  const [showTooltip, setShowTooltip] = useState({ checked: fields?.show_tooltip?.checked, type: fields?.show_tooltip?.type, message: fields?.show_tooltip?.message });
  const [showPreview, setShowPreview] = useState(false);
  
  

  const handleCancel = () => {
  
    setFieldLabel('Long Integer');
    setMaximumDigitsAllowed(18);
    setAllowDuplicateValues(true);
    setRequired(false);
    setEncryptField(false);
    setDisplayWithNumberSeparator(false);
    setShowTooltip({
      checked: true,
      type: 'static',
      message: '',
    });
    handleClose();
  };


  const handleDone = () => {
    const formData = {
      field_label: fieldLabel,
      number_of_characters_allowed: maximumDigitsAllowed,
      allow_duplicate_values: allowDuplicateValues,
      required,
      encrypt_field: encryptField,
      display_with_number_separator: displayWithNumberSeparator,
      show_tooltip: showTooltip,
    };

   editPropertySubmit(formData);
    
    handleClose();
  };

  const handleFieldLabelChange = (e) => {
    handleFieldLabelUpdate(e.target.value, setFieldLabel, setLabelError);
  }

  const handleCharLimitChange = (e) => {
    let value = e.target.value;
    const numericValue = value === '' ? '' : Math.min(Math.max(parseInt(value, 10), 1), 18);
    setMaximumDigitsAllowed(numericValue);
  }

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-size">
      <Modal.Header closeButton>
        <Modal.Title>Long Integer Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFieldLabel">
            <Form.Label>Field Label</Form.Label>
            <Form.Control
              type="text"
              value={fieldLabel}
              onChange={handleFieldLabelChange}
              placeholder="Field Label"
              required
            />
            {labelError && <div className="text-danger">{labelError}</div>}
          </Form.Group>

          <Form.Group controlId="formMaximumDigitsAllowed" className="mt-3">
            <Form.Label>Maximum Digits Allowed</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={maximumDigitsAllowed}
                onChange={handleCharLimitChange}
                max={18}
                min={1}
                required
              />
              <InputGroup.Text>Digits</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formRequired" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Required"
              checked={required}
              onChange={() => setRequired(!required)}
            />
          </Form.Group>

          <Form.Group controlId="formAllowDuplicateValues" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Allow Duplicate Values"
              checked={allowDuplicateValues}
              onChange={() => setAllowDuplicateValues(!allowDuplicateValues)}
            />
          </Form.Group>

          <Form.Group controlId="formEncryptField" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Encrypt Field"
              checked={encryptField}
              onChange={() => setEncryptField(!encryptField)}
            />
          </Form.Group>

          <Form.Group controlId="formDisplayWithNumberSeparator" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Display with Number Separator"
              checked={displayWithNumberSeparator}
              onChange={() => setDisplayWithNumberSeparator(!displayWithNumberSeparator)}
            />
          </Form.Group>
          
          <Form.Group controlId="formShowTooltip" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Show Tooltip"
              checked={showTooltip.checked}
              onChange={() => setShowTooltip({ ...showTooltip, checked: !showTooltip.checked })}
            />
          </Form.Group>

          {showTooltip.checked && (
            <div>
              <Form.Group>
                <Form.Label>Tooltip Message (Max 255 characters)</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={255}
                  value={showTooltip.message}
                  onChange={(e) => setShowTooltip({ ...showTooltip, message: e.target.value })}
                  placeholder="Enter Tooltip message"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Info Icon"
                  name="tooltipOption"
                  checked={showTooltip.type === 'info'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'info' })}
                />
                <Form.Check
                  type="radio"
                  label="Static Text"
                  name="tooltipOption"
                  checked={showTooltip.type === 'static'}
                  onChange={() => setShowTooltip({ ...showTooltip, type: 'static' })}
                />
              </Form.Group>

              <Button variant="primary" onClick={handlePreview}>
                Preview Tooltip
              </Button>

              <Modal show={showPreview} onHide={handleClosePreview}>
                <Modal.Header closeButton>
                  <Modal.Title>Tooltip Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showTooltip.type === 'info' ? (
                    <div>
                      <i className="bi bi-info-circle"></i>
                      {showTooltip.message && <span className="ms-2">{showTooltip.message}</span>}
                    </div>
                  ) : (
                    <p>{showTooltip.message}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePreview}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleDone}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LongIntegerPropertiesModal;
